import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";

import { model as accordionModel } from "~/accordion";

import "./rec-event-accordion-item.css";
import { AccordionItem } from "~/accordion/model";

export const REC_EVENT_HEIGHT = 50;

//actions below will be changed when 10306 is merged into test

export const createRecAccordionItems = (recList: Record<string, any>[]): any => {
    return recList?.map((rec) => {
        const payload = { recGeneralGuid: rec.recGeneralGuid };
        return new accordionModel.AccordionItem(REC_EVENT_HEIGHT, false, payload);
    });
};

export const getRecGuidFromDimIdx = (accordionItems: AccordionItem[], dimIdx: number[]): any =>
    accordionModel.getItem(accordionItems, dimIdx).payload.recGeneralGuid;

export const createEventAccordionItems = (eventList: Record<string, any>): any => {
    return eventList.map((event) => {
        const payload = { agEventGeneralGuid: event.agEventGeneralGuid };
        return new accordionModel.AccordionItem(REC_EVENT_HEIGHT, false, payload);
    });
};

export const getEventGuidFromDimIdx = (accordionItems: AccordionItem[], dimIdx: number[]): any =>
    accordionModel.getItem(accordionItems, dimIdx).payload.agEventGeneralGuid;

export interface IRecEventItem_Props {
    contextMenu?: JSX.Element;
    deselectItemsFromDimIdx: (itemIdx1: number[], itemIdx2: number[]) => void;
    intl: intlShape;
    isExpanded: boolean;
    isInactiveTab?: boolean;
    itemDimIdx: number[];
    lastClickedDimIdx: number[];
    setLastClickedDimIdx: () => void;
    selectItemsFromDimIdx: (itemIdx1: number[], itemIdx2: number[]) => void;
    isSelected: boolean;
    displayName?: string;
    eventId?: string[];
    modifiedUserDetails?: string;
    summary?: string;
    status?: string;
    statusCodeClassName?: string;
    statusMessageArg?: any;
    statusMessageId?: any;
}
export class RecEventItem_ extends Component<IRecEventItem_Props> {
    static defaultProps = {
        contextMenu: null,
        deselectItemsFromDimIdx: (): void => null,
        isExpanded: false,
        itemDimIdx: [],
        lastClickedDimIdx: [],
        setLastClickedDimIdx: (): void => null,
        selectItemsFromDimIdx: (): void => null,
        isSelected: false,
    };

    private _onClick(event): void {
        if (event.isDefaultPrevented() || this.props.isInactiveTab) {
            return;
        }

        event.preventDefault();

        if (event.getModifierState("Shift")) {
            this._onShiftClick();
            return;
        }

        const {
            deselectItemsFromDimIdx,
            isSelected,
            itemDimIdx,
            setLastClickedDimIdx,
            selectItemsFromDimIdx,
        } = this.props;

        setLastClickedDimIdx();

        if (isSelected) {
            deselectItemsFromDimIdx(itemDimIdx, itemDimIdx);
        } else {
            selectItemsFromDimIdx(itemDimIdx, itemDimIdx);
        }
    }

    private _onShiftClick(): void {
        const { itemDimIdx, lastClickedDimIdx, selectItemsFromDimIdx } = this.props;

        const compareResult = accordionModel.dimIdxCompare(itemDimIdx, lastClickedDimIdx);
        if (compareResult === 0) {
            // shift-clicked the same item ...
            return;
        }

        const [startDimIdx, endDimIdx] =
            compareResult === -1
                ? [itemDimIdx, lastClickedDimIdx]
                : [lastClickedDimIdx, itemDimIdx];

        selectItemsFromDimIdx(startDimIdx, endDimIdx);
    }

    render(): JSX.Element {
        const {
            isSelected,
            contextMenu,
            displayName,
            modifiedUserDetails,
            summary,
            statusCodeClassName,
            status,
            statusMessageArg,
            statusMessageId,
            eventId,
        } = this.props;

        const { formatMessage } = this.props.intl;
        const displayEventId = Array.isArray(eventId) && eventId.length > 0 ? eventId[0] : null;
        const statusStr =
            statusMessageId != null ? formatMessage(statusMessageId, statusMessageArg) : status;
        return (
            <div
                className={classNames(["rec-event-accordion-item", { selected: isSelected }])}
                onClick={(event) => this._onClick(event)}
            >
                <div className="spacer" />
                <div className="rec-event-info">
                    <div className="rec-event-name" title={displayName}>
                        {displayName}
                    </div>
                    <div className="rec-event-summary" title={summary}>
                        {summary}
                    </div>
                </div>
                <div className="rec-event-status" title={modifiedUserDetails}>
                    {!statusMessageArg ||
                    (!statusMessageArg.hasSoilSampleResults && !statusMessageArg.isBatch) ||
                    !displayEventId ? null : (
                        <div className="status event-id">{displayEventId}</div>
                    )}
                    <div title={status} className={classNames("status", statusCodeClassName)}>
                        {statusStr}
                    </div>
                </div>
                {contextMenu}
            </div>
        );
    }
}

export const RecEventItem = injectIntl(RecEventItem_);
