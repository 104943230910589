import React, { Component, ReactElement, ReactNode, MouseEvent as ReactMouseEvent } from "react";
import _ from "lodash";

//Components
import { DataTable } from "~/core";
import NestedTableRow from "./nested-table-row";
import { ITableService, IRecord, IMessages, IDataTableFooterOptions } from "../interfaces";

export interface INestedDataTableProps {
    children?: ReactElement;
    className?: string;
    isCheckbox?: boolean;
    isEditable?: boolean;
    lastSelectedRow?: IRecord;
    messages: IMessages;
    onItemSelection?: () => void;
    onRowSelection?: (
        event: ReactMouseEvent,
        selectItem: IRecord,
        isSelected?: boolean,
        defaultGuid?: IRecord
    ) => void;
    onRowUnselect?: () => void;
    records?: IRecord[];
    rowClassName?: string;
    selectedItems?: IRecord[];
    selectedRow?: IRecord;
    footerOptions?: IDataTableFooterOptions[];
    service: ITableService;
    toggleChildComponent?: (record: IRecord, recordService: IRecord) => ReactNode;
}

export interface INestedDataTableState {
    selectedRow: IRecord;
    selectedChildRow: IRecord;
    recordGuid: string;
}
class NestedDataTable extends Component<INestedDataTableProps, INestedDataTableState> {
    constructor(props: INestedDataTableProps) {
        super(props);
        this.state = {
            selectedRow: { canDelete: null, modifiedDate: null },
            selectedChildRow: null,
            recordGuid: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: INestedDataTableProps): void {
        if (nextProps.selectedRow !== this.props.selectedRow) {
            this.setState({
                selectedRow: nextProps.selectedRow,
                selectedChildRow: nextProps.selectedRow,
            });
        }
    }

    private isRowSelected = (record: IRecord): boolean => {
        const { service, selectedItems } = this.props;
        const selectedRecord = service.getDefaultGuid(record);
        return (
            _.findIndex(selectedItems, (item) => {
                return service.isItemSelected(item, selectedRecord);
            }) > -1
        );
    };

    private renderRows = ({ selectItems, deleteSelected }): ReactNode => {
        const {
            children,
            isCheckbox,
            isEditable,
            lastSelectedRow,
            records,
            rowClassName,
            service,
            toggleChildComponent,
        } = this.props;
        const { selectedRow, selectedChildRow, recordGuid } = this.state;
        const columns = service.getDefaultColumns();
        return (
            <div className="data-table-rows">
                {records &&
                    records
                        .filter((record) => record)
                        .map((record) => {
                            return (
                                <NestedTableRow
                                    {...this.props}
                                    checked={this.isRowSelected(record)}
                                    childComponent={children}
                                    classNames={rowClassName}
                                    columns={columns}
                                    deleteSelected={deleteSelected}
                                    isCheckbox={isCheckbox}
                                    isEditable={isEditable}
                                    key={record[service.guid]}
                                    onItemSelection={selectItems}
                                    record={record}
                                    recordGuid={recordGuid}
                                    selected={service.isRowSelected({
                                        selectedRow,
                                        currentRecord: record,
                                        lastSelectedRow,
                                    })}
                                    selectedChildRow={selectedChildRow}
                                    service={service}
                                    selectedRow={this.state.selectedRow}
                                    toggleChildComponent={toggleChildComponent}
                                />
                            );
                        })}
            </div>
        );
    };

    render(): ReactNode {
        const { service, messages, footerOptions } = this.props;
        return (
            <DataTable
                classNames="nested-data-table"
                service={service}
                messages={messages}
                {...this.props}
                renderRows={this.renderRows}
                selectedRow={this.state.selectedRow}
                footerOptions={footerOptions}
            />
        );
    }
}

export default NestedDataTable;
