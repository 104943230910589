import { connect } from "react-redux";
import { setCompanyUserPreference } from "~/admin/setup/batch-zapper/data/actions";
import { getLastUsedOwnerGuid } from "~/admin/setup/batch-zapper/data/selectors";
import { withMasked } from "~/hocs";
import { getTheUserCompanyGuid } from "~/login";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { getReportTypePageList } from "../../../../reports/data/actions";
import { getReportPref, getReportTypePages } from "../../../../reports/data/selectors";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { actions, selectors } from "../data";
import AddEditPanel from "./add-edit-panel";

const mapStateToProps = (state) => ({
    companyGuid: getTheUserCompanyGuid(state),
    filteredLocationList: selectors.getFilteredLocationList(state),
    lastUsedOwnerGuid: getLastUsedOwnerGuid(state),
    loggedInUserGuid: getTheUserGuid(state),
    loadingModal: selectors.getLoadingModal(state),
    loadingOrgLevelGuids: selectors.getLoadingOrgLevelGuids(state),
    loadingReportBookReports: selectors.getLoadingReportBookReports(state),
    loadingReportBooks: selectors.getLoadingReportBooks(state),
    reportBookNames: selectors.getReportBookNames(state),
    reportBookOrgLevelGuids: selectors.getReportBookOrgLevelGuids(state),
    reportBookReports: selectors.getReportBookReports(state),
    reportOptions: selectors.getReportOptions(state),
    reportPreferences: getReportPref(state),
    reportTypePages: getReportTypePages(state),
    reportTypes: selectors.getReportTypes(state),
    selectedReportOptionGuid: selectors.getSelectedReportOptionGuid(state),
    soilTypeAttributes: selectors.getSoilTypeAttributes(state),
    userGuid: getTheUserGuid(state),
});

const mapDispatchToProps = (dispatch) => ({
    getFilteredLocationList: () => dispatch(actions.getFilteredLocationList()),
    getReportTypePageList: (payload) => getReportTypePageList(payload),
    getReportBookTypeNames: () => dispatch(actions.getReportBookTypeNames()),
    getReportBookOrgLevelGuids: (payload) => dispatch(actions.getReportBookOrgLevelGuids(payload)),
    getReportBookReports: (payload) => dispatch(actions.getReportBookReports(payload)),
    getReportOptions: (payload) => dispatch(actions.getReportOptions(payload)),
    getReportTypes: () => dispatch(actions.getReportTypes()),
    getSoilTypeAttributes: () => dispatch(actions.getSoilTypeAttributes()),
    mergeReportOptions: (payload) => dispatch(actions.mergeReportOptions(payload)),
    onApiCallErr: (error) => dispatch(notificationActions.apiCallError(error)),
    onPushToasterMessage: (message: any, type: any) =>
        dispatch(notificationActions.pushToasterMessage(message, type)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    setCompanyUserPreference: (companyGuid) => dispatch(setCompanyUserPreference(companyGuid)),
});

export default withMasked(connect(mapStateToProps, mapDispatchToProps)(AddEditPanel));
