import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import Measure from "react-measure";
import PropTypes from "prop-types";
import classnames from "classnames";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../i18n-messages";
import { SelectInput, optionPropType } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";
import "./sample-point-table.css";

import { updateSampleLabelSequence, isSampleLabelComposite } from "@ai360/core";

class ProductivityRatingCell extends PureComponent {
    static propTypes = {
        intl: intlShape,
        onSetProdRating: PropTypes.func.isRequired,
        productivityRatingGuid: PropTypes.string,
        productivityRatingOptions: PropTypes.arrayOf(optionPropType).isRequired,
    };

    render() {
        const { productivityRatingGuid, productivityRatingOptions, onSetProdRating } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div onClick={(evt) => evt.preventDefault()}>
                <SelectInput
                    maxHeight={500}
                    optionIsHiddenKey={ACTIVE_YN}
                    minOptionsWidth={175}
                    onChange={(val) => onSetProdRating(val)}
                    options={productivityRatingOptions}
                    placeholderText={formatMessage(messages.ratingTxt)}
                    value={productivityRatingGuid}
                />
            </div>
        );
    }
}

export class SamplePointTable_ extends PureComponent {
    static propTypes = {
        depthConfig: PropTypes.arrayOf(
            PropTypes.shape({
                depthId: PropTypes.string.isRequired,
                endDepth: PropTypes.number.isRequired,
                startDepth: PropTypes.number.isRequired,
            })
        ),
        intl: intlShape,
        onSetPointProdRating: PropTypes.func.isRequired,
        onSetSelectedPointSet: PropTypes.func.isRequired,
        productivityRatingOptions: PropTypes.arrayOf(optionPropType).isRequired,
        samplePoints: PropTypes.arrayOf(
            PropTypes.shape({
                sampleId: PropTypes.number,
                sequenceId: PropTypes.number,
                productivityRatingGuid: PropTypes.string,
                soilSamplePointGuid: PropTypes.string,
            })
        ).isRequired,
        selectedPointSet: PropTypes.object.isRequired,
        showProductivityRating: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            colWidths: [],
            isComposite: isSampleLabelComposite(props.samplePoints),
            focusedRatingInput: null,
            scrollLeft: 0,
            scrollTop: 0,
            samplePointsSequenceMap: new Map(),
        };
        updateSampleLabelSequence(this.state.samplePointsSequenceMap, props.samplePoints);
    }

    _getColumnHorizontalPos(columnIndex, isStickyCol) {
        const { colWidths, scrollLeft } = this.state;
        const prevColumnsWidth = colWidths
            .slice(0, columnIndex)
            .reduce((accum, width) => accum + width, 0);
        if (!isStickyCol) {
            return prevColumnsWidth;
        }
        return prevColumnsWidth + scrollLeft;
    }

    _getDepthConfigRange(depthConfigIndex) {
        const depthConfig = this.props.depthConfig[depthConfigIndex];
        return (
            <div className="body-text">{`${depthConfig.startDepth} - ${depthConfig.endDepth}`}</div>
        );
    }

    _getHeader(isStickyRow) {
        const { depthConfig, showProductivityRating } = this.props;
        const { formatMessage } = this.props.intl;

        const stickyColCount = showProductivityRating ? 2 : 1;

        const columnIndices = Array.from(
            { length: depthConfig.length + stickyColCount },
            (_, idx) => idx
        );
        const headerCells = columnIndices.map((columnIndex) => {
            const isStickyCol = columnIndex < stickyColCount;
            const className = classnames(
                {
                    "prod-rating": showProductivityRating && columnIndex === 1,
                    "sticky-col": isStickyCol && !isStickyRow,
                    sticky: isStickyRow && !isStickyCol,
                    "sticky-all": isStickyRow && isStickyCol,
                },
                "header-cell"
            );

            if (!isStickyRow) {
                const spacerCell = <div className={className}>{columnIndex}</div>;
                return (
                    <Measure
                        key={`header_${columnIndex}`}
                        onMeasure={({ width }) => this._setColWidth(columnIndex, width)}
                    >
                        {spacerCell}
                    </Measure>
                );
            }

            const stickyStyle = {
                left: this._getColumnHorizontalPos(columnIndex, isStickyCol),
                top: this.state.scrollTop,
                width: this.state.colWidths[columnIndex],
            };
            return (
                <div key={`header_${columnIndex}`} className={className} style={stickyStyle}>
                    {columnIndex >= stickyColCount
                        ? depthConfig.length > 1
                            ? depthConfig[columnIndex - stickyColCount].depthId
                            : formatMessage(messages.depthColLabel)
                        : columnIndex === 0
                        ? formatMessage(messages.idColLabel)
                        : formatMessage(messages.ratingTxt)}
                </div>
            );
        });

        return <div className="header">{headerCells}</div>;
    }

    _getRow(samplePoint, pointIndex) {
        const { depthConfig, selectedPointSet, showProductivityRating } = this.props;
        const stickyColCount = showProductivityRating ? 2 : 1;

        const columnIndices = Array.from(
            { length: depthConfig.length + stickyColCount },
            (_, idx) => idx
        );
        const rowCells = columnIndices.map((columnIndex) => {
            const isStickyCol = columnIndex < stickyColCount;
            const className = classnames(
                {
                    "fixed-container": this.state.focusedRatingInput === pointIndex,
                    "prod-rating": showProductivityRating && columnIndex === 1,
                    selected: selectedPointSet.has(samplePoint.soilSamplePointGuid),
                    "sticky-col": isStickyCol,
                    sticky: isStickyCol,
                },
                "body-cell"
            );

            const onClick = (event) => {
                if (event.defaultPrevented) {
                    return;
                }
                this._selectPoints(pointIndex, event.ctrlKey, event.shiftKey);
            };

            const handleFocus = showProductivityRating && columnIndex === 1;
            const onFocus = !handleFocus
                ? null
                : () => {
                      this.setState({ focusedRatingInput: pointIndex });
                  };
            const onBlur = !handleFocus
                ? null
                : () => {
                      this.setState({ focusedRatingInput: null });
                  };

            const stickyStyle = !isStickyCol
                ? null
                : {
                      left: this._getColumnHorizontalPos(columnIndex, isStickyCol),
                      width: this.state.colWidths[columnIndex],
                  };

            return (
                <div
                    key={`${samplePoint.soilSamplePointGuid}_${columnIndex}`}
                    className={className}
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    style={stickyStyle}
                >
                    {columnIndex >= stickyColCount
                        ? this._getDepthConfigRange(columnIndex - stickyColCount)
                        : columnIndex === 0
                        ? this._getSamplePointId(samplePoint)
                        : this._getSampleProductivityRating(samplePoint, pointIndex)}
                </div>
            );
        });

        for (let columnIndex = 0; columnIndex < stickyColCount; columnIndex++) {
            const className = classnames("body-cell", {
                "prod-rating": showProductivityRating && columnIndex === 1,
            });
            rowCells.splice(
                0,
                0,
                <div
                    key={`${samplePoint.soilSamplePointGuid}_flexspacer_${columnIndex}`}
                    className={className}
                />
            );
        }

        return (
            <div key={`${samplePoint.soilSamplePointGuid}`} className="row">
                {rowCells}
            </div>
        );
    }

    _getSamplePointId(samplePoint) {
        const seqId = this.state.samplePointsSequenceMap.get(samplePoint.soilSamplePointGuid);
        return (
            <div className="body-text">
                {samplePoint.sequenceId == null
                    ? "-"
                    : this.state.isComposite
                    ? `${samplePoint.sampleId} - ${seqId}`
                    : `${samplePoint.sampleId}`}
            </div>
        );
    }

    _getSampleProductivityRating(samplePoint, pointIndex) {
        const { intl, onSetPointProdRating, productivityRatingOptions } = this.props;
        return (
            <ProductivityRatingCell
                intl={intl}
                onSetProdRating={(val) => onSetPointProdRating(pointIndex, val)}
                productivityRatingGuid={samplePoint.productivityRatingGuid}
                productivityRatingOptions={productivityRatingOptions}
            />
        );
    }

    _onScroll(evt) {
        const { target } = evt;
        if (this.containerNode == null && this.container != null) {
            // eslint-disable-next-line react/no-find-dom-node
            this.containerNode = ReactDOM.findDOMNode(this.container);
        }
        if (target !== this.containerNode) {
            return;
        }
        const { scrollLeft, scrollTop } = target;
        this.setState({ scrollLeft, scrollTop });
    }

    _selectPoints(pointIndex, withCtrl, withShift) {
        const { samplePoints, selectedPointSet } = this.props;
        const noModifiers = !withCtrl && !withShift;

        const newSelectedPointSet = new Set();
        if (noModifiers) {
            newSelectedPointSet.add(samplePoints[pointIndex].soilSamplePointGuid);
        } else if (withCtrl) {
            [...selectedPointSet].forEach((soilSamplePointGuid) =>
                newSelectedPointSet.add(soilSamplePointGuid)
            );

            const toggleSamplePoint = samplePoints[pointIndex];
            if (selectedPointSet.has(toggleSamplePoint.soilSamplePointGuid)) {
                newSelectedPointSet.delete(toggleSamplePoint.soilSamplePointGuid);
            } else {
                newSelectedPointSet.add(toggleSamplePoint.soilSamplePointGuid);
            }
        } else {
            console.assert(withShift);

            // Select all options between this option and the last non-select-click
            if (this.lastClickedWithoutShiftIndex == null) {
                this.lastClickedWithoutShiftIndex = 0;
            }

            samplePoints
                .slice(
                    Math.min(this.lastClickedWithoutShiftIndex, pointIndex),
                    Math.max(this.lastClickedWithoutShiftIndex, pointIndex) + 1
                )
                .forEach((samplePoint) => newSelectedPointSet.add(samplePoint.soilSamplePointGuid));
        }

        if (!withShift) {
            this.lastClickedWithoutShiftIndex = pointIndex;
        }

        this.props.onSetSelectedPointSet(newSelectedPointSet);
    }

    _setColWidth(columnIndex, width) {
        const { colWidths } = this.state;
        if (colWidths[columnIndex] === width) {
            return;
        }
        colWidths[columnIndex] = width;
        this.setState({ colWidths: [...colWidths] });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const samplePointsChanged = nextProps.samplePoints !== this.props.samplePoints;
        if (samplePointsChanged) {
            this.setState({
                isComposite: isSampleLabelComposite(nextProps.samplePoints),
            });
            const seqMap = this.state.samplePointsSequenceMap;
            updateSampleLabelSequence(seqMap, nextProps.samplePoints);
            this.setState({ samplePointsSequenceMap: seqMap });
        }
    }

    render() {
        const { samplePoints } = this.props;
        return (
            <div
                ref={(ref) => (this.container = ref)}
                className="sample-point-tbl"
                onScroll={(evt) => this._onScroll(evt)}
            >
                {this._getHeader(false)}
                {this._getHeader(true)}
                {samplePoints.map((point, pointIdx) => this._getRow(point, pointIdx))}
            </div>
        );
    }
}

export const SamplePointTable = injectIntl(SamplePointTable_);
