import { createAction } from "redux-act";

const FETCH_PREFERENCE = "FETCH_PREFERENCE_DATA";
const FETCH_PREFERENCE_SUCCESS = "FETCH_PREFERENCE_SUCCESS";
const FETCH_PREFERENCE_FAILED = "FETCH_PREFERENCE_FAILED";

const SAVE_PREFERENCE = "SAVE_PREFERENCE_DATA";
const SAVE_PREFERENCE_SUCCESS = "SAVE_PREFERENCE_SUCCESS";
const SAVE_PREFERENCE_FAILED = "SAVE_PREFERENCE_FAILED";

export const savePreference = createAction(SAVE_PREFERENCE);
export const savePreferenceSuccess = createAction(SAVE_PREFERENCE_SUCCESS);
export const savePreferenceFailure = createAction(SAVE_PREFERENCE_FAILED);

export const fetchPreference = createAction(FETCH_PREFERENCE);
export const fetchPreferenceSuccess = createAction(FETCH_PREFERENCE_SUCCESS);
export const fetchPreferenceFailure = createAction(FETCH_PREFERENCE_FAILED);
