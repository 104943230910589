import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Accordion, actions as accordionActions } from "~/accordion";

import * as selectors from "./selectors";

import { CustomerItem } from "./customer-item";
import { FeatureItem } from "./feature-item";

import { actions as cdActions } from "~/customer-data";

const getAccordionItemEl = ({ key, style, accordionItem, accordionItemDimIdx, componentProps }) => {
    const { accordionId } = componentProps;
    const expandItem = () => componentProps.expandItem(accordionId, accordionItemDimIdx);
    const collapseItem = () => componentProps.collapseItem(accordionId, accordionItemDimIdx);
    const isExpanded = accordionItem.expanded;

    const accordionItemRender = (function () {
        if (accordionItemDimIdx.length === 1) {
            const customer = accordionItem.payload.customer;
            const onClick = () => (isExpanded ? collapseItem() : expandItem());

            return <CustomerItem name={customer.name} isExpanded={isExpanded} onClick={onClick} />;
        } else if (accordionItemDimIdx.length === 2) {
            const { feature, customer } = accordionItem.payload;
            const isHighlighted = accordionItem.payload.isHighlighted;

            const onRadioButtonClicked = () => {
                isHighlighted
                    ? componentProps.dehighlightFeature(feature)
                    : componentProps.highlightFeature(feature);
                accordionItem.payload.isHighlighted = !accordionItem.payload.isHighlighted;
            };

            return (
                <FeatureItem
                    feature={feature}
                    customer={customer}
                    isHighlighted={isHighlighted}
                    onRadioButtonClicked={onRadioButtonClicked}
                />
            );
        }

        return null;
    })();

    return (
        <div key={key} style={style}>
            {accordionItemRender}
        </div>
    );
};

getAccordionItemEl.propTypes = {
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Unique key within array of rows
    style: PropTypes.object, // Style object to be applied to row (to position it)
    accordionItem: PropTypes.object,
    accordionItemDimIdx: PropTypes.arrayOf(PropTypes.number),
    isStuck: PropTypes.bool,
    componentProps: PropTypes.object, // Pass thru props from <Accordion />
};

const mapDispatchToProps = (dispatch) => ({
    expandItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx)),
    collapseItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx)),
    collapseChildren: (accordionId, itemDimIdx) =>
        dispatch(accordionActions.collapseAccordionItemChildren(accordionId, itemDimIdx)),
    expandChildren: (accordionId, itemDimIdx) =>
        dispatch(accordionActions.expandAccordionItemChildren(accordionId, itemDimIdx)),
    highlightFeature: (feature) =>
        dispatch(cdActions.modifyHighlightedNonFieldFeatures({ add: [feature] })),
    dehighlightFeature: (feature) =>
        dispatch(cdActions.modifyHighlightedNonFieldFeatures({ remove: [feature] })),
});

const mapStateToProps = (state) => ({
    itemCount: selectors.getRecItemCount(state),
    itemList: selectors.getItems(state),
    totalHeight: selectors.getRecHeight(state),
    getAccordionItemFromFlatIdx: (idx) => selectors.getFlatIdxMapSelector(state)(idx),
    getAccordionItemFromDimIdx: (dimIdx) => selectors.getDimIdxMapSelector(state)(dimIdx),
    getAccordionItemEl: getAccordionItemEl,
    scrollTop: selectors.getScrollTop(),
    accordionId: selectors.getId(state),
});

export const NonFieldDataAccordion = connect(mapStateToProps, mapDispatchToProps)(Accordion);
