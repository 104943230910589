import { all, call, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import { defineMessages } from "react-intl";

import { CreditAPI, PicklistAPI, RecAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login";
import { mapActions } from "~/map";

import * as blending from "./blending-utils";

import * as actions from "./actions";
import * as selectors from "./selectors";
import * as recSelectors from "../../rec-module/components/rec-info/selectors";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import {
    PICKLIST_PRODUCT_PARENT_TYPE,
    PICKLIST_PRODUCT_TYPE,
    PICKLIST_MANUFACTURER,
    PICKLIST_PHYSICAL_STATE,
    getPickListCode,
} from "~/core/picklist/picklist-names";

import {
    UNIT_PRODUCT_RATE_DRY,
    UNIT_PRODUCT_RATE_LIQUID,
    UNIT_PRICE_UNIT_DRY,
    UNIT_PRICE_UNIT_LIQUID,
    UNIT_PRICE_UNIT_SERVICE,
    UNIT_DENSITY,
    getUnitCode,
} from "~/core/units/unit-names";
const NEW = "NEW";

function sortServicePriceUnits(a, b) {
    const unitOrder = ["ac", "ea", "oz", "lb", "gal", "ton"];
    const aIdx = unitOrder.findIndex((unit) => unit === a.name);
    const bIdx = unitOrder.findIndex((unit) => unit === b.name);
    return aIdx < bIdx ? -1 : bIdx < aIdx ? 1 : 0;
}

const messages = defineMessages({
    saveBlendSuccessful: {
        id: "productBlend.saveBlendSuccessful",
        defaultMessage: "Operation was successful",
    },
    saveBlendFailed: {
        id: "productBlend.saveBlendFailed",
        defaultMessage: "Error Saving Product",
    },
});

const fetchProductBlendModalData = function* (action) {
    try {
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const existingDefaultCarrierGuid = yield select(selectors.getDefaultCarrierGuid);
        const existingDefaultProductMixTypeGuid = yield select(
            selectors.getDefaultProductMixTypeGuid
        );
        const eventRecCustomProductGuids = yield select(selectors.getEventRecCustomProductGuids);

        const [
            productParentTypePicklist,
            productTypePicklist,
            manufacturerPicklist,
            physicalStatePicklist,
            dryRateUnitsList,
            liquidRateUnitsList,
            dryPriceUnitsList,
            liquidPriceUnitsList,
            servicePriceUnitsList,
            availableProducts,
            conversionFactors,
            productMixTypesList,
            defaultCarrier,
            customProductTypesList,
            nutrients,
            densityUnitsList,
            customProducts,
        ] = yield all([
            call(
                PicklistAPI.getPicklistValuesById,
                userGuid,
                getPickListCode(PICKLIST_PRODUCT_PARENT_TYPE)
            ),
            call(
                PicklistAPI.getPicklistValuesById,
                userGuid,
                getPickListCode(PICKLIST_PRODUCT_TYPE)
            ),
            call(
                PicklistAPI.getPicklistValuesById,
                userGuid,
                getPickListCode(PICKLIST_MANUFACTURER)
            ),
            call(
                PicklistAPI.getPicklistValuesById,
                userGuid,
                getPickListCode(PICKLIST_PHYSICAL_STATE)
            ),
            call(PicklistAPI.getUnitListByType, userGuid, getUnitCode(UNIT_PRODUCT_RATE_DRY)),
            call(PicklistAPI.getUnitListByType, userGuid, getUnitCode(UNIT_PRODUCT_RATE_LIQUID)),
            call(PicklistAPI.getUnitListByType, userGuid, getUnitCode(UNIT_PRICE_UNIT_DRY)),
            call(PicklistAPI.getUnitListByType, userGuid, getUnitCode(UNIT_PRICE_UNIT_LIQUID)),
            call(PicklistAPI.getUnitListByType, userGuid, getUnitCode(UNIT_PRICE_UNIT_SERVICE)),
            call(RecAPI.getAvailableProducts, userGuid, {}),
            call(RecAPI.getRecConversionFactors, userGuid),
            call(RecAPI.getProductMixTypes, userGuid),
            call(RecAPI.getDefaultCarrier, userGuid),
            call(RecAPI.getCustomProductTypes, userGuid),
            call(RecAPI.getNutrients, userGuid),
            call(PicklistAPI.getUnitListByType, userGuid, getUnitCode(UNIT_DENSITY)),
            call(RecAPI.getCustomProducts, userGuid, {
                customProductGuid: "",
                physicalStateGuid: "",
                customProductTypeGuid: "",
                eventRecCustomProductGuids,
            }),
        ]);

        const productParentType = PicklistAPI.parsePicklistResp(productParentTypePicklist);
        const productType = PicklistAPI.parsePicklistResp(productTypePicklist);
        const manufacturer = PicklistAPI.parsePicklistResp(manufacturerPicklist);
        const physicalState = PicklistAPI.parsePicklistResp(physicalStatePicklist);
        const customProductTypes = customProductTypesList.map((cpt) => {
            return {
                label: cpt.name,
                value: cpt.customProductTypeGuid,
                activeYn: cpt.activeYn,
            };
        });

        const mapUnit = (unit) => {
            return {
                label: unit.name,
                value: unit.guid,
                activeYn: unit.activeYn,
            };
        };

        const dryRateUnits = dryRateUnitsList.sort(blending.sortUnits).map(mapUnit);
        const liquidRateUnits = liquidRateUnitsList.sort(blending.sortUnits).map(mapUnit);
        const dryPriceUnits = dryPriceUnitsList.sort(blending.sortUnits).map(mapUnit);
        const liquidPriceUnits = liquidPriceUnitsList.sort(blending.sortUnits).map(mapUnit);
        const servicePriceUnits = servicePriceUnitsList.sort(sortServicePriceUnits).map(mapUnit);
        const densityUnits = densityUnitsList.sort(blending.sortUnits).map(mapUnit);

        const productMixTypes = productMixTypesList.productMixTypes.map((type) => {
            return {
                label: type.name,
                value: type.productMixTypeGuid,
                activeYn: type.activeYn,
            };
        });
        const defaultProductMixTypeGuid = productMixTypesList.defaultProductMixTypeGuid;
        const defaultCarrierGuid = defaultCarrier.productGuid;
        const defaultTargetRate = defaultCarrier.targetRate;

        const productBlendPicklists = {
            productParentType,
            productType,
            manufacturer,
            physicalState,
            dryRateUnits,
            liquidRateUnits,
            dryPriceUnits,
            liquidPriceUnits,
            servicePriceUnits,
            productMixTypes,
            customProductTypes,
            nutrients,
            densityUnits,
        };

        const allAvailableProducts = availableProducts.map((product) => {
            const productParentType = productBlendPicklists.productParentType.find(
                (parentType) => parentType.label === product.productParentType
            );
            const productTypeList = product.productTypeList.map((pt) =>
                productBlendPicklists.productType.find((productType) => pt === productType.label)
            );
            const manufacturer = productBlendPicklists.manufacturer.find(
                (manufacturer) => manufacturer.label === product.manufacturerName
            );
            const physicalState = productBlendPicklists.physicalState.find(
                (physicalState) => physicalState.label === product.physicalState
            );

            return {
                ...product,
                productParentTypeGuid: productParentType?.value,
                productTypeGuidList: productTypeList?.map((pt) => pt.value),
                manufacturerGuid: manufacturer?.value,
                physicalStateGuid: physicalState?.value,
            };
        });
        // If we already have defaults set from local changes, ignore the server values (which should get corrected on save).
        if (!existingDefaultProductMixTypeGuid) {
            yield put(actions.setDefaultProductMixTypeGuid(defaultProductMixTypeGuid));
        }
        if (!existingDefaultCarrierGuid || existingDefaultCarrierGuid !== defaultCarrierGuid) {
            yield put(actions.setDefaultCarrierGuid(defaultCarrierGuid));
        }

        yield put(actions.setDefaultChemicalTargetRate(Number(defaultTargetRate)));

        yield put(actions.setCustomProducts(customProducts.sort(blending.sortCustomProducts)));
        yield put(actions.setConversionFactors(conversionFactors));
        yield put(actions.setProductBlendPicklists(productBlendPicklists));
        yield put(actions.setAvailableProducts(allAvailableProducts));
        yield call(updateProductFilter, actions.updateProductFilter(null, null));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        yield* updateLoadingStatus();
    }
};

const updateLoadingStatus = function* (isAlreadyLoading = false) {
    const batchRecDetails = yield select(recSelectors.getBatchRecDetailsForEdit);
    const isBatchEditRec = batchRecDetails?.length > 0;
    if (isBatchEditRec) {
        const availableCredits = yield select(selectors.getAvailableCredits);
        const creditedGridCells = yield select(selectors.getCreditedGridCells);
        if (availableCredits && creditedGridCells?.length > 0) {
            yield put(actions.setIsLoading(false));
        }
    } else if (!isAlreadyLoading) {
        yield put(actions.setIsLoading(false));
    }
};

export const updateProductFilter = function* (
    action: ReturnType<typeof actions.updateProductFilter>
) {
    try {
        yield put(actions.setIsLoading(true));
        const { filterProperty, filterValue } = action.payload;
        const existingProductFilters = yield select(selectors.getProductFilters);
        const availableProducts = yield select(selectors.getAvailableProducts);
        const { productParentType, productType, manufacturer, physicalState } = yield select(
            selectors.getProductBlendPicklists
        );
        const productFilters = filterProperty == null ? {} : { [filterProperty]: filterValue };

        const filteredProducts =
            filterProperty == null || filterValue == null
                ? availableProducts
                : availableProducts.filter((product) =>
                      filterProperty !== "productTypeGuid"
                          ? product[filterProperty] === filterValue
                          : product.productTypeGuidList.includes(filterValue)
                  );

        const validProductParentTypeFilters = productFilters.productParentTypeGuid
            ? productParentType
            : productParentType.filter((item) =>
                  filteredProducts
                      .map((product) => product.productParentTypeGuid)
                      .includes(item.value)
              );
        const validProductTypeFilters = productFilters.productTypeGuid
            ? productType
            : productType.filter((item) =>
                  filteredProducts
                      .map((product) => product.productTypeGuidList)
                      .some((p) => p.includes(item.value))
              );
        const validManufacturerFilters = productFilters.manufacturerGuid
            ? manufacturer
            : manufacturer.filter((item) =>
                  filteredProducts.map((product) => product.manufacturerGuid).includes(item.value)
              );
        const validPhysicalStateFilters = productFilters.physicalStateGuid
            ? physicalState
            : physicalState.filter((item) =>
                  filteredProducts.map((product) => product.physicalStateGuid).includes(item.value)
              );

        const filteredProductBlendPicklists = {
            productParentType: Array.from(validProductParentTypeFilters),
            productType: Array.from(validProductTypeFilters),
            manufacturer: Array.from(validManufacturerFilters),
            physicalState: Array.from(validPhysicalStateFilters),
        };

        productFilters.productParentTypeGuid =
            filterProperty === "productParentTypeGuid"
                ? filterValue
                : validProductParentTypeFilters.find(
                      (item) => item.value === existingProductFilters.productParentTypeGuid
                  )
                ? existingProductFilters.productParentTypeGuid
                : null;
        productFilters.productTypeGuid =
            filterProperty === "productTypeGuid"
                ? filterValue
                : validProductTypeFilters.find(
                      (item) => item.value === existingProductFilters.productTypeGuid
                  )
                ? existingProductFilters.productTypeGuid
                : null;
        productFilters.manufacturerGuid =
            filterProperty === "manufacturerGuid"
                ? filterValue
                : validManufacturerFilters.find(
                      (item) => item.value === existingProductFilters.manufacturerGuid
                  )
                ? existingProductFilters.manufacturerGuid
                : null;
        productFilters.physicalStateGuid =
            filterProperty === "physicalStateGuid"
                ? filterValue
                : validPhysicalStateFilters.find(
                      (item) => item.value === existingProductFilters.physicalStateGuid
                  )
                ? existingProductFilters.physicalStateGuid
                : null;

        yield put(actions.setProductFilters(productFilters));
        yield put(actions.setFilteredProductBlendPicklists(filteredProductBlendPicklists));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        yield* updateLoadingStatus();
    }
};

export const saveProductBlend = function* (action) {
    try {
        const { productBlend, callback } = action.payload;
        const userGuid = yield select(getTheUserGuid);
        const response = productBlend.productBlendGuid
            ? yield call(RecAPI.updateProductBlend, userGuid, productBlend)
            : yield call(RecAPI.addProductBlend, userGuid, productBlend);
        if (response && !response.success) {
            const existingBlendGuid = response.model;
            yield put(actions.setProductBlendGuid(existingBlendGuid));
        } else {
            if (callback) {
                callback(response ? response.model : productBlend.productBlendGuid);
            }
            yield put(actions.setProductBlendGuid(null));
            yield put(
                notificationActions.pushToasterMessage(
                    messages.saveBlendSuccessful,
                    MSGTYPE.SUCCESS
                )
            );
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    }
};
export const saveCustomProduct = function* (action) {
    try {
        yield put(actions.setIsLoading(true));
        const { customProduct } = action.payload;
        const userGuid = yield select(getTheUserGuid);
        const existingCustomProductList = yield select(selectors.getCustomProducts);

        const newCustomProduct = {
            ...customProduct,
            customProductGuid:
                customProduct.customProductGuid !== NEW ? customProduct.customProductGuid : null,
        };

        const response = newCustomProduct.customProductGuid
            ? yield call(RecAPI.updateCustomProduct, userGuid, newCustomProduct.customProductGuid)
            : yield call(RecAPI.addCustomProduct, userGuid, newCustomProduct);

        if (response && !response.success) {
            yield put(
                notificationActions.pushToasterMessage(messages.saveBlendFailed, MSGTYPE.ERROR)
            );
        } else {
            const addedCustomProduct = newCustomProduct.customProductGuid
                ? newCustomProduct
                : response.model;
            yield put(actions.setProductBlendGuid(addedCustomProduct.customProductGuid));
            const newCustomProductList = [
                {
                    ...newCustomProduct,
                    ...addedCustomProduct,
                },
                ...existingCustomProductList,
            ];
            yield put(
                actions.setCustomProducts(newCustomProductList.sort(blending.sortCustomProducts))
            );
            yield put(
                notificationActions.pushToasterMessage(
                    messages.saveBlendSuccessful,
                    MSGTYPE.SUCCESS
                )
            );
            return addedCustomProduct.customProductGuid;
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const applyCredits = function* (action) {
    try {
        const { recNutrientGuid, credits } = action.payload;
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const creditList = credits.filter(
            (credit) =>
                credit.flatRate || credit.creditRecGeneralGuid || credit.creditAgEventGeneralGuid
        );
        const response = yield call(RecAPI.addRecCredit, userGuid, {
            recNutrientGuid,
            credits: creditList,
        });
        yield put(actions.setCreditedRecNutrient(response));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const applyBatchCredits = function* (action) {
    const { recNutrientGuidList, credits } = action.payload;
    yield put(actions.setIsLoading(true));
    const newCreditedRecNutrients = [];
    const userGuid = yield select(getTheUserGuid);
    const creditList = credits.filter(
        (credit) =>
            credit.flatRate || credit.creditRecGeneralGuid || credit.creditAgEventGeneralGuid
    );
    for (const recNutrientGuid of recNutrientGuidList) {
        const revisedCredits = [];
        for (const credit of creditList) {
            const cr = {
                ...credit,
                recNutrientGuid,
            };
            revisedCredits.push(cr);
        }
        try {
            const response = yield call(RecAPI.addRecCredit, userGuid, {
                recNutrientGuid,
                credits: revisedCredits,
            });
            newCreditedRecNutrients.push(response);
        } catch (error) {
            yield put(notificationActions.apiCallError(error, action));
        }
    }

    yield put(actions.setBatchCreditedRecNutrients(newCreditedRecNutrients));
    yield put(actions.setIsLoading(false));
};

export const deactivateProductBlend = function* (action) {
    const { productBlendGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(RecAPI.deactivateProductBlend, userGuid, productBlendGuid);
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    }
};

export const fetchAvailableCredits = function* (action) {
    const { recNutrientGuid } = action.payload;
    const isBatch = recNutrientGuid.indexOf("BATCH_REC_NUTRIENT") >= 0;
    const isAlreadyLoading = yield select(selectors.getIsLoading);
    try {
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        // There's not currently support for anything other than flat rate credits in batch edit recs.
        if (isBatch) {
            yield put(
                actions.setAvailableCredits({
                    availableApplicationCredits: [],
                    availableRecCredits: [],
                })
            );
        } else {
            const availableCredits = yield call(CreditAPI.options, recNutrientGuid, userGuid);
            yield put(actions.setAvailableCredits(availableCredits));
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        if (isBatch) {
            const creditedGridCells = yield select(selectors.getCreditedGridCells);
            if (creditedGridCells?.length > 0) {
                yield put(actions.setIsLoading(false));
            }
        } else if (!isAlreadyLoading) {
            yield put(actions.setIsLoading(false));
        }
    }
};

export const fetchCreditedGridCells = function* (action) {
    const isAlreadyLoading = yield select(selectors.getIsLoading);
    try {
        const { recNutrientGuidList } = action.payload;
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const creditedGridCells = [];
        for (const recNutrientGuid of recNutrientGuidList) {
            const cells = yield call(RecAPI.getCreditedGridCells, userGuid, recNutrientGuid);
            creditedGridCells.push(...cells);
        }
        yield put(actions.setCreditedGridCells(creditedGridCells));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        yield* updateLoadingStatus(isAlreadyLoading);
    }
};
export const fetchCustomBlends = function* (action) {
    const isAlreadyLoading = yield select(selectors.getIsLoading);
    try {
        const { productMixTypeGuid, nutrientGuid } = action.payload;
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const customBlends = !nutrientGuid
            ? yield call(RecAPI.getProductBlends, userGuid, productMixTypeGuid)
            : yield call(RecAPI.getFertilizerBlendsByNutrient, userGuid, nutrientGuid);
        yield put(actions.setCustomBlends(customBlends));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        if (!isAlreadyLoading) {
            yield put(actions.setIsLoading(false));
        }
    }
};
export const fetchCustomProducts = function* (action) {
    const isAlreadyLoading = yield select(selectors.getIsLoading);
    const eventRecCustomProductGuids = yield select(selectors.getEventRecCustomProductGuids);
    try {
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const customProducts = yield call(RecAPI.getCustomProducts, userGuid, {
            customProductGuid: "",
            physicalStateGuid: "",
            customProductTypeGuid: "",
            eventRecCustomProductGuids,
        });
        yield put(actions.setCustomProducts(customProducts.sort(blending.sortCustomProducts)));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        if (!isAlreadyLoading) {
            yield put(actions.setIsLoading(false));
        }
    }
};
export const productBlendSaga = function* () {
    yield all([
        takeLatest(mapActions.SET_MAP_READY, fetchProductBlendModalData),
        takeLatest(actions.APPLY_CREDITS, applyCredits),
        takeLatest(actions.APPLY_BATCH_CREDITS, applyBatchCredits),
        takeLatest(actions.DEACTIVATE_PRODUCT_BLEND, deactivateProductBlend),
        takeLatest(actions.INIT_PRODUCT_BLEND_MODAL, fetchProductBlendModalData),
        takeEvery(actions.UPDATE_PRODUCT_FILTER, updateProductFilter),
        takeEvery(actions.SAVE_PRODUCT_BLEND, saveProductBlend),
        takeEvery(actions.SAVE_CUSTOM_PRODUCT, saveCustomProduct),
        takeLatest(actions.FETCH_AVAILABLE_CREDITS, fetchAvailableCredits),
        takeLatest(actions.FETCH_CREDITED_GRID_CELLS, fetchCreditedGridCells),
        takeLatest(actions.FETCH_CUSTOM_BLENDS, fetchCustomBlends),
        takeLatest(actions.FETCH_CUSTOM_PRODUCTS, fetchCustomProducts),
    ]);
};
