import React, {
    Component,
    CSSProperties,
    MouseEvent,
    KeyboardEvent,
    FocusEvent,
    ChangeEvent,
} from "react";
import classnames from "classnames";

import { CloseIcon } from "~/core/icons";

import "./text-input.css";

export interface ITextInputProps {
    autoComplete?: boolean;
    autoFocus?: boolean;
    containerClassNames?: Array<string | Record<string, any>>;
    clearable?: boolean;
    disabled?: boolean;
    errorText?: string;
    hasError?: boolean;
    id?: string;
    inputContainerLeftElements?: Array<JSX.Element>;
    inputContainerRightElements?: Array<JSX.Element>;
    inputCSS?: CSSProperties;
    maxLength?: number;
    onChange?: (value: string, formattedValue?: string, numericValue?: number) => void;
    onClear?: () => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (event?: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
    password?: boolean;
    placeholderText?: string;
    preFixinputContainerRightElements?: boolean;
    readOnly?: boolean;
    required?: boolean;
    showTopLabel?: boolean;
    tabIndex?: number;
    title?: string;
    value?: string;
    visible?: boolean;
}

export interface ITextInputState {
    hasFocus: boolean;
    value: string;
    autoFocus: boolean;
}

export class TextInput<
    TProps extends ITextInputProps = ITextInputProps,
    TState extends ITextInputState = ITextInputState
> extends Component<TProps, TState> {
    private static REQUIRED_TEXT = "*";
    protected title: string;
    protected input: HTMLInputElement;

    public static defaultProps = {
        autoComplete: false,
        autoFocus: false,
        containerClassNames: [],
        clearable: false,
        disabled: false,
        errorText: "",
        hasError: false,
        inputContainerLeftElements: [],
        inputContainerRightElements: [],
        onBlur: (): void => null,
        onClear: (): void => null,
        onFocus: (): void => null,
        onKeyDown: (): void => null,
        maxLength: 256,
        password: false,
        placeholderText: "Text Input",
        readOnly: false,
        required: false,
        showTopLabel: true,
        value: "",
        visible: true,
    };

    constructor(props: TProps) {
        super(props);
        this.state = {
            hasFocus: this.props.autoFocus === true,
            value: props.value,
            autoFocus: props.autoFocus,
        } as TState;
    }

    componentDidMount(): void {
        this.valueToState(this.props.value);
    }

    UNSAFE_componentWillReceiveProps(newProps: TProps): void {
        if (newProps.value !== this.props.value) {
            this.valueToState(newProps.value);
        }
        if (newProps.autoFocus !== this.props.autoFocus) {
            this.setState({
                autoFocus: newProps.autoFocus,
            });
        }
    }

    protected valueToState(value: string): void {
        const stateAndFormattedAreEmpty = value === "" && !this.state.value;
        if (value !== this.state.value && !stateAndFormattedAreEmpty) {
            this.setState({ value }, () => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            });
        }
    }

    protected onChange(event: ChangeEvent<HTMLInputElement>): void {
        this.valueToState(event.target.value);
    }

    protected getTitle(): string {
        return this.props.title;
    }

    #onFocus(event?: FocusEvent<HTMLInputElement>): void {
        if (this.input) {
            this.input.setSelectionRange(0, this.input.value.length);
        }
        event?.persist();
        this.setState({ hasFocus: true }, () => this.props.onFocus(event));
    }

    #onBlur(event?: FocusEvent<HTMLInputElement>): void {
        event?.persist();
        this.setState({ hasFocus: false }, () => {
            this.props.onBlur(event);
        });
    }

    blur(): void {
        if (this.input) {
            this.input.blur();
        } else {
            this.#onBlur();
        }
    }

    focus(): void {
        if (this.input) {
            this.input.focus();
        } else {
            this.#onFocus();
        }
    }

    #getRequiredStarEl(): JSX.Element {
        return (
            <span
                key="required-star"
                className={classnames("required-star", {
                    disabled: this.props.disabled,
                })}
            >
                {TextInput.REQUIRED_TEXT}
            </span>
        );
    }

    #getClearableBtn(): JSX.Element {
        const onClear = (evt: MouseEvent<HTMLSpanElement>): void => {
            evt.preventDefault();
            this.valueToState("");
            this.props.onClear();
        };
        return (
            <span key="clear" className="clear-btn-icon" onMouseDown={onClear}>
                <CloseIcon />
            </span>
        );
    }

    render(): JSX.Element {
        const containerDivClassNames = [
            this.props.containerClassNames,
            "form-input",
            "text-form-input",
            {
                focus: this.state.hasFocus,
                error: this.props.hasError,
            },
        ];

        const containerDivStyle: CSSProperties = {
            visibility: this.props.visible ? "visible" : "hidden",
        };

        const inputContainerClassNames = {
            "input-container": true,
            "required-input": this.props.required && this.state.hasFocus,
            disabled: this.props.disabled,
        };

        // Label Text (Top label, when focused)
        const labelVisible: boolean = Boolean(this.state.value) || this.state.hasFocus;
        const labelText: string = labelVisible ? this.props.placeholderText : "";

        const labelContClassNames = {
            "input-label-container": true,
            "label-visible": labelVisible,
        };

        const labelClassNames = {
            "disabled-text": this.props.disabled,
        };
        const labelStyle: CSSProperties = {
            visibility: labelVisible ? "visible" : "hidden",
        };

        const requiredElementClassNames: string = classnames("red-star", {
            disabled: this.props.disabled,
        });
        const requiredElement: JSX.Element | null =
            labelVisible && this.props.required ? (
                <span className={requiredElementClassNames}>{TextInput.REQUIRED_TEXT}</span>
            ) : null;

        const inputClassNames = {
            "disabled-text": this.props.disabled,
            enabled: !this.props.disabled,
            "hint-visible": labelVisible,
            focus: labelVisible,
        };

        const placeholderText = this.state.hasFocus ? "" : this.props.placeholderText;
        const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
            autoComplete: this.props.autoComplete.toString(),
            autoFocus: this.state.autoFocus,
            className: classnames(inputClassNames),
            disabled: this.props.disabled,
            maxLength: this.props.maxLength,
            onChange: (evt) => this.onChange(evt),
            onKeyDown: (evt) => this.props.onKeyDown(evt),
            onBlur: (evt) => this.#onBlur(evt),
            onFocus: (evt) => this.#onFocus(evt),
            placeholder: placeholderText,
            readOnly: this.props.readOnly,
            title: this.props.password ? "" : this.getTitle() || this.state.value,
            type: this.props.password ? "password" : "text",
            value: this.state.value || "",
        };

        if (this.props.tabIndex) {
            inputProps.tabIndex = this.props.tabIndex;
        }

        const inputContainerLeftElements: JSX.Element[] =
            this.props.required && !(this.state.value || this.state.hasFocus)
                ? [...this.props.inputContainerLeftElements, this.#getRequiredStarEl()]
                : this.props.inputContainerLeftElements;

        const inputContainerRightElements: JSX.Element[] =
            this.props.clearable &&
            !this.props.disabled &&
            this.state.value &&
            this.state.value !== ""
                ? this.props.preFixinputContainerRightElements
                    ? [...this.props.inputContainerRightElements, this.#getClearableBtn()]
                    : [this.#getClearableBtn(), ...this.props.inputContainerRightElements]
                : this.props.inputContainerRightElements;

        return (
            <div className={classnames(containerDivClassNames)} style={containerDivStyle}>
                {this.props.showTopLabel && (
                    <div className={classnames(labelContClassNames)}>
                        {requiredElement}
                        <label className={classnames(labelClassNames)} style={labelStyle}>
                            {labelText + ":"}
                        </label>
                    </div>
                )}
                <div className={classnames(inputContainerClassNames)}>
                    {inputContainerLeftElements}
                    <input
                        {...inputProps}
                        ref={(input) => (this.input = input)}
                        style={this.props.inputCSS}
                    />
                    {inputContainerRightElements}
                </div>
                {this.props.hasError && (
                    <div className="input-label-container">
                        <label className="error-text" title={this.props.errorText}>
                            {this.props.errorText}
                        </label>
                    </div>
                )}
            </div>
        );
    }
}
