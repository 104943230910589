import React, { MouseEvent as ReactMouseEvent } from "react";
import classnames from "classnames";

import { CloseIcon } from "~/core/icons";

import { DialogTool } from "./dialog-tool";

type DialogBoxHeaderProps = {
    className?: string;
    onClose: (e: ReactMouseEvent) => void;
    hideX?: boolean;
    letterIcon?: string;
    title?: string;
    tools?: Node;
    unrestricted?: boolean;
};
const DialogBoxHeader = ({
    onClose,
    hideX,
    letterIcon,
    title,
    tools,
    unrestricted,
}: DialogBoxHeaderProps): JSX.Element => {
    return (
        <div
            className={classnames("dialog-box-title", {
                restricted: !unrestricted,
            })}
        >
            {!letterIcon ? null : <span className="letter-icon">{letterIcon}</span>}
            <div title={title}>{title}</div>
            {tools}
            {hideX ? null : (
                <DialogTool onClick={(e: ReactMouseEvent) => onClose(e)}>
                    <CloseIcon />
                </DialogTool>
            )}
        </div>
    );
};

DialogBoxHeader.defaultProps = {
    hideX: true,
    letterIcon: null,
    title: "",
    unrestricted: false,
};

export default DialogBoxHeader;
