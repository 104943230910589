import { ClassBreak, GeometryUtils, GeometryMath, ZoneGeometryInfo, SearchAPI } from "@ai360/core";

import { OrderedMap } from "immutable";
import Immutable from "immutable";

import * as commonModels from "../model";
import { batchUpdateFieldRecEventMap } from "../reducer";
import * as actions from "./actions";
import * as models from "./model";
import { filterOutEvents } from "./data";

import { ICalibrateEquipmentLoads } from "./models/yield-calibration";
import Polygon from "@arcgis/core/geometry/Polygon";
import Graphic from "@arcgis/core/Graphic";
import { ActionType } from "typesafe-actions";
import { logFirebaseEvent } from "~/utils/firebase";

export interface IEventsState {
    agEventTypeInfoList: models.AgEventTypeInfo[];
    batchFieldGuid: any;
    equipmentGuidToHarvestLoadListMap: Map<string, ICalibrateEquipmentLoads>;
    eventsReadyToMerge: any[];
    latestUpdatedEvent: any[];
    fieldGuidToEventListMap: Immutable.OrderedMap<string, models.AgEventSummary[]>;
    fieldGuidToInactiveEventListMap: Immutable.OrderedMap<string, models.AgEventSummary[]>;
    fieldGuidToEventDetails: Immutable.OrderedMap<string, models.EventDetails>;
    fieldGuidToFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>;
    fieldGuidToInactiveFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>;
    gridMinAreaAcres: number;
    gridMaxAreaAcres: number;
    gridStandardUnitSettings: { areaAcres: number } | { cellWidthFt: number; cellHeightFt: number };
    gridOrientationSettings: {
        rotation: number;
        offsetX: number;
        offsetY: number;
    };
    isBatchCopy: boolean;
    layerNameToSurfaceInfoMap: Map<string, Record<string, any>>;
    samplingFieldPointsPlaced: boolean;
    saveEventDetailsErrorModel: any;
    saveEventDetailsErrorCodeList: number[];
    scoutingPhotoPresignedUrlMap: Map<string, string>;
    selectedSamplePointGuidIdSet: Set<number>;
    soilSampleResults: {
        gridData: Record<string, any>[];
        gridHeader: Record<string, any>[];
    } | null;
    soilSampleResultsOriginal: {
        gridData: Record<string, any>[];
        gridHeader: Record<string, any>[];
    } | null;
    temporaryScoutingPhotoList: string[];
    tissueSampleResults: {
        gridData: Record<string, any>[];
        gridHeader: Record<string, any>[];
    } | null;
    tissueSampleResultsOriginal: {
        gridData: Record<string, any>[];
        gridHeader: Record<string, any>[];
    } | null;
    traceCompleteCount: number;
    traceSites: Graphic[];
    traceTotalCount: number;
    yieldCalibrationOptions: any[];
    yieldCalibrationPassedValidation: boolean;
    yieldCalibrationHasError: boolean;
    yieldCalibrationProgress: Map<string, number>;
}

const newInitialState: IEventsState = Object.freeze({
    agEventTypeInfoList: [],
    batchFieldGuid: "",
    equipmentGuidToHarvestLoadListMap: new Map(),
    eventsReadyToMerge: [],
    latestUpdatedEvent: [],
    fieldGuidToEventListMap: OrderedMap<string, models.AgEventSummary[]>(),
    fieldGuidToInactiveEventListMap: OrderedMap<string, models.AgEventSummary[]>(),
    fieldGuidToEventDetails: OrderedMap<string, models.EventDetails>(),
    fieldGuidToFieldMap: OrderedMap<string, SearchAPI.IFieldResult>(),
    fieldGuidToInactiveFieldMap: OrderedMap<string, SearchAPI.IFieldResult>(),
    gridMinAreaAcres: 0,
    gridMaxAreaAcres: Number.MAX_SAFE_INTEGER,
    gridStandardUnitSettings: { areaAcres: 2.5 },
    gridOrientationSettings: { rotation: 0, offsetX: 0, offsetY: 0 },
    isBatchCopy: false,
    layerNameToSurfaceInfoMap: null,
    samplingFieldPointsPlaced: false,
    saveEventDetailsErrorCodeList: [],
    saveEventDetailsErrorModel: null,
    scoutingPhotoPresignedUrlMap: new Map<string, string>(),
    selectedSamplePointGuidIdSet: new Set<number>(),
    soilSampleResults: null,
    soilSampleResultsOriginal: null,
    temporaryScoutingPhotoList: [],
    tissueSampleResults: null,
    tissueSampleResultsOriginal: null,
    traceCompleteCount: 0,
    traceSites: [],
    traceTotalCount: 0,
    yieldCalibrationOptions: [],
    yieldCalibrationPassedValidation: true,
    yieldCalibrationHasError: false,
    yieldCalibrationProgress: new Map(),
});

export const eventsReducer = (
    state: IEventsState = newInitialState,
    action: ActionType<typeof actions>
): IEventsState => {
    switch (action.type) {
        case actions.SET_EVENT_TYPE_INFO: {
            const { agEventTypeInfoList } = action.payload;
            return Object.freeze({
                ...state,
                agEventTypeInfoList,
            });
        }
        case actions.ADD_AREA: {
            const {
                fieldGuid,
                eventTypeName,
                eventAreaId,
                currentAreaId,
                calculatedArea,
                shape,
            }: {
                fieldGuid: string;
                eventTypeName: string;
                eventAreaId: number;
                currentAreaId: number;
                calculatedArea: number;
                shape: string;
            } = action.payload;
            const eventDetails = state.fieldGuidToEventDetails.get(fieldGuid);
            const agEventTypeInfo = state.agEventTypeInfoList.find(
                (typeInfo) => typeInfo.agEventTransactionTypeName === eventTypeName
            );
            const agEvent = new models.AgEvent(agEventTypeInfo);
            const currentArea = eventDetails.eventAreaList.find(
                (area) => area.eventAreaId === currentAreaId
            );
            const currentAgEventModel = currentArea
                ? currentArea.agEventList[0].agEventModel
                : ({} as any);
            const cropGuid = currentAgEventModel.cropGuid ? currentAgEventModel.cropGuid : "";
            const cropPurposeGuid = currentAgEventModel.cropPurposeGuid
                ? currentAgEventModel.cropPurposeGuid
                : "";
            let agEventList: Array<models.AgEvent>;
            if (cropGuid || cropPurposeGuid) {
                const agEventModel = agEvent.agEventModel.updateAgEventModel({
                    cropGuid: cropGuid,
                    cropPurposeGuid: cropPurposeGuid,
                });
                const agEventWithCrop = models.AgEvent.updateAgEvent(agEvent, {
                    agEventGuid: agEventModel.agEventGuid,
                    agEventModel,
                });
                agEventList = [Object.freeze(agEventWithCrop)];
            } else {
                agEventList = [Object.freeze(agEvent)];
            }

            const agEventAreaPolygon = new models.AgEventAreaPolygon(
                Polygon.fromJSON(JSON.parse(shape))
            );
            const applyEventToArea = agEventList.every(
                (agEvent) => agEvent.agEventModel.isAllRequiredFieldsSet
            );
            const newAgEventArea = new models.AgEventArea(
                [agEventAreaPolygon],
                agEventList,
                calculatedArea,
                applyEventToArea
            );
            newAgEventArea.eventAreaId = eventAreaId;
            const newEventAreaList = eventDetails.eventAreaList.map((area) => area);
            newEventAreaList.push(newAgEventArea);
            const newEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                eventAreaList: newEventAreaList,
            });
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                newEventDetails
            );

            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.ADD_AGGREGATE_EVENT: {
            const { fieldGuid, agEventTransactionTypeGuid, sampleTypeGuid } = action.payload;
            const eventDetails = state.fieldGuidToEventDetails.get(fieldGuid);

            const eventTypeExists =
                eventDetails.agEventTypeList.findIndex(
                    (typeInfo) => typeInfo.agEventTransactionTypeGuid === agEventTransactionTypeGuid
                ) !== -1;
            if (eventTypeExists) {
                return state;
            }

            const agEventTypeInfo = state.agEventTypeInfoList.find(
                (typeInfo) =>
                    typeInfo.agEventTransactionTypeGuid === agEventTransactionTypeGuid &&
                    typeInfo.sampleTypeGuid === sampleTypeGuid
            );
            console.assert(agEventTypeInfo != null);

            const agEventTypeList = [...eventDetails.agEventTypeList, agEventTypeInfo];
            const eventAreaList = eventDetails.eventAreaList.map((agEventArea, idx) => {
                const newAgEvent = new models.AgEvent(agEventTypeInfo);
                const newAgEventList = !eventDetails.isSamplingEvent
                    ? [...agEventArea.agEventList, newAgEvent]
                    : idx === 0
                    ? [newAgEvent]
                    : [];
                const applyEventToArea =
                    (agEventTypeList.length > 0 && agEventTypeList[0].sampleType !== null) ||
                    newAgEventList.some((agEvent) => agEvent.agEventModel.isAllRequiredFieldsSet);
                return models.AgEventArea.updateAgEventArea(agEventArea, {
                    applyEventToArea,
                    agEventList: newAgEventList,
                });
            });

            const newEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                agEventTypeList,
                eventAreaList,
            });
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                newEventDetails
            );
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.ADD_LATEST_UPDATED_EVENT: {
            const { agEventSummary } = action.payload;
            return Object.freeze({
                ...state,
                latestUpdatedEvent: agEventSummary,
            });
        }
        case actions.CACHE_TEMPORARY_SCOUTING_PHOTO_LIST: {
            const { temporaryScoutingPhotoList } = action.payload;
            return Object.freeze({
                ...state,
                temporaryScoutingPhotoList,
            });
        }
        case actions.CLEAR_TEMPORARY_SCOUTING_PHOTO_LIST: {
            return Object.freeze({
                ...state,
                temporaryScoutingPhotoList: [],
            });
        }
        case actions.BATCH_COPY_EVENTS: {
            return Object.freeze({
                ...state,
                isBatchCopy: true,
            });
        }
        case actions.BATCH_COPY_RESET: {
            return Object.freeze({
                ...state,
                isBatchCopy: false,
            });
        }
        case actions.COPY_BATCH_TEMPLATE_TO_EVENTS: {
            const templateEventDetails = state.fieldGuidToEventDetails.get(
                commonModels.BATCH_TEMPLATE_FIELD_GUID
            );
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.withMutations((map) => {
                for (const [fieldGuid, eventDetails] of state.fieldGuidToEventDetails.entries()) {
                    if (fieldGuid === commonModels.BATCH_TEMPLATE_FIELD_GUID) {
                        map.set(fieldGuid, eventDetails);
                        continue;
                    }
                    console.assert(fieldGuid === eventDetails.fieldGuid);
                    const { fieldBoundaryGuid } = eventDetails;
                    if (templateEventDetails.isSamplingEvent) {
                        const eventAreaList = eventDetails.eventAreaList;
                        map.set(
                            fieldGuid,
                            models.EventDetails.updateEventDetails(templateEventDetails, {
                                fieldGuid,
                                fieldBoundaryGuid,
                                eventAreaList,
                            })
                        );
                        continue;
                    }
                    map.set(
                        fieldGuid,
                        models.EventDetails.updateEventDetails(templateEventDetails, {
                            fieldGuid,
                            fieldBoundaryGuid,
                        })
                    );
                }
            });

            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }

        case actions.CLEAR_EVENT_DETAILS: {
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails: state.fieldGuidToEventDetails.clear(),
                samplingFieldPointsPlaced: false,
                saveEventDetailsErrorCodeList: [],
                saveEventDetailsErrorModel: null,
                gridMinAreaAcres: 0,
                gridMaxAreaAcres: Number.MAX_SAFE_INTEGER,
                soilSampleResults: null,
                soilSampleResultsOriginal: null,
                temporaryScoutingPhotoList: [],
                tissueSampleResults: null,
                tissueSampleResultsOriginal: null,
                layerNameToSurfaceInfoMap: null,
                scoutingPhotoPresignedUrlMap: new Map<string, string>(),
                selectedSamplePointGuidIdSet: new Set<number>(),
            });
        }

        case actions.CREATE_NEW_EVENT_DETAILS: {
            const {
                fieldGuidList,
                fieldGuidToBoundaryGuidMap,
                fieldGuidToCustomerGuidMap,
                agEventTransactionTypeName,
                eventAreaList,
            } = action.payload;

            if (fieldGuidList && fieldGuidList.length > 1) {
                switch (agEventTransactionTypeName) {
                    case models.EVENT_TYPE_NAME_APPLICATION:
                        logFirebaseEvent("new_batch_event_application");
                        break;
                    case models.EVENT_TYPE_NAME_HARVEST:
                        logFirebaseEvent("new_batch_event_harvest");
                        break;
                    case models.EVENT_TYPE_NAME_IRRIGATION:
                        logFirebaseEvent("new_batch_event_irrigation");
                        break;
                    case models.EVENT_TYPE_NAME_PLANTING:
                        logFirebaseEvent("new_batch_event_planting");
                        break;
                    case models.EVENT_TYPE_NAME_SAMPLING_SOIL:
                        logFirebaseEvent("new_batch_event_sampling_soil");
                        break;
                    case models.EVENT_TYPE_NAME_SAMPLING_TISSUE:
                        logFirebaseEvent("new_batch_event_sampling_tissue");
                        break;
                    case models.EVENT_TYPE_NAME_TILLAGE:
                        logFirebaseEvent("new_batch_event_tillage");
                        break;
                }
            }
            const agEventTypeInfo = state.agEventTypeInfoList.find(
                (typeInfo) => typeInfo.agEventTransactionTypeName === agEventTransactionTypeName
            );
            const agEventTypeList = agEventTypeInfo ? [agEventTypeInfo] : [];
            const fieldGuidToEventDetails = OrderedMap<string, models.EventDetails>(
                fieldGuidList.map((fieldGuid: string) => {
                    const fieldBoundaryGuid = fieldGuidToBoundaryGuidMap.get(fieldGuid);
                    let eventDetails = Object.freeze(
                        new models.EventDetails(fieldGuid, fieldBoundaryGuid, agEventTypeList)
                    );
                    const customerGuid = fieldGuidToCustomerGuidMap.get(fieldGuid);
                    eventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                        customerGuid,
                    });
                    if (eventAreaList != null) {
                        const { agEventList } = eventDetails.eventAreaList[0];
                        const newEventAreaList = eventAreaList.map(
                            (agEventArea: models.AgEventArea) =>
                                models.AgEventArea.updateAgEventArea(agEventArea, { agEventList })
                        );
                        eventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                            eventAreaList: newEventAreaList,
                        });
                    }
                    return [fieldGuid, eventDetails];
                })
            );
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.DELETE_EVENTS: {
            logFirebaseEvent("delete_selected_events");
            const { agEventGeneralGuidList } = action.payload;
            const removedAgEventGeneralGuidSet = new Set(agEventGeneralGuidList);
            const fieldGuidToEventListMap = state.fieldGuidToEventListMap.withMutations((map) => {
                for (const [fieldGuid, eventSummaryList] of state.fieldGuidToEventListMap) {
                    map.set(
                        fieldGuid,
                        eventSummaryList.filter(
                            (agEventSummary) =>
                                !removedAgEventGeneralGuidSet.has(agEventSummary.agEventGeneralGuid)
                        )
                    );
                }
            });

            return Object.freeze({
                ...state,
                fieldGuidToEventListMap,
            });
        }
        case actions.FETCH_EVENTS_SUCCEEDED: {
            const {
                fieldGuidToEventListMap,
                fieldGuidToInactiveEventListMap,
                fieldGuidToFieldMap,
                fieldGuidToInactiveFieldMap,
            } = action.payload;
            return Object.freeze({
                ...state,
                fieldGuidToEventListMap,
                fieldGuidToInactiveEventListMap,
                fieldGuidToFieldMap,
                fieldGuidToInactiveFieldMap,
            });
        }
        case actions.UPDATE_SELECTED_FIELD_EVENTS: {
            const { fieldGuidToEventListMap, fieldGuidToInactiveEventListMap } = action.payload;
            return Object.freeze({
                ...state,
                fieldGuidToEventListMap,
                fieldGuidToInactiveEventListMap,
            });
        }
        case actions.FETCH_BATCH_EVENT_DETAILS_SUCCEEDED: {
            const { eventDetails }: { eventDetails: models.EventDetails[] } = action.payload;
            const fieldGuidToEventDetails = OrderedMap<string, models.EventDetails>(
                eventDetails.map((eventDetail: models.EventDetails) => {
                    return [eventDetail.fieldGuid, eventDetail];
                })
            );
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.FETCH_EVENT_DETAILS_SUCCEEDED: {
            const { eventDetails }: { eventDetails: models.EventDetails } = action.payload;
            const fieldGuidToEventDetails = OrderedMap([[eventDetails.fieldGuid, eventDetails]]);
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.FETCH_SOIL_SAMPLE_RESULTS: {
            return Object.freeze({
                ...state,
                soilSampleResults: { gridData: [], gridHeader: [] }, // not null enables view-results mode
            });
        }
        case actions.FETCH_SOIL_SAMPLE_RESULTS_SUCCEEDED: {
            const { soilSampleResults } = action.payload;
            return Object.freeze({
                ...state,
                soilSampleResults,
                soilSampleResultsOriginal: soilSampleResults,
            });
        }
        case actions.FETCH_TISSUE_SAMPLE_RESULTS: {
            return Object.freeze({
                ...state,
                tissueSampleResults: { gridData: [], gridHeader: [] }, // not null enables view-results mode
            });
        }
        case actions.FETCH_TISSUE_SAMPLE_RESULTS_SUCCEEDED: {
            const { tissueSampleResults } = action.payload;
            return Object.freeze({
                ...state,
                tissueSampleResults,
                tissueSampleResultsOriginal: tissueSampleResults,
            });
        }
        case actions.FETCH_YIELD_CALIBRATION_COMPLETED: {
            const { results } = action.payload;
            if (results) {
                const { equipmentGuidToHarvestLoadListMap } = state;
                const modifiedEquipmentGuidToHarvestLoadListMap = new Map(
                    equipmentGuidToHarvestLoadListMap
                );

                for (const equipment of results[0]) {
                    const existingEquipment: any = modifiedEquipmentGuidToHarvestLoadListMap.get(
                        equipment.equipmentProfileGuid
                    );
                    if (existingEquipment) {
                        for (const customer of equipment.customerFieldList) {
                            for (const load of customer.loadList) {
                                // now we need to find this load in the existing record and replace it
                                const existingCustomerFieldIndex =
                                    existingEquipment.customerFieldList.findIndex((cf) =>
                                        cf.loadList.some((l) => l.agEventGuid === load.agEventGuid)
                                    );
                                const existingCustomerField =
                                    existingEquipment.customerFieldList[existingCustomerFieldIndex];
                                existingCustomerField.loadList = existingCustomerField.loadList.map(
                                    (l) => {
                                        return l.agEventGuid === load.agEventGuid &&
                                            l.loadName === load.loadName &&
                                            l.eventDate === load.eventDate
                                            ? load
                                            : l;
                                    }
                                );
                                existingEquipment.customerFieldList[existingCustomerFieldIndex] =
                                    existingCustomerField;
                                modifiedEquipmentGuidToHarvestLoadListMap.set(
                                    existingEquipment.equipmentProfileGuid,
                                    existingEquipment
                                );
                            }
                        }
                    } else {
                        modifiedEquipmentGuidToHarvestLoadListMap.set(
                            equipment.equipmentProfileGuid,
                            equipment
                        );
                    }
                }
                const yieldCalibrationOptions = results[1] || state.yieldCalibrationOptions;
                return Object.freeze({
                    ...state,
                    equipmentGuidToHarvestLoadListMap: modifiedEquipmentGuidToHarvestLoadListMap,
                    yieldCalibrationOptions,
                });
            }
            return state;
        }
        case actions.SET_EQUIPMENT_HARVEST_LOAD_MAP: {
            const { equipmentGuidToHarvestLoadListMap } = action.payload;
            return Object.freeze({
                ...state,
                equipmentGuidToHarvestLoadListMap,
            });
        }
        case actions.YIELD_CALIBRATION_COMPLETE: {
            const { failedEvents } = action.payload[0];
            return Object.freeze({
                ...state,
                yieldCalibrationHasError: failedEvents.length > 0,
            });
        }
        case actions.CLEAR_YIELD_CALIBRATION_ERROR: {
            return Object.freeze({
                ...state,
                yieldCalibrationHasError: false,
            });
        }
        case actions.CLEAR_YIELD_CALIBRATION_LOAD_LIST: {
            return Object.freeze({
                ...state,
                equipmentGuidToHarvestLoadListMap: new Map(),
            });
        }
        case actions.REMOVE_AGGREGATE_EVENT: {
            const { fieldGuid, agEventTransactionTypeGuid } = action.payload;
            const eventDetails = state.fieldGuidToEventDetails.get(fieldGuid);

            const agEventTypeList = eventDetails.agEventTypeList.filter(
                (typeInfo) => typeInfo.agEventTransactionTypeGuid !== agEventTransactionTypeGuid
            );
            const eventAreaList = eventDetails.eventAreaList.map((agEventArea) => {
                const newAgEventList = agEventArea.agEventList.filter(
                    (agEvent) => agEvent.agEventTransactionTypeGuid !== agEventTransactionTypeGuid
                );
                const applyEventToArea = newAgEventList.some(
                    (agEvent) => agEvent.agEventModel.isAllRequiredFieldsSet
                );
                return models.AgEventArea.updateAgEventArea(agEventArea, {
                    applyEventToArea,
                    agEventList: newAgEventList,
                });
            });

            const newEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                agEventTypeList,
                eventAreaList,
            });
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                newEventDetails
            );
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.REMOVE_EVENT_GENERAL:
        case actions.REPLACE_EVENT_GENERAL: {
            const { changes, fields } = action.payload;

            return batchUpdateFieldRecEventMap(
                state,
                changes.map((event) => ({
                    fieldGuid: event.fieldGuid,
                    recEventGeneralGuid: event.agEventGeneralGuid,
                    recEventData: event.eventData,
                })),
                fields,
                {
                    keyProp: "agEventGeneralGuid",
                    mapProp: "fieldGuidToEventListMap",
                    model: models.AgEventSummary,
                }
            ) as IEventsState;
        }
        case actions.REMOVE_INACTIVE_EVENT_GENERAL:
        case actions.REPLACE_INACTIVE_EVENT_GENERAL: {
            const { changes, fields } = action.payload;

            return batchUpdateFieldRecEventMap(
                state,
                changes.map((event) => ({
                    fieldGuid: event.fieldGuid,
                    recEventGeneralGuid: event.agEventGeneralGuid,
                    recEventData: event.eventData,
                })),
                fields,
                {
                    keyProp: "agEventGeneralGuid",
                    mapProp: "fieldGuidToInactiveEventListMap",
                    model: models.AgEventSummary,
                }
            ) as IEventsState;
        }
        case actions.SET_EVENT_AREA_IS_INCLUDED: {
            const {
                fieldGuid,
                areaId,
                isIncluded,
            }: {
                fieldGuid: string;
                areaId: actions.agEventAreaIdType;
                isIncluded: boolean;
            } = action.payload;
            const eventDetails = state.fieldGuidToEventDetails.get(fieldGuid);
            if (!eventDetails.isSamplingEvent) {
                console.warn(
                    "SET_EVENT_AREA_IS_INCLUDED action should only be used for inclusion-select"
                );
                return state;
            }
            const eventAreaList = eventDetails.eventAreaList.map((agEventArea) => {
                if (agEventArea.eventAreaId !== areaId) {
                    return agEventArea;
                }
                return models.AgEventArea.updateAgEventArea(agEventArea, {
                    applyEventToArea: isIncluded,
                });
            });

            const newEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                eventAreaList,
            });
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                newEventDetails
            );
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.SET_PASSED_YIELD_CALIBRATION_VALIDATION: {
            let { results } = action.payload;
            if (results == null) {
                results = true;
            }
            return Object.freeze({
                ...state,
                yieldCalibrationPassedValidation: results,
            });
        }
        case actions.RESET_EVENT_AREA_POLYGONS:
        case actions.RESET_CLASSIFIED_EVENT_AREA_POLYGONS: {
            const { fieldGuid, areaIdToPolygonMap, areaIdToClassBreaksMap } = action.payload;
            const eventDetails: models.EventDetails = state.fieldGuidToEventDetails.get(fieldGuid);
            const agEventGeneralGuid: string = eventDetails.agEventGeneralGuid;
            const agEventList: models.AgEvent[] =
                eventDetails.eventAreaList.length > 0
                    ? eventDetails.eventAreaList[0].agEventList
                    : eventDetails.agEventTypeList.map(
                          (agEventTypeInfo) => new models.AgEvent(agEventTypeInfo)
                      );
            const applyEventToArea: boolean =
                eventDetails.eventAreaList.length > 0
                    ? eventDetails.eventAreaList[0].applyEventToArea
                    : false;
            const eventAreaList: models.AgEventArea[] = [...areaIdToPolygonMap.entries()].map(
                ([newEventAreaId, newAreaPolygon], idx) => {
                    const calculatedArea =
                        newAreaPolygon != null
                            ? GeometryUtils.calculateArea(newAreaPolygon.shape)
                            : null;
                    const agEventArea: models.AgEventArea =
                        eventDetails.eventAreaList.find(
                            (ea) => ea.eventAreaId === newEventAreaId
                        ) ||
                        new models.AgEventArea(null, agEventList, calculatedArea, applyEventToArea);
                    const eventAreaPolygons =
                        newAreaPolygon != null
                            ? GeometryMath.getSimplifiedPolygonParts(newAreaPolygon.shape).map(
                                  (poly: Polygon) => new models.AgEventAreaPolygon(poly)
                              )
                            : null;
                    const eventAreaClassBreak: ClassBreak =
                        eventAreaPolygons != null && areaIdToClassBreaksMap
                            ? areaIdToClassBreaksMap.get(newEventAreaId)
                            : null;
                    return models.AgEventArea.updateAgEventArea(agEventArea, {
                        agEventList:
                            idx > 0 && eventDetails.isSamplingEvent
                                ? []
                                : agEventList.map((agEvent) =>
                                      models.AgEvent.updateAgEvent(agEvent, {
                                          agEventGuid: idx > 0 ? null : agEvent.agEventGuid,
                                          agEventModel: agEvent.agEventModel.updateAgEventModel({
                                              agEventGuid: idx > 0 ? null : agEvent.agEventGuid,
                                          }),
                                      })
                                  ),
                        calculatedArea,
                        eventAreaId: newEventAreaId,
                        zonePolygons: eventAreaPolygons,
                        eventAreaClassBreak,
                        fieldBoundaryGuid:
                            newAreaPolygon != null ? newAreaPolygon.fieldBoundaryGuid : null,
                        applyEventToArea,
                        agEventGeneralGuid,
                    });
                }
            );

            const updatedEventDetails: models.EventDetails = models.EventDetails.updateEventDetails(
                eventDetails,
                {
                    eventAreaList,
                }
            );
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                updatedEventDetails
            );

            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.SAVE_EVENT_DETAILS: {
            logFirebaseEvent("event_save");
            return Object.freeze({
                ...state,
                saveEventDetailsErrorCodeList: [],
                saveEventDetailsErrorModel: null,
            });
        }
        case actions.SAVE_EVENT_DETAILS_FAILED: {
            const { errorCodeList, errorModel } = action.payload;
            return Object.freeze({
                ...state,
                saveEventDetailsErrorCodeList: errorCodeList,
                saveEventDetailsErrorModel: errorModel,
            });
        }
        case actions.SET_GRID_MIN_MAX_AREA: {
            const { minAreaAcres, maxAreaAcres } = action.payload;
            const round = (val, precision) => {
                const factor = Math.pow(10, precision);
                return Math.round(val * factor) / factor;
            };
            return Object.freeze({
                ...state,
                gridMinAreaAcres: round(minAreaAcres, 3),
                gridMaxAreaAcres: round(maxAreaAcres, 3),
            });
        }
        case actions.SET_GRID_ORIENTATION_SETTINGS: {
            const { settings } = action.payload;
            return Object.freeze({
                ...state,
                gridOrientationSettings: settings,
            });
        }
        case actions.SET_GRID_STANDARD_UNIT_SETTINGS: {
            const { settings } = action.payload;
            return Object.freeze({
                ...state,
                gridStandardUnitSettings: settings,
            });
        }
        case actions.SET_LAST_EVENT_BATCH_FIELD_GUID: {
            const { batchFieldGuid } = action.payload;
            return Object.freeze({
                ...state,
                batchFieldGuid,
            });
        }
        case actions.SET_LAYER_NAME_TO_SURFACE_INFO_MAP: {
            const { layerNameToSurfaceInfoMap } = action.payload;
            return Object.freeze({ ...state, layerNameToSurfaceInfoMap });
        }
        case actions.SET_SAMPLING_FIELD_POINTS_PLACED: {
            const { isPlaced } = action.payload;
            return Object.freeze({
                ...state,
                samplingFieldPointsPlaced: isPlaced,
            });
        }
        case actions.SET_SCOUTING_PHOTO_PRESIGNED_URL_MAP: {
            const { scoutingPhotoPresignedUrlMap } = action.payload;
            return Object.freeze({
                ...state,
                scoutingPhotoPresignedUrlMap,
            });
        }
        case actions.SET_SELECTED_SAMPLE_POINTS: {
            const { selectedSamplePointGuidIdSet } = action.payload;
            return Object.freeze({ ...state, selectedSamplePointGuidIdSet });
        }
        case actions.SET_TRACE_COUNTS: {
            const { traceCompleteCount, traceTotalCount } = action.payload;
            return Object.freeze({
                ...state,
                traceCompleteCount,
                traceTotalCount,
            });
        }
        case actions.SET_TRACE_SITES: {
            const { traceSites } = action.payload;
            return Object.freeze({
                ...state,
                traceSites,
            });
        }
        case actions.SET_EVENT_MODEL: {
            const { fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps } =
                action.payload;
            const eventDetails = state.fieldGuidToEventDetails.get(fieldGuid);
            const agEventAreaIdx = eventDetails.eventAreaList.findIndex(
                (agEventArea) => agEventArea.eventAreaId === agEventAreaId
            );
            const agEventArea = eventDetails.eventAreaList[agEventAreaIdx];
            const agEventIdx = agEventArea.agEventList.findIndex(
                (agEvent) => agEvent.agEventTransactionTypeGuid === agEventTransactionTypeGuid
            );
            const agEvent = agEventArea.agEventList[agEventIdx];

            const agEventModel = agEvent.agEventModel.updateAgEventModel(newProps);
            const newAgEvent = models.AgEvent.updateAgEvent(agEventArea.agEventList[agEventIdx], {
                agEventGuid: agEventModel.agEventGuid,
                agEventModel,
            });

            const newAgEventList = [...agEventArea.agEventList];
            newAgEventList.splice(agEventIdx, 1, newAgEvent);
            const applyEventToArea: boolean = eventDetails.isSamplingEvent
                ? agEventArea.applyEventToArea
                : newAgEventList.some((agEvent) => agEvent.agEventModel.isAllRequiredFieldsSet);

            const newAgEventArea = models.AgEventArea.updateAgEventArea(agEventArea, {
                applyEventToArea,
                agEventList: newAgEventList,
            });

            const eventAreaList = [...eventDetails.eventAreaList];
            eventAreaList.splice(agEventAreaIdx, 1, newAgEventArea);

            const updatedEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                eventAreaList,
            });
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                updatedEventDetails
            );

            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.UPDATE_EVENT_AREA_POLYGONS: {
            const {
                fieldGuid,
                areaIdToNewAreaIdPolygonMap,
            }: {
                fieldGuid: string;
                areaIdToNewAreaIdPolygonMap: Map<number, Map<number, ZoneGeometryInfo>>;
            } = action.payload;
            const eventDetails = state.fieldGuidToEventDetails.get(fieldGuid);
            const replacedAreaIdSet = new Set(areaIdToNewAreaIdPolygonMap.keys());
            const eventAreaList = eventDetails.eventAreaList.filter(
                (agEventArea) => !replacedAreaIdSet.has(agEventArea.eventAreaId)
            );
            for (const [
                replaceAreaId,
                newAreaIdToPolygonMap,
            ] of areaIdToNewAreaIdPolygonMap.entries()) {
                const replaceArea = eventDetails.eventAreaList.find(
                    (agEventArea) => agEventArea.eventAreaId === replaceAreaId
                );
                if (replaceArea == null) {
                    throw new Error("invalid `eventAreaId` key in `areaIdToNewAreaIdPolygonMap`");
                }
                const newAgEventAreas = [...newAreaIdToPolygonMap.entries()].map(
                    ([newAreaId, newAreaPolygon], index) => {
                        const isNewZone = index > 0;
                        const calculatedArea =
                            newAreaPolygon != null
                                ? GeometryUtils.calculateArea(newAreaPolygon.shape)
                                : null;
                        const newEventAreaPolygons =
                            newAreaPolygon != null
                                ? GeometryMath.getSimplifiedPolygonParts(newAreaPolygon.shape).map(
                                      (poly: Polygon) => new models.AgEventAreaPolygon(poly)
                                  )
                                : null;
                        // reset the class breaks when setting the polygon to null
                        const eventAreaClassBreak =
                            newAreaPolygon != null ? replaceArea.eventAreaClassBreak : null;
                        // don't clone the `agEventList` to new zones when it's a sample event
                        const agEventList = !eventDetails.isSamplingEvent
                            ? replaceArea.agEventList
                            : [];
                        return models.AgEventArea.updateAgEventArea(replaceArea, {
                            agEventList: agEventList.map((agEvent) =>
                                models.AgEvent.updateAgEvent(agEvent, {
                                    agEventGuid: isNewZone ? null : agEvent.agEventGuid,
                                    agEventModel: agEvent.agEventModel.updateAgEventModel({
                                        agEventGuid: isNewZone ? null : agEvent.agEventGuid,
                                    }),
                                })
                            ),
                            calculatedArea,
                            eventAreaGuid:
                                replaceArea.eventAreaGuid && !isNewZone
                                    ? replaceArea.eventAreaGuid
                                    : "",
                            eventAreaClassBreak,
                            eventAreaId: newAreaId,
                            fieldBoundaryGuid:
                                newAreaPolygon != null ? newAreaPolygon.fieldBoundaryGuid : null,
                            zonePolygons: newEventAreaPolygons,
                        });
                    }
                );
                if ((window as any).process_env.NODE_ENV !== "production") {
                    const areaIdCountMap = new Map();
                    for (const { eventAreaId } of newAgEventAreas) {
                        const curCount = areaIdCountMap.has(eventAreaId)
                            ? areaIdCountMap.get(eventAreaId)
                            : 0;
                        areaIdCountMap.set(eventAreaId, curCount + 1);
                    }
                    if ([...areaIdCountMap.values()].some((count) => count !== 1)) {
                        throw new Error("invalid `areaIdToNewAreaIdPolygonMap`");
                    }
                }
                eventAreaList.push(...newAgEventAreas);
            }

            if (eventDetails.isSamplingEvent) {
                // Sort by eventAreaId, as the sampling event only has 1 AgEvent, which must be in idx === 0
                eventAreaList.sort((larea, rarea) =>
                    larea.eventAreaId < rarea.eventAreaId
                        ? -1
                        : larea.eventAreaId === rarea.eventAreaId
                        ? 0
                        : 1
                );
                const areaWithEventIdx = eventAreaList.findIndex(
                    (area) => area.agEventList.length !== 0
                );
                if (areaWithEventIdx > 0) {
                    const { agEventList } = eventAreaList[areaWithEventIdx];
                    eventAreaList[0] = models.AgEventArea.updateAgEventArea(eventAreaList[0], {
                        agEventList,
                    });
                    eventAreaList[areaWithEventIdx] = models.AgEventArea.updateAgEventArea(
                        eventAreaList[areaWithEventIdx],
                        { agEventList: [] }
                    );
                } else if (areaWithEventIdx === -1) {
                    const { agEventList } = eventDetails.eventAreaList[0];
                    eventAreaList[0] = models.AgEventArea.updateAgEventArea(eventAreaList[0], {
                        agEventList,
                    });
                }
                console.assert(eventAreaList[0].agEventList.length === 1);
                console.assert(
                    eventAreaList.slice(1).every((area) => area.agEventList.length === 0)
                );
            }
            const updatedEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                eventAreaList,
            });
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                updatedEventDetails
            );

            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.UPDATE_EVENT_DETAILS: {
            const { fieldGuid, newProps } = action.payload;
            const eventDetails = models.EventDetails.updateEventDetails(
                state.fieldGuidToEventDetails.get(fieldGuid),
                newProps
            );
            const fieldGuidToEventDetails = state.fieldGuidToEventDetails.set(
                fieldGuid,
                eventDetails
            );
            return Object.freeze({
                ...state,
                fieldGuidToEventDetails,
            });
        }
        case actions.UPDATE_EVENT_SUMMARY_ITEM: {
            const { fieldGuid, agEventGeneralGuid, newProps } = action.payload;
            const eventSummaryList = state.fieldGuidToEventListMap.get(fieldGuid);
            if (eventSummaryList == null) {
                return state;
            }
            const eventSummaryIdx = eventSummaryList.findIndex(
                (summary) => summary.agEventGeneralGuid === agEventGeneralGuid
            );
            if (eventSummaryIdx === -1) {
                return state;
            }
            const updatedEventSummary = eventSummaryList[eventSummaryIdx].updateProps(newProps);
            const fieldGuidToEventListMap = state.fieldGuidToEventListMap.set(fieldGuid, [
                ...eventSummaryList.slice(0, eventSummaryIdx),
                updatedEventSummary,
                ...eventSummaryList.slice(eventSummaryIdx + 1),
            ]);
            return Object.freeze({
                ...state,
                fieldGuidToEventListMap,
            });
        }
        case actions.UPDATE_SOIL_SAMPLE_RESULTS: {
            const { soilSampleResults } = action.payload;
            return Object.freeze({
                ...state,
                soilSampleResults,
            });
        }
        case actions.UPDATE_TISSUE_SAMPLE_RESULTS: {
            const { tissueSampleResults } = action.payload;
            return Object.freeze({
                ...state,
                tissueSampleResults,
            });
        }
        case actions.DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID: {
            const { agEventGeneralGuids } = action.payload;

            const filteredFieldGuidToEventListMap = filterOutEvents(
                state.fieldGuidToEventListMap,
                agEventGeneralGuids
            );
            const filteredFieldGuidToInactiveEventListMap = filterOutEvents(
                state.fieldGuidToInactiveEventListMap,
                agEventGeneralGuids
            );

            return Object.freeze({
                ...state,
                fieldGuidToEventListMap: filteredFieldGuidToEventListMap,
                fieldGuidToInactiveEventListMap: filteredFieldGuidToInactiveEventListMap,
            });
        }
        default:
            return state;
    }
};
