import React from "react";
import "./title-bar.css";

interface ITitleBarProps {
    acres: any;
    customerName: any;
    featureName: any;
}

export const TitleBar = (props: ITitleBarProps) => {
    const { featureName, customerName, acres } = props;

    return (
        <div className="non-field-feature-information-title-bar title-bar">
            <div className="first-line">{featureName}</div>
            <div className="second-line">{customerName}</div>
            <div className="third-line">{acres.toFixed(2)} acres</div>
        </div>
    );
};
