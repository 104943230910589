import { AgEventAPI } from "@ai360/core";
import { IAgEventModelMethods, AreaDimension, PointPlacement } from "../model";

export class SampleSetup implements AgEventAPI.ISampleSetup, IAgEventModelMethods {
    agEventGuid = "";
    sampleMethodGuid = "";
    areaDimension: AreaDimension.AREA | AreaDimension.DIMENSION | "" = "";
    height: number | null = null;
    width: number | null = null;
    gridSize: number | null = null;
    gridUnitGuid = "";
    pointPlacement: PointPlacement.CENTROID | PointPlacement.RANDOM | "" = "";
    eventId = "";
    samplePoints: AgEventAPI.ISamplePoint[] = [];
    depthIAGuid = "";
    rotation = 0;
    offsetX = 0;
    offsetY = 0;
    interpolationTypeId = 0;
    singleSampleIdPerZone = false;
    batchSamplingEventGuid: string = null;
    // Tissue Only
    cropGuid: string = null;
    cropPurposeGuid: string = null;

    constructor(public sampleTypeGuid: string) {}

    get isAllRequiredFieldsSet(): boolean {
        return (
            this.sampleMethodGuid &&
            this.sampleMethodGuid !== "" &&
            this.samplePoints != null &&
            this.samplePoints.length > 0 &&
            ((this.samplePoints[0] as AgEventAPI.ISoilSamplePoint).soilSamplePointGuid !==
                undefined ||
                Boolean(
                    this.cropGuid &&
                        this.samplePoints.every(
                            (p) => (p as AgEventAPI.ITissueSamplePoint).cropGrowthStageGuid
                        )
                ))
        );
    }

    get isAnyRequiredFieldSet(): boolean {
        return (
            this.sampleMethodGuid !== "" ||
            (this.samplePoints != null &&
                this.samplePoints.length > 0 &&
                ((this.samplePoints[0] as AgEventAPI.ISoilSamplePoint).soilSamplePointGuid !==
                    undefined ||
                    Boolean(
                        this.cropGuid &&
                            this.samplePoints.every(
                                (p) => (p as AgEventAPI.ITissueSamplePoint).cropGrowthStageGuid
                            )
                    )))
        );
    }

    reset(): SampleSetup {
        // `reset` isn't ever called for this event type currently
        const rv = new SampleSetup(this.sampleTypeGuid);
        rv.sampleMethodGuid = this.sampleMethodGuid;
        rv.gridUnitGuid = this.gridUnitGuid;
        rv.eventId = this.eventId;
        rv.depthIAGuid = this.depthIAGuid;
        return rv;
    }

    updateAgEventModel(newProps: Partial<SampleSetup>): SampleSetup {
        return Object.assign(new SampleSetup(this.sampleTypeGuid), this, newProps);
    }
}
