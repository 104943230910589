import React, { Component } from "react";

import classnames from "classnames";
import { v4 as uuid } from "uuid";
import Immutable from "immutable";
import { config as intlConfig } from "~/intl-provider/config";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import type MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import type Geometry from "@arcgis/core/geometry/Geometry";
import Polygon from "@arcgis/core/geometry/Polygon";
import type Collection from "@arcgis/core/core/Collection";

// workaround for unknown props that will not allow unknow / any
interface INextProps {
    [x: string]: any;
}
interface IAltFeatures {
    [x: string]: any;
}

import {
    Button,
    DialogBox,
    DialogBoxFooterType,
    Toolbar,
    ToolbarButton,
    ToolbarTool,
} from "~/core";
import { FileImportUtils } from "~/file-import";
import {
    AgEventAPI,
    AppHelpers,
    FieldAPI,
    FileImportAPI,
    IZone,
    NonFieldFeatureAPI,
    Request,
} from "@ai360/core";
import {
    BaseLayer,
    FieldBoundaryImportLayer,
    FieldsLayer,
    HistoryManager,
    IHandler,
    ImportDataConvexHullLayer,
    IToolbar,
    ZoneClassificationUtils,
    Tool,
    Toolset,
    ClassBreak,
    Confirm,
    Cursor,
    GridOrientationSettings,
    ImportFieldData,
    Inset,
    IFormatter,
    MessageDescriptor,
    MessageFormatPrimitiveValue,
    SamplingAction,
    ToastType,
    ZoneGeometryInfo,
    Unit,
    IEventDetails,
} from "@ai360/core";

import { models as recsEventsModels, eventsModels, actions } from "~/recs-events";
import { AreaIdType } from "~/recs-events/reducer";
import { messages as recsEventsMessages } from "~/recs-events";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";
import { messages } from "../i18n-messages";
import { IBufferSetupDialogProps } from "../toolbars/dialog-boxes/buffer-setup-modal";
import { ImportZonesModal } from "../toolbars/dialog-boxes/import-zones-modal";
import { LoadZonesModal } from "../toolbars/dialog-boxes/load-zones-modal";
import { DrawToolSet } from "../toolbars/draw-tools.4x";
import { NonFieldFeatureDrawTools } from "../toolbars/non-field-feature-draw-tools/non-field-feature-draw-tools.4x";
import { ExtentToolSet } from "../toolbars/extent-tools.4x";
import { MeasureToolSet } from "../toolbars/measure-tools.4x";
import { ProcessCountTools } from "../toolbars/process-count-tools.4x";
import { SearchToolSet } from "../toolbars/search-tools.4x";
import { GridPointPlacementMethod, SamplingToolSet } from "../toolbars/sampling-tools.4x";
import { ScoutingZoneToolSet } from "../toolbars/scouting-zone-tools.4x";
import { ZoneToolSet } from "../toolbars/zone-tools.4x";

import { ANALYSIS_INFO_NAME_MANAGEMENT_AREA } from "~/recs-events/analysis/model";

import "./map-tools.css";
import "./dijits.css";
import { ToggleEvent } from "~/core/components/toolbar/toolbar-tool";
import { logFirebaseEvent } from "~/utils/firebase";

export interface IField {
    fieldGuid: string;
    fieldBoundaryGuid: string;
    customerGuid: string;
}

export type ToolsetPayload = {
    attributes?;
    auto?: boolean;
    customerGuid?: string;
    fieldGuid?: string;
    feature?: NonFieldFeatureAPI.NonFieldFeature;
    field: IField;
    fields?: FieldAPI.IFieldInfo[];
    isMerge?: boolean;
    isTissue?: boolean;
    existingPoints?:
        | AgEventAPI.ISamplePoint[]
        | AgEventAPI.ISoilSamplePoint[]
        | AgEventAPI.ITissueSamplePoint[];
    recEventDetails: IEventDetails;
    samplePoints?: AgEventAPI.ISamplePoint[];
};
export interface HaasMapView extends MapView {
    isReady: boolean;
}
export interface IMapToolsProps {
    eventGuidToEventSummaryMap: Map<string, eventsModels.AgEventSummary>;
    actionPanelIsExpanded: boolean;
    activeMapTool: string;
    activeToolset: Toolset;
    areaIdToHasDataMap?: Map<number, boolean>;
    areaUnits: Unit[];
    batchReset: boolean;
    bufferOptions: IBufferSetupDialogProps;
    bufferReady: boolean;
    copyZonesFromToMap?: Map<number, Set<number>>;
    currentAreaId?: number;
    currentFieldEditGeometries: Geometry[];
    currentProcessCounts: Map<string, Immutable.Set<string>>;
    fieldModel: Record<string, any>;
    getFieldBoundaryImportLayer(layers: Map<string, BaseLayer>): FieldBoundaryImportLayer;
    getFieldsLayer(layers: Map<string, BaseLayer>): FieldsLayer;
    getImportDataConvexHullLayer(layers: Map<string, BaseLayer>): ImportDataConvexHullLayer;
    gridRemoveFlag: boolean;
    importFieldList: ImportFieldData[];
    intl: IFormatter;
    isEquationRec: boolean;
    isEquationRecProcessing: boolean;
    isFromEquationRec: boolean;
    isImportedEventRec: boolean;
    isNewEventRec: boolean;
    isProcessing: boolean;
    lengthUnits: Unit[];
    map: MapView;
    onAddZone(
        fieldGuid: string,
        eventTypeName: string,
        eventAreaId: number,
        currentAreaId: number,
        calculatedArea: number,
        shape: string
    ): void;
    onClearRemoveGridFlag(): void;
    onClearTraceFlags(): void;
    onPushToasterMessage(
        msg: MessageDescriptor | string,
        type?: ToastType,
        values?: Record<string, MessageFormatPrimitiveValue>,
        autoClose?: boolean
    ): void;
    onPushToasterMessageDirect(message: any, type: any, autoClose: boolean): void;
    onResetClassifiedZones(
        fieldGuid: string,
        zonesMap: Map<string, ZoneGeometryInfo>,
        classBreaksMap: Map<number, ClassBreak>
    ): void;
    onResetZones(fieldGuid: string, zonesMap: Map<string, ZoneGeometryInfo>): void;
    onRestoreAreaList(fieldGuid: string, restoreId: string): void;
    onSaveAreaList(fieldGuid: string, restoreId: string): void;
    onSetActiveMapTool(tool: Tool): void;
    onSetActiveToolsetOnly(toolset: Toolset): void;
    onSetActiveZone(fieldGuid: string, areaId: string): void;
    onSetAreaIsIncluded(
        fieldGuid: string,
        areaId: actions.agEventAreaIdType,
        isIncluded: boolean
    ): void;
    onSetBatchReset(batchReset: boolean): void;
    onSetBufferOptions(options: Partial<IBufferSetupDialogProps>): void;
    onSetBufferReady(ready: boolean): void;
    onSetCanClip(canClip: boolean): void;
    onSetCanClipPending(pending: boolean): void;
    onSetCopyToAreaIdSet(areaIdSet: Set<AreaIdType>): void;
    onSetFieldBoundaryImportFileGuid(importFileGuid: string): void;
    onSetFieldEditGeometries(geometries: Geometry[]): void;
    onSetMapIsLoading(loading: boolean): void;
    onSetMinMaxGridCell(): void;
    onSetGridOrientationSettings(): void;
    onSetNonFieldFeatureInformationFeatureGeometries(
        geometries: NonFieldFeatureAPI.NonFieldFeature[]
    ): void;
    onSetSamplePoints(samplePoints: AgEventAPI.ISamplePoint[]): void;
    onSetSelectedSamplePoints(guidSet: Set<string>): void;
    onSetToolsProcessing(processing: boolean): void;
    onSetTraceCounts(traceCompleteCount: number, traceTotalCount: number): void;
    onSetTraceSites(traceSites: Graphic[]): void;
    onSetVisibleToolbarMenuDimensions(inset: Inset): void;
    onSetZoneFileGuid(fieldGuid: string, fileGuid: string): void;
    onSetZonesLoading: (loading: boolean) => void;
    onUpdateAnalysisZones(
        allZones: Graphic[],
        fieldBoundaryGuid?: string,
        zonesMap?: Map<string, Map<string, ZoneGeometryInfo>>
    ): void;
    onUpdateZones(fieldGuid: string, zonesMap: Map<string, Map<string, ZoneGeometryInfo>>): void;
    recEventDetails?: IEventDetails;
    samplingGridSettings: Record<string, any>;
    gridOrientationSettings: GridOrientationSettings;
    samplingEvent: AgEventAPI.ISampleSetup;
    samplingViewOnly?: boolean;
    selectedSites: Set<string>;
    setZoomToNonFieldFeatures(features: NonFieldFeatureAPI.NonFieldFeature[]): void;
    setZoomToField(selectedValue: string): void;
    toolsetDisabled: boolean;
    toolsetPayload: ToolsetPayload;
    traceApplyFlag: boolean;
    traceResetFlag: boolean;
    updateFieldModel(fieldModel: Record<string, any>): void;
    userGuid: string;
    userInfo: { role: { cluBoundaries: boolean } };
    visibleToolbarMenuDimensions: Inset;
    zoom: number;
}
export interface IMapToolsState {
    confirmationText: string;
    confirmationTitle: string;
    confirmationType: Confirm;
    fieldBoundaryGuid: string;
    importClassificationFields: string[];
    importGeometries: Polygon[];
    importNeedsClip: boolean;
    importZoneFileGuid: string;
    importZoneGeometries: Graphic[];
    isConfirmationModalOpen: boolean;
    isImportZonesModalOpen: boolean;
    isLoadZonesModalOpen: boolean;
    isMessageModalOpen: boolean;
    isUploadingFiles: boolean;
    message: string;
    previousConfirmationType: Confirm;
    previousGeometries: any[];
    title: string;
}
export class MapTools extends Component<IMapToolsProps, IMapToolsState> {
    adjustingSamplingGrid: boolean;
    cluBoundary: ToolbarTool;
    #cluBoundaryClickEvent: IHandler;
    drawTools: DrawToolSet;
    extentTools: ExtentToolSet;
    #fileInput: HTMLInputElement;
    isTraceApplied: boolean;
    isTracing: boolean;
    #keyup: IHandle;
    measureTools: MeasureToolSet;
    nonFieldFeatureDrawTools: NonFieldFeatureDrawTools;
    primaryToolbar: Toolbar;
    processCountTools: ProcessCountTools;
    samplingRestoreId: RegExp;
    samplingTools: SamplingToolSet;
    samplingHistory: HistoryManager = new HistoryManager();
    scoutingZoneTools: ScoutingZoneToolSet;
    searchTools: SearchToolSet;
    statusToolbar: Toolbar;
    toolbars: IToolbar[];
    zoneRestoreId: RegExp;
    zoneTools: ZoneToolSet;

    private traceSites: Graphic[] = [];

    constructor(props: IMapToolsProps) {
        super(props);
        this.state = {
            confirmationText: "",
            confirmationTitle: "",
            confirmationType: Confirm.NONE,
            fieldBoundaryGuid: null,
            importClassificationFields: null,
            importZoneFileGuid: null,
            isImportZonesModalOpen: false,
            importGeometries: null,
            importNeedsClip: false,
            importZoneGeometries: null,
            isConfirmationModalOpen: false,
            isLoadZonesModalOpen: false,
            isMessageModalOpen: false,
            isUploadingFiles: false,
            message: "",
            previousConfirmationType: Confirm.NONE,
            previousGeometries: [],
            title: "",
        };
    }

    _applyTrace(): void {
        this.traceSites = [];
        const traceSites: Graphic[] =
            this.samplingTools.traceSites instanceof Array
                ? this.samplingTools.traceSites
                : (this.samplingTools.traceSites as Collection<Graphic>).toArray();
        this.traceSites = this.samplingTools._cloneGraphics(traceSites) as Graphic[];
        this.props.onSetTraceSites(this.traceSites.length > 0 ? this.traceSites : []);
        this.props.onClearTraceFlags();
        this.props.onSetActiveToolsetOnly(Toolset.SAMPLING);
        this.samplingTools.stopTrace();
        this._clearTrace();
    }

    _cancelTrace(): void {
        if (this.traceSites?.length > 0) {
            this.samplingTools._updateTraceSites(this.traceSites);
            this.samplingTools._resetGraphics();
            this.samplingTools.stopTrace();
            this.traceSites = [];
        } else {
            this.samplingTools.cancelTrace(this.isTraceApplied);
            this.isTraceApplied = false;
        }
        this._clearTrace();
    }

    _canRedo(toolset: Toolset): boolean {
        switch (toolset) {
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                return this.drawTools.canRedo();
            case Toolset.DRAW_NON_FIELD_FEATURE:
                return this.nonFieldFeatureDrawTools.canRedo();
            case Toolset.SAMPLING:
                return this.samplingHistory.canRedo();
            case Toolset.SAMPLING_GRID:
                return this.samplingTools.canRedoGrid();
            case Toolset.SAMPLING_TRACE:
                return this.samplingTools.canRedoTrace();
            case Toolset.ANALYSIS:
            case Toolset.ZONE_EDIT:
                return this.zoneTools.canRedo();
            case Toolset.SCOUTING_ZONE_EDIT:
                return this.scoutingZoneTools.canRedo();
            default:
                break;
        }
        return false;
    }

    _canUndo(toolset: Toolset): boolean {
        switch (toolset) {
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                return this.drawTools.canUndo();
            case Toolset.DRAW_NON_FIELD_FEATURE:
                return this.nonFieldFeatureDrawTools.canUndo();
            case Toolset.SAMPLING:
                return this.samplingHistory.canUndo() && this.samplingHistory.getIndex() > 1;
            case Toolset.SAMPLING_GRID:
                return this.samplingTools.canUndoGrid();
            case Toolset.SAMPLING_TRACE:
                return this.samplingTools.canUndoTrace();
            case Toolset.ANALYSIS:
            case Toolset.ZONE_EDIT:
                return this.zoneTools.canUndo();
            case Toolset.SCOUTING_ZONE_EDIT:
                return this.scoutingZoneTools.canUndo();
            default:
                break;
        }
        return false;
    }

    _changeToolset(fromToolset: Toolset, toToolset: Toolset, nextProps: IMapToolsProps): Toolset {
        const invalidTransition = () => {
            throw new Error(`Invalid Map Toolset Transtion (${fromToolset} to ${toToolset})`);
        };

        switch (fromToolset) {
            case Toolset.DEFAULT:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this._setActiveMapTool(null);
                        return;
                    case Toolset.ANALYSIS: {
                        const { toolsetPayload } = nextProps;
                        const isManagementArea =
                            toolsetPayload.recEventDetails?.type ===
                            ANALYSIS_INFO_NAME_MANAGEMENT_AREA;
                        this._updateZoneTools(
                            toolsetPayload,
                            null,
                            null,
                            !isManagementArea,
                            !isManagementArea
                        );
                        return;
                    }
                    case Toolset.DRAW_EDIT:
                    case Toolset.DRAW_EDIT_CLIP:
                    case Toolset.DRAW_EDIT_IMPORT:
                        this.drawTools.setFieldData(
                            nextProps.toolsetPayload,
                            toToolset === Toolset.DRAW_EDIT_IMPORT
                        );
                        return;
                    case Toolset.DRAW_NON_FIELD_FEATURE: {
                        const { feature } = nextProps.toolsetPayload;
                        this.nonFieldFeatureDrawTools.editFeature(feature);
                        return;
                    }
                    case Toolset.SAMPLING: {
                        const {
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            samplingViewOnly,
                        } = nextProps;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            true
                        ).then(() => {
                            this._updateSampling(toolsetPayload, null, samplingViewOnly);
                        });
                        return;
                    }
                    case Toolset.ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            false
                        );
                        return;
                    }
                    case Toolset.SCOUTING_ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this._updateScoutingZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId
                        );
                        return;
                    }
                    case Toolset.SAMPLING_AUTO_PLACE: {
                        const { toolsetPayload } = nextProps;
                        const { attributes, fields, isTissue, existingPoints } = toolsetPayload;
                        this.samplingTools.setBatchSitesInfo(
                            attributes,
                            fields,
                            existingPoints,
                            isTissue,
                            this.zoneTools.getMiddleLayers()
                        );
                        this.adjustingSamplingGrid = false;
                        return;
                    }
                    default:
                        return invalidTransition();
                }
            case Toolset.ANALYSIS:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.zoneTools.cancel();
                        this._setActiveMapTool(null);
                        nextProps.onSetMapIsLoading(false);
                        return;
                    case Toolset.ANALYSIS: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        const isManagementArea =
                            toolsetPayload.recEventDetails?.type ===
                            ANALYSIS_INFO_NAME_MANAGEMENT_AREA;
                        if (isManagementArea) {
                            this._updateZoneTools(
                                toolsetPayload,
                                areaIdToHasDataMap,
                                currentAreaId,
                                false,
                                false
                            );
                        }

                        return;
                    }
                    default:
                        return invalidTransition();
                }
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.drawTools.clear();
                        this._setActiveMapTool(null);
                        nextProps.onSetMapIsLoading(false);
                        return;
                    case Toolset.DRAW_EDIT:
                    case Toolset.DRAW_EDIT_CLIP:
                    case Toolset.DRAW_EDIT_IMPORT: {
                        const { toolsetPayload } = nextProps;
                        this._updateDrawTools(toolsetPayload);
                        return;
                    }
                    default:
                        return invalidTransition();
                }
            case Toolset.DRAW_NON_FIELD_FEATURE:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.nonFieldFeatureDrawTools.clear();
                        this._setActiveMapTool(null);
                        return;
                    case Toolset.DRAW_NON_FIELD_FEATURE: {
                        return;
                    }
                    default:
                        return invalidTransition();
                }
            case Toolset.SAMPLING:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.samplingTools.cancel();
                        this.zoneTools.cancel();
                        this._setActiveMapTool(null);
                        nextProps.onSetMapIsLoading(false);
                        return;
                    case Toolset.SAMPLING: {
                        const {
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            samplingViewOnly,
                        } = nextProps;
                        // Check for updated selected sites before promise is resolved
                        const updatedSelectedSites =
                            nextProps.selectedSites !== this.props.selectedSites
                                ? nextProps.selectedSites
                                : null;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            true
                        ).then(() => {
                            this._updateSampling(null, updatedSelectedSites, samplingViewOnly);
                        });
                        return;
                    }
                    case Toolset.SAMPLING_GRID:
                        this._updateSamplingGrid(nextProps, true);
                        return;
                    case Toolset.SAMPLING_TRACE:
                        this._startTrace(nextProps);
                        return;
                    case Toolset.ZONE_EDIT:
                        this.samplingTools.cancel();
                        this.zoneTools.setIsInclusionSelect(false);
                        return;
                    case Toolset.SCOUTING_ZONE_EDIT:
                        this.scoutingZoneTools.cancel();
                        return;
                    case Toolset.SAMPLING_AUTO_PLACE: {
                        const { toolsetPayload } = nextProps;
                        const { attributes, fields, isTissue, existingPoints } = toolsetPayload;
                        this.samplingTools.setBatchSitesInfo(
                            attributes,
                            fields,
                            existingPoints,
                            isTissue,
                            this.zoneTools.getMiddleLayers()
                        );
                        this.adjustingSamplingGrid = false;
                        return;
                    }
                    default:
                        return invalidTransition();
                }
            case Toolset.SAMPLING_GRID:
                switch (toToolset) {
                    case Toolset.SAMPLING:
                        this._placePoints(nextProps);
                        return;
                    case Toolset.SAMPLING_GRID:
                        this._updateSamplingGrid(nextProps, false);
                        return;
                    default:
                        return invalidTransition();
                }
            case Toolset.SAMPLING_TRACE:
                switch (toToolset) {
                    case Toolset.SAMPLING:
                        this._cancelTrace();
                        return;
                    case Toolset.SAMPLING_TRACE:
                        this._updateSamplingTrace(nextProps);
                        return;
                    default:
                        return invalidTransition();
                }
            case Toolset.ZONE_EDIT:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.zoneTools.cancel();
                        this._setActiveMapTool(null);
                        nextProps.onSetMapIsLoading(false);
                        return;
                    case Toolset.SAMPLING: {
                        const {
                            areaIdToHasDataMap,
                            currentAreaId,
                            samplingViewOnly,
                            toolsetPayload,
                        } = nextProps;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            true
                        ).then(() => {
                            this._updateSampling(toolsetPayload, null, samplingViewOnly);
                            this.zoneTools.emitZonesUpdate();
                        });
                        return;
                    }
                    case Toolset.SCOUTING_ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this._updateScoutingZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId
                        );
                        return;
                    }
                    case Toolset.ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            false
                        );
                        return;
                    }
                    case Toolset.ZONE_COPY:
                        this.zoneTools.setZoneCopyFromAreaId(
                            nextProps.copyZonesFromToMap.keys().next().value
                        );
                        this.zoneTools.updateCopyZonesFromToMap(nextProps.copyZonesFromToMap);
                        return;
                    default:
                        return invalidTransition();
                }
            case Toolset.ZONE_COPY:
                switch (toToolset) {
                    case Toolset.ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this.zoneTools.setZoneCopyFromAreaId(null);
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            false
                        );
                        return;
                    }
                    case Toolset.ZONE_COPY:
                        this.zoneTools.updateCopyZonesFromToMap(nextProps.copyZonesFromToMap);
                        return;
                    default:
                        return invalidTransition();
                }
            case Toolset.SCOUTING_ZONE_EDIT:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.scoutingZoneTools.cancel();
                        this._setActiveMapTool(null);
                        nextProps.onSetMapIsLoading(false);
                        return;
                    case Toolset.SCOUTING_ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this._updateScoutingZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId
                        );
                        return;
                    }
                    case Toolset.ZONE_EDIT: {
                        const { toolsetPayload, areaIdToHasDataMap, currentAreaId } = nextProps;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            false
                        );
                        return;
                    }
                    default:
                        return invalidTransition();
                }
            case Toolset.SAMPLING_AUTO_PLACE:
                switch (toToolset) {
                    case Toolset.DEFAULT:
                        this.samplingTools.cancel();
                        this.zoneTools.cancel();
                        this._setActiveMapTool(null);
                        nextProps.onSetMapIsLoading(false);
                        return;
                    case Toolset.SAMPLING: {
                        const {
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            samplingViewOnly,
                        } = nextProps;
                        this._updateZoneTools(
                            toolsetPayload,
                            areaIdToHasDataMap,
                            currentAreaId,
                            true
                        ).then(() => {
                            this._updateSampling(toolsetPayload, null, samplingViewOnly);
                        });
                        return;
                    }
                    case Toolset.SAMPLING_AUTO_PLACE:
                        return;
                    default:
                        return invalidTransition();
                }
            default:
                return invalidTransition();
        }
    }

    _getInterpolationType(): number {
        const { samplingEvent } = this.props;
        if (samplingEvent) {
            const { interpolationTypeId } = samplingEvent;
            return interpolationTypeId;
        }
        return null;
    }
    _addZone(
        fieldGuid: string,
        eventTypeName: string,
        eventAreaId: number,
        calculatedArea: number,
        shape: string
    ): void {
        const { currentAreaId, onAddZone } = this.props;
        onAddZone(fieldGuid, eventTypeName, eventAreaId, currentAreaId, calculatedArea, shape);
    }

    _clearTrace(): void {
        this.isTracing = false;
        this._resetSamplingHistory();
        this.samplingTools.resetTools();
    }

    _closeConfirmDialog(prevType: Confirm = Confirm.NONE): void {
        this.setState({
            importGeometries: null,
            isConfirmationModalOpen: false,
            confirmationText: "",
            confirmationTitle: "",
            confirmationType: Confirm.NONE,
            previousConfirmationType: prevType !== Confirm.NONE ? prevType : Confirm.NONE,
        });
    }

    _closeImportZoneModal(): void {
        this.setState({
            isImportZonesModalOpen: false,
        });
    }

    _closeLoadZoneModal(): void {
        this.setState({
            isLoadZonesModalOpen: false,
            fieldBoundaryGuid: null,
        });
    }

    _closeMessageDialog(): void {
        this.setState({
            isMessageModalOpen: false,
            message: "",
        });
    }

    _importZones(classificationField = null, useNaturalBreaks = false): void {
        const { field } = this.props.toolsetPayload;
        this.setState({
            isImportZonesModalOpen: false,
        });
        this.props.onSetZonesLoading(true);
        this.props.onSetMapIsLoading(true);
        this.props.onSetToolsProcessing(true);
        this.props.onSetZoneFileGuid(field.fieldGuid, this.state.importZoneFileGuid);
        // Make call to importZones non-blocking, so that UI will re-render (ie, hide dialog and show progress indicators).
        setTimeout(() => {
            this.zoneTools.importZones(
                this.state.importZoneGeometries,
                classificationField,
                useNaturalBreaks
            );
        }, 10);
    }

    _onImportZonesComplete(): void {
        this.props.onSetZonesLoading(false);
        this.props.onSetMapIsLoading(false);
        this.props.onSetToolsProcessing(false);

        this.setState({
            importZoneFileGuid: null,
            importZoneGeometries: null,
            importClassificationFields: null,
        });
    }

    _confirmYes(confirmationType?: Confirm): void {
        const type = confirmationType || this.state.confirmationType;
        let setPrevType = false;
        switch (type) {
            case Confirm.DELETE:
                this.drawTools.delete();
                break;
            case Confirm.DELETE_NON_FIELD_FEATURE:
                this.nonFieldFeatureDrawTools.delete();
                break;
            case Confirm.IMPORT_APPEND:
                this.drawTools.addGeometries(this.state.importGeometries, false);
                setPrevType = true;
                break;
            case Confirm.IMPORT_CLIP:
                this.drawTools.clipImportGeometries(this.state.importGeometries);
                setPrevType = true;
                break;
            case Confirm.IMPORT_REPLACE:
                this.drawTools.addGeometries(this.state.importGeometries, true);
                setPrevType = true;
                break;
            case Confirm.SIMPLIFY_VERTEX_PROMPT:
                this.drawTools.confirmSimplify(true);
                break;
            case Confirm.REMOVE_MIN_AREA_POLYS:
                this.drawTools.removeMinAreaPolygons();
                break;
            default:
                break;
        }
        this._closeConfirmDialog(setPrevType ? type : Confirm.NONE);
    }

    _onClose(): void {
        switch (this.state.confirmationType) {
            case Confirm.SIMPLIFY_VERTEX_PROMPT:
                this.drawTools.confirmSimplify(false);
                break;
            default:
                break;
        }
        // Avoid calling _closeConfirmDialog() a second time, when dialog fires its
        // close event in response to isOpen being set to false.
        if (this.state.isConfirmationModalOpen) {
            this._closeConfirmDialog();
        }
    }

    _createListeners(): void {
        this.#keyup = this.props.map.on("key-up", (evt) => {
            const { activeToolset } = this.props;
            if (!AppHelpers.isInputTheActiveElement()) {
                if (["Backspace", "Delete", "Del"].includes(evt.key)) {
                    if (
                        [
                            Toolset.DRAW_EDIT,
                            Toolset.DRAW_EDIT_CLIP,
                            Toolset.DRAW_EDIT_IMPORT,
                        ].includes(activeToolset) &&
                        this.drawTools.canDelete()
                    ) {
                        this._openConfirmDialog(Confirm.DELETE);
                        return;
                    } else if (
                        activeToolset === Toolset.DRAW_NON_FIELD_FEATURE &&
                        this.nonFieldFeatureDrawTools.canDelete()
                    ) {
                        this._openConfirmDialog(Confirm.DELETE_NON_FIELD_FEATURE);
                        return;
                    } else if (
                        activeToolset === Toolset.SAMPLING &&
                        this.samplingTools.canDelete()
                    ) {
                        this.samplingTools.delete();
                        return;
                    }
                } else if (evt.ctrlKey && evt.key === "z" && this._canUndo(activeToolset)) {
                    this._undo(activeToolset);
                    return;
                } else if (evt.ctrlKey && evt.key === "y" && this._canRedo(activeToolset)) {
                    this._redo(activeToolset);
                    return;
                }
            }
        });
    }

    _createToolbars(props: IMapToolsProps): void {
        const { formatMessage, formatNumber } = props.intl;
        const {
            areaUnits,
            currentProcessCounts,
            lengthUnits,
            map,
            onPushToasterMessage,
            onPushToasterMessageDirect,
            onResetClassifiedZones,
            onResetZones,
            onSetActiveZone,
            onSetAreaIsIncluded,
            onSetBufferOptions,
            onSetCanClip,
            onSetCanClipPending,
            onSetCopyToAreaIdSet,
            onSetFieldEditGeometries,
            onSetMapIsLoading,
            onSetMinMaxGridCell,
            onSetGridOrientationSettings,
            onSetSelectedSamplePoints,
            onSetToolsProcessing,
            gridOrientationSettings,
            setZoomToNonFieldFeatures,
            setZoomToField,
            userGuid,
        } = props;
        const options = {
            forceUpdate: () => this.forceUpdate(),
            formatMessage,
            formatNumber,
            intlConfig,
            messages,
            onPrompt: (confirmationType, params) =>
                this._openConfirmDialog(confirmationType, params),
            onPushToasterMessage,
            onPushToasterMessageDirect,
        };
        const onSetActiveMapTool = (tool) => this._setActiveMapTool(tool);

        this.extentTools = new ExtentToolSet(map, options);
        this.measureTools = new MeasureToolSet(map, {
            ...options,
            areaUnits,
            lengthUnits,
            onSetActiveMapTool,
        });
        this.searchTools = new SearchToolSet(map, {
            ...options,
            setZoomToNonFieldFeatures,
            setZoomToField,
            userGuid,
        });

        const mapToolOptions = {
            onDisplayMessage: (msg, title) => this._onDisplayMessage(msg, title),
            onSetActiveMapTool,
            onSetMapIsLoading,
            onSetToolsProcessing,
        };
        this.drawTools = new DrawToolSet(map, {
            ...options,
            ...mapToolOptions,
            onSetCanClip,
            onSetCanClipPending,
            onUpdateGeometries: onSetFieldEditGeometries,
            setEditImportEditClip: () => this._setEditImportClip(),
        });

        this.nonFieldFeatureDrawTools = new NonFieldFeatureDrawTools(
            map,
            {
                ...options,
                ...mapToolOptions,
            },
            this.props.onSetNonFieldFeatureInformationFeatureGeometries
        );

        const undoRedoActions = {
            onRestoreAreaList: (fieldGuid, restoreId, state) =>
                this._restoreAreaList(fieldGuid, restoreId, state),
            onSaveAreaList: (fieldGuid, restoreId) => this._saveAreaList(fieldGuid, restoreId),
        };

        const samplingmapToolOptions = {
            ...mapToolOptions,
            onDisplayMessage: (msg, title) => this._onDisplayBatchEventMessage(msg, title),
        };
        this.samplingTools = new SamplingToolSet(map, {
            ...options,
            ...samplingmapToolOptions,
            ...undoRedoActions,
            getInterpolationType: () => this._getInterpolationType(),
            gridOrientationSettings,
            onSetMinMaxGridCell,
            onSetGridOrientationSettings,
            onSetSharedToolsetTool: (tool) => this._setSharedToolsetTool(this.samplingTools, tool),
            onUpdateSelectedSites: (guidSet) => onSetSelectedSamplePoints(guidSet),
            onUpdateSites: (fieldGuid, sites, samplePoints) =>
                this._updateSites(fieldGuid, sites, samplePoints),
            onUpdateTrace: (fieldGuid, sites, completeCount) =>
                this._updateTrace(fieldGuid, sites, completeCount),
            onRestoreSites: (sites) => this._restoreSites(sites),
        });
        this.scoutingZoneTools = new ScoutingZoneToolSet(map, {
            ...options,
            ...mapToolOptions,
            ...undoRedoActions,
            recsEventsMessages,
            recsEventsModels,
            onAddZone: (fieldGuid, eventTypeName, eventAreaId, calculatedArea, shape) =>
                this._addZone(fieldGuid, eventTypeName, eventAreaId, calculatedArea, shape),
            onResetZones: (fieldGuid, zonesMap) => onResetZones(fieldGuid, zonesMap),
            onSetActiveZone,
            onSetEntireField: (entireField) => this._setEntireField(entireField as Polygon),
            onUpdateZones: (fieldGuid, zonesMap, includedZones, allZones, isMiddleStep) =>
                this._updateZones(fieldGuid, zonesMap, includedZones, allZones, isMiddleStep),
        });
        this.zoneTools = new ZoneToolSet(map, {
            ...options,
            ...mapToolOptions,
            ...undoRedoActions,
            recsEventsMessages,
            getCurrentDetails: () => this.props.recEventDetails,
            onImportZonesComplete: () => this._onImportZonesComplete(),
            onSetBufferOptions,
            onOpenLoadZonesModal: (fieldBoundaryGuid) =>
                this._openLoadZonesModal(fieldBoundaryGuid),
            onResetClassifiedZones: (fieldGuid, zonesMap, classBreaksMap) =>
                onResetClassifiedZones(fieldGuid, zonesMap, classBreaksMap),
            onResetZones: (fieldGuid, zonesMap) => onResetZones(fieldGuid, zonesMap),
            onSetActiveZone,
            onSetAreaIsIncluded,
            onSetCopyToAreaIdSet,
            onSetEntireField: (entireField) => this._setEntireField(entireField as Polygon),
            onSetSharedToolsetTool: (tool) => this._setSharedToolsetTool(this.zoneTools, tool),
            onUpdateZones: (fieldGuid, zonesMap, includedZones, allZones, isMiddleStep) =>
                this._updateZones(fieldGuid, zonesMap, includedZones, allZones, isMiddleStep),
        });

        this.processCountTools = new ProcessCountTools(map, {
            ...options,
            currentProcessCounts,
            rtl: true,
        });
        this.toolbars = [
            this.extentTools,
            this.measureTools,
            this.searchTools,
            this.drawTools,
            this.nonFieldFeatureDrawTools,
            this.samplingTools,
            this.scoutingZoneTools,
            this.zoneTools,
            this.processCountTools,
        ];

        this.samplingRestoreId = new RegExp(`^${this.samplingTools.id}`);
        this.zoneRestoreId = new RegExp(`^${this.zoneTools.id}`);
    }

    _createRedoButtonForToolset(toolset: Toolset): JSX.Element {
        const { toolsetDisabled } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <ToolbarButton
                key="redo"
                disabled={toolsetDisabled || !this._canRedo(toolset)}
                icon={ToolbarIcon(IconType.REDO)}
                label={formatMessage(messages.redoLbl)}
                onClick={() => this._redo(toolset)}
            />
        );
    }

    _createRetryShapeWithWKTButton(
        alternateFeatures: Record<string, unknown>,
        message: string
    ): JSX.Element {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                {message}
                <br />
                <br />
                {formatMessage(messages.retryWithWKTNotice)}
                <br />
                <br />
                <Button
                    value={formatMessage(messages.retry)}
                    onClick={() => this._retryShapefileImportFromWKT(alternateFeatures)}
                ></Button>
            </div>
        );
    }

    _createUndoButtonForToolset(toolset: Toolset): JSX.Element {
        const { toolsetDisabled } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <ToolbarButton
                key="undo"
                disabled={toolsetDisabled || !this._canUndo(toolset)}
                icon={ToolbarIcon(IconType.UNDO)}
                label={formatMessage(messages.undoLbl)}
                onClick={() => this._undo(toolset)}
            />
        );
    }

    _getShapeFromFiles(files: FileList, isZoneImport: boolean): void {
        const { onPushToasterMessage, onPushToasterMessageDirect, userGuid } = this.props;
        const { formatMessage } = this.props.intl;

        const primaryFile = FileImportUtils.getPrimaryFile(files);
        const importFileGuid = uuid();
        const importFile = {
            importFileGuid: importFileGuid,
            importFileName: primaryFile.name,
            fileSizeBytes: primaryFile.size,
        };
        if (isZoneImport) {
            // Importing Zone
            FileImportAPI.addZoneImportFile(userGuid, importFile);
        } else {
            FileImportAPI.addFieldBoundaryImportFile(userGuid, importFile);
        }

        FileImportAPI.getProjectedFeaturesFromUploadedShapefile(
            userGuid,
            null,
            primaryFile.name,
            importFileGuid,
            4326
        )
            .then((results) => {
                if (!results) {
                    throw new Error(formatMessage(messages.errorUploadingFileServerError));
                }
                const { topologyErrors, features } = results;
                let featureCollection;
                try {
                    featureCollection = JSON.parse(features);
                } catch (ex) {
                    this._onDisplayMessage(
                        results,
                        formatMessage(messages.importZoneInvalidImportTitle)
                    );
                    return;
                }
                topologyErrors.forEach((err, i) => {
                    if (i === 0 && !isZoneImport) {
                        onPushToasterMessageDirect(
                            this._createRetryShapeWithWKTButton(
                                featureCollection["alternate"],
                                formatMessage(messages.topologyError, {
                                    error: err,
                                })
                            ),
                            ToastType.WARNING,
                            false
                        );
                    } else {
                        onPushToasterMessage(
                            formatMessage(messages.topologyErrorBasic, {
                                error: err,
                            }),
                            ToastType.WARNING,
                            undefined,
                            false
                        );
                    }
                });
                if (isZoneImport) {
                    // Importing Zone
                    const features = featureCollection.features.map(
                        FileImportUtils.convertToEsriGeometry
                    );
                    if (this.zoneTools.geometriesIntersectField(features.map((f) => f.geometry))) {
                        const featuresTouchingField =
                            this.zoneTools.filterGeometriesOutsideField(features);
                        const nbrOutside = features.length - featuresTouchingField.length;
                        if (nbrOutside > 0) {
                            onPushToasterMessage(
                                formatMessage(messages.importZoneSomeDoNotIntersectField, {
                                    count: nbrOutside,
                                })
                            );
                        }
                        let classificationFields = undefined;
                        if (
                            featuresTouchingField.length >
                            ZoneClassificationUtils.NUMBER_OF_FEATURES_CLASSIFY_THRESHOLD
                        ) {
                            classificationFields =
                                ZoneClassificationUtils.getFieldNames(featuresTouchingField);
                        }
                        this.setState(
                            {
                                importZoneFileGuid: importFileGuid,
                                importZoneGeometries: featuresTouchingField,
                                importClassificationFields: classificationFields,
                            },
                            () => this._openImportZoneModal()
                        );
                    } else {
                        this._onDisplayMessage(
                            formatMessage(messages.importZoneNoneIntersectField),
                            formatMessage(messages.importZoneInvalidImportTitle)
                        );
                    }
                } else {
                    // Importing Field
                    this.props.onSetFieldBoundaryImportFileGuid(importFileGuid);
                    const geometries = featureCollection.features
                        .map(FileImportUtils.convertToEsriGeometry)
                        .map((g) => g.geometry);
                    if (!this.drawTools.geometriesIntersect(geometries)) {
                        this.drawTools.addGeometries(geometries);
                    } else {
                        this.setState(
                            {
                                importGeometries: geometries,
                            },
                            () => this._openConfirmDialog(Confirm.IMPORT_PROMPT)
                        );
                    }
                }
            })
            .catch((err) => {
                onPushToasterMessage(
                    `${formatMessage(messages.errorUploadingFile)} ${err.message}`,
                    ToastType.ERROR
                );
            })
            .finally(() => this._resetFileInput());
    }

    _getVisibleToolbars(): any[] {
        const baseToolbars = [this.extentTools, this.measureTools, this.searchTools];
        switch (this.props.activeToolset) {
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                return [...baseToolbars, this.drawTools];
            case Toolset.DRAW_NON_FIELD_FEATURE:
                return [...baseToolbars, this.nonFieldFeatureDrawTools];
            case Toolset.SAMPLING:
                return [...baseToolbars, this.zoneTools, this.samplingTools];
            case Toolset.ZONE_EDIT:
                return [...baseToolbars, this.zoneTools];
            case Toolset.SCOUTING_ZONE_EDIT:
                return [...baseToolbars, this.scoutingZoneTools];
            default:
                break;
        }
        return baseToolbars;
    }

    _importFiles(files: FileList = new FileList()): void {
        const { activeToolset, onPushToasterMessage, onSetZonesLoading, userGuid } = this.props;
        if (
            ![
                Toolset.DRAW_EDIT,
                Toolset.DRAW_EDIT_CLIP,
                Toolset.DRAW_EDIT_IMPORT,
                Toolset.ZONE_EDIT,
                Toolset.ANALYSIS,
                Toolset.SAMPLING,
                Toolset.SCOUTING_ZONE_EDIT,
            ].includes(activeToolset) ||
            files.length === 0
        ) {
            return;
        }
        onSetZonesLoading(true);
        this.setState(
            {
                isUploadingFiles: true,
                previousGeometries: [],
            },
            () => {
                FileImportUtils.checkIfShapefileValid(files)
                    .then((isValid) => {
                        if (!isValid) {
                            onPushToasterMessage(messages.invalidFiles, ToastType.WARNING);
                            this._resetFileInput();
                            return;
                        }

                        const zoneImport =
                            activeToolset === Toolset.ZONE_EDIT ||
                            activeToolset === Toolset.ANALYSIS ||
                            activeToolset === Toolset.SAMPLING;

                        const promises = [];
                        for (const file of files) {
                            FileImportAPI.getImportS3PreSignedUrl(userGuid, null, file.name)
                                .then((url) => {
                                    promises.push(
                                        Request.uploadToS3(url, file).catch((err) => {
                                            onSetZonesLoading(false);
                                            console.error(err.message, file, url);
                                            return err;
                                        })
                                    );
                                    if (promises.length === files.length) {
                                        Promise.all(promises).then((results) => {
                                            if (results.find((r) => r instanceof Error)) {
                                                onPushToasterMessage(
                                                    messages.errorUploadingFile,
                                                    ToastType.ERROR
                                                );
                                                this._resetFileInput();
                                                return;
                                            }
                                            this._getShapeFromFiles(files, zoneImport);
                                        });
                                    }
                                })
                                .catch(() => onSetZonesLoading(false));
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        onPushToasterMessage(messages.invalidFiles, ToastType.ERROR);
                        this._resetFileInput();
                    });
            }
        );
    }

    _loadZones(zonesInfo: IZone): void {
        this.zoneTools.loadZones(zonesInfo);
        this._closeLoadZoneModal();
    }

    _logActiveToolToFirebase(tool: Tool): void {
        let eventName = "";
        switch (tool) {
            case Tool.MeasureArea:
                eventName = "measure_area";
                break;
            case Tool.MeasureLength:
                eventName = "measure_length";
                break;
            case Tool.MeasureLocation:
                eventName = "measure_lat_long";
                break;
        }
        eventName && logFirebaseEvent(eventName);
    }

    _onDisplayBatchEventMessage(
        message: string,
        title = this.props.intl.formatMessage(messages.batchEvent)
    ): void {
        this.setState({
            isMessageModalOpen: true,
            message,
            title,
        });
    }

    _onDisplayMessage(
        message: string,
        title = this.props.intl.formatMessage(messages.invalidEdit)
    ): void {
        this.setState({
            isMessageModalOpen: true,
            message,
            title,
        });
    }

    _onToggleMenu(e: ToggleEvent): void {
        const { activeToolset, onSetVisibleToolbarMenuDimensions } = this.props;
        const promises = [];
        const visibleToolbars = this._getVisibleToolbars();

        visibleToolbars.forEach((toolbar, index) => {
            if (e.index !== index && toolbar.reset) {
                promises.push(toolbar.reset(true));
            }
        });
        Promise.all(promises).then(() => {
            if (e.expanded) {
                this.statusToolbar.toggleMenu({
                    expanded: false,
                });
                if (visibleToolbars[e.index]) {
                    if (visibleToolbars[e.index].activate) {
                        visibleToolbars[e.index].activate();
                    }
                    if (visibleToolbars[e.index].getDimensions) {
                        onSetVisibleToolbarMenuDimensions(visibleToolbars[e.index].getDimensions());
                    }
                }
            } else {
                onSetVisibleToolbarMenuDimensions(null);
            }

            if (
                [Toolset.DRAW_EDIT, Toolset.DRAW_EDIT_CLIP, Toolset.DRAW_EDIT_IMPORT].includes(
                    activeToolset
                )
            ) {
                this.drawTools.activateToolset();
            } else if (activeToolset === Toolset.DRAW_NON_FIELD_FEATURE) {
                this.nonFieldFeatureDrawTools.activateToolset();
            } else if (activeToolset === Toolset.SAMPLING) {
                this.zoneTools.activateToolset();
                this.samplingTools.activateToolset();
            } else if (activeToolset === Toolset.ZONE_EDIT) {
                this.zoneTools.activateToolset();
            } else if (activeToolset === Toolset.SCOUTING_ZONE_EDIT) {
                this.scoutingZoneTools.activateToolset();
            }
        });
    }

    _onToggleStatusMenu(e: ToggleEvent): void {
        if (e.expanded) {
            this.primaryToolbar.toggleMenu({
                expanded: false,
            });
        }
    }

    _openConfirmDialog(
        confirmationType: Confirm,
        params?: Record<string, MessageFormatPrimitiveValue>
    ): void {
        const { formatMessage } = this.props.intl;

        let confirmationText, confirmationTitle;
        switch (confirmationType) {
            case Confirm.DELETE:
                confirmationText = formatMessage(messages.confirmDeleteMsg);
                confirmationTitle = formatMessage(messages.confirmDeleteTitle);
                break;
            case Confirm.DELETE_NON_FIELD_FEATURE:
                confirmationText = formatMessage(messages.confirmDeleteMsg);
                confirmationTitle = formatMessage(messages.confirmDeleteTitle);
                break;
            case Confirm.IMPORT_PROMPT:
                confirmationText = formatMessage(messages.promptImportMsg);
                confirmationTitle = formatMessage(messages.promptImportTitle);
                break;
            case Confirm.SIMPLIFY_VERTEX_PROMPT:
                confirmationText = formatMessage(messages.promptSimplifyVertexMsg, params);
                confirmationTitle = formatMessage(messages.promptSimplifyVertexTitle);
                break;
            case Confirm.REMOVE_MIN_AREA_POLYS:
                confirmationText = formatMessage(messages.promptRemoveMinAreaPolysMsg);
                confirmationTitle = formatMessage(messages.promptRemoveMinAreaPolysTitle);
                break;
            default:
                confirmationText = "";
                confirmationTitle = "";
        }

        this.setState({
            confirmationText,
            confirmationTitle,
            confirmationType,
            isConfirmationModalOpen: true,
            previousGeometries: this.drawTools.geometries,
        });
    }

    _openImportZoneModal(): void {
        logFirebaseEvent("zones_import");
        this.setState({
            isImportZonesModalOpen: true,
        });
    }

    _openLoadZonesModal(fieldBoundaryGuid: string): void {
        this.setState({
            fieldBoundaryGuid,
            isLoadZonesModalOpen: true,
        });
    }
    _placePoints(nextProps: INextProps): void {
        const { toolsetPayload } = nextProps;
        this.samplingTools.moveLayersToTop(this.zoneTools.getMiddleLayers());
        if (toolsetPayload.placePoints) {
            this.samplingTools.placePoints(
                toolsetPayload.placePoints === "A"
                    ? GridPointPlacementMethod.AUTO
                    : toolsetPayload.placePoints === "C"
                    ? GridPointPlacementMethod.CENTER
                    : GridPointPlacementMethod.RANDOM
            );
            this._resetSamplingHistory();
        } else if (toolsetPayload.clearGrid) {
            this.samplingTools.clearGrid();
        } else {
            this.samplingTools.cancelGrid();
        }
        this.samplingTools.resetTools();
        this.adjustingSamplingGrid = false;
    }

    _redo(toolset: Toolset): void {
        switch (toolset) {
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                this.drawTools.redo();
                break;
            case Toolset.DRAW_NON_FIELD_FEATURE:
                this.nonFieldFeatureDrawTools.redo();
                break;
            case Toolset.SAMPLING: {
                this.samplingHistory.redo();
                const prevAction = this.samplingHistory.getCurrentState();
                if (prevAction === SamplingAction.ZONES) {
                    this.zoneTools.redo();
                } else {
                    console.assert(prevAction === SamplingAction.SAMPLING);
                    this.samplingTools.redo();
                }
                break;
            }
            case Toolset.SAMPLING_GRID:
                this.samplingTools.redoGrid();
                break;
            case Toolset.SAMPLING_TRACE:
                this.samplingTools.redoTrace();
                break;
            case Toolset.ANALYSIS:
            case Toolset.ZONE_EDIT:
                this.zoneTools.redo();
                break;
            case Toolset.SCOUTING_ZONE_EDIT:
                this.scoutingZoneTools.redo();
                break;
            default:
                break;
        }
        this.forceUpdate();
    }

    _removeListeners(): void {
        if (this.#keyup) {
            this.#keyup.remove();
            this.#keyup = null;
        }
    }
    private renderConfirmationDialog() {
        const { formatMessage } = this.props.intl;
        const { confirmationType } = this.state;
        return (
            <DialogBox
                draggable
                footerType={
                    confirmationType === Confirm.IMPORT_PROMPT
                        ? DialogBoxFooterType.MULTI_ACTION_CANCEL
                        : DialogBoxFooterType.YES_NO
                }
                className={confirmationType === Confirm.IMPORT_PROMPT ? "import-confirmation" : ""}
                multiActionList={
                    confirmationType === Confirm.IMPORT_PROMPT
                        ? [
                              {
                                  action: formatMessage(messages.promptImportReplaceBtnText),
                                  onAction: () => this._confirmYes(Confirm.IMPORT_REPLACE),
                              },
                              {
                                  action: formatMessage(messages.promptImportAppendBtnText),
                                  onAction: () => this._confirmYes(Confirm.IMPORT_APPEND),
                              },
                              {
                                  action: formatMessage(messages.promptImportClipBtnText),
                                  onAction: () => this._confirmYes(Confirm.IMPORT_CLIP),
                              },
                          ]
                        : null
                }
                isOpen={this.state.isConfirmationModalOpen}
                onAction={
                    confirmationType !== Confirm.IMPORT_PROMPT ? () => this._confirmYes() : null
                }
                onClose={() => this._onClose()}
                title={this.state.confirmationTitle}
            >
                {this.state.confirmationText}
            </DialogBox>
        );
    }
    _resetFileInput(): void {
        this.props.onSetZonesLoading(false);
        this.setState(
            {
                isUploadingFiles: false,
            },
            () => (this.#fileInput.value = "")
        );
    }

    _resetSamplingHistory(): void {
        this.samplingHistory.add(SamplingAction.NONE, true);
    }

    _resetTrace(): void {
        this.props.onClearTraceFlags();
        this.samplingTools.resetTrace();
    }

    _restoreAreaList(
        fieldGuid: string,
        restoreId: string,
        state: Graphic[] | Collection<Graphic>
    ): void {
        const { activeToolset } = this.props;
        if (activeToolset === Toolset.DEFAULT) {
            return;
        }
        if (activeToolset === Toolset.SAMPLING) {
            if (restoreId.match(this.samplingRestoreId)) {
                const samplePoints = this.samplingTools.getUpdatedSamplePoints(state);
                this.props.onSetSamplePoints(samplePoints);
                this.zoneTools.setSamplingSites(state);
            } else if (restoreId.match(this.zoneRestoreId)) {
                this.samplingTools.setIncludedZones("toArray" in state ? state.toArray() : state);
            }
        }
        this.props.onRestoreAreaList(fieldGuid, restoreId);
    }

    _restoreSites(sites: Graphic[] | Collection<Graphic>): void {
        this.samplingHistory.add(SamplingAction.SAMPLING);
        this.zoneTools.setSamplingSites(sites);
    }

    _retryShapefileImportFromWKT(alternateFeatures: IAltFeatures): void {
        const { previousConfirmationType, previousGeometries } = this.state;
        const prevConfirm =
            previousConfirmationType === Confirm.NONE
                ? Confirm.IMPORT_REPLACE
                : previousConfirmationType;
        this.drawTools.geometries.forEach((g) => {
            this.drawTools.removeGeometry(g);
        });
        const geometries = alternateFeatures.features
            .map(FileImportUtils.convertToEsriGeometry)
            .map((g) => g.geometry);
        if (previousGeometries.length === 0) {
            this.drawTools.addGeometries(geometries);
        } else {
            this.setState(
                {
                    importGeometries: geometries,
                    previousConfirmationType: Confirm.NONE,
                },
                () => {
                    setTimeout(() => {
                        this.drawTools.addGeometries(previousGeometries);
                        this._confirmYes(prevConfirm);
                    });
                }
            );
        }
    }

    _saveAreaList(fieldGuid: string, restoreId: string): void {
        const { activeToolset } = this.props;
        if (activeToolset === Toolset.DEFAULT) {
            return;
        }
        this.props.onSaveAreaList(fieldGuid, restoreId);
    }

    _setActiveMapTool(tool: Tool): void {
        const { activeMapTool, activeToolset, onSetActiveMapTool } = this.props;
        if (activeToolset === Toolset.SAMPLING && this.zoneTools.getTools().includes(tool)) {
            this.samplingTools.moveLayersToTop(this.zoneTools.getMiddleLayers());
            this.samplingTools.pointCreator.deactivate();
        }
        if (activeMapTool !== tool) {
            onSetActiveMapTool(tool);
            this._logActiveToolToFirebase(tool);
        }
    }

    _setEditImportClip(): void {
        this.props.onSetActiveToolsetOnly(Toolset.DRAW_EDIT_IMPORT);
        this.setState({
            importNeedsClip: true,
        });
    }

    _setEntireField(entireField: Polygon): void {
        const { activeToolset } = this.props;
        if (activeToolset === Toolset.SAMPLING) {
            this.samplingTools.setEntireField(entireField);
        }
    }

    _setSharedToolsetTool(toolbar: unknown, tool: Tool): void {
        if (this.props.activeToolset === Toolset.SAMPLING) {
            if (toolbar === this.samplingTools) {
                this.zoneTools.setSharedMapTool(tool);
                this.zoneTools.refresh();
            } else {
                console.assert(this.zoneTools === toolbar);
                this.samplingTools.setSharedMapTool(tool);
            }
        }
    }

    _setToolLayers(): void {
        const { getFieldBoundaryImportLayer, getFieldsLayer } = this.props;
        const fieldsLayer = getFieldsLayer.call(null) as FieldsLayer;
        const fieldBoundaryImportLayer = getFieldBoundaryImportLayer.call(
            null
        ) as FieldBoundaryImportLayer;
        this.drawTools.setLayers({ fieldsLayer, fieldBoundaryImportLayer });
        this.nonFieldFeatureDrawTools.setLayers({ fieldsLayer });
        this.samplingTools.setLayers({ fieldsLayer });
        this.zoneTools.setLayers({ fieldsLayer });
        this.scoutingZoneTools.setLayers({ fieldsLayer });
    }

    _startTrace(nextProps: INextProps): void {
        this.isTracing = true;
        this.samplingTools.startTrace(nextProps.toolsetPayload.auto);
    }

    _toggleCLU(evt: ToggleEvent): void {
        this._updateCLUBoundary(evt.selected);
        if (evt.selected) {
            logFirebaseEvent("clu");
            this._setActiveMapTool(null);
            for (const toolbar of this.toolbars) {
                toolbar.menu?.close();
            }
        }
    }

    _undo(toolset: Toolset): void {
        switch (toolset) {
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                this.drawTools.undo();
                break;
            case Toolset.DRAW_NON_FIELD_FEATURE:
                this.nonFieldFeatureDrawTools.undo();
                break;
            case Toolset.SAMPLING: {
                const prevAction = this.samplingHistory.getCurrentState();
                this.samplingHistory.undo();
                if (prevAction === SamplingAction.ZONES) {
                    this.zoneTools.undo();
                } else {
                    console.assert(prevAction === SamplingAction.SAMPLING);
                    this.samplingTools.undo();
                }
                break;
            }
            case Toolset.SAMPLING_GRID:
                this.samplingTools.undoGrid();
                break;
            case Toolset.SAMPLING_TRACE:
                this.samplingTools.undoTrace();
                break;
            case Toolset.ANALYSIS:
            case Toolset.ZONE_EDIT:
                this.zoneTools.undo();
                break;
            case Toolset.SCOUTING_ZONE_EDIT:
                this.scoutingZoneTools.undo();
                break;
            default:
                break;
        }
        this.forceUpdate();
    }

    _updateCLUBoundary(active: boolean): void {
        const { formatMessage } = this.props.intl;
        const { fieldModel, updateFieldModel } = this.props;

        const handleResponse = (cluBoundaryResponse, error = false) => {
            if (error) {
                this.props.onSetToolsProcessing(false);
                this._onDisplayMessage(
                    "No CLU boundary found.",
                    formatMessage(messages.cluBoundary)
                );
            } else {
                updateFieldModel({ ...fieldModel, cluBoundaryUsed: true });
                const geometries = [];
                for (const feature of cluBoundaryResponse.results.features) {
                    const polygon = new Polygon({
                        rings: feature.geometry.coordinates,
                        spatialReference: SpatialReference.WGS84,
                    });
                    if (webMercatorUtils.canProject(polygon, SpatialReference.WebMercator)) {
                        geometries.push(webMercatorUtils.geographicToWebMercator(polygon));
                    }
                }
                this.drawTools.addGeometries(geometries, false);
                this.props.onSetToolsProcessing(false);
                removeCLUBoundaryClickEvent();
                if (this.cluBoundary?.isSelected()) {
                    this.cluBoundary?.unselect();
                }
                this._toggleCLU({ selected: false });
            }
        };

        const removeCLUBoundaryClickEvent = () => {
            if (this.#cluBoundaryClickEvent != null) {
                this.props.map.container.style.cursor = Cursor.default;
                this.#cluBoundaryClickEvent.remove();
                this.#cluBoundaryClickEvent = null;
            }
        };

        removeCLUBoundaryClickEvent();
        if (active) {
            this.props.map.container.style.cursor = Cursor.crosshair;
            this.#cluBoundaryClickEvent = this.props.map.on("click", async (evt) => {
                this.props.onSetToolsProcessing(true);
                const hitResults = await this.props.map.hitTest(evt);
                if (
                    hitResults.results.length > 0 &&
                    hitResults.results.some((result) =>
                        result.graphic.layer.id.toLowerCase().includes("field")
                    )
                ) {
                    this.props.onSetToolsProcessing(false);
                    this._onDisplayMessage(
                        "Cannot select on existing boundary.",
                        formatMessage(messages.cluBoundary)
                    );
                    return;
                }

                FieldAPI.getCLUBoundary({
                    x: evt.mapPoint.longitude,
                    y: evt.mapPoint.latitude,
                })
                    .then((cluBoundaryResponse) => {
                        handleResponse(cluBoundaryResponse);
                    })
                    .catch(() => {
                        handleResponse(null, true);
                        this.props.onSetToolsProcessing(false);
                    });
            });
        }
    }

    //
    _updateDrawTools(toolsetPayload: ToolsetPayload): void {
        this.drawTools.updateCustomerGuid({
            customerGuid: toolsetPayload.customerGuid,
            fieldGuid: toolsetPayload.fieldGuid,
        });
    }

    _updateSampling(
        updatedToolsetPayload: Record<string, any>,
        updatedSelectedSites: Set<string>,
        samplingViewOnly: boolean
    ): void {
        if (updatedToolsetPayload != null && !updatedToolsetPayload.isAuto) {
            const { recEventDetails, field, samplePoints, isMerge } = updatedToolsetPayload;
            const isTissue = recEventDetails.agEventTypeList.some(
                (agEventType) =>
                    agEventType.agEventTransactionTypeName ===
                    recsEventsModels.EVENT_TYPE_NAME_SAMPLING_TISSUE
            );
            this._resetSamplingHistory();
            this.samplingTools.setSitesInfo(samplePoints, field, isTissue);
            if (isMerge) {
                this.zoneTools.resetZones();
            }
        }
        if (updatedSelectedSites != null) {
            this.samplingTools.updateSelectedSites(updatedSelectedSites);
        }
        this.samplingTools.setViewOnly(samplingViewOnly);
        this.samplingTools.moveLayersToTop(this.zoneTools.getMiddleLayers());
    }

    _updateSamplingGrid(nextProps: IMapToolsProps, init = false): void {
        this.adjustingSamplingGrid = true;
        const { samplingGridSettings, gridOrientationSettings } = nextProps;

        if (
            init ||
            JSON.stringify(samplingGridSettings) !==
                JSON.stringify(this.props.samplingGridSettings) ||
            JSON.stringify(gridOrientationSettings) !==
                JSON.stringify(this.props.gridOrientationSettings)
        ) {
            this.samplingTools.createGrid(samplingGridSettings, gridOrientationSettings);
        }
    }

    _updateSamplingTrace(nextProps: IMapToolsProps): void {
        if (nextProps.toolsetPayload !== this.props.toolsetPayload) {
            this.samplingTools.setAutoTracing(nextProps.toolsetPayload.auto);
        }
        if (nextProps.traceApplyFlag) {
            this.isTraceApplied = true;
            this._applyTrace();
        } else if (nextProps.traceResetFlag) {
            this._resetTrace();
        }
    }

    _updateScoutingZoneTools(
        toolsetPayload: ToolsetPayload,
        areaIdToHasDataMap: Map<number, boolean>,
        currentAreaId: number
    ): Promise<void> {
        if (
            toolsetPayload !== this.props.toolsetPayload &&
            toolsetPayload.recEventDetails != null
        ) {
            return this.scoutingZoneTools.setZoneData(toolsetPayload);
        }
        if (areaIdToHasDataMap != null && areaIdToHasDataMap !== this.props.areaIdToHasDataMap) {
            this.scoutingZoneTools.updateAreaHasData(areaIdToHasDataMap);
        }
        if (currentAreaId != null && currentAreaId !== this.props.currentAreaId) {
            this.scoutingZoneTools.setCurrentAreaId(currentAreaId);
        }
        return Promise.resolve();
    }

    _updateZoneTools(
        toolsetPayload: ToolsetPayload,
        areaIdToHasDataMap: Map<number, boolean>,
        currentAreaId: number,
        isInclusionSelect?: boolean,
        isAnalysisMode?: boolean
    ): any {
        let promise;
        if (
            toolsetPayload !== this.props.toolsetPayload &&
            toolsetPayload.recEventDetails != null
        ) {
            promise = this.zoneTools.setZoneData(toolsetPayload, isInclusionSelect, isAnalysisMode);
        }
        if (areaIdToHasDataMap != null && areaIdToHasDataMap !== this.props.areaIdToHasDataMap) {
            this.zoneTools.updateAreaHasData(areaIdToHasDataMap);
        }
        if (currentAreaId != null && currentAreaId !== this.props.currentAreaId) {
            this.zoneTools.setCurrentAreaId(currentAreaId);
        }
        if (toolsetPayload?.field) {
            const { customerGuid } = toolsetPayload.field;
            if (customerGuid !== this.props.bufferOptions.customerGuid) {
                this.props.onSetBufferOptions({ customerGuid });
            }
        }

        return promise || Promise.resolve();
    }

    _updateSites(
        fieldGuid: string,
        sites: Graphic[] | Collection<Graphic>,
        samplePoints: Record<string, any>
    ): void {
        this.samplingHistory.add(SamplingAction.SAMPLING);
        this.zoneTools.setSamplingSites(sites);
        const formattedSamplePoints = samplePoints.toArray ? samplePoints.toArray() : samplePoints;
        this.props.onSetSamplePoints(formattedSamplePoints);
    }
    // unused params, removing them could work if all usages are changed
    _updateTrace(
        fieldGuid: string,
        sites: Graphic[] | Collection<Graphic>,
        completeCount: number
    ): void {
        this.props.onSetTraceCounts(completeCount, sites.length);
    }

    _updateToolbars(nextProps: INextProps): void {
        const { activeMapTool, areaUnits, lengthUnits, visibleToolbarMenuDimensions } = nextProps;
        this.measureTools.update({
            areaUnits,
            lengthUnits,
        });
        this.searchTools.update(nextProps);

        const menuDimensionsChanged =
            JSON.stringify(this.props.visibleToolbarMenuDimensions) !==
            JSON.stringify(visibleToolbarMenuDimensions);

        this.toolbars.forEach((toolbar) => {
            if (toolbar.setActiveMapTool) {
                toolbar.setActiveMapTool(activeMapTool);
            }
            if (menuDimensionsChanged && toolbar.setToolbarMenuInset) {
                toolbar.setToolbarMenuInset(visibleToolbarMenuDimensions);
            }
        });
    }

    _updateZones(
        fieldGuid: string,
        zonesMap: Map<string, Map<string, ZoneGeometryInfo>>,
        includedZones: Graphic[] = [],
        allZones: Graphic[] = [],
        isMiddleStep = false
    ): void {
        const { activeToolset, onUpdateZones, onUpdateAnalysisZones } = this.props;
        if (activeToolset === Toolset.SAMPLING) {
            this.samplingTools.setIncludedZones(includedZones, !isMiddleStep);
        }
        if (activeToolset === Toolset.ANALYSIS) {
            // A single analysis zone indicates the zone covers the
            // whole field, otherwise pass a null fieldBoundaryGuid
            const fieldBoundaryGuid =
                allZones.length === 1 ? this.zoneTools.fieldBoundaryGuid : null;
            onUpdateAnalysisZones(allZones, fieldBoundaryGuid, zonesMap);
            return;
        }
        if (fieldGuid != null && zonesMap != null) {
            onUpdateZones(fieldGuid, zonesMap);
            this.samplingHistory.add(SamplingAction.ZONES);
        }
    }

    UNSAFE_componentWillMount(): void {
        this._createToolbars(this.props);
        if ((this.props.map as HaasMapView).isReady) {
            this._setToolLayers();
        } else {
            this.props.map.when(() => {
                this._setToolLayers();
            });
        }

        this._createListeners();
    }

    componentWillUnmount(): void {
        this._removeListeners();
        this.toolbars.forEach((toolbar) => {
            if (toolbar.destroy) {
                toolbar.destroy();
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IMapToolsProps): void {
        this._updateToolbars(nextProps);

        const { activeMapTool, activeToolset, currentProcessCounts, zoom } = this.props;
        if (zoom !== nextProps.zoom && nextProps.zoom !== nextProps.map.zoom) {
            this.forceUpdate();
        }

        if (nextProps.currentProcessCounts !== currentProcessCounts) {
            this.processCountTools.setCurrentProcessCounts(nextProps.currentProcessCounts);
        }

        this._changeToolset(activeToolset, nextProps.activeToolset, nextProps);

        if (nextProps.gridRemoveFlag) {
            this.samplingTools.clear();
            this._resetSamplingHistory();
            this.props.onClearRemoveGridFlag();
            this.props.onSetSamplePoints([]);
            this.zoneTools.setSamplingSites([]);
            this.samplingTools.resetTools();
        }

        if (nextProps.batchReset) {
            this.samplingTools.cancel();
            this.samplingTools.field = null;
            this.samplingTools.isBatch = false;
            this.zoneTools.cancel();
            this._setActiveMapTool(null);
            this.props.onSetBatchReset(false);
        }

        if (nextProps.bufferReady === true) {
            // Ensure a buffer op is intended and supported
            if (
                this.props.bufferReady === false &&
                [Toolset.ZONE_EDIT, Toolset.SAMPLING, Toolset.ANALYSIS].includes(
                    nextProps.activeToolset
                )
            ) {
                const { bufferOptions } = nextProps;
                const geometries =
                    bufferOptions.source === "measure"
                        ? this.measureTools.geometries
                        : bufferOptions.geometries;
                const buffersAdded = this.zoneTools.addZonesFromBuffers({
                    ...bufferOptions,
                    geometries,
                });
                if (buffersAdded) {
                    this.props.onSetBufferOptions({ open: false });
                }
            }
            this.props.onSetBufferReady(false);
        }

        if (this.props.importFieldList !== nextProps.importFieldList) {
            this.drawTools.updateImportFieldList(nextProps.importFieldList);
        }

        if (activeMapTool !== nextProps.activeMapTool) {
            switch (nextProps.activeToolset) {
                case Toolset.DRAW_EDIT:
                case Toolset.DRAW_EDIT_CLIP:
                case Toolset.DRAW_EDIT_IMPORT:
                    if (!this.drawTools.getTools().includes(nextProps.activeMapTool as Tool)) {
                        this.drawTools.resetTools();
                    }
                    if (this.cluBoundary?.isSelected()) {
                        this.cluBoundary?.unselect();
                        this._toggleCLU({ selected: false });
                    }
                    break;
                case Toolset.DRAW_NON_FIELD_FEATURE:
                    if (
                        !this.nonFieldFeatureDrawTools.getTools().includes(nextProps.activeMapTool)
                    ) {
                        this.nonFieldFeatureDrawTools.resetTools();
                    }
                    break;
                case Toolset.SAMPLING: {
                    const samplingTools = this.samplingTools
                        .getTools()
                        .concat(this.zoneTools.getTools());
                    if (Tool.SamplingAddSelect === nextProps.activeMapTool) {
                        this.samplingTools.setTheActiveMapTool(nextProps.activeMapTool);
                    } else if (!samplingTools.includes(nextProps.activeMapTool as Tool)) {
                        this.samplingTools.resetTools();
                        this.zoneTools.resetTools();
                    }
                    break;
                }
                case Toolset.ZONE_EDIT:
                    if (!this.zoneTools.getTools().includes(nextProps.activeMapTool as Tool)) {
                        this.zoneTools.resetTools();
                    }
                    break;
                case Toolset.SCOUTING_ZONE_EDIT:
                    if (
                        !this.scoutingZoneTools.getTools().includes(nextProps.activeMapTool as Tool)
                    ) {
                        this.scoutingZoneTools.resetTools();
                    }
                    break;
                default:
                    break;
            }
        }
    }

    render(): JSX.Element {
        const {
            activeToolset,
            isEquationRec,
            isEquationRecProcessing,
            isFromEquationRec,
            isImportedEventRec,
            isNewEventRec,
            isProcessing,
            samplingEvent,
            toolsetDisabled,
            userInfo,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const {
            fieldBoundaryGuid,
            importClassificationFields,
            importZoneGeometries,
            isImportZonesModalOpen,
            isLoadZonesModalOpen,
            isMessageModalOpen,
            isUploadingFiles,
            message,
            title,
        } = this.state;

        const toolbarClasses = ["map-tools"];
        if (!this.props.actionPanelIsExpanded) {
            toolbarClasses.push("action-panel-is-collapsed");
        }
        const activeTools = [];
        const showZoneTools = (toolsetDisabled) => {
            // ensure the disabled flag gets set on the toolbar
            const toolbar = this.zoneTools.getToolbar(toolsetDisabled);
            const show =
                samplingEvent ||
                (!isImportedEventRec &&
                    (!isEquationRec || (isNewEventRec && !isEquationRecProcessing)) &&
                    !isFromEquationRec);
            show && activeTools.unshift(toolbar);
            return show;
        };
        const CLUBoundaryButton = (
            <ToolbarTool
                key="cluBoundary"
                icon={ToolbarIcon(IconType.CLU_BOUNDARY)}
                label={formatMessage(messages.clu)}
                onToggle={(evt) => {
                    this._toggleCLU(evt);
                }}
                ref={(tool) => (this.cluBoundary = tool)}
            />
        );

        switch (activeToolset) {
            case Toolset.DRAW_EDIT:
            case Toolset.DRAW_EDIT_CLIP:
            case Toolset.DRAW_EDIT_IMPORT:
                activeTools.push(
                    ...[
                        this.drawTools.getToolbar(
                            toolsetDisabled,
                            [Toolset.DRAW_EDIT_IMPORT, Toolset.DRAW_EDIT_CLIP].includes(
                                activeToolset
                            )
                        ),
                        this._createUndoButtonForToolset(activeToolset),
                        this._createRedoButtonForToolset(activeToolset),
                        <ToolbarButton
                            key="delete"
                            disabled={toolsetDisabled || !this.drawTools.canDelete()}
                            icon={ToolbarIcon(IconType.DELETE)}
                            label={formatMessage(messages.deleteLbl)}
                            onClick={() => {
                                logFirebaseEvent("field_setup_delete");
                                this._openConfirmDialog(Confirm.DELETE);
                            }}
                        />,
                        <ToolbarButton
                            key="import"
                            disabled={
                                toolsetDisabled ||
                                this.drawTools.disabled ||
                                this.drawTools.activeTool != null
                            }
                            icon={ToolbarIcon(IconType.IMPORT)}
                            label={formatMessage(messages.importLbl)}
                            onClick={() => {
                                logFirebaseEvent("field_setup_import");
                                this.#fileInput.click();
                            }}
                        />,
                        userInfo.role.cluBoundaries ? CLUBoundaryButton : null,
                    ].filter(Boolean)
                );
                break;
            case Toolset.DRAW_NON_FIELD_FEATURE:
                activeTools.push(
                    ...[
                        this.nonFieldFeatureDrawTools.getToolbar(toolsetDisabled),
                        this._createUndoButtonForToolset(activeToolset),
                        this._createRedoButtonForToolset(activeToolset),
                        <ToolbarButton
                            key="delete"
                            disabled={toolsetDisabled || !this.nonFieldFeatureDrawTools.canDelete()}
                            icon={ToolbarIcon(IconType.DELETE)}
                            label={formatMessage(messages.deleteLbl)}
                            onClick={() =>
                                this._openConfirmDialog(Confirm.DELETE_NON_FIELD_FEATURE)
                            }
                        />,
                    ]
                );

                break;
            case Toolset.SAMPLING: {
                const disabled = toolsetDisabled || this.adjustingSamplingGrid || this.isTracing;
                const importDisabled =
                    disabled || this.zoneTools.disabled || this.zoneTools.activeTool != null;
                activeTools.push(
                    ...[
                        this.samplingTools.getToolbar(disabled),
                        this._createUndoButtonForToolset(activeToolset),
                        this._createRedoButtonForToolset(activeToolset),
                        <ToolbarButton
                            key="import"
                            disabled={importDisabled}
                            icon={ToolbarIcon(IconType.IMPORT)}
                            label={formatMessage(messages.importLbl)}
                            onClick={() => this.#fileInput.click()}
                        />,
                    ]
                );
                showZoneTools(disabled);
                break;
            }
            case Toolset.SAMPLING_GRID:
            case Toolset.SAMPLING_TRACE: {
                const disabled = toolsetDisabled || this.adjustingSamplingGrid || this.isTracing;
                activeTools.push(
                    ...[
                        this.samplingTools.getToolbar(disabled),
                        this._createUndoButtonForToolset(activeToolset),
                        this._createRedoButtonForToolset(activeToolset),
                    ]
                );
                showZoneTools(disabled);
                break;
            }
            case Toolset.ZONE_EDIT:
            case Toolset.ANALYSIS:
                if (showZoneTools(toolsetDisabled)) {
                    activeTools.push(
                        ...[
                            this._createUndoButtonForToolset(activeToolset),
                            this._createRedoButtonForToolset(activeToolset),
                            <ToolbarButton
                                key="import"
                                disabled={
                                    toolsetDisabled ||
                                    this.zoneTools.disabled ||
                                    this.zoneTools.activeTool != null ||
                                    !isNewEventRec
                                }
                                icon={ToolbarIcon(IconType.IMPORT)}
                                label={formatMessage(messages.importLbl)}
                                onClick={() => this.#fileInput.click()}
                            />,
                        ]
                    );
                }
                break;
            case Toolset.ZONE_COPY:
                if (showZoneTools(toolsetDisabled)) {
                    activeTools.push(
                        ...[
                            this._createUndoButtonForToolset(activeToolset),
                            this._createRedoButtonForToolset(activeToolset),
                        ]
                    );
                }
                break;
            case Toolset.SCOUTING_ZONE_EDIT:
                activeTools.push(
                    ...[
                        this.scoutingZoneTools.getToolbar(toolsetDisabled),
                        this._createUndoButtonForToolset(activeToolset),
                        this._createRedoButtonForToolset(activeToolset),
                    ]
                );
                break;
            default:
                if (userInfo.role.cluBoundaries) {
                    this.cluBoundary?.unselect();
                    this._updateCLUBoundary(false);
                }
                break;
        }

        return (
            <div className="map-toolbar">
                <Toolbar
                    className={classnames(toolbarClasses, "primary")}
                    isProcessing={isProcessing || isUploadingFiles}
                    onToggleMenu={(e) => this._onToggleMenu(e)}
                    ref={(ref) => (this.primaryToolbar = ref)}
                >
                    {this.extentTools.getToolbar()}
                    {this.measureTools.getToolbar()}
                    {this.searchTools.getToolbar()}
                    {activeTools}
                </Toolbar>
                <Toolbar
                    className={classnames(toolbarClasses)}
                    onToggleMenu={(e) => this._onToggleStatusMenu(e)}
                    ref={(ref) => (this.statusToolbar = ref)}
                >
                    {this.processCountTools.getToolbar()}
                </Toolbar>
                {this.renderConfirmationDialog()}
                <DialogBox
                    closeOnEscape
                    closeOnClickOff
                    draggable
                    isOpen={isMessageModalOpen}
                    onClose={() => this._closeMessageDialog()}
                    title={title}
                >
                    {message}
                </DialogBox>
                <LoadZonesModal
                    fieldBoundaryGuid={fieldBoundaryGuid}
                    isOpen={isLoadZonesModalOpen}
                    onAction={(e) => this._loadZones(e)}
                    onClose={() => this._closeLoadZoneModal()}
                />
                <ImportZonesModal
                    isOpen={isImportZonesModalOpen}
                    classFields={importClassificationFields}
                    features={importZoneGeometries}
                    onAction={(c, n) => this._importZones(c, n)}
                    onClose={() => this._closeImportZoneModal()}
                />
                <input
                    ref={(e) => (this.#fileInput = e)}
                    style={{ display: "none" }}
                    type="file"
                    multiple
                    accept=".shp,.shx,.dbf,.prj,.zip"
                    onChange={() => this._importFiles(this.#fileInput.files)}
                />
            </div>
        );
    }
}
