import React, { Component, PureComponent } from "react";
import ReactDOM from "react-dom";
import Measure from "react-measure";
import classnames from "classnames";

import { InjectedIntl, injectIntl } from "react-intl";
import { messages } from "../../../i18n-messages";
import { SelectInput } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";

import { updateSampleLabelSequence, isSampleLabelComposite } from "@ai360/core";

import { IPicklistOption, ISamplePointTablePoint } from "../models";

import "./sample-point-table.css";
import { ITissueSamplePoint } from "@ai360/core/dist/4x/es/api/ag-event/ag-event.4x";

interface IProductivityRatingCellProps {
    intl: InjectedIntl;
    onSetProdRating: (productivityRatingOption: string) => void;
    productivityRatingGuid: string;
    productivityRatingOptions: IPicklistOption[];
}

class ProductivityRatingCell extends PureComponent<IProductivityRatingCellProps> {
    public render() {
        const { productivityRatingGuid, productivityRatingOptions, onSetProdRating } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div onClick={this._preventDefault}>
                <SelectInput
                    maxHeight={500}
                    optionIsHiddenKey={ACTIVE_YN}
                    minOptionsWidth={175}
                    onChange={onSetProdRating}
                    options={productivityRatingOptions}
                    placeholderText={formatMessage(messages.ratingTxt)}
                    value={productivityRatingGuid}
                />
            </div>
        );
    }

    private _preventDefault = (evt: any): void => {
        evt.preventDefault();
    };
}

interface ISamplePointTableProps {
    intl: InjectedIntl;
    onSetPointProdRating: (pointIdx: number, productivityRatingOption: string) => void;
    onSetSelectedPointSet: (selectedPointSet: Set<string>) => void;
    productivityRatingOptions: IPicklistOption[];
    samplePoints: ISamplePointTablePoint[] | ITissueSamplePoint[];
    selectedPointSet: Set<string>;
    showProductivityRating: boolean;
}

interface ISamplePointTableState {
    colWidths: number[];
    isComposite: boolean;
    focusedRatingInput: number;
    scrollLeft: number;
    scrollTop: number;
    samplePointsSequenceMap: Map<string, number>;
}

export class SamplePointTable_ extends Component<ISamplePointTableProps, ISamplePointTableState> {
    private lastClickedWithoutShiftIndex = null;
    private containerNode = null;
    private container = null;

    constructor(props: ISamplePointTableProps) {
        super(props);

        this.state = {
            colWidths: [],
            isComposite: isSampleLabelComposite(props.samplePoints as ITissueSamplePoint[]),
            focusedRatingInput: null,
            scrollLeft: 0,
            scrollTop: 0,
            samplePointsSequenceMap: new Map<string, number>(),
        };
        updateSampleLabelSequence(
            this.state.samplePointsSequenceMap,
            props.samplePoints as ITissueSamplePoint[]
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ISamplePointTableProps): void {
        const samplePointsChanged = nextProps.samplePoints !== this.props.samplePoints;
        if (samplePointsChanged) {
            this.setState({
                isComposite: isSampleLabelComposite(nextProps.samplePoints as ITissueSamplePoint[]),
            });
            const seqMap = this.state.samplePointsSequenceMap;
            updateSampleLabelSequence(seqMap, nextProps.samplePoints as ITissueSamplePoint[]);
            this.setState({ samplePointsSequenceMap: seqMap });
        }
    }

    public render(): JSX.Element {
        const { samplePoints } = this.props;
        return (
            <div
                ref={(ref) => (this.container = ref)}
                className="sample-point-tbl"
                onScroll={this._onScroll}
            >
                {this._getHeader(false)}
                {this._getHeader(true)}
                {samplePoints.map((point, pointIdx) => this._getRow(point, pointIdx))}
            </div>
        );
    }

    private _getColumnHorizontalPos = (columnIndex: number, isStickyCol: boolean): number => {
        const { colWidths, scrollLeft } = this.state;
        const prevColumnsWidth = colWidths
            .slice(0, columnIndex)
            .reduce((accum, width) => accum + width, 0);
        if (!isStickyCol) {
            return prevColumnsWidth;
        }
        return prevColumnsWidth + scrollLeft;
    };

    private _getHeader(isStickyRow: boolean): JSX.Element {
        const { showProductivityRating } = this.props;
        const { formatMessage } = this.props.intl;

        const stickyColCount = showProductivityRating ? 2 : 1;

        const columnIndices = Array.from({ length: stickyColCount }, (_, idx) => idx);
        const headerCells = columnIndices.map((columnIndex) => {
            const isStickyCol = columnIndex < stickyColCount;
            const className = classnames(
                {
                    "prod-rating": showProductivityRating && columnIndex === 1,
                    "sticky-col": isStickyCol,
                    sticky: isStickyRow,
                },
                "header-cell"
            );

            if (!isStickyRow) {
                const spacerCell = <div className={className}>{columnIndex}</div>;
                return (
                    <Measure
                        key={`header_${columnIndex}`}
                        onMeasure={this._setColWidth.bind(this, columnIndex)}
                    >
                        {spacerCell}
                    </Measure>
                );
            }

            const stickyStyle = {
                left: this._getColumnHorizontalPos(columnIndex, isStickyCol),
                top: this.state.scrollTop,
                width: this.state.colWidths[columnIndex],
            };
            return (
                <div key={`header_${columnIndex}`} className={className} style={stickyStyle}>
                    {columnIndex === 0
                        ? formatMessage(messages.idColLabel)
                        : formatMessage(messages.ratingTxt)}
                </div>
            );
        });

        return <div className="header">{headerCells}</div>;
    }

    private _getRow(samplePoint: ISamplePointTablePoint, pointIndex: number): JSX.Element {
        const { selectedPointSet, showProductivityRating } = this.props;
        const stickyColCount = showProductivityRating ? 2 : 1;

        const columnIndices = Array.from({ length: stickyColCount }, (_, idx) => idx);
        const rowCells = columnIndices.map((columnIndex) => {
            const isStickyCol = columnIndex < stickyColCount;
            const className = classnames(
                {
                    "fixed-container": this.state.focusedRatingInput === pointIndex,
                    "prod-rating": showProductivityRating && columnIndex === 1,
                    selected: selectedPointSet.has(samplePoint.eventSampleTissuePointGuid),
                    "sticky-col": isStickyCol,
                    sticky: isStickyCol,
                },
                "body-cell"
            );

            const onClick = (event) => {
                if (event.defaultPrevented) {
                    return;
                }
                this._selectPoints(pointIndex, event.ctrlKey, event.shiftKey);
            };

            const handleFocus = showProductivityRating && columnIndex === 1;
            const onFocus = !handleFocus
                ? null
                : () => {
                      this.setState({ focusedRatingInput: pointIndex });
                  };
            const onBlur = !handleFocus
                ? null
                : () => {
                      this.setState({ focusedRatingInput: null });
                  };

            const stickyStyle = !isStickyCol
                ? null
                : {
                      left: this._getColumnHorizontalPos(columnIndex, isStickyCol),
                      width: this.state.colWidths[columnIndex],
                  };
            return (
                <div
                    key={`${samplePoint.eventSampleTissuePointGuid}_${columnIndex}`}
                    className={className}
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    style={stickyStyle}
                >
                    {columnIndex === 0
                        ? this._getSamplePointId(samplePoint)
                        : this._getSampleProductivityRating(samplePoint, pointIndex)}
                </div>
            );
        });

        for (let columnIndex = 0; columnIndex < stickyColCount; columnIndex++) {
            const className = classnames("body-cell", {
                "prod-rating": showProductivityRating && columnIndex === 1,
            });
            rowCells.splice(
                0,
                0,
                <div
                    key={`${samplePoint.eventSampleTissuePointGuid}_flexspacer_${columnIndex}`}
                    className={className}
                />
            );
        }

        return (
            <div key={`${samplePoint.eventSampleTissuePointGuid}`} className="row">
                {rowCells}
            </div>
        );
    }

    private _getSamplePointId(samplePoint: ISamplePointTablePoint): JSX.Element {
        const seqId = this.state.samplePointsSequenceMap.get(
            samplePoint.eventSampleTissuePointGuid
        );
        return (
            <div className="body-text">
                {samplePoint.sequenceId == null
                    ? "-"
                    : this.state.isComposite
                    ? `${samplePoint.sampleId} - ${seqId}`
                    : `${samplePoint.sampleId}`}
            </div>
        );
    }

    private _getSampleProductivityRating(
        samplePoint: ISamplePointTablePoint,
        pointIndex: number
    ): JSX.Element {
        const { intl, onSetPointProdRating, productivityRatingOptions } = this.props;
        return (
            <ProductivityRatingCell
                intl={intl}
                onSetProdRating={onSetPointProdRating.bind(this, pointIndex)}
                productivityRatingGuid={samplePoint.productivityRatingGuid}
                productivityRatingOptions={productivityRatingOptions}
            />
        );
    }

    private _onScroll = (evt: any): void => {
        const { target } = evt;
        if (this.containerNode == null && this.container != null) {
            // eslint-disable-next-line react/no-find-dom-node
            this.containerNode = ReactDOM.findDOMNode(this.container);
        }
        if (target !== this.containerNode) {
            return;
        }
        const { scrollLeft, scrollTop } = target;
        this.setState({ scrollLeft, scrollTop });
    };

    private _selectPoints = (pointIndex: number, withCtrl: boolean, withShift: boolean): void => {
        const { samplePoints, selectedPointSet } = this.props;
        const noModifiers = !withCtrl && !withShift;

        const newSelectedPointSet = new Set<string>();
        if (noModifiers) {
            newSelectedPointSet.add(samplePoints[pointIndex].eventSampleTissuePointGuid);
        } else if (withCtrl) {
            [...selectedPointSet].forEach((eventSampleTissuePointGuid) =>
                newSelectedPointSet.add(eventSampleTissuePointGuid)
            );

            const toggleSamplePoint = samplePoints[pointIndex];
            if (selectedPointSet.has(toggleSamplePoint.eventSampleTissuePointGuid)) {
                newSelectedPointSet.delete(toggleSamplePoint.eventSampleTissuePointGuid);
            } else {
                newSelectedPointSet.add(toggleSamplePoint.eventSampleTissuePointGuid);
            }
        } else {
            console.assert(withShift);

            // Select all options between this option and the last non-select-click
            if (this.lastClickedWithoutShiftIndex == null) {
                this.lastClickedWithoutShiftIndex = 0;
            }

            samplePoints
                .slice(
                    Math.min(this.lastClickedWithoutShiftIndex, pointIndex),
                    Math.max(this.lastClickedWithoutShiftIndex, pointIndex) + 1
                )
                .forEach((samplePoint) =>
                    newSelectedPointSet.add(samplePoint.eventSampleTissuePointGuid)
                );
        }

        if (!withShift) {
            this.lastClickedWithoutShiftIndex = pointIndex;
        }

        this.props.onSetSelectedPointSet(newSelectedPointSet);
    };

    private _setColWidth = (columnIndex: number, { width }): void => {
        const { colWidths } = this.state;
        if (colWidths[columnIndex] === width) {
            return;
        }
        colWidths[columnIndex] = width;
        this.setState({ colWidths: [...colWidths] });
    };
}

export const SamplePointTable = injectIntl(SamplePointTable_);
