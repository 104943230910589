import {
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from "amazon-cognito-identity-js";
import Immutable from "immutable";
import * as aws from "aws-sdk";
import fileSaver from "file-saver";
import { delay } from "redux-saga";
import {
    AllEffect,
    CallEffect,
    PutEffect,
    SelectEffect,
    TakeEffect,
    all,
    call,
    put,
    race,
    select,
    take,
    takeLatest,
} from "redux-saga/effects";

import {
    AppHelpers,
    LocalStorageHelpers,
    APIErrorWithCode,
    AuthenticationAPI,
    LayerAPI,
    SystemAPI,
    SearchAPI,
    urlBase,
    Credentials,
} from "@ai360/core";

import { actions as cdActions } from "~/customer-data";
import { errorCodeMessages } from "~/i18n-error-messages";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import * as messagingActions from "~/messaging/actions";
import { setIsLoading } from "~/theme";
import * as actions from "./actions";
import { messages } from "./i18n-messages";
import * as selectors from "./selectors";
import { IActionData } from "./interfaces";
import { PayloadAction } from "typesafe-actions/dist/types";

const CUSTOMER_ID = "2";
const SALESPERSON_ID = "4";

const { LoginState } = actions;

const getLoginBackgroundImage = function* () {
    try {
        const response = yield call(AuthenticationAPI.getLoginBackgroundUrl);
        yield put(actions.setLoginBackgroundUrl(response));
    } catch (error) {
        console.error(error);
    }
};

const getSystemFaviconUrl = function* () {
    try {
        const UserGuid = yield select(selectors.getTheUserGuid);
        const companyGuid = yield select(selectors.getTheUserCompanyGuid);
        const response = yield call(SystemAPI.getSystemFaviconUrl, {
            UserGuid,
            Model: companyGuid,
        });
        yield put(actions.setSystemFaviconUrl(response));
    } catch (error) {
        console.error(error);
    }
};

export const getMobileAppDetails = function* (): Generator<
    CallEffect | PutEffect<IActionData> | SelectEffect,
    void,
    any
> {
    try {
        const UserGuid = yield select(selectors.getTheUserGuid);
        const response = yield call(SystemAPI.getMobileAppDetails, {
            UserGuid,
        });
        yield put(actions.setMobileAppDetails(response));
    } catch (error) {
        console.error(error);
    }
};

export const onFetchEnrollmentForm = function* (
    action: IActionData
): Generator<Promise<any>, void, any> {
    const { customerGuid } = action;
    try {
        const response = yield AuthenticationAPI.getEnrollmentForm(customerGuid);
        const fileType = response.url.split(".").pop();
        fileSaver.saveAs(AppHelpers.base64ToBlob(response.file), "EnrollmentAgreement." + fileType);
    } catch (error) {
        console.error(error);
    }
};

export const onFetchFilteredCustomerList = function* (
    action: IActionData
): Generator<CallEffect | PutEffect<PayloadAction<string, boolean> | IActionData>, void, any> {
    const { orgLevelGuid, userGuid } = action;
    yield put(setIsLoading(true));
    try {
        const allCustomersCall = call(SearchAPI.getCustomers, {
            userGuid,
            orgLevelGuids: [orgLevelGuid],
            active: true,
        });

        const withoutLastUsedCustomerCall = call(SearchAPI.getCustomers, {
            userGuid,
            orgLevelGuids: [orgLevelGuid],
            active: true,
            lastUsedCustomer: SearchAPI.LastUsedCustomer.Remove,
        });

        const allCustomers: SearchAPI.ILegacyCustomerResult[] = yield allCustomersCall;
        const withoutLastUsedCustomer: SearchAPI.ILegacyCustomerResult[] =
            yield withoutLastUsedCustomerCall;

        const allCustomerGuids = Immutable.Set(allCustomers.map((x) => x.customerGuid));
        const withoutLastUsedCustomerGuids = Immutable.Set(
            withoutLastUsedCustomer.map((x) => x.customerGuid)
        );

        const lastUsedCustomerGuid = allCustomerGuids
            .subtract(withoutLastUsedCustomerGuids)
            .first();

        yield put(actions.setFilteredCustomers(allCustomers, lastUsedCustomerGuid));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(setIsLoading(false));
    }
};

export const onInitialMobileLoad = function* (): Generator<CallEffect, void, unknown> {
    yield call(getMobileAppDetails);
};

export const onInitialEnrollmentLoad = function* (): Generator<CallEffect, void, unknown> {
    yield call(getLoginBackgroundImage);
    yield call(getSystemFaviconUrl);
    document.title = "Enrollment";
};

const setSessionFromKey = function* (key, history) {
    const { email, session } = yield call(AuthenticationAPI.keyLogin, key);

    const cognitoConfig = JSON.parse(LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG));
    const Pool = new CognitoUserPool(cognitoConfig);
    const cognitoUser = new CognitoUser({
        Pool,
        Username: email.toLowerCase(),
    });
    const userSession = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: session.idToken }),
        RefreshToken: new CognitoRefreshToken({
            RefreshToken: session.refreshToken,
        }),
        AccessToken: new CognitoAccessToken({
            AccessToken: session.accessToken,
        }),
    });
    cognitoUser.setSignInUserSession(userSession);

    yield call(fetchLoginUser, history);
};

const checkSessionIsActive = function* (history) {
    const cognitoConfigJson = LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG);
    if (cognitoConfigJson != null) {
        const userPool = new CognitoUserPool(JSON.parse(cognitoConfigJson));
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            try {
                yield call(fetchLoginUser, history);
                return;
            } catch (err) {
                console.warn("Session inactive", err);
            }
        }
    }
    yield put(actions.setLoginState(LoginState.LOGIN_FORM));
    yield put(actions.setProcessing(false));
};

const fetchCognitoConfig = function* () {
    LocalStorageHelpers.remove(LocalStorageHelpers.COGNITO_CONFIG);
    const idpConfig = yield call(AuthenticationAPI.getIdpConfig);
    LocalStorageHelpers.set(
        LocalStorageHelpers.COGNITO_CONFIG,
        JSON.stringify({
            UserPoolId: idpConfig.cognitoUserPoolId,
            IotIdentityPoolId: idpConfig.iotIdentityPoolId,
            ClientId: idpConfig.identityProviderClientId,
            Region: idpConfig.region,
        })
    );
};

const fetchLoginUser = function* (
    history
): Generator<CallEffect | PutEffect<IActionData> | TakeEffect | SelectEffect, void, any> {
    const response = yield call(AuthenticationAPI.getLoginUser);
    if (response.isFirstTimeLogin || !response.isEulaAcceptanceUpToDate) {
        yield put(
            actions.setEulaInfo(response.eula, response.loginGuid, response.isFirstTimeLogin)
        );
        yield put(actions.setUsersInfo(response.theUsers));
        yield put(actions.setSecurityInfo(response.email));
        yield put(actions.setLoginState(LoginState.EULA));
        yield put(actions.setReleaseNotes(response.releaseNotes));
        yield put(actions.setProcessing(false));
        return;
    }
    if (response.loginGuid != null) {
        yield put(actions.setLoginGuid(response.loginGuid));
    }

    if (response.theUser == null) {
        const companyGuid = LocalStorageHelpers.get(LocalStorageHelpers.COMPANY_GUID);
        if (companyGuid && response.theUsers.some((u) => u.companyGuid === companyGuid)) {
            response.theUser = response.theUsers.find((u) => u.companyGuid === companyGuid);
            console.assert(response.theUser != null);
            yield put(
                actions.setUserInfo(
                    response.theUser,
                    response.theUser.lastUsedCompanyGuid,
                    response.theUser.lastUsedLocationGuid,
                    response.theUser.lastUsedCustomerGuid
                )
            );
            yield take(actions.SET_USER_INFO_COMPLETE);
            history.replace(urlBase);
        }
    } else {
        console.assert(response.theUser != null);
        yield put(
            actions.setUserInfo(
                response.theUser,
                response.theUser.lastUsedCompanyGuid,
                response.theUser.lastUsedLocationGuid,
                response.theUser.lastUsedCustomerGuid
            )
        );
        history.replace(urlBase);
    }

    console.assert(response.theUsers != null);
    yield put(actions.setUsersInfo(response.theUsers));
    yield put(actions.setLoginState(LoginState.SELECT_COMPANY));
    const userGuid = yield select(selectors.getTheUserGuid);
    const imageryTileBucketName = yield call(LayerAPI.getImageryTileBucketName, userGuid);
    yield put(actions.setImageryTileBucketName(imageryTileBucketName));
    yield put(actions.setReleaseNotes(response.releaseNotes));
    yield put(actions.setProcessing(false));
};
const autoLogin = function* (action) {
    yield put(actions.setProcessing(true));
    yield call(getLoginBackgroundImage);
    yield call(getSystemFaviconUrl);
    const userGuid = yield select(selectors.getTheUserGuid);
    const imageryTileBucketName = yield call(LayerAPI.getImageryTileBucketName, userGuid);
    yield put(actions.setImageryTileBucketName(imageryTileBucketName));

    const { key, history } = action;
    try {
        yield call(fetchCognitoConfig);
        if (key != null) {
            yield call(setSessionFromKey, key, history);
            return;
        }
        yield call(checkSessionIsActive, history);
    } catch (error) {
        if (!(error instanceof APIErrorWithCode)) {
            console.error(error);
        }
        yield put(actions.resetLogin());
        yield put(actions.setProcessing(false));
    }
};

const fetchSessionInfo = (Username, Password) => {
    const cognitoConfig = JSON.parse(LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG));
    const Pool = new CognitoUserPool(cognitoConfig);
    const authDetails = new AuthenticationDetails({ Username, Password });
    const cognitoUser = new CognitoUser({ Username, Pool });
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (session /*, userConfirmationNecessary */) => resolve(session),
            onFailure: (err) => {
                if (err.name === "UserNotFoundException" || err.name === "NotAuthorizedException") {
                    err = errorCodeMessages[1];
                }
                reject(err);
            },
        });
    });
};

const login = function* (action) {
    const { email, history, password } = action;
    const cognitoConfigJson = LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG);
    if (cognitoConfigJson == null) {
        try {
            yield call(fetchCognitoConfig);
        } catch (error) {
            yield put(actions.setError(error));
            return;
        }
    }
    try {
        yield call(fetchSessionInfo, email.toLowerCase(), password);
        yield call(fetchLoginUser, history);
    } catch (error) {
        yield put(actions.setError(error));
    }
};

const logoutUser = function () {
    try {
        const cognitoConfig = JSON.parse(
            LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG)
        );
        const userPool = new CognitoUserPool(cognitoConfig);
        const cognitoUser = userPool.getCurrentUser();
        cognitoUser.signOut();
    } catch (error) {
        console.error("Encountered an error while attempting to disconnect and logout: ", error);
    } finally {
        LocalStorageHelpers.remove(LocalStorageHelpers.COGNITO_CONFIG);
        LocalStorageHelpers.remove(LocalStorageHelpers.COMPANY_GUID);
        LocalStorageHelpers.remove(LocalStorageHelpers.USER_GUID);
        window.onbeforeunload = null;
        window.location.reload();
    }
};

const refreshSession = (cognitoUser, refreshToken) => {
    return new Promise((resolve) =>
        cognitoUser.refreshSession(refreshToken, (err) => resolve(err))
    );
};

const getCognitoUserSession = (cognitoUser) => {
    return new Promise((resolve, reject) =>
        cognitoUser.getSession((err, session) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(session);
        })
    );
};

const monitorActivity = function* () {
    const inactivityTimeoutSeconds = 7200;
    const timerIntervalSeconds = 30;

    let lastActivityIndicated = Math.floor(+new Date() / 1000);
    let lastDelayCallback = Math.floor(+new Date() / 1000);

    let userActivity = false;
    const indicateActivity = () => {
        userActivity = true;
    };
    window.addEventListener("mousemove", indicateActivity, false);
    window.addEventListener("keypress", indicateActivity, false);

    /**
     * 1. Log the user out automatically after `inactivityTimeoutMillis`
     * 2. Refresh the token if it will timeout before the next `timerIntervalMillis`
     */

    const cognitoConfig = JSON.parse(LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG));
    const userPool = new CognitoUserPool(cognitoConfig);
    const cognitoUser = userPool.getCurrentUser();
    console.assert(cognitoUser != null);

    while (true) {
        const { logoutAction } = yield race({
            _: call(delay, timerIntervalSeconds * 1000),
            logoutAction: take(actions.LOGOUT_USER),
        });
        if (logoutAction != null) {
            // explicity logout action posted
            break;
        }

        const now = Math.floor(+new Date() / 1000);
        let session;
        try {
            session = yield call(getCognitoUserSession, cognitoUser);
        } catch (err) {
            console.warn("Error fetching session", err);
            // This generally only happens when a machine resumes from suspend ..
            // if we've missed more than 3 iterations on this loop, we just logout the
            // user, which should refresh the app.  We have to do this because signalR
            // will disconnect without any status change event, so the app won't get
            // the updates and there's no indication in the UI that anything's wrong.
            // After updating the signalR library, we may way to revisit this (i.e.,
            // there's no real reason to force a logout here unless the time is >
            // than `inactivityTimeoutSeconds`, which is much longer than
            // `callbackDelayTimeout`)
            const callbackDelayTimeout = timerIntervalSeconds * 3;
            const timeSinceDelayCallback = now - lastDelayCallback;
            if (timeSinceDelayCallback > callbackDelayTimeout) {
                yield put(actions.logoutUser());
                break;
            }
            continue;
        }
        lastDelayCallback = now;

        console.assert(session != null);

        const adjusted = now - session.clockDrift;
        const sessionTimeoutSeconds = session.getIdToken().getExpiration() - adjusted;
        if (sessionTimeoutSeconds < timerIntervalSeconds * 3) {
            const refreshErr = yield call(refreshSession, cognitoUser, session.getRefreshToken());
            if (refreshErr != null) {
                if (sessionTimeoutSeconds < 0) {
                    yield put(actions.logoutUser());
                    break;
                }
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.unableToKeepAlive,
                        MSGTYPE.WARNING,
                        {
                            seconds: sessionTimeoutSeconds,
                        }
                    )
                );
            } else {
                const iotConfig = JSON.parse(
                    LocalStorageHelpers.get(LocalStorageHelpers.IOT_CONFIG)
                );
                const identityPoolId = iotConfig.iotIdentityPoolId;
                const messagingCredentials = yield call(
                    retrieveMessagingCredentials,
                    identityPoolId
                );
                yield put(messagingActions.updateCredentials(messagingCredentials));
            }
        }

        if (userActivity) {
            lastActivityIndicated = Math.floor(+new Date() / 1000);
        } else {
            const timeSinceLastActivity = now - lastActivityIndicated;
            const inactivityTimeRemaining = inactivityTimeoutSeconds - timeSinceLastActivity;
            if (inactivityTimeRemaining <= 0) {
                yield put(actions.logoutUser());
                break;
            }
            if (
                inactivityTimeRemaining <= timerIntervalSeconds * 2 &&
                inactivityTimeRemaining > timerIntervalSeconds
            ) {
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.loginTimeoutWarning,
                        MSGTYPE.WARNING,
                        { seconds: inactivityTimeRemaining },
                        (inactivityTimeRemaining - 1) * 1000
                    )
                );
            }
        }
        userActivity = false;
    }

    window.removeEventListener("mousemove", indicateActivity, false);
    window.removeEventListener("keypress", indicateActivity, false);
};

const onSetUserInfo = function* (action) {
    const { theUser, selectedCompanyGuid, selectedCustomerGuid, selectedLocationGuid, isReset } =
        action;
    try {
        const theUsers = yield select(selectors.getUsers);
        if (theUsers) {
            yield put(
                actions.setUsersInfo([
                    ...theUsers.map((usr) => ({
                        ...usr,
                        lastUsedCompanyGuid: selectedCompanyGuid,
                        lastUsedCustomerGuid: selectedCustomerGuid,
                        lastUsedLocationGuid: selectedLocationGuid,
                    })),
                ])
            );
        }
        yield put(cdActions.clearAllSelectedFields());
        yield call(
            AuthenticationAPI.SetLastUsedLogin,
            theUser.userGuid,
            selectedCompanyGuid,
            selectedLocationGuid,
            selectedCustomerGuid
        );
        LocalStorageHelpers.set(LocalStorageHelpers.USER_GUID, theUser.userGuid);
        yield put(actions.resetApp());
        if (!isReset) {
            yield setupMessaging();
            yield put(actions.setUserInfoComplete(theUser));
        } else {
            yield put(actions.continueReset());
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
};

const onReloginUser = function* () {
    yield put(setIsLoading(true));
    yield take([actions.CONTINUE_RESET]);
    yield put(setIsLoading(false));
    window.location.reload();
};

const setupMessaging = function* () {
    const iotConfig = yield call(retrieveAndSetIotConfig);

    const identityPoolId = iotConfig.iotIdentityPoolId;
    const host = iotConfig.host;

    const userGuid = yield select(selectors.getTheUserGuid);
    const userTypeId = yield select(selectors.getUserTypeId);
    const userHasRealTimeUpdates = yield select(selectors.getUserHasRealTimeFieldUpdates);

    const orgLevelGuids =
        userHasRealTimeUpdates && userTypeId !== CUSTOMER_ID && userTypeId !== SALESPERSON_ID
            ? yield select(selectors.getOrgLevelGuids)
            : null;

    const messagingCredentials = yield call(retrieveMessagingCredentials, identityPoolId);

    yield put(messagingActions.start(userGuid, orgLevelGuids, host, messagingCredentials));
};

const retrieveAndSetIotConfig = function* () {
    const iotConfig = yield call(AuthenticationAPI.getIotConfig);
    LocalStorageHelpers.set(LocalStorageHelpers.IOT_CONFIG, JSON.stringify(iotConfig));
    return iotConfig;
};

const retrieveMessagingCredentials = function* (identityPoolId) {
    const iotCredentials = yield call(identityPoolCredentials, identityPoolId);
    return {
        accessKeyId: iotCredentials.AccessKeyId,
        secretKey: iotCredentials.SecretKey,
        sessionToken: iotCredentials.SessionToken,
    };
};

const identityPoolCredentials = (identityPoolId) => {
    const cognitoConfig = JSON.parse(LocalStorageHelpers.get(LocalStorageHelpers.COGNITO_CONFIG));
    const region = cognitoConfig.Region;
    const userPoolId = cognitoConfig.UserPoolId;

    return Credentials.userPoolToken().then((jwt) => {
        const login = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
        aws.config.region = region;

        const logins = {
            [login]: jwt,
        };

        return AuthenticationAPI.getIotIdentityId(identityPoolId, logins).then((response) => {
            const cognitoIdentity = new aws.CognitoIdentity();
            const params = {
                IdentityId: response.identityId,
                Logins: logins,
            };
            return new Promise((resolve, reject) =>
                cognitoIdentity.getCredentialsForIdentity(params, (error, data) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(data.Credentials);
                    }
                })
            );
        });
    });
};

export const userSaga = function* (): Generator<AllEffect, void, any> {
    yield all([
        takeLatest(actions.AUTO_LOGIN, autoLogin),
        takeLatest(actions.FETCH_ENROLLMENT_FORM, onFetchEnrollmentForm),
        takeLatest(actions.FETCH_FILTERED_CUSTOMER_LIST, onFetchFilteredCustomerList),
        takeLatest(actions.INITIAL_MOBILE_LOAD, onInitialMobileLoad),
        takeLatest(actions.INITIAL_ENROLLMENT_LOAD, onInitialEnrollmentLoad),
        takeLatest(actions.LOGIN, login),
        takeLatest(actions.RELOGIN_USER, onReloginUser),
        takeLatest(actions.SET_USER_INFO, onSetUserInfo),
        takeLatest(actions.SET_USER_INFO_COMPLETE, monitorActivity),
        takeLatest(actions.SET_USER_INFO_COMPLETE, getSystemFaviconUrl),
        takeLatest(actions.LOGOUT_USER, logoutUser),
    ]);
};
