import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { Checkbox, NoLink } from "~/core";
import { UpArrowIcon, DownArrowIcon } from "~/core/icons";

import { models as recsEventsModels } from "~/recs-events";

import { setEquationGroupList, setActiveEquationGroup } from "../../actions";

import * as selectors from "../../selectors";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "../rec-equation-application/rec-equation-application-form.css";

const messages = defineMessages({
    selectText: {
        id: "rec-info.selectText",
        defaultMessage: "Select",
    },
    nutrientsText: {
        id: "rec-info.nutrientsText",
        defaultMessage: "Nutrients",
    },
});

export class RecEquationGroupList_ extends PureComponent {
    static propTypes = {
        recEventDetails: PropTypes.object,
        isPlanting: PropTypes.bool,
        isSelectable: PropTypes.bool,
        equationGroupList: PropTypes.array,
        onSetActiveEquationGroup: PropTypes.func.isRequired,
        preferencesNeedUpdated: PropTypes.bool,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.expandedGroups = new Set();
        this.preferencesNeedUpdated = false;
    }

    _isAnySelected = (equations) => {
        return Boolean(equations.some((eq) => eq.isSelected));
    };

    _toggleEquationGroup = (index) => {
        if (this.props.isPlanting) {
            return null;
        } else if (this.expandedGroups.has(index)) {
            this.expandedGroups.delete(index);
        } else {
            this.expandedGroups.add(index);
        }
        this.forceUpdate();
    };

    _updateNutrientStatus = (nutrient, value) => {
        this.preferencesNeedUpdated = true;
        const targetEquationGroup = this.props.equationGroupList.find(
            (equationGroup) => equationGroup.equationGroupGuid === nutrient.equationGroupGuid
        );
        const targetEquation = targetEquationGroup.equations.find(
            (equation) => equation.nutrientGuid === nutrient.nutrientGuid
        );
        targetEquation.isSelected = value;
        targetEquationGroup.equations.map((eq) => {
            return eq.id === targetEquation.id ? targetEquation : eq;
        });
        const revisedFilteredEquationGroupList = this.props.equationGroupList.map((eqGroup) => {
            return eqGroup.equationGroupGuid === targetEquationGroup.equationGroupGuid
                ? targetEquationGroup
                : eqGroup;
        });
        setEquationGroupList(revisedFilteredEquationGroupList);
        this.forceUpdate();
    };

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="equation-group-list">
                {this.props.equationGroupList.map((equationGroup, index) => {
                    const nutrientList = new Set();
                    let lastNutrientPushed = null;
                    let updateNutrientList = true;
                    let isSelectable = this._isAnySelected(equationGroup.equations);
                    equationGroup.equations.forEach((nutrient) => {
                        if (lastNutrientPushed) {
                            if (
                                lastNutrientPushed.nutrientName !== nutrient.nutrientName ||
                                nutrient.equationId < lastNutrientPushed.equationId
                            ) {
                                updateNutrientList = true;
                                if (nutrient.equationId < lastNutrientPushed.equationId) {
                                    nutrientList.delete(lastNutrientPushed);
                                }
                            }
                        }
                        if (updateNutrientList) {
                            lastNutrientPushed = nutrient;
                            nutrientList.add({
                                nutrientName: lastNutrientPushed.nutrientName,
                                equationId: lastNutrientPushed.equationId,
                                nutrientGuid: lastNutrientPushed.nutrientGuid,
                                description: lastNutrientPushed.equationDescription,
                                isSelected: lastNutrientPushed.isSelected,
                                equationGroupGuid: lastNutrientPushed.equationGroupGuid,
                            });
                            updateNutrientList = false;
                        }
                    });
                    const symbol = this.props.isPlanting ? null : this.expandedGroups.has(index) ? (
                        <UpArrowIcon className="equation-group-arrow-icon" />
                    ) : (
                        <DownArrowIcon className="equation-group-arrow-icon" />
                    );
                    return (
                        <div key={`equation-group-${index}`} className="equation-group-row">
                            <div className="equation-group-name-select">
                                <div
                                    className="equation-group-name"
                                    onClick={() => this._toggleEquationGroup(index)}
                                >
                                    <div title={equationGroup.description}>
                                        {symbol}
                                        {equationGroup.groupName}
                                    </div>
                                </div>
                                <div className="equation-group-select">
                                    <NoLink
                                        disabled={!isSelectable}
                                        label={formatMessage(messages.selectText)}
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            this.props.onSetActiveEquationGroup(
                                                equationGroup,
                                                this.preferencesNeedUpdated
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            {!this.expandedGroups.has(index) ? null : (
                                <div className="equation-group-description-attributes">
                                    <div className="nutrient-text">{`${formatMessage(
                                        messages.nutrientsText
                                    )}:  `}</div>
                                    {Array.from(nutrientList).map((nutrient, attrIndex) => (
                                        <div
                                            key={`nutrient-${index}-${attrIndex}`}
                                            className="grid-attribute"
                                        >
                                            <Checkbox
                                                title={
                                                    nutrient.description
                                                        ? `${nutrient.equationId} - ${nutrient.description} `
                                                        : `${nutrient.equationId}`
                                                }
                                                label={nutrient.nutrientName}
                                                value={nutrient.isSelected}
                                                onChange={(evt, v) =>
                                                    this._updateNutrientStatus(nutrient, v)
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    equationGroupList: selectors.getEquationGroupList(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetActiveEquationGroup: (equationGroup, updatePreferences, recEventDetails) =>
        dispatch(setActiveEquationGroup(equationGroup, updatePreferences, recEventDetails)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    isPlanting:
        ownProps.recEventDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING,
    onSetActiveEquationGroup: (equationGroup, updatePreferences) =>
        dispatchProps.onSetActiveEquationGroup(
            equationGroup,
            updatePreferences,
            ownProps.recEventDetails
        ),
});

export const RecEquationGroupList = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecEquationGroupList_));
