import React, { Component, PureComponent } from "react";
import classnames from "classnames";
import Measure from "react-measure";
import "./sample-results-table.css";
import { messages } from "../../../i18n-messages";
import { Loader, SelectInput } from "~/core";
import { IFormatter, ISelectOption } from "@ai360/core";
import { ACTIVE_YN } from "~/core/picklist";
import { ISamplePointTablePoint, ISampleResults } from "../models";
import { ICellProps } from "../../models";

interface IProductivityRatingCellProps {
    intl: IFormatter;
    onSetProdRating: (productivityRatingOption: string) => void;
    productivityRatingGuid: string;
    productivityRatingOptions: ISelectOption<string>[];
}

class ProductivityRatingCell extends PureComponent<IProductivityRatingCellProps> {
    render() {
        const { productivityRatingGuid, productivityRatingOptions, onSetProdRating } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div onClick={(evt) => evt.preventDefault()}>
                <SelectInput
                    horizontalMenuAlign={true}
                    maxHeight={500}
                    minOptionsWidth={175}
                    optionIsHiddenKey={ACTIVE_YN}
                    onChange={(val) => onSetProdRating(val)}
                    options={productivityRatingOptions}
                    placeholderText={formatMessage(messages.ratingTxt)}
                    showTopLabel={false}
                    value={productivityRatingGuid}
                />
            </div>
        );
    }
}

interface ISampleResultsTableProps {
    intl: IFormatter;
    onSetPointProdRating: (pointIdx: number, productivityRatingOption: string) => void;
    onSetSelectedPointSet: (selectedPointSet: Set<string>) => void;
    productivityRatingOptions: ISelectOption<string>[];
    samplePoints: ISamplePointTablePoint[];
    selectedPointSet: Set<string>;
    showProductivityRating: boolean;
    soilSampleResults: ISampleResults;
}

interface ISampleResultsTableState {
    attribColWidth: number;
    columnNameToPointIdxMap: Map<string, number>;
    pointGuidToColumnIdxMap: Map<string, number>;
    scrollLeft: number;
    scrollTop: number;
}

export class SampleResultsTable extends Component<
    ISampleResultsTableProps,
    ISampleResultsTableState
> {
    private lastClickedWithoutShiftIndex = null;

    constructor(props: ISampleResultsTableProps) {
        super(props);
        const [columnNameToPointIdxMap, pointGuidToColumnIdxMap] = this._getColumnPointMaps(props);
        this.state = {
            attribColWidth: null,
            columnNameToPointIdxMap,
            pointGuidToColumnIdxMap,
            scrollLeft: 0,
            scrollTop: 0,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: ISampleResultsTableProps): void {
        if (
            this.props.samplePoints !== nextProps.samplePoints ||
            this.props.soilSampleResults !== nextProps.soilSampleResults
        ) {
            const [columnNameToPointIdxMap, pointGuidToColumnIdxMap] =
                this._getColumnPointMaps(nextProps);
            this.setState({
                columnNameToPointIdxMap,
                pointGuidToColumnIdxMap,
            });
        }
    }
    _getColumnPointMaps(
        props: ISampleResultsTableProps
    ): [Map<string, number>, Map<string, number>] {
        const { samplePoints, soilSampleResults } = props;

        const matchPairs: string[][][] | number[][][] = soilSampleResults.gridHeader
            .map(({ columnName }, columnIdx) => {
                if (columnIdx === 0 || columnName == null) {
                    return null;
                }
                const seqIdMatch = columnName.match(/^((\d+)|\d+-(\d+))[A-E]?$/);
                if (seqIdMatch == null || seqIdMatch.length !== 4) {
                    console.warn("Unexpected `samplePointSequenceDepthId` format", columnName);
                    return null;
                }
                const sequenceId = Number(seqIdMatch[2] || seqIdMatch[3]);
                const pointIndex = samplePoints.findIndex((p) => p.sequenceId === sequenceId);
                if (pointIndex < 0) {
                    console.warn(
                        "No sample point matched `samplePointSequenceDepthId`",
                        columnName
                    );
                    return null;
                }
                const { soilSamplePointGuid } = samplePoints[pointIndex];
                return [
                    [columnName, pointIndex],
                    [soilSamplePointGuid, columnIdx],
                ];
            })
            .filter((pairs) => pairs != null);

        const columnNameToPointIdxMap = new Map<string, number>(
            matchPairs.map((pairs) => pairs[0] as [string, number])
        );
        const pointGuidToColumnIdxMap = new Map<string, number>(
            matchPairs.map((pairs) => pairs[1] as [string, number])
        );

        return [columnNameToPointIdxMap, pointGuidToColumnIdxMap];
    }

    _getAttributeNameMeasure(): JSX.Element {
        const { soilSampleResults } = this.props;
        const { attribColWidth } = this.state;
        if (attribColWidth != null) {
            return null;
        }

        const measureText = [soilSampleResults.gridHeader[0].columnName].concat(
            soilSampleResults.gridData.map((data) => data.sampleAttributeName)
        );

        const measureDivs = measureText.map((label, idx) => {
            const isHeader = idx === 0;
            const className = classnames("attribute-name", {
                "header-cell": isHeader,
                "body-cell": !isHeader,
            });
            return (
                <div key={idx} className={className}>
                    {label}
                </div>
            );
        });

        return (
            <div>
                <Measure onMeasure={({ width }) => this.setState({ attribColWidth: width })}>
                    <div className="measure">{measureDivs}</div>
                </Measure>
            </div>
        );
    }

    _getHeaderSpacer(): JSX.Element {
        return (
            <div key="header-spacer" className="row">
                <div className="attribute-name header-cell" />
            </div>
        );
    }

    _getHeader(): JSX.Element {
        const { soilSampleResults } = this.props;
        const { attribColWidth, scrollLeft, scrollTop } = this.state;

        const headerRow = soilSampleResults.gridHeader.map((column) => column.columnName);

        const attribNameStyle = {
            width: attribColWidth || 130,
            minWidth: attribColWidth || 130,
            left: scrollLeft,
        };
        const onClick = (event, colIdx) => {
            if (event.defaultPrevented) {
                return;
            }
            this._selectPoints(colIdx, event.ctrlKey, event.shiftKey);
        };
        const rowStyle = { top: scrollTop };
        return (
            <div key="header" className="row sticky" style={rowStyle}>
                <div className="attribute-name header-cell sticky" style={attribNameStyle}>
                    {soilSampleResults.gridHeader[0].columnName}
                </div>
                {headerRow.map((columnName, colIdx) => {
                    const isAttrName = colIdx === 0;
                    const className = classnames("header-cell", {
                        "attribute-name": isAttrName,
                    });
                    const cellProps: ICellProps = {
                        className,
                        key: columnName,
                        style: null,
                        onClick: () => null,
                    };
                    if (isAttrName) {
                        cellProps.style = attribNameStyle;
                    } else {
                        cellProps.onClick = (e) => onClick(e, colIdx);
                    }
                    return (
                        <div key={`${columnName}-${colIdx}`} {...cellProps}>
                            {columnName}
                        </div>
                    );
                })}
            </div>
        );
    }

    _getBody(): JSX.Element[] {
        const { selectedPointSet, soilSampleResults, showProductivityRating, samplePoints } =
            this.props;
        const { attribColWidth, scrollLeft, pointGuidToColumnIdxMap } = this.state;

        const headerRow = soilSampleResults.gridHeader.slice(1).map((column) => column.columnName);
        const bodyRows = soilSampleResults.gridData.map((data) => {
            const m = new Map(
                data.sampleResultValues.map((v) => [
                    v.samplePointSequenceDepthId,
                    v.sampleResultValue,
                ])
            );
            return [data.sampleAttributeName, data.actualAttributeName].concat(
                headerRow.map((columnName) => m.get(columnName))
            );
        });
        const sequenceToProductRating = new Map(
            samplePoints.map((sp) => [sp.sequenceId.toString(), sp.productivityRatingGuid])
        );
        const productivityRatingRow = ["Productivity Rating", "Productivity Rating"].concat(
            headerRow.map((columnName) => sequenceToProductRating.get(columnName))
        );
        bodyRows.unshift(productivityRatingRow);

        const attribNameStyle = {
            width: attribColWidth || 130,
            minWidth: attribColWidth || 130,
            left: scrollLeft,
        };

        const onClick = (event, colIdx) => {
            if (event.defaultPrevented) {
                return;
            }
            this._selectPoints(colIdx, event.ctrlKey, event.shiftKey);
        };

        const selectedColumnIdxSet = new Set(
            [...selectedPointSet].map((pointGuid) => pointGuidToColumnIdxMap.get(pointGuid))
        );

        return bodyRows.map((vals, rowIdx) => {
            if (rowIdx === 0 && !showProductivityRating) {
                return null;
            }
            return (
                <div key={rowIdx} className="row">
                    <div
                        className="body-cell attribute-name sticky"
                        style={attribNameStyle}
                        title={vals[1]}
                    >
                        {vals[0]}
                    </div>
                    {vals.map((columnVal, colIdx) => {
                        if (colIdx === 1) {
                            //This is the sample attribute's actual name that displays on hover, do not create a grid cell for it
                            return null;
                        } else {
                            const isAttrName = colIdx === 0;
                            const className = classnames("body-cell", {
                                "prod-rating": rowIdx === 0 && colIdx > 0,
                                "attribute-name": isAttrName,
                                selected: selectedColumnIdxSet.has(colIdx - 1),
                            });
                            const cellProps: ICellProps = {
                                className,
                                key: colIdx,
                                style: null,
                                onClick: () => null,
                            };
                            if (isAttrName) {
                                cellProps.style = attribNameStyle;
                            } else {
                                cellProps.onClick = (event) => onClick(event, colIdx - 1);
                            }
                            if (rowIdx === 0 && colIdx > 0) {
                                return (
                                    <div {...cellProps}>
                                        {this._getSampleProductivityRating(columnVal, colIdx - 1)}
                                    </div>
                                );
                            } else {
                                return <div {...cellProps}>{columnVal}</div>;
                            }
                        }
                    })}
                </div>
            );
        });
    }
    // no keyboard or mouse event seems to work here
    _onScroll(evt: any): void {
        const { target } = evt;
        if (target.tagName === "INPUT") {
            //Skip updating when receiving an event from clicking a select-input after a horizontal scroll
            return;
        }
        const { scrollLeft, scrollTop } = target;
        this.setState({ scrollLeft, scrollTop });
    }

    _selectPoints(colIdx: number, withCtrl: boolean, withShift: boolean): void {
        const { samplePoints, selectedPointSet, soilSampleResults } = this.props;
        const { columnNameToPointIdxMap } = this.state;

        const { columnName } = soilSampleResults.gridHeader[colIdx];
        const pointIndex = columnNameToPointIdxMap.get(columnName);

        if (pointIndex == null) {
            return;
        }

        const noModifiers = !withCtrl && !withShift;

        const newSelectedPointSet = new Set<string>();
        if (noModifiers) {
            newSelectedPointSet.add(samplePoints[pointIndex].soilSamplePointGuid);
        } else if (withCtrl) {
            [...selectedPointSet].forEach((soilSamplePointGuid) =>
                newSelectedPointSet.add(soilSamplePointGuid)
            );

            const toggleSamplePoint = samplePoints[pointIndex];
            if (selectedPointSet.has(toggleSamplePoint.soilSamplePointGuid)) {
                newSelectedPointSet.delete(toggleSamplePoint.soilSamplePointGuid);
            } else {
                newSelectedPointSet.add(toggleSamplePoint.soilSamplePointGuid);
            }
        } else {
            console.assert(withShift);

            // Select all options between this option and the last non-select-click
            if (this.lastClickedWithoutShiftIndex == null) {
                this.lastClickedWithoutShiftIndex = 0;
            }

            samplePoints
                .slice(
                    Math.min(this.lastClickedWithoutShiftIndex, pointIndex),
                    Math.max(this.lastClickedWithoutShiftIndex, pointIndex) + 1
                )
                .forEach((samplePoint) => newSelectedPointSet.add(samplePoint.soilSamplePointGuid));
        }

        if (!withShift) {
            this.lastClickedWithoutShiftIndex = pointIndex;
        }

        this.props.onSetSelectedPointSet(newSelectedPointSet);
    }

    _getSampleProductivityRating(productivityRatingGuid: string, colIdx: number): JSX.Element {
        const { intl, onSetPointProdRating, productivityRatingOptions, soilSampleResults } =
            this.props;
        const { columnNameToPointIdxMap } = this.state;

        const { columnName } = soilSampleResults.gridHeader[colIdx];
        const pointIndex = columnNameToPointIdxMap.get(columnName);

        return (
            <ProductivityRatingCell
                intl={intl}
                onSetProdRating={onSetPointProdRating.bind(this, pointIndex)}
                productivityRatingGuid={productivityRatingGuid}
                productivityRatingOptions={productivityRatingOptions}
            />
        );
    }

    render(): JSX.Element {
        const { soilSampleResults } = this.props;
        if (soilSampleResults.gridHeader.length === 0) {
            return <Loader />;
        }

        return (
            <div className="sample-results-tbl-container" onScroll={(evt) => this._onScroll(evt)}>
                <div className="sample-results-tbl">
                    {this._getAttributeNameMeasure()}
                    {this._getHeaderSpacer()}
                    {this._getHeader()}
                    {this._getBody()}
                </div>
            </div>
        );
    }
}
