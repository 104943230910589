import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Checkbox, DialogBox, DialogBoxFooterType, Loader, LoaderTypes, SelectInput } from "~/core";
import { getTheUserGuid } from "~/login";
import { FileImportAPI } from "@ai360/core";

import { messages } from "../../../i18n-messages";

import "./export-controller-file-modal.css";

interface IProps {
    intl: any;
    isOpen: boolean;
    onAction: (exportRecRequest: FileImportAPI.ExportRecRequest) => void;
    onClose: () => void;
    recGeneralGuidList: string[];
    recSummaries: any;
    userGuid: string;
}

interface IState {
    controllerTypeOptions: any[];
    createLoadsheetYn: boolean;
    isLoading: boolean;
    mergeControllerFilesYn: boolean;
    multiProductYn: boolean;
    productFormatOptions: any[];
    selectedControllerFileType: any;
    selectedControllerType: any;
    selectedExportFormat: any;
    validationWarnings: any[];
    controllerFileTypeOptions: any;
}

export class ExportControllerFileModal_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            controllerTypeOptions: [],
            createLoadsheetYn: false,
            isLoading: false,
            mergeControllerFilesYn: false,
            multiProductYn: false,
            productFormatOptions: [],
            selectedControllerFileType: null,
            selectedControllerType: null,
            selectedExportFormat: null,
            validationWarnings: [],
            controllerFileTypeOptions: [],
        };
    }

    private onAction() {
        const { onAction, recGeneralGuidList } = this.props;
        const {
            createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
            selectedControllerFileType,
            selectedControllerType,
            selectedExportFormat,
        } = this.state;

        if (mergeControllerFilesYn || selectedControllerFileType.sendThroughOnsite) {
            const validationWarnings = this.validateControllerFileMerge();
            if (validationWarnings.length > 0) {
                this.setState({ validationWarnings: validationWarnings });
                return;
            }
        }

        this.setState({ isLoading: true });
        const exportRecRequest = new FileImportAPI.models.ExportRecRequest(recGeneralGuidList);
        exportRecRequest.setProps({
            controllerFileTypeGuid: selectedControllerFileType.guid,
            controllerTypeGuid: selectedControllerType,
            fileNameFormatGuid: selectedExportFormat,
            loadsheetYn: createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
        });
        onAction(exportRecRequest);
    }

    private fetchOptions(nextProps: IProps) {
        const { userGuid, recGeneralGuidList } = nextProps;

        const promises = [];
        promises.push(
            FileImportAPI.getControllerTypeList(userGuid).then((result) => {
                const controllerTypeOptions = result.map((r) => ({
                    label: r.name,
                    value: r.guid,
                }));
                return controllerTypeOptions;
            })
        );

        promises.push(FileImportAPI.getUserExportPreferences(userGuid));

        promises.push(
            FileImportAPI.getFileNameFormatList(userGuid).then((result) => result.fileNameFormats)
        );

        Promise.all(promises).then(([controllerTypeOptions, prefs, productFormatOptions]) => {
            const {
                controllerExportFormatGuid,
                controllerFileTypeGuid,
                controllerTypeGuid,
                createLoadsheetYn,
                mergeControllerFilesYn,
                multiProductYn,
            } = prefs;

            this.setState(
                {
                    controllerTypeOptions,
                    createLoadsheetYn,
                    mergeControllerFilesYn: recGeneralGuidList.length > 1 && mergeControllerFilesYn,
                    multiProductYn,
                    productFormatOptions,
                    selectedControllerType: controllerTypeGuid,
                    selectedExportFormat: controllerExportFormatGuid,
                },
                () =>
                    this.onChangeControllerType(
                        controllerTypeGuid,
                        this.state.multiProductYn,
                        controllerFileTypeGuid
                    )
            );
        });
    }

    private onChangeControllerType(
        newSelectedControllerType,
        multiProductYn = false,
        fileTypeGuid = false
    ) {
        const { selectedControllerType, selectedControllerFileType } = this.state;
        if (
            newSelectedControllerType === selectedControllerType &&
            selectedControllerFileType &&
            selectedControllerFileType.sendThroughOnsite
        ) {
            // if it's just a multiProductYn change and a filetype sent through onsite is already selected, don't reload the file type list
            this.setState({ isLoading: false });
            return;
        }

        const { recGeneralGuidList } = this.props;
        this.setState(
            {
                selectedControllerType: newSelectedControllerType,
                isLoading: true,
            },
            () => {
                FileImportAPI.getControllerFileTypeList(
                    multiProductYn ? recGeneralGuidList : [],
                    newSelectedControllerType
                ).then((result) => {
                    const controllerFileTypeOptions = result.map((r) => ({
                        label: r.name,
                        value: {
                            guid: r.guid,
                            sendThroughOnsite: r.sendThroughOnsite,
                        },
                    }));
                    let selectedControllerFileType = null;
                    if (controllerFileTypeOptions.length === 1) {
                        selectedControllerFileType = controllerFileTypeOptions[0].value;
                    } else if (fileTypeGuid) {
                        selectedControllerFileType = controllerFileTypeOptions.filter(
                            (o) => o.value.guid === fileTypeGuid
                        )[0]?.value;
                    }
                    this.setState({
                        controllerFileTypeOptions,
                        isLoading: false,
                        selectedControllerFileType,
                    });
                });
            }
        );
    }

    private onChangeControllerFormat(selectedControllerFileType) {
        let { multiProductYn } = this.state;
        if (selectedControllerFileType.sendThroughOnsite) {
            multiProductYn = false;
        }

        this.setState({ selectedControllerFileType, multiProductYn });
    }

    private validateControllerFileMerge() {
        const { recSummaries } = this.props;
        const fields = new Set([...recSummaries.map((r) => r.fieldGuid)]);
        const recTypes = recSummaries.map((r) => r.recType);
        const warnings = [];
        if (fields.size !== recSummaries.length) {
            warnings.push(messages.exportValidationWarningOneRecPerField);
        }

        if (
            recTypes.some((r) => r.includes("Planting")) &&
            recTypes.some((r) => r.includes("Application"))
        ) {
            warnings.push(messages.exportValidationWarningOneRecType);
        }

        if (
            recTypes.some((r) => r === "Planting (Manual)") &&
            recTypes.some((r) => r === "Planting (Equation)")
        ) {
            warnings.push(messages.exportValidationWarningPlantingRecType);
        }

        return warnings;
    }

    private getValidationMessages() {
        const { validationWarnings } = this.state;
        const { formatMessage } = this.props.intl;

        return validationWarnings.map((warning) => {
            return (
                <div className="validation-message" key={warning.id}>
                    {formatMessage(warning)}
                </div>
            );
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (
            (nextProps.userGuid && this.props.userGuid !== nextProps.userGuid) ||
            (nextProps.isOpen && this.props.isOpen !== nextProps.isOpen)
        ) {
            this.setState({ isLoading: true }, () => this.fetchOptions(nextProps));
        }
    }

    render() {
        const { recGeneralGuidList, isOpen, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        const {
            controllerFileTypeOptions,
            controllerTypeOptions,
            createLoadsheetYn,
            isLoading,
            mergeControllerFilesYn,
            multiProductYn,
            productFormatOptions,
            selectedControllerFileType,
            selectedControllerType,
            selectedExportFormat,
            validationWarnings,
        } = this.state;

        const exportControllerFilesTitle = formatMessage(messages.exportControllerFile, {
            count: recGeneralGuidList.length,
        });
        const title =
            recGeneralGuidList.length > 1
                ? `${exportControllerFilesTitle} (${recGeneralGuidList.length})`
                : exportControllerFilesTitle;

        const sendThroughOnsite =
            (selectedControllerFileType && selectedControllerFileType.sendThroughOnsite) || false;

        const fileNameFormats = productFormatOptions
            .filter(
                (o) =>
                    o.isMultiProduct === (multiProductYn || sendThroughOnsite) &&
                    o.isMergeController === mergeControllerFilesYn
            )
            .map((o) => {
                return { label: o.name, value: o.guid };
            });

        const exportFormat = fileNameFormats.find((o) => o.value === selectedExportFormat)
            ? selectedExportFormat
            : null;

        const canExport =
            selectedControllerType != null &&
            selectedControllerFileType != null &&
            exportFormat != null;

        return (
            <DialogBox
                draggable
                action={formatMessage(messages.exportToFile)}
                actionDisabled={!canExport}
                className="export-controller-file-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                onAction={() => this.onAction()}
                onClose={() => onClose()}
                title={title}
                unrestricted
            >
                <div className="row">
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.onChangeControllerType(value)}
                        options={controllerTypeOptions}
                        required
                        placeholderText="Controller Company"
                        value={selectedControllerType}
                    />
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.onChangeControllerFormat(value)}
                        options={controllerFileTypeOptions}
                        required
                        placeholderText="Controller Format"
                        value={selectedControllerFileType}
                    />
                </div>
                <div className="row">
                    <div className="checkboxes">
                        <Checkbox
                            label="Loadsheet"
                            onChange={(e, createLoadsheetYn) =>
                                this.setState({ createLoadsheetYn })
                            }
                            value={createLoadsheetYn}
                        />
                        <Checkbox
                            label="Multi-Product File"
                            onChange={(e, multiProductYn) => {
                                this.setState({ multiProductYn });
                                this.onChangeControllerType(
                                    this.state.selectedControllerType,
                                    multiProductYn
                                );
                            }}
                            value={multiProductYn}
                        />
                        {!sendThroughOnsite && recGeneralGuidList.length > 1 && (
                            <Checkbox
                                label="Merge Files"
                                onChange={(e, mergeControllerFilesYn) =>
                                    this.setState({
                                        mergeControllerFilesYn,
                                    })
                                }
                                value={mergeControllerFilesYn}
                            />
                        )}
                    </div>
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.setState({ selectedExportFormat: value })}
                        options={fileNameFormats}
                        required={true}
                        placeholderText="File Name Format"
                        value={exportFormat}
                    />
                </div>
                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
                <DialogBox
                    title={formatMessage(messages.exportValidationWarningTitle, {
                        count: validationWarnings.length,
                    })}
                    isOpen={validationWarnings.length > 0}
                    onClose={() => this.setState({ validationWarnings: [] })}
                >
                    {<div>{this.getValidationMessages()}</div>}
                </DialogBox>
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

const ExportControllerFileModal = connect(mapStateToProps)(injectIntl(ExportControllerFileModal_));
export default ExportControllerFileModal;
