import { ReportBooksAPI, SetupReportBooksAPI } from "@ai360/core";
import {
    ForkEffect,
    GenericAllEffect,
    PutEffect,
    all,
    put,
    select,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import { getUser } from "~/login/selectors";
import { actions as messagingActions } from "~/messaging";
import * as selectors from "./selectors";
import * as actions from "./actions";
import { actions as notificationActions } from "~/notifications";
import { IReportBookResultInfo } from "~/report-books/data/interface";

const deactivateReportBook = function* (action) {
    try {
        yield put(actions.setReportBookLoading(true));
        yield ReportBooksAPI.deactivateReportBooks(action.payload.reportBookGuids);
        yield put(
            actions.getReportBooks({
                fieldGuids: action.payload.fieldGuids as string[],
                filterOptions: action.payload.filterOptions,
            })
        );
    } catch (err) {
        yield put(actions.setReportBookLoading(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBooks = function* (action) {
    try {
        yield put(actions.setReportBookLoading(true));
        const response = yield ReportBooksAPI.getReportBooks(
            action.payload.fieldGuids,
            action.payload.filterOptions
        );
        yield put(actions.setReportBooks(response.reportBooks));
        yield put(actions.setReportBooksTotalCount(response.totalCount));
        yield put(actions.setReportBookLoading(false));
    } catch (err) {
        yield put(actions.setReportBookLoading(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBooksAutoComplete = function* (action) {
    try {
        const response = yield ReportBooksAPI.getReportBooksAutoComplete(
            action.payload.fieldGuids,
            action.payload.filterOptions,
            action.payload.searchName,
            action.payload.searchString
        );
        yield put(actions.setReportBooksAutoComplete(response));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBooksSelectAll = function* (action) {
    try {
        const response = yield ReportBooksAPI.getReportBooks(
            action.payload.fieldGuids,
            action.payload.filterOptions
        );
        yield put(actions.setReportBooks(response.reportBooks));
        yield put(actions.setReportBooksSelectAll(response.reportBooks.map((rb) => rb.batchGuid)));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBookTypes = function* (action) {
    try {
        yield put(actions.setReportBookLoading(true));
        const userInfo = yield select(getUser);
        const locationGuid =
            userInfo.lastUsedLocationGuid == null || userInfo.lastUsedLocationGuid.length === 0
                ? null
                : userInfo.lastUsedLocationGuid;
        const response = yield SetupReportBooksAPI.getReportBookTypes(
            false,
            userInfo.companyGuid,
            locationGuid
        );
        yield put(actions.setReportBookLoading(false));
        yield put(actions.setReportBookTypes(response.reportBookTypes));
    } catch (err) {
        yield put(actions.setReportBookLoading(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const mergeReportBooks = function* (action) {
    try {
        yield put(actions.setReportBookLoading(true));
        action.payload.fields = action.payload.fields.map((field) => ({
            ...field,
            fieldName:
                field.fieldName != null && field.fieldName !== "" ? field.fieldName : field.name,
        }));
        yield ReportBooksAPI.mergeReportBook(action.payload);
        yield put(actions.refreshReportBooks(action.payload.trackingGuid));
        yield put(actions.setClearSelectedReportBookTypes(true));
        yield put(actions.setReportBookLoading(false));
        yield put(actions.setClearSelectedReportBookTypes(false));
    } catch (err) {
        yield put(actions.setReportBookLoading(false));
        yield put(actions.setClearSelectedReportBookTypes(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const mergeReportBookNotification = function* (): Generator<PutEffect<any>, void, unknown> {
    const reportBookCreated = function* (message) {
        const reportBooks = yield select(selectors.getReportBooks);
        const newReportBooks = [];
        for (const reportBook of reportBooks) {
            if (reportBook.reportBookGuid.toLowerCase() === message.reportBookGuid.toLowerCase()) {
                newReportBooks.push({
                    ...reportBook,
                    status: message.status,
                    fileName: message.fileName,
                });
            } else {
                newReportBooks.push({ ...reportBook });
            }
        }
        yield put(actions.setReportBooks(newReportBooks));
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "reportBookCreated",
            generator: reportBookCreated,
        })
    );
};

export const mergeReportBookResultNotification = function* (): Generator<
    PutEffect<any>,
    void,
    unknown
> {
    const reportBookResult = function* (errorInfo: IReportBookResultInfo[]) {
        const reportBookErrorMap = yield select(selectors.getReportBookErrorsMap);
        for (const ei of errorInfo) {
            reportBookErrorMap.set(ei.trackingGuid, ei);
        }
        yield put(actions.setReportBookErrorsMap(reportBookErrorMap));
    };
    yield put(
        messagingActions.subscribe(0, {
            eventName: "reportBookResults",
            generator: reportBookResult,
        })
    );
};

export function* reportBooksSaga(): Generator<
    GenericAllEffect<Generator<PutEffect<any>, void, unknown> | ForkEffect>,
    void,
    unknown
> {
    yield all([
        takeEvery(actions.DEACTIVATE_REPORT_BOOKS, deactivateReportBook),
        takeLatest(actions.GET_REPORT_BOOKS, getReportBooks),
        takeLatest(actions.GET_REPORT_BOOKS_AUTO_COMPLETE, getReportBooksAutoComplete),
        takeLatest(actions.GET_REPORT_BOOKS_SELECT_ALL, getReportBooksSelectAll),
        takeEvery(actions.GET_REPORT_BOOK_TYPES, getReportBookTypes),
        takeEvery(actions.MERGE_REPORT_BOOKS, mergeReportBooks),
        mergeReportBookResultNotification(),
        mergeReportBookNotification(),
    ]);
}
