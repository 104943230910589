import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import Immutable from "immutable";
import type { FieldAPI, UserAPI } from "@ai360/core";
import { IFormatter, SearchAPI } from "@ai360/core";

import { actions as accordionActions } from "~/accordion";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getUser } from "~/login/selectors";
import { OrgLevelInfo, ICustomerFieldMapping } from "~/customer-data/models";
import { DialogBox, DialogBoxFooterType } from "~/core";

import { AddCustomerLink } from "../components/add-customer-link/add-customer-link";
import { BatchEditDetails } from "../components/batch-edit-details/batch-edit-details";
import { CustomersAccordion } from "../components/customers-accordion/customers-accordion";
import { MoveFieldsModal } from "../components/dialog-boxes/move-fields-modal";
import { CombineFieldsModal } from "../components/combine-fields-modal/combine-fields-modal";
import { ModuleSearch } from "../../../../module-search";
import { FieldSelectBar } from "../components/field-select-bar/field-select-bar";
import { FieldFilter } from "../components/field-filter/field-filter";
import { ModuleFilters, ModuleFiltersToggle } from "../../../../module-filters";
import { messages } from "../../../../common/i18n-messages";
import fieldInfoMessages from "../../field-information/components/field-information-detail/i18n-messages";
import * as actions from "../../../actions";
import * as listActions from "../actions";
import * as selectors from "../../../selectors";
import * as customerAccordionSelectors from "../selectors";
import { IFieldFilterSelections, IFieldFilterOptions } from "../models";

import { IFieldsToMove } from "../../../interfaces";

import * as eventListSelectors from "~/action-panel/components/event-module/components/event-list/selectors";
import * as recListSelectors from "~/action-panel/components/rec-module/components/rec-list/selectors";

import "./field-list.css";
import { filteredSelectCustomerFieldRequest } from "~/utils/api/search";
import { logFirebaseEvent } from "~/utils/firebase";

interface IFieldListProps {
    activeTab: actions.FieldListTabs;
    addCustomerFieldMappings: (mappings: ICustomerFieldMapping[]) => Record<string, any>;
    changeActiveTab: (activeTab: actions.FieldListTabs) => Record<string, any>;
    clearAllSelectedFields: () => Record<string, any>;
    clearSelectedFields: (fieldGuids: string[]) => Record<string, any>;
    collapseAll: () => Record<string, any>;
    expandAll: () => Record<string, any>;
    fetchBatchOptions: () => Record<string, any>;
    filterSelections: IFieldFilterSelections;
    filterCount: number;
    filterOptions: IFieldFilterOptions;
    initialFilterStr: string;
    intl: IFormatter;
    isActiveTabOperationTab: boolean;
    isCombineDialogOpen: boolean;
    isLoading: boolean;
    loadDetailPage: () => Record<string, any>;
    fieldsToMove: IFieldsToMove;
    onAddEditField: () => Record<string, any>;
    onDeleteSelected: () => Record<string, any>;
    onExportBoundaries: () => Record<string, any>;
    onExportDetails: () => Record<string, any>;
    onToggleBatchEdit: (visible: boolean) => Record<string, any>;
    onToggleFilters: (visible: boolean) => Record<string, any>;
    orgLevelGuid: string;
    orgLevelList: OrgLevelInfo[];
    orgLevels: Map<string, OrgLevelInfo>;
    searchValue: string;
    selectFields: (fieldGuids: string[]) => Record<string, any>;
    selectedFieldGuids: Immutable.Set<string>;
    selectedEventFieldGuidSet: Set<string>;
    selectedRecFieldGuidSet: Set<string>;
    showBatchEdit: boolean;
    showFilters: boolean;
    togglePanelNavigationEnabled: (enabled: boolean) => Record<string, any>;
    updateBatchFieldDetails: (batchDetails: Partial<FieldAPI.IField>) => Record<string, any>;
    updateSearchValue: (searchValue: string) => Record<string, any>;
    userInfo: UserAPI.IUser;
}

interface IFieldListState {
    lastSearchValue: string;
    showCertOrganicChangeDialog: boolean;
    newCertOrganicValue: boolean;
    previousCertOrganicValue: boolean;
}

class FieldListContainer extends React.Component<IFieldListProps, IFieldListState> {
    constructor(props: IFieldListProps) {
        super(props);
        this.state = {
            lastSearchValue: null,
            newCertOrganicValue: null,
            previousCertOrganicValue: null,
            showCertOrganicChangeDialog: false,
        };
    }

    private onBatchEditDetails(newProps: Partial<FieldAPI.IField>) {
        logFirebaseEvent("field_batch_edit_details");
        this.props.updateBatchFieldDetails({
            ...newProps,
        });
        this.props.fetchBatchOptions();
        this.props.onToggleBatchEdit(true);
    }

    private closeCertOrganicVerifyModal() {
        this.setState({ showCertOrganicChangeDialog: false });
    }

    private changeOrganicValue(certifiedOrganic: boolean) {
        this.props.updateBatchFieldDetails({
            certifiedOrganic,
        });
        this.setState({
            newCertOrganicValue: certifiedOrganic,
            showCertOrganicChangeDialog: true,
        });
    }

    private onClearAll() {
        logFirebaseEvent("clear_selected_fields");
        this.props.clearAllSelectedFields();
    }

    private onEventSelection() {
        const { clearSelectedFields, selectFields, selectedFieldGuids, selectedEventFieldGuidSet } =
            this.props;
        if (selectedEventFieldGuidSet.size > 0) {
            clearSelectedFields(Array.from(selectedFieldGuids.subtract(selectedEventFieldGuidSet)));
            selectFields(Array.from(selectedEventFieldGuidSet.values()));
        }
    }

    private onRecSelection() {
        const { clearSelectedFields, selectFields, selectedFieldGuids, selectedRecFieldGuidSet } =
            this.props;
        if (selectedRecFieldGuidSet.size > 0) {
            clearSelectedFields(Array.from(selectedFieldGuids.subtract(selectedRecFieldGuidSet)));
            selectFields(Array.from(selectedRecFieldGuidSet.values()));
        }
    }

    private onSearchChange(newValue: string) {
        if (this.state.lastSearchValue !== newValue) {
            logFirebaseEvent("search_fields");
            this.setState(
                {
                    lastSearchValue: newValue,
                },
                () => this.props.updateSearchValue(newValue)
            );
        }
    }

    private async onSelectAll() {
        const { activeTab, selectFields, addCustomerFieldMappings, userInfo, filterSelections } =
            this.props;
        if (activeTab !== actions.FieldListTabs.ACTIVE) {
            return;
        }

        logFirebaseEvent("select_all_fields");
        const selected = await SearchAPI.selectCustomerFields(
            filteredSelectCustomerFieldRequest(true, userInfo.userGuid, null, filterSelections)
        );
        addCustomerFieldMappings(
            selected.map((x) => ({
                fieldGuid: x.fieldId,
                customerGuid: x.customerId,
            }))
        );
        selectFields(selected.map((x) => x.fieldId));
    }

    private onTabChange(newTab: actions.FieldListTabs) {
        this.props.togglePanelNavigationEnabled(newTab !== actions.FieldListTabs.INACTIVE);
        this.props.changeActiveTab(newTab);
    }

    render() {
        const {
            activeTab,
            collapseAll,
            expandAll,
            filterSelections,
            filterCount,
            filterOptions,
            isActiveTabOperationTab,
            isCombineDialogOpen,
            isLoading,
            loadDetailPage,
            fieldsToMove,
            onAddEditField,
            onDeleteSelected,
            onExportBoundaries,
            onExportDetails,
            onToggleBatchEdit,
            onToggleFilters,
            orgLevelGuid,
            orgLevelList,
            orgLevels,
            searchValue,
            selectedFieldGuids,
            showBatchEdit,
            showFilters,
            userInfo,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const orgLevelName = orgLevelGuid == null ? "" : orgLevels.get(orgLevelGuid).name;
        const filterInputEls = [
            <FieldFilter
                key={0}
                initialFilterStr={orgLevelName}
                filterOptions={filterOptions}
                records={orgLevelList}
                visible={showFilters}
                onClose={() => {
                    logFirebaseEvent("filters_fields");
                    onToggleFilters(false);
                }}
            />,
        ];
        return (
            <div className="field-list">
                <div className="module-filter-row">
                    <ModuleSearch
                        isLoading={isLoading}
                        searchValue={searchValue}
                        onSearchChange={(newValue) => {
                            this.onSearchChange(newValue);
                        }}
                    />
                    <ModuleFiltersToggle
                        filterValueCount={filterCount}
                        filterVisible={showFilters}
                        filterCount={Object.keys(filterSelections).length}
                        label={formatMessage(messages.showFilters)}
                        onToggleFilters={(newValue) => {
                            logFirebaseEvent("filters_fields");
                            onToggleFilters(newValue);
                        }}
                        showFilterValueCount={true}
                    />
                </div>
                {!showFilters ? null : (
                    <div className="module-filter-row module-filters">
                        <ModuleFilters>{filterInputEls}</ModuleFilters>
                    </div>
                )}
                {!showBatchEdit ? null : (
                    <BatchEditDetails
                        changeOrganicValue={(value) => this.changeOrganicValue(value)}
                        fieldCount={selectedFieldGuids.size}
                        onClose={() => onToggleBatchEdit(false)}
                        updateBatchField={this.onBatchEditDetails.bind(this)}
                        visible={showBatchEdit}
                    />
                )}
                <DialogBox
                    isOpen={this.state.showCertOrganicChangeDialog}
                    onAction={() => {
                        this.setState({
                            previousCertOrganicValue: this.state.newCertOrganicValue,
                        });
                        this.closeCertOrganicVerifyModal();
                    }}
                    onClose={() => {
                        this.props.updateBatchFieldDetails({
                            certifiedOrganic: this.state.previousCertOrganicValue,
                        });
                        this.closeCertOrganicVerifyModal();
                    }}
                    footerType={DialogBoxFooterType.YES_NO}
                    title={formatMessage(fieldInfoMessages.certOrganicVerifyTitle)}
                >
                    {formatMessage(fieldInfoMessages.certOrganicVerifyMessage)}
                </DialogBox>

                <FieldSelectBar
                    activeTab={activeTab}
                    className="field-list-row"
                    hasInactivePermission={userInfo.role.activeInactive}
                    hasSelectedFields={selectedFieldGuids.size > 0}
                    onTabChange={this.onTabChange.bind(this)}
                    onSelectAll={this.onSelectAll.bind(this)}
                    onBatchEditDetails={this.onBatchEditDetails.bind(this)}
                    onClearAll={this.onClearAll.bind(this)}
                    onRecSelection={this.onRecSelection.bind(this)}
                    onEventSelection={this.onEventSelection.bind(this)}
                    onExpandAll={expandAll}
                    onCollapseAll={collapseAll}
                    onDeleteSelected={onDeleteSelected}
                    onExportBoundaries={onExportBoundaries}
                    onExportDetails={onExportDetails}
                    userRole={userInfo.role}
                >
                    <div className="field-selector-accordion field-list-fill">
                        <CustomersAccordion
                            onAddEditField={onAddEditField}
                            isLockCustomer={userInfo.lockCustomersNotEnrolledYn}
                            loadDetailPage={loadDetailPage}
                            userRole={userInfo.role}
                        />
                    </div>
                </FieldSelectBar>

                {!userInfo.role.customerAdd || !isActiveTabOperationTab ? null : (
                    <AddCustomerLink loadDetailPage={loadDetailPage} />
                )}

                {fieldsToMove == null ? null : (
                    <MoveFieldsModal
                        fieldsToMove={fieldsToMove}
                        isOpen={fieldsToMove.fieldGuids.length > 0}
                        orgLevelList={orgLevelList}
                    />
                )}
                {!isCombineDialogOpen ? null : <CombineFieldsModal />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    accordionId: customerAccordionSelectors.getAccordionId(state),
    activeTab: selectors.getActiveTab(state),
    filterSelections: customerAccordionSelectors.getFilterSelections(state),
    filterCount: customerAccordionSelectors.getFilterCount(state),
    filterOptions: customerAccordionSelectors.getFilterOptions(state),
    isActiveTabOperationTab: selectors.getActiveTabIsOperationTab(state),
    isCombineDialogOpen: selectors.getIsCombineDialogOpen(state),
    isLoading: cdSelectors.getFetchingCustomerFields(state),
    fieldsToMove: selectors.getFieldsToMove(state),
    orgLevelGuid: null,
    orgLevelList: cdSelectors.getParsedOrgLevels(state),
    orgLevels: cdSelectors.getOrgLevelMap(state),
    searchValue: customerAccordionSelectors.getSearchValue(state),
    selectedFieldGuids: cdSelectors.getSelectedFieldGuids(state),
    selectedEventFieldGuidSet: eventListSelectors.getSelectedEventFieldGuidSet(state),
    selectedRecFieldGuidSet: recListSelectors.getSelectedRecFieldGuidSet(state),
    showBatchEdit: customerAccordionSelectors.getShowBatchEdit(state),
    showFilters: customerAccordionSelectors.getShowFilters(state),
    userInfo: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    addCustomerFieldMappings: (mappings) => dispatch(cdActions.addCustomerFieldMappings(mappings)),
    changeActiveTab: (newActiveTab) => dispatch(actions.changeActiveTab(newActiveTab)),
    clearAllSelectedFields: () => dispatch(cdActions.clearAllSelectedFields()),
    clearSelectedFields: (fieldGuids) => dispatch(cdActions.clearSelectedFields(fieldGuids)),
    collapseAll: (accordionId) =>
        dispatch(accordionActions.collapseAllAccordionItems(accordionId, [1])),
    expandAll: (accordionId) =>
        dispatch(accordionActions.expandAllAccordionItems(accordionId, [1])),
    fetchBatchOptions: () => dispatch(listActions.fetchBatchOptions()),
    onDeleteSelected: (ignoreAgvanceConfirmation?: boolean) =>
        dispatch(actions.deleteSelectedFields(ignoreAgvanceConfirmation)),
    onExportBoundaries: () => dispatch(actions.exportBoundary()),
    onExportDetails: () => dispatch(actions.exportDetails()),
    onToggleBatchEdit: (showBatchEdit) => dispatch(listActions.setShowBatchEdit(showBatchEdit)),
    onToggleFilters: (showFilters) => dispatch(listActions.setShowFilters(showFilters)),
    selectFields: (fieldGuidList) => dispatch(cdActions.addSelectedFields(fieldGuidList)),
    updateBatchFieldDetails: (batchEditDetails) =>
        dispatch(listActions.updateBatchFieldDetails(batchEditDetails)),
    updateSearchValue: (searchValue) => {
        dispatch(listActions.setFilterSelections({ search: searchValue }));
        dispatch(listActions.updateSearchValue(searchValue));
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    collapseAll: () => dispatchProps.collapseAll(stateProps.accordionId),
    expandAll: () => dispatchProps.expandAll(stateProps.accordionId),
});

export const FieldList = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(FieldListContainer));
