import React, { Component, FocusEvent, MouseEvent } from "react";
import DateTime from "react-datetime";
import onClickOutside, { WrapperInstance } from "react-onclickoutside";
import moment from "moment";

import { TextInput, ITextInputProps } from "../text-input/text-input";
import { CalendarIcon } from "~/core/icons";
import { Moment } from "moment";
import "./date-input.css";

interface IWrappableDateTimeProps {
    className?: string;
    value?: Moment | string;
    defaultValue?: Moment;
    timeFormat?: boolean | string;
    onChange?: (value?: Moment | string) => void;
    onBlur?: (value?: Moment | string) => void;
}

class WrappableDateTime extends Component<IWrappableDateTimeProps> {
    static defaultProps = {
        className: "date-open-left",
    };

    public dateTimeRef: DateTime;
    public currentView: DateTime.ViewMode = "days";

    public handleClickOutside(): void {
        this.props.onBlur(this.props.value);
    }

    render(): JSX.Element {
        return (
            <DateTime
                className={this.props.className}
                ref={(ref) => (this.dateTimeRef = ref)}
                input={false}
                value={this.props.value ? this.props.value : this.props.defaultValue}
                onChange={this.props.onChange}
                onNavigate={(viewMode: DateTime.ViewMode) => {
                    this.currentView = viewMode;
                }}
                timeFormat={this.props.timeFormat}
            />
        );
    }
}

const WrappedDateTime = onClickOutside(WrappableDateTime);

export interface IDateInputProps extends IWrappableDateTimeProps {
    autoFocus?: boolean;
    clearDisabled?: boolean;
    containerClassNames?: string[];
    dateFormat?: string;
    disabled?: boolean;
    onFocus?: (value?: Moment) => void;
    openDirection?: string;
    placeholderText?: string;
    required?: boolean;
    tabIndex?: number;
    visible?: boolean;
}

export interface IDateInputState {
    expanded: boolean;
    value: Moment | string;
    inputValue: string;
}

export class DateInput extends Component<IDateInputProps, IDateInputState> {
    static defaultProps = {
        ...TextInput.defaultProps,
        value: null,
        dateFormat: "M/D/YY",
        timeFormat: false,
        clearDisabled: false,
        placeholderText: "Date Input",
        onChange: (): void => null,
        onFocus: (): void => null,
        onBlur: (): void => null,
    };

    private _textInput: TextInput;
    private _wrappedDateTimeRef: WrapperInstance<IWrappableDateTimeProps, typeof WrappableDateTime>;

    constructor(props: IDateInputProps) {
        super(props);

        const value = props.value || props.defaultValue;

        this.state = {
            expanded: false,
            value,
            inputValue: this.#getFormattedDateTime(props, value),
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: IDateInputProps): void {
        if (
            newProps.value !== this.state.value ||
            newProps.dateFormat !== this.props.dateFormat ||
            newProps.timeFormat !== this.props.timeFormat ||
            newProps.defaultValue !== this.props.defaultValue
        ) {
            const value = newProps.value || newProps.defaultValue;

            this.setState({
                value,
                inputValue: this.#getFormattedDateTime(newProps, value),
            });
        }
    }

    #toggleExpanded(event: FocusEvent | MouseEvent): void {
        event.preventDefault();
        if (this.props.disabled) {
            return;
        }
        const hasFocus = !this.state.expanded;
        this._textInput.blur();
        this.setState({ expanded: hasFocus }, hasFocus ? this.props.onFocus : this.props.onBlur);
    }

    #getFormattedDateTime(props: IDateInputProps, val: Moment | string): string {
        if (val == null) {
            return null;
        }
        const timeFormatStr: string = props.timeFormat ? ` ${props.timeFormat}` : "";
        return moment(val).format(`${props.dateFormat}${timeFormatStr}`);
    }

    #onDateTimeChange(value: Moment): void {
        let wrappedDtInstance: WrappableDateTime;
        try {
            wrappedDtInstance = this._wrappedDateTimeRef.getInstance();
        } catch (ex) {
            wrappedDtInstance = null;
        }
        const shouldBlur: boolean = wrappedDtInstance?.currentView !== "time";
        this.setState(
            {
                expanded: !shouldBlur,
                value,
                inputValue: this.#getFormattedDateTime(this.props, value),
            },
            () => {
                this.props.onChange(value);
                if (shouldBlur) {
                    this._textInput.blur();
                    this.props.onBlur();
                }
            }
        );
    }

    #onDateTimeBlur(value: Moment): void {
        const newState = {
            ...this.state,
            expanded: false,
        };
        if (value) {
            newState.value = value;
            newState.inputValue = this.#getFormattedDateTime(this.props, value);
        }
        this.setState(newState, () => {
            if (value) {
                this.props.onChange(value);
            }
            this._textInput.blur();
            this.props.onBlur();
        });
    }

    render(): JSX.Element {
        const datePickerElement: JSX.Element = !this.state.expanded ? null : (
            <WrappedDateTime
                className={
                    !this.props.openDirection ? null : "date-open-" + this.props.openDirection
                }
                ref={(ref) => {
                    if (ref) {
                        this._wrappedDateTimeRef = ref;
                    }
                }}
                value={this.state.value}
                timeFormat={this.props.timeFormat}
                onChange={this.#onDateTimeChange.bind(this)}
                onBlur={this.#onDateTimeBlur.bind(this)}
            />
        );

        const enableClear: boolean = this.state.value && !this.props.clearDisabled;

        const inputContainerRightElements: JSX.Element[] = [
            <CalendarIcon className="expand-contract-date-icon" key="expand-contract-date-icon" />,
        ];

        const textInputContainerClassNames: Array<string | Record<string, any>> = [
            ...this.props.containerClassNames,
            "date-form-input",
            { "date-form-input-expanded": this.state.expanded },
        ];

        const { autoFocus, disabled, placeholderText, required, tabIndex, visible } = this.props;

        const passThruProps: Partial<ITextInputProps> = {
            autoFocus,
            disabled,
            placeholderText,
            required,
            tabIndex,
            visible,
        };

        return (
            <div className="date-form-input-container">
                <div onMouseDown={(evt) => this.#toggleExpanded(evt)}>
                    <TextInput
                        {...passThruProps}
                        clearable={enableClear}
                        containerClassNames={textInputContainerClassNames}
                        value={this.state.inputValue || ""}
                        inputContainerRightElements={inputContainerRightElements}
                        onClear={() => this.#onDateTimeChange(null)}
                        onFocus={(evt) => this.#toggleExpanded(evt)}
                        ref={(textInput) => (this._textInput = textInput)}
                    />
                </div>
                {datePickerElement}
            </div>
        );
    }
}
