import { model as accordionModel } from "~/accordion";
import { IField } from "~/accordion/interfaces";
import { AccordionItem } from "~/accordion/model";

export const FIELD_HEIGHT = 56;

export const createFieldAccordionItems = (
    fields: IField[],
    expandedGuids: Set<string>
): AccordionItem[] =>
    fields.map(
        (field) =>
            new accordionModel.AccordionItem(
                FIELD_HEIGHT,
                expandedGuids.has(field.fieldGuid),
                field
            )
    );

export const getFieldGuidFromDimIdx = (accordionItems: AccordionItem[], dimIdx: number[]): void =>
    accordionModel.getItem(accordionItems, dimIdx).payload.fieldGuid;
