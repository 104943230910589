import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { Menu } from "~/core";
import { messages } from "../../../../context-menus/i18n-messages";
import { messages as globalMessages } from "~/i18n-messages";

import { DeleteConfDialog } from "../../../../context-menus/item-delete-confirmation-dialog";

import { actions as cdActions } from "~/customer-data";
import * as nonFieldFeatureInformationActions from "../../../components/non-field-feature-information/actions";

import "./context-menu.css";
import { logFirebaseEvent } from "~/utils/firebase";

const NonFieldDataContextMenu_ = (props) => {
    const { formatMessage } = props.intl;

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const menuItems = [
        {
            disabled: false,
            label: formatMessage(messages.editNonFieldFeature),
            action: () => props.startEditingFeature(props.feature, props.customer),
        },
        {
            disabled: false,
            label: formatMessage(messages.deleteNonFieldFeature),
            action: () => setShowDeleteDialog(true),
        },
    ].map((menuItem, key) => {
        return { ...menuItem, key };
    });

    const deleteDialog = !showDeleteDialog
        ? null
        : DeleteConfDialog({
              formatMessage,
              deleteConfirmationTitle: globalMessages.confirmTitle,
              deleteConfirmationMessage: messages.deleteNonFieldFeatureConfirmation,
              onConfirmationClick: (confirmation) => {
                  setShowDeleteDialog(false);
                  if (confirmation) {
                      logFirebaseEvent("nff_delete_feature");
                      props.deleteFeature(props.feature);
                  }
              },
          });

    return (
        <div>
            <div
                className="context-menu-container non-field-data-context-menu-container"
                onClick={(evt) => evt.preventDefault()}
            >
                <Menu
                    className="context-menu non-field-data-context-menu"
                    isDotMenu={true}
                    getMenuItems={() => menuItems}
                />
            </div>
            {deleteDialog}
        </div>
    );
};

NonFieldDataContextMenu_.propTypes = {
    intl: intlShape.isRequired,
    startEditingFeature: PropTypes.any,
    feature: PropTypes.any,
    customer: PropTypes.any,
    deleteFeature: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    startEditingFeature: (feature, customer) =>
        dispatch(nonFieldFeatureInformationActions.startEditingFeature(feature, customer)),
    deleteFeature: (feature) => dispatch(cdActions.deleteNonFieldFeature(feature)),
});

const mergeProps = (_, dispatchProps, ownProps) => {
    return {
        ...dispatchProps,
        ...ownProps,
        startEditingFeature: (feature, customer) =>
            dispatchProps.startEditingFeature(feature, customer),
    };
};

export const NonFieldDataContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(NonFieldDataContextMenu_));
