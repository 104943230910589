import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import { parse } from "qs";

import { Loader } from "~/core";
import { messages as coreMessages } from "~/core/i18n-messages";
import { getErrorMessages } from "~/i18n-error-messages";
import { messages } from "~/i18n-messages";
import { ThemeRoot } from "~/theme";
import { AuthenticationAPI, APIErrorWithCode } from "@ai360/core";
import {
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SubSection,
    TextInput,
} from "../../core";

import * as actions from "../actions";
import * as selectors from "../selectors";
import "./login.css";

export interface IEnrollmentPage_Props {
    error?: Error;
    location?: Record<string, any>;
    onFetchEnrollmentForm?: (customerGuid: string) => void;
    onInitialEnrollmentLoad?: () => void;
    onStartProcessing?: () => void;
    onStopProcessing?: () => void;
    isProcessing?: boolean;
    intl: intlShape;
    history?: any;
}

export interface IEnrollmentPage_State {
    enrollmentComplete: boolean;
    enrollmentText: string;
    errMessage: string;
    shaking: boolean;
    params: Record<string, any>;
    privateOption: boolean;
    verificationComplete: boolean;
    verificationCodeSent: boolean;
    showPrivacyConfirmModal: boolean;
    verificationCode: string;
    showPrivacyModal: boolean;
}
class EnrollmentPage_ extends Component<IEnrollmentPage_Props, IEnrollmentPage_State> {
    constructor(props) {
        super(props);
        this.state = {
            enrollmentComplete: false,
            enrollmentText: "",
            errMessage: "",
            shaking: false,
            params: parse(props.location.search.slice(1)) || {},
            privateOption: false,
            verificationComplete: false,
            verificationCodeSent: false,
            showPrivacyConfirmModal: false,
            verificationCode: "",
            showPrivacyModal: false,
        };
        this._getEnrollmentStatus();
    }

    private _accept() {
        !this.state.privateOption
            ? this.setState({ showPrivacyConfirmModal: true })
            : this._confirmAccept();
    }

    private _confirmAccept() {
        const { params, privateOption } = this.state;
        const { formatMessage } = this.props.intl;
        this.setState({ showPrivacyConfirmModal: false });
        this._clearError();
        this.props.onStartProcessing();
        AuthenticationAPI.setCustomerEnrolled(params.customerGuid, !privateOption)
            .then(() => {
                this.setState({
                    enrollmentComplete: true,
                    enrollmentText: formatMessage(messages.enrollmentComplete),
                });
                this.props.onStopProcessing();
            })
            .catch((error) => this._handleError(error));
    }

    private _clearError() {
        this.setState({
            errMessage: "",
        });
    }

    private _clearCode() {
        this.setState({
            verificationCode: "",
        });
    }

    private _getEnrollmentAgreementSection(enrollmentComplete, enrollmentText) {
        const { formatMessage } = this.props.intl;
        if (enrollmentComplete) {
            return <div>{enrollmentText}</div>;
        } else {
            const enrollmentTextParts = enrollmentText
                ? enrollmentText.split("privacyOptionCheckbox")
                : ["", ""];

            return (
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: enrollmentTextParts[0],
                        }}
                    ></div>
                    <div className="privacy-section">
                        <Checkbox
                            className={"privacy-checkbox"}
                            label={formatMessage(messages.privateOption)}
                            onChange={(e, value) => {
                                this.setState({
                                    privateOption: value,
                                    showPrivacyModal: value,
                                });
                            }}
                            value={this.state.privateOption}
                        />
                        <p className="privacy-paragraph">
                            {formatMessage(messages.enrollmentPrivacyParagraph)}
                        </p>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: enrollmentTextParts[1],
                        }}
                    ></div>
                </div>
            );
        }
    }

    private _getAgreeButton(verificationComplete, enrollmentComplete) {
        if (!verificationComplete || enrollmentComplete) {
            return null;
        }
        return (
            <div className="accept-control">
                <Button
                    tabIndex={2}
                    className="accept-btn"
                    type="accept"
                    onClick={() => this._accept()}
                />
            </div>
        );
    }

    private _getEnrollmentStatus() {
        const { customerGuid } = this.state.params;
        const { formatMessage } = this.props.intl;
        this.props.onStartProcessing();
        AuthenticationAPI.getCustomerEnrollmentStatus(customerGuid)
            .then((result) => {
                this.props.onStopProcessing();
                this.setState({
                    verificationCodeSent: result.enrolledYn,
                    verificationComplete: result.enrolledYn,
                    enrollmentComplete: result.enrolledYn,
                    enrollmentText: formatMessage(messages.enrollmentComplete),
                });
            })
            .catch((error) => this._handleError(error));
    }

    private _getFormLink(enrollmentComplete) {
        const { formatMessage } = this.props.intl;
        if (!enrollmentComplete) {
            return null;
        }
        return (
            <NoLink
                className="form-link"
                label={formatMessage(messages.enrollmentFormLink)}
                onClick={this._viewEnrollmentForm}
            />
        );
    }

    private _getVerifyInput(verificationCodeSent) {
        const { formatMessage } = this.props.intl;
        if (!verificationCodeSent) {
            return null;
        }
        return (
            <TextInput
                autoFocus={true}
                value={this.state.verificationCode}
                placeholderText={formatMessage(messages.enterVerificationCode)}
                onChange={(verificationCode) => this.setState({ verificationCode })}
            />
        );
    }

    private _getVerifyButton(verificationCodeSent) {
        if (!verificationCodeSent) {
            return null;
        }
        return (
            <Button
                tabIndex={2}
                className="verify-btn"
                type="verify"
                onClick={() => this._verifyCode()}
            />
        );
    }

    private _getVerificationSection(verificationCodeSent) {
        const { formatMessage } = this.props.intl;
        const verifyButtonClass = verificationCodeSent
            ? "enrollment-resend-btn cancel-btn"
            : "enrollment-btn";
        return (
            <SubSection className={"form-section-child-stretch"}>
                <p className="verification-instructions">
                    {formatMessage(messages.enrollmentVerificationText)}
                </p>
                {this._getVerifyInput(verificationCodeSent)}
                {this._getVerifyButton(verificationCodeSent)}
                <br />
                <Button
                    tabIndex={1}
                    className={verifyButtonClass}
                    type={verificationCodeSent ? "resendVerification" : "sendVerification"}
                    onClick={() => this._sendCode()}
                />
            </SubSection>
        );
    }

    private _handleError(err) {
        const { formatMessage } = this.props.intl;
        let errMessage = "";
        if (err instanceof APIErrorWithCode) {
            errMessage = getErrorMessages(formatMessage, err);
        } else {
            errMessage = formatMessage(messages.unknownError);
        }

        this.setState(
            {
                shaking: true,
                errMessage,
            },
            () => setTimeout(() => this.setState({ shaking: false }), 250)
        );

        this.props.onStopProcessing();
    }

    private _sendCode() {
        const { customerGuid, phoneNumber } = this.state.params;
        this.props.onStartProcessing();
        this._clearError();
        this._clearCode();
        AuthenticationAPI.sendVerificationCode(customerGuid, phoneNumber)
            .then(() => {
                this.props.onStopProcessing();
                this.setState({ verificationCodeSent: true });
                this.props.onStopProcessing();
            })
            .catch((error) => this._handleError(error));
    }

    private _verifyCode() {
        const { verificationCode } = this.state;
        const { customerGuid } = this.state.params;
        this.props.onStartProcessing();
        this._clearError();
        this._clearCode();
        AuthenticationAPI.confirmVerificationCode(customerGuid, verificationCode)
            .then((result) => {
                this.props.onStopProcessing();
                this.setState({
                    verificationComplete: true,
                    enrollmentText: result,
                });
            })
            .catch((error) => this._handleError(error));
    }

    private _viewEnrollmentForm = () => {
        const { customerGuid } = this.state.params;
        this.props.onFetchEnrollmentForm(customerGuid);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            this._handleError(nextProps.error);
        }
    }

    componentDidMount() {
        this.props.onInitialEnrollmentLoad();
    }

    render() {
        const {
            enrollmentComplete,
            enrollmentText,
            errMessage,
            shaking,
            verificationCodeSent,
            verificationComplete,
            showPrivacyModal,
            showPrivacyConfirmModal,
        } = this.state;
        const { formatMessage } = this.props.intl;
        const loginBoxClasses = [
            "login-box",
            {
                "login-err-animation": shaking,
            },
        ];
        const errMsgEl = Array.isArray(errMessage)
            ? errMessage.map((msg, idx) => <p key={idx}>{msg}</p>)
            : errMessage;
        const containerClassName = enrollmentComplete
            ? "enrollment-complete"
            : verificationComplete
            ? "enrollment-container"
            : "verification-container";
        return (
            <ThemeRoot className="login-root">
                {this.props.isProcessing ? <Loader /> : null}
                <div className="login-page-container">
                    <div className={classnames(loginBoxClasses)}>
                        <div className="login-logo-div"></div>
                        <div className="validation-summary-errors">{errMsgEl}</div>
                        <div className={containerClassName}>
                            <div className="display-label">
                                {formatMessage(messages.enrollmentAgreement)}
                            </div>
                            <Section headerText={formatMessage(messages.enrollmentAgreement)}>
                                <div className="enrollment-body">
                                    {verificationComplete
                                        ? this._getEnrollmentAgreementSection(
                                              enrollmentComplete,
                                              enrollmentText
                                          )
                                        : this._getVerificationSection(verificationCodeSent)}
                                    <br />
                                </div>
                            </Section>
                            {this._getAgreeButton(verificationComplete, enrollmentComplete)}
                            {this._getFormLink(enrollmentComplete)}
                            <DialogBox
                                title={formatMessage(messages.important)}
                                footerType={DialogBoxFooterType.NONE}
                                isOpen={showPrivacyModal}
                                onClose={() => this.setState({ showPrivacyModal: false })}
                            >
                                {" "}
                                {formatMessage(messages.privateOptionWarning)}
                            </DialogBox>
                            <DialogBox
                                action={formatMessage(coreMessages.doneButtonText)}
                                className="private-confirm-modal"
                                title={formatMessage(messages.confirmTitle)}
                                footerType={DialogBoxFooterType.ACTION_CANCEL}
                                isOpen={showPrivacyConfirmModal}
                                onAction={() => this._confirmAccept()}
                                onClose={() =>
                                    this.setState({
                                        showPrivacyConfirmModal: false,
                                    })
                                }
                            >
                                {" "}
                                {formatMessage(messages.privateOptionConfirmation)}
                            </DialogBox>
                        </div>
                    </div>
                </div>
            </ThemeRoot>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: selectors.getError(state),
        isProcessing: selectors.isProcessing(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitialEnrollmentLoad: () => dispatch(actions.initialEnrollmentLoad()),
        onFetchEnrollmentForm: (customerGuid) =>
            dispatch(actions.fetchEnrollmentForm(customerGuid)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export const EnrollmentPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EnrollmentPage_));
