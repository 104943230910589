import * as actions from "./actions";

export const PRODUCT_BLEND_STATE_KEY = "PRODUCT_BLEND_STATE";

export const productBlendInitialState = {
    availableCredits: null,
    availableProducts: [],
    conversionFactors: new Map(),
    creditedRecNutrient: null,
    creditedGridCells: [],
    customBlends: [],
    customProducts: [],
    defaultCarrierGuid: null,
    defaultChemicalTargetRate: 0,
    defaultProductMixTypeGuid: null,
    eventRecCustomProductGuids: [],
    filteredProductBlendPicklists: {
        productParentType: [],
        productType: [],
        manufacturer: [],
        physicalState: [],
    },
    isLoading: false,
    productBlendPicklists: {
        productParentType: [],
        productType: [],
        manufacturer: [],
        physicalState: [],
        dryRateUnits: [],
        liquidRateUnits: [],
        dryPriceUnits: [],
        liquidPriceUnits: [],
        productMixTypes: [],
        nutrients: [],
        densityUnits: [],
    },
    productBlendGuid: null,
    productFilters: {
        productParentTypeGuid: null,
        productTypeGuid: null,
        manufacturerGuid: null,
        physicalStateGuid: null,
    },
};

export const productBlendReducer = (state = productBlendInitialState, action) => {
    switch (action.type) {
        case actions.INIT_PRODUCT_BLEND_MODAL: {
            return Object.freeze({
                ...productBlendInitialState,
                defaultCarrierGuid: state.defaultCarrierGuid,
                defaultProductMixTypeGuid: state.defaultProductMixTypeGuid,
                eventRecCustomProductGuids: state.eventRecCustomProductGuids,
            });
        }
        case actions.SET_AVAILABLE_CREDITS: {
            const { availableCredits } = action.payload;
            return Object.freeze({
                ...state,
                availableCredits,
            });
        }
        case actions.SET_AVAILABLE_PRODUCTS: {
            const { availableProducts } = action.payload;
            return Object.freeze({
                ...state,
                availableProducts,
            });
        }
        case actions.SET_BATCH_CREDITED_REC_NUTRIENTS: {
            const { batchCreditedRecNutrients } = action.payload;
            return Object.freeze({
                ...state,
                batchCreditedRecNutrients,
            });
        }
        case actions.SET_CREDITED_GRID_CELLS: {
            const { creditedGridCells } = action.payload;
            return Object.freeze({
                ...state,
                creditedGridCells,
            });
        }
        case actions.SET_CREDITED_REC_NUTRIENT: {
            const { creditedRecNutrient } = action.payload;
            return Object.freeze({
                ...state,
                creditedRecNutrient,
            });
        }
        case actions.SET_PRODUCT_BLEND_GUID: {
            const { productBlendGuid } = action.payload;
            return Object.freeze({
                ...state,
                productBlendGuid,
            });
        }
        case actions.SET_CONVERSION_FACTORS: {
            const conversionFactors = new Map();
            action.payload.conversionFactors.forEach((conversion) => {
                conversionFactors.set(conversion.name, conversion.factor);
            });
            return Object.freeze({
                ...state,
                conversionFactors,
            });
        }
        case actions.SET_CUSTOM_BLENDS: {
            const { customBlends } = action.payload;
            return Object.freeze({
                ...state,
                customBlends,
            });
        }
        case actions.SET_CUSTOM_PRODUCTS: {
            const { customProducts } = action.payload;
            return Object.freeze({
                ...state,
                customProducts,
            });
        }
        case actions.SET_DEFAULT_CARRIER_GUID: {
            const { defaultCarrierGuid } = action.payload;
            return Object.freeze({
                ...state,
                defaultCarrierGuid,
            });
        }
        case actions.SET_DEFAULT_CHEMICAL_TARGET_RATE: {
            const { defaultChemicalTargetRate } = action.payload;
            return Object.freeze({
                ...state,
                defaultChemicalTargetRate,
            });
        }
        case actions.SET_DEFAULT_PRODUCT_MIX_TYPE_GUID: {
            const { defaultProductMixTypeGuid } = action.payload;
            return Object.freeze({
                ...state,
                defaultProductMixTypeGuid,
            });
        }
        case actions.SET_EVENT_REC_CUSTOM_PRODUCT_GUIDS: {
            const { eventRecCustomProductGuids } = action.payload;
            return Object.freeze({
                ...state,
                eventRecCustomProductGuids,
            });
        }
        case actions.SET_FILTERED_PRODUCT_BLEND_PICKLISTS: {
            const { filteredProductBlendPicklists } = action.payload;
            return Object.freeze({
                ...state,
                filteredProductBlendPicklists,
            });
        }
        case actions.SET_IS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.SET_PRODUCT_BLEND_PICKLISTS: {
            const { productBlendPicklists } = action.payload;
            return Object.freeze({
                ...state,
                productBlendPicklists,
            });
        }
        case actions.SET_PRODUCT_FILTERS: {
            const { productFilters } = action.payload;
            return Object.freeze({
                ...state,
                productFilters,
            });
        }
        default: {
            return state;
        }
    }
};
