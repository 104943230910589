import { createSelector } from "reselect";

import { ACTION_PANEL_STATE_KEY } from "../../selectors";
import { FieldListTabs } from "./actions";
import { FieldAPI } from "@ai360/core";
import * as models from "./models";

export const FIELD_INFO_STATE_KEY = "FIELD_INFO_STATE";
export const FIELD_MODULE_STATE_KEY = "FIELD_MODULE_STATE";

export const getModuleState = (state: Record<string, unknown>): any =>
    state[ACTION_PANEL_STATE_KEY][FIELD_MODULE_STATE_KEY];
export const getFieldInfoState = (state: Record<string, unknown>): any =>
    getModuleState(state)[FIELD_INFO_STATE_KEY];

export const getAgvanceFieldData = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).agvanceFieldData;
export const getActiveTab = (state: Record<string, unknown>): FieldListTabs =>
    getFieldInfoState(state).activeTab;
export const getAgvanceFieldList = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).agvanceFieldList;
export const getAgvanceFieldClassificationList = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).agvanceFieldClassificationList;
export const getAgvanceOrgLevelList = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).agvanceOrgLevelList;
export const getCustomerFieldListWithOrgLevel = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).customerFieldListWithOrgLevel;
export const getErrorCodes = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).errorCodes;
export const getFetchingFieldInfo = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).fetchingFieldInfo;
export const getFieldInfoModel = (state: Record<string, unknown>): models.IFieldModel => ({
    ...getFieldInfoState(state).fieldModel,
});
export const getFieldDeleteAgvanceOverrideFieldGuids = (state: Record<string, unknown>): string[] =>
    getFieldInfoState(state).fieldDeleteAgvanceOverride?.fieldGuids;
export const getFieldModuleDropdowns = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).dropdowns;
export const getIsFieldLoading = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).isLoading;
export const getLastSearchValue = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).lastSearchValue;
export const getFieldsToMove = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).fieldsToMove;
export const getMoveDialogLoading = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).isMoveDialogLoading;
export const getFullFieldRecords = (state: Record<string, unknown>): FieldAPI.IFieldResult[] =>
    getFieldInfoState(state).fullFieldRecords;
export const getIsCombineDialogOpen = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).isCombineDialogOpen;
export const getOrgLevelGuid = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).orgLevelGuid;
export const getResurfaceEvents = (state: Record<string, unknown>): any =>
    getFieldInfoState(state).resurfaceEvents;
export const getActiveTabIsOperationTab = createSelector(
    getActiveTab,
    (activeTab) => activeTab === FieldListTabs.ACTIVE || activeTab === FieldListTabs.SELECTED
);
export const getCombineFieldsError = (state: Record<string, unknown>): unknown =>
    getFieldInfoState(state).combineFieldsError;
