import { HierarchyAPI, SetupReportBooksAPI } from "@ai360/core";
import { AllEffect, all, put, select, takeEvery } from "redux-saga/effects";
import { MergeStatus } from "~/admin/setup/report-books/data/interfaces";
import { getUser } from "~/login/selectors";
import * as actions from "./actions";
import { actions as notificationActions } from "~/notifications";

const deactivateReportBookType = function* (action) {
    try {
        yield put(actions.setLoadingReportBooks(true));
        yield SetupReportBooksAPI.deactivateReportBookType(action.reportBookTypeGuid);
        yield put(actions.getReportBookTypes());
        yield put(actions.setSelectedReportBookTypeGuid(null));
    } catch (err) {
        yield put(actions.setLoadingReportBooks(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const deactivateReportOption = function* (action) {
    try {
        yield put(actions.setLoadingModal(true));
        const canDeleteResponse: SetupReportBooksAPI.IReportBookTypesFromOptionResponse =
            yield SetupReportBooksAPI.getReportBookTypesFromOption(action.reportBookOptionGuid);

        if (canDeleteResponse.canDelete) {
            yield SetupReportBooksAPI.deactivateReportOption(action.reportBookOptionGuid);
            yield put(actions.getReportOptions(null));
        } else {
            yield put(actions.setReportBookOptionsErrors(canDeleteResponse.reportBookTypes));
            yield put(actions.setLoadingModal(false));
        }
    } catch (err) {
        yield put(actions.setLoadingReportBooks(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getFilteredLocationList = function* (action) {
    try {
        yield put(actions.setLoadingOrgLevelGuids(true));
        const userInfo = yield select(getUser);
        const response = yield HierarchyAPI.getOrgLevelList(userInfo.userGuid);
        yield put(actions.setLoadingOrgLevelGuids(false));
        yield put(actions.setFilteredLocationList(response));
    } catch (err) {
        yield put(actions.setLoadingOrgLevelGuids(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getOrgLevelGuids = function* (action) {
    try {
        yield put(actions.setLoadingOrgLevelGuids(true));
        const response = yield SetupReportBooksAPI.getReportBookOrgLevelGuids(
            action.reportBookTypeGuid
        );
        yield put(actions.setLoadingOrgLevelGuids(false));
        yield put(actions.setReportBookOrgLevelGuids(response.orgLevelGuids));
    } catch (err) {
        yield put(actions.setLoadingOrgLevelGuids(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBookReports = function* (action) {
    try {
        yield put(actions.setLoadingReportBookReports(true));
        const response = yield SetupReportBooksAPI.getReportBookReports(action.reportBookTypeGuid);
        yield put(actions.setLoadingReportBookReports(false));
        yield put(actions.setReportBookReports(response.reports));
    } catch (err) {
        yield put(actions.setLoadingReportBookReports(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBookTypeNames = function* (action) {
    try {
        yield put(actions.setLoadingReportBooks(true));
        const userInfo = yield select(getUser);
        const response = yield SetupReportBooksAPI.getReportBookTypeNames(userInfo.companyGuid);
        yield put(actions.setReportBookTypeNames(response.reportBookTypeNames));
        yield put(actions.setLoadingReportBooks(false));
    } catch (err) {
        yield put(actions.setLoadingReportBooks(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportBookTypes = function* (action) {
    try {
        yield put(actions.setLoadingReportBooks(true));
        const { userInfo, locationGuid } = yield generateUserAndLocationInfo();
        const response = yield SetupReportBooksAPI.getReportBookTypes(
            action.includeInactive,
            userInfo.companyGuid,
            locationGuid
        );
        yield put(actions.setReportBookTypes(response.reportBookTypes));
        yield put(actions.setLoadingReportBooks(false));
    } catch (err) {
        yield put(actions.setLoadingReportBooks(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportOptions = function* (action) {
    try {
        yield put(actions.setLoadingModal(true));
        const { userInfo, locationGuid } = yield generateUserAndLocationInfo();
        const response = yield SetupReportBooksAPI.getReportOptions(
            action.reportBookOptionsGuid,
            userInfo.companyGuid,
            locationGuid
        );
        yield put(actions.setLoadingModal(false));
        yield put(actions.setReportOptions(response.reportOptions));
    } catch (err) {
        yield put(actions.setLoadingModal(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const getReportTypes = function* () {
    try {
        const response = yield SetupReportBooksAPI.getReportTypes();
        yield put(actions.setReportTypes(response.reportTypes));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

const getSoilTypeAttributes = function* () {
    try {
        const response = yield SetupReportBooksAPI.getSoilTypeAttributes();
        yield put(actions.setSoilTypeAttributes(response.soilTypeAttributes));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

const mergeReportBookType = function* (action) {
    try {
        yield put(actions.setLoadingReportBooks(true));
        yield put(actions.setMergeStatus(MergeStatus.processing));
        const id = yield SetupReportBooksAPI.mergeReportBookType(action.data);
        yield put(actions.setLoadingReportBooks(false));
        yield put(actions.getReportBookTypes());
        yield put(actions.setSelectedReportBookTypeGuid(id));
        yield put(actions.setMergeStatus(MergeStatus.success));
    } catch (err) {
        yield put(actions.setLoadingReportBooks(false));
        yield put(actions.setMergeStatus(MergeStatus.failed));
        yield put(notificationActions.apiCallError(err, action));
    }
};

const mergeReportOptions = function* (action) {
    try {
        yield put(actions.setLoadingModal(true));
        const id = yield SetupReportBooksAPI.mergeReportBookOptions(action.data);
        yield put(actions.setLoadingModal(false));
        yield put(actions.getReportOptions(null));
        yield put(actions.setSelectedReportOptionGuid(id));
    } catch (err) {
        yield put(actions.setLoadingModal(false));
        yield put(notificationActions.apiCallError(err, action));
    }
};

export function* setupReportBooksSaga(): Generator<AllEffect, void, unknown> {
    yield all([
        takeEvery(actions.DEACTIVATE_REPORT_BOOK_TYPES, deactivateReportBookType),
        takeEvery(actions.DEACTIVATE_REPORT_OPTION, deactivateReportOption),
        takeEvery(actions.GET_FILTERED_LOCATION_LIST, getFilteredLocationList),
        takeEvery(actions.GET_REPORT_BOOK_ORG_LEVEL_GUIDS, getOrgLevelGuids),
        takeEvery(actions.GET_REPORT_BOOK_REPORTS, getReportBookReports),
        takeEvery(actions.GET_REPORT_BOOK_TYPE_NAMES, getReportBookTypeNames),
        takeEvery(actions.GET_REPORT_BOOK_TYPES, getReportBookTypes),
        takeEvery(actions.GET_REPORT_OPTIONS, getReportOptions),
        takeEvery(actions.GET_REPORT_TYPES, getReportTypes),
        takeEvery(actions.GET_SOIL_TYPE_ATTRIBUTES, getSoilTypeAttributes),
        takeEvery(actions.MERGE_REPORT_BOOK_TYPES, mergeReportBookType),
        takeEvery(actions.MERGE_REPORT_OPTIONS, mergeReportOptions),
    ]);
}

const generateUserAndLocationInfo = function* () {
    const userInfo = yield select(getUser);
    const locationGuid =
        userInfo.lastUsedLocationGuid == null || userInfo.lastUsedLocationGuid.length === 0
            ? null
            : userInfo.lastUsedLocationGuid;
    return { userInfo, locationGuid };
};
