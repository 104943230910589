import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";

import { PreferenceAPI, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";
import { setApiResult } from "~/core/api/actions";
import { actions as customerDataActions } from "~/customer-data";
import { actions as recsEventsActions } from "~/recs-events";

export function* fetchPreference(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield PreferenceAPI.getUserPreferences({
            UserGuid,
            Model: UserGuid,
        });
        if (response) {
            yield put(actions.fetchPreferenceSuccess(response));
        } else {
            yield put(actions.fetchPreferenceFailure("No Data"));
        }
    } catch (error) {
        yield put(actions.fetchPreferenceFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchPreference() {
    yield takeEvery(actions.fetchPreference, fetchPreference);
}

export function* savePreference(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            yield PreferenceAPI.saveUserPreferences(requestOptions);
            yield put(actions.savePreferenceSuccess(payload));
            yield put(customerDataActions.fetchFieldsForExpandedCustomers());
            yield put(customerDataActions.fetchSummary());
            yield put(recsEventsActions.fetchSelectedFieldEvents());
            yield put(recsEventsActions.fetchSelectedFieldRecs());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchPreferenceFailure({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchPreferenceFailure("No Data"));
    }
}

export function* watchSavePreference() {
    yield takeEvery(actions.savePreference, savePreference);
}

const PreferenceSagas = function* () {
    yield all([fork(watchFetchPreference), fork(watchSavePreference)]);
};

export default PreferenceSagas;
