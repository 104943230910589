import { defineMessages } from "react-intl";

export const messages = defineMessages({
    accessory: {
        id: "setup.accessory",
        defaultMessage: "{count, plural, one {Accessory} other {Accessories}}",
    },
    accessLevel: { id: "setup.accessLevel", defaultMessage: "Access Level" },
    accumulatedDepreciationAsOfYearEnd: {
        id: "setup.accumulatedDepreciationAsOfYearEnd",
        defaultMessage: "Accumulated Depreciation as of Year End($)",
    },
    active: { id: "setup.active", defaultMessage: "Active" },
    activity: { id: "setup.activity", defaultMessage: "Activity" },
    add: { id: "setup.add", defaultMessage: "Add" },
    addCustomer: { id: "setup.addCustomer", defaultMessage: " Add Customer" },
    addSalesperson: {
        id: "setup.addSalesperson",
        defaultMessage: "Add Salesperson",
    },
    additionalAgvanceCustomer: {
        id: "setup.additionalAgvanceCustomer",
        defaultMessage: "Additional Agvance Customer(s)",
    },
    address: { id: "setup.address", defaultMessage: "Address" },
    addressHeading: {
        id: "setup.addressHeading",
        defaultMessage: "Address(es)",
    },
    addressName: { id: "setup.addressName", defaultMessage: "Address Name" },
    addressType: { id: "setup.addressType", defaultMessage: "Address Type" },
    addressWarn: {
        id: "setup.addressWarn",
        defaultMessage:
            "Unmarking this address as primary will result in no primary address. Please select another primary address.",
    },
    adminLoginEmail: {
        id: "setup.adminLoginEmail",
        defaultMessage: "Admin Login Email",
    },
    adminScript: { id: "setup.adminScript", defaultMessage: "Admin Script" },
    adminScriptCreateEventDefinedLegends: {
        id: "setup.adminScriptCreateEventDefinedLegends",
        defaultMessage: "Create/Resurface Event Defined Legends",
    },
    adminScriptResurfaceEvents: {
        id: "setup.adminScriptResurfaceEvents",
        defaultMessage: "Resurface Events",
    },
    adminScriptSetDefinedLegendsAsDefault: {
        id: "setup.adminScriptSetDefinedLegendsAsDefault",
        defaultMessage: "Set Event Defined Legends as Default",
    },
    adminScriptReprocessSoilTypes: {
        id: "setup.adminScriptReprocessSoilTypes",
        defaultMessage: "Reprocess Soil Types",
    },
    adminScriptSuccessMsg: {
        id: "setup.adminScriptSuccessMsg",
        defaultMessage: "Success, your script was kicked off.",
    },
    adminScriptCancelledMsgExceeds: {
        id: "setup.adminScriptCancelledMsgExceeds",
        defaultMessage: "Script cancelled, list exceeds 200 limit.",
    },
    agBytesAdminEmail: {
        id: "setup.setupAdminEmail",
        defaultMessage: "AgBytes Administrators",
    },
    agEventGeneralGuids: {
        id: "setup.agEventGeneralGuids",
        defaultMessage: "AgEventGeneralGuids",
    },
    agvanceIntegration: {
        id: "setup.agvanceIntegration",
        defaultMessage: "Agvance Integration",
    },
    agvanceLocation: {
        id: "setup.agvanceLocation",
        defaultMessage: "Agvance Location",
    },
    allowBranding: {
        id: "setup.allowBranding",
        defaultMessage: "Allow Branding",
    },
    apiUrl: { id: "setup.apiUrl", defaultMessage: "API URL" },
    appColorTheme: {
        id: "setup.appColorTheme",
        defaultMessage: "App Color Theme",
    },
    application: { id: "setup.application", defaultMessage: "Application" },
    applicationStandardDeviation: {
        id: "setup.applicationStandardDeviation",
        defaultMessage: "Application Standard Deviation",
    },
    areaLimit: { id: "setup.areaLimit", defaultMessage: "Area Limit (Ac)" },
    aspectRatio: { id: "setup.aspectRatio", defaultMessage: "Aspect Ratio" },
    assignedTo: {
        id: "setup.assignedTo",
        defaultMessage: "Assigned To {ownerOperatorLabel}",
    },
    attribute: { id: "setup.attributes", defaultMessage: "Attribute" },
    autoCreateReports: {
        id: "setup.autoCreateReports",
        defaultMessage: "Auto-Create Reports",
    },
    autoExchange: {
        id: "setup.autoExchange",
        defaultMessage: "Auto Exchange",
    },
    automatedTestResultEmail: {
        id: "setup.automatedTestResultEmail",
        defaultMessage: "Automated Test Result Issues",
    },
    binTank: { id: "setup.binTank", defaultMessage: "Bin/Tank" },
    binTankName: { id: "setup.binTankName", defaultMessage: "Bin/Tank Name" },
    bookValueAsOfYearEndLabel: {
        id: "setup.bookValueAsOfYearEndLabel",
        defaultMessage: "Book Value As Of Year End($)",
    },
    brandingName: { id: "setup.brandingName", defaultMessage: "Branding Name" },
    browserTabIcon: {
        id: "setup.browserTabIcon",
        defaultMessage: "Browser Tab Icon",
    },
    business: { id: "setup.business", defaultMessage: "Business Unit" },
    cab: { id: "setup.cab", defaultMessage: "Cab" },
    cancel: { id: "setup.cancel", defaultMessage: "Cancel" },
    canDelete: { id: "setup.canDelete", defaultMessage: "Can Delete" },
    chemigation: { id: "setup.chemigation", defaultMessage: "Chemigation" },
    city: { id: "setup.city", defaultMessage: "City" },
    classificationMethodName: {
        id: "setup.classificationMethodName",
        defaultMessage: "Classification Method",
    },
    classSize: { id: "setup.classSize", defaultMessage: "Class Size" },
    clickHere: { id: "setup.clickHere", defaultMessage: "Click here " },
    colorRampName: { id: "setup.colorRampName", defaultMessage: "Color Ramp" },
    communityData: {
        id: "setup.communityData",
        defaultMessage: "Community Data",
    },
    companyLogo: { id: "setup.companyLogo", defaultMessage: "Company Logo" },
    companyName: { id: "setup.companyName", defaultMessage: "Company Name" },
    completeRegistration: {
        id: "setup.completeRegistration",
        defaultMessage: "Complete Registration",
    },
    configuration: {
        id: "setup.configuration",
        defaultMessage: "Configuration",
    },
    configurationName: {
        id: "setup.configurationName",
        defaultMessage: "Configuration Name",
    },
    confirm: { id: "setup.confirm", defaultMessage: "Confirm" },
    confirmationModalAreYourSure: {
        id: "setup.confirmationModalAreYourSure",
        defaultMessage: "Are you sure?!? You are about to kick off a script that will be:",
    },
    confirmationModalTitle: {
        id: "setup.confirmationModalTitle",
        defaultMessage: "Confirmation",
    },
    confirmationModalBody: {
        id: "setup.confirmationModalBody",
        defaultMessage:
            "Both flow and yield are mapped on this template. Saving this will ignore flow. Do you want to continue?",
    },
    confirmRemoveMsg: {
        id: "setup.confirmRemoveMsg",
        defaultMessage: "Are you sure you want to remove this?",
    },
    connectionType: {
        id: "setup.connectionType",
        defaultMessage: "Connection {count, plural, one {Type} other {Types}}",
    },
    containerSize: {
        id: "setup.containerSize",
        defaultMessage: "Container Size",
    },
    containerSizeUnit: {
        id: "setup.containerSizeUnit",
        defaultMessage: "Container Size Unit",
    },
    copyrightAndPolicyDetail: {
        id: "setup.copyrightAndPolicyDetail:",
        defaultMessage: "Copyright and Policy Detail",
    },
    copyrightText: {
        id: "setup.copyrightText:",
        defaultMessage: "Copyright Text",
    },
    country: { id: "setup.country", defaultMessage: "Country" },
    coverPage: { id: "setup.coverPage", defaultMessage: "Cover Page" },
    coverPageMustBePdf: {
        id: "setup.coverPageMustBePdf",
        defaultMessage: "Invalid Cover Page.  File must be PDF.",
    },
    createConfiguration: {
        id: "setup.createConfiguration",
        defaultMessage: "Create Configuration",
    },
    createCustomerConfDialogLine1: {
        id: "setup.createCustomerConfDialogLine1",
        defaultMessage:
            "The Customer you are attempting to add has the same name as an existing Customer at the same Location.",
    },
    createCustomerConfDialogLine2: {
        id: "setup.createCustomerConfDialogLine2",
        defaultMessage: "Would you still like to add this Customer?",
    },
    createCustomerFailed: {
        id: "setup.createCustomerFailed",
        defaultMessage: "Failed to create a new customer",
    },
    createCustomerConfTitle: {
        id: "setup.createCustomerConfTitle",
        defaultMessage: "Warning",
    },
    createNewPerson: {
        id: "setup.createNewPerson",
        defaultMessage: "+Create New Person",
    },
    createdBy: { id: "setup.createdBy", defaultMessage: "Created By" },
    cropClassName: {
        id: "setup.cropClassName",
        defaultMessage: "Crop Class Name",
    },
    cropName: { id: "setup.cropName", defaultMessage: "Crop Name" },
    croppingSeason: {
        id: "setup.croppingSeason",
        defaultMessage: "Cropping Season",
    },
    cropPurposeList: {
        id: "setup.cropPurposeList",
        defaultMessage: "Crop(s)/Crop Purpose(s)",
    },
    cropPurposeName: {
        id: "setup.cropPurposeName",
        defaultMessage: "Crop Purpose Name",
    },
    currentYearDepreciation: {
        id: "setup.currentYearDepreciation",
        defaultMessage: "Current Year Depreciation($)",
    },
    customer: { id: "setup.customer", defaultMessage: "Customer" },
    customerAffiliation: {
        id: "setup.customerAffiliation",
        defaultMessage: "Customer Affiliation(s)",
    },
    customerAffiliationHeading: {
        id: "setup.customerAffiliationHeading",
        defaultMessage: "Customer Affiliation(s)",
    },
    customerAliasName: { id: "setup.customerAliasName", defaultMessage: "Alias Name" },
    customerDataPoolLabel: {
        id: "setup.customerDataPoolLabel",
        defaultMessage: "Customer (default):",
    },
    customerName: { id: "setup.customerName", defaultMessage: "Customer Name" },
    databaseId: { id: "setup.databaseId", defaultMessage: "Database ID" },
    databaseIdChange: {
        id: "setup.databaseIdChange",
        defaultMessage: "Database ID Change",
    },
    databaseIdChangeMsg: {
        id: "setup.databaseIdChangeMsg",
        defaultMessage:
            "You have changed the Agvance Database ID. Would you like proceed with moving all Customers and Fields under this location to the new Agvance database you have entered an ID for?",
    },
    dataPoolingChangePromptTitle: {
        id: "setup.dataPoolingChangePromptTitle",
        defaultMessage: "Warning",
    },
    dataPoolingChangeToYesPromptLine1: {
        id: "setup.dataPoolingChangeToYesPromptLine1",
        defaultMessage:
            "Making this change will move all previously added data for this Customer to the Community Pool along with all future data.",
    },
    dataPoolingChangeToNoPromptLine1: {
        id: "setup.dataPoolingChangeToNoPromptLine1",
        defaultMessage:
            "Making this change keeps all previously added data in the Community Pooling but all future data will be left out of this Community Pooling.",
    },
    dataPoolingChangePromptLine2: {
        id: "setup.dataPoolingChangePromptLine2",
        defaultMessage: "Are you sure you want to make this change?",
    },
    date: { id: "setup.date", defaultMessage: "Date" },
    dateRead: { id: "setup.dateRead", defaultMessage: "Date Read" },
    deactivateConfiguration: {
        id: "setup.deactivateConfiguration",
        defaultMessage: "Are you sure you want to deactivate this configuration?",
    },
    delay: { id: "setup.delay", defaultMessage: "Delay" },
    deleteLocation: {
        id: "setup.deleteLocation",
        defaultMessage: "Delete Location",
    },
    deleteLocationDlgMsg: {
        id: "setup.deleteLocationDlgMsg",
        defaultMessage:
            "Deleting a Location will remove all Customer Affiliations for this User. Are you sure you want to delete this item?",
    },
    deleteSelected: {
        id: "setup.deleteSelected",
        defaultMessage: "Delete Selected",
    },
    delimiter: { id: "setup.delimiter", defaultMessage: "Delimiter" },
    depth: { id: "setup.depth", defaultMessage: "Depth" },
    depthUnit: { id: "setup.depthUnit", defaultMessage: "Depth Unit" },
    description: { id: "setup.description", defaultMessage: "Description" },
    detail: { id: "setup.detail", defaultMessage: "Detail" },
    details: { id: "setup.details", defaultMessage: "Details" },
    dimensionUnit: {
        id: "setup.dimensionUnit",
        defaultMessage: "Dimension Unit",
    },
    displayTypeName: {
        id: "setup.displayTypeName",
        defaultMessage: "Display Type",
    },
    division: { id: "setup.division", defaultMessage: "Division" },
    driveTrain: { id: "setup.driveTrain", defaultMessage: "Drive Train" },
    duplicateCustomerMsg: {
        id: "setup.duplicateCustomerMsg",
        defaultMessage:
            "Customer is in multiple databases (Same Oracle ID). Fields will be moved to the Primary Customer",
    },
    duplicateLevelNameError: {
        id: "setup.duplicateLevelNameError",
        defaultMessage:
            "The Level Name must be different than other Levels before it can be added.",
    },
    ecDataStandardDeviation: {
        id: "setup.ecDataStandardDeviation",
        defaultMessage: "EC Data Standard Deviation",
    },
    editLevel: { id: "setup.editLevel", defaultMessage: "Edit Level" },
    email: { id: "setup.email", defaultMessage: "Email" },
    emailHeading: { id: "setup.emailHeading", defaultMessage: "Email(s)" },
    employeeInfo: {
        id: "setup.employeeInfoHeading",
        defaultMessage: "Employee Info",
    },
    // THIS IS NOT A SPACE! IT'S INVISIBLE TEXT
    // DO NOT REMOVE THIS "SPACE"
    // if removed please visit https://invisibletext.com/ to get the invisible character back
    empty: { id: "setup.empty", defaultMessage: "ㅤ" },
    endDate: { id: "setup.endDate", defaultMessage: "End Date" },
    endPassDelay: {
        id: "setup.endPassDelay",
        defaultMessage: "End Pass Delay (Seconds)",
    },
    engineDisplacement: {
        id: "setup.engineDisplacement",
        defaultMessage: "Engine Displacement (L)",
    },
    enrolled: { id: "setup.enrolled", defaultMessage: "Enrolled" },
    enrollmentAttachForm: {
        id: "setup.enrollmentAttachForm",
        defaultMessage: "Attach Signed Agreement",
    },
    enrollmentAgreement: {
        id: "setup.enrollmentAgreement",
        defaultMessage: "Enrollment Agreement",
    },
    enrollmentFormLink: {
        id: "setup.enrollmentFormLink",
        defaultMessage: "View Signed Agreement",
    },
    enterMigrateToDatabaseId: {
        id: "setup.enterMigrateToDatabaseId",
        defaultMessage: "Enter Migrate To Database ID:",
    },
    enrollmentFormUploadSuccess: {
        id: "setup.enrollmentFormUploadSuccess",
        defaultMessage: "Enrollment Form Updated",
    },
    enrollmentFormRemoveConfirm: {
        id: "setup.enrollmentFormRemoveConfirm",
        defaultMessage: "Remove Signed Agreement",
    },
    enrollmentRequired: {
        id: "setup.enrollmentRequired",
        defaultMessage: "Enrollment Required",
    },
    enrollmentSendEmailSuccess: {
        id: "setup.enrollmentSendEmailSuccess",
        defaultMessage: "Enrollment Authentication Email has been sent",
    },
    entity: { id: "setup.entity", defaultMessage: "Entity" },
    equipment: { id: "setup.equipment", defaultMessage: "Equipment" },
    equipmentId: { id: "setup.equipmentId", defaultMessage: "Equipment ID" },
    equipmentName: {
        id: "setup.equipmentName",
        defaultMessage: "Equipment Name",
    },
    equipmentNode: {
        id: "setup.equipmentNode",
        defaultMessage: "Equipment (Node)",
    },
    equipmentParentType: {
        id: "setup.equipmentParentType",
        defaultMessage: "Equipment Parent Type",
    },
    equipmentType: {
        id: "setup.equipmentType",
        defaultMessage: "Equipment Type",
    },
    equipmentTypeName: {
        id: "setup.equipmentTypeName",
        defaultMessage: "Equipment Type Name",
    },
    eventType: { id: "setup.eventType", defaultMessage: "Event Type" },
    expiration: { id: "setup.expiration", defaultMessage: "Expiration" },
    expirationDate: {
        id: "setup.expirationDate",
        defaultMessage: "Expiration Date",
    },
    failedCreateEquipment: {
        id: "vendor.saga.error.failedCreateEquipment",
        defaultMessage: "Failed to create {equipmentType} equipment",
    },
    fertigation: { id: "setup.fertigation", defaultMessage: "Fertigation" },
    fetchCompanyListError: {
        id: "setup.fetchCompanyListError",
        defaultMessage: "Failed to fetch Company List",
    },
    fetchFilteredLocationListError: {
        id: "setup.fetchFilteredLocationListError",
        defaultMessage: "Failed to fetch Location List",
    },
    fetchUserCompanyListError: {
        id: "setup.fetchUserCompanyListError",
        defaultMessage: "Failed to fetch user company list",
    },
    fieldGuids: {
        id: "setup.fieldGuids",
        defaultMessage: "Field Guids - 200 Limit",
    },
    fields: { id: "setup.fields", defaultMessage: "Fields" },
    fieldSizeLimit: {
        id: "setup.fieldSizeLimit",
        defaultMessage: "Field Size Limit",
    },
    fileType: { id: "setup.fileType", defaultMessage: "File Type" },
    findPeople: { id: "setup.findPeople", defaultMessage: "Find People" },
    firstName: { id: "setup.firstName", defaultMessage: "First Name" },
    flowDelay: { id: "setup.flowDelay", defaultMessage: "Flow Delay" },
    fuelAdded: { id: "setup.fuelAdded", defaultMessage: "Fuel Added" },
    fuelAddedUnit: {
        id: "setup.fuelAddedUnit",
        defaultMessage: "Fuel Added Unit",
    },
    fuelCapacity: { id: "setup.fuelCapacity", defaultMessage: "Fuel Capacity" },
    fuelCapacityUnit: {
        id: "setup.fuelCapacityUnit",
        defaultMessage: "Fuel Capacity Unit",
    },
    fuelEfficiency: {
        id: "setup.fuelEfficiency",
        defaultMessage: "Fuel Efficiency",
    },
    fuelEfficiencyUnit: {
        id: "setup.fuelEfficiencyUnit",
        defaultMessage: "Fuel Efficiency Unit",
    },
    fuelType: { id: "setup.fuelType", defaultMessage: "Fuel Type" },
    fuelingDate: { id: "setup.fuelingDate", defaultMessage: "Fueling Date" },
    fuelingEvent: { id: "setup.fuelingEvent", defaultMessage: "Fueling Event" },
    generalSettingsText: {
        id: "setup.generalSettingsText",
        defaultMessage: "General Options",
    },
    glnIdentifier: {
        id: "setup.glnIdentifier",
        defaultMessage: "GLN Identifier",
    },
    growthStageOrderName: {
        id: "setup.growthStageOrderName",
        defaultMessage: "Growth Stage Order",
    },
    headerRow: { id: "setup.headerRow", defaultMessage: "Header Row" },
    matchHeaderName: {
        id: "setup.matchHeaderName",
        defaultMessage: "Match Header Name",
    },
    height: { id: "setup.height", defaultMessage: "Height" },
    hideInactiveRecords: {
        id: "setup.hideInactiveRecords",
        defaultMessage: "Hide Inactive Records",
    },
    hitchType: { id: "setup.hitchType", defaultMessage: "Hitch Type" },
    horsepower: { id: "setup.horsepower", defaultMessage: "Horsepower" },
    horsepowerUnit: {
        id: "setup.horsepowerUnit",
        defaultMessage: "Horsepower Unit",
    },
    id: { id: "setup.id", defaultMessage: "ID" },
    ignoreNegativeValues: {
        id: "setup.ignoreNegativeValues",
        defaultMessage: "Ignore Negative Values",
    },
    introMemo: { id: "setup.introMemo", defaultMessage: "Intro/Memo" },
    importSampleTemplates: {
        id: "setup.importSampleTemplates",
        defaultMessage: "Import Sample Templates",
    },
    importSurfaces: {
        id: "setup.importSurfaces",
        defaultMessage: "Import Surfaces",
    },
    invalidConfigurationName: {
        id: "setup.invalidConfigurationName",
        defaultMessage: "Invalid Configuration Name",
    },
    invalidCustomerFieldCheck: {
        id: "setup.invalidCustomerFieldCheck",
        defaultMessage: "Invalid Customer/Field Check",
    },
    invalidLocations: {
        id: "setup.invalidLocations",
        defaultMessage: "Invalid Locations",
    },
    invalidCustomers: {
        id: "setup.invalidCustomers",
        defaultMessage: "Invalid Customers",
    },
    invalidFields: {
        id: "setup.invalidFields",
        defaultMessage: "Invalid Fields",
    },
    irrigation: { id: "setup.irrigation", defaultMessage: "Irrigation" },
    irrigationCategory: { id: "setup.irrigationCategory", defaultMessage: "Irrigation Category" },
    isActive: { id: "setup.isActive", defaultMessage: "Is Active" },
    isAssigned: { id: "setup.isAssigned", defaultMessage: "Is Assigned" },
    jobTitle: { id: "setup.jobTitle", defaultMessage: "Job Title" },
    jobTitleSearch: {
        id: "setup.jobTitleSearch",
        defaultMessage: "Search Job Title",
    },
    labIdentifier: {
        id: "setup.labIdentifier",
        defaultMessage: "Lab Identifier",
    },
    lastModified: { id: "setup.lastModified", defaultMessage: "Last Modified" },
    lastModifiedBy: {
        id: "setup.lastModifiedBy",
        defaultMessage: "Last Modified By",
    },
    lastModifiedDate: {
        id: "setup.lastModifiedDate",
        defaultMessage: "Last Modified Date",
    },
    lastName: { id: "setup.lastName", defaultMessage: "Last Name" },
    length: { id: "setup.length", defaultMessage: "Length" },
    level: {
        id: "setup.level",
        defaultMessage: "{count, plural, one {Level} other {Levels}}",
    },
    levelName: { id: "setup:levelName", defaultMessage: "Level Name" },
    license: { id: "setup.license", defaultMessage: "License" },
    licenseHeading: {
        id: "setup.licenseHeading",
        defaultMessage: "License(s)",
    },
    licenseNumber: {
        id: "setup.licenseNumber",
        defaultMessage: "License Number",
    },
    licenseType: { id: "setup.licenseType", defaultMessage: "License Type" },
    linkedFields: { id: "setup.linkedFields", defaultMessage: "Linked Fields" },
    loadRating: { id: "setup.loadRating", defaultMessage: "Load Rating" },
    loadRatingUnit: {
        id: "setup.loadRatingUnit",
        defaultMessage: "Load Rating Unit",
    },
    location: { id: "setup.location", defaultMessage: "Location" },
    locationAffiliations: {
        id: "setup.locationAffiliations",
        defaultMessage: "Location Affiliation(s)",
    },
    locationID: { id: "setup.locationID", defaultMessage: "Location ID" },
    locationStructure: {
        id: "setup.locationStructure",
        defaultMessage: "Location Structure",
    },
    locationMigrationTool: {
        id: "setup.locationMigrationTool",
        defaultMessage: "Location Migration Tool",
    },
    lockCustomersNotEnrolledYn: {
        id: "setup.lockCustomersNotEnrolledYn",
        defaultMessage: "Lock Customers",
    },
    loginEmail: { id: "setup.loginEmail", defaultMessage: "Login Email" },
    logo: { id: "setup.logo", defaultMessage: "Logo" },
    maintenanceEvent: {
        id: "setup.maintenanceEvent",
        defaultMessage: "Maintenance Event",
    },
    maintenanceType: {
        id: "setup.maintenanceType",
        defaultMessage: "Maintenance Type",
    },
    managementAreaTypeName: {
        id: "setup.managementareatypename",
        defaultMessage: "Management Area Type Name",
    },
    manualOnly: { id: "setup.manualonly", defaultMessage: "Manual Only" },
    manufacturer: { id: "setup.manufacturer", defaultMessage: "Manufacturer" },
    matchCustomers: {
        id: "setup.matchCustomers",
        defaultMessage: "Matched Customers",
    },
    matchFields: { id: "setup.matchFields", defaultMessage: "Matched Fields" },
    maxDistanceAndSwathWidth: {
        id: "setup.maxDistanceAndSwathWidth",
        defaultMessage: "Maximum Distance/Swath Width",
    },
    maxDistance: {
        id: "setup.maxDistance",
        defaultMessage: "Maximum Distance (Feet/Second)",
    },
    maxSwathWidth: {
        id: "setup.maxSwathWidth",
        defaultMessage: "Maximum Swath Width (Feet)",
    },
    maxGrossWeight: {
        id: "setup.maxGrossWeight",
        defaultMessage: "Max Gross Weight",
    },
    maximumSpeed: { id: "setup.maximumSpeed", defaultMessage: "Maximum Speed" },
    maximumSpeedUnit: {
        id: "setup.maximumSpeedUnit",
        defaultMessage: "Maximum Speed Unit",
    },
    meterReading: { id: "setup.meterReading", defaultMessage: "Meter Reading" },
    meterReadingUnit: {
        id: "setup.meterReadingUnit",
        defaultMessage: "Meter Reading Unit",
    },
    migrateCustomerToLocation: {
        id: "setup.migrateCustomerToLocation",
        defaultMessage: "Migrate Customers To Location",
    },
    migrateFromDatabaseStatus: {
        id: "setup.migrateFromDatabaseStatus",
        defaultMessage: "Migrate From Database Status",
    },
    minimumSwathWidth: {
        id: "setup.minimumSwathWidth",
        defaultMessage: "Minimum Swath Width",
    },
    mobileInApp: { id: "setup.mobileInApp", defaultMessage: "Mobile In-App" },
    mobilePhone: { id: "setup.mobilePhone", defaultMessage: "Mobile Phone" },
    modelName: { id: "setup.modelName", defaultMessage: "Model Name" },
    modelYear: { id: "setup.modelYear", defaultMessage: "Model Year" },
    modifyConfiguration: {
        id: "setup.modifyConfiguration",
        defaultMessage: "Modify Configuration",
    },
    monitorId: { id: "setup.monitorId", defaultMessage: "Monitor ID" },
    moveLocation: {
        id: "setup.moveLocation:",
        defaultMessage: "Move Location",
    },
    moveToLocationOrg: {
        id: "setup.moveToLocationOrg",
        defaultMessage: "Move to Location/Org Level",
    },
    name: { id: "setup.name", defaultMessage: "Name" },
    needLocation: {
        id: "setup.needLocation",
        defaultMessage: "Must have at least one location before adding reports.",
    },
    newTemplateName: {
        id: "setup.newTemplateName",
        defaultMessage: "New Template Name",
    },
    newFieldsAdded: {
        id: "setup.newFieldsAdded",
        defaultMessage: "New Fields Added",
    },
    newReportBookName: {
        id: "setup.newReportBookName",
        defaultMessage: "New Report Book Name",
    },
    no: { id: "setup.no", defaultMessage: "No" },
    nonConsolidation: {
        id: "setup.nonConsolidation",
        defaultMessage: "Non-Consolidation",
    },
    noDatabaseIdChange: {
        id: "setup.noDatabaseIdChange",
        defaultMessage: "No database id change is detected",
    },
    noResultsMatch: {
        id: "setup.noResultsMatch",
        defaultMessage: 'No results match "{value}"',
    },
    notes: { id: "setup.notes", defaultMessage: "Notes" },
    nozzle: {
        id: "setup.nozzle",
        defaultMessage: "{count, plural, one {Nozzle} other {Nozzles}}",
    },
    nozzleSize: { id: "setup.nozzleSize", defaultMessage: "Nozzle Size" },
    nozzleSpacing: {
        id: "setup.nozzleSpacing",
        defaultMessage: "Nozzle Spacing",
    },
    nozzleType: { id: "setup.nozzleType", defaultMessage: "Nozzle Type" },
    nozzlesPerSection: {
        id: "setup.nozzlesPerSection",
        defaultMessage: "Nozzles Per Section",
    },
    number: { id: "setup.number", defaultMessage: "Number" },
    numberOfCylinders: {
        id: "setup.numberOfCylinders",
        defaultMessage: "# of Cylinders",
    },
    numberOfClasses: {
        id: "setup.numberOfClasses",
        defaultMessage: "# of Classes",
    },
    numberOfRows: {
        id: "setup.numberOfRows",
        defaultMessage: "Number Of Rows",
    },
    numberOfSections: {
        id: "setup.numberOfSections",
        defaultMessage: "Number Of Sections",
    },
    numberOfSprinklers: {
        id: "setup.numberOfSprinklers",
        defaultMessage: "Number Of Sprinklers",
    },
    numberOfTires: {
        id: "setup.numberOfTires",
        defaultMessage: "Number Of Tires",
    },
    nutrientIdName: {
        id: "setup.nutrientIdName",
        defaultMessage: "Nutrient ID/Name",
    },
    nutrientName: { id: "setup.nutrientName", defaultMessage: "Nutrient Name" },
    nutrientAttributeName: {
        id: "setup.nutrientAttributeName",
        defaultMessage: "Nutrient / Attribute Name",
    },
    options: { id: "setup.setupOptions", defaultMessage: "Options" },
    oracleCustomerDialogTitle: {
        id: "setup.oracleCustomerDialogTitle",
        defaultMessage: "New Location",
    },
    oracleCustomerDialogTextLine1: {
        id: "setup.oracleCustomerDialogTextLine1",
        defaultMessage: "This Customer has already been setup under {existingLocation}.",
    },
    oracleCustomerDialogTextLine2: {
        id: "setup.oracleCustomerDialogTextLine2",
        defaultMessage:
            "{newLocation} will be added to this existing Customer to make it available to you.",
    },
    oracleCustomerDialogTextLine3: {
        id: "setup.oracleCustomerDialogTextLine3",
        defaultMessage: "Would you like to proceed with this change?",
    },
    orgLevelList: {
        id: "setup.orgLevelList",
        defaultMessage: "Location Affiliations",
    },
    orgLevelName: { id: "setup.orgLevelName", defaultMessage: "Location Name" },
    orificeDiameter: {
        id: "setup.orificeDiameter",
        defaultMessage: "Orifice Diameter",
    },
    orificeDiameterUnit: {
        id: "setup.orificeDiameterUnit",
        defaultMessage: "Orifice Diameter Unit",
    },
    overwritePrevious: {
        id: "setup.overwritePrevious",
        defaultMessage: "Overwrite Previous",
    },
    ownerAdminUser: {
        id: "setup.ownerAdminUser",
        defaultMessage: "Owner Admin User",
    },
    ownerAndOperator: {
        id: "setup.ownerAndOperator",
        defaultMessage: "Owner/Operator",
    },
    ownerBrandingName: {
        id: "setup.ownerBrandingName:",
        defaultMessage: "Owner Branding Name",
    },
    ownerBrandingReportLogo: {
        id: "setup.ownerBrandingReportLogo",
        defaultMessage: "Owner Branding Report Logo",
    },
    ownerDataPoolLabel: {
        id: "setup.ownerDataPoolLabel",
        defaultMessage: "Owner:",
    },
    ownerName: { id: "setup.ownerName", defaultMessage: "Owner Name" },
    ownerOperator: {
        id: "setup.ownerOperator",
        defaultMessage: "Owner/Operator",
    },
    ownerPersonality: {
        id: "ownerPersonality",
        defaultMessage: "Owner Personality",
    },
    ownerReportLogo: {
        id: "setup.ownerReportLogo",
        defaultMessage: "Owner Report Logo",
    },
    parentType: { id: "setup.parentType", defaultMessage: "Parent Type" },
    partId: { id: "setup.partId", defaultMessage: "Part ID" },
    payRate: { id: "setup.payRate", defaultMessage: "Pay Rate" },
    payRateUnit: { id: "setup.payRateUnit", defaultMessage: "Pay Rate Unit" },
    payType: { id: "setup.payType", defaultMessage: "Pay Type" },
    person: { id: "setup.person", defaultMessage: "Person" },
    personInActiveConfirm: {
        id: "setup.personInActiveConfirm",
        defaultMessage:
            "Making this User inactive will also make their Person account inactive. Do you want to proceed?",
    },
    phoneNumber: { id: "setup.phoneNumber", defaultMessage: "Phone Number" },
    phoneNumberHeading: {
        id: "setup.phoneNumberHeading",
        defaultMessage: "Phone Number(s)",
    },
    phoneNumbers: {
        id: "setup.phoneNumbers",
        defaultMessage: "Phone Number(s)",
    },
    phoneType: { id: "setup.phoneType", defaultMessage: "Phone Type" },
    photos: { id: "setup.photos", defaultMessage: "Photos" },
    physicalState: {
        id: "setup.physicalState",
        defaultMessage: "Physical State",
    },
    pivotSize: { id: "setup.pivotSize", defaultMessage: "Pivot Size" },
    pivotSizeUnit: {
        id: "setup.pivotSizeUnit",
        defaultMessage: "Pivot Size Unit",
    },
    planter: { id: "setup.planter", defaultMessage: "Planter" },
    plantingStandardDeviation: {
        id: "setup.plantingStandardDeviation",
        defaultMessage: "Planting Standard Deviation",
    },
    policyBodyContent: {
        id: "setup.policyBodyContent",
        defaultMessage: " EULA Body Content",
    },
    policyLastUpdated: {
        id: "setup.policyLastUpdated",
        defaultMessage: "EULA Last Updated",
    },
    pressureUnit: {
        id: "setup.pressureUnit:",
        defaultMessage: "Pressure Unit",
    },
    primary: { id: "setup.primary", defaultMessage: "Primary" },
    primaryCustomerChangeLocationMismatch: {
        id: "setup.primaryCustomerChangeLocationMismatch",
        defaultMessage:
            "The new Primary Customer does not have the same associated Locations as the current Primary so you cannot proceed with this change until they are the same.",
    },
    primaryCustomerChangeText: {
        id: "setup.primaryCustomerChangeText",
        defaultMessage: "Are you sure you want to change the Primary Agvance Customer?",
    },
    primaryCustomerChangeTitle: {
        id: "setup.primaryCustomerChangeTitle",
        defaultMessage: "Primary Customer Change",
    },
    privacyPolicyTitle: {
        id: "setup.privacyPolicyTitle",
        defaultMessage: "EULA Title",
    },
    primaryUser: { id: "setup.primaryUser", defaultMessage: "Primary User" },
    productAssignmentLogHistory: {
        id: "setup.productAssignmentLogHistory",
        defaultMessage: "Product Assignment Log History",
    },
    productsHeader: { id: "setup.product", defaultMessage: "Product(s)" },
    productName: { id: "setup.productName", defaultMessage: "Products" },
    purchaseDate: { id: "setup.purchaseDate", defaultMessage: "Purchase Date" },
    purchaseHistory: {
        id: "setup.purchaseHistory",
        defaultMessage: "Purchase History",
    },
    purchasePrice: {
        id: "setup.purchasePrice",
        defaultMessage: "Purchase Price ($)",
    },
    recSurfaces: { id: "setup.recSurfaces", defaultMessage: "Rec Surfaces" },
    notificationSettings: {
        id: "setup.notificationSettings",
        defaultMessage: "Notification Settings",
    },
    onsitePreferences: {
        id: "setup.onsitePreferences",
        defaultMessage: "Onsite Preferences",
    },
    onsitePreferencesLabel: {
        id: "setup.onsitePreferencesLabel",
        defaultMessage: "Row Level As-Planted Data",
    },
    orgLevelQuickChanger: {
        id: "setup.orgLevelQuickChanger",
        defaultMessage: "Org Level Quick Changer",
    },
    realTimeUpdatesLabel: {
        id: "setup.realTimeUpdatesLabel",
        defaultMessage: "Real-Time Updates",
    },
    autoImportDataLabel: {
        id: "setup.autoImportDataLabel",
        defaultMessage: "Auto Import Data",
    },
    autoImportDataType: {
        id: "setup.autoImportDataType",
        defaultMessage: "Auto Import Data Type",
    },
    recommendedImageSize: {
        id: "setup.recommendedImageSize",
        defaultMessage: "Recommended Image Size: 337px x 76px",
    },
    recommendedFaviconSize: {
        id: "setup.recommendedFaviconSize",
        defaultMessage: "Recommended Image Size: 32px x 32px",
    },
    recommendedPressure: {
        id: "setup.recommendedPressure:",
        defaultMessage: "Recommended Pressure",
    },
    registrationCancel: {
        id: "setup.registrationCancel",
        defaultMessage: "Cancel Registration",
    },
    registrationCompleted: {
        id: "setup.registrationCompleted",
        defaultMessage: "Registration Done",
    },
    registrationLink: {
        id: "setup.registrationLink",
        defaultMessage: " to register your account with {connectionTypeName}.",
    },
    remove: { id: "setup.remove", defaultMessage: "Remove" },
    reportBookName: {
        id: "setup.reportBookName",
        defaultMessage: "Report Book Name",
    },
    reportBookNameInvalid: {
        id: "setup.reportBookNameInvalid",
        defaultMessage: "Report Book Name Invalid",
    },
    reportBookNameDuplicated: {
        id: "setup.reportBookNameDuplicated",
        defaultMessage: "Duplicate Report Book Name not allowed",
    },
    reportBookNameRequired: {
        id: "setup.reportBookNameRequired",
        defaultMessage: "Report Book Name Required",
    },
    reportIssuesToEmail: {
        id: "setup.reportIssuesToEmail",
        defaultMessage: "Report Issues to Email(s)",
    },
    report: { id: "setup.report", defaultMessage: "Report" },
    reportLengthError: {
        id: "setup.reportLengthError",
        defaultMessage: "Must have at least one report",
    },
    reports: { id: "setup.reports", defaultMessage: "Reports" },
    reportLogo: { id: "setup.reportLogo", defaultMessage: "Report Logo" },
    reportType: { id: "setup.reportType", defaultMessage: "Report Type" },
    reportTypes: { id: "setup.reportTypes", defaultMessage: "Report Types" },
    resetPassword: {
        id: "setup.resetPassword",
        defaultMessage: "Reset Password",
    },
    responseEmail: {
        id: "setup.responseEmail",
        defaultMessage: "Response Email",
    },
    retiredDate: { id: "setup.retiredDate", defaultMessage: "Retired Date" },
    requireCustomerSearch: {
        id: "setup.requireCustomerSearch",
        defaultMessage: "Require Customer Search",
    },
    rimDiameter: { id: "setup.rimDiameter", defaultMessage: "Rim Diameter" },
    rimDiameterUnit: {
        id: "setup.rimDiameterUnit",
        defaultMessage: "Rim Diameter Unit",
    },
    roleName: { id: "setup.roleName", defaultMessage: "Role Name" },
    rowSpacing: { id: "setup.rowSpacing", defaultMessage: "Row Spacing" },
    rowSpacingUnit: {
        id: "setup.rowSpacingUnit",
        defaultMessage: "Row Spacing Unit",
    },
    runScript1: {
        id: "setup.runScript1",
        defaultMessage: "Consequences be Damned! Run Script!",
    },
    runScript2: {
        id: "setup.runScript2",
        defaultMessage: "Damn the torpedoes! Full speed ahead! Run Script!",
    },
    runScript3: {
        id: "setup.runScript3",
        defaultMessage: "Never tell me the odds! Run Script!",
    },
    salesperson: { id: "setup.salesperson", defaultMessage: "Salesperson" },
    salespersonID: {
        id: "setup.salespersonID",
        defaultMessage: "Salesperson ID",
    },
    salespersonIDHeading: {
        id: "setup.salespersonIDHeading",
        defaultMessage: "Salesperson ID(s)",
    },
    sampleType: { id: "setup.sampleType", defaultMessage: "Sample Type" },
    sampleTypeName: {
        id: "setup.sampleTypeName",
        defaultMessage: "Sample Type Name",
    },
    saveBatchZapperSuccessful: {
        id: "batchZapper.saveBatchZapperSuccessful",
        defaultMessage: "Operation was successful",
    },
    saveBatchZapperFailed: {
        id: "batchZapper.saveBatchZapperFailed",
        defaultMessage: "Error Zapping",
    },
    scriptOptions: {
        id: "setup.scriptOptions",
        defaultMessage: "3. Script Options",
    },
    seasonFiltersText: {
        id: "setup.seasonFiltersText",
        defaultMessage: "Season Filters",
    },
    seasonFilterSingular: {
        id: "setup.seasonFilterSingular",
        defaultMessage: "Season Filter",
    },
    seasonLayerType: {
        id: "setup.seasonLayerType",
        defaultMessage: "Layer Type",
    },
    seasonFilterOption: {
        id: "setup.seasonFilterOption",
        defaultMessage: "Filter Option",
    },
    searchAttribute: {
        id: "setup.searchAttribute",
        defaultMessage: "Search Attribute",
    },
    searchMustBeAtleast: {
        id: "setup.searchMustBeAtleast",
        defaultMessage: "Enter at least 2 characters…",
    },
    searchFor: {
        id: "setup.searchFor",
        defaultMessage: "Search For {searchedFor}",
    },
    searchLocation: {
        id: "setup.searchLocation",
        defaultMessage: "Search Location",
    },
    secondaryOf: { id: "setup.secondaryOf", defaultMessage: "Secondary Of" },
    sectionLength: {
        id: "setup.sectionLength",
        defaultMessage: "Section Length",
    },
    sectionLengthUnit: {
        id: "setup.sectionLengthUnit",
        defaultMessage: "Section Length Unit",
    },
    selectACompanyMessage: {
        id: "setup.selectACompanyMessage",
        defaultMessage: "Please select a company.",
    },
    selectAdminScript: {
        id: "setup.selectAdminScript",
        defaultMessage: "1. Select Script",
    },
    selectAttributeAttributeTab: {
        id: "setup.selectAttributeAttributeTab",
        defaultMessage: "1. Select Attribute",
    },
    selectAttributeEntityTab: {
        id: "setup.selectEntityEntityTab",
        defaultMessage: "2. Select Attribute",
    },
    selectedOrgLevelGuidsError: {
        id: "setup.selectedOrgLevelGuidsError",
        defaultMessage: "Must have at least one location selected",
    },
    selectEntitiesAttributeTab: {
        id: "setup.selectEntitiesAttributeTab",
        defaultMessage: "2. Select Entities",
    },
    selectEntitiesEntityTab: {
        id: "setup.selectEntitiesEntityTab",
        defaultMessage: "1. Select Entities",
    },
    selectLocations: {
        id: "setup.selectLocations",
        defaultMessage: "2. Select Locations",
    },
    selectMigrateFromLocations: {
        id: "setup.selectMigrateFromLocations",
        defaultMessage: "Select Migrate From Location(s):",
    },
    selectMoveToLocation: {
        id: "setup.selectMoveToLocation",
        defaultMessage: "Select Move to Location (Same Database ID):",
    },
    selectNewParent: {
        id: "setup.selectNewParent",
        defaultMessage: "Select New Parent Location",
    },
    selectPerson: { id: "setup.selectPerson", defaultMessage: "Select Person" },
    sendFromEmail: {
        id: "setup.sendFromEmail",
        defaultMessage: "Send From Email(s)",
    },
    sendResultsToEmail: {
        id: "setup.sendResultsToEmail",
        defaultMessage: "Send Results To Email",
    },
    serialNumber: { id: "setup.serialNumber", defaultMessage: "Serial Number" },
    setCompanyUserPreferenceError: {
        id: "setup.setCompanyUserPreferenceError",
        defaultMessage: "Failed to set company user preference",
    },
    showAll: { id: "setup.showAll", defaultMessage: "Show All" },
    showInactiveRecords: {
        id: "setup.showInactiveRecords",
        defaultMessage: "Show Inactive Records",
    },
    showMapped: { id: "setup.showMapped", defaultMessage: "Show Mapped" },
    sourceDatabaseInvalid: {
        id: "setup.sourceDatabaseInvalid",
        defaultMessage: "Source Database Invalid",
    },
    signerFullName: {
        id: "setup.signerFullName",
        defaultMessage: "Signer's Full Name",
    },
    specificAgEventGeneralGuids: {
        id: "setup.specificAgEventGeneralGuids",
        defaultMessage: "Specific AgEventGeneralGuids",
    },
    specifications: {
        id: "setup.specifications",
        defaultMessage: "Specifications",
    },
    speed: { id: "setup.speed", defaultMessage: "Speed" },
    speedRating: { id: "setup.speedRating", defaultMessage: "Speed Rating" },
    speedLowerPercentile: {
        id: "setup.speedLowerPercentile",
        defaultMessage: "Speed Lower Percentile",
    },
    speedMinimumAbsolute: {
        id: "setup.speedMinimumAbsolute",
        defaultMessage: "Speed Minimum Absolute",
    },
    speedScale: { id: "setup.speedScale", defaultMessage: "Speed Scale" },
    speedUpperPercentile: {
        id: "setup.speedUpperPercentile",
        defaultMessage: "Speed Upper Percentile",
    },
    sprayWidth: { id: "setup.sprayWidth", defaultMessage: "Spray Width" },
    sprayWidthUnit: {
        id: "setup.sprayWidthUnit",
        defaultMessage: "Spray Width Unit",
    },
    standardDeviation: {
        id: "setup.standardDeviation",
        defaultMessage: "Standard Deviation",
    },
    standardDeviationThreshold: {
        id: "setup.standardDeviationThreshold",
        defaultMessage: "Standard Deviation Maximum Removal (%)",
    },
    startDate: { id: "setup.startDate", defaultMessage: "Start Date" },
    startPassDelay: {
        id: "setup.startPassDelay",
        defaultMessage: "Start Pass Delay (Seconds)",
    },
    state: { id: "setup.state", defaultMessage: "State" },
    stateAbbr: { id: "setup.stateAbbr", defaultMessage: "State" },
    statisticalOutliersOption: {
        id: "setup.statisticalOutliersOption",
        defaultMessage: "Statistical Outliers Option",
    },
    submitterId: { id: "setup.submitterId", defaultMessage: "Submitter ID" },
    supervisor: { id: "setup.supervisor", defaultMessage: "Supervisor" },
    supportedFileTypes: {
        id: "setup.supportedFileTypes",
        defaultMessage: "Supported File Types",
    },
    surfaceDefaultsLogHistory: {
        id: "setup.surfaceDefaultsLogHistory",
        defaultMessage: "Surface Defaults Log History",
    },
    swathWidth: { id: "setup.swathWidth:", defaultMessage: "Swath Width" },
    syncFilesFromDate: {
        id: "setup.syncFilesFromDate",
        defaultMessage: "Sync Files From Date",
    },
    systemAttributeName: {
        id: "setup.systemAttributeName",
        defaultMessage: "System Attribute",
    },
    systemLogoDarkTheme: {
        id: "setup.systemLogoDarkTheme",
        defaultMessage: "System Logo Dark Theme",
    },
    systemLogoLightTheme: {
        id: "setup.systemLogoLightTheme",
        defaultMessage: "System Logo Light Theme",
    },
    systemName: { id: "setup.systemName:", defaultMessage: "System Name" },
    systemReportLogo: {
        id: "setup.systemReportLogo",
        defaultMessage: "System Report Logo",
    },
    tareWeight: { id: "setup.tareWeight:", defaultMessage: "Tare Weight" },
    telematicsSetup: {
        id: "setup.telematicsSetup",
        defaultMessage: "Telematics Setup",
    },
    templateName: { id: "setup.templateName", defaultMessage: "Template Name" },
    testingLab: { id: "setup.testingLab", defaultMessage: "Testing Lab" },
    testPackage: { id: "setup.testPackage", defaultMessage: "Test Package" },
    timeZone: { id: "setup.timeZone", defaultMessage: "Time Zone" },
    tireType: { id: "setup.tireType", defaultMessage: "Tire Type" },
    tireWidth: { id: "setup.tireWidth", defaultMessage: "Tire Width (mm)" },
    totalApplicationWidth: {
        id: "setup.totalApplicationWidth",
        defaultMessage: "Total Application Width",
    },
    totalApplicationWidthUnit: {
        id: "setup.totalApplicationWidthUnit",
        defaultMessage: "Total Application Width Unit",
    },
    totalArea: { id: "setup.totalArea", defaultMessage: "Total Area" },
    totalAreaUnit: {
        id: "setup.totalAreaUnit",
        defaultMessage: "Total Area Unit",
    },
    totalCustomers: {
        id: "setup.totalCustomers",
        defaultMessage: "Total Customers",
    },
    totalFields: { id: "setup.totalFields", defaultMessage: "Total Fields" },
    totalMatchCustomers: {
        id: "setup.totalMatchCustomers",
        defaultMessage: "Total Matched Customers",
    },
    totalmatchFields: {
        id: "setup.totalmatchFields",
        defaultMessage: "Total Matched Fields",
    },
    totalMultiDatabaseCustomers: {
        id: "setup.totalMultiDatabaseCustomers",
        defaultMessage: "Total Multi-Database Customers",
    },
    totalInactiveCustomers: {
        id: "setup.totalInactiveCustomers",
        defaultMessage: "Total Inactive Customers",
    },
    totalInactiveFields: {
        id: "setup.totalInactiveFields",
        defaultMessage: "Total Inactive Fields",
    },
    totalSecondaryCustomers: {
        id: "setup.totalSecondaryCustomers",
        defaultMessage: "Total Secondary Customers",
    },
    totalUnmatchCustomers: {
        id: "setup.totalUnmatchCustomers",
        defaultMessage: "Total Unmatched Customers",
    },
    totalUnmatchFields: {
        id: "setup.totalUnmatchFields",
        defaultMessage: "Total Unmatched Fields",
    },
    totalCost: { id: "setup.totalCost", defaultMessage: "Total Cost" },
    towingCapacity: {
        id: "setup.towingCapacity",
        defaultMessage: "Towing Capacity",
    },
    towingCapacityUnit: {
        id: "setup.towingCapacityUnit",
        defaultMessage: "Towing Capacity Unit",
    },
    transmission: { id: "setup.transmission", defaultMessage: "Transmission" },
    tubingSize: { id: "setup.tubingSize", defaultMessage: "Tubing Size" },
    tubingSizeUnit: {
        id: "setup.tubingSizeUnit",
        defaultMessage: "Tubing Size Unit",
    },
    type: { id: "setup.type", defaultMessage: "Type" },
    typeName: { id: "setup.typeName", defaultMessage: "Type Name" },
    unmatchCustomers: {
        id: "setup.unmatchCustomers",
        defaultMessage: "Unmatched Customers",
    },
    unmatchFields: {
        id: "setup.unmatchFields",
        defaultMessage: "Unmatched Fields",
    },
    unit: { id: "setup.unit", defaultMessage: "Unit" },
    unitSystem: { id: "setup.unitSystem", defaultMessage: "Unit System" },
    updateCustomerFailed: {
        id: "setup.updateCustomerFailed",
        defaultMessage: "Failed to update the customer information",
    },
    url: {
        id: "setup.url",
        defaultMessage: "{count, plural, one {URL} other {URL(s)}}",
    },
    usage: { id: "setup.usage", defaultMessage: "Usage" },
    usageReading: { id: "setup.usageReading", defaultMessage: "Usage Reading" },
    usageTotalArea: {
        id: "setup.usageTotalArea",
        defaultMessage: "Usage Total Area",
    },
    usageTotalAreaUnit: {
        id: "setup.usageTotalAreaUnit",
        defaultMessage: "Usage Total Area Unit",
    },
    usageTotalHours: {
        id: "setup.usageTotalHours",
        defaultMessage: "Usage Total Hours",
    },
    usageTotalHoursUnit: {
        id: "setup.usageTotalHoursUnit",
        defaultMessage: "Usage Total Hours Unit",
    },
    usageTotalVolume: {
        id: "setup.usageTotalVolume",
        defaultMessage: "Usage Total Volume",
    },
    usageTotalVolumeUnit: {
        id: "setup.usageTotalVolumeUnit",
        defaultMessage: "Usage Total Volume Unit",
    },
    usageUnit: { id: "setup.usageUnit", defaultMessage: "Usage Unit" },
    useOnly: { id: "setup.useOnly", defaultMessage: "Use Only" },
    user: { id: "setup.user", defaultMessage: "User" },
    userId: { id: "setup.userId", defaultMessage: "User ID" },
    userInActiveConfirm: {
        id: "setup.userInActiveConfirm",
        defaultMessage:
            "Making this Person inactive will also make their User account inactive. Do you want to proceed?",
    },
    userPassword: { id: "setup.userPassword", defaultMessage: "User Password" },
    userRole: { id: "setup.userRole", defaultMessage: "User Role" },
    userRoleMatrix: {
        id: "setup.userRoleMatrix",
        defaultMessage: "User Role Matrix",
    },
    userRoleName: { id: "setup.userRoleName", defaultMessage: "User Role" },
    userRoleSearch: {
        id: "setup.userRoleSearch",
        defaultMessage: "Search User Role",
    },
    userRoleTreeHeader: {
        id: "setup.userRoleTreeHeader",
        defaultMessage: "The user will have access to:",
    },
    userRoleTreeSubHeader: {
        id: "setup.userRoleTreeSubHeader",
        defaultMessage: "You must select at least one item",
    },
    userSearch: { id: "setup.userSearch", defaultMessage: "User Search" },
    userType: { id: "setup.userType", defaultMessage: "User Type" },
    userTypeChange: {
        id: "setup.userTypeChange",
        defaultMessage: "Change User Type",
    },
    userTypeChangeDlgMsg: {
        id: "setup.userTypeChangeDlgMsg",
        defaultMessage:
            "Changing User Type will remove all Customer affiliations for this User. Are you sure you want to change User Type?",
    },
    userTypeChangeSalespersonDlgMsg: {
        id: "setup.userTypeChangeSalespersonDlgMsg",
        defaultMessage:
            "Changing User Type will remove all Salesperson IDs for this User. Are you sure you want to change User Type?",
    },
    userTypeName: { id: "setup.userTypeName", defaultMessage: "User Type" },
    validationOutput: {
        id: "setup.validationOutput",
        defaultMessage: "Validation Output",
    },
    vehicle: { id: "setup.vehicle", defaultMessage: "Vehicle" },
    vendor: { id: "setup.vendor", defaultMessage: "Vendor" },
    vendorName: { id: "setup.vendorName", defaultMessage: "Vendor Name" },
    vendorType: { id: "setup.vendorType", defaultMessage: "Vendor Type" },
    viewUserRoleMatrix: {
        id: "setup.viewUserRoleMatrix",
        defaultMessage: "+View User Role Matrix",
    },
    waterPurpose: { id: "setup.waterPurpose", defaultMessage: "Water Purpose" },
    webInApp: { id: "setup.webInApp", defaultMessage: "Web In-App" },
    weightUnit: { id: "setup.weightUnit", defaultMessage: "Weight Unit" },
    well: { id: "setup.well", defaultMessage: "Well" },
    wellOutput: { id: "setup.wellOutput", defaultMessage: "Well Output" },
    wellOutputUnit: {
        id: "setup.wellOutputUnit",
        defaultMessage: "Well Output Unit",
    },
    wellType: { id: "setup.wellType", defaultMessage: "Well Type" },
    width: { id: "setup.width", defaultMessage: "Width" },
    workOrders: { id: "setup.workOrders", defaultMessage: "Work Orders" },
    yes: { id: "setup.yes", defaultMessage: "Yes" },
    yield: { id: "setup.yield", defaultMessage: "Yield" },
    yieldFilter: { id: "setup.yieldFilter", defaultMessage: "Yield Filter" },
    yieldLowerPercentile: {
        id: "setup.yieldLowerPercentile",
        defaultMessage: "Yield Lower Percentile",
    },
    yieldMinimumAbsolute: {
        id: "setup.yieldMinimumAbsolute",
        defaultMessage: "Yield Minimum Absolute",
    },
    yieldScale: { id: "setup.yieldScale", defaultMessage: "Yield Scale" },
    yieldSmoothVelocity: {
        id: "setup.yieldSmoothVelocity",
        defaultMessage: "Yield Smooth Velocity",
    },
    yieldStandardDeviation: {
        id: "setup.yieldStandardDeviation",
        defaultMessage: "Yield Standard Deviation",
    },
    yieldVelocityUnit: {
        id: "setup.yieldVelocityUnit",
        defaultMessage: "Yield Velocity Unit",
    },
    yieldUpperPercentile: {
        id: "setup.yieldUpperPercentile",
        defaultMessage: "Yield Upper Percentile",
    },
    zipCode: { id: "setup.zipCode", defaultMessage: "Zip Code" },
});
