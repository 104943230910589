import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import classnames from "classnames";

import { setActiveModule } from "~/action-panel/actions";
import { setTriggeredPage } from "~/action-panel/components/rec-module/actions";
import { getTriggeredPage } from "~/action-panel/components/rec-module/selectors";
import { messages as globalMessages } from "~/i18n-messages";
import { DialogBox, DialogBoxFooterType, optionPropType, Pane } from "~/core";
import { CloseIcon } from "~/core/icons";
import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";
import { config as intlConfig } from "~/intl-provider/config";
import {
    actions as recsEventsActions,
    models as recsEventsModels,
    recsModels,
    recsSelectors,
    selectors as recsEventsSelectors,
} from "~/recs-events";

import { BatchRecEventStrip } from "../../../../common/accordion/batch-rec-event-strip";
import { RecEventItem } from "../../../../common/accordion/rec-event-accordion-item";
import { getEventStatusMessageId } from "../../../../common/status-messages";
import { getClassNameForStatusFromEvent } from "../../../../common/status-codes";
import { RecEventInfo, GeneralPaneLabel } from "../../../../common/rec-event-info/rec-event-info";
import { RecEventZoneInfo } from "../../../../common/rec-event-info/rec-event-zone-info";

import { errorCodesApply as recGeneralErrorCodesApply, RecGeneralForm } from "./rec-general-form";

import { messages as moduleMessages } from "../../i18n-messages";
import { BatchRecsList } from "./batch-recs-list";
import * as actions from "../actions";
import * as selectors from "../selectors";

import * as manualApplicationModule from "./rec-manual-application";
import * as equationApplicationModule from "./rec-equation-application";
import * as equationPlantingModule from "./rec-equation-planting";
import * as equationCommon from "./common";
import { plantingModule } from "./rec-manual-planting";
import { BatchEditRecsModal } from "./rec-equation-application";
import {
    actions as rxFileImportActions,
    models as rxFileImportModels,
    selectors as rxFileSelectors,
} from "./rec-equation-application/rx-file-import";

const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
const PROCESSING_REC = "Processing Rec";
const WAITING_TO_PROCESS = "Waiting to Process";
const messages = defineMessages({
    addRecTooltip: {
        id: "recModule.recInfo.addRecTooltip",
        defaultMessage: "Add Rec",
    },
    unimplementedFormLabel: {
        id: "recModule.recInfo.unimplementedFormLabel",
        defaultMessage: "Unknown",
    },
    batchSummaryLine1: {
        id: "recModule.recInfo.batchSummaryLine1",
        defaultMessage: "Create Recs",
    },
    batchSummaryLine2: {
        id: "recModule.recInfo.batchSummaryLine2",
        defaultMessage: "Batching {batchSize} Fields",
    },
    batchEditSummaryLine1: {
        id: "recModule.recInfo.batchSummaryLine1",
        defaultMessage: "Create Recs",
    },
    batchEditSummaryLine2: {
        id: "recModule.recInfo.batchEditSummaryLine2",
        defaultMessage: "Batch Editing {batchRecEditSize} Recs",
    },
});

const getRecTypeNameToFormMap = () =>
    new Map([
        [
            recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION,
            [
                equationApplicationModule.RecEquationApplicationForm,
                equationApplicationModule.errorCodesApply,
                equationApplicationModule.formLabelMessage,
                equationApplicationModule.formLabelIcon,
                equationApplicationModule.RecEquationApplicationSummary,
                equationCommon.RecEquationGroupList,
            ],
        ],
        [
            recsEventsModels.REC_TYPE_NAME_MANUAL_APPLICATION,
            [
                manualApplicationModule.RecManualApplicationForm,
                manualApplicationModule.errorCodesApply,
                manualApplicationModule.formLabelMessage,
                manualApplicationModule.formLabelIcon,
                manualApplicationModule.RecManualApplicationSummary,
                manualApplicationModule.RecManualApplicationFieldData,
            ],
        ],
        [
            recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING,
            [
                equationPlantingModule.RecEquationPlantingForm,
                equationPlantingModule.errorCodesApply,
                equationPlantingModule.formLabelMessage,
                equationPlantingModule.formLabelIcon,
                equationPlantingModule.RecEquationPlantingSummary,
                equationCommon.RecEquationGroupList,
            ],
        ],
        [
            recsEventsModels.REC_TYPE_NAME_MANUAL_PLANTING,
            [
                plantingModule.RecManualPlantingForm,
                plantingModule.errorCodesApply,
                plantingModule.formLabelMessage,
                plantingModule.formLabelIcon,
                plantingModule.RecManualPlantingSummary,
            ],
        ],
    ]);

export class FormLabel_ extends PureComponent {
    static propTypes = {
        canRemove: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        intl: intlShape,
        labelIconEl: PropTypes.element.isRequired,
        labelMessage: PropTypes.object.isRequired,
        labelParameters: PropTypes.object,
        onRemove: PropTypes.func.isRequired,
    };
    static defaultProps = {
        labelParameters: {},
    };

    _onRemoveIconClick(evt) {
        const { onRemove } = this.props;
        evt.preventDefault();
        onRemove();
    }

    render() {
        const { canRemove, hasError, labelIconEl, labelMessage, labelParameters } = this.props;
        const { formatMessage } = this.props.intl;

        const removeIcon = !canRemove ? null : (
            <div
                className="remove-btn"
                title={formatMessage(moduleMessages.delete)}
                onClick={(evt) => this._onRemoveIconClick(evt)}
            >
                <CloseIcon />
            </div>
        );
        return (
            <div
                className={classnames("rec-event-info-tab-label", {
                    "has-error": hasError,
                })}
            >
                {removeIcon}
                {labelIconEl}
                <div className="tab-nav-text">{formatMessage(labelMessage, labelParameters)}</div>
            </div>
        );
    }
}

export const FormLabel = injectIntl(FormLabel_);

export class RecInfo_ extends PureComponent {
    static propTypes = {
        batchFieldGuid: PropTypes.string,
        batchRecDetailsForEdit: PropTypes.any,
        batchRecEditSize: PropTypes.any,
        batchSize: PropTypes.number.isRequired,
        cancelAllEnabled: PropTypes.bool,
        closeAllEnabled: PropTypes.bool,
        closeAllVisible: PropTypes.bool,
        enableSave: PropTypes.bool.isRequired,
        recDetails: PropTypes.object,
        recSummary: PropTypes.object,
        fieldGuid: PropTypes.string,
        generalAdjustments: PropTypes.bool,
        intl: intlShape.isRequired,
        isBatchTemplate: PropTypes.bool.isRequired,
        isBatchRecEdit: PropTypes.bool.isRequired,
        isBatchEditRecsModalActive: PropTypes.bool,
        isEquationsLoading: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        isRxFileImport: PropTypes.bool.isRequired,
        isRxFileImportComplete: PropTypes.bool.isRequired,
        isRxFileImportNextActionEnabled: PropTypes.bool.isRequired,
        isRxFileImportUploadStep: PropTypes.bool.isRequired,
        onBatchRecEditSave: PropTypes.func,
        onCopyBatchTemplate: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onRxFileImportNextStep: PropTypes.func.isRequired,
        onRxFileImportPrepareRecs: PropTypes.func.isRequired,
        onRxFileImportReset: PropTypes.func.isRequired,
        onRxFileImportSetUploadStep: PropTypes.func.isRequired,
        onReturnToBatchRecTemplate: PropTypes.func.isRequired,
        onReturnToBatchRecList: PropTypes.func.isRequired,
        onSaveChanges: PropTypes.func.isRequired,
        permanentAdjustments: PropTypes.bool,
        picklistOptionsCroppingSeason: PropTypes.arrayOf(optionPropType),
        recTypeInfoList: PropTypes.array.isRequired,
        resetBatchRecDetails: PropTypes.any,
        resetBatchRecSummaries: PropTypes.any,
        runAllEnabled: PropTypes.bool,
        saveRecDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number),
        saveRecDetailsErrorFieldGuidList: PropTypes.arrayOf(PropTypes.string),
        setActiveModule: PropTypes.func,
        setTraceSites: PropTypes.func.isRequired,
        setTriggeredPage: PropTypes.func,
        setGeneralAdjustments: PropTypes.func,
        setPermanentAdjustments: PropTypes.func,
        toggleBatchEditRecsModal: PropTypes.any,
        triggeredPage: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isNew: props.recDetails ? !props.recDetails.recGeneralGuid : true,
            isConfirmationModalOpen: false,
        };
    }

    _getAddRecMenuItems() {
        return [];
    }

    _getRecStripItem() {
        const {
            batchRecEditSize,
            batchSize,
            isBatchRecEdit,
            isBatchTemplate,
            onReturnToBatchRecList,
            picklistOptionsCroppingSeason,
            recDetails,
            recSummary,
            recTypeInfoList,
        } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;

        if (!recDetails) {
            return null;
        }
        const croppingSeasonOption = picklistOptionsCroppingSeason.find(
            (option) => option.value === recDetails.croppingSeasonGuid
        );
        const displayNameParts = [
            recDetails.recType,
            croppingSeasonOption?.label,
            moment(recDetails.momentCreatedDate).format("M/D/YY"),
        ].filter((p) => p);

        const batchStripItem =
            batchSize === 0 ? null : isBatchRecEdit ? (
                <div className="item-container batch-strip">
                    <RecEventItem
                        isSelected
                        displayName={recDetails.recType}
                        summary={formatMessage(messages.batchEditSummaryLine2, {
                            batchRecEditSize,
                        })}
                    />
                </div>
            ) : isBatchTemplate && !isBatchRecEdit ? (
                <div className="item-container batch-strip">
                    <RecEventItem
                        isSelected
                        displayName={displayNameParts.join(" - ")}
                        summary={formatMessage(messages.batchSummaryLine2, {
                            batchSize,
                        })}
                    />
                </div>
            ) : (
                <BatchRecEventStrip />
            );

        const recTypeInfo = recTypeInfoList.find(
            (typeInfo) => typeInfo.recTypeGuid === recSummary.recTypeGuid
        );

        if (recDetails.name) {
            displayNameParts.push(recDetails.name);
        }

        const { recTypeGuid, name } = recDetails;
        const recStripItemProps = isBatchTemplate
            ? null
            : {
                  displayName: displayNameParts.join(" - "),
                  isSelected: batchSize > 0,
                  summary: [
                      recSummary.customerName,
                      recSummary.farmName,
                      recSummary.fieldName,
                      `${formatNumber(
                          recSummary.fieldAcres,
                          intlConfig.numberFormatOptions
                      )}  ${formatMessage(globalMessages.acres)}`,
                  ]
                      .filter((v) => v && v !== "")
                      .join(", "),
                  statusCodeClassName: getClassNameForStatusFromEvent(recSummary, recTypeInfo),
                  statusMessageArg: {
                      ...recSummary,
                      statusCode: recSummary.importedStatus,
                  },
                  statusMessageId:
                      recSummary.importedStatus === 0
                          ? undefined
                          : getEventStatusMessageId(recSummary, {
                                recTypeGuid,
                                name,
                            }),
              };

        const recStripItem =
            recStripItemProps == null ? null : (
                <div
                    className={classnames("item-container", {
                        "batch-rec-event-strip": batchSize > 0,
                    })}
                    onClick={onReturnToBatchRecList}
                >
                    <RecEventItem {...recStripItemProps} />
                </div>
            );

        return (
            <div>
                {batchStripItem}
                {recStripItem}
            </div>
        );
    }

    _getRecTypeNameToFormMap() {
        if (this._recTypeNameToFormMap == null) {
            this._recTypeNameToFormMap = getRecTypeNameToFormMap();
        }
        return this._recTypeNameToFormMap;
    }

    _getRecPanes() {
        const panes = [];
        const { recDetails, saveRecDetailsErrorCodeList } = this.props;

        if (!recDetails) {
            return panes;
        }

        const recTypeGuid = recDetails.recTypeGuid;
        const recTypeName = recDetails.recType;

        if (!this._getRecTypeNameToFormMap().has(recTypeName)) {
            console.warn(
                "Ignoring unsupported `rec` type",
                recTypeName,
                this._getRecTypeNameToFormMap()
            );
        }
        const [
            RecEditForm,
            errorCodesApply,
            labelMessage,
            LabelIcon,
            SummarySection,
            FieldDataForm,
        ] = this._getRecTypeNameToFormMap().get(recTypeName);

        const isPlantingEquation = recTypeName === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING;
        const isApplicationEquation =
            recTypeName === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION;
        const isEquation = isApplicationEquation || isPlantingEquation;

        const label = (
            <FormLabel
                canRemove={false}
                hasError={errorCodesApply(saveRecDetailsErrorCodeList)}
                labelMessage={labelMessage}
                labelParameters={{}}
                labelIconEl={<LabelIcon />}
                onRemove={() => null}
            />
        );

        const equationGroupGuid = isEquation
            ? recDetails.recAreaList[0].recs.find((rec) => rec.equationGroupGuid)
            : null;
        panes.push(
            <Pane key={0} label={label}>
                {!FieldDataForm || equationGroupGuid ? null : (
                    <FieldDataForm recEventDetails={recDetails} fieldGuid={recDetails.fieldGuid} />
                )}
                {!equationGroupGuid && isEquation ? null : (
                    <RecEventZoneInfo
                        isEquation={isEquation}
                        isPlantingEquation={isPlantingEquation}
                        modelId={{ recTypeGuid }}
                        SummarySection={SummarySection}
                        EditForm={RecEditForm}
                    />
                )}
            </Pane>
        );

        return panes;
    }

    _onClose(isCancel = false) {
        const {
            onClose,
            isBatchRecEdit,
            isRxFileImport,
            onRxFileImportReset,
            recDetails,
            resetBatchRecDetails,
            resetBatchRecSummaries,
            setActiveModule,
            setTraceSites,
            setTriggeredPage,
            toggleBatchEditRecsModal,
            triggeredPage,
        } = this.props;
        setTraceSites([]);

        const { isNew } = this.state;

        const isEquation =
            recDetails != null &&
            (recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING);

        if (triggeredPage) {
            setActiveModule(triggeredPage);
            setTriggeredPage(null);
        }
        if (isBatchRecEdit) {
            resetBatchRecSummaries();
            resetBatchRecDetails();
            toggleBatchEditRecsModal(false);
        }
        this.props.setGeneralAdjustments(false);
        this.props.setPermanentAdjustments(false);
        if (isRxFileImport) {
            onRxFileImportReset();
        }

        onClose(
            Boolean(recDetails && recDetails.recGeneralGuid === "") ||
                (isNew &&
                    isEquation &&
                    isCancel &&
                    recDetails?.recGeneralGuid !== "" &&
                    !isBatchRecEdit)
        );
    }

    _onSave() {
        const { recDetails, setTraceSites } = this.props;
        setTraceSites([]);
        const needsConfirmation =
            recDetails.recType === recsEventsModels.REC_TYPE_NAME_MANUAL_PLANTING &&
            Boolean(
                recDetails?.recAreaList.some((a) => {
                    return a.recs.some((r) => {
                        return (
                            r.recPlanting.cropGuid &&
                            r.recPlanting.recPlantingVarietyHybridList.some((p) => {
                                return !p.varietyHybridGuid;
                            })
                        );
                    });
                })
            );

        if (needsConfirmation) {
            this._openConfirmDialog();
        } else {
            this._onSaveConfirmed();
        }
    }

    _onSaveConfirmed() {
        if (this.props.isRxFileImport) {
            this.props.onRxFileImportPrepareRecs();
        }
        this.props.setPermanentAdjustments(true);
        this.props.onSaveChanges();
    }

    _confirmYes() {
        this._closeConfirmDialog();
        this._onSaveConfirmed();
    }

    _confirmOnClose() {
        this._closeConfirmDialog();
    }

    _openConfirmDialog() {
        this.setState({
            isConfirmationModalOpen: true,
        });
    }

    _closeConfirmDialog() {
        this.setState({
            isConfirmationModalOpen: false,
        });
    }

    _renderConfirmationDialog() {
        const { formatMessage } = this.props.intl;
        const { isConfirmationModalOpen } = this.state;
        return (
            <DialogBox
                draggable
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={isConfirmationModalOpen}
                onAction={() => this._confirmYes()}
                onClose={() => this._confirmOnClose()}
                title={formatMessage(moduleMessages.confirmationTitle)}
            >
                {formatMessage(moduleMessages.confirmationText)}
            </DialogBox>
        );
    }

    render() {
        const {
            batchSize,
            batchFieldGuid,
            batchRecDetailsForEdit,
            cancelAllEnabled,
            closeAllEnabled,
            closeAllVisible,
            enableSave,
            fieldGuid,
            generalAdjustments,
            isBatchTemplate,
            isBatchRecEdit,
            isEquationsLoading,
            isLoading,
            isBatchEditRecsModalActive,
            isRxFileImport,
            isRxFileImportComplete,
            isRxFileImportNextActionEnabled,
            isRxFileImportUploadStep,
            onBatchRecEditSave,
            onCopyBatchTemplate,
            onClose,
            onReturnToBatchRecTemplate,
            onRxFileImportNextStep,
            onRxFileImportSetUploadStep,
            onSaveChanges,
            permanentAdjustments,
            recDetails,
            recSummary,
            runAllEnabled,
            saveRecDetailsErrorCodeList,
            saveRecDetailsErrorFieldGuidList,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { isNew } = this.state;
        const isEquation =
            recDetails != null &&
            (recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING);
        const zonesWithData =
            recDetails && recDetails.recAreaList
                ? recDetails.recAreaList.filter((zone) => zone.applyRecToArea)
                : [];
        const isRunEnabled =
            !isEquation || isEquationsLoading || isLoading
                ? false
                : Boolean(
                      zonesWithData.length > 0 &&
                          zonesWithData.every((zone) =>
                              zone.recs.every(
                                  (rec) =>
                                      rec.isAllRequiredFieldsSetForRun &&
                                      !rec.isAnyRecAdjustmentProcessing
                              )
                          ) &&
                          zonesWithData.some((zone) =>
                              zone.recs.some((rec) => rec.isRecDirty && rec.isEquationRunEnabled)
                          )
                  );
        const isCloseVisible = !isEquation
            ? false
            : (permanentAdjustments || generalAdjustments) && //Some adjustment must be made to allow a close
              !isRunEnabled && //if run is enabled, there are parts that cannot be saved without running
              zonesWithData.some((zone) =>
                  zone.recs.some(
                      (rec) => rec.isEquationRun || rec.isAnyRecAdjustmentProcessing || rec.recGuid
                  )
              );

        const isCancelEnabled =
            !isEquation ||
            (!isEquationsLoading &&
                !isLoading &&
                (!isNew ||
                    (recsModels.TERMINAL_STATUS_CODES.includes(recSummary.importedStatus) &&
                        recSummary.importedStatus !== 8)) &&
                !zonesWithData.some((zone) =>
                    zone.recs.some((rec) => rec.isAnyRecAdjustmentProcessing)
                ));
        //Non-equation and new Recs should always have cancel enabled
        //Any time a user makes changes worthy of a run or re-run, they should have an out
        //Prior to any permanent adjustments made, Cancel should be available

        const isCloseEnabled = !isEquation
            ? false
            : isCloseVisible &&
              recSummary.recStatuses != null &&
              recSummary.recStatuses !== PROCESSING_REC &&
              recSummary.recStatuses !== "";

        if (isBatchEditRecsModalActive) {
            return (
                <BatchEditRecsModal
                    isOpen={isBatchEditRecsModalActive}
                    // onUpdate={(productMix) => this.onUpdateProductBlending(productMix)}
                    onClose={() => this._onClose()}
                    batchRecDetailsForEdit={batchRecDetailsForEdit}
                />
            );
        }

        if (batchSize > 0 && batchFieldGuid == null) {
            // display batch accordion ..
            //  use `recSummary` to determine `isEquation` in this case, since we don't have
            //  an active `recDetails`
            const isEquation =
                recSummary != null &&
                (recSummary.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                    recSummary.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING);
            return (
                <BatchRecsList
                    isCancelEnabled={cancelAllEnabled}
                    isCloseEnabled={closeAllEnabled}
                    isCloseVisible={closeAllVisible}
                    isEquation={isEquation}
                    isLoading={isLoading}
                    isRunEnabled={runAllEnabled}
                    onBack={onReturnToBatchRecTemplate}
                    onSave={onSaveChanges}
                    onCancel={onClose}
                />
            );
        }

        const isBatchEdit =
            !batchFieldGuid && !fieldGuid
                ? false
                : !isBatchTemplate && batchFieldGuid === fieldGuid;

        const genPaneLabel = (
            <GeneralPaneLabel hasError={recGeneralErrorCodesApply(saveRecDetailsErrorCodeList)} />
        );

        const informationPanes = isBatchRecEdit
            ? [this._getRecPanes()]
            : [
                  <Pane key="general" label={genPaneLabel}>
                      <RecGeneralForm />
                  </Pane>,
                  ...this._getRecPanes(),
              ];

        const farmName = recSummary && recSummary.farmName ? `${recSummary.farmName} - ` : "";
        const customerFarmField = recSummary
            ? `${recSummary.customerName} - ${farmName}${recSummary.fieldName}`
            : "";

        const errorFieldList =
            !recSummary ||
            !saveRecDetailsErrorFieldGuidList ||
            saveRecDetailsErrorFieldGuidList.length === 0 ||
            !saveRecDetailsErrorFieldGuidList.includes(recSummary.fieldGuid)
                ? []
                : [<div key="0">{customerFarmField}</div>];

        return (
            <React.Fragment>
                <RecEventInfo
                    addItemTitle={formatMessage(messages.addRecTooltip)}
                    enableRun={isRunEnabled}
                    enableSave={enableSave}
                    errorCodeList={saveRecDetailsErrorCodeList}
                    errorFieldList={errorFieldList}
                    eventRecItemStrip={this._getRecStripItem()}
                    informationPanes={informationPanes}
                    isBatchTemplate={isBatchTemplate}
                    isBatch={batchSize > 1}
                    isBatchEdit={isBatchEdit}
                    isBatchRecEdit={isBatchRecEdit}
                    isCancelEnabled={isCancelEnabled}
                    isCloseEnabled={isCloseEnabled}
                    isCloseVisible={isCloseVisible}
                    isEquation={isEquation}
                    isLoading={isLoading}
                    isNew={this.state.isNew}
                    isRxFileImport={isRxFileImport}
                    isRxFileImportComplete={isRxFileImportComplete}
                    isRxFileImportNextActionEnabled={isRxFileImportNextActionEnabled}
                    isRxFileImportUploadStep={isRxFileImportUploadStep}
                    addMenuItems={this._getAddRecMenuItems()}
                    canAddRecEvent={false}
                    onBack={
                        isRxFileImport && !isRxFileImportUploadStep
                            ? onRxFileImportSetUploadStep
                            : batchSize === 0 || isBatchTemplate
                            ? null
                            : onReturnToBatchRecTemplate
                    }
                    onSave={
                        isRxFileImport && isRxFileImportUploadStep
                            ? onRxFileImportNextStep
                            : isBatchTemplate
                            ? onCopyBatchTemplate
                            : isBatchRecEdit
                            ? onBatchRecEditSave
                            : this._onSave.bind(this)
                    }
                    onCancel={() => this._onClose(true)}
                />
                {this._renderConfirmationDialog()}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onBatchRecEditSave: () => dispatch(actions.setBatchRecDetailsForEdit([])),
    resetBatchRecSummaries: () => dispatch(actions.setBatchRecSummariesForEdit([])),
    resetBatchRecDetails: () => dispatch(actions.setBatchRecDetailsForEdit([])),
    onClose: (isCancel = false) => dispatch(actions.closeRecInfo(isCancel)),
    onCopyBatchTemplate: () => dispatch(recsEventsActions.copyBatchTemplateToRecs()),
    onRxFileImportNextStep: () => dispatch(rxFileImportActions.onNextStep()),
    onRxFileImportReset: () => dispatch(rxFileImportActions.rxFileImportReset()),
    onRxFileImportSetMatchStep: () => dispatch(rxFileImportActions.setMatchStep()),
    onRxFileImportSetUploadStep: () => dispatch(rxFileImportActions.setUploadStep()),
    onRxFileImportPrepareRecs: () => dispatch(rxFileImportActions.prepareRecs()),
    onSaveChanges: () => dispatch(actions.saveRecInfo()),
    setCurrentBatchFieldGuid: (fieldGuid) =>
        dispatch(recsEventsActions.setCurrentBatchFieldGuid(fieldGuid)),
    setActiveModule: (triggeredPage) => dispatch(setActiveModule(triggeredPage)),
    setGeneralAdjustments: (status) => dispatch(actions.setGeneralAdjustments(status)),
    setPermanentAdjustments: (status) => dispatch(actions.setPermanentAdjustments(status)),
    setTraceSites: (traceSites) => dispatch(recsEventsActions.setTraceSites(traceSites)),
    setTriggeredPage: (triggeredPage) => dispatch(setTriggeredPage(triggeredPage)),
    toggleBatchEditRecsModal: (isOpen) => dispatch(actions.toggleBatchEditRecsModal(isOpen)),
});

const mapStateToProps = (state) => {
    const triggeredPage = getTriggeredPage(state);
    const recInfoState = selectors.getModuleState(state);
    const {
        batchRecSummariesForEdit,
        batchRecDetailsForEdit,
        filteredEquationGroupList,
        recSummary,
        isLoading,
        isBatchEditRecsModalActive,
        generalAdjustments,
        permanentAdjustments,
    } = recInfoState;
    const { batchFieldGuid, loading } = recsEventsSelectors.getZonesState(state);

    const { fieldGuidToRecDetails, saveRecDetailsErrorCodeList, saveRecDetailsErrorFieldGuidList } =
        recsSelectors.getModuleState(state);

    const isRxFileImport =
        filteredEquationGroupList.length === 1 &&
        filteredEquationGroupList[0].groupName === rxFileImportModels.RX_FILE_IMPORT_TYPE_NAME;
    const isRxFileImportComplete = rxFileSelectors.getIsRxFileImportComplete(state);
    const isRxFileImportNextActionEnabled = rxFileSelectors.getIsNextActionEnabled(state);
    const isRxFileImportUploadStep = rxFileSelectors.getIsRxFileImportStepUpload(state);

    const batchSize = Math.max(fieldGuidToRecDetails.size - 1, 0);
    const isBatchTemplate =
        batchSize > 0 && batchFieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;
    const isBatchRecEdit =
        batchRecSummariesForEdit && batchRecSummariesForEdit.length > 0 ? true : false;
    const batchRecEditSize = batchRecSummariesForEdit ? batchRecSummariesForEdit.length : 0;
    const fieldGuid =
        batchSize > 0 ? batchFieldGuid : recSummary != null ? recSummary.fieldGuid : null;

    const recTypeInfoList = recsSelectors.getRecTypeInfoList(state);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    const cancelDisableCheck = [undefined, null, 29, 30, 33, 41]; //Status codes: 29 33 and 41 are Waiting to Process, 30 is Processing Rec
    const enableSave = !isLoading && recsSelectors.getEnableSave(state, fieldGuid);

    const runAllEnabled =
        fieldGuidToRecDetails.size > 1 &&
        Array.from(fieldGuidToRecDetails.values()).some(
            (recDetails) =>
                recDetails.fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID &&
                recDetails.recAreaList &&
                recDetails.recAreaList.some(
                    (recArea) =>
                        recArea.applyRecToArea &&
                        recArea.recs.some(
                            (rec) =>
                                rec.isEquationRunEnabled &&
                                !rec.isAnyRecAdjustmentProcessing &&
                                rec.isRecDirty
                        )
                )
        );
    const closeAllEnabled =
        fieldGuidToRecDetails.size > 1 &&
        Array.from(fieldGuidToRecDetails.values()).some(
            (recDetails) =>
                recDetails.recStatuses &&
                recDetails.recStatuses !== PROCESSING_REC &&
                recDetails.recStatuses !== WAITING_TO_PROCESS
        );
    const closeAllVisible =
        fieldGuidToRecDetails.size > 1 &&
        Array.from(fieldGuidToRecDetails.values()).some(
            (recDetails) =>
                recDetails.recAreaList &&
                recDetails.recAreaList.some(
                    (recArea) =>
                        recArea.applyRecToArea &&
                        recArea.recs.some(
                            (rec) =>
                                rec.isEquationRun || rec.isAnyRecAdjustmentProcessing || rec.recGuid
                        )
                )
        );
    //Disable the cancel button until all of the valid recs have finished processing and begins surfacing
    const cancelAllEnabled =
        (recSummary &&
            !(
                recSummary.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recSummary.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING
            )) || //Enable if not an Equation Rec
        runAllEnabled || //Enable if the Batch Rec has not started running yet
        (fieldGuidToRecDetails.size > 1 &&
            Array.from(fieldGuidToRecDetails.values()).every((recDetails) => {
                //Cycle through the list-when all items meet one of the following, enable the button
                return (
                    recDetails.fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID || //First item in the list on a batch rec is the Batch Template
                    saveRecDetailsErrorFieldGuidList.includes(recDetails.fieldGuid) || //Item does not pass validation for running an equation rec
                    !cancelDisableCheck.includes(recDetails.importedStatus)
                ); //Rec has reached the Processing Surface status/step
            }));

    const isEquationsLoading = selectors.getEquationsLoading(state);

    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );

    return {
        batchFieldGuid,
        batchRecEditSize,
        batchSize,
        batchRecDetailsForEdit,
        cancelAllEnabled,
        closeAllEnabled,
        closeAllVisible,
        enableSave,
        fieldGuid,
        generalAdjustments,
        isBatchTemplate,
        isBatchRecEdit,
        isBatchEditRecsModalActive,
        isEquationsLoading,
        isLoading: isLoading || loading,
        isRxFileImport,
        isRxFileImportComplete,
        isRxFileImportNextActionEnabled,
        isRxFileImportUploadStep,
        permanentAdjustments,
        picklistOptionsCroppingSeason,
        recDetails,
        recSummary,
        recTypeInfoList,
        runAllEnabled,
        saveRecDetailsErrorCodeList,
        saveRecDetailsErrorFieldGuidList,
        triggeredPage,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onReturnToBatchRecList: () => {
        if (stateProps.batchSize > 0) {
            dispatchProps.setCurrentBatchFieldGuid(null);
        }
    },
    onReturnToBatchRecTemplate: () => {
        if (stateProps.batchSize > 0) {
            dispatchProps.setCurrentBatchFieldGuid(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID);
        }
    },
});

export const RecInfo = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecInfo_));
