import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { withRouter } from "react-router-dom";

import { LocalStorageHelpers } from "@ai360/core";

import { messages } from "../i18n-messages";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { Button, Checkbox, TextInput } from "../../core";

const { LoginState } = actions;

export interface ILoginForm_Props {
    copyright?: string;
    error?: any;
    history: any;
    intl: intlShape;
    onError: (error: Error) => void;
    onLogin: (username: string, password: string, history: any) => void;
    onSetError: (error: Error) => void;
    onSetLoginState: (action: number) => void;
    onSetUsers: (users: Record<string, any>) => void;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
}

export interface ILoginForm_State {
    username: string;
    password: string;
    rememberMe: boolean;
}

class LoginForm_ extends Component<ILoginForm_Props, ILoginForm_State> {
    txtPassword: Record<string, any>;
    txtUsername: Record<string, any>;

    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            username: LocalStorageHelpers.get(LocalStorageHelpers.USERNAME) || "",
            password: "",
            rememberMe: Boolean(LocalStorageHelpers.get(LocalStorageHelpers.REMEMBER_ME)),
        };
    }

    private _clearPassword() {
        this.setState({ password: "" });
        if (this.txtPassword) {
            this.txtPassword.input.focus();
        }
    }

    private _handleError(error) {
        this._clearPassword();
        this.props.onError(error);
        this.props.onStopProcessing();
    }

    private _handleKeyDown(event) {
        if (event.key === "Enter") {
            this.login();
        }
    }

    public login() {
        this.props.onStartProcessing();
        this.props.onSetError(null);
        const { username, password, rememberMe } = this.state;
        const { history, intl } = this.props;
        const { formatMessage } = intl;

        if (rememberMe) {
            LocalStorageHelpers.set(LocalStorageHelpers.USERNAME, username);
            LocalStorageHelpers.set(LocalStorageHelpers.REMEMBER_ME);
        } else {
            LocalStorageHelpers.remove(LocalStorageHelpers.USERNAME);
            LocalStorageHelpers.remove(LocalStorageHelpers.REMEMBER_ME);
        }

        if (username.length === 0) {
            this._handleError(formatMessage(messages.usernameRequired));
            return;
        } else if (password.length === 0) {
            this._handleError(formatMessage(messages.passwordRequired));
            return;
        }

        this.props.onLogin(username, password, history);
    }

    public recoverPassword(evt) {
        evt.preventDefault();
        this.props.onSetError(null);
        this.props.onSetLoginState(LoginState.RECOVER_PASSWORD);
    }

    componentDidMount() {
        if (this.state.username.length > 0) {
            this.txtPassword.input.focus();
        } else {
            this.txtUsername.input.focus();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            this._clearPassword();
            this.props.onStopProcessing();
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const inputFormProps = {
            onKeyDown: (e) => this._handleKeyDown(e),
        };
        return (
            <div>
                <div className="login-input-container">
                    <TextInput
                        tabIndex={1}
                        value={this.state.username}
                        autoComplete={true}
                        placeholderText={formatMessage(messages.usernameLbl)}
                        {...inputFormProps}
                        //removing whitespaces as you type does not trigger re-render on state change
                        onChange={(username) => this.setState({ username })}
                        //onBlur does not pass in the text input value, using the value stored by onChange
                        onBlur={() =>
                            this.setState({
                                username: this.state.username.replaceAll(" ", ""),
                            })
                        }
                        ref={(input) => {
                            this.txtUsername = input;
                        }}
                    />
                    <TextInput
                        tabIndex={2}
                        value={this.state.password}
                        password
                        placeholderText={formatMessage(messages.passwordLbl)}
                        {...inputFormProps}
                        onChange={(password) => this.setState({ password })}
                        ref={(input) => {
                            this.txtPassword = input;
                        }}
                    />
                    <div className="login-options-div">
                        <div className="login-remember-me-container">
                            <Checkbox
                                tabIndex={3}
                                value={this.state.rememberMe}
                                label={formatMessage(messages.rememberMeLbl)}
                                {...inputFormProps}
                                onChange={(e, rememberMe) => this.setState({ rememberMe })}
                            />
                        </div>
                        <div className="login-forgot-password">
                            <a
                                tabIndex={4}
                                href="#Recover Password"
                                onClick={(evt) => this.recoverPassword(evt)}
                            >
                                {formatMessage(messages.forgotPasswordLnk)}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="login-center">
                    <Button
                        tabIndex={5}
                        className="login-btn"
                        type="login"
                        {...inputFormProps}
                        onClick={() => this.login()}
                    />
                </div>
            </div>
        );
    }
}
export const LoginForm = injectIntl(LoginForm_);

const mapStateToProps = (state) => {
    return {
        error: selectors.getError(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (email, password, history) => dispatch(actions.login(email, password, history)),
        onSetError: (error) => dispatch(actions.setError(error)),
        onSetLoginState: (loginState) => dispatch(actions.setLoginState(loginState)),
        onSetUsers: (theUsers) => dispatch(actions.setUsersInfo(theUsers)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect<
    Partial<ILoginForm_Props>,
    Partial<ILoginForm_Props>,
    Partial<ILoginForm_Props>
>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LoginForm));
