import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../../i18n-messages";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

// Components
import { ZeroToInfinite, Section } from "~/core";
import EmailItem from "./email-item";

//Style
import "./email.css";

export const EMAIL_ERROR_CODE_LIST = [2085, 17];

export class Email extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        className: PropTypes.string,
        emailAddText: PropTypes.object,
        emailAlias: PropTypes.string,
        emailHeaderText: PropTypes.object,
        emailList: PropTypes.array,
        formKey: PropTypes.string,
        index: PropTypes.number,
        intl: intlShape.isRequired,
        noHeader: PropTypes.bool,
        onChildComponentChange: PropTypes.func,
        required: PropTypes.bool,
    };

    static defaultProps = {
        className: "",
        emailList: [],
        required: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            email: props.emailList,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addEditPanel.mode === "EDIT" && nextProps.emailList !== this.state.email) {
            this.setState({
                email: nextProps.emailList,
            });
        }
        if (nextProps.apiErrors !== this.props.apiErrors) {
            const containsError = EMAIL_ERROR_CODE_LIST.some((errorCode) =>
                nextProps.apiErrors.includes(errorCode)
            );
            if (containsError) {
                const { email } = this.state;
                email.forEach((record) => {
                    record.isError = containsError;
                });
                this.setState({ email });
            }
        }
    }

    addItem = (e, items) => {
        this.setState({ email: items });
    };

    onDelete = (e, items) => {
        this.email = items;
    };

    onChange = ({ type }, value, index) => {
        const email = [...this.state.email];
        if (type) {
            email[index][this.props.emailAlias] = value;
        }
        this.setState({ email }, () =>
            this.props.onChildComponentChange(this.props.formKey, this.state.email)
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { email } = this.state;
        const initialValue = {
            [this.props.emailAlias]: "",
        };
        const { onChange } = this;
        const { apiErrors, apiErrorModel, emailAlias, required } = this.props;
        const props = {
            apiErrors,
            apiErrorModel,
            onChange,
            email,
            emailAlias,
            required,
        };
        let sectionProps = {};
        if (!this.props.noHeader) {
            const emailHeaderText = this.props.emailHeaderText || messages.emailHeading;
            sectionProps = {
                headerText: formatMessage(emailHeaderText),
            };
        }
        const emailAddButtonTxt = this.props.emailAddText || messages.email;
        return (
            <Section {...sectionProps} className={`z-to-n-header ${this.props.className}`}>
                <ZeroToInfinite
                    items={email}
                    initialValue={initialValue}
                    getChildProps={() => null}
                    onDelete={this.onDelete}
                    addItem={this.addItem}
                    deleteText={formatMessage(mainMessages.delete)}
                    addText={createAddLinkLabelText(formatMessage, emailAddButtonTxt)}
                    formatMessage={formatMessage}
                    required={this.props.required}
                >
                    <EmailItem {...props} />
                </ZeroToInfinite>
            </Section>
        );
    }
}

export default injectIntl(Email);
