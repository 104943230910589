import { createSelector } from "reselect";

import Immutable from "immutable";
import * as models from "./models";
import { NonFieldFeature } from "@ai360/core/dist/4x/es/api/non-field-feature";

export const CUSTOMER_DATA_KEY = "CUSTOMER_DATA";

const _getModuleState = (state: any): models.CustomerDataState => state[CUSTOMER_DATA_KEY];
export const getCustomerMap = (state: any) => _getModuleState(state).customerMap;
export const getFieldMap = (state: any) => _getModuleState(state).fieldMap;
export const getOrgLevelMap = (state: any) => _getModuleState(state).orgLevels;
export const getSelectedFieldGuids = (state: any) => _getModuleState(state).selectedFields;
export const getSelectedFieldsCount = (state: any) => getSelectedFieldGuids(state).size;
export const getFetchingCustomerFields = (state: any) =>
    _getModuleState(state).fetchingCustomerFields;
export const getHasFields = (state: any, customerId: string) =>
    (_getModuleState(state).customerMap.get(customerId)?.fields ?? 0) > 0;
export const getLastCustomerPageId = (state: any) => _getModuleState(state).lastCustomerFieldPageId;
export const getIsDoneFetchingCustomers = (state: any) =>
    _getModuleState(state).isDoneFetchingCustomers;
export const getFetchingSummary = (state: any) => _getModuleState(state).fetchingSummary;
export const getQuickSummary = (state: any) => _getModuleState(state).quickSummary;
export const getCustomerFieldMappings = (state: any) =>
    _getModuleState(state).customerFieldMappings;
export const getFetchingFilteredFieldGuids = (state: any): boolean =>
    _getModuleState(state).fetchingFilteredFieldGuids;
export const getFilteredFieldGuids = (state: any): Immutable.Set<string> =>
    _getModuleState(state).filteredFieldGuids;
export const getAutoExpandedCustomers = (state: any): Immutable.Set<string> =>
    _getModuleState(state).autoExpandedCustomers;

export const getSelectedCustomerFields = createSelector(
    getCustomerFieldMappings,
    getSelectedFieldGuids,
    (customerFieldMappings, selectedFieldGuids) =>
        customerFieldMappings.filter((mapping) => selectedFieldGuids.has(mapping.fieldGuid))
);

export const getSelectedFieldGuidsForCustomer = createSelector(
    getSelectedCustomerFields,
    (_, customerGuid: string) => customerGuid,
    (selectedCustomerFields, customerGuid) =>
        selectedCustomerFields
            .filter((mapping) => mapping.customerGuid === customerGuid)
            .map((mapping) => mapping.fieldGuid)
);

export const getSelectedCustomerGuids = createSelector(
    getSelectedCustomerFields,
    (selectedCustomerFields) => selectedCustomerFields.map((f) => f.customerGuid)
);

export const getParsedOrgLevels = createSelector(getOrgLevelMap, (orgLevelMap) => {
    const orgLevels = [...orgLevelMap.values()];
    const { name } = orgLevels.find(({ orgLevelParents }) => orgLevelParents.length === 0) || {
        name: "",
    };
    return orgLevels.map((orgLevel) =>
        models.OrgLevelInfo.parseParentsSummary(
            orgLevel,
            orgLevel.orgLevelParents
                .filter((n) => n !== name)
                .reduce((s, o) => (s !== "" ? `${s} > ${o}` : o), "")
        )
    );
});

export const getNonFieldFeatures = (state: unknown): Immutable.Set<models.NonFieldFeatureInfo> =>
    Immutable.Set<models.NonFieldFeatureInfo>(
        _getModuleState(state)
            .nonFieldFeatureMap.valueSeq()
            .map((x) => x.feature)
    );

export const getVisibleNonFieldFeatures = (state: unknown): Immutable.Set<NonFieldFeature> =>
    _getModuleState(state)
        .nonFieldFeatureMap.reduce((accumulator, value) => {
            if (value.visible) {
                accumulator.add(value.feature);
            }

            return accumulator;
        }, Immutable.Set<NonFieldFeature>().asMutable())
        .asImmutable();

export const getSelectedNonFieldFeatures = (state: unknown): Immutable.Set<NonFieldFeature> =>
    _getModuleState(state)
        .nonFieldFeatureMap.reduce((accumulator, value) => {
            if (value.selected) {
                accumulator.add(value.feature);
            }

            return accumulator;
        }, Immutable.Set<NonFieldFeature>().asMutable())
        .asImmutable();

export const getHighlightedNonFieldFeatures = (state: unknown): Immutable.Set<NonFieldFeature> =>
    _getModuleState(state)
        .nonFieldFeatureMap.reduce((accumulator, value) => {
            if (value.highlighted) {
                accumulator.add(value.feature);
            }

            return accumulator;
        }, Immutable.Set<NonFieldFeature>().asMutable())
        .asImmutable();
