import { defineMessages } from "react-intl";

export const messages = defineMessages({
    acres: { id: "globalMessages.acres", defaultMessage: "Acres" },
    add: { id: "globalMessages.add", defaultMessage: "Add" },
    addSymbol: { id: "globalMessages.addSymbol", defaultMessage: "+" },
    agvanceCustomer: {
        id: "globalMessages.agvanceCustomer",
        defaultMessage: "Agvance Customer",
    },
    alertTitle: { id: "globalMessages.alertTitle", defaultMessage: "Alert" },
    confirmTitle: {
        id: "globalMessages.confirmTitle",
        defaultMessage: "Confirm",
    },
    delete: { id: "globalMessages.delete", defaultMessage: "Delete" },
    deleteConfirmationMessage: {
        id: "globalMessages.deleteConfirmationMessage",
        defaultMessage: "Are you sure you want to remove this item?",
    },
    deselectAll: {
        id: "globalMessages.deselectAll",
        defaultMessage: "Deselect All",
    },
    edit: { id: "globalMessages.edit", defaultMessage: "Edit" },
    enrollmentAgreement: {
        id: "globalMessages.enrollmentAgreement",
        defaultMessage: "Enrollment Agreement",
    },
    enrollmentComplete: {
        id: "globalMessages.enrollmentComplete",
        defaultMessage:
            "You are officially enrolled in AI360. Please click on the following download link to save your copy of the AI360 Enrollment agreement.",
    },
    enrollmentFormLink: {
        id: "globalMessages.enrollmentFormLink",
        defaultMessage: "Download Enrollment Agreement",
    },
    enrollmentPrivacyParagraph: {
        id: "globalMessages.enrollmentPrivacyParagraph",
        defaultMessage:
            "Customer does not grant permission for Processed Data containing Customer’s Raw Data to be aggregated (pooled) with others’ data." +
            "Please be advised that there are limitations on our ability to remove your Raw Data from our system and from the pooled/aggregated data that we received prior to your request, but we will take steps to ensure that the storage of your " +
            "Raw Data is promptly adjusted so that any Raw Data that you send to us after you instruct us to remove it from pooled or aggregate data is no longer included in any pooled or aggregated Processed Data.",
    },
    enrollmentVerificationText: {
        id: "globalMessages.enrollmentVerificationText",
        defaultMessage:
            "In order to access the Enrollment Agreement, click on the button below. This will send you a text message with a 6 digit verification code to enter.",
    },
    enterVerificationCode: {
        id: "globalMessages.enterVerificationCode",
        defaultMessage: "Enter Verification Code",
    },
    emailPlaceholder: {
        id: "globalMessages.emailPlaceholder",
        defaultMessage: "Email",
    },
    errorsTitle: {
        id: "globalMessages.errorsTitle",
        defaultMessage: "{count, plural, one {Validation Error} other {Validation Errors}}",
    },
    important: { id: "globalMessages.important", defaultMessage: "Important" },
    operationSuccessful: {
        id: "globalMessages.operationSuccessful",
        defaultMessage: "Operation was successful",
    },
    privateOption: {
        id: "globalMessages.privateOption",
        defaultMessage: "Private Option:",
    },
    privateOptionConfirmation: {
        id: "globalMessages.privateOptionConfirmation",
        defaultMessage:
            "You have accepted the AI360 Enrollment Agreement and have granted permission for Processed Data containing Customer’s Raw Data to be aggregated (pooled) with others' data. Be assured that pooling data means that your data is ANONYMOUSLY POOLED meaning that No Data Identifying you or your farm will be shared in a data pool. If you would like to change this permission you need to click Cancel and select the Private Option checkbox available within the body of the agreement.",
    },
    privateOptionWarning: {
        id: "globalMessages.privateOptionWarning",
        defaultMessage:
            "By selecting this Privacy Option you will be removing your ability to benefit from all features that leverage this aggregated (pooled) data.",
    },
    saveErrorTextFile: {
        id: "globalMessages.saveErrorTextFile",
        defaultMessage: "Do you want to save the error text file?",
    },
    selectAll: { id: "globalMessages.selectAll", defaultMessage: "Select All" },
    validateImportResultsTitle: {
        id: "globalMessages.validateImportResultsTitle",
        defaultMessage: "Validate Import Results",
    },
    unknownError: {
        id: "globalMessages.unknownError",
        defaultMessage: "An unknown error has occurred.",
    },
    unknownId: {
        id: "globalMessages.unknownId",
        defaultMessage: "Unknown GUID",
    },
    unknownIntegerId: {
        id: "globalMessages.unknownIntegerId",
        defaultMessage: "Unknown ID",
    },
    unknownName: {
        id: "globalMessages.unknownName",
        defaultMessage: "Unknown Name",
    },
});

/**
 * Concatenates the passed string into an add link
 * @param {function} formatMessage function passed for i18n
 * @param {message} message i18n message
 * @param {?object} values Optional object for values (default value is `{ count: 1 }`)
 * @param {?bool} edit Optional flag to change from "Add" to "Edit" (default is false)
 */
export function createAddLinkLabelText(
    formatMessage,
    message,
    values = { count: 1 },
    edit = false
) {
    const addEdit = edit ? messages.edit : messages.add;
    return `${formatMessage(messages.addSymbol)}${formatMessage(addEdit)} ${formatMessage(
        message,
        values
    )}`;
}

/**
 * Concatenates the passed string into an add/edit link
 * @param {function} formatMessage function passed for i18n
 * @param {message} message i18n message
 * @param {?object} values Optional object for values (default value is `{ count: 1 }`)
 */
export function createAddEditLinkLabelText(formatMessage, message, values = { count: 1 }) {
    const addEdit = `${formatMessage(messages.add)}/${formatMessage(messages.edit)}`;
    return `${formatMessage(messages.addSymbol)}${addEdit} ${formatMessage(message, values)}`;
}

/**
 * Concatenates the passed string into a title for add vs edit modals
 * @param {bool} editingItem flag to indicate if "Add" or "Edit"
 * @param {function} formatMessage function passed for i18n
 * @param {message} message i18n message
 * @param {?object} values Optional object for values (default value is `{ count: 1 }`)
 */
export function createAddEditModalTitle(
    editingItem,
    formatMessage,
    message,
    values = { count: 1 }
) {
    const addEdit = editingItem ? messages.edit : messages.add;
    return `${formatMessage(addEdit)} ${formatMessage(message, values)}`;
}
