import { ConnectedComponent, connect } from "react-redux";

import {
    actions as picklistActions,
    selectors as picklistSelectors,
    picklistNames,
} from "~/core/picklist";
import { actions as unitActions } from "~/core/units";
import { selectors as cdSelectors } from "~/customer-data";

import { actions, selectors } from "../data";
import { getSelectedRecGuidSet } from "~/action-panel/components/rec-module/components/rec-list/selectors";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";
import { recsSelectors } from "~/recs-events";
import { ComponentType } from "react";
import { Ai360API } from "@ai360/core";

const mapStateToProps = (state) => ({
    reportData: selectors.getReportData(state),
    reportTypes: selectors.getReportTypes(state),
    reportTypePages: selectors.getReportTypePages(state),
    crops: selectors.getCropOptionsData(state),
    fileNameFormats: selectors.getFileNameFormatData(state),
    seasons: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
    ),
    cropPurpose: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
    ),
    totalingUnit: selectors.getTotalingOptions(state),
    selectedFields: selectors.getFields(state),
    selectedFieldCount: cdSelectors.getSelectedFieldsCount(state),
    defaultFileNameFormat: selectors.selectUserExportFileNameFormatPreference(state),
    defaultMultiFieldRec: selectors.selectUserExportMultiFieldRecPreference(state),
    reportPreferences: selectors.getReportPref(state),
    selectedEventGuidSet: getSelectedEventGuidSet(state) as Set<string>,
    selectedRecGuidSet: getSelectedRecGuidSet(state),
    recGeneralGuidToRecMap: recsSelectors.getRecGeneralGuidToRecSummaryMap(state),
    quickSummary: cdSelectors.getQuickSummary(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearReportHubData: () => dispatch(actions.clearReportHubData()),
    // Actions to be dispatched using needs
    createReport: (payload) => actions.createReport(payload),
    createReports: (payload) => actions.createReports(payload),
    fetchPicklistData: (payload) => picklistActions.fetchPicklistData(payload),
    fetchUnitData: (payload) => unitActions.fetchUnitData(payload),
    getCropDropdown: (payload) => actions.getCropDropdown(payload),
    getFileNameFormat: (payload) => actions.getFileNameFormat(payload),
    getUserMultiFieldRecPreference: (payload) => actions.getUserMultiFieldRecPreference(payload),
    getUserFileNameFormatPreference: (payload) => actions.getUserFileNameFormatPreference(payload),
    getReportPref: () => actions.getReportPrefData(),
    getReportPreferences: () => dispatch(actions.getReportPrefData()),
    getReportTypeList: (payload) => actions.getReportTypeList(payload),
    getReportTypePageList: (payload) => actions.getReportTypePageList(payload),
    getYieldByCompareByOption: (payload) => actions.getYieldByCompareByOption(payload),
    mergeReportData: (payload) => dispatch(actions.mergeReportData(payload)),
    resetReportData: () => dispatch(actions.resetReportData()),
    resetReportPreferencesCache: () => dispatch(Ai360API.util.invalidateTags(["LoginPreferences"])),
    setReportDataValue: (payload) => dispatch(actions.setReportDataValue(payload)),
    updateYieldByCompareByOptions: (payload) => actions.updateYieldByCompareByOptions(payload),
});
export const reportContainer = (
    View: ComponentType<never>
): ConnectedComponent<ComponentType<never>, never> =>
    connect(mapStateToProps, mapDispatchToProps)(View);
