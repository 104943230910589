import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tabs, Pane } from "~/core";
import * as actions from "../../../../actions";
import { FieldAccordionContextMenu } from "~/action-panel/components/context-menus/field-accordion-context-menu/field-accordion-context-menu";
import { defineMessages, injectIntl, intlShape } from "react-intl";

import "./field-select-bar.css";
import { logFirebaseEvent } from "~/utils/firebase";

const messages = defineMessages({
    inactiveTabText: {
        id: "fieldSelect.inactiveTabText",
        defaultMessage: "Inactive",
    },
    activeTabText: {
        id: "fieldSelect.activeTabText",
        defaultMessage: "Active",
    },
    allTabText: {
        id: "fieldSelect.allTabText",
        defaultMessage: "All",
    },
    selectedTabText: {
        id: "fieldSelect.selectedTabText",
        defaultMessage: "Selected",
    },
});

export class FieldSelectBar_ extends Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        className: PropTypes.string,
        hasInactivePermission: PropTypes.bool.isRequired,
        activeTab: PropTypes.oneOf(Object.values(actions.FieldListTabs)),
        hasSelectedFields: PropTypes.bool,
        onTabChange: PropTypes.func.isRequired,
        onSelectAll: PropTypes.func.isRequired,
        onClearAll: PropTypes.func.isRequired,
        onRecSelection: PropTypes.func.isRequired,
        onEventSelection: PropTypes.func.isRequired,
        onExpandAll: PropTypes.func.isRequired,
        onBatchEditDetails: PropTypes.func.isRequired,
        onCollapseAll: PropTypes.func.isRequired,
        onDeleteSelected: PropTypes.func.isRequired,
        onExportBoundaries: PropTypes.func.isRequired,
        onExportDetails: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        children: PropTypes.node,
        userRole: PropTypes.object,
    };
    static defaultProps = {
        activeTab: actions.FieldListTabs.ACTIVE,
        hasSelectedFields: false,
    };
    /// ----------------------------------------- ///

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.activeTab === actions.FieldListTabs.SELECTED &&
            !nextProps.hasSelectedFields
        ) {
            this.props.onTabChange(actions.FieldListTabs.ACTIVE);
        }
    }
    /// Event Handlers ///
    onTabClick = (newTab) => {
        if (newTab === actions.FieldListTabs.SELECTED) {
            logFirebaseEvent("selected_tabs_fields");
        }
        if (
            (newTab === actions.FieldListTabs.INACTIVE && !this.props.hasInactivePermission) ||
            (newTab === actions.FieldListTabs.SELECTED &&
                this.props.activeTab === actions.FieldListTabs.INACTIVE &&
                !this.props.hasSelectedFields) ||
            newTab === this.props.activeTab
        ) {
            this.props.onTabChange(actions.FieldListTabs.ACTIVE);
        } else {
            this.props.onTabChange(newTab);
        }
    };
    getTabFromIdx = (tabIdx) => {
        const { hasInactivePermission } = this.props;
        switch (tabIdx) {
            case 0:
                return hasInactivePermission
                    ? actions.FieldListTabs.INACTIVE
                    : actions.FieldListTabs.ACTIVE;
            case 1:
                return hasInactivePermission
                    ? actions.FieldListTabs.ACTIVE
                    : actions.FieldListTabs.SELECTED;
            case 2:
                console.assert(hasInactivePermission);
                return actions.FieldListTabs.SELECTED;
            default:
                break;
        }
    };
    getIdxFromTab = (tab) => {
        const { hasInactivePermission } = this.props;
        switch (tab) {
            case actions.FieldListTabs.INACTIVE:
                console.assert(hasInactivePermission);
                return 0;
            case actions.FieldListTabs.ACTIVE:
                return hasInactivePermission ? 1 : 0;
            case actions.FieldListTabs.SELECTED:
                return hasInactivePermission ? 2 : 1;
            default:
                break;
        }
    };
    /// ------------- ///

    render() {
        const { formatMessage } = this.props.intl;
        const activeTabText = this.props.hasInactivePermission
            ? formatMessage(messages.activeTabText)
            : formatMessage(messages.allTabText);
        const labelContainerRightElements = [
            <FieldAccordionContextMenu
                key={"field-accordion-context-menu"}
                activeTab={this.props.activeTab}
                onSelectAll={this.props.onSelectAll}
                onClearAll={this.props.onClearAll}
                onRecSelection={this.props.onRecSelection}
                onEventSelection={this.props.onEventSelection}
                onExpandAll={this.props.onExpandAll}
                onBatchEditDetails={this.props.onBatchEditDetails}
                onCollapseAll={this.props.onCollapseAll}
                onDeleteSelected={this.props.onDeleteSelected}
                onExportBoundaries={this.props.onExportBoundaries}
                onExportDetails={this.props.onExportDetails}
                userRole={this.props.userRole}
            />,
        ];

        const panes = [];
        if (this.props.hasInactivePermission) {
            panes.push(
                <Pane
                    key={this.getIdxFromTab(actions.FieldListTabs.INACTIVE)}
                    label={formatMessage(messages.inactiveTabText)}
                >
                    {this.props.children}
                </Pane>
            );
        }
        panes.push(
            <Pane key={this.getIdxFromTab(actions.FieldListTabs.ACTIVE)} label={activeTabText}>
                {this.props.children}
            </Pane>
        );
        panes.push(
            <Pane
                key={this.getIdxFromTab(actions.FieldListTabs.SELECTED)}
                label={formatMessage(messages.selectedTabText)}
                disabled={!this.props.hasSelectedFields}
            >
                {this.props.children}
            </Pane>
        );

        return (
            <div className={classNames("field-filter-tabs", this.props.className)}>
                <Tabs
                    selected={this.getIdxFromTab(this.props.activeTab)}
                    labelContainerLeftElements={[<div key={0} className="spacer"></div>]}
                    labelContainerRightElements={labelContainerRightElements}
                    onTabSelect={(tabIdx) => this.onTabClick(this.getTabFromIdx(tabIdx))}
                >
                    {panes}
                </Tabs>
            </div>
        );
    }
}
export const FieldSelectBar = injectIntl(FieldSelectBar_);
