import { IAction, ICropGrowthStage } from "./models";

export const FETCH_CROP_GROWTH_STAGE_LIST = "event-info/sample-tissue/FETCH_CROP_GROWTH_STAGE_LIST";
export const SET_SHOW_PRODUCTIVITY_RATING = "event-info/sample-tissue/SET_SHOW_PRODUCTIVITY_RATING";
export const SET_CROP_GROWTH_STAGE_LIST = "event-info/sample-tissue/SET_CROP_GROWTH_STAGE_LIST";

export const fetchCropGrowthStageList = (fieldGuid: string): IAction => ({
    type: FETCH_CROP_GROWTH_STAGE_LIST,
    payload: { fieldGuid },
});

export const setShowProductivityRating = (showProductivityRating: boolean): IAction => ({
    type: SET_SHOW_PRODUCTIVITY_RATING,
    payload: { showProductivityRating },
});

export const setCropGrowthStageList = (cropGrowthStageList: ICropGrowthStage[]): IAction => ({
    type: SET_CROP_GROWTH_STAGE_LIST,
    payload: { cropGrowthStageList },
});
