import { createReducer } from "redux-act";
import * as actions from "./actions";

export const USER_PREFERENCE_DATA_KEY = "USER_PREFERENCE_DATA";

const initialState = {
    userPreference: {},
};

export const userPreferenceReducer = createReducer(
    {
        [actions.fetchPreferenceSuccess]: (state, userPreference) => ({
            ...state,
            userPreference,
        }),
        [actions.savePreferenceSuccess]: (state, userPreference) => ({
            ...state,
            userPreference,
        }),
    },
    initialState
);
