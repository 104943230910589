import React, { Component } from "react";
import classnames from "classnames";
import * as blending from "../blending-utils";
import { intlShape } from "react-intl";
import { config as intlConfig } from "~/intl-provider/config";
import { TrashcanIcon } from "~/core/icons";
import { ProductBlendPicklists, ProductMix, ProductMixProduct } from "../model";
import { RecDetails, RecNutrient } from "~/recs-events/recs/model";
import { logFirebaseEvent } from "~/utils/firebase";

import {
    Bucket,
    BucketHeader,
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    NoLink,
    NumericInput,
    SelectInput,
    TextInput,
    Tabs,
    Pane,
} from "~/core";

import { ACTIVE_YN } from "~/core/picklist";

import { ProductMixTypes, PhysicalStates } from "../actions";
import * as blendingModels from "../model";
import { messages } from "../i18n-messages";
import "./product-blend-modal.css";
import {
    NEW,
    LB,
    PER100GAL,
    GALPERAC,
    LBPERAC,
    FLOZPERAC,
    OZPERAC,
    LBPERFT3,
    LBPERGAL,
    GAL,
    LPERAC,
} from "../keywords";
import { debounce } from "lodash";
import { IRecNutrient } from "@ai360/core/dist/4x/es/api/rec/rec.4x";
import { IProductMixProduct } from "@ai360/core/dist/4x/es/api/ag-event/ag-event.4x";

interface CustomProductPercentages {
    nitrogen: number;
    phosphorus: number;
    potassium: number;
    calcium: number;
    magnesium: number;
    sulfur: number;
    iron: number;
    zinc: number;
    copper: number;
    manganese: number;
    boron: number;
    chlorine: number;
    molybdenum: number;
}

interface CustomProduct {
    customProductGuid: string;
    customProductType: string;
    customProductTypeGuid: string;
    defaultRateUnitGuid: string;
    density: number;
    densityUnitGuid: string;
    guaranteedAnalysis: string;
    nutrients: any[];
    physicalState: string;
    physicalStateGuid: string;
    rateUnitGuid?: string;
}

interface ProductFilters {
    manufacturerGuid: string;
    physicalStateGuid: string;
    productParentTypeGuid: string;
    productTypeGuid: string;
}

interface IProductBlendModalProps {
    activeProductMixIdx: number;
    applyBatchCredits: (recNutrientGuid: string[], credits: any) => void;
    applyCredits?: (recNutrientGuid: string, credits: any) => void;
    areaId?: number;
    availableCredits?: any;
    availableProducts?: any[];
    batchCreditedRecNutrients?: any[];
    batchRecDetailsForEdit?: any[];
    calculatedArea: number;
    carrierProducts?: any[];
    clearBatchCreditedRecNutrients?: () => void;
    clearCreditedRecNutrient?: () => void;
    clearCustomBlends: () => void;
    clearProductBlendGuid: () => void;
    conversionFactors?: Map<string, number>;
    creditedGridCells?: any[];
    creditedRecNutrient?: any;
    customBlends?: any[];
    customProducts?: any[];
    deactivateProductBlend?: (guid: string) => void;
    defaultCarrierGuid?: string;
    defaultChemicalTargetRate?: number;
    defaultProductMixTypeGuid?: string;
    fetchAvailableCredits?: (guid: string) => void;
    fetchCreditedGridCells?: (guids: string[]) => void;
    fetchCustomBlends: (typeGuid: string, nutrientGuid?: string) => void;
    fetchCustomProducts: () => void;
    filteredNutrientProducts?: any[];
    filteredProductBlendPicklists?: any;
    filteredProducts?: any[];
    hideAreaCalculations?: boolean;
    initProductBlending: () => void;
    intl: intlShape;
    isBatchRecEdit?: boolean;
    isEquationComplete?: boolean;
    isEquation?: boolean;
    isLime?: boolean;
    isFromEquationRec?: boolean;
    isLoading: boolean;
    isOpen: boolean;
    isProcessingRecNutrient?: boolean;
    nutrientGuid?: string;
    nutrientName?: string;
    onCancel: () => void;
    onSave: () => void;
    onUpdate: (update: any) => void;
    onUpdateRecNutrient?: (recNutrient: RecNutrient, hasProductsChanged: boolean) => void;
    productBlendGuid?: string;
    productBlendPicklists?: ProductBlendPicklists;
    productFilters?: ProductFilters;
    recNutrient?: any;
    setIsProcessingRecNutrient?: (status: boolean) => void;
    productMix: ProductMix;
    saveProductBlend: (
        blendInfo: any,
        equation: boolean,
        updateProductMix: (info: any) => void
    ) => void;
    saveCustomProduct: (product: CustomProduct) => void;
    setCustomBlends: (customBlends: any[]) => void;
    setDefaultCarrierGuid: (productGuid: string) => void;
    setDefaultProductMixTypeGuid: (productMixTypeGuid: string) => void;
    updateProductFilter: (filterProp: string, filterValue: any) => void;
    updateBatchRecDetails?: (recAreaList: any[]) => void;
    limeEfficiency?: number;
    minimumRate?: number;
    maximumRate?: number;
    zerosBelowMinimum?: number;
    minimumIncludeZeros?: number;
    minimumExcludeZeros?: number;
    zerosBelowSwitchRate?: number;
    switchRate?: number;
    percentAdjustment?: number;
    isCarrier?: boolean;
    costPerAcre?: number;
    totalProduct?: number;
    totalCost?: number;
    targetCost?: number;
    targetRate?: number;
}

interface IProductBlendModalState {
    isCreditsExpanded: boolean;
    isEquationSavingBlend: boolean;
    isBlendingActive: boolean;
    blendingOriginal: any;
    selectedTab: number;
    showProductFilters: boolean;
    selectedProductGuid: string;
    blendName: string;
    hasSavedBlend: boolean;
    loadCustomBlendModalOpen: boolean;
    selectedExistingBlendIdx: number;
    preserveRatesOnLoad: boolean;
    clearProductsModalOpen: boolean;
    nextSelectedTab: number;
    customProductList: any[];
    customProductPercentages: CustomProductPercentages;
    customProduct: CustomProduct;
    selectedExistingBlendGuid: string;
    deactivateProductBlendModalOpen: boolean;
    densityValidationCode: number;
    isSavingCustomProduct: boolean;
    isSavingModal: boolean;
    hasFetchedRecNutrientData: boolean;
    credits: any[];
    isResetRecNutrient: boolean;
    isModalInitialized: boolean;
    isModalInitializing: boolean;
    pendingCodes: any[];
    storedCodes: any[];
    lastValidRecNutrient: any;
    originalRecNutrient: any;
    appliedAreaIncludeZeros: any;
}

export class ProductBlendModal_ extends Component<
    IProductBlendModalProps,
    IProductBlendModalState
> {
    static defaultProps = {
        calculatedArea: 1,
        hideAreaCalculations: false,
        isEquation: false,
        isEquationComplete: false,
    };

    constructor(props: IProductBlendModalProps) {
        super(props);
        this.state = {
            isCreditsExpanded: false,
            isEquationSavingBlend: false,
            isBlendingActive: false,
            blendingOriginal: null,
            selectedTab: 0,
            showProductFilters: false,
            selectedProductGuid: null,
            blendName: props.productMix.name,
            hasSavedBlend: props.productMix.hasSavedBlend,
            loadCustomBlendModalOpen: false,
            selectedExistingBlendIdx: -1,
            preserveRatesOnLoad: false,
            clearProductsModalOpen: false,
            nextSelectedTab: -1,
            customProductList: [...props.customProducts],
            customProductPercentages: {
                nitrogen: null,
                phosphorus: null,
                potassium: null,
                calcium: null,
                magnesium: null,
                sulfur: null,
                iron: null,
                zinc: null,
                copper: null,
                manganese: null,
                boron: null,
                chlorine: null,
                molybdenum: null,
            },
            customProduct: {
                customProductGuid: null,
                customProductType: "",
                customProductTypeGuid: null,
                defaultRateUnitGuid: null,
                density: null,
                densityUnitGuid: null,
                guaranteedAnalysis: "",
                nutrients: [],
                physicalState: "",
                physicalStateGuid: null,
            },
            selectedExistingBlendGuid: null,
            deactivateProductBlendModalOpen: false,
            densityValidationCode: 0,
            isSavingCustomProduct: false,
            isSavingModal: false,
            hasFetchedRecNutrientData: false,
            credits: [],
            isResetRecNutrient: false,
            isModalInitialized: false,
            isModalInitializing: false,
            pendingCodes: [],
            storedCodes: [],
            lastValidRecNutrient: props.recNutrient,
            originalRecNutrient: null,
            appliedAreaIncludeZeros: 0,
        };
    }

    componentDidMount(): void {
        this.props.initProductBlending();
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProductBlendModalProps): void {
        const {
            creditedGridCells,
            creditedRecNutrient,
            recNutrient,
            productMix,
            productBlendPicklists,
            customProducts,
            productBlendGuid,
            isEquation,
            isProcessingRecNutrient,
            batchCreditedRecNutrients,
            batchRecDetailsForEdit,
        } = nextProps;
        const { productMixTypes } = productBlendPicklists;

        if (JSON.stringify(recNutrient) !== JSON.stringify(this.props.recNutrient)) {
            this.#handleChangedRecNutrient(recNutrient, isProcessingRecNutrient);
        }
        if (
            isEquation &&
            recNutrient &&
            recNutrient.recNutrientGuid &&
            !this.state.hasFetchedRecNutrientData
        ) {
            this.#handleCredits(nextProps);
        }
        if (recNutrient && this.state.hasFetchedRecNutrientData) {
            this.setState({
                appliedAreaIncludeZeros: this._calculateAppliedAcres(recNutrient),
            });
        }
        if (isEquation && creditedGridCells !== this.props.creditedGridCells) {
            if (this.state.originalRecNutrient == null) {
                this.setState(
                    {
                        originalRecNutrient: {
                            ...nextProps.recNutrient,
                            creditedGridCells,
                        },
                    },
                    () => {
                        this._updateRecNutrient({ creditedGridCells });
                    }
                );
            } else {
                this._updateRecNutrient({ creditedGridCells }, false, nextProps);
            }
        }
        if (batchCreditedRecNutrients) {
            this.#setBatchCreditedRecNutrients(
                nextProps,
                batchRecDetailsForEdit,
                batchCreditedRecNutrients
            );
        }
        if (creditedRecNutrient) {
            const newRecNutrient = this._initializeCreditedRecNutrient(
                creditedRecNutrient,
                recNutrient,
                nextProps
            );
            this._updateRecNutrient(newRecNutrient);
            this.props.clearCreditedRecNutrient();
        }
        if (JSON.stringify(customProducts) !== JSON.stringify(this.props.customProducts)) {
            this.#setCustomProductList(customProducts);
        }
        if (productMixTypes.length > 0) {
            this.#setProductMixType(productMixTypes, nextProps);
        }
        if (
            productMix.productMixType.toLowerCase() === ProductMixTypes.MANURE &&
            this.state.isSavingCustomProduct &&
            productBlendGuid
        ) {
            this.#handleCustomProductSave(productMix, productBlendGuid, nextProps);
        }
        if (this.state.isSavingModal) {
            this.#handleSave(productMix);
        }
        if (blending.getIsDataLoaded(nextProps)) {
            this.#handleProductMix(nextProps, productMixTypes);
        }
        if (this.state.isModalInitializing) {
            this.setState(
                {
                    isModalInitialized: true,
                    isModalInitializing: false,
                },
                () => this.#adjustRecNutrientParameterMinMax()
            );
        }
        if (this.state.isEquationSavingBlend && nextProps.productBlendGuid) {
            this._updateProductMix({
                productBlendGuid: nextProps.productBlendGuid,
                productBlendName: this.state.blendName,
                hasSavedBlend: true,
            });
            this.setState({ isEquationSavingBlend: false, hasSavedBlend: true });
        }
    }

    #handleChangedRecNutrient(recNutrient, isProcessingRecNutrient): void {
        const hasProductsChanged =
            JSON.stringify(
                recNutrient.recNutrientProductMix.products.map(
                    (product) => product.productGuid || product.customProductGuid
                )
            ) !==
            JSON.stringify(
                this.props.recNutrient.recNutrientProductMix.products.map(
                    (product) => product.productGuid || product.customProductGuid
                )
            );

        if (isProcessingRecNutrient) {
            this.props.setIsProcessingRecNutrient(false);
        } else if (hasProductsChanged) {
            this._clearValidationCodes(recNutrient);
        }
    }

    #handleCredits(nextProps): void {
        const {
            availableCredits,
            batchRecDetailsForEdit,
            creditedGridCells,
            isBatchRecEdit,
            recNutrient,
        } = nextProps;
        const recNutrientGuidList = !isBatchRecEdit
            ? [recNutrient.recNutrientGuid]
            : this._addGuidsForBatchEdit(recNutrient, batchRecDetailsForEdit);

        if (!creditedGridCells.length) {
            this.props.fetchCreditedGridCells(recNutrientGuidList);
        }
        if (!availableCredits) {
            const applicableRecNutrientGuid =
                !isBatchRecEdit || batchRecDetailsForEdit.length > 1
                    ? recNutrient.recNutrientGuid
                    : recNutrientGuidList[0];
            this.props.fetchAvailableCredits(applicableRecNutrientGuid);
        }
        this.setState({
            hasFetchedRecNutrientData: true,
            isCreditsExpanded: recNutrient.credits.length > 0,
        });
    }

    #handleProductMix(nextProps: IProductBlendModalProps, productMixTypes): void {
        const { availableProducts, productMix, defaultCarrierGuid } = nextProps;
        if (
            (!blending.getIsProductMixInitialized(productMix) ||
                (!this.state.isModalInitialized && !this.state.isModalInitializing)) &&
            productMix.products.length > 0
        ) {
            if (
                productMix.productMixType.toLowerCase() !== ProductMixTypes.MANURE &&
                productMix.products[0].productGuid
            ) {
                const newProducts = productMix.products
                    .filter((product) => product.productGuid)
                    .map((product) =>
                        blending.initializeProduct(nextProps.productMix, product, nextProps)
                    );

                const isChemicalEditNoCarrier =
                    productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL &&
                    newProducts.length > 0 &&
                    !productMix.isCarrier &&
                    productMix.products.find((product) => product.isCarrier) == null;

                if (isChemicalEditNoCarrier) {
                    const carrierProduct = {
                        ...blending.initializeProduct(
                            productMix,
                            availableProducts.find(
                                (product) => product.productGuid === defaultCarrierGuid
                            ),
                            nextProps
                        ),
                        isCarrier: true,
                    };
                    newProducts.push(carrierProduct);
                }

                const mixUnits: any = blending.getProductMixUnits(
                    productMix,
                    newProducts,
                    nextProps
                );
                let effectiveTargetRate = +productMix.actualRate || +productMix.targetRate;

                if (
                    isChemicalEditNoCarrier &&
                    mixUnits.targetRateUnitGuid !== productMix.targetRateUnitGuid
                ) {
                    effectiveTargetRate =
                        effectiveTargetRate *
                        blending.getProductRateConversionFactor(
                            productMix.targetRateUnitGuid,
                            mixUnits.targetCostUnitGuid,
                            productMix.density,
                            effectiveTargetRate,
                            nextProps
                        );
                }

                const calculateTotalsProps =
                    !effectiveTargetRate || isNaN(effectiveTargetRate)
                        ? {}
                        : {
                              targetRate: effectiveTargetRate,
                          };

                const summaryTotals = this._calculateTotals(
                    newProducts,
                    mixUnits.targetRateUnitGuid,
                    blending.getEffectiveCostUnitGuid(productMix, newProducts),
                    calculateTotalsProps,
                    true,
                    false,
                    nextProps
                );

                const productMixType = !productMix.productMixType
                    ? productMix.products[0].productGuid
                        ? productMixTypes.find(
                              (type) => type.label.toLowerCase() === ProductMixTypes.FERTILIZER
                          )
                        : productMixTypes.find(
                              (type) => type.label.toLowerCase() === ProductMixTypes.MANURE
                          )
                    : productMixTypes.find((type) => type.value === productMix.productMixTypeGuid);

                const newProductMix = {
                    products: [...newProducts],
                    ...mixUnits,
                    ...summaryTotals,
                    productMixType: productMixType.label,
                    productMixTypeGuid: productMixType.value,
                    productBlendGuid: productMix.productBlendGuid,
                    isLockGuaranteedAnalysis: false,
                    hasSavedBlend: productMix.hasSavedBlend,
                };

                this.setState({ isModalInitializing: true }, () => {
                    this._updateProductMix(newProductMix);
                });
            } else if (
                this.state.selectedExistingBlendIdx === -1 &&
                productMix.products[0].customProductGuid !==
                    this.state.customProduct.customProductGuid
            ) {
                this._initializeCustomProduct(productMix);
            }
        } else if (productMix.products.length === 0) {
            this.setState({ isModalInitialized: true });
        }
    }

    #handleCustomProductSave(
        productMix: ProductMix,
        productBlendGuid: string,
        props: IProductBlendModalProps = this.props
    ): void {
        // inject the guid into any with NEW as the guid
        const newProductList = productMix.products.map((product) => {
            return {
                ...product,
                customProductGuid:
                    product.customProductGuid === NEW || !product.customProductGuid
                        ? productBlendGuid
                        : product.customProductGuid,
                rateUnit: blending.getRateUnit(product.rateUnitGuid, props)?.label,
                costUnit: blending.getPriceUnit(product.costUnitGuid, props)?.label,
            };
        });
        const newProductMix = {
            ...productMix,
            customProductGuid:
                productMix.customProductGuid === NEW || !productMix.customProductGuid
                    ? productBlendGuid
                    : productMix.customProductGuid,
            products: newProductList,
            hasSavedBlend: false,
        };

        this.props.clearProductBlendGuid();
        this._updateProductMix(newProductMix);

        this.setState({
            isSavingCustomProduct: false,
        });
    }

    #handleSave(productMix: ProductMix): void {
        const allGuidsSet = productMix.customProductGuid !== NEW && productMix.customProductGuid;
        if (allGuidsSet) {
            this.setState(
                {
                    isSavingModal: false,
                },
                () => {
                    this.props.onSave();
                }
            );
        }
    }

    _initializeCustomProduct(productMix: ProductMix): void {
        const { customProductList } = this.state;

        const initCustomProduct = productMix.products[0];
        let fullCustomProductIdx = -1;
        const fullCustomProduct = customProductList.find((cp, index) => {
            const isMatch = cp.customProductGuid === initCustomProduct.customProductGuid;
            if (isMatch) {
                fullCustomProductIdx = index;
            }
            return isMatch;
        });
        if (fullCustomProduct) {
            this.setState(
                {
                    selectedExistingBlendIdx: fullCustomProductIdx,
                    preserveRatesOnLoad: true,
                },
                () => {
                    this._loadBlend();
                }
            );
        }
    }

    _initializeCreditedRecNutrient(
        creditedRecNutrient: RecNutrient,
        recNutrient: IRecNutrient,
        nextProps: IProductBlendModalProps
    ): any {
        const { productMix } = nextProps;
        const isZeroedOut =
            +creditedRecNutrient.recNutrientProductMix.targetRate === 0 &&
            recNutrient.recNutrientProductMix.guaranteedAnalysis !== "0-0-0";
        const newProducts = creditedRecNutrient.recNutrientProductMix.products.map((product) => {
            if (product.productGuid) {
                const oldProduct = recNutrient.recNutrientProductMix.products.find(
                    (oProd) => oProd.productGuid === product.productGuid
                );
                const newProduct = blending.initializeProduct(
                    creditedRecNutrient.recNutrientProductMix,
                    {
                        ...product,
                        rate: 0,
                        cost: oldProduct?.cost,
                        limeEfficiency: oldProduct?.limeEfficiency,
                    },
                    {
                        ...nextProps,
                        recNutrient: {
                            ...recNutrient,
                            averageAdjustedRecNutrientResult: {
                                appliedArea:
                                    creditedRecNutrient.averageAdjustedRecNutrientResult
                                        .appliedArea,
                            },
                        },
                    }
                );
                return {
                    ...newProduct,
                    percentOfMix: isZeroedOut ? oldProduct.percentOfMix : newProduct.percentOfMix,
                };
            } else if (product.customProductGuid) {
                const oldProduct = recNutrient.recNutrientProductMix.products.find(
                    (oProd) => oProd.customProductGuid === product.customProductGuid
                );
                return {
                    ...oldProduct,
                    rate: creditedRecNutrient.recNutrientProductMix.targetRate,
                    cost: oldProduct.cost,
                    ...blending.calculate(
                        productMix,
                        {
                            ...oldProduct,
                            rate: creditedRecNutrient.recNutrientProductMix.targetRate,
                        },
                        {},
                        0,
                        this.props
                    ),
                };
            }

            return product;
        });

        const mixUnits: any = blending.getProductMixUnits(
            creditedRecNutrient.recNutrientProductMix,
            newProducts,
            nextProps
        );
        const summaryData = this._calculateTotals(
            newProducts,
            mixUnits.targetRateUnitGuid,
            blending.getEffectiveCostUnitGuid(
                creditedRecNutrient.recNutrientProductMix,
                newProducts
            ),
            {}
        );
        const newRecNutrient = {
            ...recNutrient,
            ...creditedRecNutrient,
            credits: [
                ...creditedRecNutrient.credits.map((credit) => {
                    return {
                        ...credit,
                        isCredited: true,
                        isOriginalCredit: true,
                        flatRate: credit.flatRate ? Number(credit.flatRate) : null,
                    };
                }),
            ],
            recNutrientProductMix: {
                ...recNutrient.recNutrientProductMix,
                ...creditedRecNutrient.recNutrientProductMix,
                products: [...newProducts],
                ...mixUnits,
                ...summaryData,
            },
            recNutrientParameters: {
                ...recNutrient.recNutrientParameters,
                recNutrientGuid: creditedRecNutrient.recNutrientParameters.recNutrientGuid,
                recNutrientParametersGuid:
                    creditedRecNutrient.recNutrientParameters.recNutrientParametersGuid,
                minimumRate: creditedRecNutrient.averageCreditRecNutrientResult.minRate,
                maximumRate: creditedRecNutrient.averageCreditRecNutrientResult.maxRate,
                percentAdjustment: "100",
                switchRate: null,
            },
            averageAdjustedRecNutrientResult: {
                ...creditedRecNutrient.averageAdjustedRecNutrientResult,
                minRate: creditedRecNutrient.averageCreditRecNutrientResult.minRate,
                maxRate: creditedRecNutrient.averageCreditRecNutrientResult.maxRate,
            },
        };

        if (isZeroedOut) {
            newRecNutrient.recNutrientProductMix.nutrients =
                creditedRecNutrient.recNutrientProductMix.nutrients;
            newRecNutrient.recNutrientProductMix.density =
                creditedRecNutrient.recNutrientProductMix.density;
            newRecNutrient.recNutrientProductMix.guaranteedAnalysis =
                creditedRecNutrient.recNutrientProductMix.guaranteedAnalysis;
            newRecNutrient.recNutrientProductMix.name =
                creditedRecNutrient.recNutrientProductMix.name;
            newRecNutrient.recNutrientProductMix.products = newProducts;
        }

        return newRecNutrient;
    }

    _addCustomProduct(): void {
        const { customProducts, productBlendPicklists } = this.props;
        const { dryRateUnits, liquidRateUnits } = productBlendPicklists;
        const { customProduct } = this.state;
        const rateUnitList =
            customProduct.physicalState.toLowerCase() === PhysicalStates.DRY
                ? dryRateUnits
                : liquidRateUnits;
        const physicalStatesMatch = rateUnitList.some(
            (unit) =>
                unit.value === customProduct.defaultRateUnitGuid ||
                unit.value === customProduct.rateUnitGuid
        );

        // DBI 6179.  I'm sorry ... FOR EVERYTHING
        const backupDefaultRateUnit =
            customProduct.physicalState.toLowerCase() === PhysicalStates.DRY
                ? dryRateUnits.find((unit) => unit.label === LBPERAC)
                : liquidRateUnits.find((unit) => unit.label === GALPERAC);

        const newCustomProduct = {
            ...customProduct,
            rateUnitGuid: physicalStatesMatch
                ? customProduct.rateUnitGuid || customProduct.defaultRateUnitGuid
                : backupDefaultRateUnit.value,
            defaultRateUnitGuid: physicalStatesMatch
                ? customProduct.rateUnitGuid || customProduct.defaultRateUnitGuid
                : backupDefaultRateUnit.value,
        };

        const existingProduct = customProducts.find((cp) => {
            return (
                cp.nutrients.every((en) => {
                    return customProduct.nutrients.some(
                        (n) => n.nutrientGuid === en.nutrientGuid && +n.percent === +en.percent
                    );
                }) &&
                cp.physicalStateGuid === customProduct.physicalStateGuid &&
                cp.density === customProduct.density &&
                cp.customProductTypeGuid === customProduct.customProductTypeGuid
            );
        });

        const customProductGuid = existingProduct?.customProductGuid || NEW;

        this.setState(
            {
                customProductList: existingProduct
                    ? [...customProducts]
                    : [{ ...newCustomProduct, customProductGuid }, ...customProducts],
            },
            () => {
                this._updateSelectedCustomProduct(customProductGuid);
            }
        );
    }

    _addGuidsForBatchEdit(
        recNutrient: RecNutrient,
        batchRecDetailsForEdit: RecDetails[]
    ): string[] {
        if (!batchRecDetailsForEdit) {
            return [recNutrient.recNutrientGuid];
        }
        const guidList = [];
        for (const recDetails of batchRecDetailsForEdit) {
            for (const recArea of recDetails.recAreaList) {
                for (const rec of recArea.recs) {
                    for (const recNutr of rec.recNutrientList) {
                        if (
                            recNutr.nutrientGuid === recNutrient.nutrientGuid &&
                            recNutr.equationSuccess
                        ) {
                            guidList.push(recNutr.recNutrientGuid);
                        }
                    }
                }
            }
        }
        return guidList;
    }

    _addProduct(): void {
        const {
            availableProducts,
            productMix,
            defaultCarrierGuid,
            defaultChemicalTargetRate,
            isEquation,
            isLime,
            productBlendPicklists,
        } = this.props;
        const { dryRateUnits, liquidRateUnits, physicalState } = productBlendPicklists;
        const { selectedProductGuid, isBlendingActive } = this.state;

        if (
            !selectedProductGuid ||
            productMix.isLockGuaranteedAnalysis ||
            productMix.isLockProductRatios
        ) {
            return;
        }

        const mixType = productMix.productMixType.toLowerCase();
        const fullProductInfo = availableProducts.find(
            (product) => product.productGuid === selectedProductGuid
        );
        const isServiceProduct = blending.getIsServiceProduct(
            availableProducts,
            selectedProductGuid
        );
        const physicalStateItem = physicalState.find(
            (phy) => fullProductInfo.physicalStateGuid === phy.value
        );

        let defaultRateUnitGuid = isServiceProduct
            ? null
            : mixType !== ProductMixTypes.CHEMICAL
            ? fullProductInfo.defaultRateUnitGuid
            : blending.getPriceUnitPhysicalState(
                  fullProductInfo.defaultPriceUnitGuid,
                  this.props
              ) === PhysicalStates.DRY
            ? dryRateUnits.find((unit) => unit.label.toLowerCase() === OZPERAC).value
            : liquidRateUnits.find((unit) => unit.label.toLowerCase() === FLOZPERAC).value;

        if (!defaultRateUnitGuid && physicalStateItem) {
            switch (physicalStateItem.label.toLowerCase()) {
                case PhysicalStates.DRY:
                    defaultRateUnitGuid = dryRateUnits.find(
                        (unit) => unit.label.toLowerCase() === LBPERAC
                    ).value;
                    break;
                case PhysicalStates.LIQUID:
                    defaultRateUnitGuid = liquidRateUnits.find(
                        (unit) => unit.label.toLowerCase() === GALPERAC
                    ).value;
                    break;
            }
        }

        const rateUnit = isServiceProduct
            ? null
            : blending.getRateUnit(defaultRateUnitGuid, this.props);
        const costUnit = isServiceProduct
            ? blending.getPriceUnitByName("ac", this.props, "Service")
            : blending.getPriceUnit(fullProductInfo.defaultPriceUnitGuid, this.props);
        const emptyProductModel = new blendingModels.ProductMixProduct();
        const targetRateUnitGuid =
            productMix.products.length === 0 ? defaultRateUnitGuid : productMix.targetRateUnitGuid;
        const { density, specificGravity } = isServiceProduct
            ? { density: null, specificGravity: null }
            : blending.getProductDensityValues(
                  fullProductInfo,
                  emptyProductModel,
                  targetRateUnitGuid,
                  rateUnit.value,
                  this.props
              );
        const densityValidationCode = blending.validateDensityValues(density, specificGravity);
        if (densityValidationCode !== 0) {
            this.setState({
                densityValidationCode,
            });
            return;
        }

        const newMixLimeEfficiency = !isEquation || !isLime ? {} : { limeEfficiency: 90 };

        const newProduct = {
            ...emptyProductModel,
            costUnit: costUnit.label || "",
            costUnitGuid: isServiceProduct ? costUnit.value : fullProductInfo.defaultPriceUnitGuid,
            density,
            densityUnitGuid: fullProductInfo.densityUnitGuid,
            isCarrier: false,
            nutrientList: [...fullProductInfo.nutrientList],
            physicalStateGuid: fullProductInfo.physicalStateGuid,
            physicalState: fullProductInfo.physicalState,
            productGuid: fullProductInfo.productGuid,
            productName: fullProductInfo.name,
            productParentType: fullProductInfo?.productParentType,
            rateUnit: rateUnit?.label || "",
            rateUnitGuid: isServiceProduct ? null : defaultRateUnitGuid,
            recProductMixGuid: productMix.recProductMixGuid,
            specificGravity,
            rate:
                productMix.isImport &&
                productMix.products.length === 0 &&
                productMix.productMixType.toLowerCase() !== ProductMixTypes.CHEMICAL &&
                !isBlendingActive
                    ? productMix.actualRate
                    : 0,
            ...newMixLimeEfficiency,
        };

        const product = !isServiceProduct
            ? newProduct
            : blending.initializeProduct(productMix, newProduct, this.props);

        const defaultCarrier =
            productMix.products.length === 0 &&
            productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL
                ? availableProducts.find((product) => product.productGuid === defaultCarrierGuid)
                : null;

        const newProducts = [
            ...productMix.products,
            {
                ...product,
                ...blending.calculate(productMix, product, {}, 0, this.props),
            },
        ];
        if (defaultCarrier) {
            const carrierRateUnit = blending.getRateUnit(
                defaultCarrier.defaultRateUnitGuid,
                this.props
            );
            const carrierCostUnit = blending.getPriceUnit(
                defaultCarrier.defaultPriceUnitGuid,
                this.props
            );

            const carrierProductDensity =
                defaultCarrier && defaultCarrier.density
                    ? defaultCarrier.density
                    : defaultCarrier.specificGravity
                    ? defaultCarrier.specificGravity * blending.DENSITY_WATER_LB_GAL
                    : blending.DENSITY_WATER_LB_GAL;
            const carrierSpecificGravity =
                defaultCarrier.specificGravity ||
                carrierProductDensity / blending.DENSITY_WATER_LB_GAL;

            const carrierProduct = {
                ...emptyProductModel,
                costUnit: carrierCostUnit.label || "",
                costUnitGuid: defaultCarrier.defaultPriceUnitGuid,
                density: carrierProductDensity,
                densityUnitGuid: defaultCarrier.densityUnitGuid,
                isCarrier: true,
                nutrientList: [...defaultCarrier.nutrientList],
                physicalStateGuid: defaultCarrier.physicalStateGuid,
                physicalState: defaultCarrier.physicalState,
                productGuid: defaultCarrier.productGuid,
                productName: defaultCarrier.name,
                rateUnit: carrierRateUnit.label || "",
                rateUnitGuid: defaultCarrier.defaultRateUnitGuid,
                recProductMixGuid: productMix.recProductMixGuid,
                specificGravity: carrierSpecificGravity,
            };
            newProducts.push({
                ...carrierProduct,
                ...blending.calculate(productMix, carrierProduct, {}, 0, this.props),
            });
        }
        const mixUnits: any = blending.getProductMixUnits(productMix, newProducts, this.props);
        const isOnlyServiceProducts = newProducts.every((p) => p.productParentType === "Service");
        const summaryData = this._calculateTotals(
            newProducts,
            mixUnits.targetRateUnitGuid,
            isOnlyServiceProducts
                ? newProducts[0].costUnitGuid
                : blending.getEffectiveCostUnitGuid(productMix, newProducts),
            !defaultCarrier ? {} : { targetRate: defaultChemicalTargetRate }
        );

        this.setState({
            selectedProductGuid: null,
        });
        this._updateProductMix({
            ...mixUnits,
            products: newProducts,
            ...summaryData,
            isCarrier: defaultCarrier ? true : productMix.isCarrier,
            ...newMixLimeEfficiency,
        });
    }

    _addValidationErrors(errorCodeList: number[], clearExistingCodes = false): void {
        const pendingCodes = clearExistingCodes ? [] : [...this.state.pendingCodes];

        for (const errorCode of errorCodeList) {
            if (!pendingCodes.includes(errorCode)) {
                pendingCodes.push(errorCode);
            }
        }

        this.setState({
            pendingCodes,
        });
    }

    #adjustRecNutrientParameterMinMax(): void {
        const { isEquation, isEquationComplete, onUpdateRecNutrient, recNutrient } = this.props;

        if (!isEquation || !isEquationComplete) {
            return;
        }

        const { minimumRate, maximumRate } = recNutrient.recNutrientParameters;
        const newRecNutrientParameters = blending.initializeRecNutrientParameters(
            recNutrient.averageAdjustedRecNutrientResult,
            recNutrient.recNutrientParameters,
            recNutrient.credits?.length > 0
        );
        const hasChanged =
            newRecNutrientParameters.minimumRate !== minimumRate ||
            newRecNutrientParameters.maximumRate !== maximumRate;

        if (hasChanged) {
            const newRecNutrient = {
                ...recNutrient,
                recNutrientParameters: newRecNutrientParameters,
            };

            const recNutrientUpdate = { ...newRecNutrient };
            onUpdateRecNutrient(recNutrientUpdate, false);
        }
    }

    _adjustRecNutrient(
        newProps: Partial<IProductBlendModalProps>,
        productMix = this.props.productMix,
        recalculateSummary = true,
        props = this.props
    ): any {
        const { calculatedArea, recNutrient, isLime } = props;
        const { creditedGridCells } = recNutrient;
        const { isBlendingActive } = this.state;

        if (!creditedGridCells) {
            return {
                ...recNutrient,
                recNutrientProductMix: {
                    ...recNutrient.recNutrientProductMix,
                    ...productMix,
                },
            };
        }
        const rateUnitChanged =
            productMix.targetRateUnitGuid &&
            recNutrient.recNutrientProductMix.targetRateUnitGuid &&
            productMix.targetRateUnitGuid !== recNutrient.recNutrientProductMix.targetRateUnitGuid;

        const limeEfficiencyChanged = isLime && newProps.limeEfficiency != null ? true : false;

        if (
            !(
                limeEfficiencyChanged ||
                rateUnitChanged ||
                blending.getIsChanged(newProps, recNutrient.recNutrientParameters)
            ) &&
            (!blending.isZeroRec(recNutrient) ||
                !blending.getIsChanged(productMix, this.props.productMix))
        ) {
            return recNutrient;
        }

        const rateUnit = blending.getRateUnit(productMix.targetRateUnitGuid, props);
        const oldTargetRateToNewTargetRateConversionFactor =
            !rateUnitChanged || !rateUnit
                ? 1
                : blending.getProductRateConversionFactor(
                      recNutrient.recNutrientProductMix.targetRateUnitGuid,
                      blending.getPriceUnitFromRateUnit(rateUnit, this.props).value,
                      recNutrient.recNutrientProductMix.density,
                      productMix.targetRate,
                      props
                  );
        const fieldRate =
            recNutrient.averageCreditRecNutrientResult.productRate *
            oldTargetRateToNewTargetRateConversionFactor;
        const appliedArea = recNutrient.averageAdjustedRecNutrientResult.appliedArea;
        const isMinLock = recNutrient.recNutrientParameters.minimumLock;
        const isMaxLock = recNutrient.recNutrientParameters.maximumLock;

        const isMinMax =
            (newProps.minimumRate != null &&
                newProps.minimumRate !== recNutrient.recNutrientParameters.minimumRate) ||
            (newProps.maximumRate != null &&
                newProps.maximumRate !== recNutrient.recNutrientParameters.maximumRate) ||
            (newProps.switchRate != null &&
                newProps.switchRate !== recNutrient.recNutrientParameters.switchRate) ||
            (newProps.minimumExcludeZeros != null &&
                newProps.minimumExcludeZeros !==
                    recNutrient.recNutrientParameters.minimumExcludeZeros) ||
            (newProps.minimumIncludeZeros != null &&
                newProps.minimumIncludeZeros !==
                    recNutrient.recNutrientParameters.minimumIncludeZeros) ||
            (newProps.zerosBelowMinimum != null &&
                newProps.zerosBelowMinimum !==
                    recNutrient.recNutrientParameters.zerosBelowMinimum) ||
            (newProps.zerosBelowSwitchRate != null &&
                newProps.zerosBelowSwitchRate !==
                    recNutrient.recNutrientParameters.zerosBelowSwitchRate);

        if (limeEfficiencyChanged) {
            const limeEfficiencyChangeRatio = productMix.limeEfficiency / newProps.limeEfficiency;
            const zerosBelowSwitchRate = recNutrient.recNutrientParameters.zerosBelowSwitchRate;

            let actualMinimum = null;
            let actualMaximum = 0;
            let actualSwitchRate = null;

            const newCreditedGridCells = creditedGridCells.map((gridCell) => {
                const originalProductRate =
                    Number(gridCell.originalProductRate) * limeEfficiencyChangeRatio;
                const creditedProductRate =
                    Number(gridCell.creditedProductRate) * limeEfficiencyChangeRatio;
                const adjustedProductRate = originalProductRate;

                actualMinimum =
                    actualMinimum == null
                        ? adjustedProductRate
                        : Math.min(actualMinimum, adjustedProductRate);
                actualMaximum = Math.max(actualMaximum, adjustedProductRate);
                actualSwitchRate =
                    zerosBelowSwitchRate &&
                    recNutrient.recNutrientParameters.switchRate &&
                    recNutrient.recNutrientParameters.minimumRate
                        ? recNutrient.recNutrientParameters.switchRate *
                          (actualMinimum / recNutrient.recNutrientParameters.minimumRate)
                        : recNutrient.recNutrientParameters.switchRate;
                return {
                    ...gridCell,
                    productRate: adjustedProductRate,
                    originalProductRate,
                    creditedProductRate,
                };
            });

            const newTargetRate =
                recNutrient.averageCreditRecNutrientResult.productRate * limeEfficiencyChangeRatio;

            const newProductMix = {
                ...productMix,
                targetRate: newTargetRate,
                products: productMix.products.map((product) => {
                    const newProduct = {
                        ...product,
                        rate: newTargetRate,
                        cost: 0,
                    };
                    return {
                        ...newProduct,
                        ...blending.calculate(productMix, newProduct, {}, newTargetRate, props),
                    };
                }),
            };

            const newRecNutrient = {
                ...recNutrient,
                recNutrientProductMix: {
                    ...newProductMix,
                },
                recNutrientParameters: {
                    ...recNutrient.recNutrientParameters,
                    minimumRate: blending.roundValue(actualMinimum),
                    maximumRate: blending.roundValue(actualMaximum),
                    switchRate: blending.roundValue(actualSwitchRate),
                    percentAdjustment: 100,
                },
                creditedGridCells: newCreditedGridCells,
                averageAdjustedRecNutrientResult: {
                    ...recNutrient.averageAdjustedRecNutrientResult,
                    productRate:
                        recNutrient.averageCreditRecNutrientResult.productRate *
                        limeEfficiencyChangeRatio, // not a typo, we're resetting to 100%, don't care about the previous adjustments
                    appliedProductRate:
                        recNutrient.averageCreditRecNutrientResult.appliedProductRate *
                        limeEfficiencyChangeRatio,
                    minRate: actualMinimum,
                    maxRate: actualMaximum,
                },
                averageCreditRecNutrientResult: {
                    ...recNutrient.averageCreditRecNutrientResult,
                    productRate:
                        recNutrient.averageCreditRecNutrientResult.productRate *
                        limeEfficiencyChangeRatio,
                    appliedProductRate:
                        recNutrient.averageCreditRecNutrientResult.appliedProductRate *
                        limeEfficiencyChangeRatio,
                    minRate: actualMinimum,
                    maxRate: actualMaximum,
                },
                averageRecNutrientResult: {
                    ...recNutrient.averageRecNutrientResult,
                    productRate:
                        recNutrient.averageRecNutrientResult.productRate *
                        limeEfficiencyChangeRatio,
                    appliedProductRate:
                        recNutrient.averageRecNutrientResult.appliedProductRate *
                        limeEfficiencyChangeRatio,
                    minRate: actualMinimum,
                    maxRate: actualMaximum,
                },
            };
            return {
                ...newRecNutrient,
            };
        } else if (rateUnitChanged) {
            const minimumExcludeZeros = recNutrient.recNutrientParameters.minimumExcludeZeros;
            const minimumIncludeZeros = recNutrient.recNutrientParameters.minimumIncludeZeros;
            const zerosBelowMinimum = recNutrient.recNutrientParameters.zerosBelowMinimum;
            const zerosBelowSwitchRate = recNutrient.recNutrientParameters.zerosBelowSwitchRate;
            const existingAdjustPercent = recNutrient.recNutrientParameters.percentAdjustment / 100;

            const applicableMinimum =
                recNutrient.recNutrientParameters.minimumRate *
                oldTargetRateToNewTargetRateConversionFactor;
            const applicableMaximum =
                recNutrient.recNutrientParameters.maximumRate *
                oldTargetRateToNewTargetRateConversionFactor;
            const applicableSwitchRate =
                zerosBelowSwitchRate && recNutrient.recNutrientParameters.switchRate
                    ? recNutrient.recNutrientParameters.switchRate *
                      oldTargetRateToNewTargetRateConversionFactor
                    : null;

            const newCreditedGridCells = creditedGridCells.map((gridCell) => {
                const originalProductRate =
                    Number(gridCell.originalProductRate) *
                    oldTargetRateToNewTargetRateConversionFactor;
                const creditedProductRate =
                    Number(gridCell.creditedProductRate) *
                    oldTargetRateToNewTargetRateConversionFactor;
                const adjustedProductRate = originalProductRate * existingAdjustPercent;
                const productRate =
                    blending.isZeroRec(recNutrient) && !minimumIncludeZeros
                        ? 0
                        : adjustedProductRate < applicableMinimum
                        ? zerosBelowMinimum
                            ? 0
                            : minimumIncludeZeros ||
                              (minimumExcludeZeros && adjustedProductRate > 0)
                            ? applicableMinimum
                            : zerosBelowSwitchRate
                            ? adjustedProductRate >= Number(applicableSwitchRate)
                                ? applicableMinimum
                                : 0
                            : adjustedProductRate
                        : adjustedProductRate > Number(applicableMaximum)
                        ? applicableMaximum
                        : adjustedProductRate;

                return {
                    ...gridCell,
                    productRate,
                    originalProductRate,
                    creditedProductRate,
                };
            });

            const newRecNutrient = {
                ...recNutrient,
                recNutrientProductMix: {
                    ...productMix,
                },
                recNutrientParameters: {
                    ...recNutrient.recNutrientParameters,
                    minimumRate: applicableMinimum,
                    maximumRate: applicableMaximum,
                    rateUnitAdjustment: true,
                    switchRate: applicableSwitchRate,
                },
                creditedGridCells: newCreditedGridCells,
                averageAdjustedRecNutrientResult: {
                    ...recNutrient.averageAdjustedRecNutrientResult,
                    productRate:
                        recNutrient.averageAdjustedRecNutrientResult.productRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    appliedProductRate:
                        recNutrient.averageAdjustedRecNutrientResult.appliedProductRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    minRate:
                        recNutrient.averageAdjustedRecNutrientResult.minRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    maxRate:
                        recNutrient.averageAdjustedRecNutrientResult.maxRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                },
                averageCreditRecNutrientResult: {
                    ...recNutrient.averageCreditRecNutrientResult,
                    productRate:
                        recNutrient.averageCreditRecNutrientResult.productRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    appliedProductRate:
                        recNutrient.averageCreditRecNutrientResult.appliedProductRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    minRate:
                        recNutrient.averageCreditRecNutrientResult.minRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    maxRate:
                        recNutrient.averageCreditRecNutrientResult.maxRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                },
                averageRecNutrientResult: {
                    ...recNutrient.averageRecNutrientResult,
                    productRate:
                        recNutrient.averageRecNutrientResult.productRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    appliedProductRate:
                        recNutrient.averageRecNutrientResult.appliedProductRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    minRate:
                        recNutrient.averageRecNutrientResult.minRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                    maxRate:
                        recNutrient.averageRecNutrientResult.maxRate *
                        oldTargetRateToNewTargetRateConversionFactor,
                },
                unitGuid: productMix.targetRateUnitGuid,
            };
            return {
                ...newRecNutrient,
            };
        } else if (isMinMax) {
            const applicableMinimum =
                newProps.minimumRate != null
                    ? newProps.minimumRate
                    : recNutrient.recNutrientParameters.minimumRate !== ""
                    ? recNutrient.recNutrientParameters.minimumRate
                    : recNutrient.averageRecNutrientResult.minRate;
            const applicableMaximum =
                newProps.maximumRate != null
                    ? newProps.maximumRate
                    : recNutrient.recNutrientParameters.maximumRate !== ""
                    ? recNutrient.recNutrientParameters.maximumRate
                    : recNutrient.averageRecNutrientResult.maxRate;

            let appliedProduct = 0;
            let appliedNutrient = 0;
            let appliedCellCount = 0;
            let appliedCellArea = 0;
            let noEffect = true;
            const zerosBelowMinimum =
                newProps.zerosBelowMinimum != null
                    ? newProps.zerosBelowMinimum
                    : recNutrient.recNutrientParameters.zerosBelowMinimum;
            const minimumIncludeZeros =
                newProps.minimumIncludeZeros != null
                    ? newProps.minimumIncludeZeros
                    : recNutrient.recNutrientParameters.minimumIncludeZeros;
            const minimumExcludeZeros =
                newProps.minimumExcludeZeros != null
                    ? newProps.minimumExcludeZeros
                    : recNutrient.recNutrientParameters.minimumExcludeZeros;
            const zerosBelowSwitchRate =
                newProps.zerosBelowSwitchRate != null
                    ? newProps.zerosBelowSwitchRate
                    : recNutrient.recNutrientParameters.zerosBelowSwitchRate;
            const effectiveFieldRate = fieldRate;
            const applicableSwitchRate =
                newProps.switchRate != null
                    ? newProps.switchRate
                    : zerosBelowSwitchRate
                    ? recNutrient.recNutrientParameters.switchRate
                    : null;
            // VALIDATE
            const validationCodes = blending.validateAdjustment(
                newProps,
                applicableMinimum,
                isMinLock,
                applicableMaximum,
                isMaxLock,
                calculatedArea,
                appliedArea,
                fieldRate,
                applicableSwitchRate
            );
            if (validationCodes.length > 0) {
                this._addValidationErrors(validationCodes);
                return null;
            } else {
                this.setState({
                    pendingCodes: [],
                });
            }
            const gridCellInTheField = creditedGridCells.find(
                (gridCell) =>
                    gridCell.creditedProductRate !== 0 &&
                    gridCell.productRate !== 0 &&
                    gridCell.productRate > recNutrient.recNutrientParameters.minimumRate &&
                    gridCell.productRate < recNutrient.recNutrientParameters.maximumRate
            );
            const existingAdjustPercent = !gridCellInTheField
                ? 1
                : gridCellInTheField.productRate / gridCellInTheField.creditedProductRate;
            const switchRate = !zerosBelowSwitchRate
                ? null
                : applicableSwitchRate != null &&
                  applicableMinimum != null &&
                  recNutrient.recNutrientParameters.minimumRate
                ? blending.roundValue(
                      applicableSwitchRate *
                          (applicableMinimum / recNutrient.recNutrientParameters.minimumRate),
                      2
                  )
                : applicableSwitchRate;
            const newCreditedGridCells = creditedGridCells.map((gridCell) => {
                const creditedProductRate = Number(gridCell.creditedProductRate);
                const adjustedProductRate = creditedProductRate * existingAdjustPercent;
                const productRate =
                    blending.isZeroRec(recNutrient) && !minimumIncludeZeros
                        ? 0
                        : adjustedProductRate < applicableMinimum
                        ? zerosBelowMinimum
                            ? 0
                            : minimumIncludeZeros ||
                              (minimumExcludeZeros && adjustedProductRate > 0)
                            ? applicableMinimum
                            : zerosBelowSwitchRate && Number(switchRate) > 0
                            ? adjustedProductRate >= Number(switchRate)
                                ? applicableMinimum
                                : 0
                            : adjustedProductRate
                        : adjustedProductRate > Number(applicableMaximum)
                        ? applicableMaximum
                        : adjustedProductRate;
                if (productRate !== Number(gridCell.productRate)) {
                    noEffect = false;
                }

                appliedCellCount += Number(productRate) > 0 || minimumIncludeZeros ? 1 : 0;
                appliedCellArea +=
                    Number(productRate) > 0 || minimumIncludeZeros ? Number(gridCell.cellArea) : 0;
                appliedProduct += Number(productRate) * Number(gridCell.cellArea);
                appliedNutrient +=
                    Number(productRate) > 0 || minimumIncludeZeros
                        ? Number(gridCell.nutrientRate) * Number(gridCell.cellArea)
                        : 0;
                return {
                    ...gridCell,
                    productRate,
                };
            });
            const newTargetRate =
                (blending.isZeroRec(recNutrient) && !minimumIncludeZeros) || appliedCellCount === 0
                    ? 0
                    : noEffect && (newProps.minimumRate != null || newProps.maximumRate != null)
                    ? productMix.targetRate
                    : blending.roundValue(appliedProduct / calculatedArea);
            const percentAdjustment =
                newTargetRate === 0 &&
                (blending.isZeroRec(recNutrient) || productMix.guaranteedAnalysis !== "0-0-0")
                    ? 100
                    : newProps.percentAdjustment
                    ? newProps.percentAdjustment
                    : blending.roundValue(
                          (Number(newTargetRate) /
                              (blending.isZeroRec(recNutrient)
                                  ? Number(newTargetRate)
                                  : effectiveFieldRate)) *
                              100
                      );
            const replaceMixPropsForZeroRec =
                !blending.isZeroRec(recNutrient) || (minimumIncludeZeros && newTargetRate !== 0)
                    ? {}
                    : {
                          targetRate: 0,
                          costPerAcre: 0,
                          totalProduct: 0,
                          totalCost: 0,
                          products: productMix.products.map((product) => ({
                              ...product,
                              rate: 0,
                              costPerAcre: 0,
                              totalProduct: 0,
                              totalCost: 0,
                          })),
                      };
            const replaceParameterPropsForZeroRec =
                !blending.isZeroRec(recNutrient) || (minimumIncludeZeros && newTargetRate !== 0)
                    ? {}
                    : {
                          minimumRate: 0,
                          maximumRate: 0,
                          switchRate: 0,
                      };
            const newRecNutrientParameters = {
                ...recNutrient.recNutrientParameters,
                ...newProps,
                percentAdjustment,
                switchRate,
                ...replaceParameterPropsForZeroRec,
            };

            const newRecNutrient = {
                ...recNutrient,
                recNutrientParameters: newRecNutrientParameters,
                recNutrientProductMix: {
                    ...productMix,
                },
                creditedGridCells: newCreditedGridCells,
                averageAdjustedRecNutrientResult: {
                    ...recNutrient.averageAdjustedRecNutrientResult,
                    appliedArea: blending.roundValue(appliedCellArea, 2),
                    appliedNutrientRate: blending.roundValue(appliedNutrient / appliedCellArea, 2),
                    appliedProductRate: blending.roundValue(appliedProduct / appliedCellArea, 2),
                    productRate: blending.roundValue(fieldRate * (percentAdjustment / 100)),
                    percentAdjustment,
                    minRate: newRecNutrientParameters.minimumRate,
                    maxRate: newRecNutrientParameters.maximumRate,
                    switchRate,
                },
            };
            return {
                ...newRecNutrient,
                recNutrientProductMix: {
                    ...newRecNutrient.recNutrientProductMix,
                    ...this._calculateTotals(
                        productMix.products,
                        productMix.targetRateUnitGuid,
                        blending.getEffectiveCostUnitGuid(productMix, productMix.products),
                        { targetRate: +newTargetRate },
                        !isBlendingActive,
                        false,
                        {
                            ...this.props,
                            recNutrient: newRecNutrient,
                        }
                    ),
                    ...replaceMixPropsForZeroRec,
                },
            };
        } else if (newProps.percentAdjustment) {
            const minimumExcludeZeros = recNutrient.recNutrientParameters.minimumExcludeZeros;
            const minimumIncludeZeros = recNutrient.recNutrientParameters.minimumIncludeZeros;
            const zerosBelowMinimum = recNutrient.recNutrientParameters.zerosBelowMinimum;
            const zerosBelowSwitchRate = recNutrient.recNutrientParameters.zerosBelowSwitchRate;
            const effectiveFieldRate = fieldRate;

            const initialValues = blending.getInitialPercentAdjustmentValues(
                newProps,
                recNutrient,
                productMix,
                effectiveFieldRate,
                recalculateSummary,
                this.props.productMix.targetRate,
                minimumExcludeZeros,
                isMinLock,
                isMaxLock
            );
            const {
                hasChanged,
                retainSummary,
                newTargetRate,
                applicableMinimum,
                applicableMaximum,
                applicableSwitchRate,
            } = initialValues;
            let { newMinimum, newMaximum } = initialValues;
            const validationCodes = blending.validateAdjustment(
                { targetRate: newTargetRate, ...newProps },
                applicableMinimum,
                isMinLock,
                applicableMaximum,
                isMaxLock,
                calculatedArea,
                appliedArea,
                fieldRate,
                applicableSwitchRate
            );
            if (validationCodes.length > 0) {
                this._addValidationErrors(validationCodes, true);
                return null;
            } else {
                this.setState({
                    pendingCodes: [],
                });
            }

            let appliedProduct = 0;
            let appliedCellArea = 0;

            let nonMinMaxRuleSumRate = 0;

            const shouldDecrease =
                recNutrient.recNutrientParameters.percentAdjustment - newProps.percentAdjustment >
                0;

            const firstNewCreditedGridCells = creditedGridCells.map((gridCell) => {
                const creditedProductRate = Number(gridCell.creditedProductRate);
                let adjustedProductRate =
                    !blending.isZeroRec(recNutrient) || minimumExcludeZeros
                        ? (creditedProductRate * newProps.percentAdjustment) / 100
                        : productMix.targetRate;
                let productRate = adjustedProductRate;

                if (hasChanged) {
                    if (!isMaxLock) {
                        newMaximum = Math.max(Number(newMaximum), Number(adjustedProductRate));
                        if (isMinLock) {
                            newMaximum = Math.max(applicableMinimum, newMaximum);
                        }
                    } else {
                        productRate = Math.min(Number(adjustedProductRate), applicableMaximum);
                        adjustedProductRate = productRate;
                    }

                    if (!isMinLock) {
                        if (+adjustedProductRate > 0 || minimumIncludeZeros) {
                            newMinimum =
                                newMinimum == null
                                    ? Number(adjustedProductRate)
                                    : Math.min(Number(newMinimum), Number(adjustedProductRate));
                        }
                        if (isMaxLock) {
                            newMinimum = Math.min(Number(newMinimum), applicableMaximum);
                        }
                    } else {
                        productRate =
                            +adjustedProductRate < +applicableMinimum
                                ? zerosBelowMinimum ||
                                  (zerosBelowSwitchRate &&
                                      +adjustedProductRate < +applicableSwitchRate)
                                    ? 0
                                    : minimumIncludeZeros ||
                                      (minimumExcludeZeros && +adjustedProductRate > 0) ||
                                      (zerosBelowSwitchRate &&
                                          +adjustedProductRate >= +applicableSwitchRate)
                                    ? applicableMinimum
                                    : adjustedProductRate
                                : adjustedProductRate;
                    }

                    if (
                        (!isMinLock || productRate !== newMinimum || !shouldDecrease) &&
                        (!isMaxLock || productRate !== newMaximum || shouldDecrease)
                    ) {
                        nonMinMaxRuleSumRate += Number(productRate) * Number(gridCell.cellArea);
                    }
                }

                appliedCellArea +=
                    Number(productRate) > 0 || minimumIncludeZeros ? Number(gridCell.cellArea) : 0;
                appliedProduct += Number(productRate) * Number(gridCell.cellArea);
                return {
                    ...gridCell,
                    productRate,
                };
            });

            // Rectify the adjustments (if necessary)
            let gridCalcResult = {
                gridCells: firstNewCreditedGridCells,
                minimumRate: newMinimum,
                maximumRate: newMaximum,
                switchRate:
                    applicableSwitchRate && recNutrient.recNutrientParameters.minimumRate
                        ? Number(applicableSwitchRate) *
                          (Number(newMinimum) / recNutrient.recNutrientParameters.minimumRate)
                        : null,
            };

            if (!blending.isZeroRec(recNutrient) && (isMinLock || isMaxLock)) {
                gridCalcResult = blending.rectifyAdjustment(
                    gridCalcResult,
                    appliedProduct / calculatedArea,
                    calculatedArea,
                    nonMinMaxRuleSumRate,
                    effectiveFieldRate,
                    minimumIncludeZeros,
                    blending.roundValue(newProps.percentAdjustment, 3),
                    isMinLock,
                    isMaxLock
                );

                appliedCellArea = 0;
                appliedProduct = 0;

                gridCalcResult.gridCells.forEach((gridCell) => {
                    appliedCellArea +=
                        Number(gridCell.productRate) > 0 || minimumIncludeZeros
                            ? Number(gridCell.cellArea)
                            : 0;
                    appliedProduct += Number(gridCell.productRate) * Number(gridCell.cellArea);
                });
            }

            const newRecNutrient = {
                ...recNutrient,
                recNutrientParameters: {
                    ...recNutrient.recNutrientParameters,
                    ...newProps,
                    percentAdjustment:
                        !blending.isZeroRec(recNutrient) && newProps.percentAdjustment != null
                            ? newProps.percentAdjustment
                            : blending.isZeroRec(recNutrient)
                            ? 100
                            : recNutrient.recNutrientParameters.percentAdjustment,
                    minimumRate: blending.roundValue(gridCalcResult.minimumRate),
                    maximumRate: blending.roundValue(gridCalcResult.maximumRate),
                    switchRate: blending.roundValue(gridCalcResult.switchRate),
                },
                recNutrientProductMix: {
                    ...productMix,
                },
                creditedGridCells: gridCalcResult.gridCells,
                averageAdjustedRecNutrientResult: {
                    ...recNutrient.averageAdjustedRecNutrientResult,
                    appliedArea: blending.roundValue(appliedCellArea, 2),
                    percentAdjustment:
                        !blending.isZeroRec(recNutrient) && newProps.percentAdjustment != null
                            ? newProps.percentAdjustment
                            : blending.isZeroRec(recNutrient)
                            ? 100
                            : recNutrient.recNutrientParameters.percentAdjustment,
                    appliedProductRate: blending.roundValue(appliedProduct / appliedCellArea, 2),
                    productRate: blending.roundValue(
                        fieldRate * (newProps.percentAdjustment / 100)
                    ),
                    minRate: blending.roundValue(gridCalcResult.minimumRate),
                    maxRate: blending.roundValue(gridCalcResult.maximumRate),
                    switchRate: blending.roundValue(gridCalcResult.switchRate),
                },
            };
            const summaryValues = retainSummary
                ? {}
                : this._calculateTotals(
                      productMix.products,
                      productMix.targetRateUnitGuid,
                      blending.getEffectiveCostUnitGuid(productMix, productMix.products),
                      { targetRate: +newTargetRate },
                      !isBlendingActive,
                      false,
                      {
                          ...this.props,
                          recNutrient: newRecNutrient,
                      }
                  );

            return {
                ...newRecNutrient,
                recNutrientProductMix: {
                    ...newRecNutrient.recNutrientProductMix,
                    ...summaryValues,
                },
            };
        } else {
            return recNutrient;
        }
    }

    _applyCredits(targetRecNutrient: RecNutrient): void {
        const { applyCredits, applyBatchCredits, isBatchRecEdit, batchRecDetailsForEdit } =
            this.props;
        logFirebaseEvent("equation_rec_apply_credits");
        const credits = targetRecNutrient.credits.filter((credit) => credit.isCredited) || [];
        this._updateRecNutrientParameter({
            minimumLock: false,
            maximumLock: false,
        });
        if (!isBatchRecEdit) {
            //TODO: Rather than tweaking the minimumRate and recNutrientProductMixGuid, find where these are not propertly set in the API upstream
            const recNutrientParameters = {
                ...targetRecNutrient.recNutrientParameters,
                minimumRate:
                    !targetRecNutrient.recNutrientParameters.minimumExcludeZeros ||
                    Number(targetRecNutrient.recNutrientParameters.minimumRate)
                        ? targetRecNutrient.recNutrientParameters.minimumRate
                        : targetRecNutrient.averageRecNutrientResult.minRate,
            };
            const applicableMixGuid = targetRecNutrient.recNutrientProductMix
                .recNutrientProductMixGuid
                ? targetRecNutrient.recNutrientProductMix.recNutrientProductMixGuid
                : targetRecNutrient.recNutrientProductMix.products[0].recNutrientProductMixGuid;
            const recNutrientProductMix = {
                ...targetRecNutrient.recNutrientProductMix,
                recNutrientProductMixGuid: applicableMixGuid,
            };
            const adjustedRecNutrient = {
                ...targetRecNutrient,
                recNutrientParameters,
                recNutrientProductMix,
            };
            applyCredits(adjustedRecNutrient.recNutrientGuid, credits);

            if (adjustedRecNutrient.recNutrientParameters.minimumIncludeZeros) {
                this.setState({
                    appliedAreaIncludeZeros: this._calculateAppliedAcres(adjustedRecNutrient),
                });
            }
            return;
        }
        const recNutrientGuidList = this._addGuidsForBatchEdit(
            targetRecNutrient,
            batchRecDetailsForEdit
        );
        applyBatchCredits(recNutrientGuidList, credits);
    }

    _calculateAppliedAcres(recNutrient: RecNutrient): number {
        let appliedAcres = 0;
        recNutrient.creditedGridCells.forEach((gridCell) => {
            if (gridCell.productRate > 0) {
                appliedAcres += gridCell.cellArea;
            }
        });
        return blending.roundValue(appliedAcres, 2);
    }

    _calculateAppliedAcresSum(currentRecNutrient: RecNutrient): number {
        const { batchRecDetailsForEdit } = this.props;
        let sumOfAppliedAcres = 0;
        for (const recDetail of batchRecDetailsForEdit) {
            for (const recArea of recDetail.recAreaList) {
                for (const rec of recArea.recs) {
                    const currentNutrient = rec.recNutrientList.find(
                        (n) => n.nutrientGuid === currentRecNutrient.nutrientGuid
                    );
                    if (currentNutrient && currentNutrient.averageRecNutrientResult) {
                        sumOfAppliedAcres +=
                            parseFloat(currentNutrient.averageRecNutrientResult.appliedArea) || 0;
                    }
                }
            }
        }
        return sumOfAppliedAcres;
    }

    _calculateTotals(
        origProductList: IProductMixProduct[],
        targetRateUnitGuid: string,
        targetCostUnitGuid: string,
        newProps: Partial<IProductBlendModalProps> | null,
        isLocked = true,
        retainSummaryData = false,
        props = this.props
    ): Record<string, any> {
        if (origProductList.length === 0) {
            return;
        }

        const { availableProducts, calculatedArea, productMix } = props;
        const { isBlendingActive } = this.state;
        const productList = [];

        let densityValidationCode = 0;
        const isOnlyServiceProducts = origProductList.every(
            (p: any) => p.productParentType === "Service"
        );

        if ((!targetRateUnitGuid || !targetCostUnitGuid) && !isOnlyServiceProducts) {
            return;
        }

        for (const prod of origProductList) {
            const fullProductInfo = availableProducts.find(
                (fullProduct) => fullProduct.productGuid === prod.productGuid
            );
            const isServiceProduct = blending.getIsServiceProduct(
                availableProducts,
                prod.productGuid
            );
            if (isServiceProduct) {
                productList.push({ ...prod });
                densityValidationCode = 0;
                continue;
            }

            const productDensityValues = blending.getProductDensityValues(
                fullProductInfo,
                prod,
                targetRateUnitGuid,
                prod.rateUnitGuid,
                props
            );
            const productDensityValidationCode = blending.validateDensityValues(
                productDensityValues.density,
                productDensityValues.specificGravity
            );
            if (
                productDensityValidationCode !== 0 &&
                props.productMix.productMixType.toLowerCase() !== ProductMixTypes.MANURE
            ) {
                densityValidationCode = productDensityValidationCode;
                continue;
            } else {
                productList.push({
                    ...prod,
                    ...productDensityValues,
                });
            }
        }

        if (densityValidationCode !== 0) {
            this.setState({ densityValidationCode });
        }

        const initialValues = {
            targetRate: 0,
            targetCost: 0,
            costPerAcre: 0,
            totalProduct: 0,
            totalCost: 0,
        };
        const isCarrier = newProps.isCarrier != null ? newProps.isCarrier : productMix.isCarrier;
        const targetRateUnit = isOnlyServiceProducts
            ? null
            : blending.getRateUnit(targetRateUnitGuid, props);
        const targetRateQuantityUnit = isOnlyServiceProducts
            ? null
            : blending.getPriceUnitFromRateUnit(targetRateUnit, props);
        const originalRate = productMix.targetRate;

        const lineRatio =
            !isLocked || (productMix.isImport && productMix.actualRate)
                ? 1
                : newProps.targetRate
                ? blending.getIsChanged(
                      { targetRate: newProps.targetRate },
                      { targetRate: +originalRate }
                  )
                    ? blending.getNumericValue(newProps.targetRate / +originalRate)
                    : 1
                : newProps.costPerAcre
                ? blending.getNumericValue(newProps.costPerAcre / productMix.costPerAcre)
                : newProps.totalProduct
                ? blending.getNumericValue(newProps.totalProduct / Number(productMix.totalProduct))
                : newProps.totalCost
                ? blending.getNumericValue(newProps.totalCost / Number(productMix.totalCost))
                : 1;

        const costRatio =
            isLocked && newProps.targetCost !== undefined
                ? blending.getNumericValue(newProps.targetCost / productMix.targetCost)
                : 1;

        const recalculateProducts = Boolean(
            newProps.targetRate ||
                newProps.targetCost !== undefined ||
                newProps.costPerAcre ||
                newProps.totalProduct ||
                newProps.totalCost
        );

        let newProductList = !recalculateProducts
            ? productList
            : productList.map((product) => {
                  const isServiceProduct = blending.getIsServiceProduct(
                      availableProducts,
                      product.productGuid
                  );

                  const isRateDependent =
                      product.rateUnit?.toLowerCase().split("/")[1] === PER100GAL;
                  const rate = isServiceProduct
                      ? product.rate || 1
                      : blending.roundValue(product.rate * (!isRateDependent ? lineRatio : 1));

                  const cost = isServiceProduct
                      ? product.cost
                      : blending.roundValue(product.cost * costRatio);

                  const lineRateToLineCostConversionFactor = isServiceProduct
                      ? 1
                      : blending.getProductRateConversionFactor(
                            product.rateUnitGuid,
                            product.costUnitGuid,
                            product.density,
                            newProps.targetRate || productMix.targetRate,
                            props
                        );

                  const isArea =
                      product.costUnitGuid ===
                      blending.getPriceUnitByName("ac", props, "Service")?.value;

                  const isEach =
                      product.costUnitGuid ===
                      blending.getPriceUnitByName("ea", props, "Service")?.value;

                  const serviceProductInitQuantity =
                      isServiceProduct &&
                      !(isArea || isEach) &&
                      isNaN(product.totalProduct) &&
                      newProps.targetRate;

                  const serviceProductConversionFactor =
                      isArea || isEach
                          ? 1
                          : serviceProductInitQuantity
                          ? blending.getServiceProductCostConversionFactor(
                                blending.getPriceUnitFromRateUnit(
                                    blending.getRateUnit(productMix.targetRateUnitGuid, props),
                                    props
                                )?.value,
                                product.costUnitGuid,
                                productMix.density,
                                props
                            )
                          : blending.getServiceProductCostConversionFactor(
                                blending.getEffectiveCostUnitGuid(productMix, productMix.products),
                                product.costUnitGuid,
                                productMix.density,
                                props
                            );

                  const totalProduct = isServiceProduct
                      ? product.totalProduct ||
                        (isArea
                            ? calculatedArea
                            : isEach
                            ? 1
                            : serviceProductInitQuantity
                            ? newProps.targetRate * calculatedArea * serviceProductConversionFactor
                            : productMix.totalProduct * serviceProductConversionFactor)
                      : blending.roundValue(
                            rate * lineRateToLineCostConversionFactor * calculatedArea
                        );

                  const totalCost =
                      newProps.targetCost === 0
                          ? 0
                          : isServiceProduct
                          ? totalProduct * cost
                          : blending.roundValue(
                                rate * cost * lineRateToLineCostConversionFactor * calculatedArea
                            );
                  const costPerAcre =
                      newProps.targetCost === 0
                          ? 0
                          : blending.roundValue(totalCost / calculatedArea);

                  const productSummaries =
                      !isRateDependent || totalProduct || isServiceProduct
                          ? {
                                costPerAcre,
                                totalProduct: blending.getIsChanged(
                                    { totalProduct },
                                    { totalProduct: product.totalProduct }
                                )
                                    ? totalProduct
                                    : product.totalProduct,
                                totalCost,
                            }
                          : {
                                ...blending.calculate(
                                    productMix,
                                    product,
                                    {},
                                    +newProps.targetRate || +productMix.targetRate,
                                    props
                                ),
                            };

                  return {
                      ...product,
                      rate,
                      cost,
                      ...productSummaries,
                  };
              });

        const summaryDataCalculations = newProductList.reduce((summaryData, product) => {
            if (product.isCarrier) {
                return summaryData;
            }

            const isServiceProduct = blending.getIsServiceProduct(
                availableProducts,
                product.productGuid
            );

            const lineRateToTargetRateConversionFactor = isServiceProduct
                ? 1
                : blending.getProductRateConversionFactor(
                      product.rateUnitGuid,
                      targetRateQuantityUnit.value,
                      product.density,
                      newProps.targetRate || productMix.targetRate,
                      props
                  );
            const lineRateToTargetCostConversionFactor = isServiceProduct
                ? 1
                : blending.getProductRateConversionFactor(
                      product.rateUnitGuid,
                      targetCostUnitGuid,
                      product.density,
                      newProps.targetRate || productMix.targetRate,
                      props
                  );

            const lineTargetRate = product.rate * lineRateToTargetRateConversionFactor;
            const lineTargetTotalProduct = isServiceProduct
                ? 0
                : product.rate * lineRateToTargetCostConversionFactor * calculatedArea;
            const totalProduct = summaryData.totalProduct + lineTargetTotalProduct;
            const totalCost = summaryData.totalCost + product.totalCost;
            const targetCost = blending.isNumeric(totalCost / totalProduct)
                ? totalCost / totalProduct
                : 0;

            return {
                targetRate: isServiceProduct
                    ? summaryData.targetRate
                    : summaryData.targetRate + lineTargetRate,
                targetCost: isServiceProduct ? summaryData.targetCost : targetCost,
                totalProduct: isServiceProduct ? summaryData.totalProduct : totalProduct,
                costPerAcre: totalCost / calculatedArea,
                totalCost: totalCost,
                noServiceTotalCost: isServiceProduct ? summaryData.totalCost : totalCost,
            };
        }, initialValues);

        if (productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL) {
            let carrierIndex = -1;
            const carrierProduct = productList.find((product, index) => {
                if (product.isCarrier) {
                    carrierIndex = index;
                }
                return product.isCarrier;
            });

            const newCarrierRate = !isCarrier
                ? 0
                : lineRatio === 1
                ? newProps.targetRate != null
                    ? blending.roundValue(+newProps.targetRate - summaryDataCalculations.targetRate)
                    : blending.roundValue(
                          Number(productMix.targetRate) - summaryDataCalculations.targetRate
                      )
                : blending.roundValue(carrierProduct.rate * lineRatio);
            const lineRateToCostConversion = blending.getProductRateConversionFactor(
                carrierProduct.rateUnitGuid,
                carrierProduct.costUnitGuid,
                carrierProduct.density,
                productMix.targetRate,
                props
            );
            const carrierTotalProduct =
                (newCarrierRate > 0 ? newCarrierRate : 0) *
                lineRateToCostConversion *
                calculatedArea;
            const carrierTotalCost = carrierTotalProduct * carrierProduct.cost;
            const carrierCostPerAcre = (carrierTotalProduct * carrierProduct.cost) / calculatedArea;
            const updatedCarrierProduct = {
                ...carrierProduct,
                rate: newCarrierRate,
                cost: blending.roundValue(carrierProduct.cost),
                costPerAcre: blending.roundValue(carrierCostPerAcre),
                totalProduct: blending.roundValue(carrierTotalProduct),
                totalCost: blending.roundValue(carrierTotalCost),
            };

            newProductList.splice(carrierIndex, 1, updatedCarrierProduct);
            // If it's import(ed) data and the carrier on/off changed, rebalance according to the change
            if (productMix.isImport && newProps.isCarrier != null && !newProps.isCarrier) {
                newProductList = this.balanceRateChange(
                    {
                        ...productMix,
                        products: newProductList,
                        targetRateUnitGuid: updatedCarrierProduct.rateUnitGuid,
                    },
                    carrierProduct,
                    updatedCarrierProduct
                );
            }

            if (isCarrier) {
                summaryDataCalculations.targetRate += updatedCarrierProduct.rate;
                summaryDataCalculations.costPerAcre += updatedCarrierProduct.costPerAcre;
                summaryDataCalculations.totalProduct += updatedCarrierProduct.totalProduct;
                summaryDataCalculations.totalCost += updatedCarrierProduct.totalCost;
                summaryDataCalculations.noServiceTotalCost += updatedCarrierProduct.totalCost;
                if (
                    blending.isNumeric(summaryDataCalculations.totalCost) &&
                    blending.isNumeric(summaryDataCalculations.totalProduct)
                ) {
                    const targetCost =
                        summaryDataCalculations.noServiceTotalCost /
                        summaryDataCalculations.totalProduct;
                    summaryDataCalculations.targetCost = !isNaN(targetCost) ? targetCost : 0;
                } else {
                    summaryDataCalculations.targetCost = 0;
                }
            }
            if (productMix.isImport && !isBlendingActive && Number(productMix.actualRate) !== 0) {
                summaryDataCalculations.targetRate = productMix.actualRate;
            }
        }
        const summaryData = !retainSummaryData
            ? {
                  targetRate:
                      newProps.targetRate != null && !isNaN(newProps.targetRate)
                          ? newProps.targetRate
                          : blending.roundValue(summaryDataCalculations.targetRate),
                  targetCost:
                      newProps.targetCost != null && !isNaN(newProps.targetCost)
                          ? newProps.targetCost
                          : summaryDataCalculations.targetCost,
                  totalProduct:
                      newProps.totalProduct != null && !isNaN(newProps.totalProduct)
                          ? newProps.totalProduct
                          : blending.roundValue(summaryDataCalculations.totalProduct),
                  costPerAcre:
                      newProps.costPerAcre != null && !isNaN(newProps.costPerAcre)
                          ? newProps.costPerAcre
                          : blending.roundValue(summaryDataCalculations.costPerAcre),
                  totalCost:
                      newProps.totalCost != null && !isNaN(newProps.totalCost)
                          ? newProps.totalCost
                          : blending.roundValue(summaryDataCalculations.totalCost),
              }
            : {
                  targetRate: productMix.targetRate,
                  targetCost: productMix.targetCost,
                  totalProduct: productMix.totalProduct,
                  costPerAcre: productMix.costPerAcre,
                  totalCost: productMix.totalCost,
              };

        const mixProductNutrients = this._calculateMixProductNutrients(
            newProductList,
            { targetRateUnitGuid, ...newProps, ...summaryData },
            props
        );

        return {
            ...mixProductNutrients,
            ...summaryData,
        };
    }

    _calculateMixProductNutrients(
        products: Record<string, any>,
        newProps: Record<string, any>,
        props = this.props
    ): any {
        const {
            availableProducts,
            calculatedArea,
            isFromEquationRec,
            productBlendPicklists,
            productMix,
            isEquation,
            isEquationComplete,
        } = props;
        const { nutrients } = productBlendPicklists;
        const { customProduct } = this.state;

        const isZeroedOut =
            +productMix.targetRate === 0 &&
            (newProps.targetRate == null || newProps.targetRate === 0) &&
            products.length === productMix.products.length &&
            products.every((p) =>
                productMix.products.some((p2) => p2.productGuid === p.productGuid)
            ) &&
            productMix.guaranteedAnalysis != null &&
            productMix.guaranteedAnalysis !== "0-0-0" &&
            isEquation &&
            isEquationComplete;

        const isOnlyServiceProducts =
            products.length > 0 &&
            products.every((p) => {
                const fullProductInfo = availableProducts.find(
                    (fullProduct) => fullProduct.productGuid === p.productGuid
                );
                return fullProductInfo?.productParentType === "Service";
            });

        const newMixNutrientList = isZeroedOut ? productMix.nutrients : [];
        const mixType = productMix.productMixType.toLowerCase();
        const poundUnit = blending.getPriceUnitByName(LB, props);
        const galUnit = blending.getPriceUnitByName(GAL, props);

        const totalMixProduct = products.reduce(
            (acc, product) => {
                const isServiceProduct = blending.getIsServiceProduct(
                    availableProducts,
                    product.productGuid
                );
                if (isServiceProduct) {
                    return [acc[0], acc[1]];
                }

                const lineRateToPoundsConversionFactor = blending.getProductRateConversionFactor(
                    product.rateUnitGuid,
                    poundUnit.value,
                    product.density,
                    productMix.targetRate,
                    props
                );
                const lineRateToGallonsConversionFactor = blending.getProductRateConversionFactor(
                    product.rateUnitGuid,
                    galUnit.value,
                    product.specificGravity * blending.DENSITY_WATER_LB_FT3,
                    productMix.targetRate,
                    props
                );
                const linePoundRate = product.rate * lineRateToPoundsConversionFactor;
                const lineGallonRate = product.rate * lineRateToGallonsConversionFactor;

                return [
                    acc[0] + linePoundRate * calculatedArea,
                    acc[1] + lineGallonRate * calculatedArea,
                ];
            },
            [0, 0]
        );

        const totalMixProductPounds = totalMixProduct[0];
        const totalMixProductGallons = totalMixProduct[1];

        const targetRateForDensity = newProps.targetRate
            ? newProps.targetRate
            : productMix.targetRate
            ? +productMix.targetRate
            : 0;
        const targetRateUnitGuidForDensity = isOnlyServiceProducts
            ? null
            : newProps.targetRateUnitGuid
            ? newProps.targetRateUnitGuid
            : productMix.targetRateUnitGuid;
        const targetRatePhysicalState = isOnlyServiceProducts
            ? null
            : blending.getRateUnitPhysicalState(targetRateUnitGuidForDensity, props);
        const mixDensity = isOnlyServiceProducts
            ? 0
            : targetRateForDensity === 0 && productMix.density
            ? productMix.density // if we're zeroing out the rec, keep the density around so we aren't up the creek later
            : targetRatePhysicalState === PhysicalStates.DRY
            ? totalMixProductPounds / (totalMixProductGallons / blending.FT3_GAL)
            : totalMixProductPounds / totalMixProductGallons;
        const densityUnits = isOnlyServiceProducts
            ? null
            : blending.getDensityUnits(targetRatePhysicalState, props);
        const newProductList = isZeroedOut
            ? products
            : products.map((product) => {
                  const isServiceProduct = blending.getIsServiceProduct(
                      availableProducts,
                      product.productGuid
                  );
                  if (isServiceProduct) {
                      return {
                          ...product,
                          percentOfMix: 0,
                      };
                  }

                  const lineRateToPoundsConversionFactor = blending.getProductRateConversionFactor(
                      product.rateUnitGuid,
                      poundUnit.value,
                      product.density,
                      targetRateForDensity,
                      props
                  );
                  const linePoundRate = product.rate * lineRateToPoundsConversionFactor;

                  const productTotalRatio =
                      (linePoundRate * calculatedArea) /
                      (totalMixProductPounds || linePoundRate * calculatedArea || 100);
                  const percentOfMix =
                      targetRateForDensity === 0 && product.percentOfMix
                          ? product.percentOfMix
                          : products.length === 1
                          ? 1
                          : blending.roundValue(productTotalRatio);

                  const nutrientList =
                      mixType === ProductMixTypes.MANURE
                          ? customProduct.nutrients
                          : product.nutrientList || product.nutrients || [];

                  nutrientList.forEach((nutrient) => {
                      const fullNutrient = nutrients.find((n) => n.id === nutrient.nutrientGuid);
                      nutrient.name = fullNutrient.name;
                      nutrient.element = fullNutrient.element;
                      nutrient.nameKey = fullNutrient.name.toLowerCase();

                      if (nutrient.percent === undefined) {
                          nutrient.percent = +nutrient.nutrientPercent;
                      }

                      const existingNutrientIndex = newMixNutrientList.findIndex(
                          (combinedNutrient) => combinedNutrient.name === nutrient.name
                      );
                      const existingNutrient =
                          existingNutrientIndex !== -1
                              ? newMixNutrientList[existingNutrientIndex]
                              : null;
                      const total =
                          (existingNutrient ? +existingNutrient.nutrientPercent : 0) +
                          +nutrient.percent * percentOfMix;

                      if (existingNutrientIndex !== -1) {
                          newMixNutrientList.splice(existingNutrientIndex, 1, {
                              ...nutrient,
                              nameKey: nutrient.name.toLowerCase().replace(/ /g, ""),
                              nutrientPercent: blending.roundValue(total),
                          });
                      } else {
                          newMixNutrientList.push({
                              ...nutrient,
                              nameKey: nutrient.name.toLowerCase().replace(/ /g, ""),
                              nutrientPercent: blending.roundValue(total),
                          });
                      }
                  });

                  return {
                      ...product,
                      percentOfMix,
                  };
              });

        const guaranteedAnalysis = isZeroedOut
            ? productMix.guaranteedAnalysis
            : blending.getGuaranteedAnalysis(newMixNutrientList);

        const productMixforId = {
            ...productMix,
            products: productMix.products.length > 0 ? productMix.products : newProductList,
        };
        const productMixId = isZeroedOut
            ? productMix.productMixId
            : mixType === ProductMixTypes.MANURE
            ? blending.getProductMixIdForManure(guaranteedAnalysis)
            : blending.getProductMixId(productMixforId);
        const newBlendName =
            mixType === ProductMixTypes.FERTILIZER
                ? (this.state.blendName === "" && !productMix.name) ||
                  this.state.blendName === guaranteedAnalysis ||
                  this.state.blendName === productMix.guaranteedAnalysis ||
                  (productMix.products.length === 1 && products.length > 1)
                    ? guaranteedAnalysis
                    : this.state.blendName
                : mixType === ProductMixTypes.CHEMICAL
                ? (this.state.blendName === "" && !productMix.name) ||
                  this.state.blendName ===
                      productMix.products
                          .filter((product) => !product.isCarrier)
                          .map((product) => product.productName)
                          .join("_")
                    ? newProductList
                          .filter((product) => !product.isCarrier)
                          .map((product) => product.productName)
                          .join("_")
                    : this.state.blendName
                : this.state.blendName;
        if (newBlendName !== this.state.blendName && products.length > 1) {
            this.setState({
                blendName: newBlendName,
            });
        }
        const newMixName = isFromEquationRec
            ? productMix.name
            : mixType === ProductMixTypes.MANURE
            ? `${this.state.customProduct.customProductType} - ${guaranteedAnalysis}`
            : newProductList.length > 1
            ? newBlendName
            : newProductList.length === 1
            ? products[0].productName
            : "";

        const newDensity =
            newProductList.length === 1 ? newProductList[0].density || 1 : mixDensity;

        return {
            density: newDensity,
            products: newProductList,
            nutrients: newMixNutrientList,
            name: newMixName,
            guaranteedAnalysis,
            productMixId,
            ...densityUnits,
        };
    }

    _cancelLoadBlend(): void {
        this.setState({
            loadCustomBlendModalOpen: false,
            selectedExistingBlendGuid: null,
            selectedExistingBlendIdx: -1,
            preserveRatesOnLoad: false,
        });
        this.props.clearCustomBlends();
    }

    _cancelUpdateProductBlend(): void {
        this.props.clearProductBlendGuid();
    }

    _canSaveCustomProduct(): boolean {
        const { productBlendPicklists } = this.props;
        const { physicalState } = productBlendPicklists;
        const { customProductPercentages, customProduct, customProductList } = this.state;

        const physicalStateItem = physicalState.find(
            (phys) => phys.value === customProduct.physicalStateGuid
        );
        let hasNutrient = false;

        for (const prop of Object.keys(customProductPercentages)) {
            if (!Object.hasOwn(customProductPercentages, prop)) {
                return;
            }
            if (customProductPercentages[prop]) {
                hasNutrient = true;
                break;
            }
        }

        const isValid =
            hasNutrient &&
            customProduct.customProductTypeGuid &&
            customProduct.physicalStateGuid &&
            (physicalStateItem.label.toLowerCase() !== PhysicalStates.LIQUID ||
                customProduct.density);

        const isDuplicate = customProductList
            .filter((blend) => blend.activeYn)
            .some((product) => {
                return (
                    product.customProductGuid &&
                    product.customProductGuid !== NEW &&
                    product.guaranteedAnalysis === customProduct.guaranteedAnalysis &&
                    product.physicalStateGuid === customProduct.physicalStateGuid &&
                    product.customProductTypeGuid === customProduct.customProductTypeGuid
                );
            });
        return isValid && !isDuplicate;
    }

    _changeProductMixTypeTab(idx: number): void {
        const { productBlendPicklists, productMix, setDefaultProductMixTypeGuid } = this.props;
        const { productMixTypes } = productBlendPicklists;
        const productMixType = productMixTypes.find(
            (mixType) => mixType.label.toLowerCase() === blending.mixNames[idx]
        );

        if (productMixType.value !== productMix.productMixTypeGuid) {
            setDefaultProductMixTypeGuid(productMixType.value);
            if (productMix.products.length !== 0) {
                this.setState({
                    clearProductsModalOpen: true,
                    nextSelectedTab: idx,
                    blendName: "",
                });
            } else {
                this.setState({ nextSelectedTab: idx, blendName: "" }, () =>
                    this._confirmClearProducts()
                );
            }
        }
    }

    _checkPendingCodes(): boolean {
        const { isEquation } = this.props;
        // If there are any pending codes at the time of the check, move them to stored codes, and return the validation state (true/false)
        const { pendingCodes } = this.state;
        if (isEquation && pendingCodes.length > 0) {
            this.setState({
                storedCodes: [...pendingCodes],
            });
            return false;
        } else {
            this._clearValidationCodes(this.props.recNutrient);
            return true;
        }
    }

    _cancelClearProducts(): void {
        this.setState({
            clearProductsModalOpen: false,
            nextSelectedTab: -1,
            selectedExistingBlendGuid: null,
            selectedExistingBlendIdx: -1,
            preserveRatesOnLoad: false,
        });
    }

    _clearCustomProduct(): void {
        this.setState({
            customProductPercentages: {
                nitrogen: null,
                phosphorus: null,
                potassium: null,
                calcium: null,
                magnesium: null,
                sulfur: null,
                iron: null,
                zinc: null,
                copper: null,
                manganese: null,
                boron: null,
                chlorine: null,
                molybdenum: null,
            },
            customProduct: {
                customProductGuid: null,
                customProductType: "",
                customProductTypeGuid: null,
                defaultRateUnitGuid: null,
                density: null,
                densityUnitGuid: null,
                guaranteedAnalysis: "",
                nutrients: [],
                physicalState: "",
                physicalStateGuid: null,
            },
            blendName: "",
            hasSavedBlend: false,
        });
    }

    /*
     *   This function get called when the user has made a valid adjustment.  Clear out any pending or stored codes,
     *   and set the current valid rec nutrient as the last good rec nutrient.
     */
    _clearValidationCodes(recNutrient: RecNutrient, reset = false): void {
        this.setState(
            {
                lastValidRecNutrient: { ...recNutrient },
                pendingCodes: [],
                storedCodes: [],
                isResetRecNutrient: reset,
            },
            () => {
                this.forceUpdate(() => {
                    this.setState({ isResetRecNutrient: false });
                });
            }
        );
    }

    _completeLoadBlend(): void {
        const { customBlends, clearCustomBlends, productMix, availableProducts } = this.props;
        const { customProductList } = this.state;
        const mixType = productMix.productMixType.toLowerCase();
        const { selectedExistingBlendGuid, selectedExistingBlendIdx, preserveRatesOnLoad } =
            this.state;
        const selectedExistingBlend =
            mixType !== ProductMixTypes.MANURE
                ? customBlends.find((blend) => blend.productBlendGuid === selectedExistingBlendGuid)
                : customProductList[selectedExistingBlendIdx];

        const hasExistingProduct = productMix.products.length > 0;
        const firstProduct =
            hasExistingProduct && preserveRatesOnLoad
                ? productMix.products[0]
                : new ProductMixProduct();
        const matchingPhysicalStates =
            productMix.physicalStateGuid === selectedExistingBlend.physicalStateGuid;

        if (productMix.isImport && mixType === ProductMixTypes.MANURE) {
            // Custom Products / Manure are always single product blends, so for import set the rate to the fixed target
            firstProduct.rate = Number(productMix.actualRate);
        }
        productMix.hasSavedBlend = true;
        const newProductList =
            mixType !== ProductMixTypes.MANURE
                ? selectedExistingBlend.products.map((product) => {
                      const isServiceProduct = blending.getIsServiceProduct(
                          availableProducts,
                          product.productGuid
                      );

                      if (productMix.isImport && mixType !== ProductMixTypes.CHEMICAL) {
                          product.rate = 0;
                          selectedExistingBlend.targetRate = productMix.actualRate;
                      } else if (isServiceProduct) {
                          product.rate = null; // this should cause it to default in the initialization step
                      }
                      return {
                          ...blending.initializeProduct(selectedExistingBlend, product, this.props),
                      };
                  })
                : [
                      {
                          ...blending.initializeProduct(
                              productMix,
                              {
                                  ...selectedExistingBlend,
                                  customProductName: selectedExistingBlend.guaranteedAnalysis,
                                  rate: firstProduct.rate ? firstProduct.rate : 0,
                                  cost: firstProduct.cost ? firstProduct.cost : 0,
                                  rateUnitGuid:
                                      firstProduct.rateUnitGuid && matchingPhysicalStates
                                          ? firstProduct.rateUnitGuid
                                          : selectedExistingBlend.defaultRateUnitGuid,
                                  costUnitGuid:
                                      firstProduct.costUnitGuid && matchingPhysicalStates
                                          ? firstProduct.costUnitGuid
                                          : null,
                                  percentOfMix: firstProduct.percentOfMix
                                      ? firstProduct.percentOfMix
                                      : 1,
                                  physicalState: blending.getRateUnitPhysicalState(
                                      firstProduct.rateUnitGuid,
                                      this.props
                                  ),
                              },
                              this.props
                          ),
                      },
                  ];

        const newProps = {
            ...selectedExistingBlend,
            name:
                mixType === ProductMixTypes.MANURE
                    ? `${selectedExistingBlend.customProductType} - ${productMix.guaranteedAnalysis}`
                    : selectedExistingBlend.name,
            productMixType: productMix.productMixType,
            products: newProductList,
            hasSavedBlend: true,
        };
        const mixUnits: any = blending.getProductMixUnits(newProps, newProps.products, this.props);
        const targetRate = selectedExistingBlend.targetRate
            ? selectedExistingBlend.targetRate
            : blending.getTargetRateFromProducts(
                  mixUnits.targetRateUnitGuid,
                  newProductList,
                  this.props
              );
        newProps.targetRate = targetRate;
        if (productMix.isImport && Number(productMix.actualRate) !== 0) {
            newProps.targetRate = productMix.actualRate;
        }
        if (mixType !== ProductMixTypes.MANURE) {
            this.setState(
                {
                    blendName: selectedExistingBlend.name,
                    hasSavedBlend: true,
                    loadCustomBlendModalOpen: false,
                    selectedExistingBlendGuid: null,
                    selectedExistingBlendIdx: -1,
                    preserveRatesOnLoad: false,
                },
                () => {
                    this._updateProductMix(
                        {
                            ...newProps,
                            ...mixUnits,
                        },
                        true
                    );
                    clearCustomBlends();
                }
            );
        } else {
            this.setState(
                {
                    customProductPercentages: {
                        nitrogen: null,
                        phosphorus: null,
                        potassium: null,
                        calcium: null,
                        magnesium: null,
                        sulfur: null,
                        iron: null,
                        zinc: null,
                        copper: null,
                        manganese: null,
                        boron: null,
                        chlorine: null,
                        molybdenum: null,
                    },
                    customProduct: {
                        ...selectedExistingBlend,
                    },
                    loadCustomBlendModalOpen: false,
                    selectedExistingBlendIdx: -1,
                    preserveRatesOnLoad: false,
                },
                () => {
                    const newNutrientPercentages = {};
                    selectedExistingBlend.nutrients.forEach(
                        (nutrient) =>
                            (newNutrientPercentages[nutrient.name.toLowerCase()] = Number(
                                nutrient.percent
                            ))
                    );
                    this._updateCustomProductPercentages(newNutrientPercentages);
                    this._updateProductMix({
                        ...newProps,
                        ...mixUnits,
                        density: this.state.customProduct.density,
                        hasSavedBlend: false,
                    });
                }
            );
        }
    }

    _completeProductMixTypeChange(): void {
        const { productBlendPicklists, isEquation } = this.props;
        const { nextSelectedTab } = this.state;
        const { productMixTypes } = productBlendPicklists;
        let mixName = "";
        switch (nextSelectedTab) {
            case 0:
                mixName = ProductMixTypes.FERTILIZER;
                break;
            case 1:
                if (isEquation) {
                    mixName = ProductMixTypes.MANURE;
                } else {
                    mixName = ProductMixTypes.CHEMICAL;
                }
                break;
            case 2:
                console.assert(!isEquation);
                mixName = ProductMixTypes.MANURE;
                break;
            default:
                break;
        }
        const productMixType = productMixTypes.find(
            (mixType) => mixType.label.toLowerCase() === mixName
        );

        this.setState(
            {
                selectedTab: nextSelectedTab,
                nextSelectedTab: -1,
                blendName: "",
                hasSavedBlend: false,
            },
            () => {
                this._updateProductMix({
                    productMixTypeGuid: productMixType.value,
                    productMixType: productMixType.label,
                    name: "",
                    hasSavedBlend: false,
                });
            }
        );
    }

    _confirmClearProducts(): void {
        this._clearProducts();
        if (this.state.nextSelectedTab !== -1) {
            this._completeProductMixTypeChange();
        }
        if (this.state.selectedExistingBlendIdx !== -1) {
            this._completeLoadBlend();
        }
        this._cancelClearProducts();
    }

    _clearProducts(): void {
        const { productMix, onUpdate } = this.props;
        const mixType = productMix.productMixType.toLowerCase();

        this.setState({
            blendName: mixType !== ProductMixTypes.MANURE ? "" : this.state.blendName,
        });
        onUpdate({
            ...productMix,
            recProductMixGuid: null,
            name: "",
            hasSavedBlend: false,
            targetRate: 0,
            targetCost: 0,
            targetRateUnitGuid: null,
            targetCostUnitGuid: null,
            totalProduct: 0,
            totalCost: 0,
            products: [],
            isLockGuaranteedAnalysis: false,
        });
    }

    _confirmDeactivateProductBlend(): void {
        const { customBlends, setCustomBlends } = this.props;
        const { selectedExistingBlendGuid } = this.state;
        this.setState({ deactivateProductBlendModalOpen: false });
        this.props.deactivateProductBlend(selectedExistingBlendGuid);
        const customList = [...customBlends];
        const revisedList = customList.filter(
            (blend) => blend.productBlendGuid !== selectedExistingBlendGuid
        );
        setCustomBlends(revisedList);
    }

    _getApplyCreditsEnabled(): boolean {
        const { recNutrient, availableCredits } = this.props;
        const { availableRecCredits, availableApplicationCredits } = availableCredits;

        return (
            recNutrient.credits.some(
                (credit) => credit.flatRate && credit.isCredited !== credit.isOriginalCredit
            ) ||
            recNutrient.credits.some((credit) =>
                availableRecCredits.some(
                    (recCredit) =>
                        credit.creditRecGeneralGuid === recCredit.recGeneralGuid &&
                        credit.creditProductMixId === recCredit.recProductMixId &&
                        ((credit.isCredited && !credit.isOriginalCredit) ||
                            (!credit.isCredited && credit.isOriginalCredit))
                )
            ) ||
            recNutrient.credits.some((credit) =>
                availableApplicationCredits.some(
                    (eventCredit) =>
                        credit.creditAgEventGeneralGuid === eventCredit.agEventGeneralGuid &&
                        credit.creditProductMixName === eventCredit.productMixName &&
                        ((credit.isCredited && !credit.isOriginalCredit) ||
                            (!credit.isCredited && credit.isOriginalCredit))
                )
            )
        );
    }

    _logFirebaseEvent(event: "create" | "load" | "edit" | "clear" | "save"): void {
        const { productMix, isEquation } = this.props;
        const mixOrBlend =
            productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL.toLowerCase()
                ? "mix"
                : "blend";
        isEquation
            ? logFirebaseEvent(`equation_rec_${event}_${mixOrBlend}`)
            : logFirebaseEvent(`application_${event}_${mixOrBlend}`);
    }

    _getValidationErrorMessage(): any[] {
        const { formatMessage } = this.props.intl;
        const { validationErrors } = blending;
        const isAdjustmentError = this.state.storedCodes.length;
        const isDensityValidationError = this.state.densityValidationCode !== 0;
        let messageArray = [];
        if (isAdjustmentError) {
            messageArray = this.state.storedCodes.map((code) => {
                switch (code) {
                    case validationErrors.MIN_MAX_INVALID:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.MIN_MAX_INVALID}`}
                            >
                                {formatMessage(messages.invalidMinMax)}
                            </div>
                        );
                    case validationErrors.ADJUST_OOB:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.ADJUST_OOB}`}
                            >
                                {formatMessage(messages.invalidAdjust)}
                            </div>
                        );
                    case validationErrors.MIN_TOO_HIGH:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.MIN_TOO_HIGH}`}
                            >
                                {formatMessage(messages.invalidMinAdjust)}
                            </div>
                        );
                    case validationErrors.MAX_TOO_LOW:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.MAX_TOO_LOW}`}
                            >
                                {formatMessage(messages.invalidMaxAdjust)}
                            </div>
                        );
                    case validationErrors.ADJUST_BELOW_LOCKED_MIN:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.ADJUST_BELOW_LOCKED_MIN}`}
                            >
                                {formatMessage(messages.invalidAdjustLow)}
                            </div>
                        );
                    case validationErrors.ADJUST_ABOVE_LOCKED_MAX:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.ADJUST_ABOVE_LOCKED_MAX}`}
                            >
                                {formatMessage(messages.invalidAdjustHigh)}
                            </div>
                        );
                    case validationErrors.SWITCH_TOO_HIGH:
                        return (
                            <div
                                className="blending-validation-error-msg"
                                key={`equation-validation-${validationErrors.SWITCH_TOO_HIGH}`}
                            >
                                {formatMessage(messages.invalidSwitchAdjust)}
                            </div>
                        );
                    default:
                        return null;
                }
            });

            messageArray.push(
                <div className="blending-validation-reset-msg" key="equation-validation-reset">
                    {formatMessage(messages.invalidResetImminent)}
                </div>
            );
        } else if (isDensityValidationError) {
            switch (this.state.densityValidationCode) {
                case validationErrors.DRY_FERT_MISSING_SG:
                    messageArray.push(
                        <div
                            className="blending-validation-error-msg"
                            key={`blending-validation-${validationErrors.DRY_FERT_MISSING_SG}`}
                        >
                            {formatMessage(messages.invalidProductSpecificGravity)}
                        </div>
                    );
                    break;
                case validationErrors.MISSING_DENSITY:
                    messageArray.push(
                        <div
                            className="blending-validation-error-msg"
                            key={`blending-validation-${validationErrors.MISSING_DENSITY}`}
                        >
                            {formatMessage(messages.invalidProductDensity)}
                        </div>
                    );
                    break;
                default:
                    break;
            }
        }

        return messageArray;
    }

    _loadBlend(): void {
        const { customBlends, productMix } = this.props;
        const { customProductList } = this.state;
        const mixType = productMix.productMixType.toLowerCase();

        const { selectedExistingBlendGuid, selectedExistingBlendIdx } = this.state;
        if (selectedExistingBlendIdx === -1 && selectedExistingBlendGuid === null) {
            return;
        }
        const selectedExistingBlend =
            mixType !== ProductMixTypes.MANURE
                ? customBlends.find((blend) => blend.productBlendGuid === selectedExistingBlendGuid)
                : customProductList[selectedExistingBlendIdx];

        if (!selectedExistingBlend) {
            return;
        }
        if (productMix.products.length !== 0 && mixType !== ProductMixTypes.MANURE) {
            this.setState({ clearProductsModalOpen: true });
        } else {
            this._confirmClearProducts();
        }
    }

    _onModalSave(): void {
        const { productMix, onSave } = this.props;
        const mixType = productMix.productMixType.toLowerCase();
        if (mixType === ProductMixTypes.MANURE) {
            if (productMix.customProductGuid === NEW || !productMix.customProductGuid) {
                this.setState(
                    {
                        isSavingCustomProduct: true,
                        isSavingModal: true,
                    },
                    () => {
                        this._saveBlend();
                    }
                );
            } else {
                onSave();
            }
        } else {
            onSave();
        }
    }

    _onModalCancel(): void {
        const { onCancel, recNutrient, onUpdateRecNutrient, isEquation } = this.props;
        const { originalRecNutrient } = this.state;

        if (isEquation && originalRecNutrient && recNutrient) {
            const hasProductsChanged =
                JSON.stringify(
                    recNutrient.recNutrientProductMix.products.map(
                        (product) => product.productGuid || product.customProductGuid
                    )
                ) !==
                JSON.stringify(
                    originalRecNutrient.recNutrientProductMix.products.map(
                        (product) => product.productGuid || product.customProductGuid
                    )
                );
            const hasCreditsChanged =
                JSON.stringify(recNutrient.credits.map((credit) => credit.creditGuid)) !==
                JSON.stringify(originalRecNutrient.credits.map((credit) => credit.creditGuid));
            if (hasCreditsChanged) {
                this._applyCredits(originalRecNutrient);
            }
            if (hasProductsChanged) {
                onUpdateRecNutrient(originalRecNutrient, hasProductsChanged);
            }
        }

        onCancel();
    }

    _removeProduct(index: number): void {
        const { productMix, onUpdate } = this.props;
        const { isBlendingActive } = this.state;

        if (
            productMix.products.length === 1 ||
            (productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL &&
                productMix.products.length === 2)
        ) {
            this._clearProducts();
        } else {
            let newProductList = [...productMix.products];

            if (productMix.isImport && !isBlendingActive) {
                newProductList = this.balanceRateChange(productMix, newProductList[index], null);
            } else {
                newProductList.splice(index, 1);
            }

            const newTargetRateUnitGuid = newProductList.some(
                (product) => product.rateUnitGuid === productMix.targetRateUnitGuid
            )
                ? productMix.targetRateUnitGuid
                : newProductList[0].rateUnitGuid;
            const newTargetRateUnit = blending.getRateUnit(newTargetRateUnitGuid, this.props);

            const newProductMix = {
                ...productMix,
                products: newProductList,
                targetRateUnitGuid: newTargetRateUnit?.value,
                targetRateUnit: newTargetRateUnit?.label,
            };

            const newTargetCostUnitGuid = blending.getEffectiveCostUnitGuid(
                newProductMix,
                newProductMix.products
            );
            const summaryTotals = this._calculateTotals(
                newProductMix.products,
                newProductMix.targetRateUnitGuid,
                newTargetCostUnitGuid,
                {}
            );

            onUpdate({
                ...newProductMix,
                ...summaryTotals,
                targetCostUnitGuid: newTargetCostUnitGuid,
            });
        }
    }

    /*
     *   This function will be called upon the close of the validation modal.  It clears out any pending/stored codes
     *   (via _clearValidationCodes) and calls onUpdateRecNutrient to set the recNutrient of the modal to the last valid
     *   rec nutrient (undoing any invalid calculations the user has made)
     */
    _resetToValidRecNutrient(): void {
        this._clearValidationCodes(this.state.lastValidRecNutrient, true);
        this.props.onUpdateRecNutrient(this.state.lastValidRecNutrient, false);
    }

    _saveBlend(): void {
        const { productMix, saveProductBlend, saveCustomProduct, productBlendGuid, isEquation } =
            this.props;
        const { isBlendingActive } = this.state;
        const mixType = productMix.productMixType.toLowerCase();

        if (!this._saveBlendEnabled()) {
            return;
        }

        if (mixType !== ProductMixTypes.MANURE) {
            const blendInfo = {
                ...productMix,
                name: this.state.blendName,
                hasSavedBlend: true,
                productBlendGuid: productBlendGuid || "",
            };
            this.setState(
                {
                    isEquationSavingBlend: isEquation && isBlendingActive,
                    hasSavedBlend: true,
                },
                () => {
                    saveProductBlend(
                        blendInfo,
                        isEquation && isBlendingActive,
                        (productBlendGuid) => {
                            this._updateProductMix({
                                productBlendGuid: productBlendGuid,
                                productBlendName: this.state.blendName,
                                hasSavedBlend: true,
                            });
                        }
                    );
                }
            );
        } else {
            this.setState(
                {
                    isSavingCustomProduct: true,
                },
                () => {
                    saveCustomProduct(this.state.customProduct);
                }
            );
        }
    }

    _saveBlendEnabled(): boolean {
        const { productMix } = this.props;
        const mixType = productMix.productMixType.toLowerCase();
        return mixType !== ProductMixTypes.MANURE
            ? this._saveEnabled() && this.state.blendName !== ""
            : this._canSaveCustomProduct();
    }

    _saveEnabled(): boolean {
        const { productMix, isEquation, isLime, nutrientGuid, recNutrient } = this.props;
        const { isBlendingActive, pendingCodes, storedCodes } = this.state;
        const zerosBelowSwitchRate =
            recNutrient && recNutrient.recNutrientParameters.zerosBelowSwitchRate;
        const switchRate =
            recNutrient && recNutrient.recNutrientParameters.switchRate
                ? recNutrient.recNutrientParameters.switchRate
                : null;

        return (
            productMix.name &&
            productMix.products.length !== 0 &&
            ((isEquation && !isBlendingActive) ||
                !productMix.products.some(
                    (product) =>
                        (!product.rate || blending.roundValue(product.rate, 2) <= 0) &&
                        product.productParentType !== "Service" &&
                        (productMix.isCarrier || !product.isCarrier)
                )) &&
            (!isBlendingActive || productMix.products.length > 1) &&
            productMix.products.some((product) => product.productParentType !== "Service") &&
            (!zerosBelowSwitchRate || switchRate > 0) &&
            (!isLime || productMix.limeEfficiency) &&
            (!isEquation ||
                productMix.products.some((product) =>
                    (
                        (product.customProductGuid
                            ? productMix.nutrients
                            : product.nutrientList || product.nutrients) || []
                    ).some((nutrient) => nutrient.nutrientGuid === nutrientGuid)
                )) &&
            (!isEquation || (pendingCodes.length === 0 && storedCodes.length === 0))
        ); // disable saving while validation errors are present
    }

    _toggleCreditsExpanded(isExpanded: boolean): void {
        this.setState({
            isCreditsExpanded: isExpanded,
        });
    }

    _toggleFlatRateCredit(value: boolean): void {
        const { recNutrient } = this.props;

        const flatRateIndex = recNutrient.credits.findIndex(
            (credit) => credit.flatRate != null && credit.flatRate !== ""
        );
        const newFlatRate =
            flatRateIndex !== -1
                ? {
                      ...recNutrient.credits[flatRateIndex],
                      recNutrientGuid: recNutrient.recNutrientGuid,
                      isCredited: value,
                  }
                : {
                      recNutrientGuid: recNutrient.recNutrientGuid,
                      flatRate: 0,
                      isCredited: value,
                      isOriginalCredit: false,
                  };
        const newCreditsList = [...recNutrient.credits];
        if (flatRateIndex !== -1) {
            newCreditsList.splice(flatRateIndex, 1, newFlatRate);
        } else {
            newCreditsList.push(newFlatRate);
        }
        this._updateRecNutrient({ credits: newCreditsList });
    }

    _toggleLoadCustomBlend(): void {
        const { fetchCustomBlends, fetchCustomProducts, productMix, isEquation, nutrientGuid } =
            this.props;
        const mixType = productMix.productMixType.toLowerCase();

        this.setState(
            {
                loadCustomBlendModalOpen: !this.state.loadCustomBlendModalOpen,
            },
            () => {
                if (this.state.loadCustomBlendModalOpen) {
                    if (mixType !== ProductMixTypes.MANURE) {
                        if (!isEquation) {
                            fetchCustomBlends(productMix.productMixTypeGuid);
                        } else {
                            fetchCustomBlends(productMix.productMixTypeGuid, nutrientGuid);
                        }
                    } else {
                        fetchCustomProducts();
                    }
                }
            }
        );
    }

    _updateCredit(credit: Record<string, any>, isCredited: boolean, isRec: boolean): void {
        const { recNutrient } = this.props;
        const guidProp = isRec ? "recGeneralGuid" : "agEventGeneralGuid";
        const productMixIdProp = isRec ? "recProductMixId" : "productMixId";
        const productMixNameProp = isRec ? "recProductMixId" : "productMixName";
        const creditGuidProp = isRec ? "creditRecGeneralGuid" : "creditAgEventGeneralGuid";

        if (isCredited) {
            const creditIndex = recNutrient.credits.findIndex(
                (nutrientCredit) =>
                    ((isRec && credit[productMixIdProp] === nutrientCredit.creditProductMixId) ||
                        credit[productMixNameProp] === nutrientCredit.creditProductMixName) &&
                    credit[guidProp] === nutrientCredit[creditGuidProp]
            );
            const newCredit =
                creditIndex !== -1
                    ? {
                          ...recNutrient.credits[creditIndex],
                          recNutrientGuid: recNutrient.recNutrientGuid,
                          isCredited: true,
                      }
                    : {
                          ...credit,
                          recNutrientGuid: recNutrient.recNutrientGuid,
                          [creditGuidProp]: credit[guidProp],
                          creditProductMixId: credit[productMixIdProp],
                          creditProductMixName: credit[productMixNameProp],
                          isCredited: true,
                          isOriginalCredit: false,
                      };
            const newCreditList = [...recNutrient.credits];
            if (creditIndex !== -1) {
                newCreditList.splice(creditIndex, 1, newCredit);
            } else {
                newCreditList.push(newCredit);
            }
            this._updateRecNutrient({
                credits: newCreditList,
            });
        } else {
            const creditIndex = recNutrient.credits.findIndex(
                (nutrientCredit) =>
                    (credit[productMixIdProp] === nutrientCredit.creditProductMixId ||
                        credit[productMixNameProp] === nutrientCredit.creditProductMixName) &&
                    (credit[guidProp] === nutrientCredit[creditGuidProp] ||
                        credit[creditGuidProp] === nutrientCredit[creditGuidProp])
            );
            const newCredit = {
                ...recNutrient.credits[creditIndex],
                recNutrientGuid: recNutrient.recNutrientGuid,
                isCredited: false,
            };
            const newCredits = [...recNutrient.credits];
            newCredits.splice(creditIndex, 1, newCredit);
            this._updateRecNutrient({ credits: newCredits });
        }
    }

    _updateCustomProduct(newProps: Record<string, any>): void {
        const { productBlendPicklists } = this.props;
        const { physicalState, densityUnits, customProductTypes } = productBlendPicklists;

        if (newProps.physicalStateGuid) {
            const physicalStateItem = physicalState.find(
                (phy) => newProps.physicalStateGuid === phy.value
            );
            const densityItem =
                physicalStateItem.label.toLowerCase() === PhysicalStates.DRY
                    ? densityUnits.find((unit) => unit.label === LBPERFT3)
                    : densityUnits.find((unit) => unit.label === LBPERGAL);
            newProps.physicalState = physicalStateItem.label;
            newProps.densityUnitGuid = densityItem.value;
            newProps.densityUnit = densityItem.label;
        } else if (newProps.customProductTypeGuid) {
            const customProductTypeItem = customProductTypes.find(
                (cpt) => cpt.value === newProps.customProductTypeGuid
            );
            newProps.customProductType = customProductTypeItem.label;
        }
        this.setState(
            {
                customProduct: {
                    ...this.state.customProduct,
                    ...newProps,
                },
            },
            () => {
                this._updateCustomProductName();
            }
        );
    }

    _updateCustomProductName(): void {
        const { productBlendPicklists } = this.props;
        const { nutrients, physicalState } = productBlendPicklists;
        const { customProduct } = this.state;
        const physicalStateItem = physicalState.find(
            (phys) => phys.value === customProduct.physicalStateGuid
        );
        const customProductNutrients = customProduct.nutrients.map((nutrient) => {
            const fullNutrient = nutrients.find((n) => n.id === nutrient.nutrientGuid);
            return {
                ...fullNutrient,
                ...nutrient,
                nameKey: fullNutrient.name.toLowerCase(),
                nutrientPercent:
                    nutrient.nutrientPercent === undefined && nutrient.percent !== undefined
                        ? +nutrient.percent
                        : nutrient.nutrientPercent,
            };
        });

        const GA = blending.getGuaranteedAnalysis(customProductNutrients);

        if (GA === "0-0-0") {
            this.setState({
                blendName: "",
                hasSavedBlend: false,
                customProduct: {
                    ...this.state.customProduct,
                    nutrients: customProductNutrients,
                    guaranteedAnalysis: GA,
                },
            });
            this._updateProductMix({ name: "", hasSavedBlend: false });
        } else {
            const newBlendName = `${GA}${physicalStateItem ? ` (${physicalStateItem.label})` : ""}`;
            this.setState({
                blendName: newBlendName,
                hasSavedBlend: false,
                customProduct: {
                    ...this.state.customProduct,
                    nutrients: customProductNutrients,
                    guaranteedAnalysis: GA,
                },
            });
            this._updateProductMix({
                name: `${this.state.customProduct.customProductType} - ${newBlendName}`,
            });
        }
    }

    _updateCustomProductPercentages(newProps: Record<string, any>): void {
        const { customProductPercentages } = this.state;

        let percentageSum = 0;

        for (const prop of Object.keys(customProductPercentages)) {
            if (!Object.hasOwn(customProductPercentages, prop)) {
                return;
            }
            percentageSum += newProps[prop] || customProductPercentages[prop];
        }
        this.setState(
            {
                customProductPercentages: {
                    ...customProductPercentages,
                    ...newProps,
                },
            },
            () => {
                if (percentageSum > 100) {
                    this.setState({
                        customProductPercentages: {
                            ...customProductPercentages,
                        },
                    });
                } else {
                    const { productBlendPicklists } = this.props;
                    const { nutrients } = productBlendPicklists;
                    const { customProduct } = this.state;
                    const nutrientList = [];
                    for (const prop of Object.keys(this.state.customProductPercentages)) {
                        if (!Object.hasOwn(this.state.customProductPercentages, prop)) {
                            return;
                        }
                        const nutrient = nutrients.find((nut) => nut.name.toLowerCase() === prop);
                        const nutrientPercent = this.state.customProductPercentages[prop];
                        if (!nutrientPercent) {
                            continue;
                        }
                        nutrientList.push({
                            customProductGuid: customProduct.customProductGuid,
                            customProductNutrientGuid: null,
                            element: nutrient.element,
                            name: nutrient.name,
                            nutrientGuid: nutrient.id,
                            percent: nutrientPercent,
                            nameKey: nutrient.name.toLowerCase(),
                            nutrientPercent,
                        });
                    }
                    this.setState(
                        {
                            customProduct: {
                                ...customProduct,
                                nutrients: nutrientList,
                            },
                        },
                        () => {
                            this._updateCustomProductName();
                        }
                    );
                }
            }
        );
    }

    _updateFlatRateCredit(value: boolean): void {
        const { recNutrient } = this.props;
        const flatRateIndex = recNutrient.credits.findIndex(
            (credit) => credit.flatRate != null && credit.flatRate !== ""
        );
        const newFlatRate =
            flatRateIndex !== -1
                ? {
                      ...recNutrient.credits[flatRateIndex],
                      recNutrientGuid: recNutrient.recNutrientGuid,
                      flatRate: value,
                  }
                : {
                      recNutrientGuid: recNutrient.recNutrientGuid,
                      flatRate: value,
                      isCredited: false,
                      isOriginalCredit: false,
                  };
        const newCreditsList = [...recNutrient.credits];
        if (flatRateIndex !== -1) {
            newCreditsList.splice(flatRateIndex, 1, newFlatRate);
        } else {
            newCreditsList.push(newFlatRate);
        }
        this._updateRecNutrient({ credits: newCreditsList });
    }

    balanceRateChange(
        productMix: Record<string, any>,
        oldProduct: Record<string, any>,
        newProduct: Record<string, any>
    ): any {
        const isRemoval = newProduct == null;

        // 1.  Get conversion rate from newProduct rateUnit to targetRate quantity unit
        const newProductToTargetConversionFactor = isRemoval
            ? 0
            : blending.getProductRateConversionFactor(
                  newProduct.rateUnitGuid,
                  blending.getPriceUnitFromRateUnit(
                      blending.getRateUnit(productMix.targetRateUnitGuid, this.props),
                      this.props
                  ).value,
                  newProduct.density,
                  productMix.targetRate,
                  this.props
              );
        // 2.  Convert new product rate to targetRate unit and validate that it's <= to targetRate
        let convertedNewProductRate = isRemoval
            ? 0
            : newProduct.rate * newProductToTargetConversionFactor;

        if (convertedNewProductRate > productMix.targetRate && !productMix.isImport) {
            convertedNewProductRate = productMix.targetRate;
            newProduct.rate = productMix.targetRate / newProductToTargetConversionFactor;

            // In this situation, all other products zero out, so there isn't much point in jumping through all the hoops that follow
            return productMix.products.map((product) => {
                return {
                    ...product,
                    ...blending.calculate(
                        productMix,
                        product,
                        {
                            rate:
                                product.productGuid === newProduct.productGuid
                                    ? newProduct.rate
                                    : 0,
                        },
                        productMix.targetRate,
                        this.props
                    ),
                };
            });
        }

        // 3.  Convert old product rate to targetRate unit to determine the rate delta in targetRateUnit
        const oldProductToTargetConversionFactor = blending.getProductRateConversionFactor(
            oldProduct.rateUnitGuid,
            blending.getPriceUnitFromRateUnit(
                blending.getRateUnit(productMix.targetRateUnitGuid, this.props),
                this.props
            ).value,
            oldProduct.density,
            productMix.targetRate,
            this.props
        );
        const convertedOldProductRate = oldProduct.rate * oldProductToTargetConversionFactor;
        const targetRateDelta = convertedNewProductRate - convertedOldProductRate;

        // 4.  Loop through the original product list, ignoring the altered product
        //      a.  Get the conversion from line product rate unit to target rate unit
        //      b.  Convert line product rate unit to target rate unit
        //      c.  Accumulate the total product (in target rate unit, excluding altered product)
        const productGuidToRateProportionMap = new Map();
        const sumConvertedProductRate = productMix.products.reduce((acc, product) => {
            if (isRemoval && product.productGuid === oldProduct.productGuid) {
                return acc;
            } else if (!isRemoval && product.productGuid === newProduct.productGuid) {
                productGuidToRateProportionMap.set(product.productGuid, {
                    originalRate: product.rate,
                    conversionFactor: 1,
                    convertedRate: convertedNewProductRate,
                    proportion: null,
                    balancedRate: null,
                });
                return acc;
            }
            const productToTargetConversionFactor = blending.getProductRateConversionFactor(
                product.rateUnitGuid,
                blending.getPriceUnitFromRateUnit(
                    blending.getRateUnit(productMix.targetRateUnitGuid, this.props),
                    this.props
                ).value,
                product.density,
                productMix.targetRate,
                this.props
            );
            const convertedProductRate = product.rate * productToTargetConversionFactor;
            productGuidToRateProportionMap.set(product.productGuid, {
                originalRate: product.rate,
                conversionFactor: productToTargetConversionFactor,
                convertedRate: convertedProductRate,
                proportion: null, // can't determine the proportion until we have the sum
                balancedRate: null,
            });
            return acc + convertedProductRate;
        }, 0);

        //      d.  Determine the proportion of the remaining mix that each product comprises
        // 5.  Using the values arrived at in 4(d), take the total rate delta from (3), and apply,
        //      for every other product in the mix, a rate change of (rate_delta * product_i_proportion)
        productGuidToRateProportionMap.forEach((product, productGuid) => {
            const proportion =
                (isRemoval && product.productGuid === oldProduct.productGuid) ||
                (!isRemoval && productGuid === newProduct.productGuid)
                    ? 0
                    : product.convertedRate / sumConvertedProductRate || 0;
            const newConvertedRate = product.convertedRate - targetRateDelta * proportion;
            const balancedRate = newConvertedRate * (1 / product.conversionFactor);
            // The inverse of the conversion reverses it to its original unit

            productGuidToRateProportionMap.set(productGuid, {
                ...product,
                proportion,
                balancedRate,
            });
        });

        // 6.  Call the blending.calculate function on each updated product
        //      to update the products summary values, and overwrite it in the product list.
        const newProductList = productMix.products
            .map((product) => {
                const updateProductInfo = productGuidToRateProportionMap.get(product.productGuid);
                if (
                    !updateProductInfo ||
                    (isRemoval && product.productGuid === oldProduct.productGuid)
                ) {
                    return null;
                }

                return {
                    ...product,
                    ...blending.calculate(
                        productMix,
                        product,
                        { rate: updateProductInfo.balancedRate },
                        productMix.targetRate,
                        this.props
                    ),
                };
            })
            .filter((product) => product);

        // 7.  Return the updated/balanced product list
        return newProductList;

        // 8.  ???
        // 9.  Profit
    }

    _updateProductDebounce = debounce((index, newProps, strValue) => {
        this._updateProduct(index, newProps, strValue);
    }, 700);

    _updateProduct(
        index: number,
        newProps: Record<string, any>,
        strValue = "",
        isCarrier = false
    ): void {
        if (strValue === "0.") {
            return;
        }

        const {
            availableProducts,
            productMix,
            calculatedArea,
            defaultCarrierGuid,
            isEquation,
            isEquationComplete,
            isLime,
            productBlendPicklists,
            setDefaultCarrierGuid,
        } = this.props;
        const { densityUnits } = productBlendPicklists;
        const { customProductList, isBlendingActive } = this.state;
        const existingProduct = productMix.products[index];
        const mixType = productMix.productMixType.toLowerCase();
        if (
            (!blending.getIsProductMixInitialized(productMix) &&
                (!isEquation || isEquationComplete)) ||
            (productMix.isImport &&
                !isBlendingActive &&
                productMix.products.length === 1 &&
                Number(productMix.actualRate) !== 0 &&
                newProps.rate &&
                existingProduct.rate !== newProps.rate)
        ) {
            return;
        }

        const applicableProductGuid = newProps.productGuid || existingProduct.productGuid;

        const fullProductInfo = availableProducts.find(
            (product) => product.productGuid === applicableProductGuid
        );

        const isServiceProduct = blending.getIsServiceProduct(
            availableProducts,
            applicableProductGuid
        );

        if (newProps.customProductGuid) {
            const newCustomProductIdx = customProductList.findIndex(
                (product) => product.customProductGuid === newProps.customProductGuid
            );
            this.setState(
                {
                    selectedExistingBlendIdx: newCustomProductIdx,
                },
                () => this._loadBlend()
            );
        } else if (newProps.productGuid) {
            const rateUnit = blending.getRateUnit(fullProductInfo.defaultRateUnitGuid, this.props);
            const costUnit = isServiceProduct
                ? blending.getPriceUnitByName("ac", this.props, "Service")
                : blending.getPriceUnit(fullProductInfo.defaultPriceUnitGuid, this.props);
            const physicalStateLabel = isServiceProduct
                ? null
                : blending.getPriceUnitPhysicalState(costUnit.value, this.props);
            const densityUnit = isServiceProduct
                ? null
                : physicalStateLabel === PhysicalStates.DRY
                ? densityUnits.find((item) => item.label.toLowerCase() === LBPERFT3)
                : densityUnits.find((item) => item.label.toLowerCase() === LBPERGAL);
            const targetRateUnitGuid = isServiceProduct
                ? productMix.targetRateUnitGuid
                : productMix.products.length === 1
                ? fullProductInfo.defaultRateUnitGuid
                : productMix.targetRateUnitGuid;
            const { density, specificGravity } = isServiceProduct
                ? { density: null, specificGravity: null }
                : blending.getProductDensityValues(
                      fullProductInfo,
                      existingProduct,
                      targetRateUnitGuid,
                      rateUnit.value,
                      this.props
                  );
            const densityValidationCode = isServiceProduct
                ? 0
                : blending.validateDensityValues(density, specificGravity);
            if (densityValidationCode !== 0) {
                this.setState({
                    densityValidationCode,
                });
                return;
            }

            const replacementProduct = {
                ...existingProduct,
                ...fullProductInfo,
                costUnit: costUnit.label || "",
                costUnitGuid: isServiceProduct
                    ? costUnit.value
                    : fullProductInfo.defaultPriceUnitGuid,
                density,
                densityUnit: isServiceProduct ? null : densityUnit.label,
                isCarrier,
                productName: fullProductInfo.name,
                rateUnit: isServiceProduct ? null : rateUnit.label || "",
                rateUnitGuid: isServiceProduct ? null : fullProductInfo.defaultRateUnitGuid,
                rateUnitName: isServiceProduct ? null : rateUnit.label || "",
                recProductMixGuid: productMix.recProductMixGuid,
                specificGravity,
            };

            const newProducts = [...productMix.products];
            newProducts.splice(index, 1, {
                ...replacementProduct,
                ...blending.calculate(productMix, replacementProduct, {}, 0, this.props),
            });

            const mixUnits: any = blending.getProductMixUnits(productMix, newProducts, this.props);

            const summaryData = this._calculateTotals(
                newProducts,
                mixUnits.targetRateUnitGuid,
                mixUnits.targetCostUnitGuid,
                {}
            );

            this._updateProductMix(
                {
                    ...mixUnits,
                    products: newProducts,
                    ...summaryData,
                },
                true
            );
        } else if (blending.getIsChanged(newProps, existingProduct)) {
            if (isServiceProduct) {
                const product = {
                    ...existingProduct,
                    ...newProps,
                    ...blending.calculate(productMix, existingProduct, newProps, 0, this.props),
                };

                const newProducts = [...productMix.products];
                newProducts.splice(index, 1, product);

                this._updateProductMix(
                    {
                        ...this._calculateTotals(
                            newProducts,
                            productMix.targetRateUnitGuid,
                            blending.getEffectiveCostUnitGuid(productMix, productMix.products),
                            {},
                            true,
                            false
                        ),
                    },
                    true
                );
            } else {
                if (newProps.totalProduct != null && +existingProduct.rate === 0) {
                    const costUnitToRateUnitConversionFactor =
                        blending.getProductRateConversionFactor(
                            existingProduct.rateUnitGuid,
                            existingProduct.costUnitGuid,
                            existingProduct.density,
                            productMix.targetRate,
                            this.props
                        );
                    newProps.rate =
                        (newProps.totalProduct * (1 / costUnitToRateUnitConversionFactor)) /
                        calculatedArea;
                    newProps.totalProduct = undefined;
                }

                const product = {
                    ...existingProduct,
                    ...newProps,
                    ...blending.calculate(productMix, existingProduct, newProps, 0, this.props),
                };

                let newProducts = [...productMix.products];

                if (
                    productMix.isImport &&
                    !isBlendingActive &&
                    Number(productMix.actualRate) !== 0 &&
                    (mixType !== ProductMixTypes.CHEMICAL || !productMix.isCarrier)
                ) {
                    newProducts = this.balanceRateChange(productMix, existingProduct, product);
                }

                if (product.isCarrier && product.productGuid !== defaultCarrierGuid) {
                    setDefaultCarrierGuid(product.productGuid);
                }

                newProducts.splice(index, 1, product);

                const hasRateDependency = isServiceProduct
                    ? false
                    : product.rateUnit?.split("/")[1]?.toLowerCase() === PER100GAL;
                const mixHasRateDependency =
                    hasRateDependency ||
                    productMix.products.some(
                        (mixProduct) =>
                            mixProduct.productGuid !== product.productGuid &&
                            mixProduct.rateUnit?.split("/")[1]?.toLowerCase() === PER100GAL
                    );
                const galPerAcre = productBlendPicklists.liquidRateUnits.find(
                    (unit) => unit.label.toLowerCase() === GALPERAC
                );

                const hadRateDependency =
                    mixType !== ProductMixTypes.CHEMICAL &&
                    !hasRateDependency &&
                    existingProduct.rateUnit?.split("/")[1]?.toLowerCase() === PER100GAL;

                const newTargetRateUnitGuid = mixHasRateDependency
                    ? galPerAcre.value
                    : hadRateDependency
                    ? product.rateUnitGuid
                    : newProducts.length === 1
                    ? newProducts[0].rateUnitGuid
                    : productMix.targetRateUnitGuid;
                const newTargetRateUnit = mixHasRateDependency
                    ? galPerAcre.label
                    : hadRateDependency
                    ? product.rateUnit
                    : newProducts.length === 1
                    ? newProducts[0].rateUnit
                    : productMix.targetRateUnit;

                const newTargetCostUnitGuid = blending.getEffectiveCostUnitGuid(
                    productMix,
                    newProducts
                );

                const newMixLimeEfficiency =
                    !isEquation || !isLime || !newProps.limeEfficiency
                        ? {}
                        : { limeEfficiency: newProps.limeEfficiency };
                const retainSummaryData =
                    mixType === ProductMixTypes.CHEMICAL && newProps.rateUnitGuid;

                this._updateProductMix(
                    {
                        ...this._calculateTotals(
                            newProducts,
                            hasRateDependency ? galPerAcre.value : newTargetRateUnitGuid,
                            newTargetCostUnitGuid,
                            {},
                            true,
                            retainSummaryData
                        ),
                        ...newMixLimeEfficiency,
                        targetRateUnitGuid: newTargetRateUnitGuid,
                        targetRateUnit: newTargetRateUnit,
                        targetCostUnitGuid: newTargetCostUnitGuid,
                    },
                    true
                );
            }
        }
    }

    _updateProductMixDebounce = debounce((newProps, isProductUpdate, strValue) => {
        this._updateProductMix(newProps, isProductUpdate, strValue);
    }, 700);

    _updateProductMix(newProps: Record<string, any>, isProductUpdate = false, strValue = ""): void {
        if (strValue === "0." || strValue === "0") {
            return;
        }

        const {
            isEquation,
            isEquationComplete,
            onUpdate,
            onUpdateRecNutrient,
            productMix,
            recNutrient,
            isProcessingRecNutrient,
        } = this.props;

        const { isBlendingActive } = this.state;

        if (isProcessingRecNutrient && isEquationComplete) {
            return;
        }

        // In import(ed) situations, changes are *not* allowed to the rate information
        // (outside of the custom blend modal, which will correct back on save)
        if (
            productMix.isImport &&
            !isBlendingActive &&
            Number(productMix.actualRate) !== 0 &&
            newProps.targetRate != null
        ) {
            newProps.targetRate = productMix.actualRate;
        }

        const products = newProps.products || productMix.products;
        const mixType = productMix.productMixType.toLowerCase();
        const targetCostUnitGuid = blending.getEffectiveCostUnitGuid(productMix, products);

        this.setState(
            {
                pendingCodes: [],
            },
            () => {
                if (blending.getIsChanged(newProps, productMix)) {
                    if (
                        newProps.isLockProductRatios == null &&
                        newProps.isLockGuaranteedAnalysis == null &&
                        productMix.productMixTypeGuid
                    ) {
                        let summaryCalculations = this._calculateTotals(
                            products,
                            newProps.targetRateUnitGuid || productMix.targetRateUnitGuid,
                            targetCostUnitGuid,
                            productMix.isLockGuaranteedAnalysis ||
                                productMix.isLockProductRatios ||
                                newProps.totalCost ||
                                (mixType === ProductMixTypes.CHEMICAL &&
                                    (newProps.targetRate || newProps.totalProduct)) ||
                                newProps.isCarrier != null ||
                                !isBlendingActive ||
                                (isBlendingActive &&
                                    (newProps.totalProduct ||
                                        newProps.costPerAcre ||
                                        newProps.totalCost))
                                ? newProps
                                : {},
                            Boolean(
                                !isProductUpdate &&
                                    (mixType !== ProductMixTypes.CHEMICAL ||
                                        newProps.totalCost ||
                                        newProps.totalProduct ||
                                        productMix.isLockGuaranteedAnalysis ||
                                        productMix.isLockProductRatios) &&
                                    (!isBlendingActive ||
                                        (isBlendingActive &&
                                            (newProps.totalProduct ||
                                                newProps.costPerAcre ||
                                                newProps.totalCost)))
                            )
                        );

                        if (
                            productMix.isImport &&
                            !isBlendingActive &&
                            Number(productMix.actualRate) !== 0 &&
                            summaryCalculations
                        ) {
                            // May need to recalculate the totals back to the fixed target rate (mostly for load blend, where the products already have rates)
                            summaryCalculations = this._calculateTotals(
                                summaryCalculations.products,
                                productMix.targetRateUnitGuid || newProps.targetRateUnitGuid,
                                targetCostUnitGuid,
                                { targetRate: +productMix.actualRate },
                                true,
                                false,
                                {
                                    ...this.props,
                                    productMix: {
                                        ...productMix,
                                        ...newProps,
                                        ...summaryCalculations,
                                        targetCostUnitGuid,
                                    },
                                }
                            );
                        }
                        let newActualRate = productMix.actualRate;
                        if (
                            productMix.isImport &&
                            Number(productMix.actualRate) === 0 &&
                            newProps.targetRate
                        ) {
                            //for case of imported Application Event with zero rates where the user has adjusted the targetRate
                            newActualRate = newProps.targetRate;
                        }

                        let { productBlendGuid, productBlendName } = productMix;
                        if (newProps.productBlendGuid) {
                            productBlendGuid = newProps.productBlendGuid;
                            productBlendName = newProps.productBlendName;
                        } else if (
                            productMix.isImport &&
                            newProps.guaranteedAnalysis &&
                            newProps.guaranteedAnalysis !== productMix.guaranteedAnalysis &&
                            productBlendGuid
                        ) {
                            // For imported Application Events, when product blend is set for a mix
                            // reset it for any update that changes the guaranteed analysis
                            productBlendGuid = null;
                            productBlendName = null;
                        }

                        const newProductMix = {
                            ...productMix,
                            ...newProps,
                            ...summaryCalculations,
                            avgApplicationRate: newActualRate,
                            productBlendGuid,
                            productBlendName,
                        };

                        const hasProductsChanged =
                            JSON.stringify(
                                newProductMix.products.map(
                                    (product) => product.productGuid || product.customProductGuid
                                )
                            ) !==
                            JSON.stringify(
                                productMix.products.map(
                                    (product) => product.productGuid || product.customProductGuid
                                )
                            );

                        const defaultAdjustmentParameterUpdate =
                            !isEquation || !hasProductsChanged || isEquationComplete
                                ? {}
                                : {
                                      minimumRate: null,
                                      maximumRate: null,
                                      minimumLock: null,
                                      maximumLock: null,
                                      minimumExcludeZeros: true,
                                      minimumIncludeZeros: false,
                                      zerosBelowMinimum: false,
                                      zerosBelowSwitchRate: false,
                                  };

                        if (
                            !isEquation ||
                            (!blending.getIsProductMixInitialized(productMix) &&
                                productMix.products.length !== 0) ||
                            (!newProps.targetRate &&
                                !newProps.costPerAcre &&
                                !newProps.totalProduct &&
                                !newProps.totalCost &&
                                !newProps.targetRateUnitGuid)
                        ) {
                            if (
                                blending.getIsProductMixInitialized(newProductMix) ||
                                newProductMix.products.length === 0
                            ) {
                                onUpdate({
                                    ...newProductMix,
                                    ...blending.getProductMixUnits(
                                        newProductMix,
                                        newProductMix.products,
                                        this.props
                                    ),
                                });
                            }
                        } else if (isEquation) {
                            const minimumIncludeZeros =
                                recNutrient.recNutrientParameters.minimumIncludeZeros;

                            const rateUnit = blending.getRateUnit(
                                productMix.targetRateUnitGuid,
                                this.props
                            );
                            const rateUnitChanged =
                                productMix.targetRateUnitGuid &&
                                newProductMix.targetRateUnitGuid &&
                                productMix.targetRateUnitGuid !== newProductMix.targetRateUnitGuid;
                            const newTargetRateToOldTargetRateConversionFactor = !rateUnitChanged
                                ? 1
                                : blending.getProductRateConversionFactor(
                                      newProductMix.targetRateUnitGuid,
                                      blending.getPriceUnitFromRateUnit(rateUnit, this.props).value,
                                      productMix.density,
                                      productMix.targetRate,
                                      this.props
                                  );

                            const fieldRate =
                                recNutrient.averageCreditRecNutrientResult.productRate /
                                newTargetRateToOldTargetRateConversionFactor;

                            const effectiveFieldRate =
                                blending.isZeroRec(recNutrient) && minimumIncludeZeros
                                    ? newProductMix.targetRate
                                    : blending.roundValue(fieldRate, 2);

                            if (this.state.isModalInitialized || this.state.isModalInitializing) {
                                const isMinLock = recNutrient.recNutrientParameters.minimumLock;
                                const isMaxLock = recNutrient.recNutrientParameters.maximumLock;
                                const minRate =
                                    recNutrient.recNutrientParameters.minimumRate /
                                    newTargetRateToOldTargetRateConversionFactor;
                                const maxRate =
                                    recNutrient.recNutrientParameters.maximumRate /
                                    newTargetRateToOldTargetRateConversionFactor;
                                const mustDecrease = isMaxLock && +effectiveFieldRate > maxRate;
                                const mustIncrease = isMinLock && +effectiveFieldRate < minRate;

                                const hardMinimumAdjustPercent = minRate / +effectiveFieldRate;
                                const hardMaximumAdjustPercent = maxRate / +effectiveFieldRate;

                                // If the targetRate isn't actually a different number, use the old one.  It could be that the only difference is rounding, which could affect
                                // the percent adjust calculation.  Basically this protects against things like 84.99 being used instead of 84.994 (which can cause a surprising amount
                                // of trouble).
                                const actualTargetRate = blending.getIsChanged(
                                    { targetRate: newProductMix.targetRate },
                                    { targetRate: productMix.targetRate }
                                )
                                    ? newProductMix.targetRate
                                    : productMix.targetRate;
                                const effectiveTargetRate =
                                    hasProductsChanged && mustIncrease // It's an equation rec and the products changed, so this is just to get it past validation enough to get to addUpdateRecProduct (which will get the real numbers)
                                        ? +actualTargetRate * hardMinimumAdjustPercent
                                        : hasProductsChanged && mustDecrease
                                        ? +actualTargetRate * hardMaximumAdjustPercent
                                        : actualTargetRate;
                                const newAdjustPercent = rateUnitChanged
                                    ? recNutrient.recNutrientParameters.percentAdjustment / 100
                                    : +effectiveTargetRate /
                                      +(blending.isZeroRec(recNutrient)
                                          ? +effectiveTargetRate
                                          : +fieldRate);
                                const recalculateSummary = mustDecrease || mustIncrease;
                                const recNutrientProps = {
                                    percentAdjustment: blending.roundValue(newAdjustPercent * 100),
                                };
                                const newRecNutrient = !blending.isNumeric(newAdjustPercent)
                                    ? recNutrient
                                    : this._adjustRecNutrient(
                                          recNutrientProps,
                                          newProductMix,
                                          recalculateSummary
                                      );
                                if (newRecNutrient) {
                                    const recNutrientUpdate = {
                                        ...newRecNutrient,
                                        recNutrientParameters: {
                                            ...newRecNutrient.recNutrientParameters,
                                            ...defaultAdjustmentParameterUpdate,
                                        },
                                        recNutrientProductMix: {
                                            ...newRecNutrient.recNutrientProductMix,
                                            ...newProductMix,
                                            ...blending.getProductMixUnits(
                                                newProductMix,
                                                newProductMix.products,
                                                this.props
                                            ),
                                        },
                                        unitGuid:
                                            newProps.targetRateUnitGuid ||
                                            productMix.targetRateUnitGuid ||
                                            newRecNutrient.unitGuid,
                                        unitName:
                                            newProps.targetRateUnit ||
                                            productMix.targetRateUnit ||
                                            newRecNutrient.unitName,
                                    };
                                    onUpdateRecNutrient(
                                        recNutrientUpdate,
                                        !(
                                            newProps.targetRate ||
                                            newProps.costPerAcre ||
                                            newProps.totalProduct ||
                                            newProps.totalCost ||
                                            newProps.targetRateUnitGuid
                                        ) ||
                                            (hasProductsChanged && !this.state.isBlendingActive)
                                    );
                                }
                            }
                        }
                    } else {
                        onUpdate({
                            ...productMix,
                            ...newProps,
                            targetCostUnitGuid,
                        });
                    }
                }
            }
        );
    }

    _updateRecNutrient(
        newProps: Record<string, any>,
        updateRecProducts = false,
        props = this.props
    ): void {
        const { recNutrient, onUpdateRecNutrient } = props;
        const newRecNutrient = {
            ...recNutrient,
            ...newProps,
        };
        onUpdateRecNutrient(newRecNutrient, updateRecProducts);
    }

    _updateRecNutrientParameter(newProps: Record<string, any>, props = this.props): void {
        const {
            isEquation,
            isEquationComplete,
            isLime,
            recNutrient,
            onUpdateRecNutrient,
            productMix,
        } = props;
        if (
            this.state.isModalInitializing ||
            !this.state.isModalInitialized ||
            !blending.getIsProductMixInitialized(productMix) ||
            !isEquation
        ) {
            return;
        }

        const hasChanged = blending.getIsChanged(newProps, recNutrient.recNutrientParameters);
        const existingRates = recNutrient.recNutrientParameters;
        if (
            isLime &&
            newProps.limeEfficiency != null &&
            newProps.limeEfficiency !== productMix.limeEfficiency
        ) {
            const newRecNutrient = newProps.limeEfficiency
                ? this._adjustRecNutrient({
                      limeEfficiency: newProps.limeEfficiency,
                  })
                : recNutrient;

            if (newRecNutrient) {
                const recNutrientUpdate = {
                    ...newRecNutrient,
                    recNutrientProductMix: {
                        ...newRecNutrient.recNutrientProductMix,
                        limeEfficiency: newProps.limeEfficiency,
                        products: newRecNutrient.recNutrientProductMix.products.map((product) => {
                            return {
                                ...product,
                                limeEfficiency: newProps.limeEfficiency,
                            };
                        }),
                    },
                };
                onUpdateRecNutrient(recNutrientUpdate, false);
            }
        } else if (
            !isEquationComplete &&
            (newProps.minimumRate === null ||
                newProps.maximumRate === null ||
                newProps.switchRate === null ||
                (existingRates.minimumRate === null && newProps.maximumRate != null) ||
                (existingRates.maximumRate === null && newProps.minimumRate != null))
        ) {
            const newRecNutrient = {
                ...recNutrient,
                recNutrientParameters: {
                    ...recNutrient.recNutrientParameters,
                    minimumRate:
                        newProps.minimumRate !== undefined
                            ? newProps.minimumRate
                            : existingRates.minimumRate,
                    maximumRate:
                        newProps.maximumRate !== undefined
                            ? newProps.maximumRate
                            : existingRates.maximumRate,
                    switchRate:
                        newProps.switchRate !== undefined
                            ? newProps.switchRate
                            : existingRates.switchRate,
                },
            };
            const recNutrientUpdate = { ...newRecNutrient };
            onUpdateRecNutrient(recNutrientUpdate, false);
        } else if (
            hasChanged &&
            (newProps.minimumRate != null ||
                newProps.maximumRate != null ||
                newProps.switchRate != null ||
                newProps.percentAdjustment != null ||
                newProps.minimumExcludeZeros != null ||
                newProps.minimumIncludeZeros != null ||
                newProps.zerosBelowMinimum != null ||
                newProps.zerosBelowSwitchRate != null)
        ) {
            const newRecNutrient = this._adjustRecNutrient(newProps);
            if (newRecNutrient) {
                const recNutrientUpdate = { ...newRecNutrient };
                onUpdateRecNutrient(recNutrientUpdate, false);
            }
        } else if (newProps.minimumLock != null || newProps.maximumLock != null) {
            const recNutrientUpdate = {
                ...recNutrient,
                recNutrientParameters: {
                    ...recNutrient.recNutrientParameters,
                    ...newProps,
                },
            };
            this._clearValidationCodes(recNutrientUpdate);
            onUpdateRecNutrient(recNutrientUpdate, false);
        }
    }
    _updateSelectedCustomProduct(customProductGuid: string = NEW): void {
        const { customProductList } = this.state;
        const selectedProductIndex = customProductList.findIndex(
            (product) => product.customProductGuid === customProductGuid
        );
        this.setState(
            {
                selectedExistingBlendIdx: selectedProductIndex,
            },
            () => {
                this._loadBlend();
            }
        );
    }
    _updateSelectedProduct(selectedProductGuid: string): void {
        this.setState({ selectedProductGuid });
    }

    // Partial Render Methods //
    _getProductSelectionFilter(isSingleProduct = false): JSX.Element {
        const {
            defaultCarrierGuid,
            filteredNutrientProducts,
            filteredProducts,
            isEquation,
            isFromEquationRec,
            nutrientGuid,
            productMix,
            updateProductFilter,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const addProductDisabled =
            !this.state.selectedProductGuid ||
            productMix.isLockGuaranteedAnalysis ||
            productMix.isLockProductRatios ||
            (isSingleProduct && productMix.products.length > 0);
        const productSelectDisabled = isSingleProduct && productMix.products.length > 0;
        const mixType = productMix.productMixType.toLowerCase();
        const isChemical = mixType === ProductMixTypes.CHEMICAL;

        const filteredProductList =
            !isSingleProduct || !isEquation || !nutrientGuid
                ? isChemical && productMix.products.length === 0
                    ? filteredProducts.filter((p) => p.value !== defaultCarrierGuid)
                    : filteredProducts
                : filteredNutrientProducts;

        return (
            <div className="product-selection">
                {isSingleProduct && productMix.products.length > 0 ? null : (
                    <div className="product-filter-bar">
                        {isFromEquationRec ? null : (
                            <NoLink
                                className="product-filters-toggle"
                                label={
                                    this.state.showProductFilters
                                        ? formatMessage(messages.hideFilterText)
                                        : formatMessage(messages.showFilterText)
                                }
                                onClick={() => {
                                    if (!this.state.showProductFilters) {
                                        productMix.actualRate
                                            ? logFirebaseEvent("event_application_show_filters")
                                            : logFirebaseEvent("rec_application_show_filters");
                                    }
                                    this.setState({
                                        showProductFilters: !this.state.showProductFilters,
                                    });
                                }}
                            />
                        )}
                        {!this.state.showProductFilters ? null : (
                            <div className="product-filters">
                                <div className="product-parent-type-filter">
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        value={this.props.productFilters.productParentTypeGuid}
                                        options={
                                            this.props.filteredProductBlendPicklists
                                                .productParentType
                                        }
                                        onChange={(value) => {
                                            updateProductFilter("productParentTypeGuid", value);
                                        }}
                                        placeholderText={formatMessage(
                                            messages.productParentTypeText
                                        )}
                                    />
                                </div>
                                <div className="product-type-filter">
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        value={this.props.productFilters.productTypeGuid}
                                        options={
                                            this.props.filteredProductBlendPicklists.productType
                                        }
                                        onChange={(value) => {
                                            updateProductFilter("productTypeGuid", value);
                                        }}
                                        placeholderText={formatMessage(messages.productTypeText)}
                                    />
                                </div>
                                <div className="product-manufacturer-filter">
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        value={this.props.productFilters.manufacturerGuid}
                                        options={
                                            this.props.filteredProductBlendPicklists.manufacturer
                                        }
                                        onChange={(value) => {
                                            updateProductFilter("manufacturerGuid", value);
                                        }}
                                        placeholderText={formatMessage(messages.manufacturerText)}
                                    />
                                </div>
                                <div className="product-physical-state-filter">
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        value={this.props.productFilters.physicalStateGuid}
                                        options={
                                            this.props.filteredProductBlendPicklists.physicalState
                                        }
                                        onChange={(value) => {
                                            updateProductFilter("physicalStateGuid", value);
                                        }}
                                        placeholderText={formatMessage(messages.physicalStateText)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isSingleProduct &&
                productMix.products.length > 0 &&
                mixType !== ProductMixTypes.CHEMICAL ? null : (
                    <div className="product-add">
                        <div className="product-select-input">
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                value={this.state.selectedProductGuid}
                                options={filteredProductList}
                                disabled={
                                    productMix.isLockGuaranteedAnalysis ||
                                    productMix.isLockProductRatios ||
                                    productSelectDisabled ||
                                    isFromEquationRec
                                }
                                onChange={(value) => {
                                    this._updateSelectedProduct(value);
                                }}
                                placeholderText={formatMessage(messages.productText, { count: 1 })}
                            />
                        </div>
                        <div className="product-add-nolink-section">
                            <div className="product-add-nolink">
                                <NoLink
                                    className={classnames({
                                        disabled: addProductDisabled,
                                    })}
                                    label={formatMessage(messages.addProductText)}
                                    onClick={() => this._addProduct()}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    _getProductBlendTable(disableBlending = false): JSX.Element {
        const {
            productMix,
            availableProducts,
            defaultCarrierGuid,
            productBlendPicklists,
            hideAreaCalculations,
            isEquation,
            isEquationComplete,
            isFromEquationRec,
        } = this.props;
        const { dryRateUnits, liquidRateUnits } = productBlendPicklists;
        const { customProductList, isBlendingActive } = this.state;
        const { formatMessage, formatNumber } = this.props.intl;
        const { isLockGuaranteedAnalysis, isLockProductRatios, isImport } = productMix;

        const effectiveCostUnitGuid = blending.getEffectiveCostUnitGuid(
            productMix,
            productMix.products
        );
        const effectiveCostUnitItem = blending.getPriceUnit(effectiveCostUnitGuid, this.props);
        const effectiveCostUnit = effectiveCostUnitItem ? effectiveCostUnitItem.label : "";

        const productRatiosLocked = isLockProductRatios || isLockGuaranteedAnalysis;
        const mixType = productMix.productMixType.toLowerCase();

        const filteredDryRateUnits =
            productMix.products.length !== 1
                ? dryRateUnits
                : dryRateUnits.filter((unit) => !unit.label.toLowerCase().includes(PER100GAL));
        const filteredLiquidRateUnits =
            productMix.products.length !== 1
                ? liquidRateUnits
                : liquidRateUnits.filter((unit) => !unit.label.toLowerCase().includes(PER100GAL));

        const isManure = mixType === ProductMixTypes.MANURE;
        const mixPhysicalState = productMix.targetRateUnitGuid
            ? blending.getRateUnitPhysicalState(productMix.targetRateUnitGuid, this.props)
            : null;

        const blendTableClass =
            !isBlendingActive && mixType !== ProductMixTypes.CHEMICAL
                ? "equation-product-blend-table"
                : null;

        const blendTableMargin = isEquation ? null : "product-blend-header-margin";

        return productMix.products.length === 0 ? (
            <div className="product-blend-table"></div>
        ) : (
            <div className={`product-blend-table ${blendTableClass}`}>
                <div className={`product-blend-header ${blendTableMargin} product-blend-item`}>
                    <div className="product-blend-column xsmall">{/* empty */}</div>
                    <div className="product-blend-column xxxlarge">
                        {formatMessage(messages.productText, {
                            count: productMix.products.length,
                        })}
                    </div>
                    {isImport ? (
                        <div className="product-blend-column small">
                            {formatMessage(messages.averageRateText)}
                        </div>
                    ) : (
                        <div className="product-blend-column small">
                            {formatMessage(messages.rateText)}
                        </div>
                    )}
                    <div className="product-blend-column med">
                        {formatMessage(messages.unitText)}
                    </div>
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column small">
                            {formatMessage(messages.priceText)}
                        </div>
                    )}
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column med">
                            {formatMessage(messages.unitText)}
                        </div>
                    )}
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column med">
                            {formatMessage(messages.costPerAcreText)}
                        </div>
                    )}
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column med">
                            {formatMessage(messages.totalProductText, {
                                unit: effectiveCostUnit,
                            })}
                        </div>
                    )}
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column med">
                            {formatMessage(messages.totalCostText)}
                        </div>
                    )}
                </div>
                <div className="product-blend-body">
                    {productMix.products.map((product, index) => {
                        const lineProductList =
                            mixType !== ProductMixTypes.MANURE
                                ? availableProducts
                                      .filter((productOption) => {
                                          return (
                                              product.productGuid === productOption.productGuid ||
                                              !(
                                                  productMix.products.some(
                                                      (mixProduct) =>
                                                          mixProduct.productGuid ===
                                                          productOption.productGuid
                                                  ) || product.productGuid === defaultCarrierGuid
                                              )
                                          );
                                      })
                                      .map((productOption) => {
                                          return {
                                              label: productOption.name,
                                              value: productOption.productGuid,
                                          };
                                      })
                                : customProductList.map((product) => {
                                      return {
                                          label: `${product.guaranteedAnalysis} (${product.physicalState})`,
                                          value: product.customProductGuid,
                                      };
                                  });
                        const importRateLocked =
                            isImport &&
                            !isBlendingActive &&
                            Number(productMix.actualRate) !== 0 &&
                            productMix.products.length === 1;

                        const isServiceProduct = blending.getIsServiceProduct(
                            availableProducts,
                            product.productGuid
                        );

                        return product.isCarrier && mixType === ProductMixTypes.CHEMICAL ? null : (
                            <div key={`product-${index}`} className="product-blend-item">
                                <div className="product-blend-column xsmall">
                                    {productRatiosLocked ||
                                    isFromEquationRec ||
                                    disableBlending ? null : (
                                        <div
                                            className="remove-product-icon"
                                            onClick={() => this._removeProduct(index)}
                                        >
                                            <TrashcanIcon />
                                        </div>
                                    )}
                                </div>
                                <div className="product-blend-column xxxlarge">
                                    {productRatiosLocked ||
                                    isFromEquationRec ||
                                    (productMix.products.length !== 1 &&
                                        !this.state.isBlendingActive &&
                                        mixType !== ProductMixTypes.CHEMICAL) ? (
                                        <div className="trim-overflow">
                                            {product.productName || product.customProductName}
                                        </div>
                                    ) : (
                                        <SelectInput
                                            optionIsHiddenKey={ACTIVE_YN}
                                            initialFilterStr={
                                                product.productName || product.customProductName
                                            }
                                            value={product.productGuid || product.customProductGuid}
                                            clearable={false}
                                            options={lineProductList}
                                            onChange={(value) => {
                                                mixType !== ProductMixTypes.MANURE
                                                    ? this._updateProduct(index, {
                                                          productGuid: value,
                                                      })
                                                    : this._updateProduct(index, {
                                                          customProductGuid: value,
                                                      });
                                            }}
                                            showTopLabel={false}
                                        />
                                    )}
                                </div>
                                <div className="product-blend-column small">
                                    {isServiceProduct ? null : productRatiosLocked ||
                                      importRateLocked ||
                                      isFromEquationRec ||
                                      disableBlending ? (
                                        <div title={product.rate?.toString()}>
                                            {formatNumber(
                                                product.rate,
                                                intlConfig.numberFormatOptions
                                            )}
                                        </div>
                                    ) : (
                                        <NumericInput
                                            scale={2}
                                            precision={11}
                                            onChange={(strValue, fmtValue, numValue) =>
                                                this._updateProduct(
                                                    index,
                                                    { rate: numValue },
                                                    strValue
                                                )
                                            }
                                            value={product.rate}
                                            showTopLabel={false}
                                        />
                                    )}
                                </div>
                                <div className="product-blend-column med">
                                    {isServiceProduct ? null : productRatiosLocked ||
                                      importRateLocked ||
                                      isFromEquationRec ||
                                      disableBlending ? (
                                        <div>{product.rateUnit}</div>
                                    ) : (
                                        <SelectInput
                                            optionIsHiddenKey={ACTIVE_YN}
                                            initialFilterStr={product.rateUnit}
                                            value={product.rateUnitGuid}
                                            clearable={false}
                                            options={
                                                (isManure
                                                    ? mixPhysicalState
                                                    : product.physicalState &&
                                                      product.physicalState.toLowerCase()) ===
                                                PhysicalStates.LIQUID
                                                    ? filteredLiquidRateUnits
                                                    : filteredDryRateUnits
                                            }
                                            onChange={(value) => {
                                                const options =
                                                    (isManure
                                                        ? mixPhysicalState
                                                        : product.physicalState &&
                                                          product.physicalState.toLowerCase()) ===
                                                    PhysicalStates.LIQUID
                                                        ? filteredLiquidRateUnits
                                                        : filteredDryRateUnits;
                                                const rateUnit = options.find(
                                                    (option) => option.value === value
                                                );
                                                const newProps = {
                                                    rateUnit: rateUnit.label,
                                                    rateUnitGuid: value,
                                                };
                                                this._updateProduct(index, newProps);
                                            }}
                                            showTopLabel={false}
                                        />
                                    )}
                                </div>
                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column small">
                                        {productRatiosLocked ? (
                                            <div title={product.cost?.toString()}>
                                                {formatNumber(
                                                    product.cost,
                                                    intlConfig.currencyFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateProduct(
                                                        index,
                                                        { cost: numValue },
                                                        strValue
                                                    )
                                                }
                                                value={product.cost}
                                                showTopLabel={false}
                                            />
                                        )}
                                    </div>
                                )}

                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column med">
                                        {productRatiosLocked && !isServiceProduct ? (
                                            <div>{product.costUnit}</div>
                                        ) : (
                                            <SelectInput
                                                optionIsHiddenKey={ACTIVE_YN}
                                                initialFilterStr={product.costUnit}
                                                value={product.costUnitGuid}
                                                clearable={false}
                                                options={
                                                    isServiceProduct
                                                        ? productBlendPicklists.servicePriceUnits
                                                        : (isManure
                                                              ? mixPhysicalState
                                                              : product.physicalState &&
                                                                product.physicalState.toLowerCase()) ===
                                                          PhysicalStates.LIQUID
                                                        ? productBlendPicklists.liquidPriceUnits
                                                        : productBlendPicklists.dryPriceUnits
                                                }
                                                onChange={(value) => {
                                                    const costUnit = blending.getPriceUnit(
                                                        value,
                                                        this.props
                                                    );
                                                    const newProps = {
                                                        costUnit: costUnit.label,
                                                        costUnitGuid: costUnit.value,
                                                    };
                                                    this._updateProduct(index, newProps);
                                                }}
                                                showTopLabel={false}
                                            />
                                        )}
                                    </div>
                                )}

                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column med">
                                        {isEquation &&
                                        !isEquationComplete ? null : productRatiosLocked ||
                                          (isImport && productMix.products.length === 1) ||
                                          isFromEquationRec ? (
                                            <div title={product.costPerAcre?.toString()}>
                                                {formatNumber(
                                                    product.costPerAcre,
                                                    intlConfig.currencyFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) => {
                                                    if (strValue && product.cost > 0) {
                                                        numValue > 0
                                                            ? this._updateProductDebounce(
                                                                  index,
                                                                  {
                                                                      costPerAcre: numValue,
                                                                  },
                                                                  strValue
                                                              )
                                                            : this._updateProductDebounce(
                                                                  index,
                                                                  { rate: 0 },
                                                                  strValue
                                                              );
                                                    }
                                                }}
                                                onBlur={(evt) => {
                                                    evt.target.value === "" &&
                                                        this._updateProduct(index, { rate: 0 }, "");
                                                }}
                                                value={product.costPerAcre}
                                                showTopLabel={false}
                                                disabled={product.cost === 0}
                                            />
                                        )}
                                    </div>
                                )}

                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column med">
                                        {isEquation &&
                                        !isEquationComplete ? null : productRatiosLocked ||
                                          (isImport && productMix.products.length === 1) ||
                                          isFromEquationRec ? (
                                            <div title={product.totalProduct?.toString()}>
                                                {formatNumber(
                                                    product.totalProduct,
                                                    intlConfig.numberFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateProduct(
                                                        index,
                                                        {
                                                            totalProduct: numValue,
                                                        },
                                                        strValue
                                                    )
                                                }
                                                value={product.totalProduct}
                                                showTopLabel={false}
                                            />
                                        )}
                                    </div>
                                )}

                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column med">
                                        {isEquation &&
                                        !isEquationComplete ? null : productRatiosLocked ||
                                          (isImport && productMix.products.length === 1) ||
                                          isFromEquationRec ? (
                                            <div title={product.totalCost?.toString()}>
                                                {formatNumber(
                                                    product.totalCost,
                                                    intlConfig.currencyFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) => {
                                                    if (strValue && product.cost > 0) {
                                                        numValue > 0
                                                            ? this._updateProductDebounce(
                                                                  index,
                                                                  {
                                                                      totalCost: numValue,
                                                                  },
                                                                  strValue
                                                              )
                                                            : this._updateProductDebounce(
                                                                  index,
                                                                  { rate: 0 },
                                                                  strValue
                                                              );
                                                    }
                                                }}
                                                onBlur={(evt) => {
                                                    evt.target.value === "" &&
                                                        this._updateProduct(index, { rate: 0 }, "");
                                                }}
                                                value={product.totalCost}
                                                showTopLabel={false}
                                                disabled={product.cost === 0}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {mixType === ProductMixTypes.CHEMICAL ? this._getCarrierSection() : null}
                    {this._getProductBlendSummary(isEquation || productMix.products.length !== 1)}
                </div>
            </div>
        );
    }

    _getEquationProductBlendTable(): JSX.Element {
        const {
            availableProducts,
            filteredNutrientProducts,
            recNutrient,
            productMix,
            productBlendPicklists,
            hideAreaCalculations,
            isEquation,
            isEquationComplete,
            calculatedArea,
            isLime,
            nutrientGuid,
        } = this.props;

        const {
            averageCreditRecNutrientResult,
            averageAdjustedRecNutrientResult,
            averageRecNutrientResult,
            recNutrientParameters,
        } = recNutrient;
        const { dryRateUnits, liquidRateUnits } = productBlendPicklists;
        const { customProductList, isResetRecNutrient } = this.state;
        const { formatMessage, formatNumber } = this.props.intl;
        const { isLockGuaranteedAnalysis, isLockProductRatios } = productMix;

        const effectiveCostUnitGuid = blending.getEffectiveCostUnitGuid(
            productMix,
            productMix.products
        );
        const effectiveCostUnitItem = blending.getPriceUnit(effectiveCostUnitGuid, this.props);
        const effectiveCostUnit = effectiveCostUnitItem ? effectiveCostUnitItem.label : "";

        const originalRateToCostUnitConversion = blending.getProductRateConversionFactor(
            recNutrient.unitGuid,
            effectiveCostUnitGuid,
            productMix.density,
            productMix.targetRate,
            this.props
        );
        const minimumIncludeZeros = recNutrient.recNutrientParameters.minimumIncludeZeros;
        const appliedAvgRate =
            recNutrient.averageAdjustedRecNutrientResult.appliedProductRate != null
                ? recNutrient.averageAdjustedRecNutrientResult.appliedProductRate
                : recNutrient.averageCreditRecNutrientResult.appliedProductRate != null
                ? recNutrient.averageCreditRecNutrientResult.appliedProductRate
                : recNutrient.averageRecNutrientResult.appliedProductRate;
        const fieldRate = recNutrient.averageCreditRecNutrientResult.productRate;

        const originalTotalProduct = fieldRate * originalRateToCostUnitConversion * calculatedArea;
        const originalTotalCost = productMix.targetCost * originalTotalProduct;
        const originalPricePerAcre = originalTotalCost / calculatedArea;

        const productRatiosLocked =
            isLockProductRatios ||
            isLockGuaranteedAnalysis ||
            (isEquation && productMix.products.length > 1);
        const mixType = productMix.productMixType.toLowerCase();
        const recNutrientUnit = blending.getRateUnit(recNutrient.unitGuid, this.props);

        const filteredDryRateUnits =
            productMix.products.length !== 1
                ? dryRateUnits
                : dryRateUnits.filter((unit) => !unit.label.toLowerCase().includes(PER100GAL));
        const filteredLiquidRateUnits =
            productMix.products.length !== 1
                ? liquidRateUnits
                : liquidRateUnits.filter((unit) => !unit.label.toLowerCase().includes(PER100GAL));

        const isManure = mixType === ProductMixTypes.MANURE;
        const mixPhysicalState = productMix.targetRateUnitGuid
            ? blending.getRateUnitPhysicalState(productMix.targetRateUnitGuid, this.props)
            : null;

        return productMix.products.length === 0 || !isEquation || isResetRecNutrient ? (
            <div className="product-blend-table"></div>
        ) : (
            <div className="product-blend-table equation-product-blend-table">
                <div className="product-blend-header product-blend-item">
                    <div className="product-blend-column xsmall">{/* empty */}</div>
                    <div className="product-blend-column xxlarge">
                        {formatMessage(messages.originalProductRecText)}
                    </div>
                    <div className="product-blend-column small">
                        {formatNumber(fieldRate || 0, intlConfig.numberFormatOptions)}
                    </div>
                    <div className="product-blend-column med">
                        {recNutrientUnit ? recNutrientUnit.label : ""}
                    </div>
                    <div className="product-blend-column small"></div>
                    <div className="product-blend-column small"></div>
                    <div className="product-blend-column small">
                        $
                        {formatNumber(
                            blending.roundValue(originalPricePerAcre) || 0,
                            intlConfig.numberFormatOptions
                        )}
                    </div>
                    <div className="product-blend-column med">
                        {formatNumber(
                            blending.roundValue(originalTotalProduct) || 0,
                            intlConfig.numberFormatOptions
                        )}
                    </div>
                    <div className="product-blend-column med">
                        $
                        {formatNumber(
                            blending.roundValue(originalTotalCost) || 0,
                            intlConfig.numberFormatOptions
                        )}
                    </div>
                    <div className="product-blend-column small">
                        {formatNumber(
                            averageCreditRecNutrientResult.minRate ||
                                averageAdjustedRecNutrientResult.minRate ||
                                averageRecNutrientResult.minRate ||
                                0,
                            intlConfig.numberFormatOptions
                        )}
                    </div>
                    <div className="product-blend-column small">
                        {formatNumber(
                            averageCreditRecNutrientResult.maxRate ||
                                averageAdjustedRecNutrientResult.maxRate ||
                                averageRecNutrientResult.maxRate ||
                                0,
                            intlConfig.numberFormatOptions
                        )}
                    </div>
                    <div className="product-blend-column small"></div>
                    {!isLime ? null : <div className="product-blend-column small"></div>}
                </div>
                <div className="product-blend-header product-blend-item">
                    <div className="product-blend-column xsmall">{/* empty */}</div>
                    <div className="product-blend-column xxlarge">
                        {formatMessage(messages.productText, {
                            count: productMix.products.length,
                        })}
                    </div>
                    <div className="product-blend-column small">
                        {formatMessage(messages.averageRateText)}
                    </div>
                    <div className="product-blend-column med">
                        {formatMessage(messages.unitText)}
                    </div>
                    <div className="product-blend-column small">
                        {formatMessage(messages.priceText)}
                    </div>
                    <div className="product-blend-column small">
                        {formatMessage(messages.unitText)}
                    </div>
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column small">
                            {formatMessage(messages.costPerAcreText)}
                        </div>
                    )}
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column med">
                            {formatMessage(messages.totalProductText, {
                                unit: effectiveCostUnit,
                            })}
                        </div>
                    )}
                    {hideAreaCalculations ? null : (
                        <div className="product-blend-column med">
                            {formatMessage(messages.totalCostText)}
                        </div>
                    )}
                    <div className="product-blend-column small">
                        {formatMessage(messages.minRateText)}
                    </div>
                    <div className="product-blend-column small">
                        {formatMessage(messages.maxRateText)}
                    </div>
                    <div className="product-blend-column small">
                        {formatMessage(messages.adjustPercentText)}
                    </div>
                    {!isLime ? null : (
                        <div className="product-blend-column small">
                            {formatMessage(messages.effectivePercentText)}
                        </div>
                    )}
                </div>
                <div className="product-blend-body">
                    {productMix.products.map((product, index) => {
                        const lineProductList =
                            mixType !== ProductMixTypes.MANURE
                                ? [
                                      {
                                          value: product.productGuid,
                                          label: product.productName,
                                      },
                                      ...filteredNutrientProducts,
                                  ]
                                : customProductList.map((product) => {
                                      return {
                                          label: `${product.guaranteedAnalysis} (${product.physicalState})`,
                                          value: product.customProductGuid,
                                      };
                                  });

                        const isServiceProduct = blending.getIsServiceProduct(
                            availableProducts,
                            product.productGuid
                        );

                        const isLimeProduct = Boolean(
                            isLime &&
                                +(product.nutrientList || product.nutrients || []).some(
                                    (nutrient) => nutrient.nutrientGuid === nutrientGuid
                                )
                        );

                        return (
                            <div key={`product-${index}`} className="product-blend-item">
                                <div className="product-blend-column xsmall">
                                    {productRatiosLocked ||
                                    productMix.products.length > 1 ? null : (
                                        <div
                                            className="remove-product-icon"
                                            onClick={() => this._removeProduct(index)}
                                        >
                                            <TrashcanIcon />
                                        </div>
                                    )}
                                </div>
                                <div className="product-blend-column xxlarge">
                                    {productRatiosLocked || productMix.products.length > 1 ? (
                                        <div
                                            className="tall trim-overflow"
                                            title={product.productName || product.customProductName}
                                        >
                                            {product.productName || product.customProductName}
                                        </div>
                                    ) : (
                                        <SelectInput
                                            optionIsHiddenKey={ACTIVE_YN}
                                            initialFilterStr={
                                                product.productName || product.customProductName
                                            }
                                            value={product.productGuid || product.customProductGuid}
                                            clearable={false}
                                            options={lineProductList}
                                            onChange={(value) => {
                                                mixType !== ProductMixTypes.MANURE
                                                    ? this._updateProduct(index, {
                                                          productGuid: value,
                                                      })
                                                    : this._updateProduct(index, {
                                                          customProductGuid: value,
                                                      });
                                            }}
                                            showTopLabel={false}
                                        />
                                    )}
                                </div>
                                <div className="product-blend-column small">
                                    {isServiceProduct ? null : productRatiosLocked ||
                                      !isEquationComplete ? (
                                        <div className="tall" title={product.rate?.toString()}>
                                            {formatNumber(
                                                blending.isNumeric(product.rate) ? product.rate : 0,
                                                intlConfig.numberFormatOptions
                                            )}
                                        </div>
                                    ) : (
                                        <NumericInput
                                            scale={2}
                                            precision={11}
                                            disabled={
                                                blending.isZeroRec(recNutrient) &&
                                                !minimumIncludeZeros
                                            }
                                            onChange={(strValue, fmtValue, numValue) =>
                                                this._updateProduct(
                                                    index,
                                                    { rate: numValue },
                                                    strValue
                                                )
                                            }
                                            onBlur={() => this._checkPendingCodes()}
                                            value={product.rate}
                                            showTopLabel={false}
                                        />
                                    )}
                                </div>
                                <div className="product-blend-column med">
                                    {productMix.products.length > 1 ? (
                                        <div className="tall">{product.rateUnit}</div>
                                    ) : (
                                        <SelectInput
                                            optionIsHiddenKey={ACTIVE_YN}
                                            initialFilterStr={product.rateUnit}
                                            value={product.rateUnitGuid}
                                            clearable={false}
                                            options={
                                                (isManure
                                                    ? mixPhysicalState
                                                    : product.physicalState &&
                                                      product.physicalState.toLowerCase()) ===
                                                PhysicalStates.LIQUID
                                                    ? filteredLiquidRateUnits
                                                    : filteredDryRateUnits
                                            }
                                            onChange={(value) => {
                                                const options =
                                                    (isManure
                                                        ? mixPhysicalState
                                                        : product.physicalState &&
                                                          product.physicalState.toLowerCase()) ===
                                                    PhysicalStates.LIQUID
                                                        ? filteredLiquidRateUnits
                                                        : filteredDryRateUnits;
                                                const rateUnit = options.find(
                                                    (option) => option.value === value
                                                );
                                                const newProps = {
                                                    rateUnit: rateUnit.label,
                                                    rateUnitGuid: value,
                                                };
                                                this._updateProduct(index, newProps);
                                            }}
                                            showTopLabel={false}
                                        />
                                    )}
                                </div>
                                <div className="product-blend-column small">
                                    <NumericInput
                                        scale={2}
                                        precision={11}
                                        onChange={(strValue, fmtValue, numValue) =>
                                            this._updateProduct(index, { cost: numValue }, strValue)
                                        }
                                        value={product.cost}
                                        showTopLabel={false}
                                    />
                                </div>
                                <div className="product-blend-column small">
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        initialFilterStr={product.costUnit}
                                        value={product.costUnitGuid}
                                        clearable={false}
                                        options={
                                            isServiceProduct
                                                ? productBlendPicklists.servicePriceUnits
                                                : (isManure
                                                      ? mixPhysicalState
                                                      : product.physicalState &&
                                                        product.physicalState.toLowerCase()) ===
                                                  PhysicalStates.LIQUID
                                                ? productBlendPicklists.liquidPriceUnits
                                                : productBlendPicklists.dryPriceUnits
                                        }
                                        onChange={(value) => {
                                            const options = isServiceProduct
                                                ? productBlendPicklists.servicePriceUnits
                                                : (isManure
                                                      ? mixPhysicalState
                                                      : product.physicalState &&
                                                        product.physicalState.toLowerCase()) ===
                                                  PhysicalStates.LIQUID
                                                ? productBlendPicklists.liquidPriceUnits
                                                : productBlendPicklists.dryPriceUnits;
                                            const costUnit = options.find(
                                                (option) => option.value === value
                                            );
                                            const newProps = {
                                                costUnit: costUnit.label,
                                                costUnitGuid: value,
                                            };
                                            this._updateProduct(index, newProps);
                                        }}
                                        showTopLabel={false}
                                    />
                                </div>
                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column small">
                                        {!isEquationComplete ? null : productRatiosLocked ? (
                                            <div
                                                className="tall"
                                                title={product.costPerAcre?.toString()}
                                            >
                                                {formatNumber(
                                                    product.costPerAcre,
                                                    intlConfig.currencyFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) => {
                                                    if (strValue && product.cost > 0) {
                                                        numValue > 0
                                                            ? this._updateProductDebounce(
                                                                  index,
                                                                  {
                                                                      costPerAcre: numValue,
                                                                  },
                                                                  strValue
                                                              )
                                                            : this._updateProductDebounce(
                                                                  index,
                                                                  { rate: 0 },
                                                                  strValue
                                                              );
                                                    }
                                                }}
                                                onBlur={(evt) => {
                                                    evt.target.value === "" &&
                                                        this._updateProduct(index, { rate: 0 }, "");
                                                    this._checkPendingCodes();
                                                }}
                                                value={product.costPerAcre}
                                                showTopLabel={false}
                                                disabled={
                                                    product.cost === 0 ||
                                                    (blending.isZeroRec(recNutrient) &&
                                                        !minimumIncludeZeros)
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column med">
                                        {!isEquationComplete ? null : productRatiosLocked ? (
                                            <div
                                                className="tall"
                                                title={product.totalProduct?.toString()}
                                            >
                                                {formatNumber(
                                                    product.totalProduct,
                                                    intlConfig.numberFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                disabled={
                                                    blending.isZeroRec(recNutrient) &&
                                                    !minimumIncludeZeros
                                                }
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateProduct(
                                                        index,
                                                        {
                                                            totalProduct: numValue,
                                                        },
                                                        strValue
                                                    )
                                                }
                                                onBlur={() => this._checkPendingCodes()}
                                                value={product.totalProduct}
                                                showTopLabel={false}
                                            />
                                        )}
                                    </div>
                                )}
                                {hideAreaCalculations ? null : (
                                    <div className="product-blend-column med">
                                        {!isEquationComplete ? null : productRatiosLocked ? (
                                            <div
                                                className="tall"
                                                title={product.totalCost?.toString()}
                                            >
                                                {formatNumber(
                                                    product.totalCost,
                                                    intlConfig.currencyFormatOptions
                                                )}
                                            </div>
                                        ) : (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) => {
                                                    if (strValue && product.cost > 0) {
                                                        numValue > 0
                                                            ? this._updateProductDebounce(
                                                                  index,
                                                                  {
                                                                      totalCost: numValue,
                                                                  },
                                                                  strValue
                                                              )
                                                            : this._updateProductDebounce(
                                                                  index,
                                                                  { rate: 0 },
                                                                  strValue
                                                              );
                                                    }
                                                }}
                                                onBlur={(evt) => {
                                                    evt.target.value === "" &&
                                                        this._updateProduct(index, { rate: 0 }, "");
                                                    this._checkPendingCodes();
                                                }}
                                                value={product.totalCost}
                                                showTopLabel={false}
                                                disabled={
                                                    product.cost === 0 ||
                                                    (blending.isZeroRec(recNutrient) &&
                                                        !minimumIncludeZeros)
                                                }
                                            />
                                        )}
                                    </div>
                                )}

                                <div className="product-blend-column small">
                                    {productMix.products.length > 1 ? null : (
                                        <div className="min-rate-input">
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                disabled={
                                                    recNutrient.recNutrientParameters.minimumLock ||
                                                    blending.isZeroRec(recNutrient) ||
                                                    (recNutrient?.recNutrientProductMix
                                                        ?.targetRate === 0 &&
                                                        recNutrient?.recNutrientProductMix
                                                            ?.guaranteedAnalysis !== "0-0-0" &&
                                                        isEquationComplete)
                                                }
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateRecNutrientParameter({
                                                        minimumRate: numValue,
                                                    })
                                                }
                                                value={
                                                    recNutrient.recNutrientParameters.minimumRate
                                                }
                                                onBlur={() => this._checkPendingCodes()}
                                                showTopLabel={false}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="product-blend-column small">
                                    {productMix.products.length > 1 ? null : (
                                        <div className="max-rate-input">
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                disabled={
                                                    recNutrient.recNutrientParameters.maximumLock ||
                                                    blending.isZeroRec(recNutrient)
                                                }
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateRecNutrientParameter({
                                                        maximumRate: numValue,
                                                    })
                                                }
                                                onBlur={() => this._checkPendingCodes()}
                                                value={recNutrientParameters.maximumRate}
                                                showTopLabel={false}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="product-blend-column small">
                                    {productMix.products.length > 1 ||
                                    !isEquationComplete ? null : (
                                        <div className="adjust-rate-input">
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                disabled={blending.isZeroRec(recNutrient)}
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateRecNutrientParameter({
                                                        percentAdjustment: numValue,
                                                    })
                                                }
                                                onBlur={() => this._checkPendingCodes()}
                                                value={recNutrientParameters.percentAdjustment}
                                                placeholderText={formatMessage(
                                                    messages.adjustPercentText
                                                )}
                                                showTopLabel={false}
                                            />
                                        </div>
                                    )}
                                </div>

                                {!isLimeProduct ? null : (
                                    <div className="product-blend-column small">
                                        <div className="lime-effiencency-input">
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                disabled={
                                                    blending.isZeroRec(recNutrient) &&
                                                    !minimumIncludeZeros
                                                }
                                                onChange={(strValue, fmtValue, numValue) => {
                                                    if (!isEquationComplete) {
                                                        this._updateProduct(
                                                            index,
                                                            {
                                                                limeEfficiency: numValue,
                                                            },
                                                            strValue
                                                        );
                                                    } else {
                                                        this._updateRecNutrientParameter({
                                                            limeEfficiency: numValue,
                                                        });
                                                    }
                                                }}
                                                onBlur={() => this._checkPendingCodes()}
                                                value={product.limeEfficiency || 90}
                                                placeholderText={formatMessage(
                                                    messages.effectivePercentText
                                                )}
                                                showTopLabel={false}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {this._getProductBlendSummary(true)}
                    {
                        <div className="minimum-settings">
                            <div className="settings-row">
                                <div className="field-avg-rate">
                                    <div className="field-avg-rate-label">
                                        {!isEquationComplete
                                            ? null
                                            : `${formatMessage(messages.appliedAvgRateText)}:`}
                                    </div>
                                    <div
                                        className="field-avg-rate-value"
                                        title={isNaN(appliedAvgRate) ? 0 : appliedAvgRate}
                                    >
                                        {!isEquationComplete
                                            ? null
                                            : `${formatNumber(
                                                  isNaN(appliedAvgRate) ? 0 : appliedAvgRate,
                                                  intlConfig.numberFormatOptions
                                              )}`}
                                    </div>
                                </div>
                                {blending.isZeroRec(recNutrient) ? null : (
                                    <div className="min-max-settings">
                                        <div className="min-max-lock-bar">
                                            <div className="min-max-lock-label">
                                                {`${formatMessage(messages.lockText)}:`}
                                            </div>
                                            <Checkbox
                                                key="min-lock-toggle"
                                                value={recNutrientParameters.minimumLock}
                                                disabled={
                                                    !recNutrientParameters.minimumRate &&
                                                    !recNutrientParameters.minimumLock
                                                }
                                                onChange={(e, val) =>
                                                    this._updateRecNutrientParameter({
                                                        minimumLock: val,
                                                    })
                                                }
                                                className="min-lock-checkbox"
                                            />
                                            <Checkbox
                                                key="max-lock-toggle"
                                                value={recNutrientParameters.maximumLock}
                                                disabled={
                                                    !recNutrientParameters.maximumRate &&
                                                    !recNutrientParameters.maximumLock
                                                }
                                                onChange={(e, val) =>
                                                    this._updateRecNutrientParameter({
                                                        maximumLock: val,
                                                    })
                                                }
                                                className="max-lock-checkbox"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                placeholderText={formatMessage(messages.minimumSettingsText)}
                                value={
                                    recNutrientParameters.zerosBelowMinimum
                                        ? "zerosBelowMinimum"
                                        : recNutrientParameters.minimumIncludeZeros
                                        ? "minimumIncludeZeros"
                                        : recNutrientParameters.zerosBelowSwitchRate
                                        ? "zerosBelowSwitchRate"
                                        : "minimumExcludeZeros"
                                }
                                clearable={false}
                                required={true}
                                options={blending.minimumSettingsOptions}
                                onChange={(value) => {
                                    if (isEquation) {
                                        switch (value) {
                                            case "minimumExcludeZeros":
                                                logFirebaseEvent("equation_rec_min_except_zeros");
                                                break;
                                            case "minimumIncludeZeros":
                                                logFirebaseEvent("equation_rec_min_include_zeros");
                                                break;
                                            case "zerosBelowMinimum":
                                                logFirebaseEvent("equation_rec_min_below_min");
                                                break;
                                            case "zerosBelowSwitchRate":
                                                logFirebaseEvent("equation_rec_min_switch_rate");
                                                break;
                                        }
                                    }
                                    this._updateRecNutrientParameter({
                                        minimumExcludeZeros: false,
                                        minimumIncludeZeros: false,
                                        zerosBelowMinimum: false,
                                        zerosBelowSwitchRate: false,
                                        [value]: true,
                                    });
                                }}
                                onBlur={() => this._checkPendingCodes()}
                            />
                        </div>
                    }
                    {!recNutrientParameters.zerosBelowSwitchRate ? null : (
                        <div className="switch-rate-input">
                            <NumericInput
                                scale={2}
                                precision={11}
                                disabled={
                                    recNutrient.recNutrientParameters.minimumLock ||
                                    blending.isZeroRec(recNutrient)
                                }
                                onChange={(strValue, fmtValue, numValue) =>
                                    this._updateRecNutrientParameter({
                                        switchRate: numValue,
                                    })
                                }
                                onBlur={() => this._checkPendingCodes()}
                                placeholderText={formatMessage(messages.switchRateText)}
                                value={recNutrientParameters.switchRate}
                                required={true}
                                showTopLabel={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    _getCarrierSection(): JSX.Element {
        const {
            availableProducts,
            productMix,
            carrierProducts,
            defaultCarrierGuid,
            productBlendPicklists,
            hideAreaCalculations,
        } = this.props;
        const { isLockGuaranteedAnalysis, isLockProductRatios } = productMix;
        const { formatMessage, formatNumber } = this.props.intl;

        const productRatiosLocked = isLockGuaranteedAnalysis || isLockProductRatios;
        let index = -1;
        const defaultCarrierProduct = availableProducts.find(
            (product) => product.productGuid === defaultCarrierGuid
        );
        const product =
            productMix.products.find((product, productIndex) => {
                if (product.isCarrier) {
                    index = productIndex;
                }
                return product.isCarrier;
            }) || blending.initializeProduct(productMix, defaultCarrierProduct, this.props);

        const filteredCarrierProducts = carrierProducts.filter(
            (cp) =>
                cp.value === product.productGuid ||
                !productMix.products.some((p) => p.productGuid === cp.value)
        );

        return !product ? null : (
            <div>
                <div className="product-blend-header carrier-header product-blend-item">
                    <div className="product-blend-column xsmall">
                        {productRatiosLocked ? null : (
                            <Checkbox
                                label={""}
                                value={productMix.isCarrier}
                                onChange={(e, value) =>
                                    this._updateProductMix({ isCarrier: value })
                                }
                            />
                        )}
                    </div>
                    <div className="product-blend-column xxxlarge">
                        {formatMessage(messages.carrierText)}
                    </div>
                    <div className="product-blend-column small">{/* empty */}</div>
                    <div className="product-blend-column med">{/* empty */}</div>
                    <div className="product-blend-column small">{/* empty */}</div>
                    <div className="product-blend-column med">{/* empty */}</div>
                    <div className="product-blend-column med">{/* empty */}</div>
                    <div className="product-blend-column med">{/* empty */}</div>
                    <div className="product-blend-column med">{/* empty */}</div>
                </div>
                <div
                    className={classnames("carrier-item product-blend-body", {
                        "carrier-disabled": !productMix.isCarrier,
                    })}
                >
                    <div className="product-blend-item">
                        <div className="product-blend-column xsmall">{/* empty */}</div>
                        <div className="product-blend-column xxxlarge">
                            {productRatiosLocked ? (
                                <div className="trim-overflow" title={product.productName}>
                                    {product.productName}
                                </div>
                            ) : (
                                <SelectInput
                                    optionIsHiddenKey={ACTIVE_YN}
                                    initialFilterStr={product.productName}
                                    value={product.productGuid}
                                    clearable={false}
                                    options={filteredCarrierProducts}
                                    disabled={!productMix.isCarrier}
                                    onChange={(value) => {
                                        this._updateProduct(
                                            index,
                                            { productGuid: value },
                                            value,
                                            true
                                        );
                                    }}
                                    showTopLabel={false}
                                />
                            )}
                        </div>
                        <div className="product-blend-column small">
                            <div
                                className={classnames({
                                    "invalid-rate": product.rate < 0,
                                })}
                            >
                                {formatNumber(product.rate, intlConfig.numberFormatOptions)}
                            </div>
                        </div>
                        <div className="product-blend-column med">
                            <div>{product.rateUnit}</div>
                        </div>
                        <div className="product-blend-column small">
                            {productMix.isImport ? null : productRatiosLocked ? (
                                <div>
                                    {formatNumber(product.cost, intlConfig.currencyFormatOptions)}
                                </div>
                            ) : (
                                <NumericInput
                                    scale={2}
                                    precision={11}
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateProduct(index, { cost: numValue }, strValue)
                                    }
                                    value={product.cost}
                                    showTopLabel={false}
                                    disabled={!productMix.isCarrier}
                                />
                            )}
                        </div>
                        {hideAreaCalculations ? null : (
                            <div className="product-blend-column med">
                                {productRatiosLocked ? (
                                    <div>{product.costUnit}</div>
                                ) : (
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        initialFilterStr={product.costUnit}
                                        value={product.costUnitGuid}
                                        clearable={false}
                                        options={
                                            product.physicalState &&
                                            product.physicalState.toLowerCase() ===
                                                PhysicalStates.LIQUID
                                                ? productBlendPicklists.liquidPriceUnits
                                                : productBlendPicklists.dryPriceUnits
                                        }
                                        onChange={(value) => {
                                            const options =
                                                product.physicalState &&
                                                product.physicalState.toLowerCase() ===
                                                    PhysicalStates.LIQUID
                                                    ? productBlendPicklists.liquidPriceUnits
                                                    : productBlendPicklists.dryPriceUnits;
                                            const costUnit = options.find(
                                                (option) => option.value === value
                                            );
                                            const newProps = {
                                                costUnit: costUnit.label,
                                                costUnitGuid: value,
                                            };
                                            this._updateProduct(index, newProps);
                                        }}
                                        showTopLabel={false}
                                        disabled={!productMix.isCarrier}
                                    />
                                )}
                            </div>
                        )}
                        {hideAreaCalculations ? null : (
                            <div className="product-blend-column med">
                                <div>
                                    {formatNumber(
                                        product.costPerAcre,
                                        intlConfig.currencyFormatOptions
                                    )}
                                </div>
                            </div>
                        )}
                        {hideAreaCalculations ? null : (
                            <div className="product-blend-column med">
                                <div>
                                    {formatNumber(
                                        product.totalProduct,
                                        intlConfig.numberFormatOptions
                                    )}
                                </div>
                            </div>
                        )}
                        {hideAreaCalculations ? null : (
                            <div className="product-blend-column med">
                                <div>
                                    {formatNumber(
                                        product.totalCost,
                                        intlConfig.currencyFormatOptions
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private displayAverageRateText(): boolean {
        const { productMix, isEquation } = this.props;
        const mixType = productMix.productMixType.toLowerCase();
        if (
            isEquation ||
            mixType === ProductMixTypes.CHEMICAL ||
            mixType === ProductMixTypes.FERTILIZER
        ) {
            return false;
        }
        return true;
    }

    _getProductBlendSummary(isEquationTable = false): JSX.Element {
        const {
            productMix,
            recNutrient,
            productBlendPicklists,
            hideAreaCalculations,
            isEquation,
            isEquationComplete,
            isFromEquationRec,
        } = this.props;
        const { liquidRateUnits, dryPriceUnits, liquidPriceUnits } = productBlendPicklists;
        const { formatMessage, formatNumber } = this.props.intl;
        const { isBlendingActive } = this.state;
        const mixType = productMix.productMixType.toLowerCase();
        const minimumIncludeZeros =
            isEquation &&
            recNutrient &&
            recNutrient.recNutrientParameters &&
            recNutrient.recNutrientParameters.minimumIncludeZeros;

        if (productMix.products.length <= 1) {
            return null;
        }

        const { isLockGuaranteedAnalysis, isLockProductRatios } = productMix;

        const productRatiosLocked =
            isLockGuaranteedAnalysis ||
            isLockProductRatios ||
            (productMix.products.length > 1 &&
                isEquationTable &&
                !(mixType === ProductMixTypes.CHEMICAL));
        const effectiveCostUnitGuid = blending.getEffectiveCostUnitGuid(
            productMix,
            productMix.products
        );
        const effectiveCostUnitItem =
            dryPriceUnits.find((unit) => unit.value === effectiveCostUnitGuid) ||
            liquidPriceUnits.find((unit) => unit.value === effectiveCostUnitGuid);
        const effectiveCostUnit = effectiveCostUnitItem ? effectiveCostUnitItem.label : "";

        const targetRateUnitOptions = [];
        const targetRateUnitOptionsMap = new Map();
        const hasRateDependency = productMix.products.some(
            (product) => product.rateUnit?.split("/")[1]?.toLowerCase() === PER100GAL
        );
        if (hasRateDependency) {
            const galPerAcre = liquidRateUnits.find(
                (unit) => unit.label.toLowerCase() === GALPERAC
            );
            targetRateUnitOptionsMap.set(galPerAcre.value, galPerAcre.label);
            productMix.products.forEach((product) => {
                if (
                    product.rateUnit?.split("/")[1]?.toLowerCase() !== PER100GAL &&
                    product.physicalState &&
                    product.physicalState.toLowerCase() === PhysicalStates.LIQUID &&
                    (mixType === ProductMixTypes.FERTILIZER ||
                        (product.isCarrier && mixType === ProductMixTypes.CHEMICAL))
                ) {
                    targetRateUnitOptionsMap.set(product.rateUnitGuid, product.rateUnit);
                }
            });
        } else {
            productMix.products.forEach((product) => {
                if (
                    mixType === ProductMixTypes.FERTILIZER ||
                    (product.isCarrier && mixType === ProductMixTypes.CHEMICAL)
                ) {
                    targetRateUnitOptionsMap.set(product.rateUnitGuid, product.rateUnit);
                }
            });
        }

        const lPerAcUnit = liquidRateUnits.find((unit) => unit.label.toLowerCase() === LPERAC);
        const galPerAcreUnit = liquidRateUnits.find(
            (unit) => unit.label.toLowerCase() === GALPERAC
        );
        if (mixType === ProductMixTypes.CHEMICAL && lPerAcUnit) {
            targetRateUnitOptionsMap.set(lPerAcUnit.value, lPerAcUnit.label);
            targetRateUnitOptionsMap.set(galPerAcreUnit.value, galPerAcreUnit.label);
        }

        targetRateUnitOptionsMap.forEach((value, key) => {
            targetRateUnitOptions.push({ value: key, label: value });
        });

        const hasMultipleRateUnits = targetRateUnitOptions.length > 1;

        const hasNonZeroProductCost = productMix.products.some((product) => product.cost);
        const applicableProductMixName =
            productMix.name && productMix.name !== productMix.guaranteedAnalysis
                ? productMix.name
                : null;

        return (
            <div
                className={classnames("product-blend-totals", {
                    "equation-blend-totals": isEquationTable,
                })}
            >
                <div className="product-blend-item">
                    <div
                        className={classnames(
                            "product-blend-column",
                            { xxxlarge: mixType === ProductMixTypes.CHEMICAL },
                            { xsmall: mixType !== ProductMixTypes.CHEMICAL }
                        )}
                    >
                        {mixType !== ProductMixTypes.CHEMICAL ? null : (
                            <Checkbox
                                className={"lock-mix-checkbox"}
                                label={formatMessage(messages.lockGAText, { mixType })}
                                value={productRatiosLocked}
                                onChange={(e, value) =>
                                    this._updateProductMix({
                                        isLockGuaranteedAnalysis: value,
                                        isLockProductRatios: value,
                                    })
                                }
                                disabled={Number(productMix.targetRate) === 0}
                            />
                        )}
                    </div>
                    <div
                        className={classnames(
                            "product-blend-column",
                            {
                                xsmall: mixType === ProductMixTypes.CHEMICAL,
                            },
                            {
                                xxlarge: isEquation && !isBlendingActive,
                            },
                            {
                                xxxlarge:
                                    !(isEquation && !isBlendingActive) &&
                                    !(mixType === ProductMixTypes.CHEMICAL),
                            }
                        )}
                    >
                        {mixType === ProductMixTypes.FERTILIZER ? (
                            <div
                                className={classnames(
                                    {
                                        "guaranteed-analysis": applicableProductMixName,
                                    },
                                    "trim-overflow"
                                )}
                            >
                                <div title={productMix.guaranteedAnalysis}>
                                    {formatMessage(messages.GAText)}:{" "}
                                    {productMix.guaranteedAnalysis}
                                </div>
                                {!applicableProductMixName ? null : (
                                    <div title={applicableProductMixName}>
                                        {applicableProductMixName}
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className="product-blend-column small">
                        {(isEquation && !isEquationComplete) ||
                        (!productRatiosLocked &&
                            mixType !== ProductMixTypes.CHEMICAL &&
                            !isEquationTable) ||
                        (productMix.isImport &&
                            !isBlendingActive &&
                            Number(productMix.actualRate) !== 0) ||
                        (isBlendingActive && mixType !== ProductMixTypes.CHEMICAL) ||
                        isFromEquationRec ? (
                            <div className="target-rate-display" title={productMix.targetRate}>
                                {formatNumber(
                                    productMix.targetRate,
                                    intlConfig.numberFormatOptions
                                )}
                            </div>
                        ) : (
                            <div className="target-rate-input">
                                <NumericInput
                                    scale={2}
                                    precision={11}
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateProductMix(
                                            { targetRate: numValue },
                                            false,
                                            strValue ? strValue : 0
                                        )
                                    }
                                    onBlur={() => this._checkPendingCodes()}
                                    value={productMix.targetRate}
                                    disabled={
                                        (mixType === ProductMixTypes.CHEMICAL &&
                                            !productMix.isCarrier) ||
                                        (blending.isZeroRec(recNutrient) && !minimumIncludeZeros)
                                    }
                                    placeholderText={
                                        this.displayAverageRateText()
                                            ? formatMessage(messages.averageRateText)
                                            : formatMessage(messages.targetRateText)
                                    }
                                    useValueTitle={true}
                                />
                            </div>
                        )}
                    </div>
                    <div className={classnames("product-blend-column", "med")}>
                        {!hasMultipleRateUnits ||
                        hasRateDependency ||
                        (isEquationTable && mixType !== ProductMixTypes.CHEMICAL) ||
                        (productMix.isImport &&
                            !isBlendingActive &&
                            Number(productMix.actualRate) !== 0) ? (
                            <div className="target-rate-unit-display">
                                {productMix.targetRateUnit}
                            </div>
                        ) : (
                            <div className="target-rate-unit-select">
                                <SelectInput
                                    optionIsHiddenKey={ACTIVE_YN}
                                    initialFilterStr={productMix.targetRateUnit}
                                    value={productMix.targetRateUnitGuid}
                                    clearable={false}
                                    options={targetRateUnitOptions}
                                    onChange={(value) => {
                                        if (mixType !== ProductMixTypes.CHEMICAL) {
                                            const targetRateUnit = blending.getRateUnit(
                                                value,
                                                this.props
                                            );
                                            const newProps = {
                                                targetRateUnit: targetRateUnit.label,
                                                targetRateUnitGuid: value,
                                            };
                                            this._updateProductMix(newProps);
                                        } else {
                                            const carrierIndex = productMix.products.findIndex(
                                                (product) => {
                                                    if (product.isCarrier) {
                                                        return true;
                                                    }
                                                }
                                            );
                                            const rateUnit = targetRateUnitOptions.find(
                                                (option) => option.value === value
                                            );

                                            const newProps = {
                                                rateUnit: rateUnit.label,
                                                rateUnitGuid: value,
                                            };

                                            this._updateProduct(carrierIndex, newProps);
                                        }
                                    }}
                                    placeholderText={formatMessage(messages.unitText)}
                                />
                            </div>
                        )}
                    </div>
                    <div className="product-blend-column small">
                        {mixType === ProductMixTypes.CHEMICAL ||
                        productMix.isImport ? null : !productRatiosLocked || isEquationTable ? (
                            <div className="cost-display" title={productMix.targetCost?.toString()}>
                                {formatNumber(
                                    productMix.targetCost,
                                    intlConfig.currencyFormatOptions
                                )}
                            </div>
                        ) : (
                            <div className="cost-input">
                                <NumericInput
                                    scale={2}
                                    precision={11}
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateProductMix(
                                            { targetCost: numValue },
                                            false,
                                            strValue
                                        )
                                    }
                                    value={productMix.targetCost}
                                    placeholderText={formatMessage(messages.priceText)}
                                    disabled={!hasNonZeroProductCost}
                                    useValueTitle={true}
                                />
                            </div>
                        )}
                    </div>

                    <div
                        className={classnames("product-blend-column", {
                            med: !isEquation || isBlendingActive,
                            small: isEquation && !isBlendingActive,
                        })}
                    >
                        {mixType === ProductMixTypes.CHEMICAL || productMix.isImport ? null : (
                            <div className="cost-unit-display">{effectiveCostUnit}</div>
                        )}
                    </div>

                    {hideAreaCalculations ? null : (
                        <div
                            className={classnames("product-blend-column", {
                                med: !isEquation || isBlendingActive,
                                small: isEquation && !isBlendingActive,
                            })}
                        >
                            {isEquation && !isEquationComplete ? null : !productRatiosLocked ||
                              productMix.isImport ||
                              isFromEquationRec ? (
                                <div
                                    className="total-cost-per-acre-display"
                                    title={productMix.costPerAcre?.toString()}
                                >
                                    {formatNumber(
                                        productMix.costPerAcre,
                                        intlConfig.currencyFormatOptions
                                    )}
                                </div>
                            ) : (
                                <div className={classnames("total-cost-per-acre-input")}>
                                    <NumericInput
                                        scale={2}
                                        precision={11}
                                        onChange={(strValue, fmtValue, numValue) => {
                                            if (strValue && productMix.targetCost > 0) {
                                                numValue > 0
                                                    ? this._updateProductMixDebounce(
                                                          {
                                                              costPerAcre: numValue,
                                                          },
                                                          false,
                                                          strValue
                                                      )
                                                    : this._updateProductMixDebounce(
                                                          { targetRate: 0 },
                                                          false,
                                                          strValue
                                                      );
                                            }
                                        }}
                                        onBlur={(evt) => {
                                            evt.target.value === "" &&
                                                this._updateProductMix(
                                                    { targetRate: 0 },
                                                    false,
                                                    ""
                                                );
                                            this._checkPendingCodes();
                                        }}
                                        value={productMix.costPerAcre}
                                        placeholderText={formatMessage(messages.costPerAcreText)}
                                        disabled={
                                            productMix.targetCost === 0 ||
                                            (blending.isZeroRec(recNutrient) &&
                                                !minimumIncludeZeros)
                                        }
                                        useValueTitle={true}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {hideAreaCalculations ? null : (
                        <div className={classnames("product-blend-column", "med")}>
                            {isEquation && !isEquationComplete ? null : (!productRatiosLocked &&
                                  mixType !== ProductMixTypes.CHEMICAL) ||
                              productMix.isImport ||
                              isFromEquationRec ? (
                                <div
                                    className="total-product-display"
                                    title={productMix.totalProduct?.toString()}
                                >
                                    {formatNumber(
                                        productMix.totalProduct,
                                        intlConfig.numberFormatOptions
                                    )}
                                </div>
                            ) : (
                                <div className="total-product-input">
                                    <NumericInput
                                        scale={2}
                                        precision={11}
                                        onChange={(strValue, fmtValue, numValue) =>
                                            this._updateProductMix(
                                                { totalProduct: numValue },
                                                false,
                                                strValue
                                            )
                                        }
                                        onBlur={() => this._checkPendingCodes()}
                                        value={productMix.totalProduct}
                                        placeholderText={formatMessage(messages.totalText)}
                                        useValueTitle={true}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {hideAreaCalculations ? null : (
                        <div className={classnames("product-blend-column", "med")}>
                            {isEquation && !isEquationComplete ? null : productMix.isImport ||
                              isFromEquationRec ? (
                                <div
                                    className="total-product-display"
                                    title={productMix.totalCost?.toString()}
                                >
                                    {formatNumber(
                                        productMix.totalCost,
                                        intlConfig.numberFormatOptions
                                    )}
                                </div>
                            ) : (
                                <div className="total-cost-input">
                                    <NumericInput
                                        scale={2}
                                        precision={11}
                                        onChange={(strValue, fmtValue, numValue) => {
                                            if (strValue && productMix.targetCost > 0) {
                                                numValue > 0
                                                    ? this._updateProductMixDebounce(
                                                          {
                                                              totalCost: numValue,
                                                          },
                                                          false,
                                                          strValue
                                                      )
                                                    : this._updateProductMixDebounce(
                                                          { targetRate: 0 },
                                                          false,
                                                          strValue
                                                      );
                                            }
                                        }}
                                        onBlur={(evt) => {
                                            evt.target.value === "" &&
                                                this._updateProductMix(
                                                    { targetRate: 0 },
                                                    false,
                                                    ""
                                                );
                                            this._checkPendingCodes();
                                        }}
                                        value={productMix.totalCost}
                                        placeholderText={formatMessage(messages.totalCostText)}
                                        disabled={
                                            productMix.targetCost === 0 ||
                                            (blending.isZeroRec(recNutrient) &&
                                                !minimumIncludeZeros)
                                        }
                                        useValueTitle={true}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {!isEquation || isBlendingActive ? null : (
                        <div className="product-blend-column small">
                            <div className="min-rate-input">
                                <NumericInput
                                    scale={2}
                                    precision={11}
                                    disabled={
                                        recNutrient.recNutrientParameters.minimumLock ||
                                        blending.isZeroRec(recNutrient)
                                    }
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateRecNutrientParameter({
                                            minimumRate: numValue,
                                        })
                                    }
                                    onBlur={() => this._checkPendingCodes()}
                                    value={recNutrient.recNutrientParameters.minimumRate}
                                    placeholderText={formatMessage(messages.minRateText)}
                                    useValueTitle={true}
                                />
                            </div>
                        </div>
                    )}
                    {!isEquation || isBlendingActive ? null : (
                        <div className="product-blend-column small">
                            <div className="max-rate-input">
                                <NumericInput
                                    scale={2}
                                    precision={11}
                                    disabled={
                                        recNutrient.recNutrientParameters.maximumLock ||
                                        blending.isZeroRec(recNutrient)
                                    }
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateRecNutrientParameter({
                                            maximumRate: numValue,
                                        })
                                    }
                                    onBlur={() => this._checkPendingCodes()}
                                    value={recNutrient.recNutrientParameters.maximumRate}
                                    placeholderText={formatMessage(messages.maxRateText)}
                                    useValueTitle={true}
                                />
                            </div>
                        </div>
                    )}
                    {!isEquation || !isEquationComplete || isBlendingActive ? null : (
                        <div className="product-blend-column small">
                            <div className="adjust-rate-input">
                                <NumericInput
                                    scale={2}
                                    precision={11}
                                    disabled={blending.isZeroRec(recNutrient)}
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateRecNutrientParameter({
                                            percentAdjustment: numValue,
                                        })
                                    }
                                    onBlur={() => this._checkPendingCodes()}
                                    value={recNutrient.recNutrientParameters.percentAdjustment}
                                    placeholderText={formatMessage(messages.adjustPercentText)}
                                    useValueTitle={true}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    _getCustomProductBuilder(): JSX.Element {
        const { productBlendPicklists, productMix } = this.props;
        const { physicalState, customProductTypes } = productBlendPicklists;
        const { customProductPercentages, customProduct } = this.state;
        const { formatMessage } = this.props.intl;
        const physicalStateItem = physicalState.find(
            (phys) => phys.value === customProduct.physicalStateGuid
        );
        const customProductDensityUnit = physicalStateItem
            ? physicalStateItem.label.toLowerCase() === PhysicalStates.DRY
                ? "lbs/ft3"
                : "lbs/gal"
            : "";
        const builderDisabled = productMix.products.length > 0;
        let hasNutrient = false;
        for (const prop in customProductPercentages) {
            if (!Object.hasOwn(customProductPercentages, prop)) {
                continue;
            }
            if (customProductPercentages[prop]) {
                hasNutrient = true;
                break;
            }
        }
        const isAddCustomProductDisabled =
            builderDisabled ||
            !hasNutrient ||
            !customProduct.customProductTypeGuid ||
            !customProduct.physicalStateGuid ||
            (physicalStateItem.label.toLowerCase() === PhysicalStates.LIQUID &&
                !customProduct.density);

        return (
            <div className="custom-product-builder">
                <div className="nutrient-bar">
                    <div className="nutrient-input nitrogen">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    nitrogen: numValue,
                                })
                            }
                            value={customProductPercentages.nitrogen}
                            placeholderText={formatMessage(messages.element, {
                                element: "nitrogen",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input phosphorus">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    phosphorus: numValue,
                                })
                            }
                            value={customProductPercentages.phosphorus}
                            placeholderText={formatMessage(messages.element, {
                                element: "phosphorus",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input potassium">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    potassium: numValue,
                                })
                            }
                            value={customProductPercentages.potassium}
                            placeholderText={formatMessage(messages.element, {
                                element: "potassium",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input calcium">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    calcium: numValue,
                                })
                            }
                            value={customProductPercentages.calcium}
                            placeholderText={formatMessage(messages.element, {
                                element: "calcium",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input magnesium">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    magnesium: numValue,
                                })
                            }
                            value={customProductPercentages.magnesium}
                            placeholderText={formatMessage(messages.element, {
                                element: "magnesium",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input sulfur">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    sulfur: numValue,
                                })
                            }
                            value={customProductPercentages.sulfur}
                            placeholderText={formatMessage(messages.element, {
                                element: "sulfur",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input iron">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    iron: numValue,
                                })
                            }
                            value={customProductPercentages.iron}
                            placeholderText={formatMessage(messages.element, {
                                element: "iron",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input zinc">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    zinc: numValue,
                                })
                            }
                            value={customProductPercentages.zinc}
                            placeholderText={formatMessage(messages.element, {
                                element: "zinc",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input copper">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    copper: numValue,
                                })
                            }
                            value={customProductPercentages.copper}
                            placeholderText={formatMessage(messages.element, {
                                element: "copper",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input manganese">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    manganese: numValue,
                                })
                            }
                            value={customProductPercentages.manganese}
                            placeholderText={formatMessage(messages.element, {
                                element: "manganese",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input boron">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    boron: numValue,
                                })
                            }
                            value={customProductPercentages.boron}
                            placeholderText={formatMessage(messages.element, {
                                element: "boron",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input chlorine">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    chlorine: numValue,
                                })
                            }
                            value={customProductPercentages.chlorine}
                            placeholderText={formatMessage(messages.element, {
                                element: "chlorine",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="nutrient-input molybdenum">
                        <NumericInput
                            scale={3}
                            precision={7}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProductPercentages({
                                    molybdenum: numValue,
                                })
                            }
                            value={customProductPercentages.molybdenum}
                            placeholderText={formatMessage(messages.element, {
                                element: "molybdenum",
                            })}
                            disabled={builderDisabled}
                        />
                    </div>
                </div>
                <div className="property-bar">
                    <div className="property-input custom-product-type">
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            required={true}
                            value={customProduct.customProductTypeGuid}
                            options={customProductTypes}
                            onChange={(value) =>
                                this._updateCustomProduct({
                                    customProductTypeGuid: value,
                                })
                            }
                            placeholderText={formatMessage(messages.manureProductTypeText)}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="property-input custom-product-physical-state">
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            required={true}
                            value={customProduct.physicalStateGuid}
                            options={physicalState}
                            onChange={(value) =>
                                this._updateCustomProduct({
                                    physicalStateGuid: value,
                                })
                            }
                            placeholderText={formatMessage(messages.physicalStateText)}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="property-input custom-product-density">
                        <NumericInput
                            scale={4}
                            precision={12}
                            onChange={(strValue, fmtValue, numValue) =>
                                this._updateCustomProduct({ density: numValue })
                            }
                            value={customProduct.density}
                            required={
                                physicalStateItem &&
                                physicalStateItem.label.toLowerCase() !== PhysicalStates.DRY
                            }
                            placeholderText={formatMessage(messages.densityText)}
                            disabled={builderDisabled}
                        />
                    </div>
                    <div className="property-label custom-product-density-unit">
                        {customProductDensityUnit}
                    </div>
                    <div className="custom-product-controls">
                        <NoLink
                            className={classnames("add-custom-product-nolink", {
                                disabled: isAddCustomProductDisabled,
                            })}
                            onClick={() =>
                                isAddCustomProductDisabled ? null : this._addCustomProduct()
                            }
                            label={formatMessage(messages.addCustomProductText)}
                        />
                        |
                        <NoLink
                            className={classnames("clear-custom-product-nolink", {
                                disabled: builderDisabled,
                            })}
                            onClick={() => (builderDisabled ? null : this._clearCustomProduct())}
                            label={formatMessage(messages.clearCustomProductText)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    _getSaveBlendBar(): JSX.Element {
        const { productMix, isEquation, isFromEquationRec } = this.props;
        const { formatMessage } = this.props.intl;

        const mixType = productMix.productMixType.toLowerCase();
        const isOptional = mixType !== ProductMixTypes.MANURE && !isEquation;

        return (productMix.products.length <= 1 && mixType !== ProductMixTypes.MANURE) ||
            isFromEquationRec ? null : (
            <div className="product-blend-save">
                <div className="blend-save-message">
                    {`${formatMessage(messages.saveBlendMessageText, {
                        mixType,
                    })}
                        ${
                            isOptional
                                ? formatMessage(messages.saveBlendOptionalText, { isOptional })
                                : ""
                        }`}
                </div>
                <div className="blend-save-row">
                    <div className="blend-save-input">
                        <TextInput
                            maxLength={50}
                            value={this.state.blendName}
                            placeholderText={formatMessage(messages.blendNameText, { mixType })}
                            onChange={(value) => {
                                this.setState({ blendName: value }, () => {
                                    mixType !== ProductMixTypes.MANURE
                                        ? this._updateProductMix({
                                              name: value,
                                          })
                                        : this._updateCustomProductName();
                                });
                            }}
                            disabled={mixType === ProductMixTypes.MANURE}
                        />
                    </div>
                    <NoLink
                        className={classnames("blend-save-nolink", {
                            disabled: !this._saveBlendEnabled(),
                        })}
                        onClick={() => {
                            this._saveBlend();
                            this._logFirebaseEvent("save");
                        }}
                        label={formatMessage(messages.saveBlendText, {
                            mixType,
                        })}
                    />
                </div>
            </div>
        );
    }

    _getCreditsSection(): JSX.Element {
        const { recNutrient, availableCredits, isBatchRecEdit, batchRecDetailsForEdit } =
            this.props;
        const { availableRecCredits, availableApplicationCredits } = availableCredits;
        const { formatMessage } = this.props.intl;
        const isApplyEnabled = this._getApplyCreditsEnabled();
        return (
            <Bucket
                className="equation-credit-bucket"
                isExpanded={this.state.isCreditsExpanded}
                onBucketToggle={(isExpanded) => this._toggleCreditsExpanded(isExpanded)}
            >
                <BucketHeader>
                    {formatMessage(messages.creditsText)}
                    <div
                        className="apply-credits-section"
                        onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                        }}
                    >
                        {!this.state.isCreditsExpanded ? null : (
                            <Button
                                value={formatMessage(messages.applyCreditsText)}
                                onClick={() => this._applyCredits(recNutrient)}
                                disabled={!isApplyEnabled}
                            />
                        )}
                        {!isApplyEnabled || !this.state.isCreditsExpanded ? null : (
                            <div className="credits-not-applied">
                                {formatMessage(messages.creditsNotAppliedText)}
                            </div>
                        )}
                    </div>
                </BucketHeader>
                <div className="equation-credits-body">
                    <div className="credit-options-section">
                        <div className="rec-credit-section flat-rate-section">
                            <div className="credit-section-header">
                                {formatMessage(messages.flatRateText)}
                            </div>
                            <div className="credit-section-body">
                                <Checkbox
                                    label={""}
                                    value={recNutrient.credits.some(
                                        (credit) =>
                                            credit.flatRate != null &&
                                            credit.flatRate !== "" &&
                                            (credit.isCredited ||
                                                (credit.creditGuid && credit.isCredited == null))
                                    )}
                                    onChange={(e, value) => this._toggleFlatRateCredit(value)}
                                    className="credit-checkbox"
                                />
                                <NumericInput
                                    placeholderText={formatMessage(messages.flatRateText)}
                                    scale={2}
                                    precision={11}
                                    onChange={(strValue, fmtValue, numValue) =>
                                        this._updateFlatRateCredit(numValue)
                                    }
                                    value={
                                        recNutrient.credits.find((credit) => credit.flatRate)
                                            ? Number(
                                                  recNutrient.credits.find(
                                                      (credit) => credit.flatRate
                                                  ).flatRate
                                              )
                                            : null
                                    }
                                />
                                lb/ac
                            </div>
                        </div>
                        {isBatchRecEdit && batchRecDetailsForEdit.length > 1 ? null : (
                            <div className="rec-credit-section">
                                <div className="credit-section-header">
                                    {formatMessage(messages.recommendationsText)}
                                </div>
                                <div className="credit-section-body">
                                    {availableRecCredits.map((credit, creditIndex) => {
                                        const { credits } = recNutrient;
                                        const value = credits.some(
                                            (c) =>
                                                c.creditProductMixId === credit.recProductMixId &&
                                                c.creditRecGeneralGuid === credit.recGeneralGuid &&
                                                (c.isCredited ||
                                                    (c.creditGuid && c.isCredited == null))
                                        );
                                        return (
                                            <Checkbox
                                                key={`rec-credit-${creditIndex}`}
                                                label={credit.displayName}
                                                value={value}
                                                onChange={(e, val) =>
                                                    this._updateCredit(credit, val, true)
                                                }
                                                className="credit-checkbox"
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {isBatchRecEdit && batchRecDetailsForEdit.length > 1 ? null : (
                            <div className="rec-credit-section">
                                <div className="credit-section-header">
                                    {formatMessage(messages.eventsText)}
                                </div>
                                <div className="credit-section-body">
                                    {availableApplicationCredits.map((credit, creditIndex) => {
                                        const { credits } = recNutrient;
                                        const value = credits.some(
                                            (c) =>
                                                c.creditProductMixName === credit.productMixName &&
                                                c.creditAgEventGeneralGuid ===
                                                    credit.agEventGeneralGuid &&
                                                (c.isCredited ||
                                                    (c.creditGuid && c.isCredited == null))
                                        );
                                        return (
                                            <Checkbox
                                                key={`event-credit-${creditIndex}`}
                                                label={credit.displayName}
                                                value={value}
                                                onChange={(e, val) =>
                                                    this._updateCredit(credit, val, false)
                                                }
                                                className="credit-checkbox"
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Bucket>
        );
    }

    _getPanes(): JSX.Element[] {
        const { isEquation, isEquationComplete, isFromEquationRec, nutrientName, productMix } =
            this.props;
        const { formatMessage } = this.props.intl;

        const panes = [];
        const mixType = productMix.productMixType.toLowerCase();
        const nutrientNameText = isEquation ? ` for ${nutrientName}` : "";
        panes.push(
            <Pane
                key={ProductMixTypes.FERTILIZER}
                label={formatMessage(messages.fertilizerText)}
                disabled={isFromEquationRec && mixType !== ProductMixTypes.FERTILIZER}
            >
                {this._getProductSelectionFilter(true)}
                {isEquation
                    ? this._getEquationProductBlendTable()
                    : this._getProductBlendTable(productMix.products.length !== 1)}
                {isEquation
                    ? isEquationComplete && this.props.availableCredits
                        ? this._getCreditsSection()
                        : null
                    : null}
                {!this.state.isBlendingActive ? null : (
                    <DialogBox
                        className="product-blend-modal"
                        isOpen={this.state.isBlendingActive}
                        isModal={true}
                        title={`${formatMessage(messages.customBlendText, {
                            mixType,
                        })}${nutrientNameText}`}
                        draggable={true}
                        hideCloseX={true}
                        actionDisabled={!this._saveEnabled()}
                        onAction={() => {
                            this.setState(
                                {
                                    isBlendingActive: false,
                                    blendingOriginal: null,
                                },
                                () => {
                                    if (isEquation) {
                                        this._updateRecNutrient({}, true);
                                    } else if (productMix.isImport) {
                                        this._updateProductMix(
                                            { targetRate: +productMix.actualRate },
                                            false
                                        );
                                    }
                                }
                            );
                        }}
                        onClose={() => {
                            this._updateProductMix({
                                ...this.state.blendingOriginal,
                            });
                            this.setState({
                                isBlendingActive: false,
                                blendingOriginal: null,
                            });
                        }}
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        unrestricted={true}
                        forceOverflow={true}
                    >
                        {this._getProductSelectionFilter()}
                        {this._getProductBlendTable()}
                        {this._getSaveBlendBar()}
                    </DialogBox>
                )}
            </Pane>
        );
        if (!isEquation && !isFromEquationRec) {
            panes.push(
                <Pane key={ProductMixTypes.CHEMICAL} label={formatMessage(messages.chemicalText)}>
                    {this._getProductSelectionFilter()}
                    {this._getProductBlendTable()}
                    {this._getSaveBlendBar()}
                </Pane>
            );
        }
        panes.push(
            <Pane
                key={ProductMixTypes.MANURE}
                label={formatMessage(messages.manureText)}
                disabled={isFromEquationRec && mixType !== ProductMixTypes.MANURE}
            >
                {this._getCustomProductBuilder()}
                {isEquation ? this._getEquationProductBlendTable() : this._getProductBlendTable()}
                {!isEquation || !(isEquationComplete && this.props.availableCredits)
                    ? null
                    : this._getCreditsSection()}
            </Pane>
        );
        return panes;
    }

    #setBatchCreditedRecNutrients(
        nextProps,
        batchRecDetailsForEdit,
        batchCreditedRecNutrients
    ): void {
        const newRecNutrientList = [];
        for (const recDetails of batchRecDetailsForEdit) {
            for (const recArea of recDetails.recAreaList) {
                for (const rec of recArea.recs) {
                    for (const recNutr of rec.recNutrientList) {
                        const creditedRecNutrient = batchCreditedRecNutrients.find(
                            (bcrn) => bcrn.recNutrientGuid === recNutr.recNutrientGuid
                        );
                        const newCreditedRecNutrient = creditedRecNutrient
                            ? this._initializeCreditedRecNutrient(
                                  creditedRecNutrient,
                                  recNutr,
                                  nextProps
                              )
                            : recNutr;
                        newRecNutrientList.push(newCreditedRecNutrient);
                    }
                }
            }
        }
        const creditedBatchRecDetailsForEdit = blending.replaceRecNutrients(
            batchRecDetailsForEdit,
            newRecNutrientList
        );

        const newRecAreaList = blending.mergeRecDetailInfo(creditedBatchRecDetailsForEdit);

        this.props.updateBatchRecDetails(newRecAreaList);
        this.props.clearBatchCreditedRecNutrients();
    }

    #setCustomProductList(customProducts): void {
        const unsavedCustomProduct = this.state.customProductList.find(
            (cp) => cp.customProductGuid == null
        );
        const newCustomProductsList = [];
        if (unsavedCustomProduct) {
            newCustomProductsList.push(unsavedCustomProduct);
        }
        newCustomProductsList.push(...customProducts);

        this.setState({
            customProductList: newCustomProductsList,
        });
    }

    #setProductMixType(productMixTypes: any[], nextProps): void {
        const { defaultProductMixTypeGuid, isEquation, isFromEquationRec, productMix } = nextProps;
        if (productMix.productMixTypeGuid) {
            const productMixType = productMixTypes.find(
                (type) => type.value === productMix.productMixTypeGuid
            );

            switch (productMixType.label.toLowerCase()) {
                case ProductMixTypes.FERTILIZER:
                    this.setState({ selectedTab: 0 });
                    break;
                case ProductMixTypes.CHEMICAL:
                    this.setState({ selectedTab: 1 });
                    break;
                case ProductMixTypes.MANURE:
                    this.setState({
                        selectedTab: isEquation || isFromEquationRec ? 1 : 2,
                    });
                    break;
                default:
                    break;
            }
        } else if (blending.getIsDataLoaded(nextProps) && productMix.products.length === 0) {
            const productMixType =
                productMixTypes.find(
                    (type) =>
                        type.value === defaultProductMixTypeGuid &&
                        (!isEquation || type.label.toLowerCase() !== ProductMixTypes.CHEMICAL)
                ) ||
                productMixTypes.find(
                    (type) => type.label.toLowerCase() === ProductMixTypes.FERTILIZER
                );
            this._updateProductMix({
                productMixTypeGuid: productMixType.value,
                productMixType: productMixType.label,
            });
            switch (productMixType.label.toLowerCase()) {
                case ProductMixTypes.FERTILIZER:
                    this.setState({ selectedTab: 0 });
                    break;
                case ProductMixTypes.CHEMICAL:
                    this.setState({ selectedTab: 1 });
                    break;
                case ProductMixTypes.MANURE:
                    this.setState({
                        selectedTab: isEquation || isFromEquationRec ? 1 : 2,
                    });
                    break;
                default:
                    break;
            }
        }
    }
    // ---------------------- //
    render(): JSX.Element {
        const {
            activeProductMixIdx,
            areaId,
            productBlendGuid,
            calculatedArea,
            customBlends,
            hideAreaCalculations,
            isBatchRecEdit,
            isEquation,
            isEquationComplete,
            isFromEquationRec,
            isLime,
            isLoading,
            isOpen,
            nutrientName,
            productMix,
            recNutrient,
        } = this.props;

        const { customProductList, selectedExistingBlendGuid } = this.state;
        const { formatMessage } = this.props.intl;
        const mixType = productMix.productMixType.toLowerCase();
        const customList =
            mixType !== ProductMixTypes.MANURE
                ? customBlends.filter((blend) => blend.activeYn).sort(blending.sortCustomBlends)
                : customProductList;
        let nutrientRate = !isEquation
            ? null
            : recNutrient.recNutrientParameters.minimumIncludeZeros
            ? recNutrient.averageAdjustedRecNutrientResult.nutrientRate != null
                ? recNutrient.averageAdjustedRecNutrientResult.nutrientRate
                : recNutrient.averageRecNutrientResult.nutrientRate
            : recNutrient.averageAdjustedRecNutrientResult.appliedNutrientRate != null
            ? recNutrient.averageAdjustedRecNutrientResult.appliedNutrientRate
            : recNutrient.averageRecNutrientResult.appliedNutrientRate || 0;
        nutrientRate = isNaN(nutrientRate) ? 0 : nutrientRate;
        const recOfTextOptions = !isEquation
            ? { rate: "", rateUnit: "", appliedArea: "" }
            : {
                  rate: nutrientRate,
                  rateUnit: LBPERAC,
                  appliedArea: isBatchRecEdit
                      ? this._calculateAppliedAcresSum(recNutrient)
                      : recNutrient.recNutrientParameters.minimumIncludeZeros ||
                        recNutrient.averageAdjustedRecNutrientResult.nutrientRate ===
                            recNutrient.averageAdjustedRecNutrientResult.appliedNutrientRate
                      ? this.state.appliedAreaIncludeZeros
                      : recNutrient.averageAdjustedRecNutrientResult.appliedArea,
              };

        const isImport = productMix.isImport;
        const titleText = !isEquation
            ? !areaId
                ? !isImport || hideAreaCalculations
                    ? formatMessage(messages.productMixTitleText, {
                          id: activeProductMixIdx + 1,
                      })
                    : `${formatMessage(messages.productMixTitleText, {
                          id: activeProductMixIdx + 1,
                      })} ${formatMessage(messages.zoneAreaText, {
                          appliedArea: calculatedArea.toFixed(2),
                      })}`
                : `${formatMessage(messages.productMixTitleText, {
                      id: activeProductMixIdx + 1,
                  })} ${formatMessage(messages.productMixZoneText, {
                      areaId,
                      calculatedArea,
                  })}`
            : !isEquationComplete
            ? formatMessage(messages.equationProductMixTitleText, {
                  nutrientName: nutrientName,
              })
            : `${formatMessage(messages.equationProductMixTitleText, {
                  nutrientName: nutrientName,
              })}, ${formatMessage(messages.recOfText, recOfTextOptions)} `;
        const isBlend = productMix.products.length > 1;
        return (
            <DialogBox
                className={classnames("product-blend-modal", {
                    "equation-blend-modal": isEquation,
                    "lime-blend-modal": isLime,
                })}
                forceOverflow
                isOpen={isOpen}
                isModal={true}
                title={titleText}
                draggable={true}
                hideCloseX={true}
                actionDisabled={!this._saveEnabled()}
                onAction={() => this._onModalSave()}
                onClose={() => this._onModalCancel()}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                unrestricted={true}
            >
                {isLoading ? <Loader /> : null}
                {!blending.getIsDataLoaded(this.props) ? null : (
                    <div>
                        {isFromEquationRec ? null : (
                            <div className="load-custom-blend">
                                {!isBlend ? null : (
                                    <NoLink
                                        className="load-custom-blend-nolink"
                                        onClick={() => {
                                            this._logFirebaseEvent("clear");
                                            this._clearProducts();
                                        }}
                                        label={formatMessage(messages.clearBlendText, { mixType })}
                                    />
                                )}
                                {!isBlend ? null : <span className="separator">|</span>}
                                {mixType !== ProductMixTypes.FERTILIZER ? null : (
                                    <NoLink
                                        label={formatMessage(messages.createEditCustomBlendText, {
                                            count: productMix.products.length,
                                            mixType,
                                        })}
                                        onClick={() => {
                                            if (productMix.products.length <= 1) {
                                                this._logFirebaseEvent("create");
                                            } else {
                                                this._logFirebaseEvent("edit");
                                            }
                                            this.setState({
                                                isBlendingActive: true,
                                                blendingOriginal: {
                                                    ...productMix,
                                                },
                                            });
                                        }}
                                    />
                                )}
                                {mixType !== ProductMixTypes.FERTILIZER ? null : (
                                    <span className="separator">|</span>
                                )}
                                <NoLink
                                    className="load-custom-blend-nolink"
                                    onClick={() => {
                                        this._logFirebaseEvent("load");
                                        this._toggleLoadCustomBlend();
                                    }}
                                    label={formatMessage(messages.loadCustomBlendText, { mixType })}
                                />
                                <DialogBox
                                    className="load-custom-blend-modal"
                                    isOpen={this.state.loadCustomBlendModalOpen}
                                    isModal={true}
                                    title={formatMessage(messages.loadBlendTitleText, { mixType })}
                                    draggable={true}
                                    hideCloseX={true}
                                    actionDisabled={this.state.selectedExistingBlendIdx === -1}
                                    action={formatMessage(messages.loadText)}
                                    onAction={() => this._loadBlend()}
                                    onClose={() => this._cancelLoadBlend()}
                                    footerType={DialogBoxFooterType.ACTION_CLOSE}
                                    unrestricted
                                >
                                    {mixType !== ProductMixTypes.MANURE &&
                                    mixType !== ProductMixTypes.CHEMICAL ? (
                                        <div className="load-blend-table-header load-blend-table-row">
                                            <div className="blend-table-name">
                                                {formatMessage(messages.nameText)}
                                            </div>
                                            <div className="blend-table-ga">
                                                {formatMessage(messages.guaranteedAnalysisText)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="load-blend-table-header load-blend-table-row">
                                            <div className="blend-table-custom">
                                                {formatMessage(messages.nameText)}
                                            </div>
                                        </div>
                                    )}
                                    <div className="load-blend-table-body">
                                        {customList.map((blend, index) =>
                                            mixType === ProductMixTypes.FERTILIZER ? (
                                                <div
                                                    key={`custom-blend-${index}`}
                                                    className={classnames("load-blend-table-row", {
                                                        "selected-custom-blend":
                                                            index ===
                                                            this.state.selectedExistingBlendIdx,
                                                    })}
                                                    onClick={() =>
                                                        this.setState({
                                                            selectedExistingBlendIdx: index,
                                                            selectedExistingBlendGuid:
                                                                blend.productBlendGuid,
                                                        })
                                                    }
                                                >
                                                    <div
                                                        className="blend-table-name"
                                                        title={blend.name}
                                                    >
                                                        {blend.name}
                                                    </div>
                                                    <div
                                                        className="blend-table-ga"
                                                        title={blend.guaranteedAnalysis}
                                                    >
                                                        {blend.guaranteedAnalysis}
                                                    </div>
                                                    {blend.productBlendGuid !==
                                                    selectedExistingBlendGuid ? null : (
                                                        <div
                                                            className="remove-product-icon"
                                                            onClick={() => {
                                                                isEquation
                                                                    ? logFirebaseEvent(
                                                                          "equation_rec_delete_blend"
                                                                      )
                                                                    : logFirebaseEvent(
                                                                          "application_delete_blend"
                                                                      );
                                                                this.setState({
                                                                    deactivateProductBlendModalOpen:
                                                                        true,
                                                                });
                                                            }}
                                                        >
                                                            <TrashcanIcon />
                                                        </div>
                                                    )}
                                                </div>
                                            ) : mixType === ProductMixTypes.MANURE ? (
                                                <div
                                                    key={`custom-blend-${index}`}
                                                    className={classnames("load-blend-table-row", {
                                                        "selected-custom-blend":
                                                            index ===
                                                            this.state.selectedExistingBlendIdx,
                                                    })}
                                                    onClick={() =>
                                                        this.setState({
                                                            selectedExistingBlendIdx: index,
                                                        })
                                                    }
                                                >
                                                    <div
                                                        className="blend-table-custom"
                                                        title={blend.guaranteedAnalysis}
                                                    >
                                                        {blend.customProductType} -{" "}
                                                        {blend.guaranteedAnalysis} (
                                                        {blend.physicalState})
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    key={`custom-blend-${index}`}
                                                    className={classnames("load-blend-table-row", {
                                                        "selected-custom-blend":
                                                            index ===
                                                            this.state.selectedExistingBlendIdx,
                                                    })}
                                                    onClick={() =>
                                                        this.setState({
                                                            selectedExistingBlendIdx: index,
                                                            selectedExistingBlendGuid:
                                                                blend.productBlendGuid,
                                                        })
                                                    }
                                                >
                                                    <div
                                                        className="blend-table-chemical"
                                                        title={blend.name}
                                                    >
                                                        {blend.name}
                                                    </div>
                                                    {blend.productBlendGuid !==
                                                    selectedExistingBlendGuid ? null : (
                                                        <div
                                                            className="remove-product-icon"
                                                            onClick={() => {
                                                                logFirebaseEvent(
                                                                    "application_delete_mix"
                                                                );
                                                                this.setState({
                                                                    deactivateProductBlendModalOpen:
                                                                        true,
                                                                });
                                                            }}
                                                        >
                                                            <TrashcanIcon />
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </DialogBox>
                            </div>
                        )}
                        <div className="blend-type">
                            <Tabs
                                className="blend-type-tabs"
                                selected={this.state.selectedTab}
                                onTabSelect={(idx) => this._changeProductMixTypeTab(idx)}
                            >
                                {this._getPanes()}
                            </Tabs>
                        </div>
                    </div>
                )}
                <DialogBox
                    isOpen={
                        productBlendGuid != null &&
                        mixType !== ProductMixTypes.MANURE &&
                        !this.state.isEquationSavingBlend
                    }
                    isModal={true}
                    title={formatMessage(messages.saveBlendTitleText, {
                        mixType,
                    })}
                    draggable={true}
                    hideCloseX={true}
                    onAction={() => {
                        this._saveBlend();
                        this._logFirebaseEvent("save");
                    }}
                    onClose={() => this._cancelUpdateProductBlend()}
                    footerType={DialogBoxFooterType.YES_NO}
                >
                    {formatMessage(messages.blendNameAlreadyUsedMessage, {
                        blendName: this.state.blendName,
                        mixType,
                    })}
                </DialogBox>

                <DialogBox
                    isOpen={this.state.clearProductsModalOpen}
                    isModal={true}
                    title={formatMessage(messages.clearProductsTitleText)}
                    draggable={true}
                    hideCloseX={true}
                    action={formatMessage(messages.continueText)}
                    onAction={() => this._confirmClearProducts()}
                    onClose={() => this._cancelClearProducts()}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                >
                    {formatMessage(messages.clearProductsMessage)}
                </DialogBox>

                <DialogBox
                    isOpen={this.state.storedCodes.length > 0}
                    isModal={true}
                    title={formatMessage(messages.invalidValue)}
                    draggable={true}
                    onClose={() => this._resetToValidRecNutrient()}
                    footerType={DialogBoxFooterType.NONE}
                    unrestricted
                >
                    {this._getValidationErrorMessage()}
                </DialogBox>

                <DialogBox
                    isOpen={this.state.densityValidationCode !== 0}
                    isModal={true}
                    title={formatMessage(messages.invalidValue)}
                    draggable={true}
                    onClose={() => this.setState({ densityValidationCode: 0 })}
                    footerType={DialogBoxFooterType.NONE}
                >
                    {this._getValidationErrorMessage()}
                </DialogBox>

                <DialogBox
                    isOpen={this.state.deactivateProductBlendModalOpen}
                    isModal={true}
                    title={formatMessage(messages.deleteConfirmTitleText)}
                    draggable={true}
                    hideCloseX={true}
                    action={formatMessage(messages.deleteText)}
                    onAction={() => this._confirmDeactivateProductBlend()}
                    onClose={() =>
                        this.setState({
                            deactivateProductBlendModalOpen: false,
                        })
                    }
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                >
                    {formatMessage(messages.deleteMessage)}
                </DialogBox>
            </DialogBox>
        );
    }
}
