import { all, put, select, takeEvery } from "redux-saga/effects";
import { actions as accordionActions } from "~/accordion";
import { AccordionItem } from "~/accordion/model";
import * as nonFieldFeatureSelectors from "~/action-panel/components/layer-module/components/layer-list/non-field-data/selectors";

import * as actionPanelActions from "~/action-panel/actions";
import * as actionPanelSelectors from "~/action-panel/selectors";
import { actions as layerListActions, selectors as layerListSelectors } from "../";
import { tabIndices } from "../data";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import * as nonFieldFeatureInformationSelectors from "~/action-panel/components/layer-module/components/non-field-feature-information/selectors";
import { actions as messagingActions } from "~/messaging";

import { Map, Set } from "immutable";
import { NonFieldFeature } from "@ai360/core/dist/4x/es/api/non-field-feature";

interface ICustomer {
    customerGuid: string;
    name: string;
}

const constructCustomerAccordionItem = (customer: ICustomer) => {
    const customerPayload = {
        customer,
    };

    const height = 56;
    return new AccordionItem(height, false, customerPayload);
};

const constructFeatureAccordionItem = (
    feature: NonFieldFeature,
    customer: ICustomer,
    isHighlighted: boolean
) => {
    const featurePayload = {
        feature,
        customer,
        isHighlighted,
    };

    const height = 50;
    return new AccordionItem(height, false, featurePayload);
};

export const constructAccordion = function* () {
    if (
        (yield select(actionPanelSelectors.getActiveModule)) !==
        actionPanelActions.ActionPanelModuleList.LAYER
    ) {
        return;
    }

    if ((yield select(layerListSelectors.getCurrentTabIndex)) !== tabIndices.nonFieldData) {
        return;
    }

    if (yield select(nonFieldFeatureInformationSelectors.getIsEditing)) {
        return;
    }

    const previousAccordionItems: AccordionItem[] = yield select(nonFieldFeatureSelectors.getItems);
    const previousAccordionItemMap = Map(
        previousAccordionItems.map((accordionItem) => [
            accordionItem.payload.customer.name,
            accordionItem,
        ])
    );

    const newFeatures: Set<NonFieldFeature> = yield select(cdSelectors.getSelectedNonFieldFeatures);
    const highlightedFeatures: Set<NonFieldFeature> = yield select(
        cdSelectors.getHighlightedNonFieldFeatures
    );

    const newCustomerAccordionItems = newFeatures
        .groupBy((feature) => feature.customerName)
        .map((features, customerName) => {
            const previousAccordionItem = previousAccordionItemMap.get(customerName);
            const customer = {
                customerGuid: features.first().customerId,
                name: customerName,
            };

            const children = features
                .valueSeq()
                .toArray()
                .map((feature) =>
                    constructFeatureAccordionItem(
                        feature,
                        customer,
                        highlightedFeatures.has(feature)
                    )
                )
                .sort((left, right) =>
                    left.payload.feature.name < right.payload.feature.name ? -1 : 1
                );

            const expanded = previousAccordionItem ? previousAccordionItem.expanded : false;

            return AccordionItem.updateAccordionItem(constructCustomerAccordionItem(customer), {
                expanded,
                children,
            });
        })
        .valueSeq()
        .toArray()
        .sort((left, right) => (left.payload.customer.name < right.payload.customer.name ? -1 : 1));

    const accordionId = yield select(nonFieldFeatureSelectors.getId);
    yield put(accordionActions.replaceAllAccordionItems(accordionId, newCustomerAccordionItems));
};

export const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(
            0,
            {
                eventName: "nonFieldFeatureMerged",
                action: (message) => cdActions.fetchNonFieldFeaturesForIds([message]),
            },
            {
                eventName: "nonFieldFeatureDeleted",
                action: (message) => cdActions.modifyNonFieldFeatures({ remove: [message] }),
            }
        )
    );
};

export const nonFieldDataSaga = function* () {
    yield all([
        takeEvery(
            [
                actionPanelActions.ACTIONPANEL_SET_ACTIVEMODULE,
                layerListActions.SET_CURRENT_TAB_INDEX,
                cdActions.SET_SELECTED_NON_FIELD_FEATURES,
                cdActions.MODIFY_SELECTED_NON_FIELD_FEATURES,
                cdActions.SET_HIGHLIGHTED_NON_FIELD_FEATURES,
                cdActions.MODIFY_HIGHLIGHTED_NON_FIELD_FEATURES,
            ],
            constructAccordion
        ),
        messageSubscriptions(),
    ]);
};
