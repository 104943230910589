import { put, select } from "redux-saga/effects";

import { model as accordionModel, actions as accordionActions } from "~/accordion";
import { selectors as cdSelectors } from "~/customer-data";

import { createFieldAccordionItems, getFieldGuidFromDimIdx } from "../common/accordion/model";
import { eventsModels, recsModels } from "~/recs-events";
import { SearchAPI } from "@ai360/core";
import { OrderedMap } from "immutable";
import { AccordionItem, MultiDimIdx } from "~/accordion/model";

type RecEventSummaries = eventsModels.AgEventSummary[] | recsModels.RecSummary[];

export const addAccordionRecEventItems = function* (
    accordionId: number,
    createAccordionItems: (recEventList: any) => any,
    expandedGuidSet: Set<string>,
    filter: any,
    search: string,
    filterList: (
        recEventList: any,
        filter: any,
        selectedGuidSet: Set<string>,
        search: string
    ) => any,
    fieldGuidToRecEventListMap: OrderedMap<string, RecEventSummaries>,
    fieldGuidToFieldMap: OrderedMap<string, SearchAPI.IFieldResult>,
    selectedGuidSet: Set<string>
): any {
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const selectedFieldList = [...fieldGuidToFieldMap.entries()]
        .filter(([fieldGuid]) => selectedFieldGuidSet.has(fieldGuid))
        .map(([fieldGuid, field]) => ({
            fieldGuid,
            fieldBoundaryGuid: field.boundaryId,
            customerGuid: field.customerId,
            customerName: field.customerName,
            name: field.name,
            farmName: field.farmName,
            acres: field.acres,
        }));
    const fieldItems = createFieldAccordionItems(selectedFieldList, expandedGuidSet);
    const filteredFieldItems = [];
    for (const [idx, field] of selectedFieldList.entries()) {
        const fieldItem = fieldItems[idx];
        let recEventList = fieldGuidToRecEventListMap.get(field.fieldGuid);
        if (!recEventList) {
            continue;
        }
        search && (search = search.toLowerCase());
        if (filter || (search && search !== "")) {
            recEventList = filterList(recEventList, filter, selectedGuidSet, search);
            if (recEventList.length === 0) {
                continue;
            }
        }
        recEventList.sort((a, b) => b.compare(a));
        const children = createAccordionItems(recEventList);
        const fieldItemUpdatedProps =
            filter || (search && search !== "")
                ? {
                      children,
                      expanded: true,
                  }
                : {
                      children,
                  };
        filteredFieldItems.push(
            accordionModel.AccordionItem.updateAccordionItem(fieldItem, fieldItemUpdatedProps)
        );
    }

    yield put(accordionActions.replaceAllAccordionItems(accordionId, filteredFieldItems));
};

export const removeDeselectedIfOnlySelected = (
    accordionItems: AccordionItem[],
    endDimIdx: MultiDimIdx,
    startDimIdx: MultiDimIdx
): Record<string, any>[] => {
    const fieldGuidChildrenLengthMap = new Map();
    const fieldGuidToRemoveDimIdxListMap = new Map();
    for (const dimIdx of accordionModel.getDimIdxSliceIter(
        accordionItems,
        startDimIdx,
        endDimIdx,
        Boolean(endDimIdx),
        [2]
    )) {
        console.assert(dimIdx.length === 2);
        const fieldDimIdx = dimIdx.slice(0, 1);
        const fieldGuid = getFieldGuidFromDimIdx(accordionItems, fieldDimIdx);
        if (!fieldGuidToRemoveDimIdxListMap.has(fieldGuid)) {
            const fieldItem = accordionModel.getItem(accordionItems, fieldDimIdx);
            fieldGuidChildrenLengthMap.set(fieldGuid, fieldItem.children.length);
            fieldGuidToRemoveDimIdxListMap.set(fieldGuid, []);
        }
        fieldGuidToRemoveDimIdxListMap.get(fieldGuid).push(dimIdx);
    }

    const itemsToRemove = [];
    for (const [fieldGuid, toRemove] of fieldGuidToRemoveDimIdxListMap.entries()) {
        itemsToRemove.push(...toRemove);
        if (fieldGuidChildrenLengthMap.get(fieldGuid) === toRemove.length) {
            const fieldDimIdx = toRemove[0].slice(0, -1);
            itemsToRemove.push(fieldDimIdx);
        }
    }
    return itemsToRemove;
};
