import { all, call, put, select, fork, take, takeEvery, takeLatest } from "redux-saga/effects";

import Immutable from "immutable";
import { getTheUserGuid, getTheUserPersonalityId } from "~/login/selectors";
import { actions as accActions, model as accModels } from "~/accordion";
import {
    actions as cdActions,
    models as cdModels,
    selectors as cdSelectors,
} from "~/customer-data";
import { actions as notificationActions } from "~/notifications";
import { FieldAPI, SearchAPI } from "@ai360/core";
import { ISelectOption } from "@ai360/core";

import * as fldActions from "../../actions";
import * as fldSelectors from "../../selectors";
import { IrrigatedListOptions, dropdowns, pickLists } from "../../sagas";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { IFieldFilterOptions } from "./models";
import { filteredFieldRequest } from "~/utils/api/search";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { fetchPicklistData } from "~/core/picklist/actions";
import { PersonalityTypes } from "~/utils/keywords";

const CUSTOMER_ITEM_HEIGHT = 55;
const FIELD_ITEM_HEIGHT = 45;
const INFINITE_SCROLL_HEIGHT = 300;

const createFieldsAccordion = function* () {
    yield put(fldActions.setIsFieldLoading(true));

    const accordionId = yield select(selectors.getAccordionId);
    const expandedCustomers: Immutable.Set<string> = yield select(
        selectors.getAllExpandedCustomers
    );
    const activeTab: fldActions.FieldListTabs = yield select(fldSelectors.getActiveTab);
    const isInactiveTab = activeTab === fldActions.FieldListTabs.INACTIVE;

    const isDoneFetchingCustomers: boolean = yield select(cdSelectors.getIsDoneFetchingCustomers);
    const customerMap: Map<string, cdModels.CustomerInfo> = yield select(
        cdSelectors.getCustomerMap
    );
    const fieldMap: Map<string, cdModels.FieldInfo> = yield select(cdSelectors.getFieldMap);
    const customers = [...customerMap.values()];
    const fields = [...fieldMap.values()];

    const customerAccordionItems = customers.map((customer, index) => {
        const isExpanded = expandedCustomers.has(customer.customerGuid);
        const customerItem = new accModels.AccordionItem(CUSTOMER_ITEM_HEIGHT, isExpanded, {
            customer,
            isLast: index === customers.length - 1,
            isActiveInactive: isInactiveTab && customer.activeYn,
        });
        const children = fields
            .filter((x) => x.customerGuid === customer.customerGuid)
            .map(
                (field) =>
                    new accModels.AccordionItem(FIELD_ITEM_HEIGHT, false, { field, customer })
            );
        return accModels.AccordionItem.updateAccordionItem(customerItem, { children });
    });
    if (!isDoneFetchingCustomers) {
        customerAccordionItems.push(
            new accModels.AccordionItem(INFINITE_SCROLL_HEIGHT, false, { infiniteScroll: true })
        );
    }

    yield put(accActions.replaceAllAccordionItems(accordionId, customerAccordionItems));

    yield put(fldActions.setIsFieldLoading(false));
};

const expandCollapseAllCustomers = function* (action: any) {
    const { accordionId } = action.payload;
    const currentAccordionId = yield select(selectors.getAccordionId);
    if (accordionId !== currentAccordionId) {
        return;
    }
    const expandingAll = yield select(selectors.getExpandingAll);
    if (action.type === accActions.EXPAND_ALL) {
        if (expandingAll) {
            return;
        }

        yield put(actions.expandAllCustomerItems());

        const customerMap: Immutable.OrderedMap<string, cdModels.CustomerInfo> = yield select(
            cdSelectors.getCustomerMap
        );
        const fields: SearchAPI.IFieldResult[] = yield call(
            SearchAPI.getFields,
            yield filteredFieldRequest([...customerMap.keys()])
        );
        yield put(actions.setManualExpandedCustomers(fields.map((x) => x.customerId)));
        yield put(cdActions.fetchFieldsSuccess(fields));
    } else {
        if (!expandingAll) {
            return;
        }
        yield put(actions.collapseAllCustomerItems());
        yield put(actions.setManualExpandedCustomers(Immutable.Set()));
    }
};

const expandCollapseCustomer = function* (action: any) {
    const { accordionId, index } = action.payload;
    const currentAccordionId = yield select(selectors.getAccordionId);
    if (accordionId !== currentAccordionId) {
        return;
    }

    const filteredItems = yield select(selectors.getAccordionItems);
    const customerItem = accModels.getItem(filteredItems, index);
    const { customerGuid } = customerItem.payload.customer;

    const hasFields = yield select(cdSelectors.getHasFields, customerGuid);
    if (!hasFields) {
        return;
    }

    if (customerItem.expanded) {
        // Customer was just expanded
        yield put(actions.expandCustomerItem(customerGuid));
        yield put(cdActions.fetchFields(customerGuid));
    } else {
        yield put(actions.collapseCustomerItem(customerGuid));
    }
};

const fetchFilters = function* (action: any) {
    const userGuid = yield select(getTheUserGuid);

    try {
        const response: SearchAPI.IFilterResponse = yield call(SearchAPI.getFilters, {
            userGuid,
            locationOrgLevelGuid: action.payload.locationOrgLevelGuid,
        });

        const mapSelectOption = (option: string): ISelectOption<string> => ({
            label: option,
            value: option,
        });

        const mapBoolean = (value: boolean): ISelectOption<boolean> => ({
            value,
            label: value ? "Yes" : "No",
        });

        const filterIrrigated = (option: ISelectOption<number>): boolean =>
            response.irrigated.includes(option.value);

        const options: IFieldFilterOptions = {
            classifications: response.classifications.map(mapSelectOption),
            crops: response.crops.map(mapSelectOption),
            salespeople: response.salespeople.map(mapSelectOption),
            certifiedOrganic: response.certifiedOrganic.map(mapBoolean),
            enrolled: response.enrolled.map(mapBoolean),
            irrigated: IrrigatedListOptions.filter(filterIrrigated),
        };

        yield put(actions.fetchFiltersSuccess(options));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    }
};

const watchFilterSelectionsChanged = function* () {
    while (true) {
        const oldFilterSelections = yield select(selectors.getFilterSelections);
        yield take(actions.SET_FILTER_SELECTIONS);
        yield put(cdActions.restartCustomerFields());
        yield put(cdActions.fetchSummary());
        yield put(cdActions.fetchFilteredFieldGuids());
        const newFilterSelections = yield select(selectors.getFilterSelections);
        if (newFilterSelections.orgLevelGuid !== oldFilterSelections.orgLevelGuid) {
            yield put(actions.fetchFilters(newFilterSelections.orgLevelGuid));
        }
    }
};

const onSelectedTab = function* (execute: () => any) {
    const activeTab = yield select(fldSelectors.getActiveTab);
    if (activeTab === fldActions.FieldListTabs.SELECTED) {
        yield* execute();
    }
};

const onClearAllSelectedFields = function* () {
    yield* onSelectedTab(function* () {
        const accordionId = yield select(selectors.getAccordionId);
        yield put(accActions.removeAllAccordionItems(accordionId));
    });
};

const onClearSelectedFields = function* (action: any) {
    yield* onSelectedTab(function* () {
        const accordionId = yield select(selectors.getAccordionId);
        const clearedFieldGuidSet = Immutable.Set(action.payload.fieldGuids);
        let itemList: accModels.AccordionItem[] = yield select(selectors.getAccordionItems);
        itemList = accModels.AccordionItem.recursiveFilter(itemList, ({ item, dimIdx }) => {
            if (dimIdx.length === 1) {
                // Customer
                return true;
            }
            console.assert(dimIdx.length === 2); // Field
            const { fieldGuid } = item.payload.field;
            return !clearedFieldGuidSet.has(fieldGuid);
        });
        // remove customer accordion items with no selected fields ...
        itemList = itemList.filter((customerItem) => customerItem.children.length > 0);
        yield put(accActions.replaceAllAccordionItems(accordionId, itemList));
    });
};

const initBatchOptions = function* (action) {
    yield put(
        fetchDropdownData({
            ...dropdowns,
            action: fldActions.fetchedDropdownData,
            async: false,
        })
    );
    yield put(fetchPicklistData(pickLists));
    const personalityId = yield select(getTheUserPersonalityId);
    if (personalityId === PersonalityTypes.DISCONNECTED) {
        return;
    }
    try {
        const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
        const fieldData = yield call(FieldAPI.getField, selectedFieldGuidSet.first());
        yield put(
            fldActions.fetchAgvanceFieldClassificationList(fieldData.agvanceFieldOrgLevelGuid)
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const fieldListSaga = function* (): any {
    yield all([
        fork(watchFilterSelectionsChanged),
        takeEvery([accActions.EXPAND, accActions.COLLAPSE], expandCollapseCustomer),
        takeEvery([accActions.EXPAND_ALL, accActions.COLLAPSE_ALL], expandCollapseAllCustomers),
        takeEvery(actions.FETCH_FILTERS, fetchFilters),
        takeLatest(cdActions.CLEAR_ALL_SELECTED_FIELDS, onClearAllSelectedFields),
        takeLatest(cdActions.CLEAR_SELECTED_FIELDS, onClearSelectedFields),
        takeLatest(actions.FETCH_BATCH_OPTIONS, initBatchOptions),
        takeLatest(
            [
                cdActions.ACTIVATE_CUSTOMER,
                cdActions.ADD_UPDATE_CUSTOMER,
                cdActions.BATCH_UPDATE_FIELD,
                cdActions.DEACTIVATE_CUSTOMER,
                cdActions.MODIFY_FIELDS,
                cdActions.MODIFY_CUSTOMERS,
                cdActions.FETCH_CUSTOMER_FIELDS_SUCCESS,
                cdActions.FETCH_CUSTOMER_FIELDS_ERROR,
                cdActions.FETCH_FIELDS_SUCCESS,
                cdActions.MOVE_FIELDS,
                cdActions.REMOVE_CONNECTED_CUSTOMER,
                cdActions.BATCH_UPDATE_FIELD_EVENT_COUNT,
                cdActions.UPDATE_FIELD_EVENT_COUNT,
                cdActions.BATCH_UPDATE_FIELD_REC_COUNT,
                cdActions.UPDATE_FIELD_REC_COUNT,
            ],
            createFieldsAccordion
        ),
    ]);
};
