import _ from "lodash";
import { FormattingHelpers } from "@ai360/core";
import moment from "moment";
import { adminData, GUID, NAME } from "~/admin/data";

export const getAgBytesErrorClassNames = (
    errorCodes: number | number[],
    apiErrors,
    classNames = [],
    applyClass = "form-input-error"
) => {
    const retClassNames = [...classNames];
    errorCodes = Array.isArray(errorCodes) ? errorCodes : [errorCodes];
    errorCodes.some((errorCode) => {
        if (apiErrors && apiErrors.includes(errorCode)) {
            retClassNames.push(applyClass);
            return true;
        }
        return false;
    });
    return retClassNames;
};

export const prepareSelectableOptions = (
    options,
    { guid, label, id, appendIdToLabel = false, details = null }
) =>
    _.reduce(
        options,
        (result, option) => {
            let labelText = option[label];
            if (appendIdToLabel && id) {
                labelText = `${option[id]} - ${labelText}`;
            }
            result.push({
                value: {
                    guid: option[guid],
                    id: option[id],
                    name: option[label],
                    activeYn:
                        option[adminData.PROPS_ACTIVE_YN] !== undefined
                            ? option[adminData.PROPS_ACTIVE_YN]
                            : true,
                    details: option[details],
                },
                label: labelText,
            });
            return result;
        },
        []
    );

export const mapToPicklistValue = ({ options = [], selectedGuid = "" }) => {
    if (options.length > 0 && selectedGuid) {
        const selectedOption = options.filter((option) => {
            return selectedGuid === option.value[GUID];
        });
        const { id, name, guid, activeYn, details } =
            selectedOption.length > 0 && selectedOption[0].value;
        return {
            activeYn,
            guid,
            id,
            name,
            details,
        };
    }
    return null;
};

export const mapNameToPicklistValue = ({ options = [], selectedName = "" }) => {
    if (options.length > 0 && selectedName) {
        const selectedOption = options.filter((option) => {
            return selectedName === option.value[NAME];
        });
        const { id, name, guid, activeYn, details } =
            selectedOption.length > 0 && selectedOption[0].value;
        return {
            activeYn,
            guid,
            id,
            name,
            details,
        };
    }
    return null;
};

export const handlePicklistChange = (record, { type, guid, value }, callback, newApi = false) => {
    if (!value) {
        record[type] = "";
        record[guid] = !newApi ? "" : null;
    } else if (guid) {
        if (type) {
            record[type] = value.name;
        }
        record[guid] = value.guid;
    } else {
        console.debug(`${type} / ${guid} / ${JSON.stringify(value)} value is null`);
    }
    callback && callback(value || {});
    return record;
};

export const onNumberChange = (record, { formKey, value }, callback?: () => void) =>
    onTextChange(
        record,
        {
            formKey,
            value: value === "" ? value : Number(FormattingHelpers.parseStringToInt(value)),
        },
        callback
    );

export const onTextChange = (record, { formKey, value }, callback) => {
    if (formKey && value != null) {
        record[formKey] = value;
        callback && callback();
    }
    return record;
};

export const onTextChangeObj = (record, { formKey, propertyName, value }, callback) => {
    if (formKey && value != null) {
        record[formKey][propertyName] = value;
        callback && callback();
    } else {
        console.error(`${formKey}, ${propertyName} or ${value} value is null`);
    }
    return record;
};

export const parseLabel = (label) => {
    if (label) {
        const arr = label.trim().split("-");
        return { id: arr[0], name: arr[1] };
    }
};

export const getCountryGuid = ({ options = [], selectedId = "3" }) => {
    if (options.length) {
        const selectedOption = options.filter((option) => {
            return selectedId === option.value.id;
        });
        const { guid } = selectedOption.length && selectedOption[0].value;
        return guid;
    }
    return null;
};

// parse moment object to date string
export const formatDate = (dateVal, format = "M/D/YYYY, hh:mm:ss A") => {
    if (dateVal) {
        return moment(dateVal).format(format);
    }
    return null;
};

export const getLabel = ({ options = [], guid }, valueKey = "guid", labelKey = "label") => {
    if (options.length) {
        const selectedOption = options.filter((option) => {
            return guid === option.value[valueKey];
        });
        return selectedOption.length ? selectedOption[0][labelKey] : null;
    }
    return null;
};

export const getGuid = ({ options = [], label }, valueKey = "guid", labelKey = "label") => {
    if (options.length) {
        const selectedOption = options.filter((option) => {
            return label === option[labelKey];
        });
        return selectedOption.length ? selectedOption[0].value[valueKey] : null;
    }
    return null;
};

/**
 * Concatenates the passed i18n message and the value into the proper format for a plain text label
 * @param {formatMessage} function passed for i18n
 * @param {label} the i18n object to be formatted
 * @param {value} the string value to be formatted
 */
export function formatPlainTextLabel(formatMessage, label, value) {
    return `${formatMessage(label)}: ${value}`;
}

export const onTextChangeMultiProp = (record, { parentKey, formKey, value }, callback) => {
    if (parentKey && formKey && value != null) {
        record[parentKey][formKey] = value;
        callback && callback();
    } else {
        console.error(`${parentKey}, ${formKey} or ${value} value is null`);
    }
    return record;
};

/**
 * Prevents events from bubbling up when submitting a form
 * @param {event}
 */
export const preventBubbleUp = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

/**
 * Prevents events from bubbling up when submitting a form
 * @param {arr} Array to be mapped and add restrictAddEdit property to it
 */
export const addRestrictEditDeleteModeZtoN = (arr) => {
    return arr.map((item) => ({ ...item, restrictEditDelete: true }));
};

export const hasOnlyInActiveOptions = (
    options = [],
    valueKey = "value",
    allowEmptyOptions = false
) => {
    if (!options || !options.length) {
        return !allowEmptyOptions;
    } else {
        const isUndefined = (value) => value === undefined;
        return !options.some((option) => {
            if (
                typeof option[valueKey] === "object" &&
                option[valueKey] &&
                adminData.PROPS_ACTIVE_YN in option[valueKey]
            ) {
                const activeYn = option[valueKey][adminData.PROPS_ACTIVE_YN];
                return activeYn || isUndefined(activeYn);
            } else if (adminData.PROPS_ACTIVE_YN in option) {
                const activeYn = option[adminData.PROPS_ACTIVE_YN];
                return activeYn || isUndefined(activeYn);
            } else {
                return option;
            }
        });
    }
};
