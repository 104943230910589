import { connect } from "react-redux";
import { withMasked, withApiResult } from "~/hocs";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { actions } from "./data";
import * as selectors from "./data/selectors";
import { getUser } from "~/login/selectors";
import { getCurrentTheme, switchTheme } from "~/theme";
import UserPreference from "./preference";

const mapStateToProps = (state) => ({
    userPreference: selectors.getUserPreference(state),
    currentTheme: getCurrentTheme(state),
    userRole: getUser(state).role,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPreference: actions.fetchPreference,
    savePreference: actions.savePreference,
    switchTheme: (payload) => dispatch(switchTheme(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
});

export default withMasked(
    withApiResult(connect(mapStateToProps, mapDispatchToProps)(UserPreference))
);
