export const PROPS_AGE_OF_SAMPLE = "ageOfSample";
export const PROPS_ALL_CUSTOMERS_FIELDS_YN = "allCustomersFieldsYn";
export const PROPS_AGVANCE_COLOR = "agvanceCropColor";
export const PROPS_BATCH_GUID = "batchGuid";
export const PROPS_BASEMAP = "basemap";
export const PROPS_BOUNDARY_MAP_QR = "boundaryMapQrCode";
export const PROPS_BREAK_EVEN_CROP_DATA = "breakEvenCropData";
export const PROPS_CROPPING_SEASON_GUID_LIST = "croppingSeasonGuidList";
export const PROPS_CROPPING_SEASON_GUID = "croppingSeasonGuid";
export const PROPS_CROP_DATA = "cropData";
export const PROPS_CROP_GUID = "cropGuid";
export const PROPS_CROP_MAP_ORIENTATION = "cropMapOrientation";
export const PROPS_CROP_ZONE = "cropZone";
export const PROPS_DISPLAY_PARAMETERS = "displayParameters";
export const PROPS_DISPLAY_SAMPLE_SITES = "displaySampleSites";
export const PROPS_DISPLAY_FIELD_ACRES = "displayFieldAcres";
export const PROPS_DISPLAY_TRAITS = "displayTraits";
export const PROPS_DISPLAY_TYPE = "displayType";
export const PROPS_END_DATE = "endDate";
export const PROPS_FIELD_LABEL_CONFIG = "fieldLabelConfig";
export const PROPS_FIELD_GUID_LIST = "fieldGuidList";
export const PROPS_FIELD_GUIDS = "fieldGuids";
export const PROPS_FIELD_ITEM_LIST = "fieldItemList";
export const PROPS_FIELDS_PER_PAGE = "fieldsPerPage";
export const PROPS_GENERAL_NOTES = "generalNotes";
export const PROPS_GROWER_SUMMARY_DISPLAY_OPTION = "growerSummaryDisplayOption";
export const PROPS_HGSUMMARY_DISPLAY_OPTION = "hyGroundSummaryDisplayOption";
export const PROPS_AGRPL_DISPLAY_OPTION = "agrProfitLossDisplayOption";
export const PROPS_INCLUDE_PRODUCT_RECAP = "includeProductRecap";
export const PROPS_INDIVIDUAL_RB = "individualReportBook";
export const PROPS_IS_HOURLY = "isHourly";
export const PROPS_LAB_ACCOUNT = "labAccount";
export const PROPS_LABEL_SIZE = "labelSize";
export const PROPS_LABEL_TYPE = "cropMapLabelType";
export const PROPS_LEGEND_TYPE = "cropMapLegendType";
export const PROPS_LOW_RATE_LEGEND = "lowRateLegend";
export const PROPS_MULTI_FIELD_REC = "multiFieldRec";
export const PROPS_MULTIPLE_MAPS_PER_PAGE = "multipleMapsPerPage";
export const PROPS_NEW_REPORT_TYPE_NAME_LIST = "newReportTypeNameList";
export const PROPS_NUMBER_OF_SEASONS = "numberOfSeasons";
export const PROPS_NUTRIENT_TARGET_COLORS = "nutrientTargetColors";
export const PROPS_ORIENTATION = "orientation";
export const PROPS_ORIGINAL_FORMAT = "originalFormat";
export const PROPS_PAGE_SIZE = "pageSize";
export const PROPS_PLANTING_ATTRIBUTE = "plantingAttribute";
export const PROPS_PLANTING_REC_COST_DETAIL = "plantingRecCostDetail";
export const PROPS_PLANTING_SUMMARY = "displaySummary";
export const PROPS_PLSS_INFO = "plssInfo";
export const PROPS_PRINT_FARM_TOTALS = "printFarmTotals";
export const PROPS_PRINT_FIELD_TOTALS = "printFieldTotals";
export const PROPS_PRINT_GROWER_TOTALS = "printGrowerTotals";
export const PROPS_REPORT_BOOK_GROUP_BY = "reportBookGroupBy";
export const PROPS_REPORT_IMAGE_CONFIG = "reportImageConfig";
export const PROPS_REPORT_STATUS = "reportStatus";
export const PROPS_REPORT_TYPE_GUID_LIST = "reportTypeGuidList";
export const PROPS_REPORT_TYPE_PAGE_SELECTION = "reportTypePageSelection";
export const PROPS_SAMPLE_BAG_LABEL_TYPE = "sampleBagLabelType";
export const PROPS_SAMPLE_SITES = "sampleSites";
export const PROPS_SELECTED_EVENT_GUID_LIST = "selectedEventGuidList";
export const PROPS_SELECTED_FILENAME_FORMAT = "selectedFileNameFormat";
export const PROPS_SELECTED_REC_GUID_LIST = "selectedRecGuidList";
export const PROPS_SELECTED_TEST_PACKAGES = "selectedTestPackages";
export const PROPS_SELECTED_TESTING_LAB = "selectedTestingLab";
export const PROPS_SHOW_GUARANTEED_ANALYSIS = "showGuaranteedAnalysis";
export const PROPS_SHOW_LOADSHEET_PRODUCT_DETAIL = "showLoadsheetProductDetail";
export const PROPS_SHOW_NON_FIELD_FEATURES = "showNonFieldFeatures";
export const PROPS_SHOW_FARM_TOTALS = "showFarmTotals";
export const PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL = "showGrowerSummaryCostDetail";
export const PROPS_SHOW_GROWER_TOTALS = "showGrowerTotals";
export const PROPS_SHOW_PHOTOS = "showPhotos";
export const PROPS_SHOW_PRODUCT_REC_COST_DETAIL = "showProductRecCostDetail";
export const PROPS_SHOW_PRODUCT_REC_SUMMARY_COST_DETAIL = "showProductRecSummaryCostDetail";
export const PROPS_SHOW_QR_CODES = "showQrCodes";
export const PROPS_SHOW_REC_SUMMARY = "showRecSummary";
export const PROPS_USE_IMPORT_DATE = "useImportDate";
export const PROPS_SHOW_SCOUTING_DETAILS = "showScoutingDetails";
export const PROPS_SHOW_YIELD_POLYGON = "showYieldPolygon";
export const PROPS_SOIL_TYPE_ATTRIBUTE = "soilTypeAttributeGuid";
export const PROPS_START_DATE = "startDate";
export const PROPS_START_POSITION = "startPosition";
export const PROPS_SELECTED_BASEMAP = "selectedBasemap";
export const PROPS_TESTING_LAB_SAMPLE_TYPE_GUID = "testingLabSampleTypeGuid";
export const PROPS_TESTING_LAB_VENDOR_GUID = "testingLabVendorGuid";
export const PROPS_TEST_PACKAGE_GUID = "testPackageGuid";
export const PROPS_TOTALING_UNIT = "totalingUnit";
export const PROPS_MULTI_GROWER = "multiGrower";
export const PROPS_USE_CROP_PLANNING_SUMMARY = "useCropPlanningSummary";
export const PROPS_USE_CUSTOM_IMAGERY = "useCustomImagery";
export const PROPS_USE_OVERVIEW_MAP = "useOverviewMap";
export const PROPS_USE_PRODUCT_RECS = "useProductRecs";
export const PROPS_YIELD_ATTRIBUTE = "yieldAttribute";
export const PROPS_APPLICATION_ATTRIBUTE = "applicationAttribute";
export const PROPS_SKY_APPLICATION_ATTRIBUTE = "skyApplicationAttribute";
export const PROPS_ZOOM_LEVEL = "zoomLevel";
export const PROPS_NON_FIELD_FEATURE_GUIDS = "nonFieldFeatureGuids";
export const PROPS_CUSTOMER_GUIDS = "customerGuids";

export enum reportPreferenceKeys {
    ageOfSample = "AgeOfSample",
    agvanceCropColor = "AgvanceCropColor",
    boundaryMapQrCode = "BoundaryMapQrCode",
    boundaryMapReportImageConfig = "BoundaryMapReportImageConfig",
    boundaryMapReportNonFieldFeatures = "BoundaryMapReportNonFieldFeatures",
    breakEvenCropList = "BreakEvenCropList",
    cropMapOrientation = "CropMapOrientation",
    cropMapLegendType = "CropMapLegendType",
    cropMapLabelType = "CropMapLabelType",
    cropZone = "CropZone",
    displayParameters = "DisplayParameters",
    displaySampleSites = "DisplaySampleSites",
    displayType = "DisplayType",
    fieldsPerPage = "FieldsPerPage",
    fertilizerRecSummaryTotalingUnit = "fertilizerRecSummaryTotalingUnit",
    fieldLabelConfig = "FieldLabelConfig",
    generalNotes = "GeneralNotes",
    growerSummaryDisplayOption = "GrowerSummaryDisplayOption",
    hyGroundSummaryDisplayOption = "HyGroundSummaryDisplayOption",
    agrProfitLossDisplayOption = "AGRProfitLossDisplayOption",
    includeProductRecap = "IncludeProductRecap",
    labAccount = "LabAccount",
    labelSize = "LabelSize",
    labSubmissionSampleTypeGuid = "labSubmissionSampleTypeGuid",
    labSubmissionTestingLabVendorGuid = "LabSubmissionTestingLabVendorGuid",
    labSubmissionTestPackageGuid = "LabSubmissionTestPackageGuid",
    lowRateLegend = "LowRateLegend",
    multipleMapsPerPage = "MultipleMapsPerPage",
    numberOfSeasons = "NumberOfSeasons",
    nutrientTargetColors = "NutrientTargetColors",
    orientation = "Orientation",
    originalFormat = "OriginalFormat",
    plantingAttribute = "PlantingAttribute",
    plantingRecCostDetail = "PlantingRecCostDetail",
    plssInfo = "PlssInfo",
    printFarmTotals = "PrintFarmTotals",
    printFieldTotals = "PrintFieldTotals",
    printGrowerTotals = "PrintGrowerTotals",
    reportTypePageSelection = "ReportTypePageSelection",
    sampleBagLabelType = "SampleBagLabelType",
    selectedBasemap = "SelectedBasemap",
    showFarmTotals = "ShowFarmTotals",
    showGrowerTotals = "ShowGrowerTotals",
    showGuaranteedAnalysis = "ShowGuaranteedAnalysis",
    showLoadsheetProductDetail = "showLoadsheetProductDetail",
    showGrowerSummaryCostDetail = "ShowGrowerSummaryCostDetail",
    showPhotos = "ShowPhotos",
    showProductRecCostDetail = "ShowProductRecCostDetail",
    showProductRecSummaryCostDetail = "ShowProductRecSummaryCostDetail",
    useImportDate = "UseImportDate",
    showRecSummary = "ShowRecSummary",
    showScoutingDetails = "ShowScoutingDetails",
    showYieldPolygon = "ShowYieldPolygon",
    soilTypeAttributeGuid = "SoilTypeAttributeGuid",
    useCustomImagery = "UseCustomImagery",
    useCropPlanningSummary = "UseCropPlanningSummary",
    useOverviewMap = "UseOverviewMap",
    useProductRecs = "UseProductRecs",
    yieldAttribute = "YieldAttribute",
    applicationAttribute = "ApplicationAttribute",
    skyApplicationAttribute = "SkyApplicationAttribute",
}
