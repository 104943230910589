import { defineMessages } from "react-intl";

export const messages = defineMessages({
    addCustomProductText: {
        id: "productBlend.addCustomProductText",
        defaultMessage: "+ Add Manure Product",
    },
    addProductText: {
        id: "productBlend.addProductText",
        defaultMessage: "+ Add Product",
    },
    adjustPercentText: {
        id: "productBlend.adjustPercentText",
        defaultMessage: "Adjust %",
    },
    averageRateText: {
        id: "productBlend.averageRateText",
        defaultMessage: "Avg Rate",
    },
    applyCreditsText: {
        id: "productBlend.applyCreditsText",
        defaultMessage: "Apply Credits",
    },
    blendNameAlreadyUsedMessage: {
        id: "productBlend.blendNameAlreadyUsedMessage",
        defaultMessage:
            "This Blend Name ({blendName}) is already used.  Do you want to overwrite the existing {mixType, select, fertilizer {blend} chemical {mix} manure {manure product}}?",
    },
    blendNameText: {
        id: "productBlend.blendNameText",
        defaultMessage:
            "{mixType, select, fertilizer {Blend} chemical {Mix} manure {Manure Product}} Name",
    },
    carrierText: { id: "productBlend.carrierText", defaultMessage: "Carrier" },
    chemicalText: {
        id: "productBlend.chemicalText",
        defaultMessage: "Chemical",
    },
    clearBlendText: {
        id: "productBlend.clearBlendText",
        defaultMessage: "Clear {mixType, select, fertilizer {Blend} chemical {Mix}}",
    },
    clearCustomProductText: {
        id: "productBlend.clearCustomProductText",
        defaultMessage: "Clear",
    },
    clearProductsTitleText: {
        id: "productBlend.clearProductsTitleText",
        defaultMessage: "Removing Selected Products",
    },
    clearProductsMessage: {
        id: "productBlend.clearProductsMessage",
        defaultMessage: "This will remove selected products.",
    },
    continueText: {
        id: "productBlend.continueText",
        defaultMessage: "Continue",
    },
    costPerAcreText: {
        id: "productBlend.costPerAcreText",
        defaultMessage: "Cost/Ac ($)",
    },
    zoneAreaText: {
        id: "productBlend.zoneAreaText",
        defaultMessage: "({appliedArea, number} Zone Acres)",
    },
    createEditCustomBlendText: {
        id: "productBlend.createEditCustomBlendText",
        defaultMessage:
            "{count, plural, =0 {Create} one {Create} other {Edit}} Custom {mixType, select, fertilizer {Blend} chemical {Mix}}",
    },
    creditsNotAppliedText: {
        id: "productBlend.creditsNotAppliedText",
        defaultMessage: "The selected credits have not yet been applied.",
    },
    creditsText: { id: "productBlend.creditsText", defaultMessage: "Credits" },
    customBlendText: {
        id: "productBlend.customBlendText",
        defaultMessage: "Custom {mixType, select, fertilizer {Blend} chemical {Mix}}",
    },
    effectivePercentText: {
        id: "productBlend.effectivePercentText",
        defaultMessage: "Effective %",
    },
    eventsText: { id: "productBlend.eventsText", defaultMessage: "Events" },
    deleteConfirmTitleText: {
        id: "productBlend.deleteConfirmTitleText",
        defaultMessage: "Confirm",
    },
    deleteMessage: {
        id: "productBlend.deleteMessage",
        defaultMessage: "Are you sure you want to delete?",
    },
    deleteText: { id: "productBlend.deleteText", defaultMessage: "Delete" },
    densityText: { id: "productBlend.densityText", defaultMessage: "Density" },
    element: {
        id: "productBlend.element",
        defaultMessage:
            "{element, select, nitrogen {N} phosphorus {P2O5} potassium {K2O} calcium {Ca} magnesium {Mg} sulfur {S} iron {Fe} zinc {Zn} copper {Cu} manganese {Mn} boron {B} chlorine {Cl} molybdenum {Mo}}",
    },
    equationProductMixTitleText: {
        id: "productBlend.equationProductMixTitleText",
        defaultMessage: "Add Product for {nutrientName}",
    },
    fertilizerText: {
        id: "productBlend.fertilizerText",
        defaultMessage: "Fertilizer",
    },
    appliedAvgRateText: {
        id: "productBlend.appliedAvgRateText",
        defaultMessage: "Applied Avg Rate",
    },
    flatRateText: {
        id: "productBlend.flatRateText",
        defaultMessage: "Flat Nutrient Rate",
    },
    GAText: { id: "productBlend.GAText", defaultMessage: "GA" },
    guaranteedAnalysisText: {
        id: "productBlend.guaranteedAnalysisText",
        defaultMessage: "Guaranteed Analysis",
    },
    hideFilterText: {
        id: "productBlend.hideFilterText",
        defaultMessage: "Hide Filters",
    },
    invalidAdjust: {
        id: "productBlend.invalidAdjust",
        defaultMessage: "Adjustment must be between locked min and/or locked max.",
    },
    invalidAdjustLow: {
        id: "productBlend.invalidAdjustLow",
        defaultMessage:
            "The adjustment made cannot cause the Avg Rate to fall below the locked Min Rate.",
    },
    invalidAdjustHigh: {
        id: "productBlend.invalidAdjustHigh",
        defaultMessage:
            "The adjustment made cannot cause the Avg Rate to go above the locked Max Rate.",
    },
    invalidMinMax: {
        id: "productBlend.invalidMinMax",
        defaultMessage: "Minimum Rate cannot exceed Maximum Rate.",
    },
    invalidMinAdjust: {
        id: "productBlend.invalidMinAdjust",
        defaultMessage: "The Min Rate needs to be less than the Max Rate.",
    },
    invalidMaxAdjust: {
        id: "productBlend.invalidMaxAdjust",
        defaultMessage: "The Max Rate needs to be greater than the Min Rate.",
    },
    invalidResetImminent: {
        id: "productBlend.invalidResetImminent",
        defaultMessage: "Values will now be reverted back to their last valid adjustment.",
    },
    invalidValue: {
        id: "productBlend.invalidValue",
        defaultMessage: "Invalid Value",
    },
    invalidProductDensity: {
        id: "productBlend.invalidDensity",
        defaultMessage:
            "A product you added to this mix is missing a density and has been removed. This is required to save or use this Product Mix.",
    },
    invalidProductSpecificGravity: {
        id: "productBlend.invalidSpecificGravity",
        defaultMessage:
            "A dry product you added to this liquid blend is missing a specific gravity, which is required to save this Product Mix.",
    },
    invalidSwitchAdjust: {
        id: "productBlend.invalidSwitchAdjust",
        defaultMessage: "The Switch Rate needs to be less than the Min Rate.",
    },
    loadBlendTitleText: {
        id: "productBlend.loadBlendTitleText",
        defaultMessage:
            "Load {mixType, select, fertilizer {Custom Blend} chemical {Product Mix} manure {Manure Product}}",
    },
    loadCustomBlendText: {
        id: "productBlend.loadCustomBlendText",
        defaultMessage:
            "Load {mixType, select, fertilizer {Custom Blend} chemical {Product Mix} manure {Manure Product}}",
    },
    loadText: { id: "productBlend.loadText", defaultMessage: "Load" },
    lockGAText: {
        id: "productBlend.lockGAText",
        defaultMessage: "Lock {mixType, select, fertilizer {GA} chemical {Mix}}",
    },
    lockText: { id: "productblend.lockText", defaultMessage: "Lock" },
    manufacturerText: {
        id: "productBlend.manufacturerText",
        defaultMessage: "Manufacturer",
    },
    manureText: { id: "productBlend.manureText", defaultMessage: "Manure" },
    manureProductTypeText: {
        id: "productBlend.manureProductTypeText",
        defaultMessage: "Manure Type",
    },
    maxRateText: { id: "productBlend.maxRateText", defaultMessage: "Max Rate" },
    minRateText: { id: "productBlend.minRateText", defaultMessage: "Min Rate" },
    minimumSettingsText: {
        id: "productBlend.minimumSettingsText",
        defaultMessage: "Minimum Settings",
    },
    nameText: { id: "productBlend.nameText", defaultMessage: "Name" },
    originalProductRecText: {
        id: "productBlend.originalProductRecText",
        defaultMessage: "Original Product Rec",
    },
    physicalStateText: {
        id: "productBlend.physicalStateText",
        defaultMessage: "Physical State",
    },
    priceText: { id: "productBlend.priceText", defaultMessage: "Price ($)" },
    productMixTitleText: {
        id: "productBlend.productMixTitleText",
        defaultMessage: "Product Mix {id, number}",
    },
    productMixZoneText: {
        id: "productBlend.productMixZoneText",
        defaultMessage: " - Zone {areaId, number} ({calculatedArea, number} Acres)",
    },
    productTypeText: {
        id: "productBlend.productTypeText",
        defaultMessage: "Product Type",
    },
    productText: {
        id: "productBlend.productText",
        defaultMessage: "{count, plural, one {Product} other {Products}}",
    },
    productParentTypeText: {
        id: "productBlend.productParentTypeText",
        defaultMessage: "Product Parent Type",
    },
    rateText: { id: "productBlend.rateText", defaultMessage: "Rate" },
    recOfText: {
        id: "productBlend.recOfText",
        defaultMessage: "Rec of {rate, number} {rateUnit} ({appliedArea, number} Applied Acres)",
    },
    recommendationsText: {
        id: "productBlend.recommendationsText",
        defaultMessage: "Recommendations",
    },
    saveBlendMessageText: {
        id: "productBlend.saveBlendMessageText",
        defaultMessage:
            "Save {mixType, select, fertilizer {Blend} chemical {Product Mix} manure {Manure Product}} for later use ",
    },
    saveBlendOptionalText: {
        id: "productBlend.saveBlendOptionalText",
        defaultMessage: "(optional)",
    },
    saveBlendText: {
        id: "productBlend.saveBlendText",
        defaultMessage:
            "Save {mixType, select, fertilizer {Blend} chemical {Mix} manure {Manure Product}}",
    },
    saveBlendTitleText: {
        id: "productBlend.saveBlendTitleText",
        defaultMessage:
            "Save {mixType, select, fertilizer {Custom Blend} chemical {Product Mix} manure {ManureProduct}}",
    },
    showFilterText: {
        id: "productBlend.showFilterText",
        defaultMessage: "Show Filters",
    },
    switchRateText: {
        id: "productBlend.switchRateText",
        defaultMessage: "Switch Rate",
    },
    targetRateText: {
        id: "productBlend.targetRateText",
        defaultMessage: "Target Rate",
    },
    totalCostText: {
        id: "productBlend.totalCostText",
        defaultMessage: "Total Cost ($)",
    },
    totalProductText: {
        id: "productBlend.totalProductText",
        defaultMessage: "Total ({unit})",
    },
    totalText: { id: "productBlend.totalText", defaultMessage: "Total" },
    unitText: { id: "productBlend.unitText", defaultMessage: "Unit" },
});
