import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ProductSummary } from "~/action-panel/components/common/product-blend-summary/product-summary";

import { defineMessages, injectIntl, intlShape } from "react-intl";
import { NoLink } from "~/core";
import { recsSelectors } from "~/recs-events";
import * as selectors from "../../selectors";
import * as actions from "../../actions";
import { actions as rxFileImportActions } from "./rx-file-import";
import "../../../../../common/rec-event-info/rec-event-info.css";
import "./rec-equation-application-form.css";

const messages = defineMessages({
    changeText: {
        id: "recModule.recInfo.equationRecSummary.changeText",
        defaultMessage: "Change",
    },
});

export class RecEquationApplicationSummary_ extends PureComponent {
    static propTypes = {
        batchRecSummariesForEdit: PropTypes.array,
        equationGroupList: PropTypes.array,
        fieldGuid: PropTypes.string,
        isBatchTemplate: PropTypes.bool,
        recDetails: PropTypes.object,
        onClearEquationGroupGuid: PropTypes.func.isRequired,
        onRxFileImportReset: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
    };
    static defaultProps = {
        isBatchTemplate: false,
    };

    render() {
        const { batchRecSummariesForEdit, recDetails, equationGroupList } = this.props;
        const { formatMessage } = this.props.intl;
        const firstActiveRec = recDetails.recAreaList[0].recs[0];
        const activeEquationGroup = equationGroupList.find(
            (equationGroup) => equationGroup.equationGroupGuid === firstActiveRec.equationGroupGuid
        );
        const hasBeenRun = Boolean(recDetails.recGeneralGuid);
        const isBatchRecEdit = batchRecSummariesForEdit && batchRecSummariesForEdit.length > 0;

        return !activeEquationGroup ? null : (
            <div className="rec-equation-application-summary">
                {!hasBeenRun && !isBatchRecEdit ? null : (
                    <ProductSummary isEvent={false} isEquation={true} />
                )}
                <div className="change-equation-group">
                    {hasBeenRun || isBatchRecEdit ? null : (
                        <NoLink
                            className="change-equation-group-nolink"
                            label={formatMessage(messages.changeText)}
                            onClick={() => {
                                this.props.onClearEquationGroupGuid();
                                this.props.onRxFileImportReset();
                            }}
                        />
                    )}
                    <div className="active-equation-group-name">
                        {activeEquationGroup.groupName}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { batchRecSummariesForEdit, recSummary } = selectors.getModuleState(state);
    const { fieldGuid } = recSummary;
    const { fieldGuidToRecDetails } = recsSelectors.getModuleState(state);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    return {
        batchRecSummariesForEdit,
        equationGroupList: selectors.getEquationGroupList(state),
        fieldGuid,
        recDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClearEquationGroupGuid: () => dispatch(actions.clearEquationGroupGuid()),
    onRxFileImportReset: () => dispatch(rxFileImportActions.rxFileImportReset()),
});
export const RecEquationApplicationSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RecEquationApplicationSummary_));
