import React, { Component } from "react";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { service } from "./service";
import * as model from "./model";
import { messages } from "../../i18n-messages";
import { errorCodeMessages } from "~/i18n-error-messages";
import { injectIntl, intlShape } from "react-intl";
import { withCrud, withMasked } from "~/hocs";
import { actions } from "./controller";
import { keywords } from "../../keywords";
import { Email } from "~/admin/setup";
import { constants } from "../../data";
import { connect } from "react-redux";
import { getFieldGuidArray } from "../../utils";
// Components
import { DataTable, NoLink } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { ReportViewer } from "../report-viewer/report-viewer";
import { getSelectedFieldGuids } from "../../../customer-data/selectors";
import { ErrorDetailsDialog } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";
import { IFilterQuery, ISortOptions, IIndex } from "~/core/components/tables/interfaces";

export interface IData {
    FileName?: string;
    UserGuid?: string;
    selectedItems?: Record<string, any>[];
    emailAlias?: string;
}

export interface ISendEmail {
    [data: string]: Record<string, any>;
}
export interface IReportTable_Props {
    actions?: Record<string, any>;
    autoSearchList?: Record<string, any>;
    createReportHubProgress: boolean;
    deleteSelectedReports?: (records: Record<string, any>[]) => void;
    deleteSelectedReportsAlt?: (records: Record<string, any>[]) => void;
    sendEmail?: (emailToSend: ISendEmail) => void;
    fetchRecords?: () => void;
    fetchRecordsAlt?: () => void;
    getAutoSearchListAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    initialRequestOptions?: Record<string, any>;
    intl: intlShape;
    needs?: (any: void[]) => void;
    onAllReportCreated?: () => void;
    onFilterChangeAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onPaginatorChangeAlt?: (opts: Partial<IIndex>) => void;
    onSelectAllAlt?: (opts: Partial<IIndex>, clearSelected?: boolean) => void;
    onSortOptionAlt?: (opts?: ISortOptions) => void;
    setIsExpanded?: (isExp: boolean) => void;
    downloadReports?: (models: Record<string, any>) => void;
    printReports?: (data: Record<string, any>) => void;
    mergeReports?: (model: Record<string, any>) => void;
    clearReportHubData?: () => void;
    records?: Record<string, any>[];
    reportStatus?: Record<string, any>[];
    selectedFields?: Immutable.Set<string>;
    url?: string;
    autoSearchUrl?: string;
    selectAllUrl?: string;
    userGuid?: string;
    currentThemeName?: string;
    reportName?: string;
    hideMergeOption?: boolean;
    selectedItemList?: any[];
    selectAll?: any[];
    totalCount?: number;
    requestIds?: string;
}

export interface IReportTable_State {
    createReportHubProgress: boolean;
    showDeleteModal: boolean;
    reportStatus: Record<string, any>[];
    showReportView: boolean;
    selectAll: any[];
    selectedItems: any[];
    selectedFields: any;
    reportErrorDetailsToShow: string;
    showEmailModal: boolean;
    currentThemeName: string;
}

export class ReportTable_ extends Component<IReportTable_Props, IReportTable_State> {
    reportsTobeDeleted: Record<string, any>;
    deleteRequestId: any;
    printReportsRequestId: any;
    reportsTobeEmail: Record<string, any>[];
    createdReports: Record<string, any>[];
    failedReports: Record<string, any>[];
    reportViewerData: Record<string, any>;
    tableFooterOptions: Record<string, any>[];
    sendEmailTo: any;

    static defaultProps = {
        records: [],
    };

    constructor(props: IReportTable_Props) {
        super(props);
        const { formatMessage } = this.props.intl;
        this.state = {
            createReportHubProgress: false,
            showDeleteModal: false,
            reportStatus: [],
            showReportView: false,
            selectAll: [],
            selectedItems: [],
            selectedFields: [],
            reportErrorDetailsToShow: null,
            showEmailModal: null,
            currentThemeName: "",
        };
        this.reportsTobeDeleted = null;
        this.deleteRequestId = null;
        this.printReportsRequestId = null;
        this.reportsTobeEmail = [];
        this.createdReports = [];
        this.failedReports = [];
        this.reportViewerData = {};
        this.tableFooterOptions = [
            {
                label: formatMessage(messages.deleteSelected),
                action: this.deleteSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: false,
            },
            {
                label: formatMessage(messages.emailSelected),
                action: this.emailSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
            {
                label: formatMessage(messages.printSelected),
                action: this.printSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
            {
                label: formatMessage(messages.mergeSelected),
                action: this.mergeSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
            {
                label: formatMessage(messages.downloadSelected),
                action: this.downloadSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
        ];
        service.printLabelValue = this._printReportStatus;
    }

    private _processDeleteRequest = (nextProps: IReportTable_Props): void => {
        if (nextProps.requestIds && this.deleteRequestId) {
            if (nextProps.requestIds[this.deleteRequestId] === "SUCCESS") {
                if (this.props.fetchRecordsAlt != null) {
                    this.props.fetchRecordsAlt();
                } else {
                    this.props.fetchRecords();
                }
                this.deleteRequestId = null;
            } else if (nextProps.requestIds[this.deleteRequestId] === "FAILURE") {
                this.deleteRequestId = null;
            }
            this.setState({
                showDeleteModal: false,
            });
            this.reportsTobeDeleted = null;
        }
    };

    private _processPrintReportsRequest = (nextProps: IReportTable_Props): void => {
        if (nextProps.requestIds && this.printReportsRequestId) {
            if (nextProps.requestIds[this.printReportsRequestId] === "SUCCESS") {
                const reportData = {
                    fileName: keywords.outputMerge,
                    loggedInUserGuid: this.props.userGuid,
                };

                this._openReportViewer(reportData, true);
                this.printReportsRequestId = null;
            } else if (nextProps.requestIds[this.printReportsRequestId] === "FAILED") {
                this.printReportsRequestId = null;
            }
        }
    };

    private _getErrorInReportStatus(status, data, attr): JSX.Element {
        const { formatMessage } = this.props.intl;
        switch (status) {
            case constants.reportStatus.GENERIC_GP_SERVICE_ERROR:
                return this._renderErrorInReportLabel(
                    formatMessage(messages.genericGpServiceError),
                    data.reportGuid
                );
            case constants.reportStatus.STATUS_ERROR:
                return this._renderErrorInReportLabel(
                    formatMessage(messages.statusError),
                    data.reportGuid
                );
            case constants.reportStatus.RAD_PDF_ERROR:
                return this._renderErrorInReportLabel(
                    formatMessage(messages.radPdfError),
                    data.reportGuid
                );
            default:
                return this._renderErrorInReportLabel(data[attr], data.reportGuid);
        }
    }

    private _reportDeleteSelected = (data) => {
        const reportData = this.props.records
            .filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: `${record.reportLink}.pdf`,
                UserGuid: record.reportUserGuid,
                ReportGuid: record.reportGuid,
            }));
        this.setState({
            selectedItems: this.state.selectedItems.filter((item) => {
                //reduce the list by those deleted reports then save that value in state
                return data.selectedItems.some(
                    (delItems) => delItems.reportGuid === item.reportGuid
                );
            }),
        });

        if (this.props.deleteSelectedReportsAlt != null) {
            this.props.deleteSelectedReportsAlt(
                this.props.records.filter(
                    (record) => data.selectedItems.indexOf(record.reportGuid) > -1
                )
            );
            this.setState({
                showDeleteModal: false,
            });
        } else {
            this.deleteRequestId = this.props.needs([this.props.deleteSelectedReports(reportData)]);
        }
    };

    private _printReportStatus = (data, attr) => {
        const { formatMessage } = this.props.intl;
        if (attr === model.PROPS_REPORT_STATUS) {
            const currentReport = this.state.reportStatus.filter((report) => {
                return report.reportGuid === data.reportGuid;
            });
            if (currentReport.length > 0) {
                const status = currentReport.pop().status;
                if (status === constants.reportStatus.IN_PROGRESS) {
                    return (
                        <div
                            className="report-progress-text"
                            title={formatMessage(messages.inProgress)}
                        >
                            {formatMessage(messages.inProgress)}
                        </div>
                    );
                } else if (status === constants.reportStatus.GENERATED) {
                    this.createdReports.push(data.reportGuid);
                    return this._renderViewReportLabel(data);
                } else {
                    this.failedReports.push(data.reportGuid);
                    return this._getErrorInReportStatus(status, data, attr);
                }
            } else {
                if (
                    data[attr] === keywords.completed ||
                    this.createdReports.indexOf(data.reportGuid) > -1
                ) {
                    return this._renderViewReportLabel(data);
                } else if (
                    data[attr] === keywords.inProgress &&
                    this.failedReports.indexOf(data.reportGuid) === -1
                ) {
                    return (
                        <span
                            className="report-progress-text"
                            title={formatMessage(messages.inProgress)}
                        >
                            {formatMessage(messages.inProgress)}
                        </span>
                    );
                } else {
                    return this._renderErrorInReportLabel(data[attr], data.reportGuid);
                }
            }
        }
        return data[attr];
    };

    private _getLabelString = () => {
        let returnValue = { count: this.props.records.length || 1 };
        const count = { count: this.props.records.length || 1 };
        if (this.props.reportName === keywords.summary) {
            returnValue = this.props.intl.formatMessage(messages.summaryReportsHeader, count);
        } else {
            returnValue = this.props.intl.formatMessage(messages.reportTableHeader, count);
        }
        return `3. ${returnValue}:`;
    };

    private _sendEmail = (emailData: IData) => {
        this.props.needs([
            this.props.sendEmail({
                [model.PROPS_EMAIL_ADDRESS_LIST]: emailData,
                [model.PROPS_REPORT_OBJECT_LIST]: this.reportsTobeEmail,
            }),
        ]);
        this.setState({
            showEmailModal: false,
        });
    };

    private _openReportViewer = (reportData, isPrint = false) => {
        const { currentThemeName } = this.props;
        this.setState({
            showReportView: true,
            currentThemeName,
        });
        this.reportViewerData = {
            fileName: reportData.reportLink ? reportData.reportLink : reportData.fileName,
            loggedInUserGuid: this.props.userGuid,
            reportUserGuid: reportData.reportUserGuid ? reportData.reportUserGuid : "",
            isPrint,
        };
        this.props.setIsExpanded(false);
    };

    private _renderViewReportLabel = (data) => {
        const { formatMessage } = this.props.intl;
        return (
            <NoLink
                onClick={() => {
                    this._openReportViewer(data);
                }}
                label={formatMessage(messages.viewReport)}
                title={formatMessage(messages.viewReport)}
            />
        );
    };

    private _renderErrorInReportLabel = (value, reportGuid): JSX.Element => {
        return (
            <NoLink
                onClick={() => this.setState({ reportErrorDetailsToShow: reportGuid })}
                className="report-error-text"
                label={value}
                title={value}
            />
        );
    };

    closeReportViewer = (): void => {
        this.setState(
            {
                showReportView: false,
            },
            () => {
                this.props.setIsExpanded(true);
                this.reportViewerData = {};
            }
        );
    };
    public emailSelected = (data: IData): any => {
        this.reportsTobeEmail.splice(0, this.reportsTobeEmail.length);
        this.reportsTobeEmail.push(
            ...this.props.records
                .filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
                .map((record) => {
                    return {
                        FileName: record.reportLink ? record.reportLink : record.fileName,
                        UserGuid: record.reportUserGuid,
                    };
                })
        );
        this.setState({
            showEmailModal: true,
        });
    };

    public downloadSelected = (data: IData): void => {
        const reportList = this.props.records
            .filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: record.reportLink ? `${record.reportLink}.pdf` : `${record.fileName}.pdf`,
                UserGuid: record.reportUserGuid,
            }));

        this.props.needs([
            this.props.downloadReports({
                model: reportList || [],
            }),
        ]);
    };

    public printSelected = (data: IData): void => {
        const reportList = this.props.records
            .filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: record.reportLink ? `${record.reportLink}.pdf` : `${record.fileName}.pdf`,
                UserGuid: record.reportUserGuid,
            }));
        this.printReportsRequestId = this.props.needs([this.props.printReports(reportList || [])]);
    };

    public mergeSelected = (data: IData): void => {
        const reportList = this.props.records
            .filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: record.reportLink ? `${record.reportLink}.pdf` : `${record.fileName}.pdf`,
                ReportGuid: record.reportGuid,
                UserGuid: record.reportUserGuid,
            }));

        this.props.needs([
            this.props.mergeReports({
                model: reportList || [],
            }),
        ]);
    };

    public deleteSelected = (data: IData): null => {
        this.reportsTobeDeleted = data;
        this.setState({
            showDeleteModal: true,
        });
        return null;
    };

    public isFooterButtonDisabled = (
        disableIfNotSelected: boolean,
        selectedItems: Record<string, any>[],
        disableIfNotSuccessful = false
    ): boolean => {
        let areAllSuccessful = true;
        selectedItems.forEach((guid) => {
            const currReport = this.props.records.find((record) => record.reportGuid === guid);
            if (currReport) {
                if (currReport.reportStatus === keywords.inProgress) {
                    const isInReportStatus = this.state.reportStatus.some(
                        (r) =>
                            r.reportGuid === guid && r.status === constants.reportStatus.GENERATED
                    );
                    areAllSuccessful = areAllSuccessful && isInReportStatus;
                } else if (currReport.reportStatus !== keywords.completed) {
                    areAllSuccessful = false;
                }
            }
        });
        return (
            (disableIfNotSelected && selectedItems.length === 0) ||
            (disableIfNotSuccessful && !areAllSuccessful)
        );
    };

    public onAddEmail = (key, emailData: IData[]): void => {
        this.sendEmailTo = emailData.map((item) => item.emailAlias);
    };

    componentDidUpdate(): void {
        const { reportStatus } = this.state;
        if (reportStatus.length > 0) {
            const someReportsInProgress = reportStatus.some(
                (report) => report.status === constants.reportStatus.IN_PROGRESS
            );
            if (this.props.createReportHubProgress && !someReportsInProgress) {
                this.props.onAllReportCreated();
            }
        }
    }

    UNSAFE_componentWillMount(): void {
        if (this.props.url) {
            service.urls.URL = this.props.url;
            service.isFooterButtonDisabled = this.isFooterButtonDisabled;
        }
        if (this.props.autoSearchUrl) {
            service.urls.AUTO_SEARCH_URL = this.props.autoSearchUrl;
        }
        if (this.props.selectAllUrl) {
            service.urls.SELECT_ALL = this.props.selectAllUrl;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IReportTable_Props): void {
        if (
            JSON.stringify(nextProps.selectedFields) !==
                JSON.stringify(this.props.selectedFields) ||
            (!this.props.createReportHubProgress && nextProps.createReportHubProgress)
        ) {
            service.defaultRequestFilters[model.PROPS_FIELD_GUID_LIST] = getFieldGuidArray(
                nextProps.selectedFields
            );

            this.createdReports = [];
            this.failedReports = [];

            this.setState({
                selectedItems: [],
                selectedFields: getFieldGuidArray(nextProps.selectedFields),
            });
            if (this.props.fetchRecordsAlt != null) {
                this.props.fetchRecordsAlt();
            } else {
                this.props.fetchRecords();
            }
        }
        if (nextProps.currentThemeName !== this.props.currentThemeName) {
            this.setState({
                currentThemeName: nextProps.currentThemeName,
            });
        }
        if (nextProps.reportStatus !== this.props.reportStatus) {
            this.setState({
                reportStatus: nextProps.reportStatus,
            });
        }
        if (
            nextProps.selectedItemList != null &&
            JSON.stringify(nextProps.selectedItemList) !== JSON.stringify(this.state.selectedItems)
        ) {
            this.setState({
                selectedItems: nextProps.selectedItemList,
            });
        }

        if (this.props.hideMergeOption) {
            this.tableFooterOptions = this.tableFooterOptions.filter(
                (tfo) => tfo.label !== this.props.intl.formatMessage(messages.mergeSelected)
            );
        }
        this._processDeleteRequest(nextProps);
        this._processPrintReportsRequest(nextProps);
    }

    shouldComponentUpdate(nextProps: IReportTable_Props): boolean {
        if (nextProps.selectedFields !== this.state.selectedFields) {
            this.setState({ selectedFields: nextProps.selectedFields });
            if (this.props.fetchRecordsAlt != null) {
                this.props.fetchRecordsAlt();
            } else {
                this.props.fetchRecords();
            }
        }
        return true;
    }

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        service.defaultRequestFilters[model.PROPS_FIELD_GUID_LIST] = this.state.selectedFields;

        return (
            <div className="report-grid-cont">
                <div className="options-header report-table-header">{this._getLabelString()}</div>
                <DataTable
                    autoSearchList={this.props.autoSearchList}
                    classNames="reports-table-cont"
                    isCheckbox
                    isSelectAll={false}
                    service={service}
                    messages={messages}
                    {...this.props}
                    deleteSelected={this.deleteSelected}
                    footerOptions={this.tableFooterOptions}
                    selectedItems={this.state.selectedItems}
                />
                <DialogBox
                    title={formatMessage(messages.confirmTitle)}
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={this.state.showDeleteModal}
                    onAction={() => this._reportDeleteSelected(this.reportsTobeDeleted)}
                    onClose={() => this.setState({ showDeleteModal: false })}
                >
                    <div>{formatMessage(errorCodeMessages.confirmDeleteMsg)}</div>
                </DialogBox>
                <DialogBox
                    draggable={true}
                    title={formatMessage(messages.sendReportTo)}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    action="send"
                    isOpen={this.state.showEmailModal}
                    onAction={() => this._sendEmail(this.sendEmailTo)}
                    onClose={() => this.setState({ showEmailModal: false })}
                >
                    <Email
                        noHeader
                        emailList={[{}]}
                        emailListAlias={"email"}
                        emailAlias={"emailAlias"}
                        formKey={"emailList"}
                        addEditPanel={{ mode: "EDIT" }}
                        emailAddText={messages.additionalEmail}
                        onChildComponentChange={this.onAddEmail}
                        required={true}
                    />
                </DialogBox>
                {!this.state.showReportView ? null : (
                    <SlidingPanel
                        noHeader
                        component={ReportViewer}
                        navigateTo={{
                            parentNameCode: "105",
                            childNameCode: "227",
                        }}
                        data={this.reportViewerData}
                        currentThemeName={this.state.currentThemeName}
                        closeReportViewer={this.closeReportViewer}
                        formatMessage={formatMessage}
                    />
                )}
                {!this.state.reportErrorDetailsToShow ? null : (
                    <ErrorDetailsDialog
                        onClose={() => this.setState({ reportErrorDetailsToShow: null })}
                        correlationId={this.state.reportErrorDetailsToShow}
                        logType="report"
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedFields: getSelectedFieldGuids(state),
});

export const ReportTableView = injectIntl(
    withMasked(
        withCrud(
            connect<Partial<IReportTable_Props>>(mapStateToProps, null)(ReportTable_),
            service,
            actions
        )
    )
);
