import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { apiUrl } from "@ai360/core";
import { TrashcanIcon } from "~/core/icons";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    AutoSearch,
    Bucket,
    BucketHeader,
    DateInput,
    Loader,
    SelectInput,
    optionPropType,
    TextArea,
    TextInput,
} from "~/core";

import {
    actions as recsEventsActions,
    recsModels,
    recsSelectors,
    selectors as recsEventsSelectors,
} from "~/recs-events";

import { getTheUserGuid } from "~/login";
import { PersonAPI } from "@ai360/core";

import { adminData, GUID, NAME } from "~/admin/data";
import { fetchDropdownData } from "~/core/dropdowns/actions";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { getModuleState, getDropdownState, getEquationsLoading } from "../selectors";
import * as actions from "../actions";
import { getSetValuesForErrorCodeList } from "../../../../common/validation-utils";

import "../../../../common/rec-event-info/rec-event-info.css";

import "./rec-general-form.css";

const messages = defineMessages({
    recNamePlaceholderText: {
        id: "recModule.recInfo.recNamePlaceholderText",
        defaultMessage: "Rec Name",
    },
    generalInfoBucketTitle: {
        id: "recModule.recInfo.generalInfoBucketTitle",
        defaultMessage: "General Information",
    },
    timingInfoBucketTitle: {
        id: "recModule.recInfo.timingInfoBucketTitle",
        defaultMessage: "Timing Info",
    },
    cropInfoBucketTitle: {
        id: "recModule.recInfo.cropInfoBucketTitle",
        defaultMessage: "Crop Info",
    },
    seasonPlaceholderText: {
        id: "recModule.recInfo.seasonPlaceholderText",
        defaultMessage: "Season",
    },
    cropCyclePlaceholderText: {
        id: "recModule.recInfo.cropCyclePlaceholderText",
        defaultMessage: "Crop Cycle",
    },
    createdDatePlaceholderText: {
        id: "recModule.recInfo.createdDatePlaceholderText",
        defaultMessage: "Date",
    },
    peopleBucketTitle: {
        id: "recModule.recInfo.peopleBucketTitle",
        defaultMessage: "People",
    },
    findPeoplePlaceholderText: {
        id: "recModule.recInfo.findPeoplePlaceholderText",
        defaultMessage: "Find People",
    },
    generalNotesBucketTitle: {
        id: "recModule.recInfo.generalNotesBucketTitle",
        defaultMessage: "General Notes",
    },
    notesPlaceholderText: {
        id: "recModule.recInfo.notesPlaceholderText",
        defaultMessage: "Notes",
    },
    cropPlaceholderText: {
        id: "recModule.recInfo.cropPlaceholderText",
        defaultMessage: "Crop",
    },
    cropPurposePlaceholderText: {
        id: "recModule.recInfo.cropPurposePlaceholderText",
        defaultMessage: "Crop Purpose",
    },
    brandPlaceholderText: {
        id: "recModule.recInfo.brandPlaceholderText",
        defaultMessage: "Brand/Organization",
    },
    varietyPlaceholderText: {
        id: "recModule.recInfo.varietyPlaceholderText",
        defaultMessage: "Variety/Hybrid",
    },
    plantingDatePlaceholderText: {
        id: "recModule.recInfo.plantingDatePlaceholderText",
        defaultMessage: "Crop Planting Date",
    },
});

const {
    getPickListCode,
    PICKLIST_CROP_PURPOSE,
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
} = picklistNames;

const errorCodeToMessageIdSetMap = new Map([
    [2899, [messages.recNamePlaceholderText, messages.createdDatePlaceholderText]], // ErrorCode.RecommendationNameDuplicated
    [498, null], // ErrorCode.RecAreaRequired
    [510, messages.recNamePlaceholderText], // ErrorCode.RecommendationNameRequired
    [518, null], // ErrorCode.RecommendationTypeRequired
    [519, messages.createdDatePlaceholderText], // ErrorCode.RecommendationCreatedDateRequired
    [80, messages.seasonPlaceholderText], // ErrorCode.AgEventCropSeasonRequired
    [2814, null], // ErrorCodes.FieldBoundaryMissingForWholeFieldZone
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class RecGeneralForm_ extends PureComponent {
    static propTypes = {
        recDetails: PropTypes.object,
        cropList: PropTypes.array,
        brandList: PropTypes.array,
        varietyHybridList: PropTypes.array,
        isEquationsLoading: PropTypes.bool,
        onFetchPicklists: PropTypes.func.isRequired,
        onRefreshDropdowns: PropTypes.func.isRequired,
        onUpdateRecDetails: PropTypes.func.isRequired,
        picklistOptionsCropPurpose: PropTypes.arrayOf(optionPropType),
        picklistOptionsCroppingSeason: PropTypes.arrayOf(optionPropType),
        picklistOptionsCroppingSeasonCycle: PropTypes.arrayOf(optionPropType),
        picklistOptionsGeneralWeather: PropTypes.arrayOf(optionPropType),
        picklistOptionsDirection: PropTypes.arrayOf(optionPropType),
        picklistOptionsSoilCondition: PropTypes.arrayOf(optionPropType),
        picklistOptionsSoilCompaction: PropTypes.arrayOf(optionPropType),
        intl: intlShape.isRequired,
        userGuid: PropTypes.string.isRequired,
        saveRecDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        setGeneralAdjustments: PropTypes.any,
        updateRecSeasonDependencies: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            haveSetDefaultValues: false,
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    UNSAFE_componentWillMount() {
        const { recDetails } = this.props;
        const fetchPicklistNames = [
            PICKLIST_CROP_PURPOSE,
            PICKLIST_CROPPING_SEASON,
            PICKLIST_CROPPING_SEASON_CYCLE,
        ];

        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = picklistNames.getPickListCode(key);
            return accum;
        }, {});

        this.props.onFetchPicklists(picklistFetchArgObj);
        if (
            this.props.recDetails != null &&
            recDetails.recType === recsModels.REC_TYPE_NAME_MANUAL_APPLICATION
        ) {
            // FIXME: move to saga
            this.refreshCropDropdownLists(
                recDetails.cropGuid,
                recDetails.brandOrganizationGuid,
                true
            );
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newSaveRecDetailsErrorCodeList =
            newProps.saveRecDetailsErrorCodeList !== this.props.saveRecDetailsErrorCodeList;
        const newPicklistOptionsCroppingSeason =
            newProps.picklistOptionsCroppingSeason !== this.props.picklistOptionsCroppingSeason;
        if (!newSaveRecDetailsErrorCodeList && !newPicklistOptionsCroppingSeason) {
            return;
        }

        const newState = {};
        if (newSaveRecDetailsErrorCodeList) {
            newState.errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
        }
        const setDefaultValues =
            !this.state.haveSetDefaultValues &&
            newProps.recDetails != null &&
            newProps.picklistOptionsCroppingSeason.length > 0;

        if (setDefaultValues) {
            newState.haveSetDefaultValues = true;
        }

        this.setState(newState, () => {
            if (setDefaultValues) {
                this._setDefaultCroppingSeason();
            }
        });
    }

    refreshCropDropdownLists = (cropGuid, brandOrganizationGuid, isInit = false) => {
        const { onRefreshDropdowns } = this.props;
        if (!cropGuid) {
            onRefreshDropdowns({
                cropList: { url: apiUrl("AgBytes/GetCropDropdownList") },
            });
        } else {
            let refreshDropdowns = {};
            if (isInit) {
                refreshDropdowns.cropList = {
                    url: apiUrl("AgBytes/GetCropDropdownList"),
                };
            }
            refreshDropdowns.brandList = {
                url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
                model: cropGuid,
            };
            if (brandOrganizationGuid) {
                refreshDropdowns.varietyHybridList = {
                    url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
                    model: {
                        cropId: cropGuid,
                        brandOrganization: brandOrganizationGuid,
                    },
                };
            }
            onRefreshDropdowns(refreshDropdowns);
        }
    };

    _setDefaultCroppingSeason() {
        console.assert(
            this.props.recDetails != null && this.props.picklistOptionsCroppingSeason != null
        );
        if (!this.props.recDetails.croppingSeasonGuid) {
            const curYearStr = String(new Date().getFullYear());
            const defaultOption = this.props.picklistOptionsCroppingSeason.find(
                (option) => option.label === curYearStr
            );
            this._updateRecDetails({
                croppingSeasonGuid: defaultOption && defaultOption.value,
            });
        }
    }

    _getOptionList(options = [], valuekey = GUID, labelKey = NAME) {
        return options.map((option) => ({
            value: option[valuekey],
            label: option[labelKey],
            activeYn:
                option[adminData.PROPS_ACTIVE_YN] !== undefined
                    ? option[adminData.PROPS_ACTIVE_YN]
                    : true,
        }));
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveRecDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveRecDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateRecDetails(newProps) {
        const { recDetails } = this.props;
        if (newProps.cropGuid != null) {
            newProps.cropPurposeGuid = "";
            newProps.brandOrganizationGuid = "";
            newProps.varietyHybridGuid = "";
            this.refreshCropDropdownLists(newProps.cropGuid, "");
        } else if (newProps.brandOrganizationGuid != null) {
            newProps.varietyHybridGuid = "";
            this.refreshCropDropdownLists(recDetails.cropGuid, newProps.brandOrganizationGuid);
        }

        if (
            (recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_PLANTING) &&
            newProps.croppingSeasonGuid &&
            newProps.croppingSeasonGuid !== recDetails.croppingSeasonGuid
        ) {
            newProps.recAreaList = recDetails.recAreaList.map((recArea) => {
                return recsModels.RecArea.updateRecArea(recArea, {
                    recs: recArea.recs.map((rec) => {
                        return recsModels.Rec.updateRec(rec, {
                            isRecDirty: true,
                        });
                    }),
                });
            });
            // Also need to (possibly) update the default event selection
            const newSeasonOpt = this.props.picklistOptionsCroppingSeason.find(
                (option) => option.value === newProps.croppingSeasonGuid
            );
            this.props.updateRecSeasonDependencies(newSeasonOpt?.label);
        }
        this.props.setGeneralAdjustments();
        this.props.onUpdateRecDetails(newProps);
    }

    _getPersonInfoPart() {
        const { recDetails, userGuid } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.peopleBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <AutoSearch
                        clearOnSelection={true}
                        required={false}
                        itemList={[]}
                        initialFilterStr=""
                        onSelection={(p) => this._addPerson(p)}
                        getAutoSearchList={PersonAPI.searchPersonWithUser}
                        userGuid={userGuid}
                        placeholderText={formatMessage(messages.findPeoplePlaceholderText)}
                        keyProp="personGuid"
                        nameProp="personName"
                        secondaryPropList={["city", "stateAbbreviation", "jobTitleName"]}
                        excludedValues={recDetails.personList.map((person) => person.personGuid)}
                    />
                </div>
                <div className="input-row">
                    <div className="person-list">
                        {recDetails.personList.map((person, index) => {
                            return (
                                <div key={`person-${index}`} className="person-item">
                                    <div
                                        className="trashcan-icon-container"
                                        onClick={() => {
                                            this._removePerson(index);
                                        }}
                                    >
                                        <TrashcanIcon />
                                    </div>
                                    <div className="person-info">{`${person.personName}`}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Bucket>
        );
    }
    _getTimingInfoPart() {
        const { recDetails, picklistOptionsCroppingSeason, picklistOptionsCroppingSeasonCycle } =
            this.props;

        const { formatMessage } = this.props.intl;
        const { defaultCroppingSeason, errorMessagePlaceholderSet } = this.state;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.timingInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        required
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        containerClassNames={[
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    messages.seasonPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => this._updateRecDetails({ croppingSeasonGuid: v })}
                        options={picklistOptionsCroppingSeason}
                        placeholderText={formatMessage(messages.seasonPlaceholderText)}
                        value={recDetails.croppingSeasonGuid || defaultCroppingSeason}
                    />
                    <SelectInput
                        onChange={(v) =>
                            this._updateRecDetails({
                                croppingSeasonCycleGuid: v,
                            })
                        }
                        optionIsHiddenKey={ACTIVE_YN}
                        options={picklistOptionsCroppingSeasonCycle}
                        placeholderText={formatMessage(messages.cropCyclePlaceholderText)}
                        value={recDetails.croppingSeasonCycleGuid}
                    />
                </div>
                <div className="input-row half">
                    <DateInput
                        required
                        clearDisabled
                        containerClassNames={[
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.createdDatePlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) =>
                            this._updateRecDetails({
                                momentCreatedDate: moment(v),
                            })
                        }
                        openDirection="left"
                        timeFormat={false}
                        placeholderText={formatMessage(messages.createdDatePlaceholderText)}
                        value={recDetails.momentCreatedDate}
                    />
                </div>
            </Bucket>
        );
    }

    _getCropInfoPart() {
        const { recDetails, cropList, picklistOptionsCropPurpose, brandList, varietyHybridList } =
            this.props;

        const { formatMessage } = this.props.intl;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.cropInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        options={this._getOptionList(cropList, "cropGuid", "cropName")}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.cropPlaceholderText)}
                        onChange={(v) => this._updateRecDetails({ cropGuid: v || "" })}
                        value={recDetails.cropGuid}
                    />
                    <SelectInput
                        options={picklistOptionsCropPurpose}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.cropPurposePlaceholderText)}
                        onChange={(v) => this._updateRecDetails({ cropPurposeGuid: v })}
                        value={recDetails.cropPurposeGuid}
                    />
                </div>
                <div className="input-row">
                    <SelectInput
                        options={this._getOptionList(brandList)}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.brandPlaceholderText)}
                        disabled={!(brandList.length > 0 && recDetails.cropGuid)}
                        onChange={(v) =>
                            this._updateRecDetails({
                                brandOrganizationGuid: v || "",
                            })
                        }
                        value={recDetails.brandOrganizationGuid}
                    />
                    <SelectInput
                        options={this._getOptionList(varietyHybridList)}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.varietyPlaceholderText)}
                        disabled={!(brandList.length > 0 && recDetails.brandOrganizationGuid)}
                        onChange={(v) => this._updateRecDetails({ varietyHybridGuid: v })}
                        value={recDetails.varietyHybridGuid}
                    />
                </div>
                <div className="input-row half">
                    <DateInput
                        openDirection={"left"}
                        value={
                            recDetails.cropPlantingDate ? moment(recDetails.cropPlantingDate) : null
                        }
                        onChange={(v) => this._updateRecDetails({ cropPlantingDate: v })}
                        placeholderText={formatMessage(messages.plantingDatePlaceholderText)}
                    />
                </div>
            </Bucket>
        );
    }

    _addPerson(person) {
        const { recDetails } = this.props;
        const { personList } = recDetails;

        if (
            person &&
            !personList.some((listPerson) => listPerson.personGuid === person.personGuid)
        ) {
            this._updateRecDetails({
                personList: [...recDetails.personList, person],
            });
        }
    }
    _removePerson(index) {
        const { recDetails } = this.props;
        const { personList } = recDetails;
        const newPersonList = [...personList];
        newPersonList.splice(index, 1);

        this._updateRecDetails({ personList: newPersonList });
    }

    render() {
        const { recDetails } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;

        if (!recDetails) {
            return null;
        }
        return (
            <div className="rec-event-info-form rec-general-form">
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.generalInfoBucketTitle)}</BucketHeader>
                    <div className="input-row half">
                        <TextInput
                            maxLength={50}
                            autoFocus={!recDetails.name}
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        messages.recNamePlaceholderText
                                    ),
                                },
                            ]}
                            value={recDetails.name}
                            onChange={(v) => this._updateRecDetails({ name: v })}
                            placeholderText={formatMessage(messages.recNamePlaceholderText)}
                        />
                    </div>
                </Bucket>
                {this._getTimingInfoPart()}
                {recDetails.recType !== recsModels.REC_TYPE_NAME_MANUAL_APPLICATION
                    ? null
                    : this._getCropInfoPart()}
                {this._getPersonInfoPart()}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.generalNotesBucketTitle)}</BucketHeader>
                    <TextArea
                        maxLength={4000}
                        value={recDetails.notes}
                        onChange={(v) => this._updateRecDetails({ notes: v })}
                        placeholderText={formatMessage(messages.notesPlaceholderText)}
                    />
                </Bucket>
                {this.props.isEquationsLoading &&
                (recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                    recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_PLANTING) ? (
                    <Loader className="equation-rec-loader" />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { recSummary, isLoading } = getModuleState(state);
    const { fieldGuidToRecDetails, saveRecDetailsErrorCodeList } =
        recsSelectors.getModuleState(state);
    const { batchFieldGuid } = recsEventsSelectors.getZonesState(state);

    const fieldGuid =
        batchFieldGuid != null ? batchFieldGuid : recSummary != null ? recSummary.fieldGuid : null;
    const recDetails = !isLoading ? fieldGuidToRecDetails.get(fieldGuid) : null;

    const dropdownData = getDropdownState(state);

    const picklistOptionsCropPurpose = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROP_PURPOSE)
    );
    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    const picklistOptionsCroppingSeasonCycle = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON_CYCLE)
    );

    return {
        recDetails,
        fieldGuid,
        picklistOptionsCropPurpose,
        picklistOptionsCroppingSeason,
        picklistOptionsCroppingSeasonCycle,
        ...dropdownData,
        saveRecDetailsErrorCodeList,
        userGuid: getTheUserGuid(state),
        isEquationsLoading: getEquationsLoading(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onRefreshDropdowns: (dropdowns) => {
        dispatch(
            fetchDropdownData({
                ...dropdowns,
                action: actions.fetchedDropdownData,
                async: false,
            })
        );
    },
    onUpdateRecDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateRecDetails(fieldGuid, newProps)),
    setGeneralAdjustments: () => dispatch(actions.setGeneralAdjustments(true)),
    updateRecSeasonDependencies: (fieldGuid, croppingSeasonName) =>
        dispatch(actions.updateRecSeasonDependencies(fieldGuid, croppingSeasonName)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateRecDetails: (newProps) =>
        dispatchProps.onUpdateRecDetails(stateProps.fieldGuid, newProps),
    updateRecSeasonDependencies: (croppingSeasonName) =>
        dispatchProps.updateRecSeasonDependencies(stateProps.fieldGuid, croppingSeasonName),
});

export const RecGeneralForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecGeneralForm_));
