import React, { Component, JSXElementConstructor, ReactElement } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import { parse } from "qs";

import { Loader } from "~/core";
import { getErrorMessages } from "~/i18n-error-messages";
import { messages as globalMessages } from "~/i18n-messages";
import { messages as loginMessages } from "../i18n-messages";
import { ThemeRoot } from "~/theme";
import { APIErrorWithCode } from "@ai360/core";

import EULA from "./eula";
import LoginForm from "./login-form";
import RecoverPassword from "./recover-password";
import ResetPassword from "./reset-password";
import SecurityQuestion from "./security-question";
import SecurityMessage from "./security-message";
import SelectCompany from "./select-company";
import SelectSecurityQuestion from "./select-security-question";
import * as actions from "../actions";
import * as selectors from "../selectors";

import "./login.css";

const { LoginState, LoginErrors } = actions;
export interface ILoginPage_Props {
    error?: any;
    location?: any;
    isProcessing?: boolean;
    loginState?: unknown;
    onAutoLogin: (query: string) => void;
    onSetSecurityMessage: (apiObj: Record<string, any>) => void;
    intl: intlShape;
    history?: any;
}

export interface ILoginPage_State {
    errMessage: string | ReactElement<unknown, string | JSXElementConstructor<any>>;
    shaking: boolean;
}
class LoginPage_ extends Component<ILoginPage_Props, ILoginPage_State> {
    versionPromise: Promise<void>;

    constructor(props) {
        super(props);
        this.versionPromise = null;
        this.state = {
            errMessage: "",
            shaking: false,
        };
    }

    private _clearError() {
        this.setState({
            errMessage: "",
        });
    }

    componentDidMount() {
        const query = this.props.location && parse(this.props.location.search.slice(1));
        this.props.onAutoLogin(query && query.key);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            (nextProps.isProcessing && this.props.isProcessing === false) ||
            nextProps.loginState !== this.props.loginState
        ) {
            this._clearError();
        }

        if (nextProps.error) {
            this.handleError(nextProps.error);
        }
    }

    public handleError(err) {
        const { formatMessage } = this.props.intl;

        let errMessage: string | ReactElement<unknown, string | JSXElementConstructor<any>> = "";
        if (err instanceof APIErrorWithCode) {
            const { apiResultObj } = err;
            if (
                apiResultObj &&
                (apiResultObj as Record<string, any>).model &&
                typeof (apiResultObj as Record<string, any>).model.securityCode === "number"
            ) {
                this.props.onSetSecurityMessage(
                    (apiResultObj as Record<string, any>).model.securityCode
                );
            } else {
                errMessage = getErrorMessages(formatMessage, err);
            }
        } else if (err.code === LoginErrors.INVALID_PARAMETER) {
            errMessage = formatMessage(loginMessages.validEmail);
        } else if (typeof err === "string" || React.isValidElement(err)) {
            errMessage = err;
        } else if (err && err.id != null && err.defaultMessage != null) {
            errMessage = formatMessage(err);
        } else if (err && typeof err.message === "string" && !/^{.*}$/.test(err.message)) {
            errMessage = err.message;
        } else if (err && err.message) {
            const message = JSON.parse(err.message);
            if (message && typeof message.model === "string") {
                errMessage = message.model;
            } else if (message && message.model && typeof message.model.securityCode === "number") {
                this.props.onSetSecurityMessage(message.model.securityCode);
            }
        } else {
            errMessage = formatMessage(globalMessages.unknownError);
        }

        this.setState(
            {
                shaking: true,
                errMessage,
            },
            () => setTimeout(() => this.setState({ shaking: false }), 250)
        );
    }

    public checkForUIupdate() {
        if (!this.versionPromise) {
            this.versionPromise = fetch(`deployed-version.json?${Date.now()}`)
                .then((response) => response.json())
                .then((versionObj) => {
                    const expectedVersion = versionObj.uiVersion.split("|")[0];
                    const actualVersion = window.process_env.__BUILD_STR__.split("|")[0];
                    if (expectedVersion.trim() !== actualVersion) {
                        console.warn(
                            `Refreshing UI, expecting '${expectedVersion}', saw '${actualVersion}'`
                        );
                        window.location.reload();
                    }

                    this.versionPromise = null;
                });
        }
    }

    render() {
        this.checkForUIupdate();
        const { errMessage, shaking } = this.state;

        let presentation = null;
        const loginBoxClasses = [
            "login-box",
            {
                "login-err-animation": shaking,
            },
        ];

        const errorHandling = {
            onError: (err) => this.handleError(err),
        };

        switch (this.props.loginState) {
            case LoginState.SELECT_COMPANY:
                presentation = <SelectCompany {...errorHandling} isQuickChanger={false} />;
                break;
            case LoginState.LOGIN_FORM:
                presentation = <LoginForm {...errorHandling} />;
                break;
            case LoginState.RECOVER_PASSWORD:
                presentation = <RecoverPassword {...errorHandling} />;
                break;
            case LoginState.RESET_PASSWORD:
                presentation = <ResetPassword {...errorHandling} history={this.props.history} />;
                break;
            case LoginState.SECURITY_QUESTION:
                presentation = <SecurityQuestion {...errorHandling} />;
                break;
            case LoginState.SELECT_SECURITY_QUESTION:
                presentation = <SelectSecurityQuestion {...errorHandling} />;
                break;
            case LoginState.SECURITY_MESSAGE:
                presentation = <SecurityMessage />;
                break;
            case LoginState.EULA:
                presentation = <EULA />;
                break;
            default:
                console.assert(this.props.loginState === LoginState.NONE);
                presentation = null;
                loginBoxClasses.push("hidden");
        }

        const errMsgEl = Array.isArray(errMessage)
            ? errMessage.map((msg, idx) => <p key={idx}>{msg}</p>)
            : errMessage;
        return (
            <ThemeRoot className="login-root">
                {this.props.isProcessing ? <Loader /> : null}
                <div className="login-page-container">
                    <div className={classnames(loginBoxClasses)}>
                        <div className="login-logo-div"></div>
                        <div className="validation-summary-errors">{errMsgEl}</div>
                        {presentation}
                    </div>
                </div>
            </ThemeRoot>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: selectors.getError(state),
        isProcessing: selectors.isProcessing(state),
        loginState: selectors.getLoginState(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAutoLogin: (key) => dispatch(actions.autoLogin(key, ownProps.history)),
        onSetSecurityMessage: (code) => dispatch(actions.setSecurityMessage(code)),
    };
};

export const LoginPage = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginPage_));
