import { layerListReducer } from "./components/layer-list/reducer";
import { LAYER_LIST_STATE_KEY } from "./components/layer-list/selectors";
import { analysisInfoReducer } from "./components/analysis-info/reducer";
import { ANALYSIS_INFO_STATE_KEY } from "./components/analysis-info/selectors";
import { imageryReducer } from "./components/imagery/reducer";
import { IMAGERY_STATE_KEY } from "./components/imagery/selectors";
import { nonFieldFeatureInformationReducer } from "./components/non-field-feature-information/reducer";
import { NON_FIELD_FEATURE_INFORMATION_STATE_KEY } from "./components/non-field-feature-information/selectors";

import * as actions from "./actions";

const recModuleInitialState = {
    activePage: actions.LayerModulePages.LAYER_LIST,
    [ANALYSIS_INFO_STATE_KEY]: analysisInfoReducer(undefined, { type: "INIT" }),
    [LAYER_LIST_STATE_KEY]: layerListReducer(undefined, { type: "INIT" }),
    [IMAGERY_STATE_KEY]: imageryReducer(undefined, { type: "INIT" }),
    [NON_FIELD_FEATURE_INFORMATION_STATE_KEY]: nonFieldFeatureInformationReducer(undefined, {
        type: "INIT",
    }),
};

export const layerModuleReducer = (state = recModuleInitialState, action) => {
    switch (action.type) {
        case actions.SET_ACTIVE_PAGE: {
            const { activePage } = action.payload;
            return Object.freeze({
                ...state,
                activePage,
            });
        }
        default: {
            const analysisInfoState = analysisInfoReducer(state[ANALYSIS_INFO_STATE_KEY], action);
            if (analysisInfoState !== state[ANALYSIS_INFO_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [ANALYSIS_INFO_STATE_KEY]: analysisInfoState,
                });
            }
            const layerListState = layerListReducer(state[LAYER_LIST_STATE_KEY], action);
            if (layerListState !== state[LAYER_LIST_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [LAYER_LIST_STATE_KEY]: layerListState,
                });
            }
            const imageryState = imageryReducer(state[IMAGERY_STATE_KEY], action);
            if (imageryState !== state[IMAGERY_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [IMAGERY_STATE_KEY]: imageryState,
                });
            }
            const nonFieldFeatureInformationState = nonFieldFeatureInformationReducer(
                state[NON_FIELD_FEATURE_INFORMATION_STATE_KEY],
                action
            );
            if (
                nonFieldFeatureInformationState !== state[NON_FIELD_FEATURE_INFORMATION_STATE_KEY]
            ) {
                state = Object.freeze({
                    ...state,
                    [NON_FIELD_FEATURE_INFORMATION_STATE_KEY]: nonFieldFeatureInformationState,
                });
            }
            return state;
        }
    }
};
