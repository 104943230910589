import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { defineMessages, injectIntl } from "react-intl";

import Immutable from "immutable";

import { getEventStatusMessageId } from "~/action-panel/components/common/status-messages";
import { getClassNameForStatusFromEvent } from "~/action-panel/components/common/status-codes";
import { Accordion, model as accordionModel, selectors as accordionSelectors } from "~/accordion";
import { Button, DialogBox, Loader } from "~/core";
import { errorCodeMessages } from "~/i18n-error-messages";
import {
    actions as recsEventsActions,
    recsSelectors,
    messages as recsEventsMessages,
} from "~/recs-events";
import { SearchAPI } from "@ai360/core";

import { BatchRecEventStrip } from "../../../../common/accordion/batch-rec-event-strip";
import {
    RecEventItem,
    REC_EVENT_HEIGHT,
} from "../../../../common/accordion/rec-event-accordion-item";
import * as selectors from "../selectors";
import { RecDetails, RecSummary } from "~/recs-events/recs/model";

import "./batch-recs-list.css";

interface IProps {
    accordionProps: any;
    fieldGuidToRecDetails: Immutable.OrderedMap<string, RecDetails>;
    fieldGuidToRecListMap: Immutable.OrderedMap<string, RecSummary[]>;
    fieldGuidToFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>;
    isCancelEnabled: boolean;
    isCloseEnabled: boolean;
    isCloseVisible: boolean;
    isEquation: boolean;
    isLoading: boolean;
    isRunEnabled: boolean;
    intl: any;
    lastRecBatchFieldGuid: string;
    onBack: () => void;
    onCancel: (value: boolean) => void;
    onSave: () => void;
    onSetCurrentBatchFieldGuid: (fieldGuid: string) => void;
    saveRecDetailsErrorCodeList: number[];
    saveRecDetailsErrorFieldGuidList: string[];
}

interface IState {
    displayErrors: boolean;
}

export const createBatchRecAccordionItems = (fieldGuidList: string[]) => {
    return fieldGuidList.map((fieldGuid) => {
        const payload = { fieldGuid };
        return new accordionModel.AccordionItem(REC_EVENT_HEIGHT, false, payload);
    });
};

export const messages = defineMessages({
    runAllBtnText: {
        id: "recEventInfo.batchRecsList.runAllBtnText",
        defaultMessage: "Run All",
    },
});

export class BatchRecsList_ extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            displayErrors: false,
        };
    }

    private getErrorMessage(errCode: number, isPlural = false) {
        const { formatMessage } = this.props.intl;
        if (errCode in errorCodeMessages) {
            return formatMessage(errorCodeMessages[errCode]);
        }
        return formatMessage(recsEventsMessages.validationErrorMsg, {
            errCode,
            count: isPlural ? 0 : 1,
        });
    }

    private getRecStripItem(
        recSummary: RecSummary,
        recDetails: RecDetails,
        field: SearchAPI.IFieldResult,
        fieldGuid: string
    ) {
        const displayNameParts = [
            recDetails.recType,
            moment(recDetails.momentCreatedDate).format("M/D/YY"),
        ];
        if (recDetails.name) {
            displayNameParts.push(recDetails.name);
        }

        const statusProps =
            recSummary == null
                ? {}
                : {
                      statusCodeClassName: getClassNameForStatusFromEvent(recSummary),
                      statusMessageArg: {
                          ...recSummary,
                          statusCode: recSummary.importedStatus,
                      },
                      statusMessageId:
                          recSummary.importedStatus === 0
                              ? undefined
                              : getEventStatusMessageId(recSummary, {
                                    recTypeGuid: recDetails.recTypeGuid,
                                    name: recDetails.name,
                                }),
                  };

        const itemProps = {
            isSelected: fieldGuid === this.props.lastRecBatchFieldGuid,
            displayName: displayNameParts.join(" - "),
            summary: [field.customerName, field.farmName, field.name]
                .filter((v) => v && v !== "")
                .join(", "),
            ...statusProps,
        };
        return <RecEventItem {...itemProps} />;
    }

    private getAccordionItemEl({ key, style, accordionItem }) {
        const { fieldGuidToFieldMap, fieldGuidToRecDetails, onSetCurrentBatchFieldGuid } =
            this.props;
        const { fieldGuid } = accordionItem.payload;
        const recDetails = fieldGuidToRecDetails.get(fieldGuid);
        const fieldDetails = fieldGuidToFieldMap.get(fieldGuid);
        const isRunning = recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) => rec.isAnyEquationRun)
        );
        let newRecSummary = this.getRecSummary(fieldGuid);
        if (isRunning && (!newRecSummary || !newRecSummary.importedStatus)) {
            newRecSummary.importedStatus = 29;
        } else if (!isRunning) {
            newRecSummary = null;
        }
        const onClick = () => onSetCurrentBatchFieldGuid(fieldGuid);

        return (
            <div key={key} style={style} className="accordion-item" onClick={onClick}>
                {this.getRecStripItem(newRecSummary, recDetails, fieldDetails, fieldGuid)}
            </div>
        );
    }

    private getRecSummary(fieldGuid: string) {
        const { fieldGuidToRecListMap, fieldGuidToRecDetails } = this.props;
        const recDetails = fieldGuidToRecDetails.get(fieldGuid);
        const recs = fieldGuidToRecListMap.get(fieldGuid);
        const isRunning = recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) => rec.isAnyEquationRun)
        );
        const recDate = recDetails.momentCreatedDate.format("MM/DD/YYYY");
        const orRecDate = recDetails.momentCreatedDate.format("M/D/YYYY");

        const found = recs.find(
            (rec) =>
                recDetails.recGeneralGuid === rec.recGeneralGuid ||
                (isRunning &&
                    (recDate === rec.recDate || orRecDate === rec.recDate) &&
                    recDetails.name === rec.recName)
        );
        return found || new RecSummary();
    }

    UNSAFE_componentWillReceiveProps(newProps: IProps) {
        if (newProps.saveRecDetailsErrorCodeList !== this.props.saveRecDetailsErrorCodeList) {
            this.setState({ displayErrors: true });
        }
    }

    render() {
        const {
            accordionProps,
            isCancelEnabled,
            isCloseEnabled,
            isCloseVisible,
            isEquation,
            isLoading,
            isRunEnabled,
            onBack,
            onCancel,
            onSave,
            fieldGuidToFieldMap,
            saveRecDetailsErrorCodeList,
            saveRecDetailsErrorFieldGuidList,
        } = this.props;
        const { formatMessage } = this.props.intl;

        if (saveRecDetailsErrorCodeList.length > 0 && this.state.displayErrors) {
            const errorMessageDivs = saveRecDetailsErrorCodeList.map((errCode) => (
                <div key={errCode}>
                    {this.getErrorMessage(
                        errCode,
                        saveRecDetailsErrorFieldGuidList &&
                            saveRecDetailsErrorFieldGuidList.length > 1
                    )}
                </div>
            ));

            return (
                <DialogBox
                    className="error-message-dialog-box"
                    title={formatMessage(recsEventsMessages.validationDlgTitle)}
                    isOpen
                    onClose={() => this.setState({ displayErrors: false })}
                >
                    {errorMessageDivs}
                    {saveRecDetailsErrorFieldGuidList.length === 0 ? null : (
                        <div className="error-field-list">
                            {saveRecDetailsErrorFieldGuidList.map((fieldGuid, index) => {
                                const field = fieldGuidToFieldMap.get(fieldGuid);
                                if (field) {
                                    const farmName = field.farmName ? `${field.farmName} - ` : "";
                                    const customerFarmField = `${field.customerName} - ${farmName}${field.name}`;
                                    return (
                                        <div key={`field-error-${index}`}>{customerFarmField}</div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    )}
                </DialogBox>
            );
        }

        accordionProps.getAccordionItemEl = this.getAccordionItemEl.bind(this);
        return (
            <div className="rec-event-batch-list">
                {!isLoading ? null : <Loader />}
                <div className="rec-event-info">
                    <BatchRecEventStrip />
                </div>
                <div className="rec-event-batch-list-accordion">
                    <Accordion {...accordionProps} />
                </div>
                <div className="save-cancel-btns">
                    {isEquation && isCloseVisible ? null : <Button type="back" onClick={onBack} />}
                    {!isEquation ? null : (
                        <Button
                            type="button"
                            value={formatMessage(messages.runAllBtnText)}
                            onClick={onSave}
                            disabled={!isRunEnabled}
                        />
                    )}
                    {!isEquation || isCloseVisible ? (
                        <Button
                            type={isEquation && isCloseVisible ? "close" : "save"}
                            disabled={isEquation && isCloseVisible && !isCloseEnabled}
                            onClick={onSave}
                        />
                    ) : null}
                    <Button
                        type="cancel"
                        disabled={!isCancelEnabled}
                        onClick={() => onCancel(true)}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onSetCurrentBatchFieldGuid: (fieldGuid) =>
        dispatch(recsEventsActions.setCurrentBatchFieldGuid(fieldGuid)),
});

const mapStateToProps = (state) => {
    const {
        fieldGuidToRecListMap,
        fieldGuidToRecDetails,
        fieldGuidToFieldMap,
        saveRecDetailsErrorCodeList,
        saveRecDetailsErrorFieldGuidList,
    } = recsSelectors.getModuleState(state);

    console.assert(fieldGuidToRecDetails.size > 1);

    const accordionState = selectors.getAccordionState(state);
    const flatIdxMapSelector = accordionSelectors.flatIdxMapSelector(accordionState);
    const dimIdxMapSelector = accordionSelectors.dimIdxMapSelector(accordionState);
    const lastRecBatchFieldGuid = recsSelectors.getLastRecBatchFieldGuid(state);

    const accordionProps = {
        itemCount: accordionState.recItemCount,
        totalHeight: accordionState.recHeight,
        getAccordionItemFromFlatIdx: (idx) => flatIdxMapSelector(idx),
        getAccordionItemFromDimIdx: (dimIdx) => dimIdxMapSelector(dimIdx),
    };

    return {
        accordionProps,
        fieldGuidToRecDetails,
        fieldGuidToRecListMap,
        fieldGuidToFieldMap,
        lastRecBatchFieldGuid,
        saveRecDetailsErrorCodeList,
        saveRecDetailsErrorFieldGuidList,
    };
};

export const BatchRecsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(BatchRecsList_));
