import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    NoLink,
    Tabs,
    Pane,
    optionPropType,
} from "~/core";

import {
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { unitNames, actions as unitActions, selectors as unitSelectors } from "~/core/units";

import { mapToolsActions } from "~/map";
import { Toolset, Tool } from "@ai360/core";
import {
    actions as recsEventsActions,
    models as recsEventsModel,
    eventsModels as em,
    eventsSelectors,
} from "~/recs-events";

import { getSetValuesForErrorCodeList } from "../../../../../../common/validation-utils";
import { getModuleState as getEventInfoModuleState } from "../../../selectors";

import { SamplingIcon } from "../../icons";

import * as actions from "../actions";
import * as selectors from "../selectors";

import { DepthConfigModal, getValidDepthConfig } from "./depth-config-modal";
import { PlaceGridModal } from "./place-grid-modal";
import { SamplePointTable } from "./sample-point-table";
import { SampleResultsTable } from "./sample-results-table";
import { TracePointsModal } from "./trace-points-modal";
import { logFirebaseEvent } from "~/utils/firebase";

import "../../../../../../common/rec-event-info/rec-event-info.css";
import "./event-sample-soil-form.css";

const messages = defineMessages({
    sampleSoilEditDepthTxt: {
        id: "eventModule.eventInfo.sampleSoilEditDepthTxt",
        defaultMessage: "Edit Depth",
    },
    sampleSoilFormLabelText: {
        id: "eventModule.eventInfo.sampleSoilFormLabelText",
        defaultMessage: "Soil",
    },
    sampleSoilManualTabTxt: {
        id: "eventModule.eventInfo.sampleSoilManualTabTxt",
        defaultMessage: "Manual",
    },
    sampleSoilMethodPlaceholderTxt: {
        id: "eventModule.eventInfo.sampleSoilMethodPlaceholderTxt",
        defaultMessage: "Placement Method",
    },
    sampleSoilPlaceGridTxt: {
        id: "eventModule.eventInfo.sampleSoilPlaceGridTxt",
        defaultMessage: "Grid Setup",
    },
    sampleSoilPlacePointsTxt: {
        id: "eventModule.eventInfo.sampleSoilPlacePointsTxt",
        defaultMessage: "Place Points",
    },
    sampleSoilProductivityRatingTxt: {
        id: "eventModule.eventInfo.sampleSoilProductivityRatingTxt",
        defaultMessage: "Productivity Rating",
    },
    sampleSoilRemoveGridDialogTitle: {
        id: "eventModule.eventInfo.sampleSoilRemoveGridDialogTitle",
        defaultMessage: "Remove Grid",
    },
    sampleSoilRemoveGridDialogMessage: {
        id: "eventModule.eventInfo.sampleSoilRemoveGridDialogMessage",
        defaultMessage: "This will remove the grid and all points. " + "Do you wish to continue?",
    },
    sampleSoilTracePointsTxt: {
        id: "eventModule.eventInfo.sampleSoilTracePointsTxt",
        defaultMessage: "Trace Points",
    },
});

const { getPickListCode, PICKLIST_NUMERIC_RATING, PICKLIST_SAMPLING_METHOD } = picklistNames;

const { getUnitCode, UNIT_AREA, UNIT_LENGTH } = unitNames;

const AUTO_METHOD = "Auto Placement";
const AUTO_METHOD_LABEL = "Auto";
const MANUAL_METHOD_LABEL = "Manual";
const GRID_METHOD_LABEL = "Grid";
const SUPPORTED_SAMPLING_METHODS = new Set([AUTO_METHOD, MANUAL_METHOD_LABEL, GRID_METHOD_LABEL]);

export const formLabelMessage = messages.sampleSoilFormLabelText;
export const formLabelIcon = SamplingIcon;

const errorCodeToMessageIdSetMap = new Map([
    [95, []], // ErrorCode.DepthConfigDepthIdRequired
    [96, []], // ErrorCode.DepthConfigStartDepthRequired
    [97, []], // ErrorCode.DepthConfigEndDepthRequired
    [99, []], // ErrorCode.SamplingGridSizeRequired
    [101, []], // ErrorCode.SamplingMultiDepthYnRequired
    [102, []], // ErrorCode.SamplingHeightRequired
    [103, []], // ErrorCode.SamplingWidthRequired
    [105, []], // ErrorCode.SamplingSequenceIdRequired
    [145, []], // ErrorCode.SamplingEventIdAlreadyUsed
    [146, []], // ErrorCode.SamplingEventIdRequired
    [2104, []], //ErrorCode.SamplingStartGreaterThanEnd
    [2156, []], // ErrorCode.SamplingSamplePointRequired
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class SampleSoilForm_ extends PureComponent {
    static propTypes = {
        agEventModel: PropTypes.object,
        defaultDepthConfig: PropTypes.object,
        defaultUserGridSettings: PropTypes.object,
        fieldGuid: PropTypes.string,
        gridOrientationSettings: PropTypes.any,
        intl: intlShape.isRequired,
        onAutoPlaceSamplingPoints: PropTypes.func.isRequired,
        onEnableAddPointsTool: PropTypes.func.isRequired,
        onFetchAutoTraceDefault: PropTypes.func.isRequired,
        onFetchPicklists: PropTypes.func.isRequired,
        onFetchSampleSoilEventDefaults: PropTypes.func.isRequired,
        onFetchSampleSoilFormPicklists: PropTypes.func.isRequired,
        onFetchUnits: PropTypes.func.isRequired,
        onRemoveGrid: PropTypes.func.isRequired,
        onSetSamplingFieldPointsPlaced: PropTypes.func.isRequired,
        onSetShowProductivityRating: PropTypes.func.isRequired,
        onSetSelectedPointSet: PropTypes.func.isRequired,
        onUpdateSamplingAgEventModel: PropTypes.func.isRequired,
        onUpdateBatchDepthConfig: PropTypes.func.isRequired,
        onUpdateUserDepthConfig: PropTypes.func.isRequired,
        picklistOptionsSamplingMethod: PropTypes.arrayOf(optionPropType).isRequired,
        picklistOptionsProductivityRating: PropTypes.arrayOf(optionPropType).isRequired,
        sampleSoilDepthUnitOptions: PropTypes.arrayOf(optionPropType).isRequired,
        samplingFieldPointsPlaced: PropTypes.bool,
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        selectedPointSet: PropTypes.object.isRequired,
        showProductivityRating: PropTypes.bool.isRequired,
        soilSampleResults: PropTypes.shape({
            gridData: PropTypes.array,
            gridHeader: PropTypes.array,
        }),
        unitOptionsArea: PropTypes.arrayOf(optionPropType).isRequired,
        unitOptionsLength: PropTypes.arrayOf(optionPropType).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            defaultUserGridSettingsCurrent: false,
            depthModalVisible: false,
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            haveSetDefaultValues: false,
            gridPlaced: false,
            placeGridModalVisible: false,
            removingPointsModalVisible: false,
            tracePointsModalVisible: false,
        };
    }

    _getDepthConfig() {
        const { agEventModel, defaultDepthConfig } = this.props;

        if (
            agEventModel.samplePoints.length === 0 ||
            agEventModel.samplePoints[0].samplePointDepths == null
        ) {
            return defaultDepthConfig;
        }

        return {
            depthIAGuid: agEventModel.depthIAGuid,
            configDetail: agEventModel.samplePoints[0].samplePointDepths,
        };
    }

    _getDepthModal() {
        const { onUpdateUserDepthConfig, sampleSoilDepthUnitOptions, soilSampleResults } =
            this.props;

        const viewResultsMode = soilSampleResults != null;

        const onClose = (newDepthConfig) => {
            if (newDepthConfig != null) {
                this._setSamplePointDepthsFromDepthConfig(newDepthConfig);
                onUpdateUserDepthConfig(newDepthConfig);
            }
            this.setState({ depthModalVisible: false });
        };

        return (
            <DepthConfigModal
                initialDepthConfig={this._getDepthConfig()}
                onClose={onClose}
                sampleSoilDepthUnitOptions={sampleSoilDepthUnitOptions}
                viewResultsMode={viewResultsMode}
            />
        );
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _getMethodSelectionPart() {
        const {
            agEventModel,
            fieldGuid,
            samplingFieldPointsPlaced,
            soilSampleResults,
            onAutoPlaceSamplingPoints,
            onEnableAddPointsTool,
            onSetSamplingFieldPointsPlaced,
            onUpdateSamplingAgEventModel,
            picklistOptionsSamplingMethod,
        } = this.props;
        const { formatMessage } = this.props.intl;

        if (
            agEventModel == null ||
            soilSampleResults != null ||
            picklistOptionsSamplingMethod.length === 0
        ) {
            return null;
        }
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        const manualMethodGuid = picklistOptionsSamplingMethod.find(
            (option) => option.label === MANUAL_METHOD_LABEL
        ).value;
        const gridMethodGuid = picklistOptionsSamplingMethod.find(
            (option) => option.label === GRID_METHOD_LABEL
        ).value;

        const selectedIdx = isBatchTemplate
            ? 0
            : agEventModel.sampleMethodGuid === gridMethodGuid
            ? 1
            : 0;
        const onTabSelect = (idx) => {
            if (this.state.gridPlaced && idx === 0) {
                this.setState({ removingPointsModalVisible: true });
                this.forceUpdate(); // update tabs props to cancel tab change
                return;
            }
            idx === 1 && logFirebaseEvent("sampling_grid");
            onUpdateSamplingAgEventModel({
                sampleMethodGuid: idx === 1 ? gridMethodGuid : manualMethodGuid,
            });
        };
        const autoPanes = [];
        const gridPanes = [];
        const manualPanes = [];
        manualPanes.push(
            <Pane key="manual" label={MANUAL_METHOD_LABEL} disabled={isBatchTemplate}>
                <div className="manual-method-steps">
                    <Button
                        disabled={!this.state.haveSetDefaultValues}
                        value={formatMessage(messages.sampleSoilPlacePointsTxt)}
                        onClick={onEnableAddPointsTool}
                    />
                </div>
            </Pane>
        );
        if (isBatchTemplate) {
            autoPanes.push(
                <Pane key="auto" label={AUTO_METHOD_LABEL}>
                    <div className="manual-method-steps">
                        <Button
                            disabled={samplingFieldPointsPlaced}
                            value={formatMessage(messages.sampleSoilPlacePointsTxt)}
                            onClick={() => {
                                onSetSamplingFieldPointsPlaced(true);
                                onAutoPlaceSamplingPoints();
                            }}
                        />
                    </div>
                </Pane>
            );
            return (
                <Tabs className="method-selection" onTabSelect={onTabSelect} selected={selectedIdx}>
                    {autoPanes}
                    {manualPanes}
                </Tabs>
            );
        } else {
            gridPanes.push(
                <Pane key="grid" label={GRID_METHOD_LABEL}>
                    <div className="grid-method-steps">
                        <Button
                            disabled={!this.state.gridPlaced}
                            onClick={() => this.setState({ tracePointsModalVisible: true })}
                            value={formatMessage(messages.sampleSoilTracePointsTxt)}
                        />
                        <Button
                            disabled={!this.state.haveSetDefaultValues}
                            onClick={() => this.setState({ placeGridModalVisible: true })}
                            value={formatMessage(messages.sampleSoilPlaceGridTxt)}
                        />
                    </div>
                    {!this.state.placeGridModalVisible ? null : this._getPlaceGridModal()}
                    {!this.state.removingPointsModalVisible ? null : this._getRemovingPointsModal()}
                    {!this.state.tracePointsModalVisible ? null : this._getTracePointsModal()}
                </Pane>
            );
            return (
                <Tabs className="method-selection" onTabSelect={onTabSelect} selected={selectedIdx}>
                    {manualPanes}
                    {gridPanes}
                </Tabs>
            );
        }
    }

    _getPlaceGridModal() {
        const { agEventModel, onUpdateSamplingAgEventModel, unitOptionsArea, unitOptionsLength } =
            this.props;

        return (
            <div className="panel-modal">
                <PlaceGridModal
                    agEventModel={agEventModel}
                    clearGridOnCancel={!this.state.gridPlaced}
                    onClose={(gridPlaced) =>
                        this.setState({
                            gridPlaced: gridPlaced || this.state.gridPlaced,
                            placeGridModalVisible: false,
                        })
                    }
                    onUpdateSamplingAgEventModel={onUpdateSamplingAgEventModel}
                    unitOptionsArea={unitOptionsArea}
                    unitOptionsLength={unitOptionsLength}
                />
            </div>
        );
    }

    _getRemovingPointsModal() {
        const { onRemoveGrid, onUpdateSamplingAgEventModel, picklistOptionsSamplingMethod } =
            this.props;
        const { formatMessage } = this.props.intl;

        const clearGridAndMoveToManualMethod = () => {
            const manualMethodGuid = picklistOptionsSamplingMethod.find(
                (option) => option.label === MANUAL_METHOD_LABEL
            ).value;
            this.setState(
                {
                    removingPointsModalVisible: false,
                    gridPlaced: false,
                },
                () => {
                    onUpdateSamplingAgEventModel({
                        sampleMethodGuid: manualMethodGuid,
                    });
                    onRemoveGrid();
                }
            );
        };

        return (
            <DialogBox
                isOpen
                onAction={clearGridAndMoveToManualMethod}
                onClose={() => this.setState({ removingPointsModalVisible: false })}
                footerType={DialogBoxFooterType.YES_NO}
                title={formatMessage(messages.sampleSoilRemoveGridDialogTitle)}
            >
                {formatMessage(messages.sampleSoilRemoveGridDialogMessage)}
            </DialogBox>
        );
    }

    _getSamplePointsPart() {
        const {
            agEventModel,
            fieldGuid,
            intl,
            soilSampleResults,
            onSetShowProductivityRating,
            onSetSelectedPointSet,
            picklistOptionsProductivityRating,
            selectedPointSet,
            showProductivityRating,
        } = this.props;
        const { formatMessage } = intl;
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        const viewResultsMode = soilSampleResults != null;
        if (viewResultsMode) {
            return (
                <div className="sample-points with-results">
                    <div className="table-controls">
                        <Checkbox
                            label={formatMessage(messages.sampleSoilProductivityRatingTxt)}
                            value={showProductivityRating}
                            onChange={(evt, val) => onSetShowProductivityRating(val)}
                        />
                        <NoLink
                            label={formatMessage(messages.sampleSoilEditDepthTxt)}
                            onClick={() => {
                                logFirebaseEvent("sampling_edit_depth");
                                this.setState({ depthModalVisible: true });
                            }}
                        />
                    </div>
                    <SampleResultsTable
                        intl={intl}
                        selectedPointSet={selectedPointSet}
                        onSetSelectedPointSet={onSetSelectedPointSet}
                        samplePoints={agEventModel.samplePoints}
                        soilSampleResults={soilSampleResults}
                        onSetPointProdRating={(idx, val) =>
                            this._setSamplePointProductivityRating(idx, val)
                        }
                        productivityRatingOptions={picklistOptionsProductivityRating}
                        showProductivityRating={showProductivityRating}
                    />
                    {!this.state.depthModalVisible ? null : this._getDepthModal()}
                </div>
            );
        }
        if (isBatchTemplate) {
            return (
                <div className="sample-points no-results">
                    <div className="table-controls">
                        <NoLink
                            label={formatMessage(messages.sampleSoilEditDepthTxt)}
                            onClick={() => {
                                logFirebaseEvent("sampling_edit_depth");
                                this.setState({ depthModalVisible: true });
                            }}
                        />
                    </div>
                    {!this.state.depthModalVisible ? null : this._getDepthModal()}
                </div>
            );
        }

        return (
            <div className="sample-points no-results">
                <div className="table-controls">
                    <Checkbox
                        label={formatMessage(messages.sampleSoilProductivityRatingTxt)}
                        value={showProductivityRating}
                        onChange={(evt, val) => onSetShowProductivityRating(val)}
                    />
                    <NoLink
                        label={formatMessage(messages.sampleSoilEditDepthTxt)}
                        onClick={() => {
                            logFirebaseEvent("sampling_edit_depth");
                            this.setState({ depthModalVisible: true });
                        }}
                    />
                </div>
                <SamplePointTable
                    depthConfig={this._getDepthConfig().configDetail}
                    selectedPointSet={selectedPointSet}
                    onSetSelectedPointSet={onSetSelectedPointSet}
                    onSetPointProdRating={(idx, val) =>
                        this._setSamplePointProductivityRating(idx, val)
                    }
                    productivityRatingOptions={picklistOptionsProductivityRating}
                    samplePoints={agEventModel == null ? [] : agEventModel.samplePoints}
                    showProductivityRating={showProductivityRating}
                />
                {!this.state.depthModalVisible ? null : this._getDepthModal()}
            </div>
        );
    }

    _getTracePointsModal() {
        return (
            <div className="panel-modal">
                <TracePointsModal
                    onClose={() => this.setState({ tracePointsModalVisible: false })}
                />
            </div>
        );
    }

    _setAgEventModelSampleDepths(props = this.props) {
        const { haveSetDefaultValues } = this.state;
        if (!haveSetDefaultValues) {
            return;
        }

        const { agEventModel, defaultDepthConfig } = props;
        if (
            agEventModel.samplePoints.length === 0 ||
            agEventModel.samplePoints[0].samplePointDepths != null
        ) {
            return;
        }

        const depthConfig = getValidDepthConfig(defaultDepthConfig);
        this._setSamplePointDepthsFromDepthConfig(depthConfig, props);
    }

    _setDefaultDepthConfig() {
        const {
            agEventModel,
            defaultDepthConfig,
            onUpdateUserDepthConfig,
            onUpdateSamplingAgEventModel,
            sampleSoilDepthUnitOptions,
        } = this.props;

        const depthConfig = getValidDepthConfig(defaultDepthConfig);

        // set the default `depthIAGuid` if not already set
        let { depthIAGuid } = depthConfig;
        if (agEventModel.depthIAGuid === "" || agEventModel.depthIAGuid === null) {
            if (depthIAGuid === "" || depthIAGuid === null) {
                // unit not set in config; just pick the first option
                depthIAGuid = sampleSoilDepthUnitOptions[0].value;
                depthConfig.depthIAGuid = depthIAGuid;
            }
            onUpdateSamplingAgEventModel({ depthIAGuid });
        }
        if (agEventModel.depthIAGuid && depthIAGuid === "") {
            depthConfig.depthIAGuid = agEventModel.depthIAGuid;
        }

        // set the default config if one has not been set previously
        if (JSON.stringify(defaultDepthConfig) !== JSON.stringify(depthConfig)) {
            onUpdateUserDepthConfig(depthConfig);
        }
    }

    _setDefaultGridSettings() {
        const {
            agEventModel,
            defaultUserGridSettings,
            onUpdateSamplingAgEventModel,
            unitOptionsArea,
            unitOptionsLength,
        } = this.props;

        const newProps = {};
        if (agEventModel.areaDimension === "") {
            newProps.areaDimension = defaultUserGridSettings.areaDimension;
        }
        if (agEventModel.pointPlacement === "") {
            newProps.pointPlacement = defaultUserGridSettings.pointPlacement;
        }
        if (agEventModel.height == null) {
            newProps.height = defaultUserGridSettings.height;
        }
        if (agEventModel.width == null) {
            newProps.width = defaultUserGridSettings.width;
        }
        if (agEventModel.gridSize == null) {
            newProps.gridSize = defaultUserGridSettings.gridSize;
        }
        if (agEventModel.rotation == null) {
            newProps.rotation = defaultUserGridSettings.rotation;
        }
        if (agEventModel.offsetX == null) {
            newProps.offsetX = defaultUserGridSettings.offsetX;
        }
        if (agEventModel.offsetY == null) {
            newProps.offsetY = defaultUserGridSettings.offsetY;
        }

        const areaDimension =
            agEventModel.areaDimension !== "" ? agEventModel.areaDimension : newProps.areaDimension;
        const unitOptionsList =
            areaDimension === em.AreaDimension.AREA ? unitOptionsArea : unitOptionsLength;
        let unitOption = unitOptionsList.find((o) => o.value === agEventModel.gridUnitGuid);
        if (unitOption == null) {
            unitOption = unitOptionsList.find(
                (o) => o.value === defaultUserGridSettings.gridUnitGuid
            );
            if (unitOption == null) {
                unitOption = unitOptionsList[0];
            }
        }
        if (agEventModel.gridUnitGuid !== unitOption.value) {
            newProps.gridUnitGuid = unitOption.value;
        }

        if (Object.keys(newProps).length > 0) {
            onUpdateSamplingAgEventModel(newProps);
        }
    }

    _setDefaultSamplingMethod() {
        const {
            agEventModel,
            defaultUserGridSettings,
            onUpdateSamplingAgEventModel,
            picklistOptionsSamplingMethod,
        } = this.props;

        if (agEventModel.sampleMethodGuid !== "") {
            return;
        }

        let option = picklistOptionsSamplingMethod.find(
            (option) => option.value === defaultUserGridSettings.sampleMethodGuid
        );
        let sampleMethodGuid;
        if (option != null) {
            sampleMethodGuid = option.value;
        } else {
            option = picklistOptionsSamplingMethod.find(
                (option) => option.label === MANUAL_METHOD_LABEL
            );

            console.assert(option != null);
            sampleMethodGuid = option.value;
        }

        onUpdateSamplingAgEventModel({ sampleMethodGuid });
    }

    _setSamplePointDepthsFromDepthConfig(depthConfig, props = this.props) {
        const { agEventModel, fieldGuid, onUpdateBatchDepthConfig, onUpdateSamplingAgEventModel } =
            props;
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        const newSamplePointDepths = depthConfig.configDetail.map((depthConfig) => ({
            ...depthConfig,
            hasImportData: false,
        }));
        if (isBatchTemplate) {
            //apply new depth config across Events
            const depthIAGuid = depthConfig.depthIAGuid;
            const samplePointDepths = newSamplePointDepths;
            onUpdateBatchDepthConfig(depthIAGuid, samplePointDepths);
        }

        const newProps = {
            depthIAGuid: depthConfig.depthIAGuid,
            samplePoints: agEventModel.samplePoints.map((samplePoint) => ({
                ...samplePoint,
                samplePointDepths: newSamplePointDepths,
            })),
        };

        onUpdateSamplingAgEventModel(newProps);
    }

    _setSamplePointProductivityRating(samplePointIdx, productivityRatingGuid) {
        const { agEventModel, onUpdateSamplingAgEventModel } = this.props;
        const samplePoints = [...agEventModel.samplePoints];
        samplePoints[samplePointIdx] = {
            ...agEventModel.samplePoints[samplePointIdx],
            productivityRatingGuid,
        };
        onUpdateSamplingAgEventModel({ samplePoints });
    }

    UNSAFE_componentWillMount() {
        const fetchPicklistNames = [PICKLIST_NUMERIC_RATING, PICKLIST_SAMPLING_METHOD];
        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});
        this.props.onFetchPicklists(picklistFetchArgObj);
        this.props.onFetchAutoTraceDefault();

        const fetchUnitListNames = [UNIT_AREA, UNIT_LENGTH];
        const unitsFetchArgObj = fetchUnitListNames.reduce((accum, key) => {
            accum[key] = getUnitCode(key);
            return accum;
        }, {});
        this.props.onFetchUnits(unitsFetchArgObj);

        //Defaults are based off the last successfully saved sampling event, so this needs to be called
        //every time this form is mounted for the first time, just in case it's changed since the last time
        if (!this.state.defaultUserGridSettingsCurrent) {
            this.props.onFetchSampleSoilEventDefaults();
            this.setState({
                defaultUserGridSettingsCurrent: true,
            });
        }

        if (this.props.sampleSoilDepthUnitOptions.length === 0) {
            this.props.onFetchSampleSoilFormPicklists();
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newAgEventModel = newProps.agEventModel !== this.props.agEventModel;
        const newPicklistOptionsSamplingMethod =
            newProps.picklistOptionsSamplingMethod !== this.props.picklistOptionsSamplingMethod;
        const newSaveEventDetailsErrorCodeList =
            newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList;

        if (
            !newAgEventModel &&
            !newPicklistOptionsSamplingMethod &&
            !newSaveEventDetailsErrorCodeList
        ) {
            return; // nothing that affects state has changed
        }

        if (newAgEventModel) {
            this._setAgEventModelSampleDepths(newProps);
        }

        const newState = {};
        if (newSaveEventDetailsErrorCodeList) {
            newState.errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
        }

        // set the default values when all values have been fetched; this only tracks if we're ready
        //  to set the values; they're only really set to the default of they're not set in the model.
        const setDefaultValues =
            !this.state.haveSetDefaultValues &&
            newProps.agEventModel != null &&
            newProps.picklistOptionsSamplingMethod.length > 0 &&
            newProps.sampleSoilDepthUnitOptions.length > 0 &&
            newProps.defaultDepthConfig != null &&
            newProps.defaultUserGridSettings != null &&
            newProps.unitOptionsArea.length > 0 &&
            newProps.unitOptionsLength.length > 0;

        if (setDefaultValues) {
            newState.haveSetDefaultValues = true;
        }

        this.setState(newState, () => {
            if (setDefaultValues) {
                this._setDefaultGridSettings();
                this._setDefaultSamplingMethod();
                this._setDefaultDepthConfig();
                this._setAgEventModelSampleDepths();
            }
        });

        if (
            JSON.stringify(this.props.gridOrientationSettings) !==
            JSON.stringify(newProps.gridOrientationSettings)
        ) {
            this.props.onUpdateSamplingAgEventModel({
                ...newProps.gridOrientationSettings,
            });
        }
    }

    render() {
        const { haveSetDefaultValues } = this.state;

        if (!haveSetDefaultValues) {
            return <Loader />;
        }

        return (
            <div className="event-sample-soil-form">
                {this._getMethodSelectionPart()}
                {this._getSamplePointsPart()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onAutoPlaceSamplingPoints: () =>
        dispatch(
            mapToolsActions.setActiveToolset(Toolset.SAMPLING_AUTO_PLACE, {
                placePoints: "A",
                isAuto: true,
                isTissue: false,
                fields: [{ fieldGuid: recsEventsModel.BATCH_TEMPLATE_FIELD_GUID }],
            })
        ),
    onEnableAddPointsTool: () => dispatch(mapToolsActions.setActiveMapTool(Tool.SamplingAddSelect)),
    onFetchAutoTraceDefault: () => dispatch(actions.fetchAutoTracePreferences()),
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onFetchSampleSoilEventDefaults: () => dispatch(actions.fetchSampleSoilEventDefaults()),
    onFetchSampleSoilFormPicklists: () => dispatch(actions.fetchSampleSoilFormPicklists()),
    onFetchUnits: (units) => dispatch(unitActions.fetchUnitData(units)),
    onRemoveGrid: () => dispatch(mapToolsActions.setRemoveGridFlag(true)),
    onSetSamplingFieldPointsPlaced: (isPlaced) =>
        dispatch(recsEventsActions.setSamplingFieldPointsPlaced(isPlaced)),
    onSetShowProductivityRating: (showProductivityRating) =>
        dispatch(actions.setShowProductivityRating(showProductivityRating)),
    onSetSelectedPointSet: (selectedPointSet) =>
        dispatch(recsEventsActions.setSelectedSamplePoints(selectedPointSet)),
    onUpdateBatchDepthConfig: (depthIAGuid, samplePointDepths) =>
        dispatch(recsEventsActions.updateBatchDepthConfig(depthIAGuid, samplePointDepths)),
    onUpdateUserDepthConfig: (depthConfig) => dispatch(actions.updateUserDepthConfig(depthConfig)),
    onUpdateSamplingAgEventModel: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateSamplingAgEventModel(fieldGuid, newProps)),
});

const mapStateToProps = (state) => {
    const sampleModuleState = selectors.getModuleState(state);
    const {
        samplingFieldPointsPlaced,
        saveEventDetailsErrorCodeList,
        selectedSamplePointGuidIdSet,
        soilSampleResults,
    } = eventsSelectors.getModuleState(state);

    const { eventInfoPicklists } = getEventInfoModuleState(state);
    const sampleSoilDepthUnitOptions = eventInfoPicklists.sampleSoilDepthUnitOptions || [];

    return {
        defaultDepthConfig: sampleModuleState.userDepthConfig,
        defaultUserGridSettings: sampleModuleState.userGridSettings,
        picklistOptionsSamplingMethod: picklistSelectors
            .getPicklistOptionsFromCode(state, getPickListCode(PICKLIST_SAMPLING_METHOD))
            .filter((option) => SUPPORTED_SAMPLING_METHODS.has(option.label)),
        picklistOptionsProductivityRating: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_NUMERIC_RATING)
        ),
        sampleSoilDepthUnitOptions,
        gridOrientationSettings: eventsSelectors.getModuleState(state).gridOrientationSettings,
        samplingFieldPointsPlaced,
        saveEventDetailsErrorCodeList,
        selectedPointSet: selectedSamplePointGuidIdSet,
        showProductivityRating: sampleModuleState.showProductivityRating,
        soilSampleResults,
        unitOptionsArea: unitSelectors.getUnitPicklistOptionsFromCode(
            state,
            getUnitCode(UNIT_AREA)
        ),
        unitOptionsLength: unitSelectors.getUnitPicklistOptionsFromCode(
            state,
            getUnitCode(UNIT_LENGTH)
        ),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateSamplingAgEventModel: (newProps) =>
        dispatchProps.onUpdateSamplingAgEventModel(ownProps.fieldGuid, newProps),
});

export const SampleSoilForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SampleSoilForm_));
