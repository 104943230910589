import { IBufferOptions, Toolset } from "@ai360/core";
import { IActionData, IMessages } from "./interfaces";
import Geometry from "@arcgis/core/geometry/Geometry";
export const PROCESS_COUNTER_MESSAGE = "map-tools/PROCESS_COUNTER_MESSAGE";
export const APPLY_TRACE = "map-tools/APPLY_TRACE";
export const CLEAR_TRACE_FLAGS = "map-tools/CLEAR_TRACE_FLAGS";
export const RESET_TRACE = "map-tools/RESET_TRACE";
export const SET_ACTIVE_MAP_TOOL = "map-tools/SET_ACTIVE_MAP_TOOL";
export const SET_ACTIVE_TOOLSET = "map-tools/SET_ACTIVE_TOOLSET";
export const SET_ACTIVE_TOOLSET_ONLY = "map-tools/SET_ACTIVE_TOOLSET_ONLY";
export const SET_ACTIVE_TOOLSET_PAYLOAD_ONLY = "map-tools/SET_ACTIVE_TOOLSET_PAYLOAD_ONLY";
export const SET_BATCH_RESET = "map-tools/SET_BATCH_RESET";
export const SET_BUFFER_OPTIONS = "map-tools/SET_BUFFER_OPTIONS";
export const SET_BUFFER_READY = "map-tools/SET_BUFFER_READY";
export const SET_CAN_CLIP = "map-tools/SET_CAN_CLIP";
export const SET_CAN_CLIP_PENDING = "map-tools/SET_CAN_CLIP_PENDING";
export const SET_FIELD_EDIT_GEOMETRIES = "map-tools/SET_FIELD_EDIT_GEOMETRIES";
export const SET_FIELD_EDITS_CHANGED = "map-tools/SET_FIELD_EDITS_CHANGED";
export const SET_FIELD_EDITS_LOADED = "map-tools/SET_FIELD_EDITS_LOADED";
export const SET_REMOVE_GRID_FLAG = "map-tools/SET_REMOVE_GRID_FLAG";
export const SET_TOOLS_PROCESSING = "map-tools/SET_TOOLS_PROCESSING";
export const SET_TOOLSET_DISABLED = "map-tools/SET_TOOLSET_DISABLED";
export const SET_VISIBLE_TOOLBAR_MENU_DIMENSIONS = "map-tools/SET_VISIBLE_TOOLBAR_MENU_DIMENSIONS";
export const UNITS_FETCH_SUCCEEDED = "map-tools/UNITS_FETCH_SUCCEEDED";
export const UNITS_FETCH_FAILED = "map-tools/UNITS_FETCH_FAILED";

export const applyTrace = (): IActionData => ({
    type: APPLY_TRACE,
    flags: {
        apply: true,
    },
});

export const setBatchReset = (batchReset: boolean): IActionData => ({
    type: SET_BATCH_RESET,
    batchReset,
});

export const clearTraceFlags = (): IActionData => ({
    type: CLEAR_TRACE_FLAGS,
});

export const resetTrace = (): IActionData => ({
    type: RESET_TRACE,
    flags: {
        reset: true,
    },
});

export const setActiveMapTool = (activeMapTool: string): IActionData => ({
    type: SET_ACTIVE_MAP_TOOL,
    activeMapTool,
});

/**
 * Set the Active Toolset with a custom payload to get the toolset started
 * if `activeToolset === DRAW_EDIT` then payload is an object that contains
 *     at a minimum the customerGuid and fieldGuid
 * @param {number} activeToolset type `Toolset`
 * @param {?object} payload custom optional payload for the given toolset to get started
 * @param {?boolean} disabled sets toolsetDisabled flag
 */
export const setActiveToolset = (
    activeToolset: Toolset,
    payload = {},
    disabled = false
): IActionData => ({
    type: SET_ACTIVE_TOOLSET,
    activeToolset,
    disabled,
    payload,
});

export const setActiveToolsetOnly = (activeToolset: Toolset, disabled = false): IActionData => ({
    type: SET_ACTIVE_TOOLSET_ONLY,
    activeToolset,
    disabled,
});

export const setActiveToolsetPayloadOnly = (payload: unknown): IActionData => ({
    type: SET_ACTIVE_TOOLSET_PAYLOAD_ONLY,
    payload,
});

export const setBufferOptions = (options: Partial<IBufferOptions>): IActionData => ({
    type: SET_BUFFER_OPTIONS,
    options,
});

export const setCanClip = (canClip: boolean): IActionData => ({
    type: SET_CAN_CLIP,
    canClip,
});

export const setCanClipPending = (canClipPending: boolean): IActionData => ({
    type: SET_CAN_CLIP_PENDING,
    canClipPending,
});

export const processCounterMessage = (messages: IMessages[]): IActionData => ({
    type: PROCESS_COUNTER_MESSAGE,
    payload: [...messages],
});

export const setBufferReady = (ready: boolean): IActionData => ({
    type: SET_BUFFER_READY,
    ready,
});

export const setFieldEditGeometries = (geometries: Geometry): IActionData => ({
    type: SET_FIELD_EDIT_GEOMETRIES,
    payload: { geometries },
});

export const setFieldEditsChanged = (changed: boolean): IActionData => ({
    type: SET_FIELD_EDITS_CHANGED,
    payload: { changed },
});

export const setFieldEditsLoaded = (loaded: boolean): IActionData => ({
    type: SET_FIELD_EDITS_LOADED,
    payload: { loaded },
});

export const setRemoveGridFlag = (gridRemoveFlag: boolean): IActionData => ({
    type: SET_REMOVE_GRID_FLAG,
    gridRemoveFlag,
});

export const setToolsProcessing = (isProcessing: boolean): IActionData => ({
    type: SET_TOOLS_PROCESSING,
    isProcessing,
});

export const setToolsetDisabled = (disabled = true): IActionData => ({
    type: SET_TOOLSET_DISABLED,
    disabled,
});

export const setVisibleToolbarMenuDimensions = (dimensions: number[]): IActionData => ({
    type: SET_VISIBLE_TOOLBAR_MENU_DIMENSIONS,
    dimensions,
});

export const unitsFetchSucceeded = (areaUnits: number[], lengthUnits: number[]): IActionData => ({
    type: UNITS_FETCH_SUCCEEDED,
    areaUnits,
    lengthUnits,
});

export const unitsFetchFailed = (errMessage: Error): IActionData => ({
    type: UNITS_FETCH_FAILED,
    errMessage,
});
