import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    NumericInput,
    SelectInput,
    optionPropType,
} from "~/core";

import { ACTIVE_YN } from "~/core/picklist";

import { TrashcanIcon } from "~/core/icons";

import "../../../../../../common/rec-event-info/rec-event-info.css";
import "./depth-config-modal.css";

const messages = defineMessages({
    sampleSoilDepthTblIdColLbl: {
        id: "eventModule.eventInfo.sampleSoilDepthTblIdColLbl",
        defaultMessage: "ID",
    },
    sampleSoilDepthTblEndColLbl: {
        id: "eventModule.eventInfo.sampleSoilDepthTblEndColLbl",
        defaultMessage: "Ending",
    },
    sampleSoilDepthTblStartColLbl: {
        id: "eventModule.eventInfo.sampleSoilDepthTblStartColLbl",
        defaultMessage: "Starting",
    },
    sampleDepthDialogTitle: {
        id: "eventModule.eventInfo.sampleDepthDialogTitle",
        defaultMessage: "Sampling Depth",
    },
    sampleDepthDialogAddLink: {
        id: "eventModule.eventInfo.sampleDepthDialogAddLink",
        defaultMessage: "+ Add New Depth",
    },
    sampleSoilDepthPlaceholderTxt: {
        id: "eventModule.eventInfo.sampleSoilDepthPlaceholderTxt",
        defaultMessage: "Depth Unit",
    },
    sampleSoilDepthValidationErrorTxt: {
        id: "eventModule.eventInfo.sampleSoilDepthValidationErrorTxt",
        defaultMessage: "Starting depth cannot be greater than ending depth.",
    },
    sampleSoilDepthValidationErrorTitle: {
        id: "eventModule.eventInfo.sampleSoilDepthValidationErrorTitle",
        defaultMessage: "Invalid Depth",
    },
});

export const getValidDepthConfig = (depthConfig) => {
    const configDetail =
        Array.isArray(depthConfig.configDetail) && depthConfig.configDetail.length > 0
            ? depthConfig.configDetail.slice(0, DepthConfigModal_.DEPTH_IDS.length)
            : [DepthConfigModal_.DEFAULT_DEPTH_DETAIL];

    configDetail.sort((d1, d2) =>
        d1.depthId < d2.depthId ? -1 : d1.depthId === d2.depthId ? 0 : 1
    );

    return {
        depthIAGuid: depthConfig.depthIAGuid != null ? depthConfig.depthIAGuid : "",
        configDetail: configDetail.map((d, idx) => ({
            depthId: DepthConfigModal_.DEPTH_IDS[idx],
            startDepth: d.startDepth,
            endDepth: d.endDepth,
            invalidDepth: d.startDepth > d.endDepth,
        })),
    };
};

export class DepthConfigModal_ extends PureComponent {
    static DEPTH_IDS = ["A", "B", "C", "D", "E"];
    static DEFAULT_DEPTH_DETAIL = {
        depthId: DepthConfigModal_.DEPTH_IDS[0],
        startDepth: 0,
        endDepth: 6,
    };

    static propTypes = {
        initialDepthConfig: PropTypes.object,
        intl: intlShape.isRequired,
        onClose: PropTypes.func.isRequired,
        sampleSoilDepthUnitOptions: PropTypes.arrayOf(optionPropType).isRequired,
        viewResultsMode: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showInvalidDepth: false,
            depthConfig: getValidDepthConfig(props.initialDepthConfig),
        };
    }

    _addDepth() {
        const { depthConfig } = this.state;
        const { configDetail } = depthConfig;

        console.assert(
            configDetail.length > 0 && configDetail.length < DepthConfigModal_.DEPTH_IDS
        );

        const prevDepth = configDetail[configDetail.length - 1];
        const depth = {
            depthId: DepthConfigModal_.DEPTH_IDS[configDetail.length],
            startDepth: prevDepth.endDepth,
            endDepth: prevDepth.endDepth + Math.abs(prevDepth.endDepth - prevDepth.startDepth),
        };
        this.setState({
            depthConfig: getValidDepthConfig({
                ...depthConfig,
                configDetail: [...depthConfig.configDetail, depth],
            }),
        });
    }

    _checkInvalidDepth() {
        const { showInvalidDepth } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <DialogBox
                containerClassNames={["err-depth-validation"]}
                draggable={false}
                isOpen={showInvalidDepth}
                closeOnClickOff
                closeOnEscape
                title={formatMessage(messages.sampleSoilDepthValidationErrorTitle)}
                onClose={() => {
                    this.setState({ showInvalidDepth: false });
                }}
            >
                <div className="err-msg-detail">
                    {formatMessage(messages.sampleSoilDepthValidationErrorTxt)}
                </div>
            </DialogBox>
        );
    }

    _getTblHeaderPart() {
        const { formatMessage } = this.props.intl;
        const { depthConfig } = this.state;
        const idLbl =
            depthConfig.configDetail.length === 1
                ? null
                : formatMessage(messages.sampleSoilDepthTblIdColLbl);
        return (
            <div className="depth-tbl-hdr">
                <div>{idLbl}</div>
                <div>{formatMessage(messages.sampleSoilDepthTblStartColLbl)}</div>
                <div>{formatMessage(messages.sampleSoilDepthTblEndColLbl)}</div>
                <div />
            </div>
        );
    }

    _getTblRow(depth) {
        const { viewResultsMode } = this.props;
        const { depthConfig } = this.state;
        const multiDepth = depthConfig.configDetail.length > 1;
        const removeIcon =
            !multiDepth || viewResultsMode ? (
                <div />
            ) : (
                <div className="remove-icon" onClick={() => this._removeDepth(depth.depthId)}>
                    <TrashcanIcon />
                </div>
            );
        const classes = [depth.invalidDepth ? "invalid-depth" : ""];
        return (
            <div className="depth-tbl-row" key={JSON.stringify(depth)}>
                <div>{!multiDepth ? null : depth.depthId}</div>
                <div>
                    <NumericInput
                        containerClassNames={classes}
                        scale={2}
                        showTopLabel={false}
                        onBlur={() => this._validateDepthConfig()}
                        onChange={(v, f, numVal) => this._setConfigDepths(depth.depthId, numVal)}
                        precision={5}
                        value={depth.startDepth}
                    />
                </div>
                <div>
                    <NumericInput
                        containerClassNames={classes}
                        scale={2}
                        showTopLabel={false}
                        onBlur={() => this._validateDepthConfig()}
                        onChange={(e, v, numVal) =>
                            this._setConfigDepths(depth.depthId, null, numVal)
                        }
                        precision={5}
                        value={depth.endDepth}
                    />
                </div>
                {removeIcon}
            </div>
        );
    }

    _removeDepth(depthId) {
        const { depthConfig } = this.state;
        const idx = depthConfig.configDetail.findIndex((depth) => depth.depthId === depthId);
        const configDetail = [
            ...depthConfig.configDetail.slice(0, idx),
            ...depthConfig.configDetail.slice(idx + 1),
        ];
        this.setState({
            depthConfig: getValidDepthConfig({
                ...depthConfig,
                configDetail,
            }),
        });
    }

    _setDepthIAGuid(depthIAGuid) {
        this.setState({
            depthConfig: {
                ...this.state.depthConfig,
                depthIAGuid,
            },
        });
    }

    _setConfigDepths(depthId, startDepth, endDepth) {
        const { depthConfig } = this.state;
        const depthIdx = depthConfig.configDetail.findIndex((d) => d.depthId === depthId);
        console.assert(depthIdx != null);

        if (startDepth != null) {
            depthConfig.configDetail[depthIdx].startDepth = startDepth;
        }
        if (endDepth != null) {
            depthConfig.configDetail[depthIdx].endDepth = endDepth;
        }
        const invalidDepth =
            depthConfig.configDetail[depthIdx].startDepth >
            depthConfig.configDetail[depthIdx].endDepth;
        depthConfig.configDetail[depthIdx].invalidDepth = invalidDepth;

        this.setState({ depthConfig });
    }

    _validateDepthConfig() {
        this.setState({
            depthConfig: getValidDepthConfig(this.state.depthConfig),
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialDepthConfig !== this.props.initialDepthConfig) {
            this.setState({
                depthConfig: getValidDepthConfig(nextProps.initialDepthConfig),
            });
        }
    }

    render() {
        const { onClose, sampleSoilDepthUnitOptions, viewResultsMode } = this.props;
        const { formatMessage } = this.props.intl;
        const { depthConfig } = this.state;
        const { configDetail } = depthConfig;

        const addLink =
            configDetail.length === DepthConfigModal_.DEPTH_IDS.length ? null : (
                <NoLink
                    disabled={viewResultsMode}
                    className="add-link"
                    label={formatMessage(messages.sampleDepthDialogAddLink)}
                    onClick={() => this._addDepth()}
                />
            );

        const hasInvalidDepth = depthConfig.configDetail.some((config) => config.invalidDepth);
        const invalidDepthBox = this._checkInvalidDepth();

        return (
            <DialogBox
                draggable
                enableSave
                isOpen
                unrestricted
                className="sample-soil-depth-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                onAction={() => {
                    if (!hasInvalidDepth) {
                        onClose(getValidDepthConfig(depthConfig));
                    } else {
                        this.setState({ showInvalidDepth: true });
                    }
                }}
                onClose={() => onClose()}
                title={formatMessage(messages.sampleDepthDialogTitle)}
            >
                {invalidDepthBox}

                <SelectInput
                    clearable={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    disabled={sampleSoilDepthUnitOptions.length === 0}
                    onChange={(v) => this._setDepthIAGuid(v)}
                    options={sampleSoilDepthUnitOptions}
                    placeholderText={formatMessage(messages.sampleSoilDepthPlaceholderTxt)}
                    value={depthConfig.depthIAGuid}
                />

                <div className="depth-table">
                    {this._getTblHeaderPart()}
                    {configDetail.map((d) => this._getTblRow(d))}
                    {addLink}
                </div>
            </DialogBox>
        );
    }
}

export const DepthConfigModal = injectIntl(DepthConfigModal_);
