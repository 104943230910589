import React, { Component, ReactNode } from "react";
import _ from "lodash";
import TableHeader from "./components/header";
import Paginator from "./components/paginator";
import TableCell from "./components/table-cell";
import TableRows from "./components/table-rows";
import classnames from "classnames";
import { NoLink } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "~/i18n-messages";
import "./data-table.css";
import {
    ITableService,
    IIndex,
    IFilterQuery,
    ISortOptions,
    IDataTableFooterOptions,
    IMessages,
    IRecord,
    ISearchData,
} from "../interfaces";

export interface IDataTableProps {
    autoSearchList?: ISearchData;
    classNames?: string;
    debounceFilterInput?: boolean;
    deleteSelected?: (params: IIndex) => void;
    disableSelectAll?: boolean;
    disableSort?: boolean;
    footerOptions?: IDataTableFooterOptions[];
    formatMessage?: (message: string) => void;
    forcedRender?: boolean; // This will allow scenarios like reports to update in realtime
    getAutoSearchList?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    getAutoSearchListAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    intl: intlShape;
    invisibleAddEdit?: boolean;
    isCheckbox?: boolean;
    isEditable?: boolean;
    messages?: IMessages[];
    onFilterChange?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onFilterChangeAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onPaginatorChange?: (index: IIndex) => void;
    onPaginatorChangeAlt?: (index: IIndex) => void;
    onRowSelection?: () => void;
    onRowUnselect?: () => void;
    onSelect?: (SelItems: IRecord | IRecord[]) => void;
    onSelectAll?: (obj: IIndex) => void;
    onSelectAllAlt?: (obj: IIndex, bool?: boolean) => void;
    onSortOption?: (obj: ISortOptions) => void;
    onSortOptionAlt?: (obj?: ISortOptions) => void;
    openAddEditPanel?: () => void;
    records: IRecord[];
    renderRows?: (columns: ReactNode | ReactNode[]) => ReactNode | ReactNode[];
    selectAll?: IRecord[];
    selectedItems?: IRecord[];
    selectedRow?: IRecord;
    service: ITableService;
    showFilterInput?: boolean;
    showHideAddButton?: boolean;
    totalCount?: number;
    lastSelectedRow?: IRecord;
    setLastEditedRow?: () => void;
}

export interface IDataTableState {
    selectedItems: IRecord[];
    filterQuery: IFilterQuery;
    sortOptions: ISortOptions;
    skip: number;
    pageSize: string;
    selectedRow: IRecord;
}

export class DataTable extends Component<IDataTableProps, IDataTableState> {
    static defaultProps = {
        debounceFilterInput: false,
        disableSort: false,
        forcedRender: true,
        service: {},
        records: [],
        invisibleAddEdit: false,
        isCheckbox: true,
        showFilterInput: true,
        showHideAddButton: true,
        selectedRow: null,
        selectedItems: [],
    };

    constructor(props: IDataTableProps) {
        super(props);
        this.state = {
            selectedItems: [],
            filterQuery: {},
            sortOptions: {},
            skip: 0,
            pageSize: `50`,
            selectedRow: null,
        };
    }

    shouldComponentUpdate(nextProps: IDataTableProps, nextState: IDataTableState): boolean {
        if (
            JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records) ||
            nextState.selectedItems !== this.state.selectedItems ||
            nextState.selectedRow !== this.state.selectedRow ||
            this.props.autoSearchList !== nextProps.autoSearchList ||
            nextProps.forcedRender
        ) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps: IDataTableProps): void {
        if (nextProps.selectAll !== this.props.selectAll) {
            this.setState(
                {
                    selectedItems: nextProps.selectAll || [],
                },
                this.onItemSelection
            );
        }

        if (
            nextProps.selectedItems !== this.props.selectedItems &&
            JSON.stringify(nextProps.selectedItems) !== JSON.stringify(this.state.selectedItems)
        ) {
            this.setState(
                {
                    selectedItems: nextProps.selectedItems as IRecord[],
                },
                this.onItemSelection
            );
        }
        if (nextProps.selectedRow !== this.state.selectedRow) {
            this.setState({
                selectedRow: nextProps.selectedRow,
            });
        }
    }

    private getColumns = (): string[] => {
        const { service } = this.props;
        return service.getDefaultColumns ? service.getDefaultColumns() : [];
    };

    public toggleSelectAll(): void {
        if (this.state.selectedItems.length === 0) {
            const { filterQuery, sortOptions } = this.state;
            this.props.onSelectAllAlt != null
                ? this.props.onSelectAllAlt({ filterQuery, sortOptions })
                : this.props.onSelectAll({ filterQuery, sortOptions });
        } else {
            if (this.props.onSelectAllAlt != null) {
                const { filterQuery, sortOptions } = this.state;
                this.props.onSelectAllAlt({ filterQuery, sortOptions }, true);
            }
            this.setState(
                {
                    selectedItems: [],
                },
                this.onItemSelection
            );
        }
    }

    private onItemSelection = (): void => {
        if (this.props.onSelect) {
            this.props.onSelect(this.state.selectedItems);
        }
    };

    private onSortOption = ({ sortOptions, filterQuery }): void => {
        if (this.props.disableSort) {
            return;
        }
        const { skip, pageSize } = this.state;
        if (filterQuery) {
            this.setState({ filterQuery, sortOptions }, () => {
                this.props.onSortOptionAlt != null
                    ? this.props.onSortOptionAlt({
                          sortOptions,
                          filterQuery,
                          skip,
                          pageSize,
                      })
                    : this.props.onSortOption({
                          sortOptions,
                          filterQuery,
                          skip,
                          pageSize,
                      });
            });
        } else {
            this.setState({ sortOptions }, () => {
                this.props.onSortOptionAlt != null
                    ? this.props.onSortOptionAlt({
                          sortOptions,
                          skip,
                          pageSize,
                      })
                    : this.props.onSortOption({ sortOptions, skip, pageSize });
            });
        }
    };

    private getAutoSearchList = (attr: string, value: string, filterQuery: IFilterQuery): void => {
        this.setState({ filterQuery }, () => {
            this.props.getAutoSearchListAlt != null
                ? this.props.getAutoSearchListAlt(attr, value, filterQuery)
                : this.props.getAutoSearchList(attr, value, filterQuery);
        });
    };

    private onFilterChange = (attr: string, value: string, filterQuery: IFilterQuery): void => {
        this.setState({ filterQuery }, () => {
            this.props.onFilterChangeAlt != null
                ? this.props.onFilterChangeAlt(attr, value, this.state.filterQuery)
                : this.props.onFilterChange(attr, value, this.state.filterQuery);
        });
    };

    private onPaginatorChange = ({ skip, pageSize }): void => {
        const { filterQuery, sortOptions } = this.state;
        this.setState({ skip, pageSize }, () => {
            this.props.onPaginatorChangeAlt != null
                ? this.props.onPaginatorChangeAlt({
                      filterQuery,
                      sortOptions,
                      skip,
                      pageSize,
                  })
                : this.props.onPaginatorChange({
                      filterQuery,
                      sortOptions,
                      skip,
                      pageSize,
                  });
        });
    };

    public deleteSelected = (selectedItems: IRecord | IRecord[], deleteFromRow?: boolean): void => {
        if (this.props.deleteSelected) {
            const { filterQuery, sortOptions, skip, pageSize } = this.state;
            this.props.deleteSelected({
                filterQuery,
                sortOptions,
                selectedItems,
                skip,
                pageSize,
                deleteFromRow,
            });
        }
    };

    public selectItems = (record: IRecord, value: boolean): void => {
        const { service } = this.props;
        const selectedRecord = service.getDefaultGuid(record);
        const selectedItems = [...this.state.selectedItems];
        if (!value) {
            // Remove item if it's already available in the array
            _.remove(selectedItems, (item) => {
                return service.isItemSelected(item, selectedRecord);
            });
        } else {
            selectedItems.push(selectedRecord);
        }
        this.setState(
            {
                selectedItems,
            },
            this.onItemSelection
        );
    };

    private isFooterButtonDisabled = (
        disableIfNotSelected: boolean,
        disableIfNotSuccessful = false
    ): boolean => {
        return this.props.service.isFooterButtonDisabled(
            disableIfNotSelected,
            this.state.selectedItems,
            disableIfNotSuccessful
        );
    };

    private renderHeader = (columns = this.getColumns()): ReactNode => {
        const {
            debounceFilterInput,
            disableSort,
            service,
            showFilterInput,
            autoSearchList,
            openAddEditPanel,
            invisibleAddEdit,
            isEditable,
            isCheckbox,
            messages,
            showHideAddButton,
        } = this.props;
        return (
            <TableHeader
                columns={columns}
                debounceFilterInput={debounceFilterInput}
                disableSort={disableSort}
                service={service}
                invisibleAddEdit={invisibleAddEdit}
                isCheckbox={isCheckbox}
                isEditable={isEditable}
                showFilterInput={showFilterInput}
                autoSearchData={autoSearchList}
                getAutoSearchList={this.getAutoSearchList}
                onFilterChange={this.onFilterChange}
                onSortOption={this.onSortOption}
                openAddEditPanel={openAddEditPanel}
                messages={messages}
                formatMessage={this.props.intl.formatMessage}
                showHideAddButton={showHideAddButton}
            />
        );
    };

    private renderPaginator = (): ReactNode => {
        return (
            <Paginator
                totalRecords={this.props.totalCount}
                onPaginatorChange={this.onPaginatorChange}
                numOfRecords={this.props.service.numOfRecords}
                isModalWindow={this.props.service.isModalWindow}
            />
        );
    };

    renderRows = (columns = this.getColumns()): ReactNode | ReactNode[] => {
        const {
            isCheckbox,
            service,
            records,
            openAddEditPanel,
            isEditable,
            onRowSelection,
            selectedRow,
            lastSelectedRow,
            setLastEditedRow,
        } = this.props;
        return (
            <TableRows
                records={records}
                columns={columns}
                isCheckbox={isCheckbox}
                isEditable={isEditable}
                service={service}
                onItemSelection={this.selectItems}
                selectedItems={this.state.selectedItems}
                openAddEditPanel={openAddEditPanel}
                deleteSelected={this.deleteSelected}
                onRowSelection={onRowSelection}
                selectedRow={selectedRow}
                lastSelectedRow={lastSelectedRow}
                setLastEditedRow={setLastEditedRow}
                isModalWindow={this.props.service.isModalWindow}
            />
        );
    };

    renderFooter = (): ReactNode => {
        const { formatMessage } = this.props.intl;
        const { footerOptions, totalCount, isCheckbox, records } = this.props;
        const { selectedItems } = this.state;
        const { filterQuery, sortOptions, skip, pageSize } = this.state;
        let areAnySelected = false;
        if (this.props.disableSelectAll && records.length === 0) {
            return null;
        }
        if (selectedItems && totalCount) {
            areAnySelected = selectedItems.length > 0;
        }
        return (
            <div className={"data-table-footer"}>
                {!this.props.disableSelectAll ? null : <TableCell className="justify-links" />}
                {isCheckbox && (
                    <TableCell key="SelectAll">
                        <NoLink
                            className={this.props.disableSelectAll ? "disabled-link" : ""}
                            label={
                                areAnySelected
                                    ? formatMessage(messages.deselectAll)
                                    : formatMessage(messages.selectAll)
                            }
                            onClick={
                                !this.props.disableSelectAll
                                    ? () => this.toggleSelectAll()
                                    : () => null
                            }
                        />
                        {footerOptions && <span className="bar" />}
                    </TableCell>
                )}
                {footerOptions &&
                    footerOptions.map(
                        (
                            {
                                label,
                                action,
                                disableIfNotSelected = false,
                                disableIfNotSuccessful = false,
                            },
                            index
                        ) => (
                            <TableCell key={`data-table-footer-${index}`}>
                                <NoLink
                                    className={
                                        this.isFooterButtonDisabled(
                                            disableIfNotSelected,
                                            disableIfNotSuccessful
                                        )
                                            ? "disabled-link"
                                            : ""
                                    }
                                    label={label}
                                    onClick={() =>
                                        !this.isFooterButtonDisabled(
                                            disableIfNotSelected,
                                            disableIfNotSuccessful
                                        )
                                            ? action({
                                                  filterQuery,
                                                  sortOptions,
                                                  selectedItems,
                                                  skip,
                                                  pageSize,
                                              })
                                            : () => null
                                    }
                                />
                                {index !== footerOptions.length - 1 && <span className="bar" />}
                            </TableCell>
                        )
                    )}
            </div>
        );
    };

    render(): ReactNode {
        const header = this.renderHeader();
        const footer = this.renderFooter();
        let rows: ReactNode | ReactNode[];
        if (this.props.renderRows) {
            rows = this.props.renderRows({ ...this });
        } else {
            rows = this.renderRows();
        }
        const paginator = this.renderPaginator();
        const className = classnames(
            "content-table-container data-table-cont",
            this.props.classNames
        );
        return (
            <div className={className}>
                {header}
                {rows}
                {footer}
                {paginator}
            </div>
        );
    }
}

export default injectIntl(DataTable);
