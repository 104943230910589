import { SetupReportBooksAPI } from "@ai360/core";
import React, { Component } from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { connect } from "react-redux";
import { ActiveInactiveLink } from "~/admin/setup/component/active-inactive-link";
import { messages } from "~/admin/setup/i18n-messages";
import {
    IAutoSearchData,
    IMergeReportBook,
    IMergeReportBookData,
    IReportBook,
    MergeStatus,
} from "~/admin/setup/report-books/data/interfaces";
import { getReportBooks } from "~/admin/setup/report-books/data/selectors";
import { preventBubbleUp } from "~/admin/utils";
import { selectors as loginSelectors } from "~/login";
import { actions, model, selectors, service } from "./data";
import { Button, DataTable, DialogBox, DialogBoxFooterType, Loader, LoaderTypes } from "~/core";
import { getApiErrorCodeList } from "~/core/api/selectors";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { withMasked } from "~/hocs";
import "./setup-report-books.css";

import AddEditPanel from "~/admin/setup/report-books/add-edit/add-edit-container";
import SlidingPanel from "~/sliding-panel/sliding-panel";

interface ISetupReportBooksProps {
    getReportBookTypes: (includeInactive: boolean) => SetupReportBooksAPI.IReportBookTypeResponse;
    loadingReportBooks: boolean;
    mergeReportBookType: (data: IMergeReportBook) => SetupReportBooksAPI.IMergeResult;
    mergeStatus: MergeStatus;
    reportBooks: SetupReportBooksAPI.IReportBookTypeRecord[];
    selectedReportBookTypeGuid: string;
    setBreadcrumbs: (...args: any[]) => any;
    setMergeStatus: (MergeStatus) => void;
    slidingPanelProps: any;
    userRole: any;
}

interface ISetupReportBooksState {
    autoSearchData: IAutoSearchData;
    copyReportBook: boolean;
    currentCoverPage: string;
    fetchData: boolean;
    filteredReportBooks: IReportBook[];
    loadingReportBooks: boolean;
    reportBooks: IReportBook[];
    reportBookNameError: boolean;
    reportsError: boolean;
    s3CleanupListOnCancel: string[];
    s3CleanupListOnSave: string[];
    selectedItems: string[];
    selectedOrgLevelGuidsError: boolean;
    selectedReportBook: IReportBook;
    selectedReportBookTypeGuid: string;
    showAddEditPanel: boolean;
    showCopyReportBookModal: boolean;
    showDeleteConfirmationModal: boolean;
    showInactive: boolean;
    showValidationModal: boolean;
}

const EDIT = "EDIT";
const NO = "No";
const YES = "Yes";

class SetupReportBooks_ extends Component<
    ISetupReportBooksProps & InjectedIntlProps,
    ISetupReportBooksState
> {
    constructor(props) {
        super(props);

        this.state = {
            autoSearchData: null,
            copyReportBook: false,
            currentCoverPage: null,
            fetchData: false,
            filteredReportBooks: [],
            loadingReportBooks: props.loadingReportBooks,
            reportBooks: [],
            reportBookNameError: false,
            reportsError: false,
            s3CleanupListOnCancel: [],
            s3CleanupListOnSave: [],
            selectedItems: [],
            selectedOrgLevelGuidsError: false,
            selectedReportBook: null,
            selectedReportBookTypeGuid: null,
            showAddEditPanel: false,
            showCopyReportBookModal: false,
            showDeleteConfirmationModal: false,
            showInactive: false,
            showValidationModal: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getReportBookTypes(this.state.showInactive);
    }

    componentWillUnmount() {
        this.setState({
            filteredReportBooks: [],
            reportBooks: [],
            showInactive: false,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISetupReportBooksProps) {
        const newState: any = {};

        if (JSON.stringify(nextProps.reportBooks) !== JSON.stringify(this.state.reportBooks)) {
            newState.reportBooks = nextProps.reportBooks.map((rb) => ({
                [model.PROPS_CAN_DELETE]: true,
                [model.PROPS_REPORT_BOOK_NAME]: rb.name,
                [model.PROPS_ACTIVE_YN]: rb.activeYN,
                [model.PROPS_SETUP_REPORT_BOOKS_TABLE_DATA]: rb,
            }));
            newState.filteredReportBooks = newState.reportBooks;
        }

        if (nextProps.loadingReportBooks !== this.state.loadingReportBooks) {
            newState.loadingReportBooks = nextProps.loadingReportBooks;
        }

        if (
            nextProps.mergeStatus === MergeStatus.success &&
            nextProps.mergeStatus !== this.props.mergeStatus
        ) {
            this.closeAddEditPanel();
        }

        if (nextProps.selectedReportBookTypeGuid !== this.props.selectedReportBookTypeGuid) {
            newState.selectedReportBookTypeGuid = nextProps.selectedReportBookTypeGuid;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    render() {
        let props = {};
        const {
            autoSearchData,
            fetchData,
            filteredReportBooks,
            loadingReportBooks,
            reportBookNameError,
            reportsError,
            selectedOrgLevelGuidsError,
            selectedReportBook,
            selectedReportBookTypeGuid,
            showAddEditPanel,
            showCopyReportBookModal,
            showDeleteConfirmationModal,
            showValidationModal,
        } = { ...this.state };
        const { formatMessage } = this.props.intl;
        const { liftCopyReportBook, liftErrorData, liftRecordData } = this;
        if (showAddEditPanel) {
            const copyButton =
                this.state.selectedReportBookTypeGuid == null ? null : (
                    <Button
                        type="copy"
                        onClick={() => this.setState({ showCopyReportBookModal: true })}
                    />
                );
            props = {
                children: [
                    <Button
                        key="save-btn"
                        type="save"
                        disabled={reportBookNameError || reportsError || selectedOrgLevelGuidsError}
                        forceSubmit
                        onClick={() => this.toggleFetchData(true)}
                    />,
                    copyButton,
                    <Button
                        key="cancel-btn"
                        type="cancel"
                        className="user-cancel"
                        onClick={() => this.closeAddEditPanel()}
                    />,
                ],
                component: AddEditPanel as unknown as JSX.Element,
                fetchData,
                liftCopyReportBook,
                liftErrorData,
                liftRecordData,
                navigateTo: { parentNameCode: "101", childNameCode: "266" },
                onUpdateCurrentCoverPage: (currentCoverPage: string) => {
                    this.setState({ currentCoverPage });
                },
                onUpdateS3CleanupList: this.updateS3CleanupList,
                reportBookNameError,
                selectedReportBookInfo: selectedReportBook,
                selectedReportBookTypeGuid,
                showCopyReportBookModal,
            };
        }
        return (
            <div className="content-table-container setup-report-books-table">
                {!loadingReportBooks ? null : <Loader type={LoaderTypes.LINE_SCALE} />}
                {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                    <div className="active-inactive-container">
                        <ActiveInactiveLink
                            intl={this.props.intl}
                            toggleActiveInactive={this.toggleActiveInactive}
                        />
                    </div>
                )}
                <DataTable
                    autoSearchList={autoSearchData}
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    {...this.props}
                    getAutoSearchList={this.getAutoSearchList}
                    onFilterChange={this.onFilterChange}
                    onRowSelection={this.onRowSelection}
                    setLastEditedRow={() => {
                        /* purposely left blank */
                    }}
                    deleteSelected={this.confirmDelete}
                    records={filteredReportBooks}
                    openAddEditPanel={this.openAddEditPanel}
                    totalCount={filteredReportBooks.length}
                    disableSort={true}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel {...props} />
                    </form>
                )}
                <DialogBox
                    className="report-book-data-validation-modal"
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={showValidationModal}
                    onClose={() => this.setState({ showValidationModal: false })}
                    title={"Report Options"}
                >
                    {!reportBookNameError ? null : (
                        <div>{formatMessage(messages.reportBookNameRequired)} </div>
                    )}
                    {!reportsError ? null : <div>{formatMessage(messages.reportLengthError)}</div>}
                    {!selectedOrgLevelGuidsError ? null : (
                        <div>{formatMessage(messages.selectedOrgLevelGuidsError)}</div>
                    )}
                </DialogBox>

                <DialogBox
                    action={"Delete"}
                    className="delete-confirmation-modal"
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    isOpen={showDeleteConfirmationModal}
                    onAction={() => this.deleteSelected()}
                    onClose={() => {
                        this.setState({
                            showDeleteConfirmationModal: false,
                            selectedItems: [],
                        });
                    }}
                    title={"Delete Confirmation"}
                >
                    <p>Are you sure you want to delete {selectedReportBook?.name}?</p>
                </DialogBox>
            </div>
        );
    }

    private closeAddEditPanel() {
        const cleanupList = (
            this.state.selectedReportBook != null &&
            this.state.selectedReportBook[model.PROPS_COVER_PAGE_URL] == null
                ? [...this.state.s3CleanupListOnCancel, this.state.currentCoverPage]
                : this.state.s3CleanupListOnCancel
        ).filter((x) => x != null && x.length > 0);
        if (cleanupList.length > 0) {
            SetupReportBooksAPI.deleteReportBookCoverPageFilesFromS3(cleanupList);
        }
        this.setState({
            copyReportBook: false,
            currentCoverPage: null,
            s3CleanupListOnCancel: [],
            s3CleanupListOnSave: [],
            showCopyReportBookModal: false,
        });
        this.props.getReportBookTypes(this.state.showInactive);
        this.toggleAddEdit(false);
    }

    private confirmDelete = (options) => {
        const { selectedItems } = options;
        this.setState({ selectedItems });
        this.setState({ showDeleteConfirmationModal: true });
    };

    private deleteSelected = () => {
        for (const reportBookGuid of this.state.selectedItems) {
            this.props.deactivateReportBookType(reportBookGuid);
        }
        this.setState({ showDeleteConfirmationModal: false });
    };

    private liftCopyReportBook = () => {
        this.setState({ copyReportBook: true, showCopyReportBookModal: false });
    };

    private liftErrorData = (data: {
        reportBookNameError: boolean;
        reportsLength: number;
        orgLevelGuidsLength: number;
    }) => {
        const newState: Partial<ISetupReportBooksState> = {};
        if (data.reportBookNameError === false) {
            newState.reportBookNameError = false;
        }
        if (data.reportsLength > 0) {
            newState.reportsError = false;
        }
        if (data.orgLevelGuidsLength > 0) {
            newState.selectedOrgLevelGuidsError = false;
        }
        this.setState(newState as ISetupReportBooksState);
    };

    private liftRecordData = (data: IMergeReportBookData) => {
        this.toggleFetchData(false);
        let hasErrors = false;
        const newState: any = {};
        newState.reportBookNameError =
            data[model.PROPS_NAME] == null || data[model.PROPS_NAME] === "";
        newState.reportsError = data.reports?.length === 0;
        newState.selectedOrgLevelGuidsError = data.orgLevelGuids?.length === 0;
        hasErrors = Object.values(newState).some((val) => val === true);
        newState.showValidationModal = hasErrors;
        let reportBookTypeGuid = null;
        if (this.state.copyReportBook) {
            reportBookTypeGuid = null;
        } else if (this.state.selectedReportBookTypeGuid != null) {
            reportBookTypeGuid = this.state.selectedReportBookTypeGuid;
        }
        data.reportBookTypeGuid = reportBookTypeGuid;
        newState.copyReportBook = false;
        newState.showCopyReportBookModal = false;
        this.setState(newState, () => {
            if (!hasErrors) {
                // make call to new api to generate report book
                this.props.mergeReportBookType(data);
                const cleanupList = this.state.s3CleanupListOnSave.filter(
                    (x) => x != null && x.length > 0
                );
                if (cleanupList.length > 0) {
                    SetupReportBooksAPI.deleteReportBookCoverPageFilesFromS3(
                        this.state.s3CleanupListOnSave
                    );
                }
                this.setState({
                    currentCoverPage: null,
                    s3CleanupListOnCancel: [],
                    s3CleanupListOnSave: [],
                });
            }
        });
    };

    private getAutoSearchList = (attr: string, value: string) => {
        const attrName = `${attr[0].toLowerCase()}${attr.slice(1)}`;
        const autoSearchData: IAutoSearchData = {
            searchName: attrName,
            response: [],
        };
        if (attrName === model.PROPS_REPORT_BOOK_NAME) {
            autoSearchData.response = this.state.reportBooks
                .filter((rb) => rb[attrName].toLowerCase().includes(value.toLowerCase()))
                .map((rb) => ({
                    value: rb.name,
                }));
        } else if (
            attrName === model.PROPS_ACTIVE_YN &&
            typeof this.state.reportBooks[0][attrName] === "boolean"
        ) {
            autoSearchData.response = [
                {
                    value: YES,
                },
                {
                    value: NO,
                },
            ];
        }
        this.setState({ autoSearchData });
    };

    private onFilterChange = (attr: string, value: string) => {
        const attrName = `${attr[0].toLowerCase()}${attr.slice(1)}`;
        const filteredReportBooks = [];
        if (value === "") {
            filteredReportBooks.push(...this.state.reportBooks);
        } else if (attrName === model.PROPS_ACTIVE_YN) {
            if (value === YES) {
                filteredReportBooks.push(
                    ...this.state.reportBooks.filter((rb) => rb[attrName] === true)
                );
            } else if (value === NO) {
                filteredReportBooks.push(
                    ...this.state.reportBooks.filter((rb) => rb[attrName] === false)
                );
            }
        } else if (attrName === model.PROPS_REPORT_BOOK_NAME) {
            filteredReportBooks.push(
                ...this.state.reportBooks.filter(
                    (rb) => rb[attrName].toLowerCase() === value.toLowerCase()
                )
            );
        }
        this.setState({ filteredReportBooks });
    };

    private onRowSelection = (event, record) => {
        this.setState({
            selectedReportBookTypeGuid: record.data.reportBookTypeGuid,
            selectedReportBook: record.data,
        });
    };

    private openAddEditPanel = (modelName, mode, reportBookTypeGuid, data = null) => {
        this.setState({
            selectedReportBookTypeGuid: mode === EDIT ? reportBookTypeGuid?.guid : null,
            selectedReportBook: data,
        });
        this.props.setMergeStatus(MergeStatus.pending);
        this.toggleAddEdit(true);
    };

    private toggleAddEdit = (showAddEditPanel: boolean) => {
        this.setState({ showAddEditPanel });
        this.props.setBreadcrumbs();
    };

    private toggleActiveInactive = () => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.getReportBookTypes(this.state.showInactive);
                const activeColumnName = service.activeColumnName || "activeYn";
                if (service._defaultLabels[activeColumnName]) {
                    service.toggleColumnVisibility(activeColumnName, this.state.showInactive);
                }
            }
        );
    };

    private toggleFetchData = (fetchData: boolean) => {
        this.setState({ fetchData });
    };

    private updateS3CleanupList = (filename: string, isOriginal: boolean) => {
        const newCleanupOnCancel = isOriginal ? [] : [filename];
        const newCleanupOnSave = [filename];
        this.setState({
            s3CleanupListOnCancel: [...this.state.s3CleanupListOnCancel, ...newCleanupOnCancel],
            s3CleanupListOnSave: [...this.state.s3CleanupListOnSave, ...newCleanupOnSave],
        });
    };
}

const mapStateToProps = (state) => ({
    apiErrors: getApiErrorCodeList(state),
    loadingReportBooks: selectors.getLoadingReportBooks(state),
    mergeStatus: selectors.getMergeStatus(state),
    reportBooks: getReportBooks(state),
    selectedReportBookTypeGuid: selectors.getSelectedReportBookTypeGuid(state),
    userRole: loginSelectors.getUser(state).role,
});

const mapDispatchToProps = (dispatch) => ({
    deactivateReportBookType: (payload) => dispatch(actions.deactivateReportBookType(payload)),
    getReportBookTypes: (payload) => dispatch(actions.getReportBookTypes(payload)),
    mergeReportBookType: (payload) => dispatch(actions.mergeReportBookType(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    setMergeStatus: (payload) => dispatch(actions.setMergeStatus(payload)),
});

export const SetupReportBooks = injectIntl(
    withMasked(connect(mapStateToProps, mapDispatchToProps)(SetupReportBooks_))
);
