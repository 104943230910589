import { apiUrl } from "@ai360/core";

export const URL_GET_PICKLIST_CHILD_VALUE_LIST = apiUrl("Picklist/GetPicklistChildValueList");

export const PICKLIST_ADDRESS_TYPE = "addressType";
export const PICKLIST_AG_BYTES_REQUEST_TYPE = "agBytesRequestType";
export const PICKLIST_AG_EVENT_TYPE = "agEventType";
export const PICKLIST_APPLICATION_METHOD = "applicationMethod";
export const PICKLIST_APPLICATION_NOZZLE_TYPE = "applicationNozzleType";
export const PICKLIST_APPLICATION_TIMING = "applicationTiming";
export const PICKLIST_ATTRIBUTE_CATEGORY = "attributeCategory";
export const PICKLIST_BALE_TYPE = "baleType";
export const PICKLIST_BRAND_ORGANIZATION = "brandOrganization";
export const PICKLIST_BUILD_FACTOR = "buildFactor";
export const PICKLIST_CATEGORY = "picklistCategory";
export const PICKLIST_CHEMICAL_FAMILY = "chemicalFamily";
export const PICKLIST_CLASS_SIZE = "classSize";
export const PICKLIST_CORRECTION_TYPE = "correctionType";
export const PICKLIST_COTTON_COLOR_GRADE = "cottonColorGrade";
export const PICKLIST_COUNTRY = "country";
export const PICKLIST_COUNT_UNIT = "countUnit";
export const PICKLIST_CROPPING_SEASON = "croppingSeason";
export const PICKLIST_CROPPING_SEASON_CYCLE = "croppingSeasonCycle";
export const PICKLIST_CROP_PURPOSE = "cropPurpose";
export const PICKLIST_CUSTOM_PRODUCT_TYPE = "customProductType";
export const PICKLIST_DATA_TYPE = "dataType";
export const PICKLIST_DENSITY_RATING = "densityRating";
export const PICKLIST_DEVELOPER = "developer";
export const PICKLIST_DIRECTION = "direction";
export const PICKLIST_DRIVE_TRAIN = "driveTrain";
export const PICKLIST_EQUIPMENT_PARENT_TYPE = "equipmentParentType";
export const PICKLIST_EQUIPMENT_TYPE_NAME = "equipmentTypeName";
export const PICKLIST_EXPENSE_CATEGORY = "expenseCategory";
export const PICKLIST_FORMULATION_TYPE = "formulationType";
export const PICKLIST_FUEL_TYPE = "fuelType";
export const PICKLIST_GENERAL_WEATHER = "generalWeather";
export const PICKLIST_GEO_TAG_MEDIA_TYPE = "geoTagMediaType";
export const PICKLIST_GROWTH_STAGE_GROUP_TYPE = "growthStageGroupType";
export const PICKLIST_HITCH_TYPE = "hitchType";
export const PICKLIST_HERBICIDE_TOLERANCE = "herbicideTolerance";
export const PICKLIST_IMAGERY_TYPE = "imageryType";
export const PICKLIST_INCORPORATION_DEPTH = "incorporationDepth";
export const PICKLIST_INSURANCE_POLICY_TYPE = "insurancePolicyType";
export const PICKLIST_IRRIGATION_TYPE = "irrigationType";
export const PICKLIST_IRRIGATION_CATEGORY = "irrigationCategory";
export const PICKLIST_JOB_TITLE = "jobTitle";
export const PICKLIST_LAB_TEST_TYPE = "labTestType";
export const PICKLIST_LAND_OWNERSHIP_TYPE = "landOwnershipType";
export const PICKLIST_LICENSE_TYPE = "licenseType";
export const PICKLIST_LIFESPAN = "lifespan";
export const PICKLIST_LOCATIONON_PLANT = "locationonPlant";
export const PICKLIST_MAINTENANCE_TYPE = "maintenanceType";
export const PICKLIST_MANUFACTURER = "manufacturer";
export const PICKLIST_MANURE_FORM = "manureForm";
export const PICKLIST_MANURE_TYPE = "manureType";
export const PICKLIST_MORPHOLOGY_TYPE = "morphologyType";
export const PICKLIST_NON_FIELD_FEATURE_TYPE = "nonFieldFeatureType";
export const PICKLIST_NORMALIZED_YIELD_CLASS = "normalizedYieldClass";
export const PICKLIST_NUMERIC_RATING = "numericRating";
export const PICKLIST_OBSERVATION_PARENT_TYPE = "observationParentType";
export const PICKLIST_OBSERVATION_TYPE = "observationType";
export const PICKLIST_ORGANISM_TYPE = "organismType";
export const PICKLIST_PAY_TYPE = "payType";
export const PICKLIST_PHONE_TYPE = "phoneType";
export const PICKLIST_PHYSICAL_STATE = "physicalState";
export const PICKLIST_PLANTING_PATTERN = "plantingPattern";
export const PICKLIST_PLANT_PART = "plantPart";
export const PICKLIST_PRICE_UNIT = "priceUnit";
export const PICKLIST_PRODUCT_ACTION_METHOD = "productActionMethod";
export const PICKLIST_PRODUCT_COST_TYPES = "productCostTypes";
export const PICKLIST_PRODUCT_LABEL_CHECKING = "productLabelChecking";
export const PICKLIST_PRODUCT_PARENT_TYPE = "productParentType";
export const PICKLIST_PRODUCT_TYPE = "productType";
export const PICKLIST_REC_OPTION = "recOption";
export const PICKLIST_RELATIVE_RELATIONSHIP_TYPE = "relativeRelationshipType";
export const PICKLIST_ROW_ORIENTATION = "rowOrientation";
export const PICKLIST_ROW_SELECTION = "rowSelection";
export const PICKLIST_SALES_PIPELINE_MILESTONES = "salesPipelineMilestones";
export const PICKLIST_SAMPLE_TYPE = "sampleType";
export const PICKLIST_SAMPLING_METHOD = "samplingMethod";
export const PICKLIST_SEASON_FILTER_TYPE = "seasonFilterType";
export const PICKLIST_SOFTWARE_LICENSE = "softwareLicense";
export const PICKLIST_SOFTWARE_LICENSE_STATUS = "softwareLicenseStatus";
export const PICKLIST_SOIL_CONDITION = "soilCondition";
export const PICKLIST_SOIL_TEXTURE = "soilTexture";
export const PICKLIST_STORAGE_TYPE = "storageType";
export const PICKLIST_SYSTEM_ATTRIBUTE = "systemAttribute";
export const PICKLIST_TARGET_PH = "targetpH";
export const PICKLIST_TERMS = "terms";
export const PICKLIST_THRESHOLD_TYPE = "thresholdType";
export const PICKLIST_TILE_TYPE = "tileType";
export const PICKLIST_TILLAGE_METHOD = "tillageMethod";
export const PICKLIST_TIRES_TYPE = "tiresType";
export const PICKLIST_TRAIT_CHARACTERISTICS = "traitCharacteristics";
export const PICKLIST_TRAIT_SPECIFICS = "traitSpecifics";
export const PICKLIST_TRANSMISSION_TYPE = "transmissionType";
export const PICKLIST_TRANSPLANT_TYPE = "transplantType";
export const PICKLIST_UNIT_SYSTEM = "unitSystem";
export const PICKLIST_UNIT_TYPE = "unitType";
export const PICKLIST_URGENCY_LEVEL = "urgencyLevel";
export const PICKLIST_USAGE_UNIT = "usageUnit";
export const PICKLIST_USER_TYPE = "userType";
export const PICKLIST_VARIETY_ATTRIBUTE = "varietyAttribute";
export const PICKLIST_VENDOR_TYPE = "vendorType";
export const PICKLIST_WATER_PURPOSE = "waterPurpose";
export const PICKLIST_WATER_SOURCE_TYPE = "waterSourceType";
export const PICKLIST_WELL_TYPE = "wellType";
export const PICKLIST_WORK_ORDER_STATUS = "workOrderStatus";
export const PICKLIST_WORK_ORDER_TYPE = "workOrderType";

const picklistNameToCodeMap = {
    [PICKLIST_ADDRESS_TYPE]: 59,
    [PICKLIST_AG_BYTES_REQUEST_TYPE]: 1,
    [PICKLIST_AG_EVENT_TYPE]: 78,
    [PICKLIST_APPLICATION_METHOD]: 3,
    [PICKLIST_APPLICATION_NOZZLE_TYPE]: 111,
    [PICKLIST_APPLICATION_TIMING]: 4,
    [PICKLIST_ATTRIBUTE_CATEGORY]: 61,
    [PICKLIST_BALE_TYPE]: 5,
    [PICKLIST_BRAND_ORGANIZATION]: 47,
    [PICKLIST_BUILD_FACTOR]: 119,
    [PICKLIST_CHEMICAL_FAMILY]: 48,
    [PICKLIST_CLASS_SIZE]: 36,
    [PICKLIST_CORRECTION_TYPE]: 37,
    [PICKLIST_COTTON_COLOR_GRADE]: 6,
    [PICKLIST_COUNT_UNIT]: 28,
    [PICKLIST_COUNTRY]: 106,
    [PICKLIST_CROP_PURPOSE]: 97,
    [PICKLIST_CROPPING_SEASON]: 9,
    [PICKLIST_CROPPING_SEASON_CYCLE]: 10,
    [PICKLIST_CUSTOM_PRODUCT_TYPE]: 57,
    [PICKLIST_DATA_TYPE]: 109,
    [PICKLIST_DENSITY_RATING]: 102,
    [PICKLIST_DEVELOPER]: 104,
    [PICKLIST_DIRECTION]: 18,
    [PICKLIST_DRIVE_TRAIN]: 38,
    [PICKLIST_EQUIPMENT_PARENT_TYPE]: 40,
    [PICKLIST_EQUIPMENT_TYPE_NAME]: 39,
    [PICKLIST_EXPENSE_CATEGORY]: 49,
    [PICKLIST_FORMULATION_TYPE]: 51,
    [PICKLIST_FUEL_TYPE]: 41,
    [PICKLIST_GENERAL_WEATHER]: 13,
    [PICKLIST_GEO_TAG_MEDIA_TYPE]: 14,
    [PICKLIST_GROWTH_STAGE_GROUP_TYPE]: 107,
    [PICKLIST_HITCH_TYPE]: 110,
    [PICKLIST_HERBICIDE_TOLERANCE]: 128,
    [PICKLIST_IMAGERY_TYPE]: 130,
    [PICKLIST_INCORPORATION_DEPTH]: 118,
    [PICKLIST_INSURANCE_POLICY_TYPE]: 103,
    [PICKLIST_IRRIGATION_TYPE]: 12,
    [PICKLIST_JOB_TITLE]: 62,
    [PICKLIST_LAB_TEST_TYPE]: 74,
    [PICKLIST_LAND_OWNERSHIP_TYPE]: 63,
    [PICKLIST_LICENSE_TYPE]: 64,
    [PICKLIST_LIFESPAN]: 29,
    [PICKLIST_LOCATIONON_PLANT]: 101,
    [PICKLIST_MAINTENANCE_TYPE]: 42,
    [PICKLIST_MANUFACTURER]: 52,
    [PICKLIST_MANURE_FORM]: 50,
    [PICKLIST_MANURE_TYPE]: 105,
    [PICKLIST_MORPHOLOGY_TYPE]: 30,
    [PICKLIST_NON_FIELD_FEATURE_TYPE]: 65,
    [PICKLIST_NORMALIZED_YIELD_CLASS]: 123,
    [PICKLIST_NUMERIC_RATING]: 8,
    [PICKLIST_OBSERVATION_PARENT_TYPE]: 31,
    [PICKLIST_OBSERVATION_TYPE]: 32,
    [PICKLIST_ORGANISM_TYPE]: 33,
    [PICKLIST_PAY_TYPE]: 67,
    [PICKLIST_PHONE_TYPE]: 69,
    [PICKLIST_PHYSICAL_STATE]: 53,
    [PICKLIST_CATEGORY]: 73,
    [PICKLIST_PLANT_PART]: 15,
    [PICKLIST_PLANTING_PATTERN]: 16,
    [PICKLIST_PRODUCT_ACTION_METHOD]: 54,
    [PICKLIST_PRODUCT_COST_TYPES]: 83,
    [PICKLIST_PRODUCT_LABEL_CHECKING]: 84,
    [PICKLIST_PRODUCT_PARENT_TYPE]: 55,
    [PICKLIST_PRODUCT_TYPE]: 56,
    [PICKLIST_REC_OPTION]: 121,
    [PICKLIST_RELATIVE_RELATIONSHIP_TYPE]: 70,
    [PICKLIST_ROW_ORIENTATION]: 108,
    [PICKLIST_ROW_SELECTION]: 116,
    [PICKLIST_SALES_PIPELINE_MILESTONES]: 85,
    [PICKLIST_SAMPLE_TYPE]: 19,
    [PICKLIST_SAMPLING_METHOD]: 11,
    [PICKLIST_SEASON_FILTER_TYPE]: 126,
    [PICKLIST_SOFTWARE_LICENSE]: 86,
    [PICKLIST_SOFTWARE_LICENSE_STATUS]: 87,
    [PICKLIST_SOIL_CONDITION]: 22,
    [PICKLIST_SOIL_TEXTURE]: 117,
    [PICKLIST_STORAGE_TYPE]: 58,
    [PICKLIST_SYSTEM_ATTRIBUTE]: 60,
    [PICKLIST_TARGET_PH]: 120,
    [PICKLIST_TERMS]: 46,
    [PICKLIST_THRESHOLD_TYPE]: 34,
    [PICKLIST_TILE_TYPE]: 24,
    [PICKLIST_TILLAGE_METHOD]: 25,
    [PICKLIST_TIRES_TYPE]: 43,
    [PICKLIST_TRAIT_CHARACTERISTICS]: 98,
    [PICKLIST_TRAIT_SPECIFICS]: 99,
    [PICKLIST_TRANSMISSION_TYPE]: 44,
    [PICKLIST_TRANSPLANT_TYPE]: 26,
    [PICKLIST_UNIT_SYSTEM]: 76,
    [PICKLIST_UNIT_TYPE]: 77,
    [PICKLIST_USER_TYPE]: 114,
    [PICKLIST_URGENCY_LEVEL]: 2,
    [PICKLIST_USAGE_UNIT]: 113,
    [PICKLIST_VARIETY_ATTRIBUTE]: 35,
    [PICKLIST_VENDOR_TYPE]: 72,
    [PICKLIST_WATER_SOURCE_TYPE]: 23,
    [PICKLIST_WELL_TYPE]: 45,
    [PICKLIST_WORK_ORDER_STATUS]: 93,
    [PICKLIST_WORK_ORDER_TYPE]: 94,
    [PICKLIST_IRRIGATION_CATEGORY]: 129,
    [PICKLIST_WATER_PURPOSE]: 127,
};

export const getPickListCode = (picklistName) => {
    return picklistNameToCodeMap[picklistName];
};
