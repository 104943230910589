import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DialogBox, DialogBoxFooterType, Loader, LoaderTypes, NoLink, SelectInput } from "~/core";
import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";
import { LayerAPI, LayerUtilsAPI } from "@ai360/core";

import { ACTIVE_YN } from "~/core/picklist";

import { messages } from "../../../i18n-messages";
import * as selectors from "../../selectors";
import {
    canReclassifyRecSurface,
    colorOptionRenderer,
    getBackgroundGradientStyle,
    isAllSingleValueClasses,
    LayerTypeShape,
} from "../../../../utils";

const DEFINED_LEGEND_LABEL = "defined legend";
const EQUAL_INTERVAL_LABEL = "equal interval";
const EQUAL_RECORDS_LABEL = "equal records";

class SurfaceProperties_ extends Component {
    _attributeGuidIsNotEditable = false;

    static propTypes = {
        classificationMethods: PropTypes.array.isRequired,
        colorRamps: PropTypes.array.isRequired,
        colorSchemeOptions: PropTypes.array.isRequired,
        customerGuid: PropTypes.string.isRequired,
        intl: intlShape.isRequired,
        isOpen: PropTypes.bool,
        layer: PropTypes.object.isRequired,
        letterIcon: PropTypes.string,
        numberOfClassesOptions: PropTypes.array.isRequired,
        onApiError: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        surface: PropTypes.object.isRequired,
        type: LayerTypeShape.isRequired,
        userGuid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            attributeGuid: props.layer.selectedAttributeGuid,
            classificationMethodGuid: props.surface.classificationMethodGuid,
            colorRampGuid: props.surface.colorRampGuid,
            colorSchemeGuid: props.surface.colorSchemeGuid,
            numberOfClasses: props.surface.numberOfClasses,
            isLoading: false,
            surfaceGuid: props.layer.selectedSurfaceGuid,
            surfaceTypeGuid: props.surface.surfaceTypeGuid,
        };
        const { layer, type } = this.props;
        this._attributeGuidIsNotEditable =
            type === LayerUtilsAPI.LayerType.REC ||
            type === LayerUtilsAPI.LayerType.EVENT_IMPORTED ||
            type === LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC ||
            layer.isSampling;
    }

    _onSave() {
        const {
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceGuid,
            surfaceTypeGuid,
        } = this.state;
        this.props.onSave({
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceGuid,
            surfaceTypeGuid,
        });
    }

    _reset() {
        const { surface, type } = this.props;
        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);
        const {
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceGuid,
            surfaceTypeGuid,
        } = surface;
        this.setState({
            attributeGuid: surface[attributeName],
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceGuid,
            surfaceTypeGuid,
        });
    }

    _resetToDefault() {
        const { layer, surface, type, userGuid, customerGuid } = this.props;
        if (type !== LayerUtilsAPI.LayerType.REC && !layer.isSampling) {
            this.setState({ isLoading: true }, () => {
                const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);
                let attributeGuid = surface[attributeName];

                const updateAttributeState = (attributeGuid) => {
                    const promises = [];
                    promises.push(
                        LayerAPI.getDefaultSymbology(
                            userGuid,
                            surface.agEventGeneralGuid,
                            attributeGuid,
                            surface.nutrientGuidFromEquationRec
                        )
                    );

                    promises.push(
                        LayerAPI.getDefaultSurfaceTypeGuid(
                            userGuid,
                            surface.agEventTransactionTypeGuid,
                            surface.systemAttributeGuid,
                            customerGuid,
                            layer.isFromEquationRec
                        )
                    );

                    Promise.all(promises)
                        .then((results) => {
                            let classificationMethodGuid = surface.classificationMethodGuid;
                            let colorRampGuid = surface.colorRampGuid;
                            let colorSchemeGuid = surface.colorSchemeGuid;
                            let numberOfClasses = surface.numberOfClasses;
                            let surfaceTypeGuid = surface.surfaceTypeGuid;
                            const isAllSingle = isAllSingleValueClasses(surface);
                            for (let result of results) {
                                if (result.colorRampGuid || result.colorSchemeGuid) {
                                    colorRampGuid = result.colorRampGuid;
                                    colorSchemeGuid = result.colorSchemeGuid;
                                    if (result.layerFiles && result.layerFiles.length > 0) {
                                        numberOfClasses = isAllSingle
                                            ? numberOfClasses
                                            : `${result.layerFiles[0].numberOfClasses}`;
                                        classificationMethodGuid =
                                            result.layerFiles[0].classificationMethodGuid;
                                    }
                                }
                                if (result.surfaceTypeGuid) {
                                    surfaceTypeGuid = result.surfaceTypeGuid;
                                }
                            }
                            this.setState({
                                attributeGuid,
                                classificationMethodGuid,
                                colorRampGuid,
                                colorSchemeGuid,
                                numberOfClasses,
                                surfaceGuid: surface.surfaceGuid,
                                surfaceTypeGuid,
                            });
                        })
                        .catch((err) => {
                            this.props.onApiError(err, messages.failedToResetToDefault);
                        })
                        .finally(() => this.setState({ isLoading: false }));
                };

                if (this._attributeGuidIsNotEditable) {
                    updateAttributeState(attributeGuid);
                } else {
                    LayerAPI.getDefaultAttributeGuid(userGuid, {
                        AgEventGeneralGuid: surface.agEventGeneralGuid,
                        AgEventTransactionTypeGuid: surface.agEventTransactionTypeGuid,
                        isManual: false,
                    })
                        .then((result) => {
                            if (result[attributeName]) {
                                attributeGuid = result[attributeName];
                            }
                            updateAttributeState(attributeGuid);
                        })
                        .catch((err) => {
                            this.props.onApiError(err, messages.failedToResetToDefault);
                            this.setState({ isLoading: false });
                        });
                }
            });
        } else {
            this._reset();
        }
    }

    _update(
        attributeGuid = this.state.attributeGuid,
        surfaceGuid = this.state.surfaceGuid,
        surfaceTypeGuid = this.state.surfaceTypeGuid
    ) {
        const { layer, surface, type } = this.props;
        let effectiveSurfaceTypeGuid = surfaceTypeGuid;
        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);
        const attributeHasSurfaceType = layer.subLayers.some(
            (slyr) =>
                slyr[attributeName] === attributeGuid && slyr.surfaceTypeGuid === surfaceTypeGuid
        );
        if (!attributeHasSurfaceType) {
            effectiveSurfaceTypeGuid = layer.subLayers.find(
                (slyr) => slyr[attributeName] === attributeGuid
            ).surfaceTypeGuid;
        }
        const { classificationMethodGuid, colorRampGuid, colorSchemeGuid, numberOfClasses } =
            LayerUtilsAPI.getOtherSurface(layer, surface, attributeGuid, effectiveSurfaceTypeGuid);
        this.setState({
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceGuid,
            surfaceTypeGuid: effectiveSurfaceTypeGuid,
        });
    }

    _updateAttribute(surfaceGuid) {
        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(this.props.type);
        const subLayer = this.props.layer.subLayers.find((s) => s.surfaceGuid === surfaceGuid);
        this._update(subLayer ? subLayer[attributeName] : this.state.attributeGuid, surfaceGuid);
    }

    _updateClassificationMethod(classificationMethodGuid) {
        this.setState({ classificationMethodGuid });
    }

    _updateColorRamp(colorRampGuid) {
        this.setState({ colorRampGuid });
    }

    _updateColorScheme(
        colorSchemeGuid,
        attributeGuid = this.state.attributeGuid,
        surfaceGuid = this.state.surfaceGuid
    ) {
        this.setState({ colorSchemeGuid, attributeGuid, surfaceGuid });
    }

    _updateDisplayType(surfaceTypeGuid) {
        this._update(this.state.attributeGuid, this.state.surfaceGuid, surfaceTypeGuid);
    }

    _updateNumClasses(numberOfClasses) {
        this.setState({ numberOfClasses });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen) {
            this._reset();
        }
    }

    render() {
        const {
            classificationMethods,
            colorRamps,
            colorSchemeOptions,
            isOpen,
            layer,
            letterIcon,
            numberOfClassesOptions,
            onClose,
            surface,
            type,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const {
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            isLoading,
            surfaceTypeGuid,
        } = this.state;

        if (!isOpen) {
            return null;
        }

        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);

        const uniqueAttributes = new Map();
        const uniqueDisplayTypes = new Map();
        const attributeToDisplayTypeMap = new Map();

        for (let slyr of layer.subLayers) {
            if (!uniqueAttributes.has(slyr[attributeName])) {
                uniqueAttributes.set(slyr[attributeName], {
                    label: slyr.displayName,
                    value: slyr[attributeName],
                });
            }
            if (!uniqueDisplayTypes.has(slyr.surfaceTypeGuid)) {
                uniqueDisplayTypes.set(slyr.surfaceTypeGuid, {
                    label: slyr.surfaceTypeDisplayName,
                    value: slyr.surfaceTypeGuid,
                });
            }
            attributeToDisplayTypeMap.set(
                slyr[attributeName],
                (attributeToDisplayTypeMap.get(slyr[attributeName]) || new Set()).add(
                    slyr.surfaceTypeGuid
                )
            );
        }

        const attributeOptions = Array.from(uniqueAttributes.values()).sort((a, b) =>
            a.label.localeCompare(b.label)
        );
        const displayTypeOptions = Array.from(uniqueDisplayTypes.values())
            .filter(
                (opt) =>
                    attributeToDisplayTypeMap.has(attributeGuid) &&
                    attributeToDisplayTypeMap.get(attributeGuid).has(opt.value)
            )
            .sort((a, b) => a.label.localeCompare(b.label));

        const colorSchemeSelectOptions = colorSchemeOptions.map((cs) => ({
            background: getBackgroundGradientStyle(cs, true),
            label: " ",
            value: cs.colorSchemeGuid,
        }));
        const colorRampSelectOptions = colorRamps.map((cr) => ({
            background: getBackgroundGradientStyle(cr.layerFiles.slice(-1)[0]),
            label: " ",
            value: cr.colorRampGuid,
        }));
        const numClassesOptions = numberOfClassesOptions.map((noc) => ({
            label: noc,
            value: noc,
        }));
        const classificationMethodOptions = classificationMethods.map((cm) => ({
            label: cm.classificationMethod,
            value: cm.classificationMethodGuid,
        }));

        const colorRamp = this.props.colorRamps.find((cs) => cs.colorRampGuid === colorRampGuid);
        let layerFile =
            colorRamp &&
            colorRamp.layerFiles.find((f) => f.numberOfClasses === Number(numberOfClasses));
        if (layerFile == null) {
            layerFile = { classBreaks: [] };
        }
        const legendPreviewItems = layerFile.classBreaks.map((cb) => (
            <div key={cb.classId} className="legend-preview-item">
                <div className="legend-icon" style={{ backgroundColor: `#${cb.color.hexCode}` }} />
                <div className="legend-label">{cb.classId}</div>
            </div>
        ));

        const selectedClassificationMethod = classificationMethodOptions.find(
            (cm) => cm.value === classificationMethodGuid
        );
        const isDefinedLegend = Boolean(
            classificationMethodGuid &&
                selectedClassificationMethod &&
                selectedClassificationMethod.label.toLowerCase() === DEFINED_LEGEND_LABEL
        );
        const otherSurface = this._attributeGuidIsNotEditable
            ? surface
            : LayerUtilsAPI.getOtherSurface(layer, surface, attributeGuid, surfaceTypeGuid);
        const isAllSingle = isAllSingleValueClasses(otherSurface);
        const canReclassifyRec = canReclassifyRecSurface(otherSurface);
        const disableClassControls = (isAllSingle == null || isAllSingle) && !canReclassifyRec;
        const isUsingColorScheme =
            otherSurface == null ||
            otherSurface.layerFileGuid == null ||
            otherSurface.layerFileGuid.length === 0;
        let allowDefinedLegend = false;
        let allowNoClassesDefinedLegend = false;

        const colorScheme = colorSchemeOptions.find((cs) => cs.colorSchemeGuid === colorSchemeGuid);
        const backgroundImageStyle = !isUsingColorScheme
            ? getBackgroundGradientStyle(colorRamp.layerFiles.slice(-1)[0])
            : getBackgroundGradientStyle(colorScheme, true);

        if (layer.isSampling) {
            const dlLayer = layer.subLayers.find(
                (slyr) =>
                    slyr[attributeName] === attributeGuid &&
                    slyr.surfaceTypeGuid === surfaceTypeGuid
            );
            if (dlLayer !== null && dlLayer.allowDefinedLegend) {
                allowDefinedLegend = true;
            }
            if (disableClassControls === true && dlLayer.allowDefinedLegend) {
                allowNoClassesDefinedLegend = true;
            }
        }
        const hideClassificationMethod = isAllSingle && !allowDefinedLegend && !canReclassifyRec;

        return (
            <DialogBox
                className="surface-properties-dialog"
                draggable
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                letterIcon={letterIcon}
                onAction={() => this._onSave()}
                onClose={() => onClose()}
                title={`${layer.displayName} - ${formatMessage(messages.layerProperties)}`}
                unrestricted
            >
                {this._attributeGuidIsNotEditable ? (
                    <div className="attribute-label">{surface.displayName}</div>
                ) : (
                    <SelectInput
                        containerClassNames={["attribute-select"]}
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        onChange={(e) => {
                            const { surfaceTypeGuid } = this.state;
                            let correctSurface = layer.subLayers.find(
                                (slyr) =>
                                    slyr[attributeName] === e &&
                                    slyr.surfaceTypeGuid === surfaceTypeGuid
                            );
                            if (!correctSurface) {
                                correctSurface = layer.subLayers.find(
                                    (slyr) => slyr[attributeName] === e
                                );
                            }

                            if (correctSurface) {
                                this._updateAttribute(correctSurface.surfaceGuid);
                            }
                        }}
                        options={attributeOptions}
                        placeholderText={formatMessage(messages.attribute)}
                        value={attributeGuid}
                    />
                )}
                {layer.isSampling || layer.isManagementArea || layer.isFromEquationRec ? null : (
                    <SelectInput
                        containerClassNames={["display-type-select"]}
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        onChange={(e) => this._updateDisplayType(e)}
                        options={displayTypeOptions}
                        placeholderText={formatMessage(messages.displayType)}
                        value={surfaceTypeGuid}
                    />
                )}
                {isUsingColorScheme ? (
                    <SelectInput
                        inputCSS={{ backgroundImage: backgroundImageStyle }}
                        containerClassNames={["color-scheme-select"]}
                        clearable={false}
                        filterable={false}
                        onChange={(e) => this._updateColorScheme(e)}
                        optionIsHiddenKey={ACTIVE_YN}
                        options={colorSchemeSelectOptions}
                        optionRenderer={colorOptionRenderer}
                        placeholderText={formatMessage(messages.colorScheme)}
                        value={colorSchemeGuid}
                    />
                ) : (
                    <div className="color-ramp-section">
                        <div className="inputs-section">
                            <SelectInput
                                inputCSS={{ backgroundImage: backgroundImageStyle }}
                                clearable={false}
                                filterable={false}
                                onChange={(e) => this._updateColorRamp(e)}
                                optionIsHiddenKey={ACTIVE_YN}
                                options={colorRampSelectOptions}
                                optionRenderer={colorOptionRenderer}
                                placeholderText={formatMessage(messages.colorRamp)}
                                value={colorRampGuid}
                            />
                            {isDefinedLegend ? null : (
                                <SelectInput
                                    clearable={false}
                                    filterable={false}
                                    onChange={(e) => this._updateNumClasses(e)}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    options={numClassesOptions}
                                    placeholderText={formatMessage(messages.numberOfClasses)}
                                    value={numberOfClasses}
                                    disabled={disableClassControls}
                                />
                            )}
                            {hideClassificationMethod ? null : (
                                <SelectInput
                                    clearable={false}
                                    filterable={false}
                                    onChange={(e) => this._updateClassificationMethod(e)}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    options={
                                        allowNoClassesDefinedLegend
                                            ? classificationMethodOptions.filter(
                                                  (cm) =>
                                                      cm.label.toLowerCase() !==
                                                          EQUAL_RECORDS_LABEL &&
                                                      cm.label.toLowerCase() !==
                                                          EQUAL_INTERVAL_LABEL
                                              )
                                            : allowDefinedLegend
                                            ? classificationMethodOptions
                                            : classificationMethodOptions.filter(
                                                  (cm) =>
                                                      cm.label.toLowerCase() !==
                                                      DEFINED_LEGEND_LABEL
                                              )
                                    }
                                    placeholderText={formatMessage(messages.classificationMethods)}
                                    value={classificationMethodGuid}
                                />
                            )}
                        </div>
                        <div className="legend-preview">
                            <div>{legendPreviewItems}</div>
                        </div>
                    </div>
                )}
                {type === LayerUtilsAPI.LayerType.REC ||
                layer.isSampling ||
                hideClassificationMethod ? null : (
                    <div className="surfaces-reset-link">
                        <NoLink
                            label={formatMessage(messages.resetToDefault)}
                            onClick={() => this._resetToDefault()}
                        />
                    </div>
                )}
                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

const mapStateToProps = (state) => ({
    classificationMethods: selectors.getClassificationMethods(state),
    colorRamps: selectors.getColorRamps(state),
    colorSchemeOptions: selectors.getColorSchemeOptions(state),
    numberOfClassesOptions: selectors.getNumberOfClasses(state),
    userGuid: getTheUserGuid(state),
});

export const SurfaceProperties = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SurfaceProperties_));
