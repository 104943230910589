import React from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { ReportAPI } from "@ai360/core";

import { Checkbox, Section, SubSection } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import { getReportDataByReportType } from "~/reports/data/selectors";
import { useAppSelector } from "~/store/hooks";
import { ReportOptionsComponent, ReportOptionsComponentProps } from ".";

function FieldReferenceList({
    intl: { formatMessage },
    onInputChange,
}: ReportOptionsComponentProps) {
    const reportData = useAppSelector(getReportDataByReportType(FieldReferenceList.reportName));

    return (
        <Section className="report-options-content">
            <SubSection className="two-wide force-wrap">
                <CroppingSeason reportType={FieldReferenceList.reportName} />
            </SubSection>
            <SubSection className="two-report-options">
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.farmTotals)}
                    onChange={(e, value) => onInputChange(model.PROPS_SHOW_FARM_TOTALS, value)}
                    value={reportData[model.PROPS_SHOW_FARM_TOTALS]}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.growerTotals)}
                    onChange={(e, value) => onInputChange(model.PROPS_SHOW_GROWER_TOTALS, value)}
                    value={reportData[model.PROPS_SHOW_GROWER_TOTALS]}
                />
            </SubSection>
            <SubSection className="two-report-options">
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.showQrCode)}
                    onChange={(e, value) => onInputChange(model.PROPS_SHOW_QR_CODES, value)}
                    value={reportData[model.PROPS_SHOW_QR_CODES]}
                />
            </SubSection>
        </Section>
    );
}

FieldReferenceList.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
};

FieldReferenceList.defaultReportOptions = {
    [model.PROPS_SHOW_FARM_TOTALS]: true,
    [model.PROPS_SHOW_GROWER_TOTALS]: true,
    [model.PROPS_SHOW_QR_CODES]: false,
};

FieldReferenceList.reportPreferences = [
    model.PROPS_SHOW_FARM_TOTALS,
    model.PROPS_SHOW_GROWER_TOTALS,
    model.PROPS_SHOW_QR_CODES,
];

FieldReferenceList.reportName = ReportAPI.ReportNames.AGVANCE_SKY_FIELD_REFERENCE_LIST;

export default injectIntl(
    FieldReferenceList
) as ReportOptionsComponent<ReportOptionsComponentProps>;
