import React, { Component, CSSProperties } from "react";
import { connect } from "react-redux";

import { apiUrl, FileImportAPI, PicklistAPI, PreferenceAPI, RecAPI } from "@ai360/core";

import { AttributeEditModal } from "./attribute-edit-modal";
import { ImportFilter } from "../import-filter";
import { injectIntl, intlShape } from "react-intl";
import { SelectInput, NumericInput, Loader, NoLink, Menu, TextInput } from "~/core";
import * as selectors from "../../../../../selectors";
import * as actions from "../../../../../actions";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { PICKLIST_CROP_PURPOSE, getPickListCode } from "~/core/picklist/picklist-names";
import systemAttributes from "~/static/system-attributes.json";
import eventTypes from "~/static/event-types.json";
import { ACTIVE_YN, selectors as picklistSelectors } from "~/core/picklist";
import { adminData } from "~/admin/data";
import { ProductBlendModal } from "~/action-panel/components/common/product-blend-modal";
import * as blendingModels from "~/action-panel/components/common/product-blend-modal/model";
import { messages } from "./i18n-messages";
import "./crop-filter.css";
import * as blendingSelectors from "~/action-panel/components/common/product-blend-modal/selectors";
import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";
import { logFirebaseEvent } from "~/utils/firebase";
import { ISelectOption } from "~/core/components/select-input/model";

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
};

const DROPDOWN_CROP = "DROPDOWN_CROP";
const DROPDOWN_BRANDORGANIZATION = "DROPDOWN_BRANDORGANIZATION";
const DROPDOWN_VARIETYHYBRID = "DROPDOWN_VARIETYHYBRID";
const DROPDOWN_YIELDUNIT_DRY = "DROPDOWN_YIELDUNIT_DRY";
const DROPDOWN_YIELDUNIT_LINT = "DROPDOWN_YIELDUNIT_LINT";
const BALE_ACRE = 210;
const BUSHEL_ACRE = 160;
const SACK_ACRE = 212;
const BAG_ACRE = 211;

export const dropdowns: CropFilterDropdowns = {
    [DROPDOWN_CROP]: apiUrl("AgBytes/GetCropDropdownList"),
    [DROPDOWN_BRANDORGANIZATION]: {
        url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
        model: "",
    },
    [DROPDOWN_VARIETYHYBRID]: {
        url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
        model: { cropId: "", brandOrganization: "" },
    },
    [DROPDOWN_YIELDUNIT_DRY]: {
        url: apiUrl("Picklist/GetUnitPicklistBySystemAttributeAndEventType"),
        model: {
            systemAttribute: systemAttributes.dryYield,
            eventType: eventTypes.harvest,
        },
    },
    [DROPDOWN_YIELDUNIT_LINT]: {
        url: apiUrl("Picklist/GetUnitPicklistBySystemAttributeAndEventType"),
        model: {
            systemAttribute: systemAttributes.cottonLintYield,
            eventType: eventTypes.harvest,
        },
    },
};

interface ICropFilterProps {
    availableProducts?: blendingModels.ProductMixProduct[];
    conversionFactors?: RecAPI.IRecConversionFactor[];
    DROPDOWN_BRANDORGANIZATION: CropFilterDropdownItem[];
    DROPDOWN_CROP: CropFilterDropdownItem[];
    DROPDOWN_VARIETYHYBRID: CropFilterDropdownItem[];
    productBlendPicklists?: blendingModels.ProductBlendPicklists;
    fetchUserCropImportPreferences: (cropGuid: string, yieldIaGuid: string) => void;
    filterItems?: FileImportAPI.CropFilterItem[];
    importFileGuidList?: string[];
    importType?: string;
    intl: intlShape;
    isHarvest: boolean;
    isLoading: boolean;
    isPlanting: boolean;
    picklistData: CropFilterPicklistData;
    refreshDropdowns: (refreshDropdowns: Partial<DropdownRequest> | string) => void;
    removeImportFiles?: (importFileGuidList: string[]) => void;
    assignMatchedToUnmatched?: (
        index: number,
        assignToCrops: (data: any, index: number) => void
    ) => void;
    seedingRateUnits: Record<string, any>[];
    selectItem: (item: FileImportAPI.CropFilterItem) => void;
    selectedFieldGuid?: string;
    setIsLoading: (value: boolean) => void;
    setUserCropImportPreferences: () => void;
    updateAttribute: (item: FileImportAPI.CropFilterItem) => void;
    updateAlias: (item: FileImportAPI.CropFilterItem) => void;
    updateFilterStatus: (isComplete: boolean) => void;
    updateUserCropImportPreferences: (
        cropGuid: string,
        yieldIaGuid: string,
        averageTestWeight: string,
        averageUnitWeight: string
    ) => void;
    userCropImportPreferences: PreferenceAPI.IUserCropImportPreference;
}

interface ICropFilterState {
    activeProductBlend: number;
    averageTestWeight: string;
    averageUnitWeight: string;
    brandOrganizationGuid: string;
    brandOrganizationName: string;
    cropClassNameGuid: string;
    cropGuid: string;
    cropName: string;
    cropId: number;
    cropPurposeGuid: string;
    cropPurposeName: string;
    addAliasItem: FileImportAPI.CropFilterItem;
    editItem: FileImportAPI.CropFilterItem;
    isEditingAlias: boolean;
    isRefreshingLists: boolean;
    isFetchingPreferences: boolean;
    isSaving: boolean;
    isProductBlendingActive: boolean;
    initialProductMix: blendingModels.ProductMix[];
    lintPercent: number | string;
    options: ImportCropsFilterOption;
    originalProductMixList: blendingModels.ProductMix[];
    seedingRate: string;
    seedingRateUnitGuid: string;
    showSeedingRateUnitOptions: boolean;
    aliasVarietyHybridName: string;
    varietyHybridGuid: string;
    varietyHybridName: string;
    yieldIaGuid: string;
    yieldUnitName: string;
    yieldUnitId: number;
}

interface ImportCropsFilterOption {
    brand: ISelectOption<any>[];
    crop: ISelectOption<any>[];
    cropPurpose: ISelectOption<any>[];
    variety: ISelectOption<any>[];
    yieldUnitDry: ISelectOption<any>[];
    yieldUnitLint: ISelectOption<any>[];
}

interface CropFilterDropdowns {
    DROPDOWN_BRANDORGANIZATION: CropFilterDropdownItem[] | DropdownRequest;
    DROPDOWN_CROP: CropFilterDropdownItem[] | string;
    DROPDOWN_VARIETYHYBRID: CropFilterDropdownItem[] | DropdownRequest;
    DROPDOWN_YIELDUNIT_DRY: CropFilterDropdownItem[] | DropdownRequest;
    DROPDOWN_YIELDUNIT_LINT: CropFilterDropdownItem[] | DropdownRequest;
}

interface CropFilterDropdownItem {
    activeYn: boolean;
    cropGuid?: string;
    fullName?: string;
    guid: string;
    id: string;
    name: string;
    parentGuid: string;
    physicalStateId: string;
}

interface CropFilterPicklistData {
    cropPurpose: PicklistAPI.IPicklistItem[];
}

interface DropdownRequest {
    url: string;
    model: any;
}

class ImportCropFilter_ extends Component<ICropFilterProps, ICropFilterState> {
    static defaultProps = {
        importFileGuidList: [],
        selectedFieldGuid: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            isRefreshingLists: false,
            isFetchingPreferences: false,
            addAliasItem: null,
            editItem: null,
            cropGuid: null,
            cropName: null,
            cropId: -1,
            cropPurposeGuid: null,
            cropPurposeName: null,
            brandOrganizationGuid: null,
            brandOrganizationName: null,
            aliasVarietyHybridName: null,
            varietyHybridGuid: null,
            varietyHybridName: null,
            yieldIaGuid: null,
            yieldUnitName: null,
            yieldUnitId: -1,
            averageTestWeight: "",
            averageUnitWeight: "",
            lintPercent: "",
            options: {
                crop: ImportCropFilter_.getOptionsFromList(
                    props[DROPDOWN_CROP],
                    "cropGuid",
                    "cropName"
                ),
                cropPurpose: ImportCropFilter_.getOptionsFromList(
                    props.picklistData[PICKLIST_CROP_PURPOSE],
                    "picklistValueGuid",
                    "value"
                ),
                brand: ImportCropFilter_.getOptionsFromList(
                    props[DROPDOWN_BRANDORGANIZATION],
                    "guid",
                    "name"
                ),
                variety: ImportCropFilter_.getOptionsFromList(
                    props[DROPDOWN_VARIETYHYBRID],
                    "guid",
                    "name",
                    "parentGuid"
                ),
                yieldUnitDry: ImportCropFilter_.getOptionsFromList(
                    props[DROPDOWN_YIELDUNIT_DRY],
                    "importAttributeGuid",
                    "abbreviation"
                ),
                yieldUnitLint: ImportCropFilter_.getOptionsFromList(
                    props[DROPDOWN_YIELDUNIT_LINT],
                    "importAttributeGuid",
                    "abbreviation"
                ),
            },
            isEditingAlias: false,
            isSaving: false,
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalProductMixList: [],
            initialProductMix: [],
            cropClassNameGuid: null,
            seedingRate: null,
            seedingRateUnitGuid: null,
            showSeedingRateUnitOptions: false,
        };
    }

    onUpdateProductBlending(productMix: blendingModels.ProductMix): void {
        const productMixList = [...this.state.originalProductMixList];
        productMixList.splice(this.state.activeProductBlend, 1, productMix);
        this.setState({ originalProductMixList: productMixList });
    }

    onSaveProductBlending(): void {
        const productMixList = [...this.state.originalProductMixList];
        this.setState({ initialProductMix: productMixList }, () => {
            this.onCloseProductBlending();
        });
    }

    onCancelProductBlending(): void {
        this.onCloseProductBlending();
    }

    onCloseProductBlending(): void {
        const initialMix = this.state.initialProductMix ? [...this.state.initialProductMix] : [];
        this.setState({
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalProductMixList: [...initialMix],
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newOptions: Partial<ImportCropsFilterOption> = {};
        const replacementState: Partial<ICropFilterState> = {};

        if (nextProps[DROPDOWN_CROP] !== this.props[DROPDOWN_CROP]) {
            newOptions.crop = ImportCropFilter_.getOptionsFromList(
                nextProps[DROPDOWN_CROP],
                "cropGuid",
                "cropName"
            );
            replacementState.isRefreshingLists = false;
        }
        if (nextProps[DROPDOWN_BRANDORGANIZATION] !== this.props[DROPDOWN_BRANDORGANIZATION]) {
            newOptions.brand = ImportCropFilter_.getOptionsFromList(
                nextProps[DROPDOWN_BRANDORGANIZATION],
                "guid",
                "name"
            );
            replacementState.isRefreshingLists = false;
        }
        if (nextProps[DROPDOWN_VARIETYHYBRID] !== this.props[DROPDOWN_VARIETYHYBRID]) {
            newOptions.variety = ImportCropFilter_.getOptionsFromList(
                nextProps[DROPDOWN_VARIETYHYBRID],
                "guid",
                "name",
                "parentGuid"
            );
            replacementState.isRefreshingLists = false;
        }
        if (
            nextProps.picklistData[PICKLIST_CROP_PURPOSE] !==
            this.props.picklistData[PICKLIST_CROP_PURPOSE]
        ) {
            newOptions.cropPurpose = ImportCropFilter_.getOptionsFromList(
                nextProps.picklistData[PICKLIST_CROP_PURPOSE],
                "picklistValueGuid",
                "value"
            );
            replacementState.isRefreshingLists = false;
        }
        if (nextProps[DROPDOWN_YIELDUNIT_DRY] !== this.props[DROPDOWN_YIELDUNIT_DRY]) {
            newOptions.yieldUnitDry = ImportCropFilter_.getOptionsFromList(
                nextProps[DROPDOWN_YIELDUNIT_DRY],
                "importAttributeGuid",
                "abbreviation"
            );
            replacementState.isRefreshingLists = false;
        }
        if (nextProps[DROPDOWN_YIELDUNIT_LINT] !== this.props[DROPDOWN_YIELDUNIT_LINT]) {
            newOptions.yieldUnitLint = ImportCropFilter_.getOptionsFromList(
                nextProps[DROPDOWN_YIELDUNIT_LINT],
                "importAttributeGuid",
                "abbreviation"
            );
            replacementState.isRefreshingLists = false;
        }

        if (nextProps.userCropImportPreferences !== this.props.userCropImportPreferences) {
            replacementState.isFetchingPreferences = false;
            const { yieldIaGuid, averageTestWeight, averageUnitWeight } =
                nextProps.userCropImportPreferences;
            replacementState.yieldUnitId = this.getYieldUnitId(this.state.cropId, yieldIaGuid);
            replacementState.yieldIaGuid = yieldIaGuid;
            if (ImportCropFilter_.isBushelPerAcre(replacementState.yieldUnitId)) {
                // if we are using bushels, set the average test weight pref
                replacementState.averageTestWeight = averageTestWeight;
            } else if (ImportCropFilter_.isVolumetricFlowRate(replacementState.yieldUnitId)) {
                // else we set average unit weight pref for other unit types
                replacementState.averageUnitWeight = averageUnitWeight;
            }
        }
        this.applyToState(nextProps, replacementState, newOptions);
    }

    componentDidMount() {
        this.refreshCropDropdownLists(true);
    }

    applyToState = (
        nextProps: Partial<ICropFilterProps>,
        replacementState: Partial<ICropFilterState>,
        newOptions: Partial<ImportCropsFilterOption>
    ): void => {
        this.setState(
            {
                averageTestWeight: replacementState.averageTestWeight
                    ? replacementState.averageTestWeight
                    : this.state.averageTestWeight,
                averageUnitWeight: replacementState.averageUnitWeight
                    ? replacementState.averageUnitWeight
                    : this.state.averageUnitWeight,
                isRefreshingLists:
                    replacementState.isRefreshingLists !== undefined
                        ? replacementState.isRefreshingLists
                        : this.state.isRefreshingLists,
                isFetchingPreferences:
                    replacementState.isFetchingPreferences !== undefined
                        ? replacementState.isFetchingPreferences
                        : this.state.isFetchingPreferences,
                yieldUnitId: replacementState.yieldUnitId
                    ? replacementState.yieldUnitId
                    : this.state.yieldUnitId,
                yieldIaGuid: replacementState.yieldIaGuid
                    ? replacementState.yieldIaGuid
                    : this.state.yieldIaGuid,
                options: {
                    ...this.state.options,
                    ...newOptions,
                },
            },
            () => {
                if (
                    nextProps.isLoading &&
                    !(this.state.isRefreshingLists || this.state.isFetchingPreferences)
                ) {
                    this.props.setIsLoading(false);
                }
            }
        );
    };

    getLabel = ({ options = [], guid = "" }, valueKey = "value", labelKey = "label") => {
        if (options.length) {
            const selectedOption = options.find((option) => guid === option[valueKey]);
            return selectedOption ? selectedOption[labelKey] : "";
        }
        return "";
    };

    static getOptionsFromList(listData, keyProp: string, nameProp: string, parentKeyProp?: string) {
        if (!listData) {
            return [];
        }
        return listData.map((item) => {
            return {
                value: item[keyProp],
                label: item[nameProp],
                activeYn:
                    item[adminData.PROPS_ACTIVE_YN] != null
                        ? item[adminData.PROPS_ACTIVE_YN]
                        : true,
                parentValue: parentKeyProp ? item[parentKeyProp] : null,
            };
        });
    }

    #getSeedingRateUnitAbb(): string {
        const { seedingRateUnitGuid } = this.state;
        const targetOption = this.props.seedingRateUnits.find(
            (x) =>
                x.importAttributeGuid === seedingRateUnitGuid || x.unitGuid === seedingRateUnitGuid
        );
        return targetOption?.abbreviation;
    }

    static isVolumetricFlowRate(yieldUnitId: number): boolean {
        const volumetricFlowRateIds = [BALE_ACRE, SACK_ACRE, BAG_ACRE];
        const unitIndex = volumetricFlowRateIds.indexOf(yieldUnitId);

        return unitIndex !== -1;
    }
    static isBushelPerAcre(yieldUnitId: number): boolean {
        return yieldUnitId === BUSHEL_ACRE;
    }
    static isCottonCrop(cropId: number): boolean {
        return cropId === 7;
    }
    filterProperties = !this.props.isPlanting
        ? [
              {
                  isRequired: true,
                  label: this.props.intl.formatMessage(messages.cropText),
                  propertyName: "cropName",
                  propertyGuid: "cropGuid",
                  cellSize: this.props.isHarvest ? 25 : 20,
              },
              {
                  isRequired: false,
                  label: this.props.intl.formatMessage(messages.brandVarietyText),
                  propertyGuid: ["brandOrganizationGuid", "varietyHybridGuid"],
                  propertyName: ["brandOrganizationName", "varietyHybridName"],
                  cellSize: this.props.isHarvest ? 30 : 25,
              },
              {
                  isRequired: true,
                  label: this.props.intl.formatMessage(messages.yieldUnitText),
                  propertyName: "yieldUnitName",
                  propertyGuid: "yieldIaGuid",
                  cellSize: 15,
              },
              {
                  isRequired: false,
                  label: this.props.intl.formatMessage(messages.featuresText),
                  propertyName: "pointCount",
                  format: "numeric",
                  cellSize: 20,
              },
          ]
        : [
              {
                  isRequired: true,
                  label: this.props.intl.formatMessage(messages.cropText),
                  propertyName: "cropName",
                  propertyGuid: "cropGuid",
                  cellSize: 15,
              },
              {
                  isRequired: true,
                  label: this.props.intl.formatMessage(messages.brandVarietyText),
                  propertyGuid: ["brandOrganizationGuid", "varietyHybridGuid"],
                  propertyName: ["brandOrganizationName", "varietyHybridName"],
                  cellSize: 40,
              },
              {
                  isRequired: false,
                  label: this.props.intl.formatMessage(messages.seedingRateText),
                  propertyName: "seedingRate",
                  format: "numeric",
                  cellSize: 20,
              },
              {
                  isRequired: false,
                  label: this.props.intl.formatMessage(messages.featuresText),
                  propertyName: "pointCount",
                  format: "numeric",
                  cellSize: 15,
              },
          ];

    onEditAttribute = (item): void => {
        const cropId = this.getCropId(item.cropGuid);
        const yieldUnitId = this.getYieldUnitId(cropId, item.yieldIaGuid);

        this.setState(
            {
                editItem: item,
                cropGuid: item.cropGuid,
                cropId: cropId,
                cropPurposeGuid: item.cropPurposeGuid,
                brandOrganizationGuid: item.brandOrganizationGuid,
                aliasVarietyHybridName: item.aliasVarietyHybridName,
                varietyHybridGuid: item.varietyHybridGuid,
                yieldIaGuid: item.yieldIaGuid,
                yieldUnitId: yieldUnitId,
                averageTestWeight: item.averageTestWeight,
                averageUnitWeight: item.averageUnitWeight,
                lintPercent: item.lintPercent,
                originalProductMixList: item.productMixList,
                initialProductMix: item.productMixList ? item.productMixList : [],
                seedingRate: item.seedingRate,
                seedingRateUnitGuid: item.seedingRateUnitGuid,
                showSeedingRateUnitOptions: this.#showSeedingRateUnitOptions(item),
            },
            () => {
                this.refreshCropDropdownLists();
            }
        );
    };

    onSaveEdit = (): void => {
        const cropId = this.getCropId(this.state.cropGuid);

        const cropName = this.getLabel({
            options: this.state.options.crop,
            guid: this.state.cropGuid,
        });

        const cropPurposeName = this.getLabel({
            options: this.state.options.cropPurpose,
            guid: this.state.cropPurposeGuid,
        });

        const brandAndVariety = this.state.brandOrganizationGuid && this.state.varietyHybridGuid;
        const brandOrganizationName = brandAndVariety
            ? this.getLabel({
                  options: this.state.options.brand,
                  guid: this.state.brandOrganizationGuid,
              })
            : "";
        const varietyHybridName = brandAndVariety
            ? this.getLabel({
                  options: this.state.options.variety,
                  guid: this.state.varietyHybridGuid,
              })
            : "";

        const yieldOptions = ImportCropFilter_.isCottonCrop(cropId)
            ? this.state.options.yieldUnitLint
            : this.state.options.yieldUnitDry;
        const yieldUnitName = this.getLabel({
            options: yieldOptions,
            guid: this.state.yieldIaGuid,
        });

        const { cropGuid, yieldIaGuid, averageTestWeight, averageUnitWeight, initialProductMix } =
            this.state;

        const item: FileImportAPI.CropFilterItem = {
            ...this.state.editItem,
            cropGuid: cropGuid,
            cropName: cropName,
            cropPurposeGuid: this.state.cropPurposeGuid,
            cropPurposeName: cropPurposeName,
            brandOrganizationGuid: this.state.brandOrganizationGuid,
            brandOrganizationName: brandOrganizationName,
            aliasVarietyHybridName: this.state.aliasVarietyHybridName,
            varietyHybridGuid: this.state.varietyHybridGuid,
            varietyHybridName: varietyHybridName,
            yieldIaGuid: yieldIaGuid,
            yieldUnitName: yieldUnitName,
            averageTestWeight: averageTestWeight,
            averageUnitWeight: averageUnitWeight,
            lintPercent: this.state.lintPercent,
            hasLookupMatch: true,
            productMixList: initialProductMix,
            seedingRate: this.state.seedingRate,
            seedingRateUnitAbbreviation: this.state.seedingRateUnitGuid
                ? this.#getSeedingRateUnitAbb()
                : null,
            seedingRateUnitGuid: this.state.seedingRateUnitGuid,
        };

        if (!this.props.isPlanting) {
            this.props.updateUserCropImportPreferences(
                cropGuid,
                yieldIaGuid,
                averageTestWeight,
                averageUnitWeight
            );
        }

        this.props.updateAttribute(item);
        this.onCloseEdit();
    };

    onCloseEdit = (): void => {
        this.setState({
            editItem: null,
            cropGuid: null,
            cropPurposeGuid: null,
            cropPurposeName: null,
            brandOrganizationGuid: null,
            aliasVarietyHybridName: "",
            varietyHybridGuid: null,
            yieldIaGuid: null,
            yieldUnitId: -1,
            averageTestWeight: "",
            averageUnitWeight: "",
            lintPercent: "",
            isSaving: false,
            seedingRate: null,
            seedingRateUnitGuid: null,
        });
    };

    onAddAlias = (item, index): void => {
        item.index = index;
        const cropId = this.getCropId(item.cropGuid);
        const yieldUnitId = this.getYieldUnitId(cropId, item.yieldIaGuid);

        this.setState(
            {
                addAliasItem: item,
                cropGuid: item.cropGuid,
                cropName: item.cropName,
                cropId: cropId,
                cropPurposeGuid: item.cropPurposeGuid,
                brandOrganizationGuid: item.brandOrganizationGuid,
                brandOrganizationName: item.brandOrganizationName,
                aliasVarietyHybridName: item.aliasVarietyHybridName,
                varietyHybridGuid: item.varietyHybridGuid,
                varietyHybridName: item.varietyHybridName,
                yieldIaGuid: item.yieldIaGuid,
                yieldUnitId: yieldUnitId,
                yieldUnitName: item.yieldUnitName,
                averageTestWeight: item.averageTestWeight,
                averageUnitWeight: item.averageUnitWeight,
                lintPercent: item.lintPercent,
                originalProductMixList: item.productMixList,
                initialProductMix: item.productMixList ? item.productMixList : [],
                seedingRate: item.seedingRate,
                seedingRateUnitGuid: item.seedingRateUnitGuid,
                showSeedingRateUnitOptions: this.#showSeedingRateUnitOptions(item),
            },
            () => {
                this.refreshCropDropdownLists();
            }
        );
    };

    onSaveAlias = (): void => {
        const item: FileImportAPI.CropFilterItem = {
            ...this.state.addAliasItem,
            isAliasEdit: true,
            cropGuid: this.state.cropGuid,
            cropName: this.state.cropName,
            cropPurposeGuid: this.state.cropPurposeGuid,
            cropPurposeName: this.state.cropPurposeName,
            brandOrganizationGuid: this.state.brandOrganizationGuid,
            brandOrganizationName: this.state.brandOrganizationName,
            aliasVarietyHybridName: this.state.aliasVarietyHybridName,
            varietyHybridGuid: this.state.varietyHybridGuid,
            varietyHybridName: this.state.varietyHybridName,
            yieldIaGuid: this.state.yieldIaGuid,
            yieldUnitName: this.state.yieldUnitName,
            averageTestWeight: this.state.averageTestWeight,
            averageUnitWeight: this.state.averageUnitWeight,
            lintPercent: this.state.lintPercent,
            hasLookupMatch: true,
            productMixList: this.state.initialProductMix,
            seedingRate: this.state.seedingRate,
            seedingRateUnitAbbreviation: this.state.seedingRateUnitGuid
                ? this.#getSeedingRateUnitAbb()
                : null,
            seedingRateUnitGuid: this.state.seedingRateUnitGuid,
        };

        this.props.updateAlias(item);
        this.onCloseAlias();
    };

    onCloseAlias = (): void => {
        const { aliasVarietyHybridName } = this.state;
        this.setState({
            isEditingAlias: Boolean(aliasVarietyHybridName),
            addAliasItem: null,
            cropGuid: null,
            cropPurposeGuid: null,
            cropPurposeName: null,
            brandOrganizationGuid: null,
            aliasVarietyHybridName: "",
            varietyHybridGuid: null,
            yieldIaGuid: null,
            yieldUnitId: -1,
            averageTestWeight: "",
            averageUnitWeight: "",
            lintPercent: "",
            isSaving: false,
            seedingRate: null,
            seedingRateUnitGuid: null,
        });
    };

    refreshCropDropdownLists = (isInitialLoad = false): void => {
        this.props.setIsLoading(true);
        this.setState({
            isRefreshingLists: true,
        });
        const { cropGuid, brandOrganizationGuid } = this.state;
        const refreshDropdowns: Partial<DropdownRequest> = {};
        if (isInitialLoad) {
            refreshDropdowns[DROPDOWN_CROP] = apiUrl("AgBytes/GetCropDropdownList");
            if (!this.props.isPlanting) {
                refreshDropdowns[DROPDOWN_YIELDUNIT_DRY] = dropdowns[DROPDOWN_YIELDUNIT_DRY];
                refreshDropdowns[DROPDOWN_YIELDUNIT_LINT] = dropdowns[DROPDOWN_YIELDUNIT_LINT];
            }
        }
        refreshDropdowns[DROPDOWN_BRANDORGANIZATION] = {
            url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
            model: cropGuid,
        };
        refreshDropdowns[DROPDOWN_VARIETYHYBRID] = {
            url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
            model: {
                cropId: cropGuid,
                brandOrganization: brandOrganizationGuid,
            },
        };

        this.props.refreshDropdowns(refreshDropdowns);
    };

    getYieldUnitId(cropId: number, yieldIaGuid: string | number): number {
        if (this.props.isPlanting) {
            return null;
        }
        let yieldUnit;
        if (this.props[DROPDOWN_YIELDUNIT_DRY] && this.props[DROPDOWN_YIELDUNIT_LINT]) {
            yieldUnit = !ImportCropFilter_.isCottonCrop(cropId)
                ? this.props[DROPDOWN_YIELDUNIT_DRY].find((unit) => {
                      return unit.importAttributeGuid === yieldIaGuid;
                  })
                : this.props[DROPDOWN_YIELDUNIT_LINT].find((unit) => {
                      return unit.importAttributeGuid === yieldIaGuid;
                  });
        }
        return yieldUnit ? +yieldUnit.unitId : -1;
    }

    getCropId(cropGuid: string): number {
        let selectedCrop;
        if (this.props[DROPDOWN_CROP]) {
            selectedCrop = this.props[DROPDOWN_CROP].find((crop) => {
                return crop.cropGuid === cropGuid;
            });
        }
        return selectedCrop ? +selectedCrop.cropId : -1;
    }

    updateCropModelSeedingRate = (newValue: string): void => {
        this.setState({
            seedingRate: newValue,
        });
    };

    updateCropModelSeedingRateUnit = (newValue: string): void => {
        this.setState({
            seedingRateUnitGuid: newValue,
        });
    };

    updateCropModel = (propertyName: string, newValue: string | number): void => {
        const { cropGuid, cropPurposeGuid, brandOrganizationGuid, varietyHybridGuid } = this.state;
        const newEditProps: Partial<ICropFilterState> = {
            cropGuid,
            cropPurposeGuid,
            brandOrganizationGuid,
            varietyHybridGuid,
        };
        let refreshCropDropdowns = false;
        /* eslint-disable no-fallthrough */
        switch (propertyName) {
            case "cropGuid": {
                newEditProps.cropId = this.getCropId(newValue as string);
                newEditProps.cropClassNameGuid = "";
                newEditProps.yieldIaGuid = "";
                newEditProps.lintPercent = ImportCropFilter_.isCottonCrop(newEditProps.cropId)
                    ? 35
                    : "";
                newEditProps.isFetchingPreferences = true;
                newEditProps.averageTestWeight = "";
                newEditProps.averageUnitWeight = "";
                newEditProps.brandOrganizationGuid = "";
                newEditProps.varietyHybridGuid = "";
                refreshCropDropdowns = true;
            }
            case "cropPurposeGuid": {
                newEditProps.cropPurposeGuid = cropPurposeGuid;
                break;
            }
            case "brandOrganizationGuid":
                newEditProps.varietyHybridGuid = "";
                refreshCropDropdowns = true;
                break;
            case "varietyHybridGuid": {
                const varietyOption = this.state.options.variety.find((v) => v.value === newValue);
                if (varietyOption) {
                    newEditProps.brandOrganizationGuid = varietyOption.parentValue;
                    refreshCropDropdowns =
                        this.state.brandOrganizationGuid !== newEditProps.brandOrganizationGuid;
                }
                break;
            }
            case "yieldIaGuid": {
                newEditProps.yieldUnitId = this.getYieldUnitId(this.state.cropId, newValue);
                newEditProps.isFetchingPreferences = true;
                newEditProps.averageTestWeight = "";
                newEditProps.averageUnitWeight = "";
                break;
            }
            default:
                break;
        }
        /* eslint-enable no-fallthrough */
        newEditProps[propertyName] = newValue;

        this.setState(
            {
                ...this.state,
                ...newEditProps,
            },
            () => {
                if (refreshCropDropdowns) {
                    this.refreshCropDropdownLists();
                }
                if (propertyName === "cropGuid" || propertyName === "yieldIaGuid") {
                    this.props.setIsLoading(true);
                    this.props.fetchUserCropImportPreferences(
                        this.state.cropGuid,
                        this.state.yieldIaGuid
                    );
                }
            }
        );
    };

    canSaveEdit = (): boolean => {
        const {
            averageTestWeight,
            averageUnitWeight,
            brandOrganizationGuid,
            cropGuid,
            cropId,
            lintPercent,
            varietyHybridGuid,
            yieldIaGuid,
            yieldUnitId,
        } = this.state;
        return Boolean(
            cropGuid &&
                (yieldIaGuid || this.props.isPlanting) &&
                (!this.props.isPlanting || (brandOrganizationGuid && varietyHybridGuid)) &&
                (averageTestWeight ||
                    !ImportCropFilter_.isBushelPerAcre(yieldUnitId) ||
                    averageUnitWeight ||
                    !ImportCropFilter_.isVolumetricFlowRate(yieldUnitId)) &&
                (!ImportCropFilter_.isCottonCrop(cropId) || lintPercent !== "") &&
                this.#seedingRateSavable()
        );
    };

    canSaveAlias = (): boolean => {
        const { aliasVarietyHybridName } = this.state;
        return Boolean(aliasVarietyHybridName) || this.state.isEditingAlias;
    };

    assignToCrops(data, index: number) {
        const from = data.crops[index];

        const copy = (to) => ({
            ...from,
            importFiles: to.importFiles,
            hasLookupMatch: true,
            isSelected: to.isSelected,
            rawBrand: to.rawBrand,
            rawCrop: to.rawCrop,
            rawVariety: to.rawVariety,
        });

        return {
            ...data,
            crops: data.crops.map((to) => (to.hasLookupMatch ? to : copy(to))),
        };
    }

    onAddEditProductMix(index = -1): void {
        const isAdd = index === -1;
        let mixIndex = index;
        let originalProductMixList = this.state.originalProductMixList
            ? [...this.state.originalProductMixList]
            : [];
        if (isAdd) {
            const productMixList = [...originalProductMixList, new blendingModels.ProductMix("")];
            mixIndex = productMixList.length - 1;
            originalProductMixList = productMixList;
        }
        this.setState({
            isProductBlendingActive: true,
            activeProductBlend: mixIndex,
            originalProductMixList: originalProductMixList,
        });
    }

    _updateTargetRate(index: number, newTargetRate: string): void {
        const { availableProducts, conversionFactors, productBlendPicklists } = this.props;
        const productMixList = this.state.originalProductMixList;
        const newProductMixList = productMixList.map((mix, mixIndex) => {
            if (
                mixIndex === index &&
                blending.roundValue(newTargetRate, 2) !== blending.roundValue(mix.targetRate, 2)
            ) {
                const blendingProps = {
                    availableProducts,
                    conversionFactors,
                    productBlendPicklists,
                };
                const lineRatio = Number(newTargetRate) / Number(mix.targetRate);
                const newCostPerAcre = mix.costPerAcre * lineRatio;
                const newTotalProduct = mix.totalProduct * lineRatio;
                const newTotalCost = mix.totalCost * lineRatio;
                return {
                    ...mix,
                    actualRate: newTargetRate,
                    targetRate: newTargetRate,
                    costPerAcre: newCostPerAcre,
                    totalProduct: newTotalProduct,
                    totalCost: newTotalCost,
                    products: mix.products.map((product) => {
                        const rate =
                            newTargetRate && product.rate === 0
                                ? blending.getProductRateInMix(
                                      product.density,
                                      {
                                          ...mix,
                                          targetRate: newTargetRate,
                                      },
                                      product,
                                      blending.getRateUnit(product.rateUnitGuid, blendingProps),
                                      blendingProps
                                  )
                                : product.rate * lineRatio;
                        const lineRateToLineCostConversionFactor =
                            blending.getProductRateConversionFactor(
                                product.rateUnitGuid,
                                product.costUnitGuid,
                                product.density,
                                newTargetRate,
                                blendingProps
                            );
                        const calculatedArea = 1;
                        const totalProduct =
                            newTargetRate && product.rate === 0
                                ? rate * lineRateToLineCostConversionFactor * calculatedArea
                                : product.totalProduct * lineRatio;
                        const totalCost = totalProduct * product.cost;
                        const costPerAcre = totalCost / calculatedArea;
                        return {
                            ...product,
                            rate,
                            costPerAcre,
                            totalProduct,
                            totalCost,
                        };
                    }),
                };
            }
            return mix;
        });
        this.setState({ originalProductMixList: newProductMixList });
    }

    onRemoveProductMix(index: number): void {
        const productMixList = [...this.state.initialProductMix];
        productMixList.splice(index, 1);
        this.setState({
            initialProductMix: productMixList,
            originalProductMixList: productMixList,
        });
    }

    _getMenuItems(index: number) {
        const { formatMessage } = this.props.intl;
        return [
            {
                label: formatMessage(messages.editProductMixText),
                action: () => this.onAddEditProductMix(index),
            },
            {
                label: formatMessage(messages.removeProductMixText),
                action: () => this.onRemoveProductMix(index),
            },
        ].map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    #seedingRateSavable(): boolean {
        const { seedingRate, seedingRateUnitGuid } = this.state;

        return Boolean(
            (!seedingRate && !seedingRateUnitGuid) ||
                seedingRate === "0" ||
                (seedingRate && seedingRateUnitGuid)
        );
    }

    #showSeedingRateUnitOptions(item: FileImportAPI.CropFilterItem): boolean {
        const showSeeding =
            !item.seedingRateOriginal ||
            Number(item.seedingRateOriginal) === 0 ||
            item.seedingRateOriginal === "0"
                ? true
                : false;
        return showSeeding;
    }

    //work around since the menu currently supports the in build window object
    triggerClick = (): void => {
        const evObj = document.createEvent("MouseEvents");
        evObj.initMouseEvent(
            "click",
            true,
            true,
            window,
            1,
            1,
            1,
            1,
            1,
            false,
            false,
            false,
            false,
            0,
            null
        );
        document.dispatchEvent(evObj);
    };

    buildProducts = (): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const addMixStyle: CSSProperties = {};
        const initialProductMix = this.state.initialProductMix;
        if (initialProductMix && initialProductMix.length === 0) {
            addMixStyle.paddingTop = 0;
            addMixStyle.position = "relative";
            addMixStyle.top = 20;
            addMixStyle.paddingLeft = 5;
        } else {
            addMixStyle.top = 0;
            addMixStyle.paddingLeft = 5;
        }
        return (
            <div onClick={() => this.triggerClick()}>
                <div className="product-mixes-table">
                    {initialProductMix &&
                        initialProductMix.map((mix, index) => {
                            const mixNumber = index + 1;
                            const mixName =
                                mix.products.length <= 1 &&
                                !(mix.products.length === 1 && mix.products[0].customProductGuid)
                                    ? ""
                                    : mix.name;
                            const mixTitle = `${formatMessage(messages.mixText)} ${mixNumber}`;
                            const isMixLocked =
                                mix.isLockGuaranteedAnalysis ||
                                mix.isLockProductRatios ||
                                !this.state.isProductBlendingActive;
                            return (
                                <div key={`mix-${index}`} className="product-mixes-rows">
                                    <div className="product-name-column">
                                        <div className="mix-title" title={mixTitle}>
                                            {mixTitle}:
                                        </div>
                                        <div className="mix-product-list">
                                            {mixName.length === 0 ? (
                                                mix.products
                                                    .filter((product) => !product.customProductGuid)
                                                    .map((product, pIndex) => (
                                                        <div
                                                            key={`product-${index}-${pIndex}`}
                                                            className="product-row"
                                                        >
                                                            {product.productName}
                                                        </div>
                                                    ))
                                            ) : (
                                                <div className="product-row"> {mixName}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="rate-input">
                                        {isMixLocked ? (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateTargetRate(index, numValue)
                                                }
                                                value={mix.targetRate}
                                                showTopLabel={false}
                                            />
                                        ) : (
                                            <div className="rate-unit">{mix.targetRate}</div>
                                        )}
                                    </div>
                                    <div className="rate-unit">{mix.targetRateUnit}</div>
                                    <div className="product-menu">
                                        <Menu
                                            className="context-menu"
                                            isDotMenu={true}
                                            getMenuItems={() => this._getMenuItems(index)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="add-mix" style={addMixStyle}>
                    <NoLink
                        label={formatMessage(messages.addProductMixText)}
                        onClick={() => {
                            logFirebaseEvent("import_planting_add_product_mix");
                            this.onAddEditProductMix();
                        }}
                    />
                </div>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <ImportFilter
                    alterEditColumn={this.props.isPlanting || this.props.isHarvest}
                    isPlanting={this.props.isPlanting}
                    isHarvest={this.props.isHarvest}
                    filterTypeName={actions.ImportFilterTypes.CROPS}
                    title={formatMessage(messages.cropFilterTitle)}
                    itemProperties={this.filterProperties}
                    filterItems={this.props.filterItems}
                    selectedFieldGuid={this.props.selectedFieldGuid}
                    selectItem={(item) => {
                        this.props.selectItem(item);
                    }}
                    onEditAttribute={(item) => {
                        this.onEditAttribute(item);
                    }}
                    onAddAlias={(item, index) => {
                        logFirebaseEvent("import_planting_variety_add_alias");
                        this.onAddAlias(item, index);
                    }}
                    updateFilterStatus={(isComplete) => this.props.updateFilterStatus(isComplete)}
                    removeImportFiles={(importFileGuidList) =>
                        this.props.removeImportFiles(importFileGuidList)
                    }
                    assignMatchedToUnmatched={(index) =>
                        this.props.assignMatchedToUnmatched(index, this.assignToCrops)
                    }
                />
                {!this.state.editItem ? null : (
                    <div>
                        <AttributeEditModal
                            className="crop-edit-modal"
                            title={formatMessage(messages.editCropText)}
                            isOpen={true}
                            canSave={this.canSaveEdit()}
                            onSaveEdit={this.onSaveEdit}
                            onCloseEdit={this.onCloseEdit}
                        >
                            {this.state.isSaving || this.state.isRefreshingLists ? (
                                <Loader />
                            ) : null}
                            <div className="cbv-inputs">
                                <SelectInput
                                    autoFocus
                                    openOnFocus={false}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    value={this.state.cropGuid}
                                    options={this.state.options.crop}
                                    required={true}
                                    clearable={false}
                                    onChange={(value) => {
                                        this.updateCropModel("cropGuid", value);
                                    }}
                                    placeholderText={formatMessage(messages.cropPlaceholderText)}
                                />
                                <SelectInput
                                    optionIsHiddenKey={ACTIVE_YN}
                                    value={this.state.cropPurposeGuid}
                                    options={this.state.options.cropPurpose}
                                    onChange={(value) => {
                                        this.updateCropModel("cropPurposeGuid", value);
                                    }}
                                    placeholderText={formatMessage(
                                        messages.cropPurposePlaceholderText
                                    )}
                                />
                                <SelectInput
                                    optionIsHiddenKey={ACTIVE_YN}
                                    value={this.state.brandOrganizationGuid}
                                    options={this.state.options.brand}
                                    onChange={(value) => {
                                        this.updateCropModel("brandOrganizationGuid", value);
                                    }}
                                    placeholderText={formatMessage(messages.brandPlaceholderText)}
                                    disabled={
                                        !(
                                            this.state.options.brand.length > 0 &&
                                            this.state.cropGuid
                                        )
                                    }
                                    required={this.props.isPlanting}
                                />
                                <SelectInput
                                    optionIsHiddenKey={ACTIVE_YN}
                                    value={this.state.varietyHybridGuid}
                                    options={this.state.options.variety}
                                    onChange={(value) => {
                                        this.updateCropModel("varietyHybridGuid", value);
                                    }}
                                    placeholderText={formatMessage(messages.varietyPlaceholderText)}
                                    disabled={
                                        !(
                                            this.state.options.variety.length > 0 &&
                                            this.state.cropGuid
                                        )
                                    }
                                    required={this.props.isPlanting}
                                />
                                {!this.props.isPlanting ||
                                !this.state.showSeedingRateUnitOptions ? null : (
                                    <NumericInput
                                        scale={2}
                                        precision={9}
                                        value={this.state.seedingRate}
                                        required={false}
                                        onChange={(v) => {
                                            this.updateCropModelSeedingRate(v);
                                        }}
                                        placeholderText={formatMessage(messages.seedingRateText)}
                                    />
                                )}
                                {!this.props.isPlanting ||
                                !this.state.showSeedingRateUnitOptions ? null : (
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        value={this.state.seedingRateUnitGuid}
                                        options={this.props.seedingRateUnits?.map(
                                            ({ importAttributeGuid, abbreviation }) => ({
                                                value: importAttributeGuid,
                                                label: abbreviation,
                                            })
                                        )}
                                        required={false}
                                        clearable={true}
                                        onChange={(v) => {
                                            this.updateCropModelSeedingRateUnit(v);
                                        }}
                                        placeholderText={formatMessage(
                                            messages.seedingRateUnitText
                                        )}
                                    />
                                )}
                                {this.props.isPlanting ? null : (
                                    <SelectInput
                                        optionIsHiddenKey={ACTIVE_YN}
                                        value={this.state.yieldIaGuid}
                                        options={
                                            !ImportCropFilter_.isCottonCrop(this.state.cropId)
                                                ? this.state.options.yieldUnitDry
                                                : this.state.options.yieldUnitLint
                                        }
                                        required={true}
                                        clearable={false}
                                        onChange={(value) => {
                                            this.updateCropModel("yieldIaGuid", value);
                                        }}
                                        placeholderText={formatMessage(
                                            messages.yieldUnitPlaceholderText
                                        )}
                                    />
                                )}
                                {this.props.isPlanting ||
                                !this.state.yieldIaGuid ||
                                !ImportCropFilter_.isVolumetricFlowRate(
                                    this.state.yieldUnitId
                                ) ? null : (
                                    <NumericInput
                                        scale={4}
                                        precision={9}
                                        value={this.state.averageUnitWeight}
                                        required={true}
                                        onChange={(v) => {
                                            this.updateCropModel("averageUnitWeight", v);
                                        }}
                                        placeholderText={formatMessage(
                                            messages.averageUnitWeightText
                                        )}
                                    />
                                )}
                                {this.props.isPlanting ||
                                !this.state.yieldIaGuid ||
                                !ImportCropFilter_.isBushelPerAcre(
                                    this.state.yieldUnitId
                                ) ? null : (
                                    <NumericInput
                                        scale={4}
                                        precision={9}
                                        value={this.state.averageTestWeight}
                                        required={true}
                                        onChange={(v) => {
                                            this.updateCropModel("averageTestWeight", v);
                                        }}
                                        placeholderText={formatMessage(
                                            messages.averageTestWeightText
                                        )}
                                    />
                                )}
                                {this.props.isPlanting ||
                                !ImportCropFilter_.isCottonCrop(this.state.cropId) ? null : (
                                    <NumericInput
                                        scale={2}
                                        precision={5}
                                        value={this.state.lintPercent}
                                        required={true}
                                        onChange={(v) => {
                                            this.updateCropModel("lintPercent", v);
                                        }}
                                        placeholderText={formatMessage(
                                            messages.lintPercentPlaceholderText
                                        )}
                                    />
                                )}
                            </div>
                            {this.props.isPlanting ? (
                                <div className="product-table">
                                    {this.buildProducts()}
                                    {!this.state.isProductBlendingActive ? null : (
                                        <ProductBlendModal
                                            productMix={
                                                this.state.originalProductMixList[
                                                    this.state.activeProductBlend
                                                ]
                                            }
                                            calculatedArea={1}
                                            isOpen={this.state.isProductBlendingActive}
                                            activeProductMixIdx={this.state.activeProductBlend}
                                            onUpdate={(productMix) =>
                                                this.onUpdateProductBlending(productMix)
                                            }
                                            onSave={() => this.onSaveProductBlending()}
                                            onCancel={() => this.onCancelProductBlending()}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </AttributeEditModal>
                    </div>
                )}
                {!this.state.addAliasItem ? null : (
                    <div>
                        <AttributeEditModal
                            className="alias-add-modal"
                            title={formatMessage(messages.addAliasVarietyHybridText)}
                            isOpen={true}
                            canSave={this.canSaveAlias()}
                            onSaveEdit={this.onSaveAlias}
                            onCloseEdit={this.onCloseAlias}
                        >
                            {this.state.isSaving || this.state.isRefreshingLists ? (
                                <Loader />
                            ) : null}
                            <TextInput
                                maxLength={50}
                                value={this.state.aliasVarietyHybridName}
                                placeholderText={formatMessage(messages.aliasVarietyHybridNameText)}
                                onChange={(value) => {
                                    this.setState({
                                        aliasVarietyHybridName: value,
                                    });
                                }}
                            />
                        </AttributeEditModal>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const dropdowns = selectors.getImportWizardDropdowns(state);
    const availableProducts = blendingSelectors.getAvailableProducts(state);
    const conversionFactors = blendingSelectors.getConversionFactors(state);
    const productBlendPicklists = blendingSelectors.getProductBlendPicklists(state);

    let dropDownData = {};

    for (const key of Object.keys(dropdowns)) {
        dropDownData = Object.assign(dropDownData, { [key]: dropdowns[key] });
    }

    return {
        ...dropDownData,
        isLoading: selectors.getIsLoading(state),
        userCropImportPreferences: selectors.getUserCropImportPreferences(state),
        availableProducts: availableProducts,
        conversionFactors: conversionFactors,
        productBlendPicklists: productBlendPicklists,
        seedingRateUnits: picklistSelectors.getPicklistFromCode(state, "seedingRate"),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIsLoading: (isLoading) => dispatch(actions.setIsLoading(isLoading)),
        fetchUserCropImportPreferences: (cropGuid, yieldIaGuid) =>
            dispatch(actions.fetchUserCropImportPreferences(cropGuid, yieldIaGuid)),
        setUserCropImportPreferences: (userCropImportPreferences) =>
            dispatch(actions.setUserCropImportPreferences(userCropImportPreferences)),
        refreshDropdowns: (dropdowns: Partial<DropdownRequest>[] | string[]) => {
            dispatch(
                fetchDropdownData({
                    ...dropdowns,
                    action: actions.setDropdownData,
                    async: false,
                })
            );
        },
        updateUserCropImportPreferences: (
            cropGuid,
            yieldIaGuid,
            averageTestWeight,
            averageUnitWeight
        ) => {
            dispatch(
                actions.updateUserCropImportPreferences(
                    cropGuid,
                    yieldIaGuid,
                    averageTestWeight,
                    averageUnitWeight
                )
            );
        },
    };
};

export const ImportCropFilter = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ImportCropFilter_));
