import { ISoilTypeAttribute } from "~/admin/setup/report-books/data/interfaces";
import * as model from "~/admin/setup/report-books/data/model";

export interface IReportPreferences {
    fieldsPerPage?: string | number;
    growerSummaryDisplayOption?: string | number;
    reportTypePageSelection?: string;
    fieldLabelConfig?: string;
    soilTypeAttributeOptions?: ISoilTypeAttribute[];
}

export const onReportOptionsConfigChange = (
    visibleConfigOptions: Record<string, unknown>,
    tempConfigurationData: Record<string, unknown>
): Record<string, unknown> => {
    tempConfigurationData = {
        ...visibleConfigOptions,
        [model.PROPS_CONFIGURATION_NAME]: tempConfigurationData[model.PROPS_CONFIGURATION_NAME],
    };

    return tempConfigurationData;
};
export const setReportPreferences = (
    reportPreferences: IReportPreferences,
    options: string,
    soilTypeAttributes: ISoilTypeAttribute[]
): IReportPreferences => {
    if (reportPreferences == null) {
        return {};
    }
    if (options != null) {
        reportPreferences = { ...reportPreferences, ...JSON.parse(options) };
        if (reportPreferences.fieldsPerPage != null) {
            reportPreferences.fieldsPerPage = ["grower", "farm", "field"].indexOf(
                reportPreferences.fieldsPerPage as string
            );
        }
        if (reportPreferences.growerSummaryDisplayOption != null) {
            reportPreferences.growerSummaryDisplayOption = ["grower", "farm", "field"].indexOf(
                reportPreferences.growerSummaryDisplayOption as string
            );
        }
        if (reportPreferences.reportTypePageSelection != null) {
            reportPreferences.reportTypePageSelection = JSON.parse(
                reportPreferences.reportTypePageSelection
            );
        }
        if (reportPreferences.fieldLabelConfig != null) {
            reportPreferences.fieldLabelConfig = JSON.parse(reportPreferences.fieldLabelConfig);
        }
    }

    if (soilTypeAttributes?.length > 0) {
        reportPreferences.soilTypeAttributeOptions = soilTypeAttributes;
    }

    return reportPreferences;
};
