import React from "react";
import { connect } from "react-redux";
import { SelectInput } from "~/core";
import {
    selectors as picklistSelectors,
    picklistNames,
    actions as picklistActions,
} from "~/core/picklist";

export interface IOptionList {
    label: string;
    value: string;
}

export interface IPicklistSelectInputProps {
    picklistName: string;
    optionList?: IOptionList[];
    value: string;
    onChange: (key: number | string, displayValue: string) => void;
    placeholderText: string;
    clearable?: boolean;
    required: boolean;
    openOnFocus?: boolean;
    fetchPicklistData?: (payload: Record<string, unknown>) => void;
    enabled?: boolean;
    optionListFilter?: (optionItem: any) => boolean;
}

class PicklistSelectInput_ extends React.PureComponent<IPicklistSelectInputProps> {
    static defaultProps: IPicklistSelectInputProps = {
        picklistName: null,
        value: null,
        onChange: () => null,
        placeholderText: null,
        clearable: false,
        required: false,
        openOnFocus: false,
        fetchPicklistData: () => null,
        enabled: true,
        optionListFilter: null,
    };

    private onChange(value: number | string) {
        const foundValue = this.props.optionList.find((row) => row.value === value);
        if (foundValue === undefined) {
            this.props.onChange(null, null);
        } else {
            this.props.onChange(value, foundValue.label);
        }
    }

    private getOptionList() {
        if (this.props.optionListFilter && this.props.optionList) {
            return this.props.optionList.filter(this.props.optionListFilter);
        } else {
            return this.props.optionList;
        }
    }

    render() {
        return (
            <SelectInput<string | number>
                required={this.props.required}
                clearable={this.props.clearable}
                openOnFocus={this.props.openOnFocus}
                placeholderText={this.props.placeholderText}
                optionIsHiddenKey={null}
                options={this.getOptionList()}
                onChange={(value) => this.onChange(value)}
                value={this.props.value}
            />
        );
    }

    componentDidMount() {
        if (!this.props.optionList || this.props.optionList.length === 0) {
            this.props.fetchPicklistData({
                [this.props.picklistName]: picklistNames.getPickListCode(this.props.picklistName),
            });
        }
    }
}

// state and payload in the next 2 functions are a bit too ambiguous to be strongly typed
const mapStateToProps = (state: Record<string, unknown>, ownProps: IPicklistSelectInputProps) => ({
    optionList:
        ownProps.optionList ||
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(ownProps.picklistName)
        ),
});

const mapDispatchToProps = (dispatch) => ({
    fetchPicklistData: (payload: Record<string, unknown>) =>
        dispatch(picklistActions.fetchPicklistData(payload)),
});

export const PicklistSelectInput = connect(
    mapStateToProps,
    mapDispatchToProps
)(PicklistSelectInput_);
