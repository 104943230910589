import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { getAgBytesErrorClassNames } from "~/admin/utils";
import { messages } from "../../../../i18n-messages";

// Components
import { SubSection, TextInput } from "~/core";

//Style
import "./email.css";

export const EMAIL_ERROR_CODE_LIST = [2085, 17];

export class EmailItem extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        intl: intlShape.isRequired,
        index: PropTypes.number,
        email: PropTypes.array,
        onChange: PropTypes.func,
        onPrimaryChange: PropTypes.func,
        emailListAlias: PropTypes.string,
        emailAlias: PropTypes.string,
        required: PropTypes.bool,
    };

    getContainerClass = (className, errorCodes, type) => {
        const { email, index } = this.props;
        if (email[index].isError) {
            const { apiErrors, apiErrorModel } = this.props;
            const isError = errorCodes.some(
                (code) => apiErrorModel[code] && apiErrorModel[code].includes(type)
            );
            return [
                classNames(
                    className,
                    isError ? getAgBytesErrorClassNames(errorCodes, apiErrors) : ""
                ),
            ];
        }
        return [className];
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { index, onChange, required, email } = this.props;
        return (
            <SubSection>
                <TextInput
                    required={required}
                    tabIndex={0}
                    maxLength={254}
                    placeholderText={formatMessage(messages.email)}
                    labelText={formatMessage(messages.email)}
                    onChange={(value) => onChange({ type: this.props.emailAlias }, value, index)}
                    value={email[index][this.props.emailAlias]}
                    containerClassNames={this.getContainerClass(
                        "email-container",
                        EMAIL_ERROR_CODE_LIST,
                        email[index][this.props.emailAlias]
                    )}
                />
            </SubSection>
        );
    }
}

export default injectIntl(EmailItem);
