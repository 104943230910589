import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as actions from "../actions";
import * as selectors from "../selectors";

import { ProductBlendModal_ } from "../components/product-blend-modal";

const mapStateToProps = (state) => ({
    availableCredits: selectors.getAvailableCredits(state),
    availableProducts: selectors.getAvailableProducts(state),
    batchCreditedRecNutrients: selectors.getBatchCreditedRecNutrients(state),
    carrierProducts: selectors.getCarrierProducts(state),
    creditedGridCells: selectors.getCreditedGridCells(state),
    creditedRecNutrient: selectors.getCreditedRecNutrient(state),
    productBlendGuid: selectors.getProductBlendGuid(state),
    conversionFactors: selectors.getConversionFactors(state),
    customBlends: selectors.getCustomBlends(state),
    customProducts: selectors.getCustomProducts(state),
    defaultCarrierGuid: selectors.getDefaultCarrierGuid(state),
    defaultChemicalTargetRate: selectors.getDefaultChemicalTargetRate(state),
    defaultProductMixTypeGuid: selectors.getDefaultProductMixTypeGuid(state),
    filteredProductBlendPicklists: selectors.getFilteredProductBlendPicklists(state),
    filteredProducts: selectors.getFilteredProducts(state),
    isLoading: selectors.getIsLoading(state),
    productBlendPicklists: selectors.getProductBlendPicklists(state),
    productFilters: selectors.getProductFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
    applyCredits: (recNutrientGuid, credits) =>
        dispatch(actions.applyCredits(recNutrientGuid, credits)),
    applyBatchCredits: (recNutrientGuidList, credits) =>
        dispatch(actions.applyBatchCredits(recNutrientGuidList, credits)),
    clearBatchCreditedRecNutrients: () => dispatch(actions.setBatchCreditedRecNutrients(null)),
    clearCreditedRecNutrient: () => dispatch(actions.setCreditedRecNutrient(null)),
    clearCustomBlends: () => dispatch(actions.setCustomBlends([])),
    clearProductBlendGuid: () => dispatch(actions.setProductBlendGuid(null)),
    deactivateProductBlend: (productBlendGuid) =>
        dispatch(actions.deactivateProductBlend(productBlendGuid)),
    fetchAvailableCredits: (recNutrientGuid) =>
        dispatch(actions.fetchAvailableCredits(recNutrientGuid)),
    fetchCreditedGridCells: (recNutrientGuidList) =>
        dispatch(actions.fetchCreditedGridCells(recNutrientGuidList)),
    fetchCustomBlends: (productMixTypeGuid, nutrientGuid) =>
        dispatch(actions.fetchCustomBlends(productMixTypeGuid, nutrientGuid)),
    fetchCustomProducts: () => dispatch(actions.fetchCustomProducts()),
    initProductBlending: () => dispatch(actions.initProductBlendModal()),
    updateProductFilter: (filterProp, filterValue) =>
        dispatch(actions.updateProductFilter(filterProp, filterValue)),
    saveProductBlend: (productBlend, isAuto, callback) =>
        dispatch(actions.saveProductBlend(productBlend, isAuto, callback)),
    saveCustomProduct: (customProduct) => dispatch(actions.saveCustomProduct(customProduct)),
    setCustomBlends: (customBlends) => dispatch(actions.setCustomBlends(customBlends)),
    setDefaultCarrierGuid: (carrierGuid) => dispatch(actions.setDefaultCarrierGuid(carrierGuid)),
    setDefaultProductMixTypeGuid: (productMixTypeGuid) =>
        dispatch(actions.setDefaultProductMixTypeGuid(productMixTypeGuid)),
    updateBatchRecDetails: (recAreaList) => dispatch(actions.updateBatchRecDetails(recAreaList)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    filteredProducts: stateProps.filteredProducts.filter((product) => {
        return !ownProps.productMix.products.some(
            (listProduct) => listProduct.productGuid === product.value
        );
    }),
    filteredNutrientProducts: !ownProps.nutrientGuid
        ? stateProps.availableProducts
        : stateProps.availableProducts
              .filter(
                  (product) =>
                      product.nutrientList &&
                      product.nutrientList.some(
                          (nutrient) => nutrient.nutrientGuid === ownProps.nutrientGuid
                      )
              )
              .map((product) => ({
                  label: product.name,
                  value: product.productGuid,
              }))
              .filter(
                  (product) =>
                      !ownProps.productMix.products.some(
                          (listProduct) => listProduct.productGuid === product.value
                      )
              ),
});

export const ProductBlendModal = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(ProductBlendModal_));
