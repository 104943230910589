export const LayerModulePages = {
    LAYER_LIST: "LAYER_LIST",
    ANALYSIS_INFORMATION: "ANALYSIS_INFORMATION",
    IMAGERY_LAYER: "IMAGERY_LAYER",
    NON_FIELD_FEATURE_INFORMATION: "NON_FIELD_FEATURE_INFORMATION",
};

export const SET_ACTIVE_PAGE = "layer-module/SET_ACTIVE_PAGE";

export const setActivePage = (activePage) => ({
    type: SET_ACTIVE_PAGE,
    payload: { activePage },
});
